import React, { useEffect, useState } from "react";
import Classes from "./RejectDocumentModal.module.css";
import { useTranslation } from "react-i18next";
import Modal from "../../../../../components/Modal/Modal";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import rejectfileIcon from "../../../../../assets/reject_document.svg";
import crossIconSVG from "../../../../../assets/cross-x-icon.svg";
import Utils from "../../../../../Utils";
import { memo } from "react";
const RejectedDocumentModal = ({
  isModalOpen,
  setIsModalOpen,
  corpDetails,
  documentsItem,
  setDocumentsItem,
  isActiveId,
  rejectReasons,
  documentsStatus,
  setAcceptDisable,
  setRejectDisable,
  setProceedStatus,
  proceedDataFunction,
  rejectedForms,
  setRejectedForms,
  selectedReason,
  setSelectedReason,
  triggerNextDocFunction,
  scrollToBottom,
  checkAllDocScruitny,
}) => {
  const [customRejReason, setCustomRejReason] = useState(false);
  const [rejCheckButton, setRejCheckButton] = useState(true);
  const [otherVal, setOtherVal] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    if (rejectedForms[isActiveId] != undefined) {
      setSelectedReason(rejectedForms[isActiveId]?.reason);
    } else {
      setSelectedReason([]);
      setCustomRejReason(false);
    }
  }, [isModalOpen === false]);

  // setreject
  const rejectedFunction = (check, id) => {
    if (documentsItem && check == true) {
      var newArr = documentsItem;
      newArr[isActiveId].docStatus = documentsStatus.rejected;
      newArr[isActiveId].isChecked = true;
      setDocumentsItem(newArr);
      ischeckedFunction(true);
      setRejCheckButton(true);
      ischeckedFunction(documentsItem[id]);
    }
    if (id !== documentsItem?.length - 1) {
      triggerNextDocFunction();
    }
    if (checkAllDocScruitny()) {
      scrollToBottom();
    }
  };

  // handle add custom reason
  const addCustomReason = () => {
    if (customRejReason) {
      if (otherVal != null && otherVal?.length > 0) {
        setSelectedReason([...selectedReason, otherVal]);
        setRejCheckButton(false);
      }
      setOtherVal("");
    }
  };

  // rejected add reason function
  const rejectedReasonFormsFunction = (id, isActiveId) => {
    var checkExisting = false;
    if (rejectedForms[isActiveId] != null) {
      checkExisting = true;
    }
    if (!checkExisting) {
      setRejectedForms({
        ...rejectedForms,
        [isActiveId]: {
          document_name: documentsItem[isActiveId].processDocumentType.uiString,
          docStatus: documentsItem[isActiveId].docStatus,
          reason: selectedReason,
          mandatory: documentsItem[isActiveId].processDocumentType.isMandatory,
        },
      });
    } else {
      rejectedForms[isActiveId].reason = selectedReason;
    }
    setRejectedForms((data) => ({
      ...data,
    }));

    setCustomRejReason(false);
    // setSelectedReason([]);
    setIsModalOpen(false);
  };
  // selected reasons for reject
  const selectedReasonFunction = (e) => {
    if (e != "others") {
      setCustomRejReason(false);
      if (!selectedReason.includes(e)) {
        setSelectedReason([...selectedReason, e]);
        setRejCheckButton(false);
      }
    } else {
      setCustomRejReason(true);
    }
    if (selectedReason.length > 0) {
      setRejCheckButton(false);
    }
  };

  //   remove selected reasons
  const removeSelectedReasonFunction = (indexToRemove) => {
    setSelectedReason((oldReasons) =>
      oldReasons.filter((_, index) => index !== indexToRemove)
    );
  };

  //   rejected reasons select
  const rejectedReasonFunction = () => {
    return rejectReasons.map((item, id) => {
      return (
        <option value={item} key={id}>
          {item}
        </option>
      );
    });
  };
  // check rejCheckButtonStatus
  const rejCheckButtonStatus = () => {
    if (selectedReason.length < 2) {
      setRejCheckButton(true);
    } else {
      setRejCheckButton(false);
    }
  };

  const ischeckedFunction = (check) => {
    if (check.docStatus == documentsStatus.accepted) {
      setAcceptDisable(true);
    } else {
      setAcceptDisable(false);
    }

    if (check.docStatus == documentsStatus.rejected) {
      setRejectDisable(true);
    } else {
      setRejectDisable(false);
    }

    for (let checkData of documentsItem) {
      if (
        (checkData.isChecked == true &&
          checkData.docStatus != documentsStatus.pending) ||
        (checkData.isChecked == false && checkData.documentName == null) ||
        checkData.orionDocId == null
      ) {
        setProceedStatus(false);
      } else {
        return setProceedStatus(true);
      }
    }
  };

  const handleTextArea = (e) => {
    let val = Utils.PreventSpecialCharacterForAddress(e);
    setOtherVal(val);
  };

  return (
    isModalOpen && (
      <Modal>
        <div className={"p-4" + " " + Classes.rejected_modal}>
          <div className="d-flex align-items-center justify-content-between flex-column">
            <h5>
              <img src={rejectfileIcon} className={Classes.rejected_svg} />{" "}
              Document Rejected !
            </h5>
            <div
              className={" " + " " + Classes.modal_acknowledgement_badge}
              disabled
            >
              Acknowledegement ID : {corpDetails.ackId}
            </div>
          </div>

          <div className={"mt-4" + " " + Classes.reject_document_name}>
            <p>
              {documentsItem[isActiveId]?.processDocumentType?.uiString &&
                Utils.ShortLengthFunction(
                  documentsItem[isActiveId]?.processDocumentType?.uiString,
                  100
                )}
            </p>
          </div>

          <div className="mt-4 ">
            <p className={Classes.normal_small_text + " " + "mb-2 "}>
              This document is rejected !Kindly select appropriate reasons for
              the same from the list listed below :
            </p>
          </div>

          <div className="reasons-part">
            <div className="rejected_reasons mt-1">
              <select
                className="form-control form-select"
                onChange={(e) => selectedReasonFunction(e.target.value)}
              >
                <option selected disabled>
                  Select Reasons
                </option>
                {/* {rejReasons} */}
                {rejectedReasonFunction()}
                <option value="others">Others</option>
              </select>
              <p className={Classes.normal_small_text}>
                <span>(You can select multiple reasons)</span>
              </p>
            </div>
            <div className={Classes.selected_reasons}>
              <ul>
                {selectedReason &&
                  selectedReason.map((item, id) => {
                    if (item != undefined) {
                      return (
                        <li key={id}>
                          {item}
                          <span
                            onClick={() => {
                              removeSelectedReasonFunction(id);
                              rejCheckButtonStatus();
                            }}
                          >
                            <i className={Classes.cross_icon_rejected}>
                              <img src={crossIconSVG} />
                            </i>
                          </span>
                        </li>
                      );
                    }
                  })}
              </ul>
            </div>

            {customRejReason && (
              <div className={Classes.custom_reason}>
                <textarea
                  className="textarea form-control w-100 rounded"
                  rows="3"
                  placeholder="write your reasons"
                  id="customreason"
                  onInput={(e) => handleTextArea(e)}
                  value={otherVal}
                ></textarea>
                <div
                  className={Classes.customReasonButton}
                  onClick={() => addCustomReason()}
                >
                  Add
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-12 mt-4 px-0">
            <div className="btn-group ml-auto mr-3">
              <PrimaryButton
                disabled={rejCheckButton}
                label={t("Reject Document ")}
                onClick={() => {
                  rejectedFunction(true, isActiveId);
                  rejectedReasonFormsFunction(isActiveId, isActiveId);
                  proceedDataFunction(documentsItem[isActiveId].id, false);
                }}
              />
            </div>
            <div className="btn-group mr-3">
              <SecondaryButton
                label={t("Close")}
                onClick={() => setIsModalOpen(false)}
              />
            </div>
          </div>
        </div>
        {/* </div> */}
      </Modal>
    )
  );
};

export default memo(RejectedDocumentModal);
