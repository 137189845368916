import React from "react";
import classes from "./Dashboard.module.css";
import Card from "../Card/Card";

const Dashboard = ({ title, cardsObj, handleCardClick }) => {
  return (
    <div className={classes.card_container}>
      <h1>{title}</h1>
      <div className={classes.cards}>
        {cardsObj?.map((data, key) => {
          return (
            <div key={key} className={classes.card}>
              <Card
                onClick={() => handleCardClick(data.path)}
                label={data.label}
                width="100%"
                height="100%"
                img={data.img}
              ></Card>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dashboard;
