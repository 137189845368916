import React from "react";
import classes from "./PrimaryButton.module.css";

function PrimaryButton(props) {
  const primaryBtn = `btn btn-md ${classes.primaryBtn}`;
  return (
    <button
      type={props.type || "button"}
      onClick={props.onClick}
      disabled={props.disabled}
      className={primaryBtn}
      style={props.style}>
      {" "}
      {props.label}
      {props.icon && <img src={props.icon} alt='icon' />}
    </button>
  );
}

export default PrimaryButton;
