import React from "react";
import i18n from "../i18n/i18n";
import MessageDialog from "./components/MessageDialog/MessageDialog";
import Consts from "./Consts";
import { Location, NavigateFunction } from "react-router";
import JSEncrypt from "jsencrypt";
import { useSelector } from "react-redux";
import CryptoJS from "crypto-js";

export const CheckLoggedIn = () => {
  const { loginUserDetails } = useSelector((state) => state);

  if (loginUserDetails?.username) {
    return false;
  }

  return true;
};

const Utils = {
  containsIgnoreCase: (string, search) => {
    return string.search(new RegExp(search, "i")) !== -1;
  },

  showErrorDialog: (errorMessage, closeHandler) => {
    return (
      <MessageDialog
        message={errorMessage}
        title={i18n.t("label.error")}
        btnLabel={i18n.t("button.ok")}
        onClose={closeHandler}
        width="15rem"
        height="5rem"
      />
    );
  },
  showSuccessDialog: (successMessage, closeHandler) => {
    return (
      <MessageDialog
        message={successMessage}
        title={i18n.t("label.success")}
        btnLabel={i18n.t("button.ok")}
        onClose={closeHandler}
        width="15rem"
        height="5rem"
      />
    );
  },

  getCopyName: (allNames, name) => {
    // Already unique.
    if (!allNames.get(name)) {
      return name;
    }

    if (allNames.get(name + " copy")) {
      // Try generating copy (1), copy (2) etc.
      let i = 1;
      while (allNames.get(name + " copy (" + i + ")")) {
        i++;
      }
      return name + " copy (" + i + ")";
    } else {
      return name + " copy";
    }
  },

  inputPrevent: (e) => {
    const invalidChars = ["-", "+", "e", "$"];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  },

  readFileAsText: (file) => {
    return new Promise(function (acc, rej) {
      let reader = new FileReader();
      reader.onload = function () {
        acc(reader.result);
      };
      reader.onerror = function (e) {
        rej("failed to load file");
      };
      reader.readAsText(file);
    });
  },

  validateInput: (inputs) => {
    let obj = {
      valid: true,
    };
    let validInputs = Object.values(inputs);
    validInputs.forEach((value) => {
      if (value === undefined || value.length === 0) {
        obj.valid = false;
        return obj;
      }
    });
    return obj;
  },

  formatShortMoney: (value) => {
    if (value >= 1000000000) {
      return (value / 1000000000).toFixed(2) + "B"; // Billion
    } else if (value >= 10000000) {
      return (value / 10000000).toFixed(2) + "Cr"; // Crore
    } else if (value >= 100000) {
      return (value / 100000).toFixed(2) + "Lac"; // Lakh
    } else if (value >= 1000) {
      return (value / 1000).toFixed(2) + "k"; // Thousand
    } else {
      return value.toString(); // No abbreviation needed
    }
  },

  // Convert dd-mm-yyyy to yyyy-mm-dd
  convertDateToYMD: (inputDate) => {
    if (!inputDate) {
      return;
    }
    // Split the input date string by "-"
    var parts = inputDate.split("-");
    // Rearrange the parts to change the format
    var outputDate = parts[2] + "-" + parts[1] + "-" + parts[0];

    return outputDate;
  },

  // Convert yyyy-mm-dd to dd-mm-yyyy
  convertDateToDMY: (inputDate) => {
    if (!inputDate) {
      return;
    }
    // Split the input date string by "-"
    var parts = inputDate?.split("-");
    // Rearrange the parts to change the format
    var outputDate = parts[2] + "-" + parts[1] + "-" + parts[0];

    return outputDate;
  },

  // check if doc name contains any special characters return true
  hasSpecialChars: (str) => {
    str = str?.replace(/\s+/g, "");
    const regex = /^[a-zA-Z0-9-_()]+(\.[a-zA-Z0-9-_()]*)?$/;
    return !regex.test(str);
  },

  nameRegex: (e) => {
    const newValue = e.target.value?.trimStart();
    const pattern = /^[A-Za-z][A-Za-z0-9\s]*$/; // Modified regex pattern
    let sanitizedValue = newValue?.replace(/[^a-zA-Z0-9\s]/g, "");
    sanitizedValue = sanitizedValue?.replace(/\s{2,}/g, " "); // Replace multiple spaces with a single space
    if (sanitizedValue.length > 90) {
      sanitizedValue = sanitizedValue?.slice(0, 90);
    }

    if (!pattern.test(sanitizedValue)) {
      // Updated condition for the new pattern
      e.preventDefault();
      e.target.value = sanitizedValue;
      return "";
    } else {
      return sanitizedValue;
    }
  },

  emailRegex: (e, fieldValue) => {
    const newValue = e.target.value?.trim();
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let sanitizedValue = newValue?.replace(/[^a-zA-Z0-9@_+.\-\s]/g, "");

    if (sanitizedValue.length > 90) {
      sanitizedValue = sanitizedValue?.slice(0, 90);
    }

    if (!pattern.test(sanitizedValue)) {
      e.preventDefault();
      e.target.value = sanitizedValue;
      return sanitizedValue;
    } else {
      return sanitizedValue;
    }
  },

  dateRegex: (e) => {
    const newValue = e.target.value?.trimStart();
    const datePattern = /^\d{4}-\d{2}-\d{2}$/; // Date regex pattern

    // Check if the input matches the date pattern
    if (datePattern.test(newValue)) {
      return newValue; // Return the value if it's a valid date
    } else {
      e.preventDefault();
      return "";
    }
  },

  validateText: (e) => {
    const newValue = e.target.value?.trimStart();
    const pattern = /^[A-Za-z]( ?[A-Za-z] ?)*$/;
    let sanitizedValue = newValue?.replace(/[^a-zA-Z\s]/g, "");
    sanitizedValue = sanitizedValue?.replace(/\s{2,}/g, " ");
    if (sanitizedValue.length > 255) {
      sanitizedValue = sanitizedValue.slice(0, 255);
    }

    if (!pattern.test(sanitizedValue)) {
      e.preventDefault();
      e.target.value = sanitizedValue;
      return;
    } else {
      return sanitizedValue;
    }
  },

  validateInputAlphaNumeric: (e) => {
    const newValue = e.target.value?.trimStart();
    const pattern = /^[A-Za-z0-9]*$/; // Updated pattern to allow only numbers, alphabets, or alphanumeric input
    let sanitizedValue = newValue?.replace(/[^a-zA-Z0-9]/g, ""); // Removed \s from the regex to disallow multiple spaces
    if (sanitizedValue.length > 255) {
      sanitizedValue = sanitizedValue.slice(0, 255);
    }

    if (newValue !== sanitizedValue) {
      e.target.value = sanitizedValue;
    }

    return sanitizedValue; // Always return sanitized value
  },

  validateName: (name) => {
    let obj = {
      valid: true,
      message: "",
    };

    let validName = /^[a-zA-Z][a-zA-Z0-9\s/]*$/;
    if (!name.match(validName) && name.length !== 0) {
      obj.valid = false;
      obj.message = "Name cannot start with a number or contain only numbers";
      return obj;
    }
    return obj;
  },

  validateAddress: (input) => {
    let obj = {
      valid: true,
      message: "",
    };

    if (input) {
      let atLeastOneCharacter = /^(?=.*[a-zA-Z]).+$/; // At least one character (non-numeric)

      if (!input.match(atLeastOneCharacter)) {
        obj.valid = false;
        obj.message = "Address must contain at least one character";
      }
    }

    return obj;
  },

  validateDate: (date) => {
    let obj = {
      valid: true,
      message: "",
    };

    let validName = /^\d{4}-\d{2}-\d{2}$/;
    if (!date.match(validName) && date.length !== 0) {
      obj.valid = false;
      obj.message = "Invalid date";
      return obj;
    }

    let currentDate = new Date();
    let inputDate = new Date(date);

    if (inputDate <= currentDate) {
      obj.valid = false;
      obj.message = "Date should be greater than the current date";
    }

    return obj;
  },

  validateEmail: (email) => {
    let obj = {
      valid: true,
      message: "",
    };

    let validEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email?.match(validEmail) && email?.length !== 0) {
      obj.valid = false;
      obj.message = "Your email is not valid";
      return obj;
    }
    return obj;
  },

  validatePan: (pan) => {
    let obj = {
      valid: true,
      message: "",
    };
    let validatePan = /^[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/;
    if (!pan.match(validatePan) && pan.length !== 0) {
      obj.valid = false;
      obj.message = "Your pan is not valid";
      return obj;
    }
    return obj;
  },

  validateCorporatePan: (pan) => {
    let obj = {
      valid: true,
      message: "",
    };
    let validateCorporatePan =
      /^[A-Z]{3}[ABCEFGHJLPT]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/;
    if (!pan.match(validateCorporatePan) && pan !== 0) {
      obj.valid = false;
      obj.message = "Your PAN is not valid";
      return obj;
    }
    return obj;
  },

  validateContact: (contact) => {
    let obj = {
      valid: true,
      message: "",
    };
    let validateContact = /^[6-9]{1}[0-9]{9}$/;
    if (!contact.match(validateContact) && contact?.length !== 0) {
      obj.valid = false;
      obj.message = "Your Contact is not valid";
      return obj;
    }
    return obj;
  },

  validateIFSC: (ifsc) => {
    let obj = {
      valid: true,
      message: "",
    };
    let validateIFSC = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    if (!ifsc.match(validateIFSC) && ifsc.length !== 0) {
      obj.valid = false;
      obj.message = "Your IFSC NUMBER  is not valid";
      return obj;
    }
    return obj;
  },

  validateCIN: (cin) => {
    let obj = {
      valid: true,
      message: "",
    };
    let validateCIN = /^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/;
    if (!cin.match(validateCIN) && cin.length !== 0) {
      obj.valid = false;
      obj.message = "Your CIN NUMBER  is not valid";
      return obj;
    }
    return obj;
  },

  validateAccount: (acc) => {
    let obj = {
      valid: true,
      message: "",
    };
    let validateAccount = /^[0-9]{9,18}$/;
    if (!acc?.match(validateAccount) && acc?.length !== 0) {
      obj.valid = false;
      obj.message = "Your Account number  is not valid";
      return obj;
    }
    return obj;
  },

  validateAadhaar: (aadhaar) => {
    let obj = {
      valid: true,
      message: "",
    };
    let validateAccount = /^[0-9]{12}$/;
    if (!aadhaar.match(validateAccount) && aadhaar.length !== 0) {
      obj.valid = false;
      obj.message = "Your Aadhaar number  is not valid";
      return obj;
    }
    return obj;
  },

  validateGST: (gst, pan) => {
    let obj = {
      valid: true,
      message: "",
    };
    let validateGST =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (
      (!gst.match(validateGST) && gst.length !== 0) ||
      pan !== gst.slice(2, 12)
    ) {
      obj.valid = false;
      obj.message = "Your GST number  is not valid";
      return obj;
    }
    return obj;
  },

  validateTan: (tan) => {
    let obj = {
      valid: true,
      message: "",
    };
    let validateTan = /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/;
    if (!tan.match(validateTan) && tan.length !== 0) {
      obj.valid = false;
      obj.message = "Your Tan NUMBER  is not valid";
      return obj;
    }
    return obj;
  },

  validatePinCode: (pinCode) => {
    let obj = {
      valid: true,
      message: "",
    };
    let validatePinCode = /^[0-9]{6}$/;
    if (!pinCode?.match(validatePinCode) && pinCode?.length !== 0) {
      obj.valid = false;
      obj.message = "PinCode is not valid";
      return obj;
    }
    return obj;
  },

  removeDecimal: (value) => {
    const newValue = value;
    const sanitizedValue = newValue.replace(/[.]/g, ""); // Remove decimal

    // Update the condition according to your validation requirements
    if (sanitizedValue !== newValue) {
      value = sanitizedValue;
      return sanitizedValue;
    } else {
      return sanitizedValue;
    }
  },

  getLocalTimezoneOffset: () => {
    // Get local timezone offset from UTC.
    return new Date().getTimezoneOffset();
  },

  getDateString: (date, fullDateString) => {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getYear() + 1900;
    let hh = date.getHours();
    let min = date.getMinutes();
    let ss = date.getSeconds();

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (hh < 10) {
      hh = "0" + hh;
    }
    if (min < 10) {
      min = "0" + min;
    }
    if (ss < 10) {
      ss = "0" + ss;
    }
    if (fullDateString) {
      return dd + "_" + mm + "_" + yyyy + "_" + hh + "_" + min + "_" + ss;
    }

    return dd + "-" + mm + "-" + yyyy + " " + hh + ":" + min;
  },

  convertToLocalDate: (utcDateString) => {
    const utcDate = new Date(utcDateString);
    const localDate = utcDate.toLocaleString();
    return localDate;
  },

  getLocalDate: (serverDate) => {
    // Get the server date as local formatted date.
    let localDate = new Date(serverDate["$date"]);
    return Utils.getDateString(localDate);
  },

  timeWithOffset: (timeInMinutes, offset) => {
    timeInMinutes = parseInt(timeInMinutes);
    offset = parseInt(offset);
    timeInMinutes += offset;
    if (timeInMinutes < 0) {
      timeInMinutes += 1440;
    }
    timeInMinutes %= 1440;

    return timeInMinutes;
  },

  convertTimeInMinutesToHHMM: (timeInMinutes) => {
    if (!timeInMinutes) return "00:00";

    let hours = parseInt(timeInMinutes / 60);
    let minutes = parseInt(timeInMinutes % 60);

    if (hours < 10) {
      hours = "0" + hours;
    }

    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    const timeInHHMM = hours + ":" + minutes;

    return timeInHHMM;
  },

  convertTimeInHHMMToMinutes: (timeInHHMM) => {
    if (!timeInHHMM || !timeInHHMM.includes(":")) {
      return "";
    }
    const array = timeInHHMM.split(":");
    let hours = array[0];
    let minutes = array[1];
    hours = parseInt(hours);
    minutes = parseInt(minutes);

    const timeInMinutes = hours * 60 + minutes;
    return timeInMinutes;
  },

  getLocalTime: (timeInMinutes) => {
    let offset = Utils.getLocalTimezoneOffset();
    const time = Utils.timeWithOffset(timeInMinutes, -offset);

    return Utils.convertTimeInMinutesToHHMM(time);
  },

  getUTCTime: (timeInHHMM) => {
    let timeInMinutes = Utils.convertTimeInHHMMToMinutes(timeInHHMM);
    let offset = Utils.getLocalTimezoneOffset();
    return Utils.timeWithOffset(timeInMinutes, offset);
  },

  // check if object containes value
  containsComponent: (uiFuctionObject, component) => {
    return uiFuctionObject.some((obj) =>
      obj.uifunctionName.includes(component)
    );
  },

  // back btn
  navigationBack: (location, navigate) => {
    navigate(-1);
    return;
  },

  // check if any key is empty
  findValueInObj: (obj, toFind) => {
    if (typeof obj[toFind] === "string") return obj[toFind];
    if (obj.hasOwnProperty(toFind)) {
      return obj[toFind].name;
    }

    for (let key in obj) {
      if (typeof obj[key] === "object") {
        const result = Utils.findValueInObj(obj[key], toFind);
        if (result) {
          return result;
        }
      }
    }
  },

  // convert server date to dd-mm-yy
  convertToLocalDateOnly: (dateTimeString) => {
    if (dateTimeString === "") return "";

    const date = new Date(dateTimeString);

    const day = date.getDate().toString().padStart(2, "0"); // Get day (1-31) and pad with leading zero if needed
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month (0-11) and add 1 to match the regular month (1-12) and pad with leading zero if needed
    const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of the year

    return `${day}/${month}/${year}`;
  },
  // convert server date to dd-mm-yy
  convertToLocalDateForInput: (dateTimeString) => {
    if (dateTimeString === "") return "";

    const date = new Date(dateTimeString);

    const day = date.getDate().toString().padStart(2, "0"); // Get day (1-31) and pad with leading zero if needed
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month (0-11) and add 1 to match the regular month (1-12) and pad with leading zero if needed
    const year = date.getFullYear().toString(); // Get last 2 digits of the year

    return `${year}-${month}-${day}`;
  },
  // convert server date to dd-mm-yyyy
  convertToLocalDateFullYearOnly: (dateTimeString) => {
    if (dateTimeString === "") return "";

    const date = new Date(dateTimeString);

    const day = date.getDate().toString().padStart(2, "0"); // Get day (1-31) and pad with leading zero if needed
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month (0-11) and add 1 to match the regular month (1-12) and pad with leading zero if needed
    const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of the year

    return `${day}/${month}/20${year}`;
  },

  //  short name or long string
  ShortLengthFunction: (text, len) => {
    if (typeof text == "string") {
      if (text.length > len) {
        return `${text.substring(0, len - 3)}...`;
      }
    }

    return text;
  },

  getTimeFromDateTime: (userDate) => {
    const date = new Date(userDate);
    const hour = ("0" + date.getHours()).slice(-2); // Ensure two digits, add leading zero if necessary
    const min = ("0" + date.getMinutes()).slice(-2); // Ensure two digits, add leading zero if necessary
    const sec = ("0" + date.getSeconds()).slice(-2); // Ensure two digits, add leading zero if necessary
    return `${hour}:${min}:${sec}`;
  },

  getTimeHHMMFromDateTime: (userDate) => {
    const date = new Date(userDate);
    let hour = date.getHours();
    let min = date.getMinutes().toString().padStart(2, "0");
    let timePeriod = hour > 12 ? "PM" : "AM";
    hour = hour > 12 ? hour - 12 : hour;
    hour = hour.toString().padStart(2, "0");
    return `${hour}:${min} ${timePeriod}`;
  },

  getDateFromDateTime: (userDate) => {
    const date = new Date(userDate);
    let currentDate = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    currentDate = currentDate < 10 ? `0${currentDate}` : currentDate;
    month = month < 10 ? `0${month}` : month;
    return `${currentDate}/${month}/${year}`;
  },

  // Prevent user from entering special characters
  PreventSpecialCharacter: (e) => {
    const newValue = e.target.value;
    const pattern = /^[a-zA-Z0-9\s]*$/;
    const sanitizedValue = newValue?.replace(/[^a-zA-Z0-9\s]/g, "");

    if (!pattern.test(sanitizedValue)) {
      e.preventDefault();
      e.target.value = sanitizedValue;
      return;
    } else {
      return sanitizedValue;
    }
  },

  PreventSpecialCharacterForEmail: (e) => {
    const newValue = e.target.value;
    // Pattern to allow characters typically found in email addresses, including +
    const pattern = /^[a-zA-Z0-9@._+-]*$/;
    const sanitizedValue = newValue?.replace(/[^a-zA-Z0-9@._+-]/g, "");

    if (!pattern.test(sanitizedValue)) {
      e.preventDefault();
      e.target.value = sanitizedValue;
      return;
    } else {
      return sanitizedValue;
    }
  },

  PreventSpecialCharacterForAddress: (e) => {
    const newValue = e.target.value;
    // Adjusted pattern to allow alphanumeric characters, single spaces, commas, and periods
    const pattern = /^[a-zA-Z0-9\s,.-]*$/;
    let sanitizedValue = newValue?.replace(/[^a-zA-Z0-9\s,.-]/g, "");

    // Remove extra spaces and ensure no leading space
    sanitizedValue = sanitizedValue.replace(/\s{2,}/g, " ").trimStart();

    if (sanitizedValue.length > 90) {
      sanitizedValue = sanitizedValue?.slice(0, 90);
    }

    if (!pattern.test(sanitizedValue)) {
      e.preventDefault();
      e.target.value = sanitizedValue;
      return;
    } else {
      return sanitizedValue;
    }
  },

  PreventSpecialCharacterForContact(e) {
    const newValue = e.target.value;
    const pattern = /^[0-9.]+$/; // Updated pattern to include .
    const sanitizedValue = newValue?.replace(/[^0-9.]+/g, ""); // Allow only numbers and .

    if (!pattern.test(sanitizedValue)) {
      e.preventDefault();
      e.target.value = sanitizedValue;
      return;
    } else {
      return sanitizedValue;
    }
  },

  PreventSpecialCharacterForNumber(e) {
    const newValue = e.target.value;
    const pattern = /^[0-9]+$/; // Pattern to allow only numbers
    const sanitizedValue = newValue?.replace(/[^0-9]+/g, ""); // Remove non-numeric characters

    if (!pattern.test(sanitizedValue)) {
      e.preventDefault();
      e.target.value = sanitizedValue;
      return;
    } else {
      return sanitizedValue;
    }
  },

  chargesRegex: (e) => {
    const newValue = e.target.value;
    const pattern = /^\d*\.?\d{0,2}$/; // Updated pattern to allow decimal values with up to two digits after the decimal point
    let sanitizedValue = newValue?.replace(/[^0-9.]/g, ""); // Allow only numbers and .

    // Ensure that there are at most two digits after the decimal point
    const decimalIndex = sanitizedValue.indexOf(".");
    if (decimalIndex !== -1) {
      const integerPart = sanitizedValue.slice(0, decimalIndex);
      const decimalPart = sanitizedValue.slice(
        decimalIndex + 1,
        decimalIndex + 3
      );
      sanitizedValue = integerPart + "." + decimalPart;
    }

    if (!pattern.test(sanitizedValue)) {
      e.preventDefault();
      e.target.value = sanitizedValue;
      return;
    } else {
      return sanitizedValue;
    }
  },

  // convert to camel case if have space
  convertToCamelCase: (input) => {
    return input
      .split(" ")
      .map((word, index) => {
        if (index === 0) {
          return word;
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join("");
  },

  formatSize: (sizeInBytes) => {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;

    if (sizeInBytes >= megabyte) {
      return (sizeInBytes / megabyte).toFixed(2) + " MB";
    } else if (sizeInBytes >= kilobyte) {
      return (sizeInBytes / kilobyte).toFixed(2) + " KB";
    } else {
      return sizeInBytes + " bytes";
    }
  },

  removeWhiteSpaceFromBetween: (text, trimCondition) => {
    var regexPattern = /\s+/g;
    var ans = text?.replace(regexPattern, " ");

    return !trimCondition ? ans.trim() : ans;
  },

  // check if user is admin or not
  isAdmin: (userDetails) => {
    // return userDetails?.isAdmin;
    return userDetails?.internalGroupRoles?.some(
      (internalGroupRoles) =>
        internalGroupRoles.id === Consts.INTERNAL_GRP_ROLE_IDS.ADMIN.id
    );
  },

  isCustomTypeUser: (userDetails, idArr) => {
    return userDetails?.internalGroupRoles?.some((data) =>
      idArr?.includes(data?.id)
    );
  },

  isCustomTypeUserEndsWith: (userDetails, checkString) => {
    return userDetails?.internalGroupRoles?.some((e) =>
      e.name?.endsWith(checkString)
    );
  },
  //  check if user has access to this function area
  hasCorporateFormFillUp: (userDetails) => {
    if (Utils.isAdmin(userDetails)) {
      return true;
    } else {
      return userDetails?.functionalAreas?.some(
        (functionalArea) =>
          functionalArea.id === Consts.FUNCTIONALITY_AREAS_ID.CORP_FORM_FILL_UP
      );
    }
  },

  //  check if user has access to this function area
  hasCorporateScrutiny: (userDetails) => {
    if (Utils.isAdmin(userDetails)) {
      return true;
    } else {
      return userDetails?.functionalAreas?.some(
        (functionalArea) =>
          functionalArea.id === Consts.FUNCTIONALITY_AREAS_ID.CORP_SCRUITNY
      );
    }
  },
  //  check if user has access to this function area
  hasChargeApproval: (userDetails) => {
    if (Utils.isAdmin(userDetails)) {
      return true;
    } else {
      return userDetails?.functionalAreas?.some(
        (functionalArea) =>
          functionalArea.id === Consts.FUNCTIONALITY_AREAS_ID.CHARGES_APPROVAL
      );
    }
  },

  //  check if user has access to this function area
  hasCorporateCho: (userDetails) => {
    if (Utils.isAdmin(userDetails)) {
      return true;
    } else {
      return userDetails?.functionalAreas?.some(
        (functionalArea) =>
          functionalArea.id === Consts.FUNCTIONALITY_AREAS_ID.CORP_CHO_UPDATE
      );
    }
  },

  // check if the user has access for checker
  hasMakerCheckerAccess: (userDetails) => {
    if (Utils.isAdmin(userDetails)) {
      return true;
    } else {
      return userDetails?.functionalAreas?.some(
        (functionalArea) =>
          functionalArea.id ===
          Consts.FUNCTIONALITY_AREAS_ID.MAKERCHECKER_CHECKER
      );
    }
  },

  // string space to special characters
  stringSpacetoSpecialChar: (text) => {
    return text.replaceAll(" ", "_");
  },

  partialHiddenContact: (num) => {
    let result = num.replace(/^.{6}/g, "xxxxxx");
    return result;
  },

  //  blob type api
  blobReader: (file) => {
    return new Promise(function (resolve, reject) {
      let reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (e) {
        reject("failed to load file");
      };
      reader.readAsText(file);
    });
  },

  validateRetirementAge: (age) => {
    let obj = {
      valid: true,
      message: "",
    };
    if (age != undefined && (age < 50 || age > 75)) {
      obj.valid = false;
      obj.message = "Retirement Age limit should be between 50 and 75";
      return obj;
    }
    return obj;
  },

  checkFunctionalAreas: (arr, userDetail) => {
    let functional_areas_obj = userDetail?.functionalAreas;
    if (functional_areas_obj != undefined) {
      let len = functional_areas_obj.length;
      for (let i = 0; i < len; i++) {
        if (arr.includes(functional_areas_obj[i].id)) {
          return true;
        }
      }
    }

    return false;
  },

  checkRoleFunction: (arr, userDetail) => {
    let flag = false;
    arr?.forEach((ele) => {
      if (typeof ele?.item === "string") {
        if (Utils.isCustomTypeUserEndsWith(userDetail, ele?.item)) {
          flag = true;
        }
      } else if (typeof ele?.item === "number") {
        if (
          Utils.isCustomTypeUser(userDetail, [
            userDetail?.internalGroupRoles[0]?.id,
          ])
        ) {
          flag = true;
        }
      }
    });
    return flag;
  },
  validatePassword: (password) => {
    let obj = {
      valid: true,
      message: "",
    };

    let validatePass =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{8,20}$/;
    if (!validatePass.test(password) && password?.length !== 0) {
      obj.valid = false;
      obj.message = "Your password is not valid";
    }

    return obj;
  },

  //  handle input length restrict to enter
  handleInputLengthFunction: (e, maxLength) => {
    const val = e?.target?.value;

    const prevValue = e.target.previousValue || "";
    if (val?.length > maxLength) {
      e.preventDefault();
      e.target.value = prevValue;
    }

    e.target.previousValue = e.target.value;
    return e?.target?.value;
  },

  handlePreventNumberFromDecimal: (inputValue) => {
    const sanitizedValue = inputValue?.replace(/[^\d]/g, "");
    return sanitizedValue;
  },

  handleDecimalCountFromNumInput: (e, count) => {
    const { key, target } = e;
    const { value } = target;
    const valueString = value?.toString();

    if (
      key === "Delete" ||
      key === "Backspace" ||
      key.startsWith("Arrow") ||
      key === "Tab"
    ) {
      return;
    }

    if (
      valueString.includes(".") &&
      valueString.split(".")[1]?.length >= count
    ) {
      e.preventDefault();
    } else if ((key >= "0" && key <= "9") || key === ".") {
      return;
    }

    e.preventDefault();
  },

  encryptedPassword: (password) => {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(process.env.REACT_APP_ENCRYPTED_PASSWORD_PUBLIC_KEY);
    const encryptedPassword = encrypt.encrypt(password);
    return encryptedPassword;
  },

  // In the encryptPayloadData and decryptPayloadData functions, we are using the AES-GCM (Advanced Encryption Standard in Galois/Counter Mode) encryption algorithm.

  encryptPayloadData: async (payload) => {
    // Convert the payload to a JSON string and then to a Uint8Array
    const encoder = new TextEncoder();
    const data = encoder.encode(JSON.stringify(payload));

    // Decode the secret key from Base64
    const secretKeyBase64 = process.env.REACT_APP_SECRET_KEY;
    const secretKeyBytes = Uint8Array.from(atob(secretKeyBase64), (c) =>
      c.charCodeAt(0)
    );

    // Import the key
    const key = await crypto.subtle.importKey(
      "raw",
      secretKeyBytes,
      "AES-GCM",
      true,
      ["encrypt"]
    );

    // Generate a random IV
    const iv = crypto.getRandomValues(new Uint8Array(12));

    // Encrypt the data
    const encryptedBuffer = await crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      key,
      data
    );

    // Convert encrypted buffer to Base64
    const encryptedArray = new Uint8Array(encryptedBuffer);
    const encryptedBase64 = btoa(String.fromCharCode(...iv, ...encryptedArray));

    // Return the encrypted payload
    return { enc: encryptedBase64 };
  },

  decryptPayloadData: async (encryptedPayload) => {
    try {
      // Check if encryptedPayload is a string
      if (typeof encryptedPayload !== "string") {
        throw new Error("Encrypted payload is missing or is not a string");
      }

      // Ensure the Base64 string is correctly padded
      const base64Padded = encryptedPayload.padEnd(
        encryptedPayload.length + ((4 - (encryptedPayload.length % 4)) % 4),
        "="
      );

      // Convert Base64 string to a Uint8Array
      const encryptedBytes = Uint8Array.from(atob(base64Padded), (c) =>
        c.charCodeAt(0)
      );

      // Extract the IV and encrypted data
      const iv = encryptedBytes.slice(0, 12); // IV is the first 12 bytes
      const encryptedData = encryptedBytes.slice(12);

      // Decode the secret key from Base64
      const secretKeyBase64 = process.env.REACT_APP_SECRET_KEY;
      const secretKeyBytes = Uint8Array.from(
        atob(
          secretKeyBase64.padEnd(
            secretKeyBase64.length + ((4 - (secretKeyBase64.length % 4)) % 4),
            "="
          )
        ),
        (c) => c.charCodeAt(0)
      );

      // Import the key
      const key = await crypto.subtle.importKey(
        "raw",
        secretKeyBytes,
        "AES-GCM",
        true,
        ["decrypt"]
      );

      // Decrypt the data
      const decryptedBuffer = await crypto.subtle.decrypt(
        { name: "AES-GCM", iv },
        key,
        encryptedData
      );
      // Convert decrypted buffer to a string and parse JSON
      return JSON.parse(new TextDecoder().decode(decryptedBuffer));
    } catch (error) {
      throw error;
    }
  },
};

export default Utils;
