import React, { useState } from "react";
import classes from "./UpdateServiceRequest.module.css";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import UploadDocument from "../../../components/UploadDocument/UploadDocument";
import Consts from "../../../Consts";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setAlertMsg } from "../../Home/HomeSlice";
import info_icon from "../../../assets/info_Icon.svg";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import bagIcon from "../../../assets/Bag_icon.svg";
import openLockIcon from "../../../assets/openLock_icon.svg";
import closeLockIcon from "../../../assets/closeLock_icon.svg";
import fileIcon from "../../../assets/file_icon2.svg";
import locationIcon from "../../../assets/location_icon.svg";
import userIcon from "../../../assets/user_icon.svg";
import calenderIcon from "../../../assets/CalanderIcon.svg";
import chairIcon from "../../../assets/chair_icon.svg";
import phoneIcon from "../../../assets/phone_icon.svg";

const UpdateServiceRequest = () => {
  const { srUpdateFormData } = useSelector((state) => state);
  const { t } = useTranslation();
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [currDoc, setCurrDoc] = useState("");

  // profileObject
  const profileObj = [
    {
      profileInfo: [
        {
          label: "Corporate Name ",
          value: srUpdateFormData?.corpData?.corpName,
        },
        { label: "CHO Number ", value: srUpdateFormData?.corpData?.choNumber },
        {
          label: "NodalOfficer ",
          value: srUpdateFormData.corpFormData?.noFirstName,
        },
        {
          label: "Acknowledgement ID ",
          value: srUpdateFormData?.corpData?.ackId,
        },
      ],
      cardsLabel: [
        {
          label: "CBO Creation",
          img: bagIcon,
        },
        {
          label: "Password",
          img: openLockIcon,
        },
        {
          label: "Update Corporate",
          img: fileIcon,
        },
        {
          label: "Change  Corporate Name / Address",
          img: locationIcon,
        },
        {
          label: "Change Nodal Officer Details",
          img: userIcon,
        },
      ],
    },
    {
      profileInfo: [
        { label: "Name", value: "Shivam Gupta" },
        { label: "Corporate Name", value: "Genpact" },
        { label: "CHO Number", value: "123456" },
        { label: "PRAN", value: "9293239" },
      ],

      cardsLabel: [
        {
          label: "Date of joining",
          img: calenderIcon,
        },
        {
          label: "Date of Retirement",
          img: chairIcon,
        },
        {
          label: "Employment ID",
          img: userIcon,
        },
        {
          label: "Change Employee Contact",
          img: phoneIcon,
        },
      ],
    },
  ];

  const fileUpdateFunction = (e) => {
    var formData = new FormData();
    formData.append("file", e);
  };

  // get upload doc info
  const handleClicked = (label) => {
    setIsUploadModal(true);
    setCurrDoc(label.label);
  };

  return (
    <div className={classes.sr_container}>
      <div className={classes.GoBackButton}>
        <GoBackButton />
      </div>
      <div className={classes.request_info}>
        <h5>Corporate Service Request</h5>
        <h6> Your Acknowledgement ID : 123456 </h6>
      </div>

      <div className={classes.sr_info_box}>
        <div className={classes.sr_info}>
          {profileObj[0].profileInfo.map((info, index) => {
            return (
              <div key={index} className={classes.sr_info_list}>
                <h5>{info.label} : </h5>
                <h6>{info.value}</h6>
              </div>
            );
          })}
        </div>
        <div className={classes.cards_container}>
          <div className={classes.card_container_info}>
            <h5>Update Information</h5>
            <h6>
              Select A Service You want to Modify and if you're making changes ,
              have your documents ready with you,
            </h6>
          </div>
          <div className={classes.cards_list}>
            {profileObj[0].cardsLabel.map((label, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleClicked(label)}
                  className={classes.card}
                >
                  <img alt={label.label} src={label.img} />
                  <h5> {label.label} </h5>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.btn}>
          <SecondaryButton label={t("button.cancel")} />
          <SecondaryButton label={t("button.save")} />
          <PrimaryButton label={"Proceed"} />
        </div>
      </div>
      {isUploadModal && (
        <UploadDocument
          isModalOpen={isUploadModal}
          setIsModalOpen={setIsUploadModal}
          acceptedFileType={Consts.ACCEPTED_FILE_TYPE_CONTRIBUTION}
          uploadedDocuments={fileUpdateFunction}
          //   InnerComponent={UploadModalHelpingComponent}
        >
          <div className={classes.upload_info_container}>
            <h5> Update Name </h5>
            <h6> Enter updated name and upload document proof </h6>
            <div>
              <label> {currDoc && currDoc} </label>
              <input type="text" />
              <p>write name as per official document </p>
            </div>
          </div>
        </UploadDocument>
      )}
    </div>
  );
};

export default UpdateServiceRequest;
