import axios from "axios";

export function getFunctionalityDesignation() {
  return axios({
    url: `/hpmc/api/authz/functionalareas`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// get roles from selecting groups
export function getRolesViaGroup(id) {
  return axios({
    url: `/hpmc/api/groups/roles/${id}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getAllGroups(data) {
  data = data === undefined ? true : false;
  return axios({
    url: `/hpmc/api/groups?getall=${data}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getAllUsers(data) {
  return axios({
    url: `/hpmc/api/usermanagement/users?sortBy=${data?.accessor}&orderBy=${data?.sort}&pageSize=${data?.perPage}&pageNumber=${data?.page}&query=${data?.searchInput}&filterBy=${data?.filterBy}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getAllRMUser(data) {
  return axios({
    url: `/hpmc/api/usermanagement/allusers`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function downloadAllUsers(data) {
  let url = `/hpmc/api/usermanagement/users?sortBy=${data?.accessor}&orderBy=${data?.sort}&pageSize=${data?.perPage}&pageNumber=${data?.page}&query=${data?.searchInput}&filterBy=${data?.filterBy}&exportCsv=1`;
  window.open(url, "_blank", "noopener,noreferrer");
}

export function getUserById(id) {
  return axios({
    url: `/hpmc/api/usermanagement/${id}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function functionalareasByGrpRole(group, role) {
  return axios({
    url: `/hpmc/api/authz/internalgrouproles/${group}/${role}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function createUser(data) {
  return axios({
    url: `/hpmc/api/usermanagement/writeuser`,
    method: "post",
    responseType: "json",
    data: data,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function downloadSampleFileUserManagement() {
  let url = `/hpmc/api/usermanagement/bulkimport/sample`;
  window.open(url, "_blank", "noopener,noreferrer");
}

// import file in master management
export function importBulkUsers(fileObj) {
  return axios({
    url: `/hpmc/api/usermanagement/bulkimport`,
    method: "post",
    responseType: "json",
    data: fileObj,
  });
}
