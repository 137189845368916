import React, { memo, useEffect, useState } from "react";
import Classes from "./EditUser.module.css";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import GoBackButton from "../../../../components/Buttons/GoBackButton";
import SwitchToggle from "../../../../components/SwitchToggle/SwitchToggle";
import infoIcon from "../../../../assets/info-blue-icon.svg";
import {
  createUser,
  functionalareasByGrpRole,
  getAllGroups,
  getAllUsers,
  getFunctionalityDesignation,
  getRolesViaGroup,
  getUserById,
} from "../UserManagementActions";
import Utils from "../../../../Utils";
import { setAlertMsg } from "../../../Home/HomeSlice";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import {
  ADMIN_URL,
  BASE_URL,
  USER_MANAGEMENT_DASHBOARD_URL,
} from "../../../../Routes/Urls";
import { useTranslation } from "react-i18next";
import Consts from "../../../../Consts";
const numberInputInvalidChars = ["-", "+", "e", "."];
const EditUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = JSON.parse(localStorage.getItem("userManagement_userId"));
  const [functionalityDesignation, setFunctionalityDesignation] = useState([]);

  const [grpId, setGrpId] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [functionalityAreasBox, setFunctionalityAreasBox] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [staticDetails, setStaticDetails] = useState({});
  const [isOnLoadVal, setIsOnLoadVal] = useState(true);
  const [canLogin, setCanLogin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [disabledStatus, setDisabledStatus] = useState(true);
  const [showValidationMsg, setShowValidationMsg] = useState({});
  // basic information form elements
  const [basicInfoFormElem, setBasicInfoFormElem] = useState([
    {
      title: "Basic Information",
      fieldsType: "array",
      showUserType: true,
      fields: [
        {
          tag: "input",
          type: "text",
          label: "Name",
          name: "fullName",
          placeholder: "Enter username",
          mandatory: true,
          disabled: true,
          autoComplete: "off",
        },
        {
          tag: "select",
          type: "text",
          label: "Status of the Employee",
          name: "canLogin",
          status: false,
          options: ["Inactive", "Active"],
          placeholder: "Ex - ABCD",
          mandatory: false,
          disabled: false,
        },
        {
          tag: "input",
          type: "email",
          label: "Email Id",
          name: "username",
          placeholder: "Ex abc@gmail.com",
          mandatory: true,
          disabled: true,
          autoComplete: "off",
        },
        {
          tag: "input",
          type: "text",
          label: "Employee Code ",
          name: "employeeCode",
          placeholder: "Ex. 1234",
          mandatory: true,
          disabled: true,
          autoComplete: "off",
        },
        {
          tag: "input",
          type: "number",
          name: "contactNo",
          label: "Contact No ",
          placeholder: "Ex. 9999933399",
          mandatory: true,
          disabled: true,
          autoComplete: "off",
        },
      ],
    },
    {
      title: "Location",
      fieldsType: "array",
      showUserType: false,
      fields: [
        {
          tag: "input",
          type: "text",
          label: "Zone",
          name: "zone",
          status: false,
          placeholder: "Ex - North",
          mandatory: false,
          disabled: true,
          autoComplete: "off",
        },
        {
          tag: "input",
          type: "text",
          label: "Region",
          name: "region",
          status: false,
          placeholder: "Ex - Delhi",
          mandatory: false,
          disabled: true,
          autoComplete: "off",
        },
        {
          tag: "input",
          type: "text",
          label: "Location",
          name: "location",
          status: false,
          placeholder: "Ex - Delhi - new Delhi",
          mandatory: false,
          disabled: true,
          autoComplete: "off",
        },
      ],
    },
    {
      title: "Department And Designation",
      fieldsType: "object",
      showUserType: false,
      fields: {},
    },
    {
      title: "Password",
      fieldsType: "array",
      showUserType: false,
      fields: [
        {
          tag: "input",
          label: "Enter Password",
          type: "password",
          name: "password",
          placeholder: "Ex - 123",
          bottomHint: "Must contain 8 characters",
          disabled: false,
          autoComplete: "new-password",
        },
        {
          tag: "input",
          label: "Confirm Password",
          type: "password",
          name: "confirm_password",
          placeholder: "Ex - 123",
          bottomHint: "Must contain 8 characters",
          autoComplete: "new-password",
          disabled: false,
        },
      ],
    },
  ]);

  // department form elements
  const [deptFormElem, setDeptFormElem] = useState({
    department: {
      label: "Department",
      name: "department",
      options: [],
      optionName: "groupName",
    },

    designation: {
      label: "Designation",
      name: "designation",
      options: [],
      optionName: "roleName",
    },
  });

  const [mandatoryFields, setMandatoryFields] = useState([
    {
      name: "fullName",
      errorText: "Name",
    },
    {
      name: "username",
      errorText: "Email",
    },
    {
      name: "employeeCode",
      errorText: "Employee Code",
    },
    {
      name: "contactNo",
      errorText: "Contact No",
    },
  ]);

  //  password hint type
  const [passwordInstruct, setPasswordInstruct] = useState([
    "Include both lower and upper case characters",
    "Include at least one number",
    "Be at least 8 characters long",
    "Include at least one special character [@#$%^&+=]",
  ]);

  useEffect(() => {
    getUserByIdFunction();
    setIsOnLoadVal(true);
  }, []);

  const getDefaultedInternalGrpRole = (data) => {
    let output = data.find(
      (data) => data.id != Consts.INTERNAL_GRP_ROLE_IDS.ADMIN.id
    );
    return output?.id;
  };

  const getUserByIdFunction = () => {
    getUserById(userId).then((res) => {
      let internalGroupRoleIdObj = {
        internalGroupRoleId: getDefaultedInternalGrpRole(
          res?.data?.data?.internalGroupRoles
        ),
      };
      let resData = res.data?.data;
      let mergedData = { ...internalGroupRoleIdObj, ...resData };
      setUserDetails(mergedData);

      // let empStatus = res.data.data.employeeStatus === "Active" ? true : false;
      let empLogin = res.data.data.canLogin;
      let isUserTypeAdmin = res.data.data?.isAdmin;
      let newArr = res.data.data.functionalAreas?.map((e) => e.id);
      setIsAdmin(isUserTypeAdmin);
      setFunctionalityAreasBox(newArr);
      let staticMergedData = { functionalAreaBox: newArr, ...mergedData };
      setStaticDetails(staticMergedData);

      setCanLogin(empLogin);

      let grp = res.data.data.internalGroupRoles[0]?.group?.id;
      setGrpId(grp);
      let roleIdDefault = res.data.data.internalGroupRoles[0]?.role?.id;
      setRoleId(roleIdDefault);
    });
  };

  useEffect(() => {
    getFunctionalityDesignation()
      .then((res) => {
        setFunctionalityDesignation(res.data.data);
      })
      .catch((err) => {});
    getDepartment();
  }, []);

  // fetch department
  const getDepartment = () => {
    getAllGroups(false).then((res) => {
      let result = res?.data?.data;
      setDeptFormElem((prevState) => ({
        ...prevState,
        department: {
          ...prevState.department,
          options: result,
        },
      }));
    });
  };

  // get designations by group
  const getDesignation = (id) => {
    if (id === null || id === "" || id === undefined) {
      return;
    }
    getRolesViaGroup(id).then((res) => {
      let result = res?.data?.data;
      setDeptFormElem((prevState) => ({
        ...prevState,
        designation: {
          ...prevState.designation,
          options: result,
        },
      }));
    });
  };

  useEffect(() => {
    if (roleId != null && isOnLoadVal === false) {
      getFunctionalArea(grpId, roleId);
    }
  }, [roleId]);

  // get functional areas by groupId and userId
  const getFunctionalArea = (group, role) => {
    functionalareasByGrpRole(group, role)
      .then((res) => {
        if (res.data?.success) {
          let resData = res.data?.data;
          setUserDetails((e) => ({
            ...e,
            internalGroupRoleId: resData?.id,
          }));
          let newArr = resData?.functionalAreas?.map((e) => e.id);
          setFunctionalityAreasBox(newArr);
        } else {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.internal_server_error"),
              alertType: "danger",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.internal_server_error"),
            alertType: "danger",
          })
        );
      });
  };

  // select department and designation
  const deptDesgFunction = (e) => {
    if (e.target.name === deptFormElem.department.name) {
      setFunctionalityAreasBox([]);
      setIsOnLoadVal(false);
      document.getElementById(`${deptFormElem.designation.name}`).value = "";
      setGrpId(e.target.value);
    } else if (e.target.name === deptFormElem.designation.name) {
      setRoleId(e.target.value);
    }
  };

  useEffect(() => {
    getDesignation(grpId);
  }, [grpId]);

  // check uncheck designation
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    let val = Number(name);

    setFunctionalityAreasBox((prevFunctionalityAreasBox) => {
      prevFunctionalityAreasBox = prevFunctionalityAreasBox || [];
      if (checked) {
        if (!prevFunctionalityAreasBox?.includes(val)) {
          return [...prevFunctionalityAreasBox, val];
        }
      } else {
        return prevFunctionalityAreasBox.filter((item) => item !== val);
      }
      return prevFunctionalityAreasBox;
    });
  };

  // check the functionality of check uncheck
  const checkedFunctionality = (e) => {
    if (functionalityAreasBox.length > 0) {
      let res = functionalityAreasBox.includes(e);
      if (res) {
        return true;
      }
      return false;
    }
    return false;
  };

  const encryptedPassword = (pass) => {
    if (pass === undefined || pass === null || pass === "") {
      return null;
    }
    return Utils.encryptedPassword(userDetails.password);
  };

  // on submit the form
  const handleSubmitForm = () => {
    if (checkValidation() === false) {
      return false;
    }

    if (userDetails.password != userDetails.confirm_password) {
      dispatch(
        setAlertMsg({
          msg: "Password does not match",
          alertType: "danger",
        })
      );
      return;
    }
    let submitData = {
      id: userId,
      employeeCode: userDetails.employeeCode,
      fullName: userDetails.fullName,
      band: "",
      zone: userDetails.zone,
      region: userDetails.region,
      location: userDetails.location,
      internalGroupRoleId: userDetails.internalGroupRoleId,
      immediateManagerId: null,
      fls: "NFLS",
      username: userDetails.email,
      password: encryptedPassword(userDetails.password),
      contactNo: userDetails.phone,
      employeeType: "Manager",
      lwd: null,
      functionalAreaIds: functionalityAreasBox,
      canLogin: canLogin,
      employeeStatus: userDetails.employeeStatus,
      isAdmin: isAdmin,
      contactNo: userDetails.contactNo,
      isHpmcUser: userDetails?.isHpmcUser,
    };
    createUser(submitData)
      .then((res) => {
        if (res.data?.success) {
          dispatch(
            setAlertMsg({
              msg: res.data.message,
              alertType: "success",
            })
          );
          redirectToDashboard();
        } else {
          const { success, message } = res?.response?.data || {};

          const alertMsg = {
            msg: success ? t("alertMsg.internal_server_error") : message,
            alertType: "danger",
          };

          dispatch(setAlertMsg(alertMsg));
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.internal_server_error"),
            alertType: "danger",
          })
        );
      });
  };

  // filter values  (special Characters)
  const filterUserData = (name, e) => {
    switch (name) {
      case "fullName":
        return Utils.nameRegex(e);
      case "email":
        return e.target.value?.length <= 90
          ? Utils.emailRegex(e)
          : userDetails[name];
      case "contactNo":
        return Utils.handleInputLengthFunction(e, 10);
      case "employeeCode":
        return Utils.handleInputLengthFunction(e, 16);

      case "zone":
      case "location":
      case "region":
        return Utils.PreventSpecialCharacterForAddress(e);
      case "password":
      case "confirm_password":
        return Utils.handleInputLengthFunction(e, 20);
      default:
        return e.target.value;
    }
  };

  const setErrorMsgsFunction = (name, val) => {
    const validationFunctions = {
      contactNo: Utils.validateContact,
      email: Utils.validateEmail,
      password: Utils.validatePassword,
    };

    if (validationFunctions.hasOwnProperty(name)) {
      const validate = validationFunctions[name];
      const errorMsg = validate(val);
      if (errorMsg && errorMsg?.valid === false) {
        setShowValidationMsg((prevState) => ({
          ...prevState,
          [name]: errorMsg?.message,
        }));
      } else {
        setShowValidationMsg((prevFormData) => {
          const { [name]: valueToRemove, ...remainingFormData } = prevFormData;
          return remainingFormData;
        });
      }
    }
  };

  const handleChangeUserInfo = (e) => {
    const { name, value } = e.target;
    let newVal = filterUserData(name, e);
    setErrorMsgsFunction(name, newVal);
    setUserDetails((data) => {
      if (value) {
        return {
          ...data,
          [name]: newVal,
        };
      } else {
        const { [name]: removedObjData, ...newObjData } = data;
        return newObjData;
      }
    });

    setUserInfo((data) => {
      if (
        staticDetails[name] != newVal &&
        ((name === "password" && newVal != "") ||
          (name === "confirm_password" && newVal != ""))
      ) {
        return {
          ...data,
          [name]: newVal,
        };
      } else if (staticDetails[name] != newVal && name != "password") {
        return {
          ...data,
          [name]: newVal,
        };
      } else {
        const { [name]: removedObjData, ...newObjData } = data;
        return newObjData;
      }
    });
  };

  const checkValidation = () => {
    for (let i = 0; i < mandatoryFields.length; i++) {
      const data = mandatoryFields[i];
      if (userDetails[data.name] === undefined) {
        dispatch(
          setAlertMsg({
            msg: `${data.errorText} cannot be empty `,
            alertType: "danger",
          })
        );
        return false;
      } else if (
        data?.name === "email" &&
        !Utils.validateEmail(userDetails[data.name]).valid
      ) {
        dispatch(
          setAlertMsg({
            msg: Utils.validateEmail(userDetails[data.name])?.message,
            alertType: "danger",
          })
        );
        return false;
      } else if (
        data?.name === "contactNo" &&
        !Utils.validateContact(userDetails[data.name]).valid
      ) {
        dispatch(
          setAlertMsg({
            msg: Utils.validateContact(userDetails[data.name])?.message,
            alertType: "danger",
          })
        );
        return false;
      }
    }
    if (grpId === null || grpId === "") {
      dispatch(
        setAlertMsg({
          msg: `Department cannot be empty `,
          alertType: "danger",
        })
      );
      return false;
    } else if (roleId === null || roleId === "") {
      dispatch(
        setAlertMsg({
          msg: `Designation cannot be empty `,
          alertType: "danger",
        })
      );
      return false;
    }
  };

  useEffect(() => {
    checkUpdateDisabled();
  }, [userInfo, functionalityAreasBox, isAdmin, canLogin]);

  const checkFunctionalAreaSameorNot = () => {
    let a = functionalityAreasBox;
    let b = staticDetails.functionalAreaBox;

    let aLen = a?.length;
    let bLen = b?.length;
    if (aLen != bLen) {
      return true;
    }
    if (aLen > 0) {
      a = a?.sort();
      b = b?.sort();

      if (a?.some((data, index) => data != b[index])) {
        return true;
      }
    }
    return false;
  };

  const checkUpdateDisabled = () => {
    if (
      Object.keys(userInfo)?.length > 0 ||
      checkFunctionalAreaSameorNot() ||
      userDetails.internalGroupRoleId != userDetails.internalGroupRoleId ||
      isAdmin != staticDetails["isAdmin"] ||
      canLogin != staticDetails["canLogin"]
    ) {
      setDisabledStatus(false);
    } else {
      setDisabledStatus(true);
    }
  };

  const groupActiveOrNotFunction = (status) => {
    setCanLogin(!status);
  };

  const defaultDeptDesgFunction = (val) => {
    if (isOnLoadVal) {
      if (
        userDetails.internalGroupRoles != undefined &&
        userDetails.internalGroupRoles.length > 0
      ) {
        if (val === deptFormElem.department.name) {
          return Number(userDetails?.internalGroupRoles[0]?.group.id);
        } else if (val === deptFormElem.designation.name) {
          return Number(userDetails?.internalGroupRoles[0]?.role.id);
        }
      }
    } else {
      if (val === deptFormElem.department.name) {
        return Number(grpId);
      } else if (val === deptFormElem.designation.name) {
        return Number(roleId);
      }
    }

    return 0;
  };

  // mandatory disabled
  const mandatoryDisabled = (e) => {
    switch (e) {
      case "username":
        return true;
        break;
      case "employeeCode":
        return true;
        break;
    }
  };

  // redirect to dashboard
  const redirectToDashboard = () => {
    navigate(`/${BASE_URL}/${ADMIN_URL}/${USER_MANAGEMENT_DASHBOARD_URL}`);
  };

  //  show validation issue
  const showValidationError = (e) => {
    return (
      showValidationMsg[e] && (
        <span className={Classes.err_msg}>{showValidationMsg[e]}</span>
      )
    );
  };
  return (
    <section>
      <div className="contain-fluid px-5 py-3">
        <GoBackButton />

        <div className="col-lg-12 mb-4 px-0">
          <div className="d-flex align-items-center justify-content-between">
            <div className={Classes.page_head}>
              <h5>Edit User </h5>
              <p className="mt-2 pb-2">Here you can edit user information</p>
            </div>
            {/* {!userDetails.isHpmcUser && <DangerOutlineButton label={"Delete User"}  />} */}
          </div>
        </div>
        <div className={`card border-0 ${Classes.radius_10}`}>
          <div className="card-body p-lg-5 p-3">
            {basicInfoFormElem &&
              basicInfoFormElem.map((data, index) => {
                return (
                  <div key={index}>
                    <div className="row mb-4" id={Classes.select_box}>
                      <div className="col-lg-12 mb-4">
                        <div className={Classes.page_form_title}>
                          <h5>
                            {data.title}{" "}
                            {index === 3 && (
                              <div className={Classes.passwordInstructions}>
                                <img src={infoIcon} />
                                <div className={Classes.showInstruction}>
                                  <ul>
                                    {passwordInstruct &&
                                      passwordInstruct?.map(
                                        (passData, passIndex) => {
                                          return (
                                            <li key={passIndex}>{passData}</li>
                                          );
                                        }
                                      )}
                                  </ul>
                                </div>
                              </div>
                            )}
                          </h5>
                          {data.showUserType && (
                            <div
                              className={`${Classes.userType} ${
                                userDetails.isHpmcUser
                                  ? Classes.hpmcUser
                                  : Classes.nonHpmcUser
                              }`}
                            >
                              {" "}
                              For{" "}
                              {userDetails.isHpmcUser
                                ? "HPMC "
                                : "non HPMC "}{" "}
                              user
                            </div>
                          )}
                        </div>
                      </div>
                      {data.fieldsType === "array" &&
                        data?.fields &&
                        data.fields.map((fieldsData, index) => {
                          return (
                            <div
                              className="col-lg-5 col-md-6 col-12 mb-4"
                              key={index}
                            >
                              <div className="input_group">
                                <label
                                  className={Classes.form_label}
                                  htmlFor="group"
                                >
                                  {fieldsData.label}
                                  {fieldsData.mandatory && (
                                    <span className={Classes.mandatory_css}>
                                      *
                                    </span>
                                  )}
                                </label>
                                {fieldsData.tag === "input" && (
                                  <input
                                    onKeyDown={(e) => {
                                      if (
                                        fieldsData.type === "number" &&
                                        numberInputInvalidChars.includes(e.key)
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    type={fieldsData.type}
                                    name={fieldsData.name}
                                    placeholder={fieldsData?.placeholder}
                                    value={userDetails[fieldsData.name]}
                                    onInput={(e) => handleChangeUserInfo(e)}
                                    disabled={
                                      (userDetails?.isHpmcUser &&
                                        fieldsData.disabled) ||
                                      mandatoryDisabled(fieldsData.name)
                                    }
                                    className="form-control"
                                    autoComplete={fieldsData?.autoComplete}
                                  />
                                )}
                                {fieldsData?.bottomHint && (
                                  <span className={Classes.bottomHint}>
                                    {fieldsData.bottomHint}
                                  </span>
                                )}
                                {fieldsData?.tag === "select" && (
                                  <>
                                    <div
                                      className={Classes.employeeStatusClass}
                                      key={index}
                                    >
                                      <span
                                        className={
                                          canLogin
                                            ? Classes.inactiveStatus
                                            : Classes.activeStatus
                                        }
                                      >
                                        {fieldsData.options[0]}
                                      </span>
                                      <SwitchToggle
                                        bgColor={"var(--app-btn-background)"}
                                        status={canLogin}
                                        otherFunction={() =>
                                          groupActiveOrNotFunction(canLogin)
                                        }
                                      />
                                      <span
                                        className={
                                          canLogin
                                            ? Classes.activeStatus
                                            : Classes.inactiveStatus
                                        }
                                      >
                                        {fieldsData.options[1]}
                                      </span>
                                    </div>
                                  </>
                                )}
                                {showValidationError(fieldsData?.name)}
                              </div>
                            </div>
                          );
                        })}

                      {data?.fieldsType === "object" &&
                        deptFormElem &&
                        Object.keys(deptFormElem).map((data, index) => {
                          return (
                            <div
                              className="col-lg-5 col-md-6 col-12 mb-3"
                              key={index}
                            >
                              <div className="input_group">
                                <label htmlFor="group">
                                  {deptFormElem[data]?.label}
                                  <span className={Classes.mandatory_css}>
                                    *
                                  </span>
                                </label>
                                <select
                                  name={deptFormElem[data].name}
                                  className="form-control form-select"
                                  id={deptFormElem[data].name}
                                  value={defaultDeptDesgFunction(
                                    deptFormElem[data].name
                                  )}
                                  onChange={(e) => deptDesgFunction(e)}
                                  disabled={
                                    userDetails?.isHpmcUser || data.disabled
                                  }
                                >
                                  <option
                                    value=""
                                    disabled={
                                      deptFormElem[data].name === "department"
                                    }
                                  >
                                    Select {deptFormElem[data]?.label}
                                  </option>
                                  {deptFormElem[data]?.options &&
                                    deptFormElem[data]?.options.map(
                                      (subData, subIndex) => {
                                        return (
                                          <option
                                            value={subData?.id}
                                            key={subIndex}
                                          >
                                            {
                                              subData[
                                                deptFormElem[data]?.optionName
                                              ]
                                            }
                                          </option>
                                        );
                                      }
                                    )}
                                </select>
                              </div>
                            </div>
                          );
                        })}

                      {}
                    </div>

                    {data.showUserType && (
                      <div className={`row mb-4 ${Classes.list_checkbox} `}>
                        <div className="col-lg-4 col-md-6 col-12 mb-4">
                          <div className={`form-check px-0`}>
                            <input
                              className={`check_input mr-3 ${Classes.pointer}`}
                              type="checkbox"
                              checked={isAdmin}
                              name={"isAdmin"}
                              id="isAdmin"
                              value={isAdmin}
                              onChange={(e) => setIsAdmin(!isAdmin)}
                            />
                            <label
                              className={`form-check-label ${Classes.pointer} ${Classes.admin_check_label}`}
                              htmlFor={"isAdmin"}
                            >
                              Is user type Admin ?
                            </label>
                          </div>
                        </div>
                      </div>
                    )}

                    {data.fieldsType === "object" && (
                      <>
                        <div className="row pt-2 pb-4">
                          <div className="col-lg-12">
                            <div className={Classes.list_head}>
                              <h4>Functionality for Designation</h4>
                              <p>
                                (You can add / remove functionality for this
                                user)
                                <span>
                                  ({functionalityAreasBox?.length} selected)
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className={"row mb-4" + " " + Classes.list_checkbox}
                        >
                          {functionalityDesignation &&
                            functionalityDesignation?.map((data, sub_index) => {
                              return (
                                <div
                                  className="col-lg-4 col-md-6 col-12 mb-4"
                                  key={sub_index}
                                >
                                  <div className={`form-check `}>
                                    <input
                                      className={`check_input ${Classes.pointer}`}
                                      type="checkbox"
                                      checked={checkedFunctionality(data.id)}
                                      name={data.id}
                                      // checked={checkboxes.checkbox1}
                                      onChange={handleCheckboxChange}
                                      id={Utils.stringSpacetoSpecialChar(
                                        data.uiString
                                      )}
                                    />
                                    <label
                                      className={`form-check-label ${Classes.pointer}`}
                                      htmlFor={Utils.stringSpacetoSpecialChar(
                                        data.uiString
                                      )}
                                    >
                                      {data.name}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            <div className="row px-4 mt-4">
              <div className="button_group mr-4">
                <SecondaryButton
                  onClick={redirectToDashboard}
                  label={"Cancel"}
                />
              </div>

              <div className="button_group">
                <PrimaryButton
                  disabled={disabledStatus}
                  onClick={handleSubmitForm}
                  label={"Update"}
                />
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
      {/* </div> */}
    </section>
  );
};

export default EditUser;
