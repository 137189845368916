import React from "react";
import Modal from "../Modal/Modal";
import Classes from "./UploadDocument.module.css";
import fileUploadIcon from "../../assets/downloadWithTopArrow.svg";
import fileUploadPinkIcon from "../../assets/file_pink.svg";

import CrossIconSVG from "../../assets/cross-x-icon.svg";
import { useTranslation } from "react-i18next";
import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../modules/Home/HomeSlice";
import ProgressBar from "../ProgressBar/ProgressBar";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import Utils from "../../Utils";
import { memo } from "react";
const UploadDocument = ({
  isModalOpen,
  setIsModalOpen,
  documents,
  acceptedFileType,
  uploadedDocuments,
  check,
  setCheck,
  InnerComponent,
  children,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [eachallDocFile, setEachallDocFile] = useState();
  const [uploadedFileName, setUploadedFileName] = useState();
  const [uploadedFileType, setUploadedFileType] = useState();
  const [fileIsUpload, setFileIsUpload] = useState(true);
  const [showProgressBar, setShowProgressBar] = useState(true);
  const fileChangeFunction = (e) => {
    var currFile = e.target.files[0];

    let hasSpecialChars = Utils.hasSpecialChars(currFile?.name);
    if (currFile.name && hasSpecialChars) {
      dispatch(
        setAlertMsg({
          msg: t("file name should not contain special characters"),
          alertType: "danger",
        })
      );
      return;
    }

    if (acceptedFileType.includes(currFile.type) && currFile != undefined) {
      setEachallDocFile(currFile);
      setUploadedFileType(currFile?.type);
      setUploadedFileName(currFile?.name);
      setFileIsUpload(false);
    } else {
      dispatch(
        setAlertMsg({
          msg: t("alertMsg.upload_fail"),
          alertType: "danger",
        })
      );
    }
  };

  const UploadFileFunction = () => {
    if (eachallDocFile != undefined) {
      uploadedDocuments(eachallDocFile);
    }
    setUploadedFileType();
    setUploadedFileName();
    setFileIsUpload(true);
    setIsModalOpen(false);
  };

  function hideBar(e) {
    setShowProgressBar(!e);
  }

  return (
    isModalOpen && (
      <Modal
        onclose={() => {
          setIsModalOpen(false);
          setShowProgressBar(true);
        }}
      >
        {children}
        <div className={Classes.uploadModal}>
          {InnerComponent && <InnerComponent></InnerComponent>}
          <div className="col-md-12 px-0">
            <div className={Classes.documents_heading}>
              {documents && <h3>{documents.heading} </h3>}
              {documents && documents?.faq && (
                <p className={Classes.fileName}>{documents.faq}</p>
              )}
            </div>
            {documents && documents?.documentName && (
              <p
                className={Classes.documentName}
                title={documents.documentName}
              >
                {" "}
                {Utils.ShortLengthFunction(documents.documentName, 70)}
              </p>
            )}
            {documents && (
              <div className={Classes.file_type}>{documents.file_type}</div>
            )}
            <div className={Classes.file_drop_area}>
              <span className={Classes.choose_file_button}>Choose files</span>
              <div className={Classes.file_message}>
                <img src={fileUploadIcon} width="100%" />
                <h3>
                  Drag & Drop Or <span>Choose File</span>{" "}
                </h3>
              </div>
              <input
                className={Classes.file_input}
                type="file"
                accept={acceptedFileType?.toString()}
                ref={fileInputRef}
                onChange={(e) => {
                  fileChangeFunction(e);
                  setShowProgressBar(true);
                }}
              />
            </div>
          </div>
          {uploadedFileName && (
            <div className={Classes.bg_grey_modal + " " + "col-lg-12 p-3  "}>
              <div className="d-flex align-item-center mb-0">
                <i className={Classes.pink_icon}>
                  <img src={fileUploadPinkIcon} />
                </i>
                <div className={Classes.file_names + " " + " my-auto mr-auto"}>
                  <span>
                    {uploadedFileName.length > 30
                      ? uploadedFileName.substring(0, 12) +
                        "..." +
                        uploadedFileType.split("/")[
                          uploadedFileType.split("/").length - 1
                        ]
                      : uploadedFileName}
                  </span>
                  <p className={Classes.fileSize}>
                    {Utils.formatSize(eachallDocFile?.size)}
                  </p>
                </div>

                <div className="btn-group">
                  <i className={Classes.cross_icon}>
                    <img
                      src={CrossIconSVG}
                      onClick={(e) => {
                        if (fileInputRef.current) {
                          fileInputRef.current.value = "";
                        }
                        setUploadedFileType();
                        setUploadedFileName();
                        setFileIsUpload(true);
                        setShowProgressBar(true);
                      }}
                    />
                  </i>
                </div>
              </div>
              {showProgressBar && (
                <div className="mt-3 w-100" style={{ display: "none" }}>
                  {" "}
                  <ProgressBar
                    timing={100}
                    height="5px"
                    hideBar={hideBar}
                    percentShow={false}
                  />
                </div>
              )}
            </div>
          )}

          <div className="col-lg-12 mt-3 px-0">
            <div className="btn-group ml-auto mr-3">
              <PrimaryButton
                style={{ height: "39.px" }}
                disabled={fileIsUpload}
                onClick={() => {
                  UploadFileFunction();
                  setShowProgressBar(true);
                  // setIsModalOpen(false);
                }}
                label={t("Upload")}
              />
            </div>
            <div className="btn-group mr-3">
              <SecondaryButton
                label={t("Cancel")}
                onClick={(e) => {
                  setUploadedFileType();
                  setUploadedFileName();
                  setFileIsUpload(true);
                  setIsModalOpen(false);
                  setShowProgressBar(true);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    )
  );
};

export default memo(UploadDocument);
