import React from "react";
import classes from "./SecondaryButton.module.css";

function SecondaryButton(props) {
  const secondaryBtn = `btn btn-md ${classes.secondaryBtn}`;
  return (
    <button
      type="button"
      onClick={props.onClick}
      disabled={props.disabled}
      className={secondaryBtn}
      style={props.style}
    >
      {" "}
      {props.label}{" "}
      {props.iconImage && (
        <img
          src={props.iconImage}
          alt={props.label}
          className={classes.iconImg}
        />
      )}
    </button>
  );
}

export default SecondaryButton;
