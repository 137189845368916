import React from "react";
import Modal from "../../../../../components/Modal/Modal";
import Classes from "./ScrutinyFormComparision.module.css";
import closeOutlineImage from "../../../../../assets/cross-x-icon.svg";
import { memo } from "react";

const ScrutinyFormComparision = ({
  isModalOpen,
  setIsModalOpen,
  document,
  choForm,
}) => {
  return (
    isModalOpen && (
      <Modal>
        <div className={Classes.scrutinyComparision}>
          <div
            className={Classes.closeBtn}
            onClick={() => setIsModalOpen(false)}
          >
            <img src={closeOutlineImage} />
          </div>
          <div className="row h-100 pt-5 mx-auto">
            <div className="col-lg-6 pb-2 px-3 mx-auto">
              <iframe
                sandbox
                src={choForm}
                width="100%"
                height={"100%"}
              ></iframe>
            </div>

            <div className="col-lg-6 pb-2 px-3 mx-auto">
              <iframe
                sandbox
                src={document}
                width="100%"
                height={"100%"}
              ></iframe>
            </div>
          </div>
        </div>
      </Modal>
    )
  );
};

export default memo(ScrutinyFormComparision);
