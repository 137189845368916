const Consts = {
  // Drawing colors
  TABLE_FILL_COLOR: "#EEE",
  TABLE_STROKE_COLOR: "#707070",
  JIG_FILL_COLOR: "#BFBFBF",
  JIG_STROKE_COLOR: "#707070",
  SLOT_FILL_COLOR: "#C2B5A4",
  FOCUSED_SLOT_FILL_COLOR: "#AB9A83",
  SLOT_STROKE_COLOR: "#707070",
  IMAGE_BOX_FILL_COLOR: "#D8BFD8",
  FOCUSED_IMAGE_BOX_FILL_COLOR: "#B788B7",
  IMAGE_BOX_STROKE_COLOR: "#707070",
  TABLE_PRINTABLE_STROKE_COLOR: "#707070",
  TABLE_PRINTABLE_FILL_COLOR: "none",
  TEXT_COLOR: "#000",
  STROKE_COLOR_WARN: "red",

  // Storage keys
  STORAGE_KEY_ACTIVE_COLUMNS: "activeColumns",
  STORAGE_KEY_HISTORY_COLUMNS: "historyColumns",

  // Error codes for job
  JOB_ERROR_NO_JIG: "NO-JIG",
  JOB_ERROR_NONE: "NONE",

  // Supported inputs
  INPUT_PERSBIZ: "PersBiz",
  INPUT_HOTFOLDER: "HotFolder",

  // Supported rips
  RIP_VERSAWORKS: "VersaWorks",
  RIP_ERGOSOFT: "ErgoSoft",
  RIP_HOTFOLDER: "LocalHotFolder",

  // Selection colors
  SELECTION_COLOR: "#EAEAEA",
  SECONDARY_COLOR: "#F2F2F2",

  // Permissions
  PERMISSIONS_JIG: "jig",
  PERMISSIONS_PRINT_LAYOUT: "print_layout",
  PERMISSIONS_AUTOMATION_RULES: "automation_rules",
  PERMISSIONS_USER_MANAGEMENT: "user_management",
  PERMISSIONS_MASTERS: "masters",
  PERMISSIONS_INPUT_SETTINGS: "input_settings",
  PERMISSIONS_OUTPUT_SETTINGS: "output_settings",
  PERMISSIONS_CONFIGURATION: "configuration",
  PERMISSIONS_DELETE_ORDERS: "delete_orders",
  PERMISSIONS_DOWNLOADS: "downloads",
  PERMISSIONS_CHANGE_ORDER_JIG: "change_order_jig",
  PERMISSIONS_CHANGE_ORDER_ASSIGNEE: "change_order_assignee",
  PERMISSIONS_PRINT_ORDER: "print_order",
  PERMISSIONS_OUTPUT_PRESET: "output_preset",

  CORPORATE: "corporate",
  // corporate registeration - names variables
  CORPORATE_REGISTRATION_PROCESS_STATE_COMPLETE_REGISTRATION:
    "Complete Registration",
  CORPORATE_REGISTRATION_PROCESS_STATE_UPLOAD_DOCUMENTS: "Upload Documents",
  CORPORATE_REGISTRATION_PROCESS_STATE_COMPLETE_SCRUTINY: "Complete Scrutiny",
  CORPORATE_REGISTRATION_PROCESS_STATE_UPDATE_CHO: "Update CHO",
  CORPORATE_REGISTRATION_PROCESS_STATE_ONOBOARDED: "Onboarded",

  //   corporate registration - status variables
  CORPORATE_REGISTRATION_STATUS_PENDING: "Pending",
  CORPORATE_REGISTRATION_STATUS_ACCEPTED: "Accepted",
  CORPORATE_REGISTRATION_STATUS_REJECTED: "Rejected",
  CORPORATE_REGISTRATION_STATUS_ONBOARDED: "Onboarded",
  ACCEPTED_FILE_TYPE_ARR: [
    "application/pdf",
    "image/jpeg",
    "image/png",
    "image/tiff",
    "image/gif",
    "image/bmp",
    "application/pkcs7-signature",
  ],

  ACCEPTED_FILE_TYPE_MASTER: [
    ".csv",
    "text/csv",
    "application/csv",
    "application/vnd.ms-excel",
  ],

  ACCEPTED_FILE_TYPE_CONTRIBUTION: [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".xlsx",
  ],

  // api those should skip encryption
  AVOID_ENCRYPTION_API_LIST: [
    "corp-reg/corporates/upload",
    "/corp-reg/corporates/savedocs",
    "usermanagement/bulkimport",
    "masters/import",
    "corp-reg/dsc-cho",
    "contribution/upload",
  ],

  // maker checker (Review)
  REVIEW_MAKER_CHECKER_IDS: {
    Corporate: {
      id: 1,
    },
    PFMS: {
      id: 9,
    },
    EMAIL_TEMP: {
      id: 4,
    },
    HOLIDAY: {
      id: 2,
    },
    DOCUMENT_TYPE: {
      id: 10,
    },
    Charge: {
      id: 3,
    },
    POP_SP: {
      id: 11,
    },
  },
  //  corporate status codes
  CORPORATE_ONBOARD_STATUSCODE: {
    onboarded: 6186,
    completeReg: 6180,
    scrutiny: 6181,
    updateCho: 6182,
    updateDoc: 6184,
    welcomeKit: 6185,
    pending: 6183,
    scruitnyCharge: 6187,
    updateCharge: 6186,
  },

  CORPORATE_STATUS_FROM_CODE: {
    6183: "Pending",
    6186: "onboarded",
    6180: "completeReg",
    6181: "Scrutiny",
    6182: "updateCHO",
    6184: "UpdateDoc",
    6185: "WelcomeKit",
    6187: "Scrutinize Charges",
    6186: "Update Charges",
  },

  INTERNAL_GRP_ROLE_IDS: {
    ADMIN: {
      id: 3,
    },
    SALES: {
      id: 1,
    },
    PROD: {
      id: 2,
    },
    OPS: {
      id: 220,
    },
  },

  INTERNAL_GRP_ROLE_NAME_SUFFIX: {
    NO: {
      name: "_Nodal Officer",
    },
  },

  SUBSCRIBER_REGISTRATION_TYPE: {
    NEW: 2,
    MIGRATION: 1,
  },
  BUTTON_TYPE: {
    NEXT: "Next",
    PREVIOUS: "Previous",
  },

  FUNCTIONALITY_AREAS_ID: {
    CORP_FORM_FILL_UP: 1,
    CORP_SCRUITNY: 2,
    CORP_CHO_UPDATE: 3,
    CORP_SERVICING: 4,
    CORP_PROFILE_UPDATE: 5,
    MAKERCHECKER_CHECKER: 6,
    CHARGES_APPROVAL: 7,
  },
  SCREEN_TYPE: {
    VIEW: "View",
    EDIT: "Edit",
  },
  TYPE_ACCEPT: "Accept",
  TYPE_REJECT: "Reject",

  //  form filler
  FORM_FILLER_RM: 1,
  FORM_FILLER_CORPORATE: 2,

  // Rs Icons and % icon
  RUPEE_ICON: "₹",
  PERCENT_ICON: "%",

  // Contribution charge select options
  CONTRI_CHARGE_SELECT_AMOUNT: "₹ 30",
  CONTRI_CHARGE_SELECT_PERCENTAGE: "Percentage",

  // Charges approval accessors
  CHARGES_APPROVAL_ACCESSORS: {
    CONTRI_CHARGE_SELECTION: "contriChargesSelection",
    CONTRI_CHARGE: "contriCharges",
    NON_FINANCE_TRANSACTION: "nonFinTransactions",
    ACCOUNT_OPENING_CHARGES: "acOpeningCharge",
    SUPER_CONTRI_CHARGE: "superContribCharges",
  },

  //  subscriber flow
  SUBSCRIBER_FORM_REGISTRATION: 1,
  SUBSCRIBER_FORM_MIGRATION: 2,

  // corporate Form contribution charge string
  Corporate_form_contribution_charge:
    "Contribution charges will be 0.5% or ₹ 30, whichever is higher.",

  ENCRYPTED_PASSWORD_PUBLIC_KEY:
    "-----BEGIN PUBLIC KEY-----MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgG9XryngTgAzF+tX/j0m0fkzhq8ssozBsXaLZXqw9OhNjfFUWEYFCKN3quJqUQ1ZMApjUdr2a7ckT30F7/mEDpoMgj7/unRLqPd5J4HjAKeDdnG1V09ANVH6cxBXkxEib6lNJyiP1GM+tOMQdjA3Sv8ImTzEUrUgXeDnFrEPP7fRAgMBAAE=-----END PUBLIC KEY-----",
};

export default Consts;
