import axios from "axios";

export function getCorporateWorkFlow(id) {
  return axios({
    url: `/hpmc/api/corp-reg/corporates/workflow/${id}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}