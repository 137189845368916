import React from "react";
import ScruitnyForm from "../ScrutinyFrame/ScruitnyForm";
import { useState } from "react";
import { useEffect } from "react";
import {
  ScrutinyFormRaiseFR,
  ScrutinyFormSubmit,
  ScrutinyRejectReasons,
  downloadZipDoc,
  getCorporateDocuments,
  getSingleDocView,
} from "../../../CorporateAction";
import { setAlertMsg } from "../../../../Home/HomeSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Consts from "../../../../../Consts";
import {
  BASE_URL,
  DASHBOARD_URL,
  CORPORATEDASHBOARD_URL,
} from "../../../../../Routes/Urls";
import RejectedDocumentModal from "../ScrutinyHelperModals/RejectedDocumentModal";
import RejectedDocumentListScrutinyModal from "../ScrutinyHelperModals/RejectedDocumentListScrutinyModal";
import ScrutinySubmitModal from "../ScrutinyHelperModals/ScrutinySubmitModal";
import ScrutinyFormComparision from "../ScrutinyHelperModals/ScrutinyFormComparision";
import { memo } from "react";
import { useRef } from "react";

const CorporateScrutiny = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const docRejectRef = useRef();
  const scrollBottomRef = useRef();
  const corpId = JSON.parse(localStorage.getItem("corpId"));
  const [currDocOrionId, setCurrDocOrionId] = useState(0);
  const [corpDetails, setCorpDetails] = useState({});
  const [documentsItem, setDocumentsItem] = useState([]);
  const [documentsStatus, setDocumentsStatus] = useState({
    pending: "Pending",
    accepted: "Accepted",
    rejected: "Rejected",
  });

  const [rejectReasons, setRejectReasons] = useState([]);
  const [isActiveDocumentName, setIsActiveDocumentName] =
    useState("CORP_CHOFORM");
  const [proceedData, setProceedData] = useState({});
  const [isActiveId, setIsActiveId] = useState(0);
  const [showCurrentImage, setShowCurrentImage] = useState();
  const [proceedStatus, setProceedStatus] = useState(true);
  const [acceptDisable, setAcceptDisable] = useState(false);
  const [rejectDisable, setRejectDisable] = useState(false);
  const [selectedReason, setSelectedReason] = useState([]);
  const [backendUploadedObj, setBackendUploadedObj] = useState({});
  const [rejectedForms, setRejectedForms] = useState({});
  const [isRejCorporateApplicationModal, setIsRejCorporateApplicationModal] =
    useState(false);
  const [isCorporateApplicationModal, setIsCorporateApplicationModal] =
    useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isComparisonModalOpen, setIsComparisonModalOpen] = useState(false);
  const [choForm, setChoForm] = useState();

  const scrollToBottom = () => {
    if (scrollBottomRef && scrollBottomRef.current) {
      scrollBottomRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  useEffect(() => {
    handleAllScrutinyDoc();
  }, []);

  const handleAllScrutinyDoc = () => {
    getCorporateDocuments(corpId)
      .then((res) => {
        var scrutinyDocumentsData = res.data.data.orionDocuments;
        let corpActionId = res.data.data.corpData.corpAction.id;
        var serverOrionDocument = scrutinyDocumentsData.filter(
          (e) =>
            ((e.isApproved === true &&
              corpActionId === Consts.CORPORATE_ONBOARD_STATUSCODE.updateCho) ||
              (e.isApproved != true &&
                corpActionId !=
                  Consts.CORPORATE_ONBOARD_STATUSCODE.updateCho)) &&
            e.documentName != null &&
            e.orionDocId
        );
        setCorpDetails(res.data.data.corpData);
        mergeServerDataWithUI(serverOrionDocument);
        setDocumentsItem(serverOrionDocument);
        let defaultPrimary = getDefaultFirst(serverOrionDocument);
        setIsActiveId(
          serverOrionDocument.findIndex((e) => e.id == defaultPrimary.id)
        );
        let choData = getChoFormData(res.data.data.orionDocuments);
        documentViewFunction(choData.orionDocId, true);
        setCurrDocOrionId(defaultPrimary.orionDocId);

        if (defaultPrimary.docStatus == documentsStatus.accepted) {
          setAcceptDisable(true);
        } else if (defaultPrimary.docStatus == documentsStatus.rejected) {
          setRejectDisable(true);
        }
        setIsActiveDocumentName(documentsItem[0]);

        ischeckedFunction(defaultPrimary);
      })
      .catch((err) => {});
    existedSelectedReason();
  };

  // existed selected reasons
  const existedSelectedReason = () => {
    // get all corporate reasons
    ScrutinyRejectReasons()
      .then((res) => {
        if (res.data.success === true) {
          setRejectReasons(res.data.data?.map((reason) => reason.name));
        }
      })
      .catch((err) => {});
    if (rejectedForms[isActiveDocumentName] != null) {
      return setSelectedReason(rejectedForms[isActiveDocumentName].reason);
    }
    setSelectedReason([]);
  };

  const mergeServerDataWithUI = (serverOrionDocument) => {
    serverOrionDocument.forEach((datas, index) => {
      if (datas.isApproved === true) {
        datas.docStatus = documentsStatus.accepted;
        proceedDataFunction(datas.id, true);
      } else {
        datas.docStatus = documentsStatus.pending;
      }

      if (datas.orionDocId != null) {
        datas.isChecked = true;
      }

      setBackendUploadedObj({ ...backendUploadedObj });
    });
  };
  const proceedDataFunction = (id, approval) => {
    let docid = id;
    let deleteAction = `${docid}`;
    let checkExist = proceedData.hasOwnProperty(`${deleteAction}`);
    if (checkExist) {
      delete proceedData[deleteAction];
    }
    // setDeletedFilesArr([...deletedFilesArr, deleteAction]);
    if (approval === true) {
      setProceedData((data) => ({
        ...data,
        [docid]: {
          ["isApproved"]: approval,
        },
      }));
    } else if (approval === false) {
      let allreason = selectedReason.join("|");

      setProceedData((data) => ({
        ...data,
        [docid]: {
          ["isApproved"]: approval,
          ["remark"]: allreason,
        },
      }));
    }
  };

  // get default first document
  const getDefaultFirst = (e) => {
    return e.find((data) => data.orionDocId != null);
  };

  const getChoFormData = (e) => {
    return e.find((data) => data.processDocumentType.id === 1);
  };
  const documentViewFunction = (currDocOrionId, isCHOForm) => {
    getSingleDocView(currDocOrionId).then((res) => {
      const fileUrl = URL.createObjectURL(res.data);
      if (isCHOForm) {
        setChoForm(fileUrl);
      } else {
        setShowCurrentImage(fileUrl);
      }
    });
  };

  useEffect(() => {
    documentViewFunction(currDocOrionId, false);
  }, [currDocOrionId]);

  const ischeckedFunction = (check) => {
    if (check.docStatus == documentsStatus.accepted) {
      setAcceptDisable(true);
    } else {
      setAcceptDisable(false);
    }

    if (check.docStatus == documentsStatus.rejected) {
      setRejectDisable(true);
    } else {
      setRejectDisable(false);
    }

    for (let checkData of documentsItem) {
      if (
        (checkData.isChecked == true &&
          checkData.docStatus != documentsStatus.pending) ||
        (checkData.isChecked == false && checkData.documentName == null) ||
        checkData.orionDocId == null
      ) {
        setProceedStatus(false);
      } else {
        return setProceedStatus(true);
      }
    }
  };

  // submit
  const handleSubmitFunction = (isSendMail) => {
    if (
      corpDetails.processState.id == 4 &&
      isSendMail == "self" &&
      Object.keys(rejectedForms).length > 0
    ) {
      var orionDocumentsDataObj = {
        ["isFrRaised"]: true,
        ["orionDocumentsData"]: proceedData,
      };
      ScrutinyFormRaiseFR(`${corpId}`, orionDocumentsDataObj, isSendMail)
        .then((res) => {
          if (res.data.success === true) {
            dispatch(
              setAlertMsg({
                msg: `FR for CR${corpId} has been raised `,
                alertType: "success",
              })
            );
            handleNavigate();
          }
        })
        .catch((error) => {
          dispatch(
            setAlertMsg({
              msg: t("server.failed.request"),
              alertType: "danger",
            })
          );
          setIsCorporateApplicationModal(false);
        });
    } else {
      ScrutinyFormSubmit(`${corpId}`, proceedData, isSendMail)
        .then((res) => {
          {
            if (res.data.success === true) {
              if (Object.keys(rejectedForms).length > 0) {
                dispatch(
                  setAlertMsg({
                    msg: `FR for CR${corpId} has been raised `,
                    alertType: "success",
                  })
                );
              } else {
                dispatch(
                  setAlertMsg({
                    msg: "Scrutiny form submitted ",
                    alertType: "success",
                  })
                );
              }

              handleNavigate();
            }
          }
        })
        .catch((error) => {
          dispatch(
            setAlertMsg({
              msg: t("server.failed.request"),
              alertType: "danger",
            })
          );
          setIsRejCorporateApplicationModal(false);
          setIsCorporateApplicationModal(false);
        });
    }
  };
  // navigate to corporate table
  const handleNavigate = () => {
    navigate(`/${BASE_URL}/${DASHBOARD_URL}/${CORPORATEDASHBOARD_URL}`);
  };

  const handleZipDownload = () => {
    downloadZipDoc(corpId);
  };

  const triggerNextDocFunction = () => {
    if (docRejectRef && docRejectRef.current) {
      docRejectRef.current.triggerFunction();
    }
  };

  const checkAllDocScruitny = () => {
    return documentsItem?.every((e) => e?.docStatus != documentsStatus.pending);
  };

  return (
    <>
      <ScruitnyForm
        corpDetails={corpDetails}
        documentsStatus={documentsStatus}
        setCurrDocOrionId={setCurrDocOrionId}
        isActiveId={isActiveId}
        setIsActiveId={setIsActiveId}
        rejectedForms={rejectedForms}
        setRejectedForms={setRejectedForms}
        setIsRejCorporateApplicationModal={setIsRejCorporateApplicationModal}
        setIsCorporateApplicationModal={setIsCorporateApplicationModal}
        handleNavigate={handleNavigate}
        documentsItem={documentsItem}
        setDocumentsItem={setDocumentsItem}
        showCurrentImage={showCurrentImage}
        ischeckedFunction={ischeckedFunction}
        acceptDisable={acceptDisable}
        rejectDisable={rejectDisable}
        isActiveDocumentName={isActiveDocumentName}
        setIsActiveDocumentName={setIsActiveDocumentName}
        setSelectedReason={setSelectedReason}
        proceedStatus={proceedStatus}
        proceedDataFunction={proceedDataFunction}
        setIsModal={setIsModal}
        setIsComparisonModalOpen={setIsComparisonModalOpen}
        isComparisonModalOpen={isComparisonModalOpen}
        ref={{ docRejectRef, scrollBottomRef }}
        scrollToBottom={scrollToBottom}
        checkAllDocScruitny={checkAllDocScruitny}
        corpId={corpId}
        handleAllScrutinyDoc={handleAllScrutinyDoc}
        currDocOrionId={currDocOrionId}
      />

      <RejectedDocumentModal
        corpDetails={corpDetails}
        isModalOpen={isModal}
        setIsModalOpen={setIsModal}
        rejectReasons={rejectReasons}
        isActiveId={isActiveId}
        documentsItem={documentsItem}
        setDocumentsItem={setDocumentsItem}
        documentsStatus={documentsStatus}
        setAcceptDisable={setAcceptDisable}
        setRejectDisable={setRejectDisable}
        setProceedStatus={setProceedStatus}
        proceedDataFunction={proceedDataFunction}
        rejectedForms={rejectedForms}
        setRejectedForms={setRejectedForms}
        selectedReason={selectedReason}
        setSelectedReason={setSelectedReason}
        triggerNextDocFunction={triggerNextDocFunction}
        scrollToBottom={scrollToBottom}
        checkAllDocScruitny={checkAllDocScruitny}
      />

      {/* rejected document list  */}
      <RejectedDocumentListScrutinyModal
        corpDetails={corpDetails}
        rejectedForms={rejectedForms}
        isModalOpen={isRejCorporateApplicationModal}
        setIsModalOpen={setIsRejCorporateApplicationModal}
        handleSubmitFunctionSELF={() => handleSubmitFunction("self")}
      />

      {/* submit modal without any rejection */}
      <ScrutinySubmitModal
        corpDetails={corpDetails}
        isModalOpen={isCorporateApplicationModal}
        setIsModalOpen={setIsCorporateApplicationModal}
        handleSubmitFunctionCRA={() => handleSubmitFunction("cra")}
        handleSubmitFunctionSELF={() => handleSubmitFunction("self")}
        handleZipDownloadFunction={() => handleZipDownload()}
      />

      {/* comparison modal from Cho Form to others form */}
      <ScrutinyFormComparision
        isModalOpen={isComparisonModalOpen}
        setIsModalOpen={setIsComparisonModalOpen}
        document={showCurrentImage}
        choForm={choForm}
      />
    </>
  );
};

export default memo(CorporateScrutiny);
