import React, { useState, useEffect } from "react";
import Classes from "./Alert.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMsg, setArrayOfAlertMsg } from "../../modules/Home/HomeSlice";

const Alert = () => {
  //  green == success , yellow == warning , red == danger

  const { alertMsg, arrayOfAlerts } = useSelector((state) => state);
  const dispatch = useDispatch();
  let alertType = alertMsg.alertType;

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setAlertMsg({ msg: "", alertType: "" }));
      dispatch(setArrayOfAlertMsg([]));
    }, 4000);

    return () => clearTimeout(timer);
  }, [alertMsg?.msg && alertMsg?.alertType, arrayOfAlerts.length > 0]);

  return (
    <>
      {alertMsg?.msg && ( // when simple sting as alert pass
        <div className={Classes.custom_alert_box}>
          <div
            className={
              alertType
                ? `alert alert-${alertType}  ${Classes.alert_style} `
                : `alert alert-primary ${Classes.alert_style}`
            }
            role="alert"
          >
            {alertMsg?.msg}
            <span
              className={Classes.alert_time_design + " " + `bg-${alertType}`}
            ></span>
          </div>
        </div>
      )}
      {
        // when array of error messages pass
        arrayOfAlerts &&
          arrayOfAlerts.length > 0 &&
          arrayOfAlerts.map((alertMsg, index) => {
            return (
              <div
                key={index}
                style={{ top: `${(index + 1.5) * 60}px` }}
                className={Classes.custom_alert_box}
              >
                <div
                  className={
                    alertType
                      ? `alert alert-${alertType}  ${Classes.alert_style} `
                      : `alert alert-warning ${Classes.alert_style}`
                  }
                  role="alert"
                >
                  {alertMsg.msg}
                  <span
                    className={
                      Classes.alert_time_design +
                      " " +
                      `bg-${alertMsg.alertType}`
                    }
                  ></span>
                </div>
              </div>
            );
          })
      }
    </>
  );
};

export default Alert;

// alert types primary , secondary success, warning, danger,info,dark , light
