import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  ADMIN_URL,
  AUTHMANAGEMENT_URL,
  BASE_URL,
  FUNCTIONALITY_MANAGEMENT_URL,
  GroupManagement_URL,
  RoleManagement_URL,
} from "../../../Routes/Urls";
import classes from "./AuthManagement.module.css";
import GroupManagement_Img from "../../../assets/AuthManagementImg/GroupManagement.png";
import RoleManagement_Img from "../../../assets/AuthManagementImg/RoleManagement.png";
import FunctionalityManagement_Img from "../../../assets/AuthManagementImg/FunctionalityManagement.png";
import Card from "../../../components/Card/Card";
import GoBackButton from "../../../components/Buttons/GoBackButton";

const AuthManagement = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleCardClick = (path) => {
    navigate(`/${BASE_URL}/${ADMIN_URL}/${AUTHMANAGEMENT_URL}/${path}`);
  };

  const adminCardObj = [
    {
      path: GroupManagement_URL,
      label: "Group Management",
      img: GroupManagement_Img,
    },
    {
      path: RoleManagement_URL,
      label: "Role Management",
      img: RoleManagement_Img,
    },
    {
      path: FUNCTIONALITY_MANAGEMENT_URL,
      label: "Functionality Management",
      img: FunctionalityManagement_Img,
    },
  ];

  return (
    <div className={classes.card_container}>
      <div>
        <GoBackButton />
      </div>
      <h1>Authorization Management</h1>
      <div className={classes.cards}>
        {adminCardObj.map((data, key) => {
          return (
            <div key={key} className={classes.card}>
              <Card
                onClick={() => handleCardClick(data?.path)}
                label={t(data.label)}
                width="100%"
                height="100%"
                img={data.img}
              ></Card>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AuthManagement;
