import React from "react";
import classes from "../MasterManagement.module.css";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import { useState } from "react";
import ArrowUPIcon from "../../../../assets/arrow-up.svg";
import PenEditIcon from "../../../../assets/pen_edit.svg";
import { useEffect } from "react";
import {
  editClaimUnclaim,
  getAllCharge,
  getCharge,
  makerCheckerFlow,
} from "../../AdminManagementActions";
import TextButton from "../../../../components/Buttons/TextButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import Modal from "../../../../components/Modal/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMsg } from "../../../Home/HomeSlice";
import Utils from "../../../../Utils";

const ChargeManagement = ({
  searchingQuery,
  dateOne,
  dateTwo,
  perPage,
  isWorkflowEnabled,
  masterId,
}) => {
  const userDetails = useSelector((state) => state.loginUserDetails);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("");
  const [accessor, setAccessor] = useState("");
  const [columnRotations, setColumnRotations] = useState("submissionDateasc");
  const [tableData, setTableData] = useState([]);
  const [pageData, setPageData] = useState({});
  const [filterBy, setfilterBy] = useState("");
  const [pFMFormActive, setPFMFormActive] = useState(false);
  const [editCharge, setEditCharge] = useState({ id : null,
    name :"",
    value :""
});
  const [staticChargedInput, setStaticChargedInput] = useState({
    id: null,
    name: "",
    value: "",
  });
  const [changedVal,setChangedVal] = useState({});
  const [rowId, setRowId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [disabledStatus,setDisabledStatus] = useState(true);
  // holiday table columns
  const chargeTableColumn = [
    {
      header: (
        <p className={classes.headers + " " + "text-left"}>
          NAME
          
        </p>
      ),
      accessor: (e) => e.name,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 80 },
    },

    {
      header: (
        <p className={classes.headers}>
          Value
          
        </p>
      ),
      accessor: (e) => e.value,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 20 },
      isTextCenter : true
    },

    {
      header: <p className={classes.headers}>ACTION</p>,
      key: "corpAction",
      accessor: (e) => (
        <div className={classes.action_btn}>
        {isWorkflowEnabled && (
          <TextButton
            style={{
              display:
                e.makerCheckerStatus === null ||
                e.makerCheckerStatus === 0 ||
                e.currentMaker?.username !== userDetails.username ||
                (e.makerCheckerFlowFunction == 1 &&
                  e.currentMaker?.username !== userDetails.username)
                  ? "none"
                  : "",
            }}
            disabled={
              e.makerCheckerStatus === 2 ||
              (e.makerCheckerStatus === 1 &&
                e.currentMaker?.username !== userDetails.username)
            }
            onClick={() => {
              editClaimUnclaimFunction(e.id, true);
              setRowId(e.id);
            }}
            label={t("button.release")}
          />
        )}

        <TextButton
          disabled={
            e.makerCheckerStatus === 2 ||
            (e.makerCheckerStatus === 1 &&
              e.currentMaker?.username !== userDetails.username &&
              userDetails.username != "wbadmin")
          }
          onClick={() => {
            isWorkflowEnabled == true &&
            (e.makerCheckerStatus == 0 || e.makerCheckerStatus == null) ? (
              <>{(setIsEdit(true), editChargeFunctionData(e.id))}</>
            ) : (
              <>
                {(setPFMFormActive(true), editChargeFunctionData(e.id))}
              </>
            );
          }}
          label={t("button.edit")}
        />
      </div>

      
      ),
    },
  ];

  useEffect(() => {
    setPage(0);
  }, [perPage, searchingQuery]);
  useEffect(() => {
    getAllChargeFunction();
  }, [sort, accessor, dateOne, dateTwo, page, perPage, searchingQuery]);

  const getAllChargeFunction = () => {
    getAllCharge({
      page,
      searchingQuery,
      sort,
      accessor,
      perPage,
      filterBy,
    }).then((res) => {
      setTableData(res.data.data);
      setPageData(res.data.data);
    });
  };

  const handleSort = (key) => {
    var sorting = sort;
    const newSort = sort === "asc" ? "desc" : "asc";
    setSort(newSort);
    setAccessor(key);
    if (sort === "asc") {
      setColumnRotations(key + "desc");
    } else {
      setColumnRotations(key + "asc");
    }
  };

  const editChargeFunctionData = (id) => {
    setRowId(id);
    getCharge(id).then((res) => {
      let {id,name,value} = res?.data?.data.masterData;
      let obj = {
        id: id,
        name: name,
        value: value,
      };
      setEditCharge(obj);
      setStaticChargedInput(obj);
    });
  };

  const editClaimUnclaimFunction = (rowId, isReleased) => {
    editClaimUnclaim(masterId, rowId)
      .then((res) => {
        if (isReleased == false) {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.task_claimed"),
              alertType: "success",
            })
          );
          setPFMFormActive(true);
          setIsEdit(false);
        } else {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.task_unclaimed"),
              alertType: "success",
            })
          );
          getAllChargeFunction();
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.task_claim_fail"),
            alertType: "danger",
          })
        );
      });
  };
  const makerCheckerFlowFunction = () => {
    let data = changedVal;
    makerCheckerFlow(masterId, rowId, data)
      .then((res) => {
        if (res.data.success === true) {
          dispatch(
            setAlertMsg({
              msg:
                isWorkflowEnabled === true
                  ? t("alertMsg.changes_for_approval")
                  : t("alertMsg.edit_successfully"),
              alertType: "success",
            })
          );

          setPFMFormActive(false);
          getAllChargeFunction();
        } else {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.update_failed"),
              alertType: "danger",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.update_failed"),
            alertType: "danger",
          })
        );
      });
  };

  //  handle change modal inputs
  const handleChangeInput = (e) => {
    let { name, value } = e.target;
    setEditCharge((data) => ({
        ...data,
        [name]: value
    }))

    if(staticChargedInput[name] === value){
      delete changedVal[name];
    }
    else{
      setChangedVal((data)=>({
        ...data,
        [name] : value
      }))
    }
}


useEffect(() => {
  checkSubmitDisabled();
}, [editCharge]);


// checked changes or not?
const checkSubmitDisabled = () => {
  var status =
    (Utils.removeWhiteSpaceFromBetween(
      editCharge.name
    ).toLocaleLowerCase() ==
      Utils.removeWhiteSpaceFromBetween(
        staticChargedInput.name
      ).toLocaleLowerCase() && Utils.removeWhiteSpaceFromBetween(
        editCharge.value
      ).toLocaleLowerCase() ==
        Utils.removeWhiteSpaceFromBetween(
          staticChargedInput.value
        ).toLocaleLowerCase() )||
      Utils.removeWhiteSpaceFromBetween(editCharge.name) == "" ||
      Utils.removeWhiteSpaceFromBetween(editCharge.value) == "" 
      ? true
      : false;

  setDisabledStatus(status);
};  
  return (
    <>
      <CustomTable
        page={page}
        setPage={setPage}
        headings={chargeTableColumn}
        data={tableData}
        pageData={pageData}
        sort={sort}
        setSort={setSort}
        accessor={accessor}
        setAccessor={setAccessor}
        srNO={true}
      />
      {/* for holiday edit */}

      {isEdit == true && isWorkflowEnabled == true && (
        <Modal>
          <div className={classes.editModal_container}>
            <h4>
              <span>
                <img src={PenEditIcon} alt="pen edit Master management" />
              </span>{" "}
              {t("proceed.edit.master")}
            </h4>
            <p>{t("proceed.edit.master.text")}</p>
            <div className={classes.editModal_btn}>
              <PrimaryButton
                onClick={() => {
                  editClaimUnclaimFunction(rowId, false);
                }}
                label={t("button.proceed")}
              />
              <TextButton
                onClick={() => {
                  setIsEdit(false);
                }}
                label={t("button.close")}
              />
            </div>
          </div>
        </Modal>
      )}

      {pFMFormActive && (
        <Modal
          closeBtn={{}}
          onClose={() => {
            getAllChargeFunction();
            setPFMFormActive(false);
          }}
        >
          <div className={classes.holiday_modal_body}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-input mb-3">
                  <label>Charge Name</label>
                  <input
                    type="text"
                    name="name"
                    value={editCharge?.name}
                    onInput={(e) => handleChangeInput(e)}
                    className="form-control"
                  />
                </div>

                <div className="form-input">
                  <label>Charge Value</label>
                  <input
                    type="text"
                    name="value"
                    value={editCharge?.value}
                    onInput={(e) => handleChangeInput(e)}
                    className="form-control"
                  />
                </div>

                <div className="btn-group mt-4">
                  <div className="btn-form mr-3">
                    <PrimaryButton
                      disabled={
                        disabledStatus
                      }
                      label={t("button.submit")}
                      onClick={() => {
                        makerCheckerFlowFunction(staticChargedInput.id);
                      }}
                    />
                  </div>
                  <div className="btn-form">
                    <SecondaryButton
                      onClick={() => {
                        getAllChargeFunction();
                        setPFMFormActive(false);
                      }}
                      label={t("button.cancel")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ChargeManagement;
