import React, { useEffect, useState } from "react";
import classes from "./Captcha.module.css";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../modules/Home/HomeSlice";
import { getCaptcha } from "../../modules/Home/HomeAction";
import TextButton from "../Buttons/TextButton";

const Captcha = ({ value, onchange, setCaptchaKey }) => {
  const dispatch = useDispatch();
  const [captchaImg, setCaptchaImg] = useState({
    img: "",
    captchaKey: "",
  });

  useEffect(() => {
    getNewCaptcha();
  }, []);

  const getNewCaptcha = () => {
    getCaptcha()
      .then((res) => {
        const imgSrc = `data:image/png;base64,${res.data.img}`;
        setCaptchaImg({
          img: imgSrc,
          captchaKey: res.data.captchaKey,
        });
        setCaptchaKey(res.data.captchaKey);
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({ msg: err.response?.data?.message, alertType: "danger" })
        );
      });
  };

  return (
    <div className={classes.captcha_container}>
      <div>
        <img src={captchaImg.img} alt="captcha" />
        <TextButton onClick={getNewCaptcha} label={"refresh"} />
      </div>
      <input
        value={value}
        onChange={(e) => onchange(e)}
        placeholder="Enter captcha"
      />
    </div>
  );
};

export default Captcha;
