import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { parseISO } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerUI = ({
  placeHolder,
  onInputChange,
  maximumDate,
  minimumDate,
  resetValue,
  setResetValue
}) => {
  const [startDate, setStartDate] = useState(null);
  const date = new Date();
  if(resetValue == undefined){
    resetValue = false;
   
  }

  useEffect(() => {
   
    if (maximumDate == undefined) {
      maximumDate = date;
    }
   
    
  }, []);


  useEffect(()=>{
    if(resetValue === true){
      setStartDate(null);
      setResetValue(false);
    }
  },[resetValue === true])

  const dateFunction = (date) => {
    setStartDate(date);
    if (date == null) {
      onInputChange("");
    } else {
      onInputChange(date);
    }
  };
  return (
    <DatePicker
    dateFormat="dd/MM/yyyy"
      selected={startDate}
      maxDate={maximumDate}
      minDate={minimumDate}
      onChange={(date) => {
        dateFunction(date);
      }}
      isClearable
      placeholderText={placeHolder}
     
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
    />
  );
};

export default DatePickerUI;
