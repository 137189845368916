import React, { useEffect, useRef } from 'react';
import Classes from "./FilterComponent.module.css";
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Utils from '../../Utils';

import cross_icon from "../../assets/cross-x-icon.svg";



const FilterComponent = ({ dataArray, setSubordinateId }) => {
  const [isModalShow, setIsShowModal] = useState(false);
  const [filteredData, setFilteredData] = useState(dataArray);
  const [isDataShow, setIsDataShow] = useState(false);
  const [selectedData, setSelectedData] = useState("");


  useEffect(() => {
    if (filteredData === undefined) {
      setFilteredData(dataArray)
    }
  }, [dataArray])

  const showHideFilter = () => {
    if (isDataShow === false) {
      setIsShowModal(!isModalShow);
    }
  }

  const filterByInput = (e) => {
    let text = Utils.removeWhiteSpaceFromBetween(e.target.value);
    const subArray = dataArray.filter((data) => data.username.toLowerCase().includes(text));
    setFilteredData(subArray);
  }


  const selectSubordinates = (id, data) => {
    setSelectedData(data);
    setSubordinateId(id);
    setFilteredData(dataArray);
    setIsDataShow(true);
    setIsShowModal(false)
  }


  const crossClicked = () => {
    setSubordinateId("");
    setIsDataShow(false);
    setIsShowModal(false);
  }

  const cardRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (cardRef.current && !cardRef.current.contains(event.target)) {
          setIsShowModal(false); 
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);



  return (
    <div className={Classes.FilterComponent} ref={cardRef}>

      <div className={Classes.filter_container} onClick={() => showHideFilter()}>{isDataShow ? <div className={Classes.selectedData}>
        {Utils.ShortLengthFunction(selectedData, 25)}
        <span className={Classes.cross_icon} onClick={() => crossClicked()}>×</span> </div>
        : <div className={Classes.filter_text}>Select Subordinate</div>}  </div>
      {isModalShow && <div className={Classes.item_box}>
        <div className={Classes.title}>
          <h2></h2>
          <div onClick={() => setIsShowModal(false)}>Close</div>
        </div>
        <Box
          component="form"
          sx={{
            '& > :not(style)': { width: '100%' },
          }}
          noValidate
          autoComplete="off"
        >
          {/* <label className={Classes.search_label}>Users</label> */}
          <TextField
            label="Select Subordinate"
            type="search"
            id="outlined-size-small"
            size="small"
            onInput={(e) => filterByInput(e)}
          />
        </Box>
        <div className={Classes.searchable_data}>
          <ul>
            {filteredData && filteredData?.map((data, index) => {
              return (
                <li key={index} onClick={() => selectSubordinates(data.id, data.username)}> <p>{data.username}</p></li>
              )

            })}
          </ul>



        </div>


      </div>}


    </div>

  )
}

export default FilterComponent

