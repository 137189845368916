import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { createRoot } from "react-dom/client";
import App from "./App/App";
import { store } from "./store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import browserHistory from "./history";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import "font-awesome/css/font-awesome.min.css";

const root = createRoot(document.getElementById("root"));

root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <BrowserRouter history={browserHistory}>
        <App />
      </BrowserRouter>
    </Provider>
  </I18nextProvider>
);
