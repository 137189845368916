import React, { useEffect } from "react";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import classes from "./NPSRegistrationPage.module.css";
import { useTranslation } from "react-i18next";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import { useNavigate } from "react-router";
import timeHandImage from "../../../assets/NPSRegistrationIcons/nps.png";
import regulatedIcon from "../../../assets/NPSRegistrationIcons/regulated.svg";
import flexibiltyIcon from "../../../assets/NPSRegistrationIcons/flexibilty.svg";
import economicalIcon from "../../../assets/NPSRegistrationIcons/economical.svg";
import portabilityIcon from "../../../assets/NPSRegistrationIcons/portability.svg";
import tax_benefitsIcon from "../../../assets/NPSRegistrationIcons/tax_benefits.svg";
import voluntaryIcon from "../../../assets/NPSRegistrationIcons/voluntary.svg";
import info_blue_icon from "../../../assets/info-blue-icon.svg";
import {
  BASE_URL,
  DASHBOARD_URL,
  SIGN_IN_URL,
  SUBSCRIBER_FORM,
  SUBSCRIBER_MIGRATION,
} from "../../../Routes/Urls";
import { useState } from "react";

import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../Home/HomeSlice";
import { subRegisterType } from "../SubscriberSlice";
import { useSearchParams } from "react-router-dom";

const NPSRegistrationPage = ({ handleClickNPS, handlePendingForm }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [corpIdParam, setCorpIdParam] = useState("");
  const [featuresArray, setFeaturesArray] = useState([
    {
      name: "Regulated",
      img: regulatedIcon,
    },
    {
      name: "Flexibility",
      img: flexibiltyIcon,
    },
    {
      name: "Economical",
      img: economicalIcon,
    },
    {
      name: "Portability",
      img: portabilityIcon,
    },
    {
      name: "Voluntary",
      img: voluntaryIcon,
    },
    {
      name: "Tax Benefits",
      img: tax_benefitsIcon,
    },
  ]);

  useEffect(() => {
    localStorage.removeItem("subDetails");
  }, []);

  return (
    <>
      <section className={classes.npsRegistrationPage}>
        <div className="container-fluid px-5 py-3">
          <div className="row">
            <GoBackButton />
          </div>
          <div className={`row ${classes.page_box}`}>
            <div className="col-lg-8 col-md-12 order-lg-0 order-1">
              <div className="row">
                <div className="col-lg-12">
                  <div className={classes.title_part + " " + "mb-4"}>
                    <div className={classes.title}>
                      <h1>National Pension System (NPS)</h1>
                    </div>
                    <div className={classes.sub_title}>
                      <p>Corporate Registration Form</p>
                    </div>
                    <div className={classes.sub_title + " " + "mt-2"}>
                      <p>
                        <strong className={classes.text_black}>
                          National Pension System (NPS)
                        </strong>{" "}
                        is based on unique Permanent Retirement Account Number
                        (PRAN) which is allotted to every subscriber. In order
                        to encourage savings, the Government of India has made
                        the scheme reassuring from security point of view and
                        has offered some attractive benefits for. NPS account
                        holders.
                      </p>
                    </div>
                  </div>

                  <div className={classes.features_info}>
                    <p>
                      What all documents you will be required{" "}
                      <i>
                        <img src={info_blue_icon} alt="info" />
                      </i>
                    </p>
                  </div>
                  <div className={classes.features_box}>
                    <div className={classes.title}>
                      <h5>Salient Features & Benefits</h5>
                    </div>
                    <div className={classes.boxes}>
                      {featuresArray &&
                        featuresArray.map((data, index) => {
                          return (
                            <div className={classes.gridColumns} key={index}>
                              <div className={classes.grid_img}>
                                <img
                                  src={data.img}
                                  width="100%"
                                  alt={data.name}
                                />
                              </div>
                              <div className={classes.name}>
                                <p>{data.name}</p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div
                  className={"col-lg-12 pt-3 my-3"}
                >
                  <div
                    className={
                      classes.btn_group 
                    }
                  >
                    <SecondaryButton
                      label={t("Complete Pending Registration")}
                      onClick={() => handlePendingForm()}
                    />
                    </div>
                    <div
                    className={
                      classes.btn_group 
                    }
                  >
                    <PrimaryButton
                      label={t("New NPS")}
                      onClick={() => {
                        handleClickNPS(2);
                      }}
                    />
                    </div>
                    <div
                    className={
                      classes.btn_group 
                    }
                  >
                    <PrimaryButton
                      label={t("Existing PRAN")}
                      onClick={() => {
                        dispatch(subRegisterType(1));
                        navigate(
                          `/${BASE_URL}/${DASHBOARD_URL}/${SUBSCRIBER_MIGRATION}?corporateId=${corpIdParam}`
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mx-auto mb-4 order-lg-1 order-0 pt-2">
              <div className={classes.main_img}>
                <img src={timeHandImage} width="100%" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NPSRegistrationPage;
