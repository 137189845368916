import browserHistory from "../../../history.js";
import axios from "axios";

export function getAllUsers() {
  return axios({
    url: "/hpmc/api/usermanagement/getallusers",
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getAllroles() {
  return axios({
    url: "/hpmc/api/rolemanagement/getallroles",
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getAllgroups() {
  return axios({
    url: "/hpmc/api/groupmanagement/getallgroups",
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function AddSingleGroup(creds) {
  return axios({
    url: "/hpmc/api/groupmanagement/addgroup",
    method: "post",
    data: creds,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function AddSingleRole(creds) {
  return axios({
    url: "/hpmc/api/rolemanagement/addrole",
    method: "post",
    data: creds,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function AddUsers(creds) {
  return axios({
    url: "/hpmc/api/usermanagement/adduser",
    method: "post",
    data: creds,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function DeleteUser(creds) {
  return axios({
    url: "/hpmc/api/usermanagement/deleteuser",
    method: "post",
    data: creds,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function DeleteGroup(creds) {
  return axios({
    url: "/hpmc/api/groupmanagement/deletegroup",
    method: "post",
    data: creds,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function DeleteRole(creds) {
  return axios({
    url: "/hpmc/api/rolemanagement/deleterole",
    method: "post",
    data: creds,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getSingleUserDetail(creds) {
  return axios({
    url: "/hpmc/api/usermanagement/getuserbyname",
    method: "post",
    data: creds,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function editUserDetail(creds) {
  return axios({
    url: "/hpmc/api/usermanagement/edituser",
    method: "put",
    data: creds,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getAllMaster(endUrl) {
  return axios({
    url: `/hpmc/api/${endUrl}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getAllLocation() {
  return axios({
    url: `/hpmc/api/locationmaster`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function addSingleMaster(endUrl, creds) {
  return axios({
    url: `/hpmc/api/${endUrl}`,
    method: "post",
    data: creds,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getGroupPermission(id) {
  return axios({
    url: `/hpmc/api/getauthorization/group/${id}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getUsersPermission(id) {
  return axios({
    url: `/hpmc/api/getauthorization/user/${id}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function bulkUploadMaster(url, creds) {
  const formData = new FormData();
  formData.append("bulkUploadCsv", creds);
  return axios({
    url: `/hpmc/api/bulkuploadcorporatemaster`,
    method: "post",
    data: formData,
    headers: {
      "content-type": "multipart/form-data",
    },
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteSingleMaster(endUrl, creds) {
  return axios({
    url: `/hpmc/api/${endUrl}`,
    method: "post",
    data: creds,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function editMasterDetail(endUrl, creds) {
  return axios({
    url: `/hpmc/api/${endUrl}`,
    method: "put",
    data: creds,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export function updateAuthGroupPermission(endUrl, creds) {
  return axios({
    url: `/hpmc/api/setauthorization/group/${endUrl}`,
    method: "post",
    data: creds,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export function updateAuthUserPermission(endUrl, creds) {
  return axios({
    url: `/hpmc/api/setauthorization/user/${endUrl}`,
    method: "post",
    data: creds,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getMISReport(fileType, startDate, endDate) {
  let url = `/hpmc/api/audittrailDateRange${fileType}?startdate=${startDate}&enddate=${endDate}`;
  window.open(url, "_blank", "noopener,noreferrer");
}

export function getAllUserInGroups(id) {
  return axios({
    url: `/hpmc/api/groupmanagement/getallusers/${id}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getAllUserInRoles(id) {
  return axios({
    url: `/hpmc/api/rolemanagement/getallusers/${id}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getMISReportAudit(
  searchingQuery,
  startDate,
  endDate,
  page,
  perPage,
  sort,
  accessor
) {
  return axios({
    url: `/hpmc/api/report/audit?sortBy=${accessor}&orderBy=${sort}&startDate=${startDate}&endDate=${endDate}&query=${searchingQuery}&pageNumber=${page}&pageSize=${perPage}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function CSVDownloadMISReportPage(
  baseUrlCSV,
  searchingQuery,
  startDate,
  endDate,
  csvDownload
) {
  let url = `/hpmc/api/report/${baseUrlCSV}?startDate=${startDate}&endDate=${endDate}&query=${searchingQuery}&exportCsv=${csvDownload}`;
  window.open(url, "_blank", "noopener,noreferrer");
}

export function PDFDownloadMISReportPage(
  baseUrlPDF,
  searchingQuery,
  startDate,
  endDate,
  csvDownload
) {
  let url = `/hpmc/api/report/${baseUrlPDF}?startDate=${startDate}&endDate=${endDate}&query=${searchingQuery}&exportPdf=${csvDownload}`;
  window.open(url, "_blank", "noopener,noreferrer");
}

// corporate report fr
export function getAllCorporateReportFR(
  searchingQuery,
  startDate,
  endDate,
  page,
  perPage,
  sort,
  accessor
) {
  return axios({
    url: `/hpmc/api/report/fr/corporate?sortBy=${accessor}&orderBy=${sort}&startDate=${startDate}&endDate=${endDate}&query=${searchingQuery}&pageNumber=${page}&pageSize=${perPage}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// corporate CHO Auto populate fr
export function getAllCorporateAutoPopulate(
  searchingQuery,
  startDate,
  endDate,
  page,
  perPage,
  sort,
  accessor
) {
  return axios({
    url: `/hpmc/api/report/choautopopulate?sortBy=${accessor}&orderBy=${sort}&startDate=${startDate}&endDate=${endDate}&query=${searchingQuery}&pageNumber=${page}&pageSize=${perPage}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getAllAPIReport(
  searchingQuery,
  startDate,
  endDate,
  page,
  perPage,
  sort,
  accessor
) {
  return axios({
    url: `/hpmc/api/report/api-report?sortBy=${accessor}&orderBy=${sort}&startDate=${startDate}&endDate=${endDate}&query=${searchingQuery}&pageNumber=${page}&pageSize=${perPage}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getAllUserReport(
  searchingQuery,
  startDate,
  endDate,
  page,
  perPage,
  sort,
  accessor
) {
  return axios({
    url: `/hpmc/api/report/user-report?sortBy=${accessor}&orderBy=${sort}&startDate=${startDate}&endDate=${endDate}&query=${searchingQuery}&pageNumber=${page}&pageSize=${perPage}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
