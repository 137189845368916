import React from "react";
import Classes from "./ScrutinySubmitModal.module.css";
import { useTranslation } from "react-i18next";
import Modal from "../../../../../components/Modal/Modal";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import accepfileIcon from "../../../../../assets/accept_document.svg";
import downloadIcon from "../../../../../assets/download_icon.svg";
import circularCheck from "../../../../../assets/circularCheckOutline.svg";
import { memo } from "react";
const ScrutinySubmitModal = ({
  isModalOpen,
  setIsModalOpen,
  corpDetails,
  handleSubmitFunctionCRA,
  handleSubmitFunctionSELF,
  handleZipDownloadFunction,
}) => {
  const { t } = useTranslation();

  var closeBtnStyle = {
    backgroundColor: "rgb(255, 255, 255)",
    width: "2.5rem",
    height: "2.5rem",
    display: "flex",
    alignItems: "center",
    top: "-0.938rem",
    justifyContent: "center",
    right: "-1.125rem",
    cursor: "pointer",
    borderRadius: "50%",
  };
  return (
    isModalOpen && (
      <Modal closeBtn={closeBtnStyle} onClose={() => setIsModalOpen(false)}>
        <div className={"p-4" + " " + Classes.rejected_modal}>
          <div className="d-flex align-items-center justify-content-between flex-column">
            <h5>
              <img src={accepfileIcon} className={Classes.rejected_svg} />{" "}
              Corporate Application
            </h5>
            <div
              className={" " + " " + Classes.modal_acknowledgement_badge}
              disabled
            >
              Acknowledgement ID : {corpDetails.ackId}
            </div>
          </div>
          <div
            className={
              "mt-4 d-flex align-items-center justify-content-between" +
              " " +
              Classes.reject_document_name
            }
          >
            <p>
              <i>
                <img
                  src={circularCheck}
                  className={Classes.circular_check_svg}
                />
              </i>{" "}
              This application has no rejected document.
            </p>
            <div
              onClick={handleZipDownloadFunction}
              className={Classes.download_zip_button}
            >
              <img src={downloadIcon} alt="Download Zip" />
              &ensp; Download Zip
            </div>
          </div>
          <div className={Classes.corpoDetails_box + " " + "mt-3 mb-5"}>
            <div className={Classes.details_box}>
              <div className={Classes.details}>
                <span className={Classes.light_grey_color}>Corporate Name</span>
                &ensp;
                <span
                  className={
                    Classes.black_color +
                    " " +
                    "font-weight-bold text-capitalize"
                  }
                >
                  {" "}
                  {corpDetails?.corpName}
                </span>
              </div>
              <div className={Classes.details + " " + "text-center"}>
                <span className={Classes.light_grey_color}>TAT </span>
                &ensp;{" "}
                <span
                  className={Classes.black_color + " " + "font-weight-bold"}
                >
                  {corpDetails?.tat} {corpDetails?.tat > 1 ? "Days" : "Day"}
                </span>
              </div>

              <div className={Classes.details}>
                <span className={Classes.light_grey_color}>
                  Relationship Manager
                </span>
                &ensp;{" "}
                <span
                  className={
                    Classes.black_color +
                    " " +
                    "font-weight-bold text-capitalize"
                  }
                >
                  {corpDetails?.submitter && corpDetails?.submitter?.username}
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mt-3 px-0">
            <p
              className={
                Classes.sm_font_bold + " " + "mb-3 " + Classes.black_color
              }
            >
              Do you want Send this application to CRA via mail?
            </p>
            <div className="btn-group ml-auto mr-3">
              <PrimaryButton
                onClick={() => {
                  handleSubmitFunctionCRA();
                  handleZipDownloadFunction();
                }}
                label={t("Submit")}
              />
            </div>
            {/* <div className="btn-group ml-auto mr-3">
                <PrimaryButton
                  label={t("No, I Will Send Myself")}
                  onClick={()=>{handleSubmitFunctionSELF();handleZipDownloadFunction()}}
                />
              </div> */}
            <div className="btn-group mr-3">
              <SecondaryButton
                label={t("Close")}
                onClick={() => setIsModalOpen(false)}
              />
            </div>
          </div>
        </div>
      </Modal>
    )
  );
};

export default memo(ScrutinySubmitModal);
