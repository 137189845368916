import React, { useEffect } from 'react';
import Classes from "./WorkFlowRoadMap.module.css";
import { useState } from 'react';
import documentIcon from "../../assets/file_icon.svg";
import greenClaimIcon from "../../assets/ActionBtnIcon/ColorfulIcons/green_Claim_icon.svg";
import welcomeKitIcon from "../../assets/ActionBtnIcon/gift.svg";
import greenWelcomeKitIcon from "../../assets/ActionBtnIcon/ColorfulIcons/green_welcome_icon.svg";
import yellowWelcomeKitIcon from "../../assets/ActionBtnIcon/ColorfulIcons/yellow_welcome_kit_icon.svg";
import greenUpdateCHOIcon from "../../assets/ActionBtnIcon/ColorfulIcons/green_update_cho_icon.svg";
import redUpdateCHOIcon from "../../assets/ActionBtnIcon/ColorfulIcons/red_update_cho_icon.svg";
import greenScrutinyIcon from "../../assets/ActionBtnIcon/ColorfulIcons/green_scrutiny_icon.svg";
import defaultScrutinyIcon from "../../assets/ActionBtnIcon/scrutiny_icon.svg";
import redScrutinyIcon from "../../assets/ActionBtnIcon/ColorfulIcons/red_scrutiny_icon.svg";
import yellowScrutinyIcon from "../../assets/ActionBtnIcon/ColorfulIcons/yellow_scrutiny_icon.svg";
import greenCorporateUploadIcon from "../../assets/ActionBtnIcon/ColorfulIcons/green_upload_icon.svg";
import yellowCorporateUploadIcon from "../../assets/ActionBtnIcon/ColorfulIcons/yellow_upload_icon.svg";
import defaultCorporateUploadICon from "../../assets/ActionBtnIcon/updatedoc_icon.svg";
import redCorporateUploadIcon from "../../assets/ActionBtnIcon/ColorfulIcons/red_upload_icon.svg";
import greenDelegateIcon from "../../assets/ActionBtnIcon/ColorfulIcons/green_delegate_icon.svg";
import greenReleasedIcon from "../../assets/ActionBtnIcon/ColorfulIcons/green_release_icon.svg";
import greenTaskAssignedIcon from "../../assets/ActionBtnIcon/ColorfulIcons/task_assigned.svg"


import yellowFormSubmitIcon from "../../assets/ActionBtnIcon/ColorfulIcons/yellow_form_submit_icon.svg";
import defaultFormSubmitIcon from "../../assets/ActionBtnIcon/register_icon_inactive.svg";
import greenFormSubmitIcon from "../../assets/ActionBtnIcon/ColorfulIcons/green_form_submit_icon.svg";


import yellowFormSaveIcon from "../../assets/ActionBtnIcon/ColorfulIcons/yellow_form_save_icon.svg";
import defaultFormSaveIcon from "../../assets/ActionBtnIcon/ColorfulIcons/default_form_save_icon.svg";
import greenFormSaveIcon from "../../assets/ActionBtnIcon/ColorfulIcons/green_form_save_icon.svg";
import ackIDGenerateIcon from "../../assets/ActionBtnIcon/ColorfulIcons/ack_generate_icon.svg";


import yellowChargeScruitnyIcon from "../../assets/ActionBtnIcon/ColorfulIcons/scruitnyCharge_yellow.svg";
import defaultChargeScruitnyIcon from "../../assets/scrutinyCharge.svg";
import greenChargeScruitnyIcon from "../../assets/ActionBtnIcon/ColorfulIcons/scruitnyCharge_green.svg";
import redChargeScruitnyIcon from "../../assets/ActionBtnIcon/ColorfulIcons/scruitnyCharge_red.svg";
import yellowUpdateChargeIcon from "../../assets/ActionBtnIcon/ColorfulIcons/updateCharge_yellow.svg";
import greenUpdateChargeIcon from "../../assets/ActionBtnIcon/ColorfulIcons/updateCharge_green.svg";

import defaultUpdateChargeIcon from "../../assets/updateCharge.svg";


import Utils from '../../Utils';
import { memo } from 'react';
var hasNext = false;
const WorkFlowRoadMap = ({ data }) => {
    const [workFlowArray, setWorkFlowArray] = useState([]);
    const [icons, setIcons] = useState({
        greenBox15: greenClaimIcon,
        greenBox18: greenDelegateIcon,
        greenBox17: greenTaskAssignedIcon,
        greenBox16: greenReleasedIcon,
        greenBox14: greenWelcomeKitIcon,
        defaultBox14 : welcomeKitIcon,
        yellowBox14 : yellowWelcomeKitIcon,
        greenBox13: greenUpdateCHOIcon,
        redBox12: redUpdateCHOIcon,
        defaultBox8 :defaultScrutinyIcon,
        yellowBox8: yellowScrutinyIcon,
        greenBox8 : greenScrutinyIcon,
        redBox9: redScrutinyIcon,

        greenBox4: greenCorporateUploadIcon,
        defaultBox4 :defaultCorporateUploadICon,
        redBox4: redCorporateUploadIcon,
        yellowBox4 : yellowCorporateUploadIcon,
        greenBox11: greenCorporateUploadIcon,
        defaultBox11 :defaultCorporateUploadICon,
        yellowBox11 : yellowCorporateUploadIcon,

        yellowBox5 : yellowChargeScruitnyIcon,
        greenBox5: greenChargeScruitnyIcon,
        redBox6: redChargeScruitnyIcon,
        defaultBox5: defaultChargeScruitnyIcon,  
        yellowBox7 : yellowUpdateChargeIcon,
        greenBox7: greenUpdateChargeIcon,  
        defaultBox7: defaultUpdateChargeIcon,   
        yellowBox3 : yellowFormSubmitIcon,
        defaultBox3 : defaultFormSubmitIcon,
        greenBox3 : greenFormSubmitIcon,
        yellowBox2 : yellowFormSaveIcon,
        defaultBox2 : defaultFormSaveIcon,
        greenBox2 : greenFormSaveIcon,
        greenBox1 :ackIDGenerateIcon

    })

    useEffect(() => {
        setWorkFlowArray(data);
    }, [])
    const getDefaultFirst = (e) => {
        return e.find((data) => data.id === null);
    };
    const getIconsandColors = (e) => {
        let copyArr = [...workFlowArray];
        const greenIds = [1, 2, 3, 4, 5,7,8, 11,13, 14, 15,16, 17,18];

        const redIds =[9,12, 6];
        let lastElemCopyArr = getDefaultFirst(copyArr);
        let obj = {
            color: "defaultBox",
            colorClass: Classes.defaultBox,
            icons: documentIcon,
            text: undefined
        };



        if (e.id != null) {

            if (redIds.includes(e.corporateAnalyticsActionId)) {
                obj.color = "redBox";
                obj.colorClass = Classes.redBox;
                obj.text = e.corporateAnalyticsActionName;
            }
           else  if (greenIds.includes(e.corporateAnalyticsActionId)) {
                obj.color = "greenBox";
                obj.colorClass = Classes.greenBox;
                obj.text = e.corporateAnalyticsActionName;
            }
        }
        else if (e === lastElemCopyArr) {
            obj.colorClass = Classes.yellowBox;
            obj.color = "yellowBox";
            obj.text = `Pending ${e.corporateAnalyticsActionName}`;

        }
        obj.icons = icons[`${obj.color + "" + e.corporateAnalyticsActionId}`]
        return obj;


    };



  

    return (
        <>
            <div className='container-fluid px-5 pt-5 bg-white'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className={Classes.milestone_box_container}>
                            {workFlowArray.reduce((rows, item, index) => {
                                if (index % 4 === 0) {
                                    rows.push([]);
                                }
                                if (index / 4 > 1) {
                                    hasNext = true;
                                }
                                rows[rows.length - 1].push(item);
                                return rows;
                            }, []).map((row, rowIndex) => (
                                <div key={rowIndex} className={`row  ${workFlowArray.length / 4 > rowIndex + 1 ? Classes.hasNext : Classes.lastRow}    ${rowIndex % 2 === 1 ? `${Classes.reverse} flex-row-reverse` : `${Classes.straight}`}`}>
                                    {row.map((item, colIndex) => (
                                        <div className={`${Classes.milestone_box} ${getIconsandColors(item).colorClass}  ${Classes.milestone_y15}`} key={colIndex}>
                                            
                                            <div className={Classes.milestone_upperDiv}>
                                                <div className={Classes.actionDate} style={{ visibility: item.id != null ? "visible" : "hidden" }}>{ Utils.getDateFromDateTime(item.actionDate)}</div>


                                                <div className={Classes.actionNameStatus} style={{ visibility: getIconsandColors(item).text != undefined ? "visible" : "hidden" }}> { getIconsandColors(item).text || item.corporateAnalyticsActionName} </div>

                                                {/* <img src=}img/year2015.svg} alt=}img} /> */}
                                            </div>
                                            <div className={Classes.actionIcon}><div className={Classes.iconImg}> <img src={getIconsandColors(item).icons} /></div></div>
                                            <div className={Classes.milestone_content} style={{ borderTop: "0 !important" }}>
                                                <h4 className={Classes.milestone_year} style={{ visibility: item.id != null ? "visible" : "hidden" }} >{ item.byUserUsername || item.corporateAnalyticsActionName}</h4>
                                                {/* <p className={Classes.actionUserName} style={{ visibility: item.id != null ? "visible" : "hidden" }} >{" User ONE"}</p> */}

                                                <div className={Classes.actionTime} style={{ visibility: item.id != null ? "visible" : "hidden" }} >{Utils.getTimeHHMMFromDateTime(item.actionDate)} </div>
                                            </div>
                                        </div>



                                    ))}

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* </div > */}

        </>
    )
}

export default memo(WorkFlowRoadMap);





