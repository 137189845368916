import React from "react";
import Modal from "../../../components/Modal/Modal";
import Classes from "./UpdateCorporateApplication.module.css";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Utils from "../../../Utils";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import { useEffect } from "react";
import {
  getCorporateDetails,
  sendCorpWelcomeKit,
  UpdateCHOFunction,
} from "../CorporateAction";
import congratsImage from "../../../assets/congratulation.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  BASE_URL,
  DASHBOARD_URL,
  CORPORATEDASHBOARD_URL,
  SCRUTINY_FORM_URL,
} from "../../../Routes/Urls";
import { setAlertMsg } from "../../Home/HomeSlice";
import { ChartsYAxis } from "@mui/x-charts";

const UpdateCorporateApplication = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [choInput, setChoInput] = useState("");
  const [cboInput, setCboInput] = useState("");
  const corpId = JSON.parse(localStorage.getItem("corpId"));
  const [isModalActive, setIsModalActive] = useState(false);
  const { t } = useTranslation();
  const [corpDetailsObj, setCorpDetailsObj] = useState({
    corporateName: "",
    ackId: "",
    date: "",
    tat: null,
    isPOPShift: null,
  });

  useEffect(() => {
    getCorporateDetails(corpId).then((res) => {
      {
        if (res.data.success === true) {
          setCorpDetailsObj({
            corporateName: res.data.data.corpData.corpName,
            ackId: res.data.data.corpData.ackId,
            date: Utils.convertToLocalDateOnly(
              res.data.data.corpData.submissionDate
            ),
            tat: res.data.data.corpData.tat,
            isPOPShift: res.data.data.corpData.isPopShift,
          });
          if (res?.data?.data?.corpFormData?.isPopShift === "true") {
            setChoInput(res.data?.data?.corpFormData.popChngChoNum);
          }
        }
      }
    });
  }, []);

  const UpdateCHOProceed = () => {
    // const obj = corpDetailsObj.isPOPShift
    //   ? {
    //     choNumber : choInput,
    //       cboNumber: cboInput,
    //     }
    //   : {
    //       choNumber: choInput,
    //       cboNumber : cboInput
    //     };

    const obj = {
      choNumber: choInput,
      cboNumber: cboInput,
    };

    UpdateCHOFunction(`${corpId}`, obj)
      .then((res) => {
        if (res?.data?.success === true) {
          dispatch(
            setAlertMsg({
              msg: `Update ${
                corpDetailsObj.isPOPShift ? "CBO" : "CHO"
              }   CR${corpId} successfully`,
              alertType: "success",
            })
          );
          setIsModalActive(true);
        } else if (
          res?.response?.data?.success === false &&
          res?.response?.status === 409
        ) {
          dispatch(
            setAlertMsg({
              msg: res?.response?.data?.message,
              alertType: "danger",
            })
          );
        } else {
          dispatch(
            setAlertMsg({
              msg: `Unable to update ${corpId}`,
              alertType: "danger",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.internal_server_error"),
            alertType: "danger",
          })
        );
      });
  };

  //  navigate to dashboard table
  const handleLaterBtn = () => {
    setIsModalActive(false);
    navigate(`/${BASE_URL}/${DASHBOARD_URL}/${CORPORATEDASHBOARD_URL}`);
  };

  // send welcome kit
  const handleWelcomeKit = () => {
    sendCorpWelcomeKit(corpId)
      .then((res) => {
        if (res.data.success === true) {
          navigate(`/${BASE_URL}/${DASHBOARD_URL}/${CORPORATEDASHBOARD_URL}`);
          dispatch(
            setAlertMsg({
              msg: `Corporate  CR${corpId} is successfully onboarded`,
              alertType: "success",
            })
          );
        } else {
          dispatch(
            setAlertMsg({
              msg: `Unable to onboard the corporate`,
              alertType: "danger",
            })
          );
        }
      })
      .catch((error) =>
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.internal_server_error"),
            alertType: "danger",
          })
        )
      );
  };

  // navigate to scrutiny

  const handleFR = () => {
    navigate(
      `/${BASE_URL}/${DASHBOARD_URL}/${CORPORATEDASHBOARD_URL}/${SCRUTINY_FORM_URL}`
    );
  };

  //  handle cho input
  const handleCHOInput = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = Utils.handlePreventNumberFromDecimal(inputValue);
    const choValue = sanitizedValue.slice(0, 7);
    setChoInput(choValue);
  };

  //  handle disable
  const handleDisable = () => {
    if (corpDetailsObj?.isPOPShift) {
      return cboInput?.length > 0;
    } else {
      return choInput?.length === 7;
    }
  };

  const handleCBOInput = (e) => {
    setCboInput(PreventSpecialCharacterForNumber(e));
  };

  const PreventSpecialCharacterForNumber = (e) => {
    const newValue = e.target.value;
    const pattern = /^[0-9,]+$/;
    let sanitizedValue = newValue?.replace(/[^0-9,]+/g, ""); // Remove non-numeric characters

    if (!pattern.test(sanitizedValue)) {
      e.preventDefault();
      e.target.value = sanitizedValue;
      return;
    } else {
      e.target.value = sanitizedValue;
      return sanitizedValue;
    }
  };

  return (
    <section className={Classes.cho_page_form}>
      <div className="container-fluid px-0">
        <GoBackButton />
        <div className="row my-3">
          <div className="col-lg-12">
            <div className={Classes.page_header}>
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="mr-5 mb-0 text-capitalize">
                  Update corporate application
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid h-75">
        <div className="row" style={{ marginBottom: "3px" }}>
          <div className="col-lg-12">
            <div className="card border-0">
              <div className="py-4 px-5">
                <div className={Classes.corpoDetails_box + " " + "mt-3"}>
                  <div className={Classes.corpoHeading}>Corporate Details</div>
                  <div className={Classes.details_box}>
                    <div className={Classes.details}>
                      Corporate Name :{" "}
                      <span
                        className={
                          Classes.light_grey_color + " " + "font-weight-bold"
                        }
                      >
                        {corpDetailsObj.corporateName}
                      </span>
                    </div>

                    <div className={Classes.details + " " + "text-center"}>
                      Acknowledegement Id :{" "}
                      <span
                        className={
                          Classes.light_grey_color + " " + "font-weight-bold"
                        }
                      >
                        {corpDetailsObj.ackId}
                      </span>
                    </div>
                    {corpDetailsObj.tat != null ? (
                      <div className={Classes.details + " " + "text-center"}>
                        TAT :{" "}
                        <span
                          className={
                            Classes.light_grey_color + " " + "font-weight-bold"
                          }
                        >
                          {corpDetailsObj.tat}{" "}
                          {corpDetailsObj.tat > 1 ? "Days" : "Day"}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      className={
                        corpDetailsObj.tat == null
                          ? `text-center  ${Classes.details}`
                          : Classes.details
                      }
                    >
                      Date :{" "}
                      <span
                        className={
                          Classes.light_grey_color + " " + "font-weight-bold"
                        }
                      >
                        {corpDetailsObj.date}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-lg-12 ">
            <div className="card  border-0">
              <div className="py-4 px-5">
                <div className="row g-3 align-items-center mt-3">
                  <div className="col-md-12">
                    <div className={Classes.form_labels_heading + " " + "mb-2"}>
                      Update CHO/CBO Number
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-8 col-12">
                    <div className="form-row">
                      <label
                        className={
                          corpDetailsObj.isPOPShift
                            ? null
                            : Classes.mandatoryCheck
                        }
                      >
                        ADD CHO
                      </label>
                      <input
                        type="text"
                        value={choInput}
                        name="CHOnumber"
                        placeholder="Ex.111-222-3"
                        className="form-control w-100"
                        disabled={corpDetailsObj.isPOPShift ? true : false}
                        onInput={handleCHOInput}
                      />
                      <div className={Classes.input_text}>
                        Write {corpDetailsObj.isPOPShift ? "CBO " : "CHO "}{" "}
                        Number generated by CRA
                      </div>
                    </div>
                    <div className="form-row mt-3">
                      <label
                        className={
                          corpDetailsObj.isPOPShift
                            ? Classes.mandatoryCheck
                            : null
                        }
                      >
                        ADD CBO{" "}
                        {corpDetailsObj.isPOPShift ? null : "(Optional)"}
                      </label>
                      <input
                        type="text"
                        value={cboInput}
                        name="CBOnumber"
                        placeholder="Ex.111-222-333"
                        className="form-control w-100"
                        onInput={handleCBOInput}
                      />
                      <div className={Classes.input_text}>
                        Write CBO Number generated by CRA
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 py-3 px-0">
                  <div className="col-lg-12 mt-3 px-0">
                    <div className="btn-group ml-auto mr-3">
                      <SecondaryButton
                        onClick={handleLaterBtn}
                        label={t("Cancel")}
                      />
                    </div>
                    <div className="btn-group ml-auto mr-3">
                      <SecondaryButton
                        onClick={handleFR}
                        label={t("Raise FR")}
                      />
                    </div>
                    <div className="btn-group mr-3">
                      <PrimaryButton
                        disabled={!handleDisable()}
                        onClick={UpdateCHOProceed}
                        label={t("Update Corporate ")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalActive && (
        <Modal>
          <div className={`${Classes.cho_page_modal_box} p-4`}>
            <div className="col-lg-12 px-0">
              <div className="congrats_box">
                <div className={Classes.img_box}>
                  <img src={congratsImage} width="100%" />
                </div>
                <h5 className="font-weight-bold text-center">
                  <strong> Congratulations !</strong>
                </h5>
                <div className={Classes.form_labels}>
                  {" "}
                  {corpDetailsObj?.isPOPShift === true ? "CBO " : "CHO "} Number
                  : {corpDetailsObj.isPOPShift ? cboInput : choInput}
                </div>
                <p className={Classes.sm_font_bold + " " + Classes.text_bg_box}>
                  The application is accepted. corporate "{" "}
                  {corpDetailsObj.corporateName}" has been onboard successfully
                  in the NPA System!{" "}
                </p>
                {/* <div className="row g-3 align-items-center mt-3">
                  <div className="col-auto">
                    <label className={Classes.form_labels}>
                      Corporate CHO Number
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-8 col-12">
                    <input
                      type="text"
                      value={input}
                      name="CHOnumber"
                      placeholder="Ex.111-222-333"
                      className="form-control w-100"
                      readOnly
                    />
                  </div>
                </div> */}
              </div>
            </div>

            {/* <hr /> */}
            <div className="col-lg-12 mt-4 px-0">
              <p
                className={`${Classes.sm_font_bold} ${Classes.text_black}  pb-2`}
              >
                Send welcome kit to corporate via mail
              </p>

              <div className="btn-group mr-3">
                <SecondaryButton
                  label={t("No, Later")}
                  onClick={handleLaterBtn}
                />
              </div>
              <div className="btn-group ml-2 mr-3">
                <PrimaryButton
                  onClick={handleWelcomeKit}
                  label={t("Yes, Send Welcome Kit ")}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </section>
  );
};

export default UpdateCorporateApplication;
