import axios from "axios";

export function getDownloadContributionFile(id) {
  const url = `/hpmc/api/download/contribution/corporate/${id}`;
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");

  return new Promise((resolve, reject) => {
    const checkDownloadStatus = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(checkDownloadStatus);
        resolve();
      }
    }, 1000);

    newWindow.onerror = () => {
      clearInterval(checkDownloadStatus);
      reject(new Error("Download failed"));
    };
  });
}

// contribution upload documents
export function contributionUploadDocumentApi(data) {
  return axios({
    url: `/hpmc/api/contribution/upload`,
    method: "post",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// contribution upload documents
export function checkIfCorporateOnboarded() {
  return axios({
    url: `/hpmc/api/contribution/check-corporate-onboarded`,
    method: "get",
    responseType: "json",
  });
}

export function getContributionData() {
  return axios({
    url: `/hpmc/api/report/contribution/corporate`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
