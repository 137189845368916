import React from "react";
import classes from "../MasterManagement.module.css";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import { useState } from "react";
import ArrowUPIcon from "../../../../assets/arrow-up.svg";
import PenEditIcon from "../../../../assets/pen_edit.svg";
import { useEffect } from "react";
import {
  getAllPFMMasters,
  getPFMMasters,
  editClaimUnclaim,
  makerCheckerFlow,
} from "../../AdminManagementActions";
import TextButton from "../../../../components/Buttons/TextButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import Modal from "../../../../components/Modal/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMsg } from "../../../Home/HomeSlice";
import Utils from "../../../../Utils";

const PFMSManagement = ({
  searchingQuery,
  dateOne,
  dateTwo,
  perPage,
  isWorkflowEnabled,
  masterId,
}) => {
  const userDetails = useSelector((state) => state.loginUserDetails);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("");
  const [accessor, setAccessor] = useState("");
  const [columnRotations, setColumnRotations] = useState("submissionDateasc");
  const [tableData, setTableData] = useState([]);
  const [pageData, setPageData] = useState({});
  const [filterBy, setfilterBy] = useState("");
  const [pFMFormActive, setPFMFormActive] = useState(false);
  const [editPFMName, seteditPFMName] = useState("");
  const [changedPFMInput, setChangedPFMInput] = useState({
    pfmName: "",
  });
  const [rowId, setRowId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  // holiday table columns
  const pfmTableColumns = [
    {
      header: (
        <p className={classes.headers + " " + "text-left"}>
          PFM NAME
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              textAlign: "left",
              transform:
                columnRotations === "nameasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("name")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.name,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 100 },
    },

    {
      header: <p className={classes.headers}>ACTION</p>,
      key: "corpAction",
      accessor: (e) => (
        <div className={classes.action_btn}>
          {isWorkflowEnabled && (
            <TextButton
              style={{
                display:
                  e.makerCheckerStatus === null ||
                  e.makerCheckerStatus === 0 ||
                  e?.currentMaker?.username !== userDetails.username ||
                  (e.makerCheckerFlowFunction == 1 &&
                    e?.currentMaker?.username !== userDetails.username)
                    ? "none"
                    : "",
              }}
              // disabled={
              //   //  if status is 1 and currentmaker user not equal to login user or admin then disable
              //   // e.makerCheckerStatus === 2 ||
              //   e.makerCheckerStatus === 1 &&
              //   e?.currentMaker?.username !== userDetails.username &&
              //   userDetails.username !== "wbadmin"
              // }
              disabled={
                e.makerCheckerStatus === 2 ||
                (e.makerCheckerStatus === 1 &&
                  e?.currentMaker?.username !== userDetails.username)
              }
              onClick={() => {
                editClaimUnclaimFunction(e.id, true);
                setRowId(e.id);
              }}
              label={t("button.release")}
            />
          )}

          <TextButton
            disabled={
              e.makerCheckerStatus === 2 ||
              (e.makerCheckerStatus === 1 &&
                e?.currentMaker?.username !== userDetails.username &&
                userDetails.username != "wbadmin")
            }
            onClick={() => {
              isWorkflowEnabled == true &&
              (e.makerCheckerStatus == 0 || e.makerCheckerStatus == null) ? (
                <>{(setIsEdit(true), editPFMSFunctionData(e.id))}</>
              ) : (
                <>{(setPFMFormActive(true), editPFMSFunctionData(e.id))}</>
              );
            }}
            label={t("button.edit")}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    setPage(0);
  }, [perPage, searchingQuery]);
  useEffect(() => {
    getAllPFMMastersFunction();
  }, [sort, accessor, dateOne, dateTwo, page, perPage, searchingQuery]);

  const getAllPFMMastersFunction = () => {
    getAllPFMMasters({
      page,
      searchingQuery,
      sort,
      accessor,
      perPage,
      filterBy,
    }).then((res) => {
      setTableData(res.data.data.content);
      setPageData(res.data.data);
    });
  };

  const handleSort = (key) => {
    var sorting = sort;
    const newSort = sort === "asc" ? "desc" : "asc";
    setSort(newSort);
    setAccessor(key);
    if (sort === "asc") {
      setColumnRotations(key + "desc");
    } else {
      setColumnRotations(key + "asc");
    }
  };

  const editPFMSFunctionData = (id) => {
    setRowId(id);
    getPFMMasters(id).then((res) => {
      let p_name = res.data.data.masterData.name;
      seteditPFMName(p_name);
      setChangedPFMInput({
        id: id,
        pfmName: p_name,
      });
    });
  };

  const editClaimUnclaimFunction = (rowId, isReleased) => {
    editClaimUnclaim(masterId, rowId)
      .then((res) => {
        if (isReleased == false) {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.task_claimed"),
              alertType: "success",
            })
          );
          setPFMFormActive(true);
          setIsEdit(false);
        } else {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.task_unclaimed"),
              alertType: "success",
            })
          );
          getAllPFMMastersFunction();
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.task_claim_fail"),
            alertType: "danger",
          })
        );
      });
  };
  const makerCheckerFlowFunction = () => {
    let data = {
      name: Utils.removeWhiteSpaceFromBetween(editPFMName),
    };
    makerCheckerFlow(masterId, rowId, data)
      .then((res) => {
        if (res.data.success === true) {
          dispatch(
            setAlertMsg({
              msg:
                isWorkflowEnabled === true
                  ? t("alertMsg.changes_for_approval")
                  : t("alertMsg.edit_successfully"),
              alertType: "success",
            })
          );

          setPFMFormActive(false);
          getAllPFMMastersFunction();
        } else {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.update_failed"),
              alertType: "danger",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.update_failed"),
            alertType: "danger",
          })
        );
      });
  };
  return (
    <>
      <CustomTable
        page={page}
        setPage={setPage}
        headings={pfmTableColumns}
        data={tableData}
        pageData={pageData}
        sort={sort}
        setSort={setSort}
        accessor={accessor}
        setAccessor={setAccessor}
        srNO={true}
      />
      {/* for holiday edit */}

      {isEdit == true && isWorkflowEnabled == true && (
        <Modal>
          <div className={classes.editModal_container}>
            <h4>
              <span>
                <img src={PenEditIcon} alt="pen edit Master management" />
              </span>{" "}
              {t("proceed.edit.master")}
            </h4>
            <p>{t("proceed.edit.master.text")}</p>
            <div className={classes.editModal_btn}>
              <PrimaryButton
                onClick={() => {
                  editClaimUnclaimFunction(rowId, false);
                }}
                label={t("button.proceed")}
              />
              <TextButton
                onClick={() => {
                  setIsEdit(false);
                }}
                label={t("button.close")}
              />
            </div>
          </div>
        </Modal>
      )}

      {pFMFormActive && (
        <Modal
          closeBtn={{}}
          onClose={() => {
            getAllPFMMastersFunction();
            setPFMFormActive(false);
          }}
        >
          <div className={classes.holiday_modal_body}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-input">
                  <label>PFM Name</label>
                  <input
                    type="text"
                    name="holiday_name"
                    value={editPFMName}
                    onInput={(e) => {
                      seteditPFMName(e.target.value);
                    }}
                    className="form-control"
                  />
                </div>

                <div className="btn-group mt-4">
                  <div className="btn-form mr-3">
                    <PrimaryButton
                      disabled={
                        Utils.removeWhiteSpaceFromBetween(
                          editPFMName
                        ).toLocaleLowerCase() ===
                          Utils.removeWhiteSpaceFromBetween(
                            changedPFMInput.pfmName
                          ).toLocaleLowerCase() ||
                        Utils.removeWhiteSpaceFromBetween(editPFMName) == ""
                          ? true
                          : false
                      }
                      label={t("button.submit")}
                      onClick={() => {
                        makerCheckerFlowFunction(changedPFMInput.id);
                        // changePFMFunction(changedPFMInput.id);
                      }}
                    />
                  </div>
                  <div className="btn-form">
                    <SecondaryButton
                      onClick={() => {
                        getAllPFMMastersFunction();
                        setPFMFormActive(false);
                      }}
                      label={t("button.cancel")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PFMSManagement;
