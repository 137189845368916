import React, { useCallback, useEffect, useState } from "react";
import Classes from "./MakerCheckerMaster.module.css";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import Utils from "../../../../Utils";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import SwitchToggle from "../../../../components/SwitchToggle/SwitchToggle";
import TextField from "@mui/material/TextField";
import {
  getAllRoles,
  getAllGroups,
  getAllMasters,
  updateMakerCheckerMaster,
  getRolesViaGroup,
} from "../../AdminManagementActions";
import GoBackButton from "../../../../components/Buttons/GoBackButton";
import { setAlertMsg } from "../../../Home/HomeSlice";
import { useDispatch } from "react-redux";

const MakerCheckerMaster = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [accessor, setAccessor] = useState("ackId");
  const [groupOrRole, setGroupOrRole] = useState({
    group: "group",
    role: "role",
  });

  const [tableData, setTableData] = useState([]);
  const [initialTable, setInitialTable] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [disableBtn, setDisableBtn] = useState(true);

  useEffect(() => {
    // getAllRoles().then((res) => {
    //   setRoleOptions(res.data.data);
    // });
    getAllGroups().then((res) => {
      setGroupOptions(res.data.data);
    });
    getAllMasters().then((res) => {
      setTableData(res.data.data);
      setInitialTable(res.data.data);
    });
  }, []);
  // useEffect(() => {
  //   for (var data of tableData) {
  //     selectRolesFunction(data.groupId, data.id, true, data.roleId);
  //   }
  // });

  // check btn will be disable or not
  const checkSavedDisaleorNot = () => {
    let enabledData = tableData.filter((e) => e.isWorkflowEnabled === true);
    let check = enabledData.every((arr, index) => {
      return arr.roleId != null && arr.groupId != null;
    });

    setDisableBtn(!check);
  };

  const handleSelectGroupChange = (e, id) => {
    let arrIndex = findIndexOfData(tableData, id);
    let groupIndex = findIndexOfData(groupOptions, e);
    var temp = tableData[arrIndex];
    temp.groupId = Number(e);
    temp.groupName = groupOptions[groupIndex].groupName;
    temp.roleId = null;
    setTableData([...tableData]);
    checkSavedDisaleorNot();
  };

  const handleSelectRoleChange = (e, id) => {
    let arrIndex = findIndexOfData(tableData, id);
    let roleIndex = findIndexOfData(roleOptions, e);

    var temp = tableData[arrIndex];
    temp.roleId = Number(e);
    // temp.roleName = roleOptions[roleIndex].roleName;
    setTableData([...tableData]);
    checkSavedDisaleorNot();
  };
  // find index ffrom data and id
  const findIndexOfData = (arr, id) => {
    return arr.findIndex((data) => data.id == id);
  };

  const groupActiveOrNotFunction = (e) => {
    let tempDataId = findIndexOfData(tableData, e.id);
    var temp = tableData[tempDataId];
    if (e.isWorkflowEnabled) {
      temp.isWorkflowEnabled = false;
    } else {
      temp.isWorkflowEnabled = true;
    }
    // temp.isWorkflowEnabled = e.isWorkflowEnabled == true ? false : true;
    temp.groupId = null;
    temp.roleId = null;
    temp.groupName = "";
    temp.roleName = "";
    var selectedRole = document.getElementById(`roleSelect-${e.id}`);

    selectedRole.innerHTML = "";
    let defaultOption = document.createElement("option");
    defaultOption.text = "Select an Option";
    defaultOption.value = null;
    defaultOption.selected = true;
    defaultOption.disabled = true;
    selectedRole.appendChild(defaultOption);
    setTableData([...tableData]);

    checkSavedDisaleorNot(e.id);
  };

  const Groupselect = (arrData, optionsList, defaultVal) => {
    return (
      <select
        id={`grpSelect-${arrData.id}`}
        defaultValue={defaultVal}
        disabled={arrData.isWorkflowEnabled ? false : true}
        onChange={(event) => {
          handleSelectGroupChange(event.target.value, arrData.id);
          selectRolesFunction(event.target.value, arrData.id, false);
        }}
        className={"form-control-sm form-select " + " " + Classes.small_select}
      >
        <option
          value=""
          selected={
            !arrData?.isWorkflowEnabled || arrData.groupId == null
              ? true
              : false
          }
          disabled
        >
          Select an option
        </option>
        {optionsList.map((option, index) => {
          return (
            <option key={index} value={option.id}>
              {option.groupName}
            </option>
          );
        })}
      </select>
    );
  };

  const RoleSelect = (arrData, optionsList, defaultVal) => {
    return (
      <select
        id={`roleSelect-${arrData.id}`}
        defaultValue={defaultVal.roleId}
        // disabled={e.isWorkflowEnabled && e.groupId != null ? false : true}
        disabled={
          arrData?.isWorkflowEnabled && arrData?.groupId != null ? false : true
        }
        onChange={(event) => {
          handleSelectRoleChange(event.target.value, arrData?.id);
        }}
        className={"form-control-sm form-select " + " " + Classes.small_select}
      >
        <option
          value=""
          selected={
            arrData?.isWorkflowEnabled === false ||
            arrData?.groupId == null ||
            (arrData?.roleId == null && arrData?.groupId != null) ||
            (!arrData?.isWorkflowEnabled &&
              arrData.roleId != null &&
              arrData.groupId != null)
          }
          disabled
        >
          Select an option
        </option>
        {optionsList &&
          optionsList.map((option, index) => {
            return (
              <option
                key={index}
                value={arrData?.isWorkflowEnabled === true && option.id}
              >
                {option.roleName}
              </option>
            );
          })}
        {defaultVal && (
          <option value={defaultVal.roleId}>{defaultVal.roleName}</option>
        )}
      </select>
    );
  };

  // table columns
  const columns = [
    {
      header: (
        <p className="mb-0">
          LIST OF MASTERS
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform: "rotate(0deg)",
            }}
          />
        </p>
      ),
      accessor: (e) => e.name,
    },
    {
      header: (
        <p className="mb-0">
          CHECKER REQUIRED
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform: "rotate(0deg)",
            }}
          />
        </p>
      ),
      accessor: (e) => (
        <SwitchToggle
          bgColor={"var(--app-btn-background)"}
          status={e.isWorkflowEnabled}
          otherFunction={() => groupActiveOrNotFunction(e)}
        />
      ),
    },
    {
      widthStyle: "25%",
      header: (
        <p className="mb-0">
          ASSIGN DEPARTMENT AS CHECKER
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform: "rotate(0deg)",
            }}
          />
        </p>
      ),
      accessor: (e) =>
        // <select
        //   defaultValue={e.groupId}
        //   disabled={e.isWorkflowEnabled ? false : true}
        //   onChange={(event) => {
        //     handleSelectGroupChange(event.target.value, e.id);
        //   }}
        //   className="form-control form-select"
        // >
        //   <option value="" selected={e.groupId == null ? true : false} disabled>
        //     Select an option
        //   </option>
        //   {options.map((option, index) => {
        //     return (
        //       <option key={index} value={index}>
        //         {option}
        //       </option>
        //     );
        //   })}
        // </select>
        // <p className="mb-0"></p>

        Groupselect(e, groupOptions, e.isWorkflowEnabled ? e.groupId : ""),
    },
    {
      widthStyle: "25%",
      header: (
        <p className="mb-0">
          ASSIGN DESIGNATION AS CHECKER
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform: "rotate(0deg)",
            }}
          />
        </p>
      ),
      accessor: (e) =>
        RoleSelect(
          e,
          roleOptions,
          e.isWorkflowEnabled
            ? { roleId: e.roleId, roleName: e.roleName }
            : { roleId: "", roleName: "Select an Option" }
        ),
      // ),
    },
  ];

  const updateHandle = () => {
    updateMakerCheckerMaster(tableData)
      .then((res) => {
        if (res.data.success === true) {
          dispatch(
            setAlertMsg({
              msg: `Updated Successfully`,
              alertType: "success",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setAlertMsg({
            msg: `Failed to  Update`,
            alertType: "danger",
          })
        );
      });
  };
  const selectRolesFunction = useCallback(
    (id, rowID, isDefault, selectedData) => {
      getRolesViaGroup(id).then((res) => {
        var optionsData = res.data.data;
        rolesOptionsView(optionsData, selectedData, isDefault, rowID);
      });
    },
    []
  );

  const rolesOptionsView = (optionsData, selectedData, isDefault, rowID) => {
    var selectedRole = document.getElementById(`roleSelect-${rowID}`);

    selectedRole.innerHTML = "";
    let defaultOption = document.createElement("option");
    defaultOption.text = "Select an Option";
    defaultOption.value = null;
    defaultOption.selected = true;
    defaultOption.disabled = true;
    selectedRole.appendChild(defaultOption);
    for (var data of optionsData) {
      let option = document.createElement("option");
      option.text = data.roleName;
      option.value = data.id;
      if (selectedData == data.id) {
        option.selected = true;
      }
      selectedRole.appendChild(option);
      // selectedRole.addEventListener(option);
    }
  };

  // search master
  const filterBySearchMaster = (event) => {
    const query = event.target.value;

    if (query.trim() === "") {
      setTableData(initialTable);
    } else {
      var updatedList = initialTable;
      const filteredItems = updatedList.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      setTableData(filteredItems);
    }
  };
  return (
    <>
      <section className={Classes.makerCheckerMaster}>
        <div className="container-fluid px-5 py-4">
          <div className="row">
            <GoBackButton />
            <div className="col-lg-12 my-3">
              <div className={Classes.head_section}>
                <div className={Classes.front_part}>
                  <h5>Maker Checker Master</h5>
                  <p className="mt-2">
                    Here you can enable checkers all masters and assign a role
                    suitable as a checker
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-4">
              <div className="card border-0 border-bottom-1">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h2 className={Classes.table_heading}>
                      List of all Masters{" "}
                      <span>{tableData && tableData.length} Masters</span>
                    </h2>

                    <TextField
                      label="Search Master"
                      type="search"
                      id="outlined-size-small"
                      size="small"
                      onChange={filterBySearchMaster}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <CustomTable
                data={tableData}
                headings={columns}
                accessor={accessor}
                setAccessor={setAccessor}
              />

              <div className={Classes.btn_group + " " + "text-center"}>
                <PrimaryButton
                  disabled={disableBtn}
                  style={{ height: "39.2px" }}
                  label={t("Save")}
                  onClick={(e) => {
                    updateHandle();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MakerCheckerMaster;
