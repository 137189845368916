import axios from "axios";
import { BASE_URL, SIGN_IN_URL } from "./Routes/Urls";
import Utils from "./Utils";
import Consts from "./Consts";

axios.interceptors.request.use(async function (request) {
  window.loading?.(true);

  let isAvoidEncryption = Consts.AVOID_ENCRYPTION_API_LIST.some((pattern) =>
    request.url.includes(pattern)
  );

  // Check if request data needs encryption
  if (!isAvoidEncryption && request.data) {
    try {
      // Encrypt the request payload
      const encReq = await Utils.encryptPayloadData(request.data);
      // Return the modified request with encrypted data
      return { ...request, data: encReq };
    } catch (error) {
      // Optionally handle encryption errors or proceed with the original request
      return request; // Proceed with the original request if encryption fails
    }
  } else {
    // Return the request as-is if no data to encrypt
    return request;
  }
});

axios.interceptors.response.use(
  function (response) {
    window.loading?.(false);

    // Check for success and handle decryption if needed
    if (response?.data?.success === true && response?.data?.data) {
      return Utils.decryptPayloadData(response.data.data)
        .then((decResponse) => {
          // Return the response with decrypted data
          return {
            ...response,
            data: {
              ...response.data,
              data: decResponse,
            },
          };
        })
        .catch((error) => {
          // Handle decryption errors and still return the original response
          return response; // Optionally return the original response if decryption fails
        });
    } else {
      // Return the original response if no decryption needed
      return response;
    }
  },
  function (error) {
    window.loading?.(false);
    if (
      error?.response?.status === 401 &&
      window.location.pathname !== `/${BASE_URL}/${SIGN_IN_URL}`
    ) {
      window.location = `/${BASE_URL}/${SIGN_IN_URL}`;
      return;
    }

    if (error?.response?.status === 429) {
      alert("To many requests");
    }

    return Promise.reject(error);
  }
);

export default axios;
