import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Classes from "./ScruitnyForm.module.css";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import { useTranslation } from "react-i18next";
import Utils from "../../../../../Utils";
import GoBackButton from "../../../../../components/Buttons/GoBackButton";
import fileSVG from "../../../../../assets/file.svg";
import CheckGreenIcon from "../../../../../assets/Right_check_icons/Green_Check.svg";
import CheckWhiteIcon from "../../../../../assets/Right_check_icons/White_Check.svg";
import CrossRedIcon from "../../../../../assets/Cross_Icons/Cross_red.svg";
import CrossWhiteIcon from "../../../../../assets/Cross_Icons/Cross_white.svg";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import compareFileIcon from "../../../../../assets/compare_file_icon.svg";
import { memo } from "react";
import Consts from "../../../../../Consts";
import UploadDocument from "../../../../../components/UploadDocument/UploadDocument";
import { dscDocUpload } from "../../../CorporateAction";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../../../Home/HomeSlice";

const ScruitnyForm = forwardRef(
  (
    {
      corpDetails,
      documentsStatus,
      isActiveId,
      setIsActiveId,
      documentsItem,
      setDocumentsItem,
      showCurrentImage,
      ischeckedFunction,
      setCurrDocOrionId,
      rejectedForms,
      setRejectedForms,
      setIsCorporateApplicationModal,
      setIsRejCorporateApplicationModal,
      handleNavigate,
      acceptDisable,
      rejectDisable,
      setIsActiveDocumentName,
      proceedStatus,
      proceedDataFunction,
      setIsModal,
      setIsComparisonModalOpen,
      scrollToBottom,
      checkAllDocScruitny,
      corpId,
      handleAllScrutinyDoc,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { docRejectRef, scrollBottomRef } = ref;
    const bottomEl = useRef();
    const [isUploadModal, setIsUploadModal] = useState(false);
    const dispatch = useDispatch();

    const fileUpdateFunction = (e) => {
      if (isActiveId === 0) {
        setCurrDocOrionId(0);
      }
      let formData = new FormData();
      formData.append("file", e);
      dscDocUpload(corpId, formData)
        .then((res) => {
          if (res.data.success === true) {
            if (isActiveId === 0) {
              setCurrDocOrionId(documentsItem[0]?.orionDocId);
            }
            dispatch(
              setAlertMsg({
                msg: "File updated successfully",
                alertType: "success",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(
            setAlertMsg({
              msg: err.response.data.message,
              alertType: "danger",
            })
          );
        });
    };

    const setIsActiveIdFunction = (id) => {
      setIsActiveId(id);
      ischeckedFunction(documentsItem[id]);
    };

    const acceptedFunction = (check) => {
      if (documentsItem && check == true) {
        const newArr = [...documentsItem];
        newArr[isActiveId].docStatus = documentsStatus.accepted;
        newArr[isActiveId].isChecked = true;

        setDocumentsItem(newArr);

        const updatedRejectedForms = { ...rejectedForms };
        if (updatedRejectedForms[isActiveId] != null) {
          delete updatedRejectedForms[isActiveId];
        }
        setRejectedForms(updatedRejectedForms);
        ischeckedFunction(newArr[isActiveId]);
      }

      if (isActiveId !== documentsItem.length - 1) {
        changeDocument(1);
      }
      if (checkAllDocScruitny()) {
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      }
    };
    // if page has rejected docuemnts or not
    const rejectionPresence = () => {
      if (Object.keys(rejectedForms).length > 0) {
        setIsRejCorporateApplicationModal(true);
      } else {
        if (!window.confirm("Have you Upload Signed Document?")) {
          return;
        }
        setIsCorporateApplicationModal(true);
      }
    };

    // change active status of document and also list
    const showchangedDocument = (num, speed, doclen) => {
      let showcurrObj = documentsItem[num];
      if (showcurrObj === undefined || showcurrObj.orionDocId === null) {
        if (speed === 1) {
          // if (num + 1 >= doclen) {
          //   num = -1;
          //   setIsActiveId(num);
          // }
          setIsActiveId(num + 1);
          return showchangedDocument(num + 1, 1, doclen);
        } else {
          // if (num === -1) {
          //   num = doclen;
          //   setIsActiveId(num);
          // }
          setIsActiveId(num - 1);
          return showchangedDocument(num - 1, -1, doclen);
        }
      }
      setCurrDocOrionId(showcurrObj.orionDocId);
      ischeckedFunction(documentsItem[num]);
      setIsActiveDocumentName(documentsItem[num]);
    };

    const changeDocument = (num) => {
      var doclen = documentsItem.length;
      doclen = doclen + 1;
      if (num == 1) {
        setIsActiveId(isActiveId + 1);
        showchangedDocument(isActiveId + 1, num, doclen);
      } else {
        setIsActiveId(isActiveId - 1);
        showchangedDocument(isActiveId - 1, num, doclen);
      }
    };

    // scrutiny compare function
    const scrutinyCompareFunction = (index, item) => {
      setIsActiveIdFunction(index);
      setIsActiveDocumentName(item.processDocumentType);
      setCurrDocOrionId(item.orionDocId);
      setIsComparisonModalOpen(true);
    };
    const handleNextBtn = () => {
      acceptedFunction(true);
      proceedDataFunction(documentsItem[isActiveId].id, true);
    };
    const handleRejectDoc = () => {
      setIsModal(true);
    };

    // disabled arrow butttons based on condition
    const disabledArrowButtons = (buttonType, docId) => {
      if (buttonType === Consts.BUTTON_TYPE.PREVIOUS) {
        return docId === 0;
      } else if (buttonType === Consts.BUTTON_TYPE.NEXT) {
        return documentsItem && documentsItem?.length - 1 === docId;
      }
      return false;
    };

    useImperativeHandle(docRejectRef, () => ({
      triggerFunction() {
        if (isActiveId !== documentsItem.length - 1) {
          changeDocument(1);
        }
      },
    }));

    return (
      <main
        className={Classes.full_width + " " + Classes.mainScreenheight}
        id="scruitnyForm"
      >
        <GoBackButton />

        <div
          className={
            Classes.container + "   mb-3 py-3 " + " " + Classes.containerHeight
          }
        >
          <div className={"row pt-3" + " " + Classes.corporateScruitny}>
            <div className="col-lg-12 p-0">
              <div
                className="card p-4   border-0"
                style={{ marginBottom: "3px" }}
              >
                <h6 className={Classes.list_head}> Corporate Details</h6>
                <div className={Classes.corpoDetails}>
                  <div className={Classes.details + " " + "mr-auto"}>
                    <div
                      className={
                        Classes.fontHighlight +
                        " " +
                        Classes.light_grey_color +
                        " " +
                        "details-item mr-5"
                      }
                    >
                      Name :
                      <span
                        className={
                          Classes.fontHighlight + " " + Classes.text_grey_600
                        }
                      >
                        {corpDetails.corpName}
                      </span>
                    </div>

                    <div
                      className={
                        Classes.fontHighlight +
                        " " +
                        Classes.light_grey_color +
                        " " +
                        "details-item"
                      }
                    >
                      Acknowledegement ID :
                      <span
                        className={
                          Classes.fontHighlight + " " + Classes.text_grey_600
                        }
                      >
                        {corpDetails.ackId}
                      </span>
                    </div>
                  </div>
                  <div className={Classes.mandatory_sign}>
                    <span className="text-danger">* </span>
                    Mandatory Document
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 p-0">
              <div className="card p-4 border-0">
                <div
                  className={"row pt-2 pb-0" + " " + Classes.documentRowHeight}
                >
                  <div className={"col-lg-4 mb-4" + " " + Classes.full_height}>
                    <div className={"card" + " " + Classes.full_height}>
                      <div className="card-body pb-2">
                        <h6
                          className={
                            "card-title mb-2 pt-2 pb-2" +
                            " " +
                            Classes.full_height +
                            " " +
                            Classes.list_head_doc
                          }
                        >
                          List Of Documents
                        </h6>
                      </div>
                      <hr className="my-1" />
                      <div
                        className={Classes.lists + " " + Classes.full_height}
                      >
                        <ul id="documentlists">
                          {documentsItem &&
                            documentsItem.map((item, index) => {
                              if (
                                item.documentName != null &&
                                item.orionDocId != null
                              ) {
                                return (
                                  <li
                                    onClick={() => {
                                      setIsActiveIdFunction(index);
                                      setIsActiveDocumentName(
                                        item?.processDocumentType
                                      );
                                      setCurrDocOrionId(item?.orionDocId);
                                    }}
                                    key={index}
                                    className={
                                      isActiveId === index
                                        ? Classes.lists_ul_li +
                                          " " +
                                          Classes.active
                                        : Classes.lists_ul_li
                                    }
                                  >
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div
                                        className={Classes.form_name}
                                        title={
                                          item.processDocumentType.uiString
                                        }
                                      >
                                        <p
                                          className={
                                            item.processDocumentType.isMandatory
                                              ? Classes.text_mandatory
                                              : ""
                                          }
                                        >
                                          {item.processDocumentType.uiString &&
                                            item.processDocumentType.uiString}
                                        </p>
                                      </div>
                                      {item?.processDocumentType?.id != 1 && (
                                        <div
                                          className={Classes.compareBtn}
                                          onClick={() =>
                                            scrutinyCompareFunction(index, item)
                                          }
                                        >
                                          <img
                                            src={compareFileIcon}
                                            width="100%"
                                            alt="compare files"
                                          />
                                        </div>
                                      )}

                                      {item.docStatus &&
                                        (item.docStatus ==
                                        documentsStatus.accepted ? (
                                          <div
                                            className={
                                              Classes.status_btn +
                                              " " +
                                              Classes.green_color +
                                              " " +
                                              "badge "
                                            }
                                          >
                                            {item.docStatus}
                                          </div>
                                        ) : item.docStatus ==
                                          documentsStatus.rejected ? (
                                          <div
                                            className={
                                              Classes.status_btn +
                                              " " +
                                              Classes.red_color +
                                              " " +
                                              "badge "
                                            }
                                          >
                                            {item.docStatus}
                                          </div>
                                        ) : (
                                          <div
                                            className={
                                              Classes.status_btn +
                                              " " +
                                              "badge "
                                            }
                                          >
                                            {item.docStatus}
                                          </div>
                                        ))}
                                    </div>
                                  </li>
                                );
                              }
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      "col-lg-8 mb-4" + " " + Classes.formDocumentSection
                    }
                  >
                    <div className={"card" + " " + Classes.full_height}>
                      <div className={"card-body py-3"}>
                        <div className="d-flex align-items-center flex-wrap ">
                          <div
                            className={
                              Classes.details + " " + "py-md-0 py-2 mr-auto"
                            }
                          >
                            <i className={Classes.svg_icon_size}>
                              <img src={fileSVG} />
                            </i>

                            {documentsItem[isActiveId] &&
                              documentsItem[isActiveId].processDocumentType && (
                                <p
                                  className={
                                    documentsItem[isActiveId]
                                      .processDocumentType.isMandatory
                                      ? Classes.text_mandatory +
                                        " " +
                                        "card-title mb-0 pb-0 " +
                                        " " +
                                        Classes.form_para_head
                                      : +" " +
                                        "card-title mb-0 pb-0 " +
                                        " " +
                                        Classes.form_para_head
                                  }
                                  title={
                                    documentsItem[isActiveId]
                                      ?.processDocumentType?.uiString
                                  }
                                >
                                  {Utils.ShortLengthFunction(
                                    documentsItem[isActiveId]
                                      ?.processDocumentType?.uiString,
                                    30
                                  )}
                                </p>
                              )}
                          </div>

                          <div className={`${Classes.prevBackButtons}`}>
                            <div
                              className={`${Classes.buttons} ${
                                disabledArrowButtons(
                                  Consts.BUTTON_TYPE.PREVIOUS,
                                  isActiveId
                                )
                                  ? Classes.disbaled_btn
                                  : ""
                              } `}
                              title="Previous Document"
                              onClick={() =>
                                disabledArrowButtons(
                                  Consts.BUTTON_TYPE.PREVIOUS,
                                  isActiveId
                                ) === false
                                  ? changeDocument(-1)
                                  : ""
                              }
                            >
                              <ArrowCircleLeftIcon />
                            </div>

                            {
                              <div
                                className={`${Classes.buttons} ${
                                  disabledArrowButtons(
                                    Consts.BUTTON_TYPE.NEXT,
                                    isActiveId
                                  )
                                    ? Classes.disbaled_btn
                                    : ""
                                } `}
                                title="Next Document"
                                onClick={() =>
                                  disabledArrowButtons(
                                    Consts.BUTTON_TYPE.NEXT,
                                    isActiveId
                                  ) === false
                                    ? changeDocument(1)
                                    : ""
                                }
                              >
                                <ArrowCircleRightIcon />
                              </div>
                            }
                          </div>

                          <div
                            className={Classes.details + " " + "flex-nowrap"}
                          >
                            <div className="btn-group mr-2">
                              <SecondaryButton
                                disabled={acceptDisable}
                                style={{
                                  backgroundColor:
                                    acceptDisable == true
                                      ? "var(--app-btn-background)"
                                      : "",
                                }}
                                onClick={handleNextBtn}
                                label={t("Accept")}
                                iconImage={
                                  acceptDisable == true
                                    ? CheckWhiteIcon
                                    : CheckGreenIcon
                                }
                              />
                            </div>
                            <div className="btn-group">
                              <SecondaryButton
                                style={
                                  rejectDisable == true
                                    ? {
                                        backgroundColor:
                                          "var(--app-btn-background)",
                                        color: "var(--app-white)",
                                      }
                                    : {}
                                }
                                label={t("Reject")}
                                iconImage={
                                  rejectDisable == true
                                    ? CrossWhiteIcon
                                    : CrossRedIcon
                                }
                                onClick={handleRejectDoc}
                              />
                            </div>
                          </div>
                        </div>

                        {/* <hr className="my-1" /> */}
                      </div>
                      <hr className="my-1" />
                      <div
                        className={
                          "document_body overflow-hidden" +
                          " " +
                          Classes.full_width +
                          " " +
                          Classes.full_height
                        }
                      >
                        <iframe
                          style={{ minHeight: "60vh" }}
                          className={
                            Classes.full_height + " " + Classes.full_width
                          }
                          src={showCurrentImage}
                          sandbox
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row py-3" ref={scrollBottomRef}>
                  <div className="col-lg-12" ref={bottomEl}>
                    <div className="d-flex align-items-center flex-wrap">
                      <div className={Classes.details + " " + " mr-auto"}>
                        <div className="btn-group  mr-3">
                          <SecondaryButton
                            onClick={handleNavigate}
                            label={t("Cancel")}
                          />
                        </div>

                        {corpDetails?.processState?.id === 3 && (
                          <PrimaryButton
                            onClick={() => setIsUploadModal(true)}
                            label={"Upload Signed CHO Form"}
                          />
                        )}

                        <div className="btn-group ml-2">
                          <PrimaryButton
                            style={{
                              // height: "39.2px",
                              backgroundColor:
                                proceedStatus == true
                                  ? "var(--app-btn-background)"
                                  : "",
                            }}
                            disabled={proceedStatus}
                            label={t("Proceed")}
                            onClick={rejectionPresence}
                          />
                        </div>
                      </div>
                      <div className={Classes.details + " " + "pb-md-0 pb-2"}>
                        <p className={`mb-0 ${Classes.count_rej_doc}`}>
                          <span>Status : &ensp;</span>
                          {Object.keys(rejectedForms).length} Document(s)
                          Rejected
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isUploadModal && (
          <UploadDocument
            isModalOpen={isUploadModal}
            setIsModalOpen={setIsUploadModal}
            acceptedFileType={Consts.ACCEPTED_FILE_TYPE_ARR[0]}
            uploadedDocuments={fileUpdateFunction}
          />
        )}
      </main>
    );
  }
);

export default memo(ScruitnyForm);
