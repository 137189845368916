import React from "react";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import classes from "./ApplicationVerification.module.css";
import dotIcon from "../../../assets/TableDashboard/OrangeDot.svg";
import infoIcon from "../../../assets/info-blue-icon.svg";
import tickIcon from "../../../assets/Right_check_icons/White_Check.svg";
import crossIcon from "../../../assets/Cross_Icons/Cross_white.svg";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";

const ApplicationVerification = () => {
  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.goBackBtn}>
          <GoBackButton />
        </div>
        <div>
          <div className={classes.card_title}>
            <h3>Subscriber Application Verification</h3>
            <div className={classes.idContainer}>
              Acknowledgement Id : 6453627181
            </div>
          </div>
          <div className={classes.subTitle}>
            Kindly review the form carefully.
          </div>
          <div className={classes.container}>
            <div className={classes.detailsContainer}>
              <div className={classes.statusContainer}>
                <div>Subscriber Details :</div>
                <div>
                  Application Status :{" "}
                  <span className={classes.statusText}>Pending</span>
                </div>
              </div>
              <div className={classes.detailsContent}>
                <div className={classes.detailsBox}>
                  <span className={classes.detailTitle}>Subscriber Name</span>
                  <span className={classes.detailValue}>Aman Sharma</span>
                </div>
                <div className={classes.detailsBox}>
                  <span className={classes.detailTitle}>Journey Type</span>
                  <span className={classes.detailValue}>PRAN Registration</span>
                </div>
                <div className={classes.detailsBox}>
                  <span className={classes.detailTitle}>CBO Number</span>
                  <span className={classes.detailValue}>0994849</span>
                </div>
                <div className={classes.detailsBox}>
                  <span className={classes.detailTitle}>PRAN Number</span>
                  <span className={classes.detailValue}>10098982</span>
                </div>
                <div className={classes.detailsBox}>
                  <span className={classes.detailTitle}>Date</span>
                  <span className={classes.detailValue}>15-07-23</span>
                </div>
                <div className={classes.detailsBox}>
                  <span className={classes.detailTitle}>TAT</span>
                  <span className={classes.detailValue}>
                    <span>
                      <img src={dotIcon} alt='icon' />
                    </span>
                    15 Days
                  </span>
                </div>
              </div>
              <div className={classes.verify_details_wrapper}>
                <div>Details that needs to be verified :</div>
                <div className={classes.verifyDetailsContent}>
                  <div className={classes.detailsBox}>
                    <span className={classes.detailTitle}>Employment Id</span>
                    <span className={classes.detailValue}>0994849</span>
                  </div>
                  <div className={classes.detailsBox}>
                    <span className={classes.detailTitle}>Date of Joining</span>
                    <span className={classes.detailValue}>10-06-12</span>
                  </div>
                  <div className={classes.detailsBox}>
                    <span className={classes.detailTitle}>
                      Date of Retirement
                    </span>
                    <span className={classes.detailValue}>15-07-50</span>
                  </div>
                </div>
              </div>
              <div className={classes.confirmContainer}>
                <div className={classes.leftContainer}>
                  <div className={classes.confirmText}>
                    Do You want to accept/reject this application ?
                  </div>
                  <div className={classes.confirmBtnContainer}>
                    <PrimaryButton label={"Accept"} icon={tickIcon} />
                    <PrimaryButton label={"Reject"} icon={crossIcon} />
                    <SecondaryButton label={"Cancel"} />
                  </div>
                </div>
                <div className={classes.rightContainer}>
                  <div className={classes.frHistoryTitle}>
                    <span>
                      <img src={infoIcon} alt='icon' />
                    </span>
                    FR History
                  </div>
                  <div className={classes.frCounter}>2 times</div>
                  <div className={classes.rejectCounter}>
                    0 Document Rejected
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationVerification;
