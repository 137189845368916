import React, { useEffect } from "react";
import NonLabelStepper from "../../../components/NonLabelStepper/NonLabelStepper";
import { useState } from "react";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Classes from "./SubscriberRegistration.module.css";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import ContactVerification from "./RegistrationHelper/ContactVerification/ContactVerification";
import leftArrowIcon from "../../../assets/left-arrow-icon.svg";
import { useNavigate } from "react-router";
import {
  BASE_URL,
  DASHBOARD_URL,
  SIGN_IN_URL,
  SUBSCRIBER_FORM,
  SUBSCRIBER_MIGRATION,
} from "../../../Routes/Urls";
import SubscriberRegistrationForm from "./RegistrationHelper/SubscriberRegistrationForm/SubscriberRegistrationForm";
import SubscriptionRequirementModal from "./RegistrationHelperModal/SubscriptionRequirementModal";
import SubscriberFormStep3 from "../SubscriberForm/SubscriberFormStep3";
import NomineeDetails from "./RegistrationHelper/NomineeDetails/NomineeDetails";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import SubscriberBankDetails from "./RegistrationHelper/BankDetails/SubscriberBankDetails";
import { useRef } from "react";
import {
  getDigiLockStartLinkFunction,
  saveorSubmitSubscriberForm,
} from "./SubscriberRegistrationAction";
import SubscriberPrevForm from "../SubscriberPrevForm/SubscriberPrevForm";
import SubscriberDigiLocker from "./SubscriberDigiLocker/SubscriberDigiLocker";
import DigiLockerModal from "./SubscriberDigiLocker/DigiLockerModal";
import { setAlertMsg } from "../../Home/HomeSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { getSubscriber } from "../SubscriberOnboardAction";
import { subDetails } from "../SubscriberSlice";
import NPSRegistrationPage from "../NPSRegistrationPage/NPSRegistrationPage";
import Consts from "../../../Consts";
import { subRegisterType } from "../SubscriberSlice";
import PendingFormModal from "./RegistrationHelperModal/PendingFormModal/PendingFormModal";
import {
  sendOTPtoNumberFunction,
  validateOTPtoNumberFunction,
} from "../SubscriberRegistration/RegistrationHelper/ContactVerification/ContactVerificationAction";
import NPSICSSForm from "../NpsICSSForm/NPSICSSForm";
const SubscriberRegistration = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bankDetailsRef = useRef();
  const subscriberRef = useRef();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [successModal, setSuccessModal] = useState(false);
  const [requirementModal, setRequirementModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [nextButtonDisable, setNextButtonDisable] = useState(true);
  const [previewData, setPreviewData] = useState({ subFormData: {} });
  const [allData, setAllData] = useState({});
  const [isFlowFinished, setIsFlowFinished] = useState(false);
  const [ackId, setAckId] = useState(null);
  const [isDigiLockerModal, setIsDigiLockerModal] = useState(false);
  const [isDigiLockerSubmit, setisDigiLockerSubmit] = useState(false);
  const [digiLockerURL, setDigiLockerURL] = useState("");
  const [digilockerProceedModal, setDigilockerProceedModal] = useState(false);
  const [isKycValidate, setIsKycValidate] = useState(false);
  const [corpIdParam, setCorpIdParam] = useState("");
  const [isOTP, setIsOTP] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [isICSS, setIsICSS] = useState(false);
  const [basicDetails, setBasicDetails] = useState({
    phoneNumber: "",
    ackId: "",
    otp: "",
  });
  const [isNew, setIsNew] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const [pageStep, setPageStep] = useState([
    {
      index: 0,
    },
    {
      index: 1,
    },
    {
      index: 2,
    },
  ]);

  const [steps, setSteps] = useState([
    {
      index: 0,
      stepName: "Step 1.... How can we contact you ?",
      formHeading: "Contact Details ",
      instruction: {
        text: "Email and Phone number is only for communication purpose only. OTP will be sent these number and email id.",
      },
    },
    {
      index: 1,
      stepName: "Step 2.....Let's know about you!",
      formHeading: "Subscriber Details",
      instruction: {
        text: "As per the amendments made under Prevention of Money-Laundering (Maintenance of Records) Second Amendment Rules, 2019, PAN is mandatory under NPS.If you do not have PAN at present, please ensure that these details are provided within six months of submission of this Subscriber Registration Form",
      },
    },
    {
      index: 2,
      stepName: isICSS
        ? "3. ICSS (Inter CRA Subscriber Shifting"
        : "ISS (Inter Sector Subscriber Shifting)",
      formHeading: isICSS
        ? "3. ICSS (Inter CRA Subscriber Shifting)"
        : "ISS (Inter Sector Subscriber Shifting)",
      instruction: {
        text: isICSS ? (
          <div>
            <h6>Existing PRAN association (Source Office Details)</h6>
            <p>
              The POP SP i.e Point of Presence Service Provider is the NPS
              intermediary with whom you have opened your NPS account
            </p>
          </div>
        ) : (
          <p>
            Kindly download CSV template , fill in the details and upload the
            file for verification
          </p>
        ),
      },
    },
    {
      index: 3,
      stepName: "Step 3",
      formHeading: "Investment Details",
      instruction: {
        text: "The NPS Auto Choice Option Is For Passive Investors Who Seek To Let An Automatic Allocation Decide The Proportion Of Money Spread Across The Available Asset Classes.",
        htmlText: (
          <div className={Classes.third_instruction}>
            <h6>Lifecycle fund and its types</h6>
            <p>
              <span>Note</span> : In case, you do not indicate a choice of LC,
              your funds will be invested as per LC 50
            </p>
            <ul>
              <li>
                <span>1. LC 75 -</span> It is the Life cycle fund where the Cap
                to Equity investments is 75% of the total asset
              </li>
              <li>
                {" "}
                <span>2. LC 50 -</span> It is the Life cycle fund where the Cap
                to Equity investments is 50% of the total asset{" "}
              </li>
              <li>
                <span>3. LC 25 - </span> It is the Life cycle fund where the Cap
                to Equity investments is 25% of the total asset
              </li>
            </ul>
          </div>
        ),
      },
    },
    {
      index: 4,
      stepName: "Step 4....almost there !",
      formHeading: "Nominee Details",
      instruction: {
        text: (
          <span>
            You can add upto{" "}
            <span className={Classes.font_bold}>3 Nominee</span> for hassle free
            succession of your investment
          </span>
        ),
      },
    },
    // {
    //   index: 4,
    //   stepName: "Step 5....last one step",
    //   formHeading: "Bank  Details",
    //   instruction: {
    //     text: "As per regulations, we need to verify your bank before you make any investments",
    //     htmlText: (
    //       <div className={Classes.bank_instructions}>
    //         <p>There are two steps to fetch you data via UPI :</p>
    //         <ol>
    //           <li>
    //             <strong>
    //               Scan the QR code from the UPI app of your choice
    //             </strong>
    //             <p>UPI should be link to your bank acount</p>
    //           </li>
    //           <li>
    //             <strong>Select bank account and pay Rs 1</strong>
    //             <p>
    //               Pay Rs 1 to verify your bank account and it will be refunded
    //               bank to you within 48 hours
    //             </p>
    //           </li>
    //         </ol>
    //       </div>
    //     ),
    //   },
    // },
  ]);
  const [corporateData, setCorporateData] = useState({});
  const [isRegPendingModal, setIsRegPendingModal] = useState(false);

  useEffect(() => {
    setNextButtonDisable(true);
    checkUrlParams();
  }, []);

  useEffect(() => {
    if (isNew) {
      setSteps((prevData) => prevData.filter((item) => item.index !== 2));
    }
  }, [isNew]);

  //  check url parameter
  const checkUrlParams = () => {
    let idParam = searchParams.get("corporateId");
    setCorpIdParam(idParam);
    if (idParam === null || idParam === undefined) {
      navigate(`/${BASE_URL}/${SIGN_IN_URL}`);
    }
  };

  const handleClick = async (e) => {
    if (activeIndex <= 1 && e === -1) {
      return;
    }

    if (activeIndex === 0) {
      subscriberRef.current.subscriberRefFunction();
      return;
    }

    const isNewLength = isNew ? steps.length + 1 : steps.length;

    if (e !== -1) {
      const isSuccess = await handleSaveSubmitForm(ackId, previewData);
      if (isSuccess) {
        setActiveIndex((data) => {
          const newIndex =
            data + e >= isNewLength
              ? isNewLength - 1
              : data + e < steps[0].index
              ? steps[0].index
              : data + e;

          return isNew && data === 1 ? 3 : newIndex;
        });
      }
    } else {
      setActiveIndex((data) => {
        const newIndex =
          data + e >= isNewLength
            ? isNewLength - 1
            : data + e < steps[0].index
            ? steps[0].index
            : data + e;

        return isNew && data === 3 ? 1 : newIndex;
      });
    }

    // Check isNew condition and set FlowFinished and ActivePage
    if (activeIndex + e === isNewLength) {
      setIsFlowFinished(true);
      setActivePage(2);
      return;
    }
  };

  // on cancel click
  const handleCancelClick = () => {
    navigate(`/${BASE_URL}/${DASHBOARD_URL}`);
  };

  // call digilocker function
  const callDigiLockerfunction = () => {
    getDigiLockStartLinkFunction(ackId)?.then((res) => {
      if (res.data.success) {
        setDigiLockerURL(res?.data?.data?.redirectUrl);
        setDigilockerProceedModal(false);
        setIsDigiLockerModal(true);
      }
    });
  };

  // handle save submit data
  const handleSaveSubmitForm = (ackId, data) => {
    return new Promise((resolve, reject) => {
      saveorSubmitSubscriberForm(ackId, data, "save")
        .then((res) => {
          if (res?.data?.success) {
            setAllData(res?.data?.data);
            setPreviewData(res?.data?.data?.subFormData);
            resolve(true);
          } else {
            dispatch(
              setAlertMsg({
                msg: res?.response?.data?.message,
                alertType: "danger",
              })
            );
            resolve(false);
          }
        })
        .catch((err) => {
          dispatch(
            setAlertMsg({
              msg: err?.response?.data?.message,
              alertType: "danger",
            })
          );
          resolve(false);
        });
    });
  };

  //  merge data
  function mergeCorpDataWithFormData(allData) {
    let obj = {
      corpName: allData?.corpData?.corpName,
      choNumber: allData?.corpData?.choNumber,
    };
    let obj2 = allData?.subFormData;
    let mergedData = {
      subFormData: { ...obj, ...obj2 },
    };
    return mergedData;
  }

  const handleSubscriber = () => {
    localStorage.setItem(
      "subDetails",
      JSON.stringify({
        subAckId: basicDetails.ackId,
        subPhone: basicDetails.phoneNumber,
      })
    );
    getSubscriber(basicDetails?.ackId, basicDetails?.phoneNumber)
      .then((res) => {
        if (res.data.success === true) {
          setAllData(res?.data?.data);
          setActivePage(1);
          setActiveIndex(1);
          setAckId(res?.data?.data?.subData?.id);
          if (
            res?.data?.data?.subData?.registrationType ===
            Consts.SUBSCRIBER_REGISTRATION_TYPE.NEW
          ) {
            setIsNew(true);
          }
        }
      })
      .catch((error) => {
        dispatch(
          setAlertMsg({
            msg: error?.response?.data?.message,
            alertType: "danger",
          })
        );
      });

    setIsRegPendingModal(false);
  };

  //  handle pending form
  const handlePendingForm = () => {
    setIsRegPendingModal(true);
    setBasicDetails({ phoneNumber: "", ackId: "", otp: "" });
  };

  //  handle click new nps
  const handleClickNPS = (regType) => {
    dispatch(subRegisterType(regType));
    setActivePage(1);
    if (regType === Consts.SUBSCRIBER_REGISTRATION_TYPE.NEW) {
      setIsNew(true);
    }
  };

  const handleSendOTP = () => {
    let phoneObj = {
      phoneNumber: basicDetails.phoneNumber,
      consent: "y",
      requestType: "subscriber",
    };
    sendOTPtoNumberFunction(phoneObj)
      .then((res) => {
        if (res.data.success === true) {
          dispatch(
            setAlertMsg({
              msg: res.data.data.message,
              alertType: "success",
            })
          );
          setRequestId(res.data.data.request_id);
          setIsOTP(true);
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: "Enter correct Phone Number",
            alertType: "danger",
          })
        );
      });
  };

  const handleValidateOTP = () => {
    let otpObj = {
      request_id: requestId,
      otp: basicDetails.otp,
      requestType: "subscriber",
    };
    validateOTPtoNumberFunction(otpObj)
      .then((res) => {
        if (res.data.success === true) {
          setIsOtpValid(true);
          dispatch(
            setAlertMsg({
              msg: res.data.data.message,
              alertType: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: "Enter correct otp ",
            alertType: "danger",
          })
        );
      });
  };
  return (
    <>
      {activePage === pageStep[0].index && (
        <NPSRegistrationPage
          handleClickNPS={handleClickNPS}
          handlePendingForm={handlePendingForm}
        />
      )}
      {isFlowFinished && activePage === pageStep[2].index && (
        <SubscriberPrevForm setPreviewData={setAllData} previewData={allData} />
      )}
      {activePage === pageStep[1].index && (
        <div className="container-fluid py-4 px-4 ">
          <div className={Classes.goback_btn}>
            <GoBackButton />
          </div>
          <div className={Classes.page_title}>
            <h4> Register Corporate Subscriber </h4>
            {ackId != null && <p>Your Acknowledgement ID : SR{ackId} </p>}
          </div>

          <div className={Classes.newSubscriberBox}>
            <div className={Classes.formArea}>
              <div className={Classes.formBox}>
                <div className={Classes.headerSteps}>
                  <div className={Classes.stepName}>
                    <span
                      className={Classes.prevButtonIcon}
                      onClick={() => handleClick(-1)}
                    >
                      <img src={leftArrowIcon} alt="Prev Back " />
                    </span>{" "}
                    {
                      steps[
                        isNew && activeIndex > 2 ? activeIndex - 1 : activeIndex
                      ]?.stepName
                    }
                  </div>
                  <NonLabelStepper steps={steps} activeIndex={activeIndex} />
                </div>
                <div className={Classes.formHeading}>
                  <h4>
                    {
                      steps[
                        isNew && activeIndex > 2 ? activeIndex - 1 : activeIndex
                      ]?.formHeading
                    }
                  </h4>
                  <div className={Classes.mandatory}>
                    <span>*</span> Mandatory Fields{" "}
                  </div>
                </div>

                {steps.map((item) => {
                  if (item.index === activeIndex) {
                    switch (item.index) {
                      case 0:
                        return (
                          <ContactVerification
                            key={item?.index}
                            startTime={30}
                            successModal={successModal}
                            setSuccessModal={setSuccessModal}
                            setIsProceed={setNextButtonDisable}
                            setAckId={setAckId}
                            ref={subscriberRef}
                            setPreviewData={setAllData}
                            setCorporateData={setCorporateData}
                            corpIdParam={corpIdParam}
                            handleProceedAckIdFunction={() => {
                              setActiveIndex(1);
                              setSuccessModal(false);
                            }}
                          />
                        );
                      case 1:
                        return (
                          <SubscriberRegistrationForm
                            key={item?.index}
                            setDigilockerProceedModal={
                              setDigilockerProceedModal
                            }
                            ackId={ackId}
                            setIsProceed={setNextButtonDisable}
                            previewData={mergeCorpDataWithFormData(allData)}
                            setPreviewData={setPreviewData}
                            setActiveIndex={setActiveIndex}
                            activeIndex={activeIndex}
                            isDigiLockerSubmit={isDigiLockerSubmit}
                            isDigiLockerModal={isDigiLockerModal}
                            isKycValidate={isKycValidate}
                          />
                        );
                      case 2:
                        return (
                          <NPSICSSForm
                            key={item?.index}
                            setIsProceed={setNextButtonDisable}
                            setPreviewData={setPreviewData}
                          />
                        );
                      case 3:
                        return (
                          <SubscriberFormStep3
                            key={item?.index}
                            setIsProceed={setNextButtonDisable}
                            ackId={ackId}
                            ref={subscriberRef}
                            previewData={allData}
                            setPreviewData={setPreviewData}
                            setActiveIndex={setActiveIndex}
                            corporateData={corporateData}
                          />
                        );
                      case 4:
                        return (
                          <NomineeDetails
                            key={item?.index}
                            setIsProceed={setNextButtonDisable}
                            setPreviewData={setPreviewData}
                            previewData={allData}
                          />
                        );
                    }
                  }
                })}

                {requirementModal && (
                  <SubscriptionRequirementModal
                    requiredModal={requirementModal}
                    setRequiredModal={setRequirementModal}
                    handleFunction={() => {
                      setRequirementModal(false);
                      setActiveIndex(1);
                    }}
                  />
                )}
                {/* {activeIndex === steps[4].index && (
                  <SubscriberBankDetails
                    bankDetailsRef={bankDetailsRef}
                    setIsProceed={setNextButtonDisable}
                    ackId={ackId}
                    subscriberRef={subscriberRef}
                    setActiveIndex={setActiveIndex}
                    setPreviewData={setPreviewData}
                  />
                )} */}
              </div>
            </div>
            <div className={Classes.comment_container_form}>
              <p className={Classes.instruct_p}>
                0{Number(steps[activeIndex]?.index) + 1} | Instruction
              </p>
              <h5>{steps[activeIndex]?.formHeading}</h5>
              <p className={Classes.text}>
                {steps[activeIndex]?.instruction?.text}
              </p>
              {steps[activeIndex]?.instruction?.htmlText &&
                steps[activeIndex]?.instruction?.htmlText}
            </div>
            <div className="btns mt-5">
              <SecondaryButton
                label={"Cancel"}
                disabled={activeIndex === steps[0].index}
                onClick={handleCancelClick}
                style={{ marginRight: "1rem" }}
              />
              <SecondaryButton
                label={activeIndex > 0 ? "Next" : "Proceed"}
                disabled={nextButtonDisable === true}
                onClick={() => handleClick(1)}
                style={{ marginRight: "1rem" }}
              />

              {/* {steps[activeIndex]?.index === 4 && (
                <PrimaryButton
                  label={"Fill Bank Details Manually"}
                  onClick={() => bankDetailsRef.current.toggle()}
                />
              )} */}
            </div>
          </div>
        </div>
      )}

      {isDigiLockerModal && (
        <SubscriberDigiLocker
          url={digiLockerURL}
          isOpenModal={isDigiLockerModal}
          ackId={ackId}
          setIsOpenModal={setIsDigiLockerModal}
          setisDigiLockerSubmit={setisDigiLockerSubmit}
          setIsKycValidate={setIsKycValidate}
        />
      )}

      {digilockerProceedModal && (
        <DigiLockerModal
          callDigiLockerfunction={callDigiLockerfunction}
          setIsModalOpen={setDigilockerProceedModal}
        />
      )}

      {isRegPendingModal && (
        <PendingFormModal
          basicDetails={basicDetails}
          setBasicDetails={setBasicDetails}
          handleSubscriber={handleSubscriber}
          handleClickNewNPS={handleClickNPS}
          isRegPendingModal={isRegPendingModal}
          setIsRegPendingModal={setIsRegPendingModal}
          handlePendingForm={handlePendingForm}
          handleSendOTP={handleSendOTP}
          isOTP={isOTP}
          isOtpValid={isOtpValid}
          requestId={requestId}
          handleValidateOTP={handleValidateOTP}
        />
      )}
    </>
  );
};
export default SubscriberRegistration;
