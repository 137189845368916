import React from "react";
import classes from "../MISReport.module.css";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { useState } from "react";
import Utils from "../../../Utils";
import ArrowUPIcon from "../../../assets/arrow-up.svg";
import { useEffect } from "react";
import {
  getAllAPIReport,
  getAllUserReport,
} from "../../AdminManagement/AdminAction";

const UserReport = ({ searchingQuery, dateOne, dateTwo, perPage }) => {
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("");
  const [accessor, setAccessor] = useState("");
  const [columnRotations, setColumnRotations] = useState("submissionDateasc");
  const [tableData, setTableData] = useState([]);
  const [pageData, setPageData] = useState({});
  // fr corporate columns
  const ApiReportColumn = [
    {
      header: (
        <p className={classes.headers}>
          ID
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "entityRowIdasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("id")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e?.id,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 12 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          NAME
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "frClosedByasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("name")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e?.name,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 16 },
      isTextCenter: true,
      widthStyle: "10%",
    },
    {
      header: (
        <p className={classes.headers}>
          IP ADDRESS
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "actionasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("ip")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e?.ip,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 16 },
      isTextCenter: true,
      widthStyle: "10%",
    },
    {
      header: (
        <p className={classes.headers}>
          DATE & TIME
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "frRaisedByasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("date")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) =>
        `${Utils.getDateFromDateTime(e?.date)}, ${Utils.getTimeFromDateTime(
          e?.date
        )}`,
      toolTipStatus: true,
      isTextCenter: true,
    },

    {
      header: (
        <p className={classes.headers}>
          EMAIL
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "submissionTimeasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("email")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e?.email,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 16 },
      isTextCenter: true,
    },

    {
      header: (
        <p className={classes.headers}>
          CONTECT NUMBER
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "updationTimeasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("contactNum")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e?.contactNum,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 10 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          DEPARTMENT
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "statusasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("department")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e?.department,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 10 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          DESIGNATION
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "statusasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("designation")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e?.designation,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 10 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          FUNCTION
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "statusasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("function")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e?.function,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 10 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          ACTION
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "statusasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("action")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e?.action,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 10 },
      isTextCenter: true,
    },
  ];
  useEffect(() => {
    setPage(0);
  }, [perPage, searchingQuery, dateOne, dateTwo]);
  useEffect(() => {
    getDataFunction();
  }, [sort, accessor, dateOne, dateTwo, page, perPage, searchingQuery]);

  const getDataFunction = () => {
    getAllUserReport(
      searchingQuery,
      dateOne,
      dateTwo,
      page,
      perPage,
      sort,
      accessor
    ).then((res) => {
      setTableData(res.data.data.content);
      setPageData(res.data.data);
    });
  };

  const handleSort = (key) => {
    var sorting = sort;
    // Toggle sort order
    const newSort = sort === "asc" ? "desc" : "asc";
    setSort(newSort);
    setAccessor(key);

    // Update rotation state for the current column
    if (sort === "asc") {
      setColumnRotations(key + "desc");
    } else {
      setColumnRotations(key + "asc");
    }
  };
  return (
    <div className={classes.table}>
      <CustomTable
        page={page}
        setPage={setPage}
        headings={ApiReportColumn}
        data={tableData}
        pageData={pageData}
        sort={sort}
        setSort={setSort}
        accessor={accessor}
        setAccessor={setAccessor}
        srNO={true}
      />
    </div>
  );
};

export default UserReport;
