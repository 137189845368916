import React, { useEffect } from "react";
import classes from "./ResetPassword.module.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import HDFCLOGO from "../../../../assets/HeaderAssets/HDFCLOGO2.svg";
import Login from "../../../../assets/Login_Img.png";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { changePassword, checkToken } from "../../../Home/HomeAction";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../../Home/HomeSlice";
import { BASE_URL, SIGN_IN_URL } from "../../../../Routes/Urls";
import Utils from "../../../../Utils";

const ResetPassword = () => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [token, setToken] = useState("");
  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [searchParams] = useSearchParams();

  // check if token is valid
  useEffect(() => {
    setToken(searchParams.get("token"));
    checkToken(searchParams.get("token"))
      .then((res) => {
        if (res.data.success === true) {
        }
      })
      .catch((error) => {
        Navigate(`${BASE_URL}/${SIGN_IN_URL}`);
      });
  }, []);

  const handlePassword = (e) => {
    e.preventDefault();
    // check both password should be equal
    if (password.newPassword !== password.confirmPassword) {
      dispatch(
        setAlertMsg({
          msg: "Password must be same",
          alertType: "danger",
        })
      );
    } else {
      //  change password
      changePassword(token, Utils.encryptedPassword(password.confirmPassword))
        .then((res) => {
          if (res?.data?.success === true) {
            Navigate(`${BASE_URL}/${SIGN_IN_URL}`);
            dispatch(
              setAlertMsg({
                msg: res.data.message,
                alertType: "success",
              })
            );
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(
              setAlertMsg({
                msg: error?.response?.data?.message,
                alertType: "danger",
              })
            );
          } else {
            dispatch(
              setAlertMsg({
                msg: t("alertMsg.internal_server_error"),
                alertType: "danger",
              })
            );
          }
        });
    }
  };

  return (
    <form onSubmit={handlePassword} className={classes.main}>
      <div className={classes.login_imgside}>
        <div className={classes.login_imgcontainer}>
          <img className={classes.hdfc_logo} src={HDFCLOGO} />
          <img className={classes.loginscreen_logo} src={Login} />
        </div>
      </div>

      <div className={classes.loginForm} autoComplete="on">
        <div className={classes.loginForm_container}>
          <div className={classes.loginForm_title}>
            <h2>Change password</h2>
          </div>
          <div className={classes.input_fields}>
            <label htmlFor="email">
              Enter new password
              <input
                value={password.newPassword}
                onChange={(e) =>
                  setPassword((data) => ({
                    ...data,
                    newPassword: e.target.value,
                  }))
                }
                type="password"
              />
            </label>
            <label>
              confirm password
              <input
                value={password.confirmPassword}
                onChange={(e) =>
                  setPassword((data) => ({
                    ...data,
                    confirmPassword: e.target.value,
                  }))
                }
                type="password"
              />
            </label>
            <PrimaryButton
              style={{
                width: "100%",
                height: "2.8rem",
                marginTop: "1rem",
              }}
              label={t("text.resetPassword")}
              type="submit"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default ResetPassword;
