import React, { useState } from "react";
import Modal from "../../../../components/Modal/Modal";
import Classes from "./ChargeRejectModal.module.css";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import Consts from "../../../../Consts";

const ChargeRejectModal = ({
  isRaiseModal,
  setIsRaiseModal,
  handleSubmit,
  screenType,
  reasonString,
}) => {
  const [textVal, setTextVal] = useState("");
  return (
    isRaiseModal && (
      <Modal
        title={
          screenType === Consts.SCREEN_TYPE.VIEW
            ? "Reject Charges"
            : "Charge Scrutiny Reason"
        }
      >
        <div className={Classes.chargeReject}>
          

          <div className={Classes.content}>
              <textarea
                className="form-control"
                rows={4}
                placeholder="Enter the Reason..."
                onInput={(e) => setTextVal(e?.target?.value)}
                disabled={screenType === Consts.SCREEN_TYPE.EDIT }
              >
                {screenType === Consts.SCREEN_TYPE.VIEW ? textVal : reasonString}
              </textarea>
          </div>
          <div className={Classes.btnSection}>
            <SecondaryButton
              label={"Cancel"}
              onClick={() => setIsRaiseModal(false)}
            />
             {screenType === Consts.SCREEN_TYPE.VIEW &&
            <PrimaryButton
              label={"Reject"}
              disabled={textVal?.trim()?.length < 1}
              onClick={() => handleSubmit(Consts.TYPE_REJECT, textVal)}
            />
             }
          </div>
        </div>
      </Modal>
    )
  );
};

export default ChargeRejectModal;
