import react from "react";
import Modal from "../../../../components/Modal/Modal";
import Classes from "./FrHistoryModal.module.css";
import CustomTable from "../../../../components/CustomTable/CustomTable";
const FrHistoryModal = ({ tableColumn,tableData, isModal, setIsModal }) => {


  const closeBtnCSS={
    cursor: "pointer",
    backgroundColor: "white",
    width: "1.875rem",
    height: "1.875rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    top: "-0.25rem",
    right: "-0.25rem",
    zIndex: "99999",
  }
  return (
    isModal &&(
    <Modal onClose={()=>setIsModal(false)} closeBtn={closeBtnCSS}>
      <div className={Classes.frHistoryModal}>
        <div className={Classes.frHistoryTitle}>
          <h4>Raised FR History</h4>
          <p>You will be re directed to Digilock website</p>
        </div>
        <div className={Classes.frHistoryContent}>
          <CustomTable headings={tableColumn} srNO={true} data={tableData} isPaginationShow={true} />
        </div>
      </div>
    </Modal>)
  );
};
export default FrHistoryModal;
