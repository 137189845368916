import { createSlice } from "@reduxjs/toolkit";

const SubscriberDetail = createSlice({
  name: "subscriberDetails",
  initialState: { subAckId: "", subPan: "" },
  reducers: {
    subDetails: (state, action) => {
      return action.payload;
    },
  },
});


const SubscriberRegType = createSlice({
  name: "subscriberRegType",
  initialState: { regType : null },
  reducers: {
    subRegisterType: (state, action) => {
      return action.payload;
    },
  },
});


export const { subDetails } = SubscriberDetail.actions;
export const { subRegisterType} = SubscriberRegType.actions;

export const SubscriberDetailReducer = SubscriberDetail.reducer;
export const SubscriberRegTypeReducer= SubscriberRegType.reducer;
 