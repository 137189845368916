import axios from "axios";

export function getAllSubscriber(data, startDate, endDate) {
  return axios({
    url: `/hpmc/api/subscribers?registrationType=${data.regType}&pageNumber=${data.page}&orderBy=${data.sort}&filterBy=${data.filterBy}&sortBy=${data.accessor}&pageSize=${data.perPage}&startDate=${startDate}&endDate=${endDate}&applicantType=1`,
    method: "GET",
    responseType: "json",
  });
}

export function getSubscriberCsvFile(dateOne, dateTwo) {
  let url = `/hpmc/api/subscribers?startDate=${dateOne}&endDate=${dateTwo}&exportCsv=1`;
  window.open(url, "_blank", "noopener,noreferrer");
}
export function getSubscriberFullCsvFile(dateOne, dateTwo) {
  let url = `/hpmc/api/subscribers?startDate=${dateOne}&endDate=${dateTwo}&exportFullCsv=1`;
  window.open(url, "_blank", "noopener,noreferrer");
}

export function getCorporateByCHO(choNum) {
  return axios({
    url: `/hpmc/api/corp-reg/corporatebychonumber?choNumber=${choNum}`,
    method: "GET",
    responseType: "json",
  });
}
