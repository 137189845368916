import React from "react";
import classes from "./SubscriberDashboard.module.css";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import Card from "../../../components/Card/Card";
import { BASE_URL } from "../../../Routes/Urls";
import FurtherRequirementImg from "../../../assets/CardsImgs/FurtherRequest.png";
import ServiceRequestImg from "../../../assets/CardsImgs/ServiceRequest.png";
import ContributionImg from "../../../assets/CardsImgs/ContributionImg.png";
import Dashboard from "../../../components/Dashboard/Dashboard";

const SubscriberDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const subscriberCardObj = [
    {
      path: FurtherRequirementImg,
      label: t("card.furtherRequirement"),
      img: FurtherRequirementImg,
    },
    {
      path: ServiceRequestImg,
      label: t("card.serviceRequest"),
      img: ServiceRequestImg,
    },
    {
      path: ContributionImg,
      label: t("card.contribution"),
      img: ContributionImg,
    },
  ];

  const handleCardClick = (path) => {
    navigate(`/${BASE_URL}/SubscriberDashboard`);
  };

  return (
    <Dashboard
      title={t("text.subscriberDashboard")}
      cardsObj={subscriberCardObj}
      handleCardClick={handleCardClick}
    />
  );
};

export default SubscriberDashboard;
