import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/CustomTable/CustomTable";
import Classes from "./ContributionDashboard.module.css";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import {
  getContributionData,
  getDownloadContributionFile,
  getSingleDocView,
} from "../ContributionActions";
import TextButton from "../../../components/Buttons/TextButton";
import Utils from "../../../Utils";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../Home/HomeSlice";

const ContributionDashboard = () => {
  const dispatch = useDispatch();
  const [contributionData, setContributionData] = useState([]);

  const handleContributionDetails = () => {
    getContributionData().then((res) => {
      setContributionData(res?.data?.data.sort((a, b) => a.id - b.id));
    });
  };

  useEffect(() => {
    handleContributionDetails();
  }, []);

  const holidayTableColumns = [
    {
      header: <p className={Classes.table_header}>Id</p>,
      accessor: (e) => e.id,
    },
    {
      header: <p className={Classes.table_header}>Corp Name</p>,
      accessor: (e) => e.corporateName,
    },
    {
      header: <p className={Classes.table_header}>Date</p>,
      accessor: (e) => Utils.getDateFromDateTime(e.uploadDate),
    },
    {
      header: <p className={Classes.table_header}>Time</p>,
      accessor: (e) => Utils.getTimeHHMMFromDateTime(e.uploadDate),
    },
    {
      header: <p className={Classes.table_header}>Corporate CHO Number</p>,
      accessor: (e) => e?.corporateChoNumber || "NA",
    },
    {
      header: <p className={Classes.table_header}>User Name</p>,
      accessor: (e) => e.nodalOfficerUsername,
    },

    {
      header: <p className={Classes.table_header}>Download Document</p>,
      accessor: (e) =>
        !e.isDownloaded ? (
          <PrimaryButton
            label={"Download "}
            onClick={() => downloadDocument(e.id)}
          />
        ) : (
          <TextButton
            label={"Download "}
            onClick={() => downloadDocument(e.id)}
          />
        ),
    },
  ];

  const downloadDocument = (data) => {
    getDownloadContributionFile(data)
      .then(() => handleContributionDetails())
      .catch((error) =>
        dispatch(
          setAlertMsg({
            msg: error.response?.data?.message,
            alertType: "danger",
          })
        )
      );
  };

  return (
    <section>
      <div className="container-fluid pb-5 pt-3 px-4">
        <div className="goBackButton">
          <GoBackButton />
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className={Classes.form_title}>
              <h1>Contribution Dashboard </h1>
            </div>
          </div>
        </div>

        <div className="row pt-4">
          <div className="col-lg-12">
            <CustomTable
              headings={holidayTableColumns}
              data={contributionData}
              srNO={false}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContributionDashboard;
