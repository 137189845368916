import React, { useEffect, useRef, useState } from "react";
import TableDashboard from "../../components/TableDashboard/TableDashboard";
import Utils from "../../Utils";
import ArrowUPIcon from "../../assets/TableDashboard/ArrowUp.svg";
import CheckMarkIcon from "../../assets/TableDashboard/checkmark.svg";
import OverdueIcon from "../../assets/TableDashboard/overdue.svg";
import TimelimitIcon from "../../assets/TableDashboard/Timelimit.svg";
import ClockIcon from "../../assets/TableDashboard/time.svg";
import GreenDot from "../../assets/TableDashboard/GreenDot.svg";
import OrangeDot from "../../assets/TableDashboard/OrangeDot.svg";
import RedDot from "../../assets/TableDashboard/RedDot.svg";
import user_blue_icon from "../../assets/user_blue_icon.svg";
import scruitnyChargeIcon from "../../assets/scrutinyCharge.svg";
import updateChargeIcon from "../../assets/updateCharge.svg";
import infoIcon from "../../assets/info-blue-icon.svg";
import classes from "./CorporateDashboard.module.css";
import {
  BASE_URL,
  DASHBOARD_URL,
  CORPORATEDASHBOARD_URL,
  CORPORATE_ONBOARD_FORM_URL,
  CHO_FORM_URL,
  SCRUTINY_FORM_URL,
  ROADMAPURL,
  CORPORATE_CHARGE_SCRUITNY_URL,
  CORPORATE_CHARGE_UPDATE_URL,
} from "../../Routes/Urls";
import {
  assignPotentialUser,
  claimCorporate,
  getCorpCsvFile,
  getCorpFullCsvFile,
  getCorporateDashboardData,
  getPotentialUser,
  releaseCorporate,
  sendCorpWelcomeKit,
  getCorpPreviewFormByAckId,
} from "./CorporateAction";
import { useDispatch, useSelector } from "react-redux";
import { json, useNavigate } from "react-router";
import Modal from "../../components/Modal/Modal";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import PreViewForm from "../../components/PreViewForm/PreViewForm";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField, Tooltip } from "@mui/material";
import { setAlertMsg } from "../Home/HomeSlice";
import ClaimIcon from "../../assets/ActionBtnIcon/flag-alt.svg";
import ReleaseIcon from "../../assets/ActionBtnIcon/release_icon.svg";
import CompleteFormIcon from "../../assets/ActionBtnIcon/register_icon_inactive.svg";
import ScrutinyIcon from "../../assets/ActionBtnIcon/scrutiny_icon.svg";
import ChoIcon from "../../assets/ActionBtnIcon/update_cho_icon.svg";
import DelegateIcon from "../../assets/ActionBtnIcon/Delegate_icon.svg";
import UpdateDocIcon from "../../assets/ActionBtnIcon/updatedoc_icon.svg";
import WelcomeKitIcon from "../../assets/ActionBtnIcon/gift.svg";
import AssignIcon from "../../assets/ActionBtnIcon/Assign_icon.svg";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import Consts from "../../Consts";

import RequiredDocumentModal from "./CorporateJourneyHelper/RequiredDocumentModal";
import TimeLineRoadMapModal from "./RMJourney/TimeLineRoadMapModal/TimeLineRoadMapModal";
import { getCorporateWorkFlow } from "../../components/WorkFlowRoadMap/WorkFlowActions";

const CorporateDashboard = () => {
  // to print screen
  const componentRef = useRef();
  const { loginUserDetails } = useSelector((state) => state);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [columnRotations, setColumnRotations] = useState("submissionDateasc");
  const [tableData, setTableData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [pageData, setPageData] = useState({});
  const [page, setPage] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [sort, setSort] = useState("desc");
  const [accessor, setAccessor] = useState("submissionDate");
  const [perPage, setperPage] = useState(10);
  const [totalCount, setTotalCount] = useState({});
  const [filterBy, setfilterBy] = useState("");
  const [choModal, setChoModal] = useState(false);
  const [ackId, setAckId] = useState("");
  const [formObj, setFormObj] = useState({});
  const [corpFormModal, setCorpFormModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [potentialUser, setPotentialUsers] = useState([]);
  const [dateOne, setDateOne] = useState("");
  const [dateTwo, setDateTwo] = useState("");
  const userDeatils = useSelector((state) => state.loginUserDetails);
  const [potentialUserId, setPotentialUserId] = useState("");
  const [currCorpId, setCurrCorpId] = useState(0);
  const [subordinateId, setSubordinateId] = useState("");
  const [requiredModal, setRequiredModal] = useState(false);
  const [roadMapData, setRoadMapData] = useState([]);
  const [isRoadMapModal, setIsRoadMapModal] = useState(false);
  const [roadMapCorpData, setRoadMapCorpData] = useState({});
  const [previewData, setPreviewData] = useState();

  useEffect(() => {
    getCorporateDashboardData(
      {
        page,
        searchInput,
        sort,
        accessor,
        perPage,
        filterBy,
        subordinateId,
      },
      Utils.convertToLocalDateForInput(dateOne),
      Utils.convertToLocalDateForInput(dateTwo)
    ).then((res) => {
      setTableData(res.data.data.pageData.content);
      setPageData(res.data.data.pageData);
      setTotalCount(res.data.data.countData);
    });

    localStorage.removeItem("currentStep");
    localStorage.removeItem("currentSubTab");
  }, [
    page,
    sort,
    accessor,
    perPage,
    filterBy,
    subordinateId,
    dateOne && dateTwo,
  ]);

  useEffect(() => {
    setCorpFormModal(false);
  }, [formObj]);

  useEffect(() => {
    setPage(0);
  }, [perPage, filterBy, searchInput]);

  // handle search
  const handleSearch = (e) => {
    e.preventDefault();
    getCorporateDashboardData(
      {
        page,
        searchInput,
        sort,
        accessor,
        perPage,
        filterBy,
        subordinateId,
      },
      Utils.convertToLocalDateForInput(dateOne),
      Utils.convertToLocalDateForInput(dateTwo)
    ).then((res) => {
      setTableData(res.data.data.pageData.content);
      setPageData(res.data.data.pageData);
      setTotalCount(res.data.data.countData);
    });
  };

  // claim and release tasks
  const handleClaimAndRelease = (taskStatus, corpId, e) => {
    if (taskStatus === null) {
      claimCorporate(corpId)
        .then((res) => {
          if (res.data) {
            if (res.data.success === true) {
              const simulatedEvent = { preventDefault: () => {} };
              handleSearch(simulatedEvent);
              dispatch(
                setAlertMsg({
                  msg: `Corporate CR${corpId} has been claimed `,
                  alertType: "success",
                })
              );
            }
          }
        })
        .catch((error) => {
          dispatch(
            setAlertMsg({
              msg: `Unable to Claim`,
              alertType: "danger",
            })
          );
        });
    } else {
      releaseCorporate(corpId)
        .then((res) => {
          if (res.data) {
            if (res.data.success === true) {
              dispatch(
                setAlertMsg({
                  msg: `Corporate CR${corpId} has been released`,
                  alertType: "success",
                })
              );
              const simulatedEvent = { preventDefault: () => {} };
              handleSearch(simulatedEvent);
            }
          }
        })
        .catch((error) => {
          dispatch(
            setAlertMsg({
              msg: `Unable to  Release`,
              alertType: "danger",
            })
          );
        });
    }
  };

  const handleCheckSubordinate = (data) => {
    if (data?.subordinates?.length > 0 && subordinateId != "") {
      return true;
    }
    return false;
  };

  //  check nodal officer (corporate task) then remove release button
  const checkNOCorporateTask = (data) => {
    if (
      data?.currentAssignedUser?.username === userDeatils?.username &&
      data?.formFiller === Consts.FORM_FILLER_CORPORATE &&
      !Utils.isAdmin(userDeatils)
    ) {
      return true;
    }

    return false;
  };

  // table columns
  // tooltip status and shortlen status, shortlen show when both conditions are true
  const columns = [
    {
      header: (
        <p className={classes.headers}>
          ACKNOWLEDGEMENT ID
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "ackIdasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("ackId")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => (
        <div className={classes.no_wrap}>
          {e.ackId}
          <Tooltip placement="bottom" title={"Timeline"}>
            <button
              className={classes.infoRoadMapIcon}
              onClick={(event) => {
                handleTimeLineRoadMapURL(e);
                setRoadMapCorpData(e);
              }}
            >
              <img className={classes.icon_img} src={infoIcon} />
            </button>
          </Tooltip>
        </div>
      ),
      widthStyle: "15%",
    },
    {
      header: (
        <p className={classes.headers} style={{ textAlign: "left" }}>
          CORPORATE NAME
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "corpNameasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("corpName")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.corpName,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 30 },
    },
    {
      header: (
        <p className={classes.headers}>
          DATE
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "submissionDateasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("submissionDate")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => Utils.convertToLocalDateOnly(e.submissionDate),
      widthStyle: "8%",
    },
    {
      header: (
        <p className={classes.headers}>
          TAT 1
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "tatasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("tat")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => (
        <p className={classes.tat_container}>
          <img
            src={
              e.tat === 0 || e.tat <= 5
                ? GreenDot
                : e.tat === 6 || e.tat <= 10
                ? OrangeDot
                : RedDot
            }
          />
          {e.tat}
        </p>
      ),
    },
    {
      header: (
        <p className={classes.headers}>
          TAT 2
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "tat2asc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("tat2")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => (
        <p className={classes.tat_container}>
          <img
            src={
              e.tat2 === 0 || e.tat2 <= 5
                ? GreenDot
                : e.tat2 === 6 || e.tat2 <= 10
                ? OrangeDot
                : RedDot
            }
          />
          {e.tat2}
        </p>
      ),
    },
    {
      header: <p className={classes.headers}>STATUS</p>,
      key: "corpStatus",
      accessor: (e) => (
        <p className={classes.state_container}>{e.corpStatus?.name}</p>
      ),
      widthStyle: "8%",
    },
    {
      header: (
        <p className={classes.headers}>
          PENDING WITH
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "currentAssignedUser.usernameasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("currentAssignedUser.username")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      key: "pendingWith",
      accessor: (e) => (
        <div
          className={classes.state_pending}
          title={
            e?.currentAssignedUser && e?.corpStatus?.name != "Onboarded"
              ? e?.currentAssignedUser.username
              : "NA"
          }
        >
          <div className={classes.pending}>
            {e?.currentAssignedUser && e?.corpStatus?.name != "Onboarded"
              ? Utils.ShortLengthFunction(e?.currentAssignedUser.username, 25)
              : "NA"}
          </div>
        </div>
      ),
    },
    {
      header: <p className={classes.headers_action}>ACTION</p>,
      key: "corpAction",
      accessor: (e) => (
        <div className={classes.action_btn}>
          {/* claim and release */}
          {checkNOCorporateTask(e) === false && (
            <Tooltip
              placement="bottom"
              title={
                e.currentAssignedUser === null
                  ? "Claim"
                  : e.currentAssignedUser.username === userDeatils.username
                  ? "Release"
                  : ""
              }
            >
              <span>
                <button
                  disabled={
                    e.currentAssignedUser === null
                      ? false
                      : e.currentAssignedUser.username !==
                          userDeatils.username || e.isSaved === true
                      ? true
                      : false
                  }
                  style={{
                    display:
                      (e.isSaved && e.isSaved === true) ||
                      e.corpStatus?.id ===
                        Consts.CORPORATE_ONBOARD_STATUSCODE.onboarded ||
                      (e.currentAssignedUser !== null &&
                        e.currentAssignedUser?.username !==
                          userDeatils?.username)
                        ? "none"
                        : "",
                  }}
                  onClick={(event) =>
                    handleClaimAndRelease(e.currentAssignedUser, e.id, event)
                  }
                >
                  <img
                    className={classes.icon_img}
                    style={{
                      opacity:
                        e.currentAssignedUser === null
                          ? false
                          : e.currentAssignedUser.username !==
                              userDeatils.username || e.isSaved === true
                          ? ".4"
                          : false,
                    }}
                    src={
                      e.currentAssignedUser === null
                        ? ClaimIcon
                        : e.currentAssignedUser.username ===
                          userDeatils.username
                        ? ReleaseIcon
                        : ""
                    }
                  />
                </button>
              </span>
            </Tooltip>
          )}

          {/* corporate action buttons */}
          <Tooltip
            title={
              e.corpAction && corporateActionButton(e?.corpAction?.id)?.name
            }
          >
            <span>
              <button
                onClick={() => handleActionBtn(e)}
                //   check status if onboard then show otherwise check assigned user
                disabled={
                  (e.corpStatus?.id ===
                  Consts.CORPORATE_ONBOARD_STATUSCODE.onboarded
                    ? false
                    : !e.currentAssignedUser ||
                      e.currentAssignedUser?.username !== userDeatils.username
                    ? true
                    : false) ||
                  (e?.corpAction?.id ===
                    Consts.CORPORATE_ONBOARD_STATUSCODE.welcomeKit &&
                    e?.isKitSent === true &&
                    !Utils.isAdmin(userDeatils))
                }
              >
                <img
                  className={classes.icon_img}
                  style={{
                    opacity:
                      e.corpStatus?.id ===
                      Consts.CORPORATE_ONBOARD_STATUSCODE.onboarded
                        ? false
                        : !e.currentAssignedUser ||
                          e.currentAssignedUser?.username !==
                            userDeatils.username
                        ? ".4"
                        : false,
                  }}
                  src={
                    e.corpAction &&
                    (e?.corpAction?.name === "Update CHO" &&
                    e?.isPopShift === true
                      ? ChoIcon
                      : corporateActionButton(e.corpAction?.id)?.img)
                  }
                />
              </button>
            </span>
          </Tooltip>

          {/* delegate and assign */}
          {e.corpStatus?.name !== "Onboarded" &&
            (Utils.isAdmin(loginUserDetails) ||
              handleCheckSubordinate(loginUserDetails)) && (
              <Tooltip
                placement="bottom"
                title={e.currentAssignedUser !== null ? "Delegate" : "Assign"}
              >
                <span>
                  <button
                    onClick={(event) =>
                      handlePotentialUser(e.id, e.currentAssignedUser, event)
                    }
                  >
                    <img
                      className={classes.icon_img}
                      src={
                        e.currentAssignedUser !== null
                          ? DelegateIcon
                          : AssignIcon
                      }
                    />
                  </button>
                </span>
              </Tooltip>
            )}
        </div>
      ),
    },
  ];

  //  get corporate action button
  const corporateActionButton = (id) => {
    let statusCode = Consts.CORPORATE_ONBOARD_STATUSCODE;
    switch (id) {
      case statusCode.completeReg:
        return { name: "Complete Registration", img: CompleteFormIcon };
      case statusCode.scrutiny:
        return { name: "Complete Scrutiny", img: ScrutinyIcon };
      case statusCode.updateCho:
        return { name: "Update CHO / CBO", img: ChoIcon };
      case statusCode.updateDoc:
        return { name: "Update Documents", img: UpdateDocIcon };
      case statusCode.welcomeKit:
        return { name: "Send Welcome Kit", img: WelcomeKitIcon };
      case statusCode.scruitnyCharge:
        return { name: "Charge Scruitny", img: scruitnyChargeIcon };
      case statusCode.updateCharge:
        return { name: "Update Charges", img: updateChargeIcon };
    }
  };

  //  corporate Action
  const handleActionBtn = (e) => {
    let statusCode = Consts.CORPORATE_ONBOARD_STATUSCODE;
    let corpId = e.id;
    let corpStatusId = e.corpAction.id;

    if (corpStatusId === statusCode.welcomeKit) {
      sendCorpWelcomeKit(corpId)
        .then((res) => {
          if (res.data.success === true) {
            dispatch(
              setAlertMsg({
                msg: `Welcome Kit has been sent to ${e.corpName}`,
                alertType: "success",
              })
            );
          }
        })
        .catch((error) => {
          dispatch(
            setAlertMsg({
              msg: error.response.data.message,
              alertType: "danger",
            })
          );
        });
    } else {
      let nextState = e.processState?.id;
      switch (nextState) {
        case 1:
        case 2:
          navigate(
            `/${BASE_URL}/${DASHBOARD_URL}/${CORPORATEDASHBOARD_URL}/${CORPORATE_ONBOARD_FORM_URL}`
          );
          break;
        case 3:
          navigate(
            `/${BASE_URL}/${DASHBOARD_URL}/${CORPORATEDASHBOARD_URL}/${SCRUTINY_FORM_URL}`
          );
          break;
        case 4:
          navigate(
            `/${BASE_URL}/${DASHBOARD_URL}/${CORPORATEDASHBOARD_URL}/${CHO_FORM_URL}`
          );

          break;
        case 9:
          navigate(
            `/${BASE_URL}/${DASHBOARD_URL}/${CORPORATEDASHBOARD_URL}/${CORPORATE_CHARGE_UPDATE_URL}`
          );
          break;
        case 10:
          navigate(
            `/${BASE_URL}/${DASHBOARD_URL}/${CORPORATEDASHBOARD_URL}/${CORPORATE_CHARGE_SCRUITNY_URL}`
          );
          break;
        default:
          break;
      }
      localStorage.setItem("corpState", JSON.stringify(nextState));
    }
    localStorage.setItem("corpId", JSON.stringify(corpId));
  };

  const handleTimeLineRoadMapURL = (e) => {
    let corpId = e.id;
    getCorporateWorkFlow(corpId).then((res) => {
      setRoadMapData(res.data.data);
      setIsRoadMapModal(true);
    });
  };

  // handling sort
  const handleSort = (key) => {
    // Toggle sort order
    const newSort = sort === "asc" ? "desc" : "asc";
    setSort(newSort);
    setAccessor(key);

    // Update rotation state for the current column
    if (sort === "asc") {
      setColumnRotations(key + "desc");
    } else {
      setColumnRotations(key + "asc");
    }
  };

  const analyticCard = [
    { icon: ClockIcon, title: t("text.pending"), count: totalCount.pendingall },
    {
      icon: CheckMarkIcon,
      title: t("text.complete"),
      count: totalCount.completedall,
    },
    {
      icon: TimelimitIcon,
      title: t("text.overdue"),
      count: totalCount.overdueall,
    },
    {
      icon: OverdueIcon,
      title: t("text.futherRequest"),
      count: totalCount.frall,
    },
  ];

  // tab  data array
  const tabsLabel = [
    {
      title: ` All (${
        totalCount.pending +
          totalCount.rejected +
          totalCount.accepted +
          totalCount.onboarded +
          totalCount.unclaimed || 0
      })`,
      key: "",
    },
    {
      title: `${t("text.pending")} (${totalCount.pending ?? 0})`,
      key: "pending",
    },
    {
      title: `${t("text.rejected")} (${totalCount.rejected ?? 0})`,
      key: "rejected",
    },
    {
      title: `${t("text.accepted")} (${totalCount.accepted ?? 0})`,
      key: "accepted",
    },
    {
      title: `${t("text.onboard")} (${totalCount.onboarded ?? 0})`,
      key: "onboarded",
      pageType: "corporateDashboard",
      click: "onboarded",
    },
    {
      title: `${t("text.tatexceeded")} (${totalCount.tatexceeded ?? 0})`,
      key: "tatexceeded",
    },
    {
      title: `${t("text.unclaimed")} (${totalCount.unclaimed ?? 0})`,
      key: "unclaimed",
    },
  ];

  // redirect to new corp  form
  const handleNewCorpForm = () => {
    localStorage.setItem("corpId", JSON.stringify(0));
    localStorage.setItem("corpState", JSON.stringify(0));

    navigate(
      `/${BASE_URL}/${DASHBOARD_URL}/${CORPORATEDASHBOARD_URL}/${CORPORATE_ONBOARD_FORM_URL}`
    );
  };

  const requiredDocumentsFunction = () => {
    setRequiredModal(true);
  };

  const handlePrviewForm = (ackId) => {
    getCorpPreviewFormByAckId(ackId)
      .then((res) => {
        if (res.status === 200) {
          const fileUrl = URL.createObjectURL(res.data);
          setPreviewData(fileUrl);
          setAckId("");
          setChoModal(false);
          setCorpFormModal(true);
        }
      })
      .catch((error) => {
        setAckId("");
        setChoModal(false);
        Utils.blobReader(error.response.data)
          .then((res) =>
            dispatch(
              setAlertMsg({
                msg: JSON.parse(res)?.message,
                alertType: "danger",
              })
            )
          )
          .catch((err) =>
            dispatch(
              setAlertMsg({
                msg: err.response.data.message,
                alertType: "warning",
              })
            )
          );
      });
  };

  // get all potential user list
  const handlePotentialUser = (corpId, currentAssignedUser, e) => {
    setPotentialUserId(currentAssignedUser?.Id);
    setCurrCorpId(corpId);
    getPotentialUser(corpId)
      .then((res) => {
        if (res.data.success === true) {
          setPotentialUsers(res.data.data);
          setAssignModal(true);
        }
      })
      .catch((error) =>
        dispatch(
          setAlertMsg({
            msg: `Unable to assign`,
            alertType: "danger",
          })
        )
      );
  };

  //  assign user
  const handleAssignUser = (e) => {
    assignPotentialUser(currCorpId, potentialUserId.id)
      .then((res) => {
        if (res.data.success === true) {
          const simulatedEvent = { preventDefault: () => {} };
          handleSearch(simulatedEvent);
          dispatch(
            setAlertMsg({
              msg: `Task assigned to ${potentialUserId?.fullName}`,
              alertType: "success",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setAlertMsg({
            msg: `Internal server error`,
            alertType: "danger",
          })
        );
      });

    setAssignModal(false);
  };

  //  table strings
  const tableStrings = {
    title: "Corporate Onboarding",
    listAll_title: "Corporate Registration",
    search_placeholder: "Search Name/Acknowledgement Id",
  };

  //  downloadzip csv
  const downloadCsv = () => {
    getCorpCsvFile(
      Utils.convertToLocalDateForInput(dateOne),
      Utils.convertToLocalDateForInput(dateTwo)
    );
  };

  const downloadFullCsv = () => {
    getCorpFullCsvFile(
      Utils.convertToLocalDateForInput(dateOne),
      Utils.convertToLocalDateForInput(dateTwo)
    );
  };

  const handleTableRowColor = (highLight) => {
    if (highLight === true) {
      return "var(--app-light-blue-color)";
    } else {
      return "";
    }
  };

  return (
    <div>
      <TableDashboard
        data={{ page, sort, accessor, perPage, filterBy, dateOne, dateTwo }}
        setChoModal={setChoModal}
        dateOne={dateOne}
        dateTwo={dateTwo}
        tableStrings={tableStrings}
        columns={columns}
        downloadCsv={downloadCsv}
        handleSort={handleSort}
        analyticCard={analyticCard}
        tabsLabel={tabsLabel}
        handleNewCorpForm={
          Utils.hasCorporateFormFillUp(loginUserDetails)
            ? requiredDocumentsFunction
            : ""
        }
        pageData={pageData}
        tabValue={tabValue}
        setTabValue={setTabValue}
        filterBy={filterBy}
        setfilterBy={setfilterBy}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        handleSearch={handleSearch}
        setDateOne={setDateOne}
        setDateTwo={setDateTwo}
        perPage={perPage}
        setperPage={setperPage}
        page={page}
        tableData={tableData}
        accessor={accessor}
        setAccessor={setAccessor}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        downloadFullCsv={downloadFullCsv}
        setSubordinateId={setSubordinateId}
        srNO={true}
        handleTableRowColor={handleTableRowColor}
        tableRow={true}
      />

      {choModal && (
        <Modal onClose={() => setChoModal(false)}>
          <div className={classes.cho_modal}>
            <label>
              Acknowledgement Id / CHO Number
              <input
                value={ackId}
                onChange={(e) => setAckId(e.target.value.toLocaleUpperCase())}
                src="text"
              />
            </label>
            <PrimaryButton
              onClick={() => handlePrviewForm(ackId)}
              label={"Submit"}
            />
          </div>
        </Modal>
      )}

      {assignModal && (
        <Modal>
          <div className={classes.select_user_container}>
            <div className={classes.head_title}>
              <h2>
                <i>
                  <img src={user_blue_icon} />
                </i>
                Assign user
              </h2>
            </div>
            <label>Assign User</label>

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={potentialUser}
              size="small"
              getOptionLabel={(option) => option.username}
              onChange={(e, v) => setPotentialUserId(v)}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Select User" />
              )}
            />
            <div
              className={`d-flex align-items-center justify-content-start mt-4 ${classes.gap_20}`}
            >
              <PrimaryButton
                onClick={(e) => handleAssignUser(e)}
                disabled={!potentialUserId}
                label={"Assign"}
              />
              <SecondaryButton
                label={"Close"}
                onClick={() => setAssignModal(false)}
              />
            </div>
          </div>
        </Modal>
      )}

      {corpFormModal && (
        <Modal
          onClose={() => setCorpFormModal(false)}
          style={{ width: "90%", height: "90%", overflowY: "scroll" }}
        >
          <PreViewForm
            ref={componentRef}
            handleSubmitCorporateForm={true}
            setIsPreview={setCorpFormModal}
            corporatePreviewData={previewData}
          />
        </Modal>
      )}

      {requiredModal && (
        <RequiredDocumentModal
          requiredModal={requiredModal}
          setRequiredModal={setRequiredModal}
          handleFunction={handleNewCorpForm}
        />
      )}

      {isRoadMapModal && (
        <TimeLineRoadMapModal
          isModal={isRoadMapModal}
          setIsModal={setIsRoadMapModal}
          corporateData={roadMapCorpData}
          data={roadMapData}
          handlePrviewForm={handlePrviewForm}
        />
      )}
    </div>
  );
};

export default CorporateDashboard;
