import React, { useEffect, useState } from "react";
import classes from "./InvestmentDetail.module.css";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import InfoIcon from "../../../assets/info-blue-icon.svg";
import RedDotIcon from "../../../assets/TableDashboard/RedDot.svg";
import OrangeDotIcon from "../../../assets/TableDashboard/OrangeDot.svg";
import GreenDotIcon from "../../../assets/TableDashboard/GreenDot.svg";
import PieArcLabel from "../../../components/PieChart/PieChart";
import RangeInput from "../../../components/RangeInput/RangeInput";
import CompanyLogo from "../../../assets/HeaderAssets/HDFCLOGO2.svg";
import Modal from "../../../components/Modal/Modal";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import { forwardRef } from "react";
import { getCorporatePfm } from "../../CorponboardingForm/CorporateAction";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../Home/HomeSlice";

const InvestmentDetail = forwardRef(
  (
    { setIsProceed, setPreviewData, previewData, migration, corporateData },
    ref
  ) => {
    const [choice, setChoice] = useState(true);

    const dispatch = useDispatch();
    //  temp fund state
    const [fundA, setfundA] = useState("0");
    const [fundE, setfundE] = useState("25");
    const [fundC, setfundC] = useState("50");
    const [fundG, setfundG] = useState("25");
    const [investAmountInput, setInvestAmountInput] = useState(null);
    const [autoChoiceType, setAutoChoiceType] = useState("lc25");
    const [contributionChargeModal, setContributionChargeModal] =
      useState(false);
    const [skipNow, setSkipNow] = useState(false);
    const [changePreference, setChangePreference] = useState(true);
    const [pfmOptions, setPfmOptions] = useState([]);
    const [selectedPfm, setSelectedPfm] = useState("");

    // investment Amount suggestion
    const [investAmountSuggestion, setInvestAmountSuggestion] = useState([
      {
        label: "Rs 25,000",
        data: 25000,
      },
      {
        label: "Rs 50,000",
        data: 50000,
      },
      {
        label: "Rs 75,000",
        data: 75000,
      },
    ]);

    //  object of pie chart data
    const autoChoiceObj = [
      {
        choice_type: "Conservative (LC25)",
        value: "lc25",
        data: [
          {
            value: Number(fundE),
            label: "Equity (E)",
            color: "var(--app-pie-chart-1st)",
            icon: "E",
          },
          {
            value: Number(fundC),
            label: "Corporate Bonds (C)",
            color: "var(--app-pie-chart-2nd)",
            icon: "C",
          },
          {
            value: Number(fundG),
            label: "Government Securities (G)",
            color: "var(--app-pie-chart-3rd)",
            icon: "G",
          },
          {
            value: Number(fundA),
            label: "Alternate Allocation (A)",
            color: "var(--app-pie-chart-4th)",
            icon: "A",
          },
        ],
      },
      {
        choice_type: "Moderate (LC50)",
        value: "lc50",
        data: [
          {
            value: 30,
            label: "Equity (E)",
            color: "var(--app-pie-chart-1st)",
            icon: "E",
          },
          {
            value: 20,
            label: "Corporate Bonds (C)",
            color: "var(--app-pie-chart-2nd)",
            icon: "C",
          },
          {
            value: 10,
            label: "Government Securities (G)",
            color: "var(--app-pie-chart-3rd)",
            icon: "G",
          },
          {
            value: 10,
            label: "Alternate Allocation (A)",
            color: "var(--app-pie-chart-4th)",
            icon: "A",
          },
        ],
      },
      {
        choice_type: "Aggressive (LC75)",
        value: "lc75",
        data: [
          {
            value: 30,
            label: "Equity (E)",
            color: "var(--app-pie-chart-1st)",
            icon: "E",
          },
          {
            value: 20,
            label: "Corporate Bonds (C)",
            color: "var(--app-pie-chart-2nd)",
            icon: "C",
          },
          {
            value: 10,
            label: "Government Securities (G)",
            color: "var(--app-pie-chart-3rd)",
            icon: "G",
          },
          {
            value: 10,
            label: "Alternate Allocation (A)",
            color: "var(--app-pie-chart-4th)",
            icon: "A",
          },
        ],
      },
    ];

    // pie Chart object
    const pieChartObj = [
      {
        value: Number(fundE),
        label: "Equity (E)",
        color: "var(--app-pie-chart-1st)",
        icon: "E",
      },
      {
        value: Number(fundC),
        label: "Corporate Bonds (C)",
        color: "var(--app-pie-chart-2nd)",
        icon: "C",
      },
      {
        value: Number(fundG),
        label: "Government Securities (G)",
        color: "var(--app-pie-chart-3rd)",
        icon: "G",
      },
      {
        value: Number(fundA),
        label: "Alternate Allocation (A)",
        color: "var(--app-pie-chart-4th)",
        icon: "A",
      },
    ];

    useEffect(() => {
      setInvestAmountInput(Number(previewData?.subFormData?.investmentAmount));
      setChoice(
        previewData?.subFormData?.investmentOption === "Auto Choice"
          ? true
          : false
      );

      setfundA(
        previewData?.subFormData?.fundA ? previewData?.subFormData?.fundA : "0"
      );
      setfundC(
        previewData?.subFormData?.fundC ? previewData?.subFormData?.fundC : "25"
      );
      setfundE(
        previewData?.subFormData?.fundE ? previewData?.subFormData?.fundE : "50"
      );
      setfundG(
        previewData?.subFormData?.fundG ? previewData?.subFormData?.fundG : "25"
      );
      setAutoChoiceType(previewData?.subFormData?.autoChoiceLc);

      setSelectedPfm(previewData?.subFormData?.pfmSelection);

      // get pfm for corporate form
      getCorporatePfm().then((res) => {
        if (res.data.success === true) {
          return setPfmOptions(res.data.data.map((data) => data.name));
        }
      });
    }, []);

    // pie chart assets range
    const handleRangeValues = (accessor1, accessor2, accessor3, value) => {
      if (
        Number(accessor1) +
          Number(accessor2) +
          Number(accessor3) +
          Number(value) !==
        0
      ) {
        let maxValue = 100 - accessor1 - accessor2 - accessor3;
        return maxValue > value ? value : maxValue;
      } else {
        dispatch(
          setAlertMsg({
            msg: "Sum of all assets cannot be empty",
            alertType: "warning",
          })
        );
        return 10;
      }
    };

    const handleChangeRange = (e) => {
      let { name, value } = e?.target;
      switch (name) {
        case "setfundC":
          setfundC(handleRangeValues(fundA, fundE, fundG, value));
          break;

        case "setfundA":
          setfundA(handleRangeValues(fundC, fundE, fundG, value));
          break;

        case "setfundE":
          setfundE(handleRangeValues(fundA, fundC, fundG, value));
          break;

        case "setfundG":
          setfundG(handleRangeValues(fundA, fundE, fundC, value));
          break;

        default:
          break;
      }
    };

    useEffect(() => {
      if (investAmountInput > 30) {
        setIsProceed(false);
      } else {
        setIsProceed(true);
      }
    }, [investAmountInput, autoChoiceType]);

    useEffect(() => {
      let investMentobj = {};
      if (choice === true) {
        investMentobj = {
          autoChoiceLc: autoChoiceType,
        };
      } else {
        investMentobj = {
          fundE: fundE,
          fundA: fundA,
          fundC: fundC,
          fundG: fundG,
        };
      }

      let obj = {
        subFormData: {
          pfmSelection: selectedPfm,
          investmentAmount: `${investAmountInput}`,
          investmentOption: choice ? "Auto Choice" : "Active Choice",
          ...investMentobj,
        },
      };
      setPreviewData(obj);
    }, [
      investAmountInput,
      choice,
      fundA,
      fundC,
      fundE,
      fundG,
      autoChoiceType,
      selectedPfm,
    ]);

    const handleInvestmentInfo = () => {
      if (migration === true && changePreference === true) {
        return true;
      } else if (!migration) return true;
    };

    return (
      <div className={classes.investment_details}>
        {!migration && (
          <div className={classes.investment_container}>
            <div className={classes.investment_inputContainer}>
              <label>
                I wish to invest this amount
                <input
                  type="number"
                  placeholder="Minimum Rs 30"
                  value={investAmountInput}
                  min={30}
                  name="investmentAmount"
                  onInput={(e) => setInvestAmountInput(e.target.value)}
                />
              </label>
              <div>
                {investAmountSuggestion?.map((data, index) => {
                  return (
                    <SecondaryButton
                      key={index}
                      style={{
                        borderRadius: "1.5rem",
                        whiteSpace: "nowrap",
                        backgroundColor:
                          investAmountInput === data?.data &&
                          "var(--app-btn-background)",
                        color:
                          investAmountInput === data?.data &&
                          "var(--app-white)",
                      }}
                      label={data.label}
                      onClick={() => setInvestAmountInput(data?.data)}
                    />
                  );
                })}
              </div>
            </div>
            <div className={classes.approx_value}>
              <h5>Your Pension Corpus at age of 60 will be around</h5>
              <h4>28 lakh(approx.)</h4>
            </div>
          </div>
        )}

        {previewData?.corpFormData?.schemePreference !== "Subscriber" && (
          <div className={classes.investPlan_corporate}>
            <p>Investment Plan Selected by your corporate</p>
            <div className={classes.corporate_selected_container}>
              <div className={classes.pfm_info}>
                <div className={classes.corporate_logo}>
                  <img src={CompanyLogo} />
                </div>
                <div className={classes.corporate_info}>
                  <div>
                    <p>Pension Fund Manager</p>
                    <h6> HDFC Pension Management Co. Ltd.</h6>
                  </div>
                  <div>
                    <p>Scheme</p>
                    <h6> Aggressive</h6>
                  </div>
                  <div>
                    <p>
                      % return over 5 year
                      <img src={InfoIcon} />
                    </p>
                    <h6> 11%</h6>
                  </div>
                  <div>
                    <p>
                      No. of subscriber
                      <img src={InfoIcon} />
                    </p>
                    <h6>16 L</h6>
                  </div>
                  <div>
                    <p>
                      AUM
                      <img src={InfoIcon} />
                    </p>
                    <h6> 50.57K </h6>
                  </div>
                  <div>
                    <p>
                      Maturity Amount
                      <img src={InfoIcon} />
                    </p>
                    <h6> 28 L</h6>
                  </div>
                </div>
              </div>
              <div className={classes.asset_info}>
                <div>
                  <p>Asset Allocation</p>
                </div>
                <div>
                  <p>Equity</p>
                  <h6> 50%</h6>
                </div>
                <div>
                  <p>Corporate Bonds</p>
                  <h6> 15%</h6>
                </div>
                <div>
                  <p>Government Bonds</p>
                  <h6> 30%</h6>
                </div>
                <div>
                  <p>Alternative Bonds</p>
                  <h6> 0%</h6>
                </div>
              </div>
            </div>
          </div>
        )}

        {migration && (
          <div className={classes.change_preference}>
            <h5>Do you want to change your existing preference ?</h5>
            <div>
              <PrimaryButton
                onClick={() => setChangePreference(true)}
                label={"Yes"}
              />
              <SecondaryButton
                onClick={() => setChangePreference(false)}
                label={"No"}
              />
            </div>
          </div>
        )}
        {handleInvestmentInfo() &&
          previewData?.corpFormData?.schemePreference === "Subscriber" && (
            <>
              <div className={classes.invest_option}>
                <div className={classes.invest_option_container}>
                  <div className={classes.investment_options}>
                    <h5> Select your investment option</h5>
                    <div>
                      <label className={classes.radio_btn}>
                        <input
                          checked={choice === true}
                          onChange={(e) => setChoice(true)}
                          type="radio"
                          value={"Auto Choice"}
                          name="auto_choice"
                        />
                        Auto Choice
                      </label>
                      <label className={classes.radio_btn}>
                        <input
                          checked={choice === false}
                          onChange={(e) => setChoice(false)}
                          type="radio"
                          value={"Active Choice"}
                          name="auto_choice"
                        />
                        Active Choice
                      </label>
                    </div>
                  </div>
                  <div className={classes.pie_char_info}>
                    <img className={classes.info_icon} src={InfoIcon} />
                    <h5>This won't be editable later</h5>
                  </div>
                </div>
                <div>
                  {choice === true && (
                    <>
                      <div className={classes.equity_symbol}>
                        <h5>Select your of lifecycle fund for auto choice </h5>
                      </div>
                      <div className={classes.chart_container}>
                        {autoChoiceObj.map((chartData, key) => {
                          return (
                            <label key={key}>
                              <div>
                                <input
                                  type="radio"
                                  name="auto_choice_type"
                                  checked={autoChoiceType === chartData?.value}
                                  value={chartData?.choice_type}
                                  onChange={() =>
                                    setAutoChoiceType(chartData?.value)
                                  }
                                />
                                {chartData.choice_type}
                              </div>

                              <PieArcLabel
                                width={80}
                                key={key}
                                data={chartData.data}
                              />
                            </label>
                          );
                        })}
                      </div>
                    </>
                  )}
                  {choice !== true && (
                    <div className={classes.pieChart_container}>
                      <div className={classes.pieChart}>
                        <PieArcLabel data={pieChartObj} />
                      </div>
                      <div className={classes.slider_container}>
                        <RangeInput
                          title={"Equity (E)"}
                          range={"Cannot exceed 75%"}
                          value={fundE}
                          setValue={handleChangeRange}
                          max={75}
                          name={"setfundE"}
                          color={pieChartObj[0].color}
                        />
                        <RangeInput
                          title={"Corporate Bonds (C)"}
                          range={"max (100)"}
                          value={fundC}
                          setValue={handleChangeRange}
                          name={"setfundC"}
                          max={100}
                          color={pieChartObj[1].color}
                        />
                        <RangeInput
                          title={"Government Securities (G)"}
                          range={"max (100)"}
                          max={100}
                          value={fundG}
                          setValue={handleChangeRange}
                          name={"setfundG"}
                          color={pieChartObj[2].color}
                        />
                        <RangeInput
                          title={"Asset Allocation (A)"}
                          range={"Cannot exceed  5%"}
                          value={fundA}
                          setValue={handleChangeRange}
                          name={"setfundA"}
                          max={5}
                          color={pieChartObj[3].color}
                        />
                      </div>
                    </div>
                  )}
                  <div className={classes.equity_info}>
                    <p>
                      <img src={GreenDotIcon} /> Equity (E)
                    </p>
                    <p>
                      <img src={OrangeDotIcon} /> Corporate Bonds (C)
                    </p>
                    <p>
                      <img src={RedDotIcon} /> Government Securities (G)
                    </p>
                  </div>
                </div>
              </div>
              <div className={classes.pfmSelection}>
                <h5>Pick your Fund Manager</h5>
                <label>Select fund Manager</label>
                <select
                  value={selectedPfm}
                  onChange={(e) => setSelectedPfm(e.target.value)}
                >
                  {pfmOptions.map((option, index) => {
                    return <option key={index}> {option} </option>;
                  })}
                </select>
              </div>
            </>
          )}

        {contributionChargeModal && (
          <Modal>
            <div className={classes.contribution_container}>
              <h5> Contribution and Charge </h5>
              <div className={classes.contribution_detail}>
                <div>
                  <h5>One Time initial contribution</h5>
                  <h4>Rs. 50,000</h4>
                </div>
                <div>
                  <h5>Service charges</h5>
                  <h4>Rs. 700 </h4>
                </div>
                <div className={classes.total_contribution}>
                  <h5>Total Payable</h5>
                  <h4>Rs. 50,700</h4>
                </div>
              </div>
              <div className={classes.skip_info}>
                <label>
                  <input
                    checked={skipNow}
                    onChange={() => {
                      skipNow ? setSkipNow(false) : setSkipNow(true);
                    }}
                    type="checkbox"
                  />
                  Incase of skip now, this will be deducted from your initial
                  contribution
                </label>
              </div>
              <div className={classes.contribution_btn}>
                <PrimaryButton
                  disabled={skipNow}
                  label={"Pay Now at the end"}
                />
                <PrimaryButton disabled={!skipNow} label={"Skip for now"} />
                <SecondaryButton
                  onClick={() => setContributionChargeModal(false)}
                  label={"Cancel"}
                />
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
);

export default InvestmentDetail;
