import React, { useState } from "react";
import Classes from "./PEPForm.module.css";
import Modal from "../../../components/Modal/Modal";
import Dropdown from "../../../components/form/Dropdown";
import InputBox from "../../../components/form/InputBox";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import TextButton from "../../../components/Buttons/TextButton";

const PEPForm = () => {
  const OccupationArray = [
    {
      id: 1,
      label: "Head of State or Government",
    },
    {
      id: 2,
      label: "Member of a Political Party",
    },
    {
      id: 3,
      label: "Senior Government / military / Judiciary official",
    },
    {
      id: 4,
      label: "Senior Executive of State/Central Corporations",
    },
    {
      id: 5,
      label: "No",
    },
  ];

  const [formDetails, setFormDetails] = useState({
    relativeRelation: "",
    occupationCategory: "",
    isCloseRelative: true,
  });

  const handleChange = (e) => {};

  const handleOccupationChange = (id) => {
    setFormDetails((data) => ({
      ...data,
      occupationCategory: id,
    }));
  };

  return (
    <Modal>
      <div className={Classes.pepform_container}>
        <div className={Classes.pep_title_container}>
          <h4>PEP Questionnaire </h4>
          <h6>
            In application, you have identified yourself as
            <b> Politically exposed person</b>
          </h6>
          <h6>Kindly fill the details below:</h6>
        </div>
        <div className={Classes.pepForm_input_container}>
          <h5 className={Classes.label}>
            1. Do you belong to any of the following occupational categories?
          </h5>
          <div className={Classes.occupation_categories}>
            {OccupationArray.map((Occupation, index) => {
              return (
                <TextButton
                  onClick={() => handleOccupationChange(Occupation.id)}
                  style={{
                    borderRadius: ".5rem",
                    background:
                      formDetails.occupationCategory === Occupation.id
                        ? "var(--app-light-blue-color)"
                        : "",
                  }}
                  key={index}
                  label={Occupation.label}
                />
              );
            })}
          </div>
        </div>

        <div className={Classes.pepForm_input_container}>
          <h5 className={Classes.label}>
            2. Do you have any close relative in any of the above occupational
            categories?
          </h5>
          <label>
            <input
              checked={formDetails.isCloseRelative}
              onClick={() =>
                setFormDetails((data) => ({
                  ...data,
                  isCloseRelative: true,
                }))
              }
              type="radio"
            />
            Yes
          </label>
          <label>
            <input
              onClick={() =>
                setFormDetails((data) => ({
                  ...data,
                  isCloseRelative: false,
                }))
              }
              checked={!formDetails.isCloseRelative}
              type="radio"
            />
            No
          </label>
          {formDetails.isCloseRelative && (
            <div className={Classes.dropdown_container}>
              <Dropdown
                label={"Relationship with the Relatives"}
                options={OccupationArray}
                handleOnchange={handleChange}
                value={formDetails.relativeRelation}
              />
            </div>
          )}
        </div>

        {formDetails.isCloseRelative ||
          (formDetails.occupationCategory !== 5 && (
            <div className={Classes.pepForm_input_container}>
              <h5 className={Classes.label}>
                3. If either (1) or (2) is Yes, please share more details
              </h5>
              <div className={Classes.shear_more_details}>
                <div>
                  <InputBox
                    placeholder={"write nature of the work"}
                    label={"Nature of work"}
                    handleOnchange={handleChange}
                  />
                </div>
                <div>
                  <InputBox
                    placeholder={"write nature of the work"}
                    label={"Name of the organization (affiliated with)"}
                    handleOnchange={handleChange}
                  />
                </div>
                <div>
                  <InputBox
                    placeholder={"write workplace area"}
                    label={"Workplace area"}
                    handleOnchange={handleChange}
                  />
                </div>
                <div>
                  <InputBox label={"In The Above Profile Since"} />
                </div>
              </div>
            </div>
          ))}

        {!formDetails.isCloseRelative &&
          formDetails.occupationCategory === 5 && (
            <div className={Classes.pepForm_input_container}>
              <h5 className={Classes.label}>
                3. In case the answer to (1) and (2) is No, then please share
                the reason for identifying yourself as a PEP in the Proposal
                for.
              </h5>
              <label>
                <input type="radio" />
                Did Not Know The Full Details Of PEP
              </label>
              <label>
                <input type="radio" />
                By Mistake
              </label>
            </div>
          )}

        <label className={Classes.final_confirmation}>
          <input type="checkbox" />
          Finally I Confirm I Belong To PEP Category As Per Present Details
          Above
        </label>

        <div className={Classes.form_btn}>
          <PrimaryButton label={"Save"} />
          <SecondaryButton label={"Cancel"} />
        </div>
      </div>
    </Modal>
  );
};

export default PEPForm;
