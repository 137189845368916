import usersReducer from "./App/modules/Users/UsersSlice";
import {
  grouplistReducer,
  rolelistReducer,
  userslistReducer,
  currentUserReducer,
  userTabValueReducer,
  adminDataListReducer,
  userDetailReducer,
  NewUserReducer,
  groupNameReducer,
  usersOfRoleReducer,
  usersInGroupReducer,
} from "./App/modules/AdminManagement/AdminSlice";
import {
  loginUserDetailsReducer,
  uiFunctionObjReducer,
  alertDetailsReducer,
  arrayOfAlertDetailsReducer,
  IsLoggedInReducer,
} from "./App/modules/Home/HomeSlice";
import {
  RmTaskStateReducer,
  CorpIdReducer,
} from "./App/modules/CorponboardingForm/CorporateSlice";
import {
  EntityRowIdReducer,
  CheckerIDReducer,
} from "./App/modules/AdminManage/MakerCheckerSlice";
// Configure our store combining all the state reducers.

import { opsSRFormDataReducer } from "./App/modules/OpsManagement/OPSSlice";
import { configureStore } from "@reduxjs/toolkit";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";

export const store = configureStore({
  reducer: {
    // is user login
    isLoggedIn: IsLoggedInReducer,

    users: usersReducer,
    userslist: userslistReducer,
    groupslist: grouplistReducer,
    roleslist: rolelistReducer,
    currentuser: currentUserReducer,
    currentAdminTab: userTabValueReducer,
    adminDatalist: adminDataListReducer,
    currentUserDetail: userDetailReducer,
    isNewUser: NewUserReducer,
    uiFuctionObject: uiFunctionObjReducer,
    loginUserDetails: loginUserDetailsReducer,
    currentGroupName: groupNameReducer,
    usersListInRoles: usersOfRoleReducer,
    usersListInGroup: usersInGroupReducer,
    // corporateRmState
    rmTaskState: RmTaskStateReducer,
    corpId: CorpIdReducer,
    rowID: EntityRowIdReducer,
    checkerid: CheckerIDReducer,

    // alert msg
    alertMsg: alertDetailsReducer,
    arrayOfAlerts: arrayOfAlertDetailsReducer,

    // ops
    srUpdateFormData: opsSRFormDataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(createStateSyncMiddleware({})),
});

initMessageListener(store);
