export const formObj = [
  {
    title: "SubscriberForm",
    formArr: [
      {
        label: "Full Name",
        name: "full_name",
        placeholder: "Enter your Full Name",
        description: "Write name",
        tag: "input",
        type: "text",
      },
      {
        label: "Higher Education Qualification",
        name: "education",
        placeholder: "Select your Higher Education",
        description: "",
        tag: "select",
        options: [
        ],
      },
      {
        label: "Are you Politically Exposed Person",
        name: "pol_exposed",
        placeholder: "Select",
        description: "",
        tag: "select",
        options: [
        ],
      },
      {
        label: "Martial Status",
        name: "martial_status",
        placeholder: "Select your Martial Status",
        description: "",
        tag: "select",
        options: [
        ],
      },
    ],
  },
 {
  title :"Employment Details",
  formArr :[
    {
      label: "Employee Code",
      name: "emp_code",
      placeholder: "Enter your Employee Code",
      description: "write number as per official records",
      tag: "input",
      type: "text",
    },
    {
      label: "Income Range (per annum)",
      name: "income_range",
      placeholder: "Select Income Range",
      description: "",
      tag: "select",
      options: [
      ],
    },
    {
      label: "Date of Joining",
      name: "joining_date",
      placeholder: "Enter your Employee Code",
      description: "write number as per official records",
      tag: "input",
      type: "date",
    },
  ]

 }
];
