import React from "react";
import Classes from "./RequiredDocumentModal.module.css";
import Modal from "../../../components/Modal/Modal";
import { useState } from "react";
import fileIcon from "../../../assets/file_icon.svg";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import { useNavigate } from "react-router";

const RequiredDocumentModal = ({
  requiredModal,
  setRequiredModal,
  handleFunction,
}) => {
  const navigate = useNavigate();
  const [contentPart, setContentPart] = useState([
    {
      name: "PAN Card",
      text: "Copy Of Company PAN Card",
    },
    {
      name: "GST Certificate",
      text: "Of The Company/Declaration For Non-GST Compliance",
    },
    {
      name: "Copy Of Certificate Of Incorporation",
      text: "Of The Company/Declaration For Non-GST Compliance",
    },
    {
      name: "CHO1",
      text: "Duly Filled Form",
    },
    {
      name: "NPS Implementation",
      text: "Letter",
    },
    {
      name: "Existing Board Resolution",
      text: "Stating The Name Of Authorized Signatories Signing - CHO1 Form/ Power Of Attorney",
    },
    {
      name: "Cancelled Cheque/Bank Statement/Declaration",
      text: "From The Corporate",
    },
    {
      name: "SLA",
      text: "Of The Company",
    },
    {
      name: "Self-Attested KYC Of Authorized Signatory",
      text: "(Driving Licence/PAN Card/Aadhar/Passport/Employee ID Card)",
    },
  ]);
  return (
    requiredModal && (
      <Modal style={{ borderRadius: "1.2rem" }}>
        <div className={Classes.requiredDocumentModal}>
          <div className={Classes.head_part}>
            <h2>
              The following documents are <span>required</span>
            </h2>
            <p>
              You will required following documents to register new corporate.
              Kindly keep these documents with you.
            </p>
          </div>
          <div className={Classes.body_part}>
            <div className={Classes.grid_box}>
              {contentPart &&
                contentPart.map((data, index) => (
                  <div className={Classes.box} key={index}>
                    <div className={Classes.box_aligning}>
                      <div className={Classes.icon_area}>
                        <img src={fileIcon} />
                      </div>
                      <div className={Classes.content_area}>
                        <h6>{data.name}</h6>
                        <p>{data.text}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className={Classes.button_area}>
            <div className="btn-group mr-4">
              <PrimaryButton
                label={"Proceed"}
                onClick={() => handleFunction()}
              />
            </div>
            <div className="btn-group">
              <SecondaryButton
                label={"Close"}
                onClick={() => setRequiredModal(false)}
              />
            </div>
          </div>
        </div>
      </Modal>
    )
  );
};

export default RequiredDocumentModal;
