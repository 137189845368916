import React, { useEffect } from "react";
import Classes from "./SwitchToggle.module.css";
import { useState } from "react";
const SwitchToggle = ({ status, bgColor, otherFunction,offColor }) => {
  const [active, setActive] = useState(false);
  useEffect(()=>{
setActive(status);
  },[status])

  return (
    <button
      type="button"
      className={`
                btn ${Classes.btn_sm + " " + Classes.btn_toggle}  
                ${active ? Classes.active : ""}
              `}
      style={{
        backgroundColor: active ? bgColor :offColor ||  "#bdc1c8",
      }}
      onClick={() => {
        setActive(active ? false : true);
        otherFunction();
      }}
      data-toggle="button"
      aria-pressed="false"
      autoComplete="off"
    >
      <div className={Classes.handle}></div>
    </button>
  );
};

export default SwitchToggle;
