import React from "react";
import {
  BASE_URL,
  ADMIN_URL,
  USER_URL,
  DASHBOARD_URL,
  REPORT_URL,
  AUTHMANAGEMENT_URL,
  MISREPORT_URL,
  CORPORATE_ONBOARD_FORM_URL,
  SCRUTINY_FORM_URL,
  CHO_FORM_URL,
  CORPORATEDASHBOARD_URL,
  SIGN_IN_URL,
  MASTERMANAGEMENT_URL,
  MAKER_CHECKER_TASK_URL,
  MAKER_CHANGER_MASTER,
  REVIEW_CORPORATE_MASTER,
  SUBSCRIBER_FORM,
  PASSWORD_RESET,
  SUBSCRIBER_REGISTRATION_HOME,
  SUBSCRIBER_MIGRATION,
  ROAD_MAP_URL,
  USER_MANAGEMENT_DASHBOARD_URL,
  USER_MANAGEMENT_CREATE_USER_URL,
  USER_MANAGEMENT_EDIT_USER_URL,
  GroupManagement_URL,
  RoleManagement_URL,
  FUNCTIONALITY_MANAGEMENT_URL,
  SUBSCRIBER_URL,
  DIGILOCKER_STATUS,
  ServiceRequest_URL,
  CORP_SUBSCRIBERS,
  SUBSCRIBER_SCRUTINY_FORM_URL,
  ServiceRequest_Update_Url,
  CORPORATE_CHARGE_SCRUITNY_URL,
  CORPORATE_CHARGE_UPDATE_URL,
  CORPORATE_ONBOARDING_URL,
  Pran_Journey_URL,
  PRAN_OSV_DASHBOARD_URL,
  RMDashboard_URL,
  CORP_CONTRIBUTION_DASHBOARD_URL,
  CORP_CONTRIBUTION_URL,
} from "./Urls";
import AdminManagement from "../modules/AdminManagement/AdminManagement";
import Login from "../modules/Home/Login";
import { Route } from "react-router";
import { Navigate, Routes } from "react-router-dom/dist";
import Dashboard from "../modules/Dashboard/Dashboard";
import { useSelector } from "react-redux";
import MISReport from "../modules/MISReport/MISReport";
import CorporateOnboardProcess from "../modules/CorponboardingForm/RMJourney/CorporateOnboardProcess";
import UpdateCorporateApplication from "../modules/CorponboardingForm/ProdJourney/UpdateCorporateApplication";
import CorporateDashboard from "../modules/CorponboardingForm/CorporateDashboard";
import Users from "../modules/Users/Users";
import MasterManagement from "../modules/AdminManage/MasterManagement/MasterManagement";
import MakerChecker from "../modules/AdminManage/MakerCheckerTask/MakerChecker";
import MakerCheckerMaster from "../modules/AdminManage/MasterManagement/MakerCheckerMaster/MakerCheckerMaster";
import ReviewCorporateMaster from "../modules/AdminManage/MakerCheckerTask/ReviewMasters/ReviewCorporateMaster/ReviewCorporateMaster";
import FunctionalityManagement from "../modules/AdminManage/FunctionalityManagement/FunctionalityManagement";
import SubscriberRegistration from "../modules/SubscriberOnboarding/SubscriberRegistration/SubscriberRegistration";
import ResetPassword from "../modules/Auth/components/ResetPassword/ResetPassword";
import SubscriberMigration from "../modules/SubscriberOnboarding/SubscriberMigration/SubscriberMigration";
import CorporateScrutiny from "../modules/CorponboardingForm/ProdJourney/CorporateScrutinyFolder/ScrutinyMain/CorporateScrutiny";
import Utils from "../Utils";
import WorkFlowRoadMap from "../components/WorkFlowRoadMap/WorkFlowRoadMap";
import UserManagementDashboard from "../modules/AdminManage/UserManagement/UserManagementDashboard/UserManagementDashboard";
import CreateUser from "../modules/AdminManage/UserManagement/CreateUser/CreateUser";
import EditUser from "../modules/AdminManage/UserManagement/EditUser/EditUser";
import AuthManagement from "../modules/AdminManage/AuthManagement/AuthManagement";
import GroupManagement from "../modules/AdminManage/GroupManagement/GroupManagement";
import RoleManagement from "../modules/AdminManage/RoleManagement/RoleManagement";
import Consts from "../Consts";
import CorporateSubscribersDashboard from "../modules/OpsManagement/CorporateSubscribers/CorporateSubscribersDashboard/CorporateSubscribersDashboard";
import SubscriberScrutiny from "../modules/OpsManagement/Scrutiny/SubscriberScrutiny";
import OPSServiceRequest from "../modules/OpsManagement/OPSServiceRequest/OPSServiceRequest";
import ContributionUploadDocument from "../modules/Contribution/ContributionUploadDocument/ContributionUploadDocument";
import ContributionDashboard from "../modules/Contribution/ContributionDashboard/ContributionDashboard";
import SubscriberCallApiParams from "../modules/SubscriberOnboarding/SubscriberRegistration/SubscriberDigiLocker/SubscriberCallApiParams";
import UpdateServiceRequest from "../modules/OpsManagement/OPSServiceRequest/UpdateServiceRequest";
import ChargeScruitny from "../modules/CorponboardingForm/ProdJourney/ChargeScruitny/ChargeScruitny";
import SubscriberForm from "../modules/SubscriberFlow/SubscriberForm/SubscriberForm";
import InvestmentModal from "../modules/SubscriberOnboarding/InvestmentDetailModal/InvestmentModal";
import SubscriberPrevForm from "../modules/SubscriberOnboarding/SubscriberPrevForm/SubscriberPrevForm";
import PEPForm from "../modules/SubscriberOnboarding/PEPForm/PEPForm";
import SubscriberDashboard from "../modules/SubscriberFlow/SubscriberDashboard/SubscriberDashboard";
import OsvListing from "../modules/SubscriberFlow/OSV/OsvListing/OsvListing";
import OSV from "../modules/SubscriberFlow/OSV/OSV";
import PranJourneyDashboard from "../modules/SubscriberFlow/PranJourneyDashboard/PranJourneyDashboard";
import CsvUploadModal from "../modules/SubscriberFlow/CsvUploadModal/CsvUploadModal";
import ApplicationVerification from "../modules/SubscriberFlow/ApplicationVerification/ApplicationVerification";
import ApplicationModal from "../modules/SubscriberFlow/ApplicationModal/ApplicationModal";
import ScrutinyForm from "../components/ScrutinyForm/ScrutinyForm";
import RMDashboard from "../modules/RMFlow/RMDashboard";
import UpdateApplication from "../modules/SubscriberFlow/UpdateApplication/UpdateApplication";

const Router = () => {
  const { loginUserDetails, isLoggedIn } = useSelector((state) => state);
  const internalGrpRoleObj = Consts.INTERNAL_GRP_ROLE_IDS;
  const {
    CORP_FORM_FILL_UP,
    CORP_SCRUITNY,
    CORP_CHO_UPDATE,
    CORP_PROFILE_UPDATE,
  } = Consts.FUNCTIONALITY_AREAS_ID;
  const userTypeArr = [
    CORP_FORM_FILL_UP,
    CORP_SCRUITNY,
    CORP_CHO_UPDATE,
    CORP_PROFILE_UPDATE,
  ];

  // sidebar routes
  const sidebarRoutes = [
    {
      index: true,
      element: <Dashboard />,
    },
    {
      path: DASHBOARD_URL,
      element: <Dashboard />,
    },
    {
      path: REPORT_URL,
      element: <MISReport />,
    },

    {
      path: ADMIN_URL,
      element: Utils.isAdmin(loginUserDetails) ? (
        <AdminManagement />
      ) : // <div>Loading...</div>
      null,
    },
  ];

  // admin management routes
  const adminManagementRoutes = [
    {
      path: USER_MANAGEMENT_DASHBOARD_URL,
      element: Utils.isAdmin(loginUserDetails) ? (
        <UserManagementDashboard />
      ) : null,
    },
    {
      path: AUTHMANAGEMENT_URL,
      element: Utils.isAdmin(loginUserDetails) ? <AuthManagement /> : null,
    },
    {
      path: MASTERMANAGEMENT_URL,
      element: Utils.isAdmin(loginUserDetails) ? <MasterManagement /> : null,
    },
    {
      path: MISREPORT_URL,
      element: Utils.isAdmin(loginUserDetails) ? <MISReport /> : null,
    },
  ];

  // corporate flow routes
  const corpOnboardRoutes = [
    {
      path: CORPORATE_ONBOARD_FORM_URL,
      element: Utils.hasCorporateFormFillUp(loginUserDetails) ? (
        <CorporateOnboardProcess />
      ) : null,
    },

    {
      path: SCRUTINY_FORM_URL,
      element: Utils.hasCorporateScrutiny(loginUserDetails) ? (
        <CorporateScrutiny />
      ) : null,
      // <Navigate to={`/${BASE_URL}/${DASHBOARD_URL}`} replace />
    },
    {
      path: CHO_FORM_URL,
      element: Utils.hasCorporateCho(loginUserDetails) ? (
        <UpdateCorporateApplication />
      ) : null,
      // <Navigate to={`/${BASE_URL}/${DASHBOARD_URL}`} replace />
    },
    {
      path: ROAD_MAP_URL,
      element: Utils.hasCorporateCho(loginUserDetails) ? (
        <WorkFlowRoadMap />
      ) : (
        <Navigate to={`/${BASE_URL}/${DASHBOARD_URL}`} replace />
      ),
    },
    {
      path: CORPORATE_CHARGE_SCRUITNY_URL,
      element: Utils.hasChargeApproval(loginUserDetails) ? (
        <ChargeScruitny screenType={Consts.SCREEN_TYPE.VIEW} />
      ) : null,
      // <Navigate to={`/${BASE_URL}/${DASHBOARD_URL}`} replace />
    },
    {
      path: CORPORATE_CHARGE_UPDATE_URL,
      element: Utils.hasCorporateFormFillUp(loginUserDetails) ? (
        <ChargeScruitny screenType={Consts.SCREEN_TYPE.EDIT} />
      ) : null,
      // <Navigate to={`/${BASE_URL}/${DASHBOARD_URL}`} replace />
    },
  ];

  // dashboard routes
  const dashboardRoutes = [
    {
      path: CORPORATEDASHBOARD_URL,
      element: <CorporateDashboard />,
    },
    {
      path: MASTERMANAGEMENT_URL,
      element: Utils.checkFunctionalAreas(userTypeArr, loginUserDetails) ? (
        <MasterManagement />
      ) : (
        <Navigate to={`/${BASE_URL}/${DASHBOARD_URL}`} replace />
      ),
    },
    {
      path: MAKER_CHECKER_TASK_URL,
      element: Utils.hasMakerCheckerAccess(loginUserDetails) ? (
        <MakerChecker />
      ) : (
        <Navigate to={`/${BASE_URL}/${DASHBOARD_URL}`} replace />
      ),
    },
  ];

  // authorization management routes
  const authRoutes = [
    {
      path: FUNCTIONALITY_MANAGEMENT_URL,
      element: Utils.isAdmin(loginUserDetails) ? (
        <FunctionalityManagement />
      ) : null,
    },
    {
      path: GroupManagement_URL,
      element: Utils.isAdmin(loginUserDetails) ? <GroupManagement /> : null,
    },
    {
      path: RoleManagement_URL,
      element: Utils.isAdmin(loginUserDetails) ? <RoleManagement /> : null,
    },
  ];

  // master management routes
  const masterManagementRoutes = [
    {
      path: MAKER_CHANGER_MASTER,
      element: Utils.isAdmin(loginUserDetails) ? <MakerCheckerMaster /> : null,
    },
    {
      path: REVIEW_CORPORATE_MASTER,
      element: Utils.isAdmin(loginUserDetails) ? (
        <ReviewCorporateMaster />
      ) : null,
    },
    {
      path: MAKER_CHECKER_TASK_URL,
      element: Utils.isAdmin(loginUserDetails) ? <MakerChecker /> : null,
    },
  ];

  // user management routes
  const userManagementRoutes = [
    {
      path: USER_MANAGEMENT_CREATE_USER_URL,
      element: Utils.isAdmin(loginUserDetails) ? <CreateUser /> : null,
    },
    {
      path: USER_MANAGEMENT_EDIT_USER_URL,
      element: Utils.isAdmin(loginUserDetails) ? <EditUser /> : null,
    },
  ];

  // subscriber routes
  const subscriberRoutes = [
    {
      path: SUBSCRIBER_FORM,
      element: <SubscriberRegistration />,
    },
    {
      path: SUBSCRIBER_MIGRATION,
      element: <SubscriberMigration />,
    },
  ];

  // subscriber new  routes
  const subscriberRoutesNew = [
    {
      path: "subscriber",
      element: <SubscriberForm />,
    },
    {
      path: SUBSCRIBER_MIGRATION,
      element: <SubscriberMigration />,
    },
  ];

  const opsRoutes = [
    {
      path: CORP_SUBSCRIBERS,
      element: <CorporateSubscribersDashboard />,
    },
    {
      path: SUBSCRIBER_SCRUTINY_FORM_URL,
      element: <SubscriberScrutiny />,
    },
    {
      path: ServiceRequest_URL,
      element: <OPSServiceRequest />,
    },
    {
      path: ServiceRequest_Update_Url,
      element: <UpdateServiceRequest />,
    },
  ];

  return (
    <>
      <Routes>
        <Route
          exact
          path="*"
          element={
            isLoggedIn ? (
              <Navigate to={`/${BASE_URL}/${DASHBOARD_URL}`} replace />
            ) : (
              <Login />
            )
          }
        />

        <Route
          path={`/${BASE_URL}/${DASHBOARD_URL}`}
          element={
            isLoggedIn ? (
              <Dashboard />
            ) : (
              <Navigate to={`${BASE_URL}/${SIGN_IN_URL}`} />
            )
          }
        />

        <Route path={BASE_URL}>
          {/*  user profile  */}
          <Route path={USER_URL} element={<Users />} />

          {/* password reset */}
          <Route path={PASSWORD_RESET} element={<ResetPassword />} />

          {/* investment modal */}
          <Route path={"investmentModal"} element={<InvestmentModal />} />

          {/* investment modal */}
          <Route path={"previewForm"} element={<SubscriberPrevForm />} />

          {/* PEP Form */}
          <Route path={"PEPForm"} element={<PEPForm />} />

          {/* subscriber dashboard */}
          <Route
            path={"SubscriberDashboard"}
            element={<SubscriberDashboard />}
          />

          {/* subscriber dashboard */}
          <Route path={"ScrutinyForm"} element={<ScrutinyForm />} />

          {/* rm dashboard */}
          <Route path={RMDashboard_URL} element={<RMDashboard />} />

          {/*  OSV */}
          <Route
            path={`${RMDashboard_URL}/${Pran_Journey_URL}`}
            element={<OsvListing />}
          />

          {/*  OSV listing */}
          <Route
            path={`${RMDashboard_URL}/${Pran_Journey_URL}/${PRAN_OSV_DASHBOARD_URL}`}
            element={<OSV />}
          />

          {/* Sidebar Routes */}
          {sidebarRoutes.map((url, key) => {
            return (
              <Route
                path={url.path}
                element={
                  isLoggedIn ? (
                    url.element
                  ) : (
                    <Navigate to={`/${BASE_URL}/${SIGN_IN_URL}`} />
                  )
                }
                key={key}
                index={url.index}
              />
            );
          })}

          {/* Admin Management Routes */}
          {adminManagementRoutes.map((url, key) => {
            return (
              <Route
                path={ADMIN_URL + `/${url.path}`}
                element={
                  isLoggedIn ? (
                    url.element
                  ) : (
                    <Navigate to={`/${BASE_URL}/${SIGN_IN_URL}`} />
                  )
                }
                key={key}
                index={url.index}
              />
            );
          })}

          {/* authorization management routes */}
          {authRoutes.map((url, key) => {
            return (
              <Route
                path={`${ADMIN_URL}/${AUTHMANAGEMENT_URL}/${url.path}`}
                element={
                  isLoggedIn ? (
                    url.element
                  ) : (
                    <Navigate to={`/${BASE_URL}/${SIGN_IN_URL}`} />
                  )
                }
                key={key}
                index={url.index}
              />
            );
          })}

          {/* master management routes */}
          {masterManagementRoutes.map((url, key) => {
            return (
              <Route
                path={`${ADMIN_URL}/${MASTERMANAGEMENT_URL}/${url.path}`}
                element={
                  isLoggedIn ? (
                    url.element
                  ) : (
                    <Navigate to={`/${BASE_URL}/${SIGN_IN_URL}`} />
                  )
                }
                key={key}
                index={url.index}
              />
            );
          })}

          {/* user management routes */}
          {userManagementRoutes.map((url, key) => {
            return (
              <Route
                path={
                  `${ADMIN_URL}/${USER_MANAGEMENT_DASHBOARD_URL}` +
                  `/${url.path}`
                }
                element={
                  isLoggedIn ? (
                    url.element
                  ) : (
                    <Navigate to={`/${BASE_URL}/${SIGN_IN_URL}`} />
                  )
                }
                key={key}
                index={url.index}
              />
            );
          })}

          {/*  corporate flow routes */}
          {corpOnboardRoutes.map((url, key) => {
            return (
              <Route
                path={`${DASHBOARD_URL}/${CORPORATEDASHBOARD_URL}/${url.path}`}
                element={
                  isLoggedIn ? (
                    url.element
                  ) : (
                    <Navigate to={`/${BASE_URL}/${SIGN_IN_URL}`} />
                  )
                }
                key={key}
                index={url.index}
              />
            );
          })}

          {/* dashboard routes */}
          {dashboardRoutes.map((url, key) => {
            return (
              <Route
                path={`${DASHBOARD_URL}/${url.path}`}
                element={
                  isLoggedIn ? (
                    url.element
                  ) : (
                    <Navigate to={`/${BASE_URL}/${SIGN_IN_URL}`} />
                  )
                }
                key={key}
                index={url.index}
              />
            );
          })}

          {/* subscriber routes */}
          {subscriberRoutes.map((url, key) => {
            return (
              <Route
                path={`${DASHBOARD_URL}/${url.path}`}
                element={url.element}
                key={key}
                index={url.index}
              />
            );
          })}

          {/* subscriber new routes */}
          {subscriberRoutesNew.map((url, key) => {
            return (
              <Route
                path={`${DASHBOARD_URL}/${url.path}`}
                element={url.element}
                key={key}
                index={url.index}
              />
            );
          })}
        </Route>

        {Utils.isAdmin(loginUserDetails) && (
          <Route
            exact
            path={`${BASE_URL}/${ADMIN_URL}/${USER_MANAGEMENT_DASHBOARD_URL}/${USER_MANAGEMENT_EDIT_USER_URL}`}
            element={
              isLoggedIn ? (
                <EditUser />
              ) : (
                <Navigate to={`${BASE_URL}/${SIGN_IN_URL}`} />
              )
            }
          />
        )}

        {/* contribution Urls */}
        <Route
          path={`${BASE_URL}/${DASHBOARD_URL}/${CORP_CONTRIBUTION_DASHBOARD_URL}`}
          element={
            isLoggedIn ? (
              Utils.isCustomTypeUser(loginUserDetails, [
                loginUserDetails?.internalGroupRoles[0].id,
              ]) ? (
                <ContributionDashboard />
              ) : null
            ) : (
              <Navigate to={`${BASE_URL}/${SIGN_IN_URL}`} />
            )
          }
        />

        {/* contribution Urls */}
        <Route
          path={`${BASE_URL}/${DASHBOARD_URL}/${CORP_CONTRIBUTION_URL}`}
          element={
            isLoggedIn ? (
              Utils.isCustomTypeUserEndsWith(
                loginUserDetails,
                Consts.INTERNAL_GRP_ROLE_NAME_SUFFIX.NO.name
              ) ? (
                <ContributionUploadDocument />
              ) : null
            ) : (
              <Navigate to={`${BASE_URL}/${SIGN_IN_URL}`} />
            )
          }
        />

        {/*  api params */}
        <Route
          path={`${BASE_URL}/${SUBSCRIBER_URL}/${DIGILOCKER_STATUS}`}
          element={<SubscriberCallApiParams />}
        />

        {/*  ops */}

        {/*  application Modal */}
        <Route
          path={`${BASE_URL}/application-modal`}
          element={<ApplicationModal />}
        />

        {/*  verify-application */}
        <Route
          path={`${BASE_URL}/verify-application`}
          element={<ApplicationVerification />}
        />

        {/*  CSV upload Modal */}
        <Route
          path={`${BASE_URL}/csv-upload-modal`}
          element={<CsvUploadModal />}
        />

        {/*  PRAN journey */}
        <Route
          path={`${BASE_URL}/no-pran-journey`}
          element={<PranJourneyDashboard />}
        />

        {/*  update-application */}
        <Route
          path={`${BASE_URL}/update-application`}
          element={<UpdateApplication />}
        />

        {opsRoutes.map((url, key) => {
          return (
            <Route
              path={`${BASE_URL}/${DASHBOARD_URL}/${url.path}`}
              element={url.element}
              key={key}
              index={url.index}
            />
          );
        })}
      </Routes>
    </>
  );
};

export default Router;
