import React from "react";
import classes from "./OsvListing.module.css";
import GoBackButton from "../../../../components/Buttons/GoBackButton";
import SearchField from "../../../../components/SearchField/SearchField";
import buildingIcon from "../../../../assets/building-icon.svg";
import { useNavigate } from "react-router";
import {
  BASE_URL,
  PRAN_OSV_DASHBOARD_URL,
  Pran_Journey_URL,
} from "../../../../Routes/Urls";

const OsvListing = () => {
  const navigate = useNavigate();
  const sampleData = [
    { name: "Genpact", cboNumber: "1675545655", cases: 2 },
    { name: "Genpact", cboNumber: "1675545655", cases: 2 },
    { name: "Genpact", cboNumber: "1675545655", cases: 2 },
    { name: "Genpact", cboNumber: "1675545655", cases: 2 },
    { name: "Genpact", cboNumber: "1675545655", cases: 2 },
    { name: "Genpact", cboNumber: "1675545655", cases: 2 },
    { name: "Genpact", cboNumber: "1675545655", cases: 2 },
    { name: "Genpact", cboNumber: "1675545655", cases: 2 },
  ];

  const handleNavigate = () => {
    navigate(`${PRAN_OSV_DASHBOARD_URL}`);
  };
  return (
    <div className={classes.mainContainer}>
      <div className={classes.wrapper}>
        <GoBackButton />
        <div className={classes.titleContainer}>
          <div className={classes.titleText}>
            <span className={classes.headingText}>PRAN Journey</span>
            <span className={classes.subTitleText}>
              sub title text text sample test sample
            </span>
          </div>
          <div>
            <SearchField placeholder={"Search Name"} />
          </div>
        </div>
        <div className={classes.cardsWrapper}>
          {sampleData.map((item) => {
            return (
              <div className={classes.companyCardWrapper}>
                <div onClick={handleNavigate} className={classes.nameWrapper}>
                  <span>
                    <img src={buildingIcon} alt="icon" />
                  </span>
                  <span className={classes.nameText}>{item.name}</span>
                </div>
                <div className={classes.choNumber}>
                  CHO/CBO Number : {item.cboNumber}
                </div>
                <div className={classes.casesContainer}>
                  {item.cases} cases added
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OsvListing;
