import React, { useEffect, useState } from "react";
import Classes from "./NomineeBox.module.css";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import Utils from "../../../Utils";
import RangeInputWithoutMaxLimit from "../../../components/RangeInputWithoutMaxLimit/RangeInputWithoutMaxLimit";

const NomineeDetails = ({ setInputData, inputData }) => {
  const [nomineeCount, setNomineeCount] = useState(1);
  const [nomineeData, setNomineeData] = useState({});
  const [nomineeForm, setNomineeForm] = useState([
    [
      {
        tag: "input",
        type: "text",
        name: "nomineeName",
        label: "Nominee Name",
        isMandatory: true,
        placeholder: "nominee name",
        bottomInput: "Write Name as per official records",
      },
      {
        tag: "input",
        type: "date",
        name: "nomineeDob",
        label: "Date of Birth",
        isMandatory: true,
        bottomInput: "Select DOB of the nominee as per official records",
      },
      {
        label: "Relationship with Nominee",
        tag: "select",
        isMandatory: true,
        options: [
          {
            name: "Son",
            value: "Son",
          },
          {
            name: "Daughter",
            value: "Daughter",
          },
          {
            name: "Spouse",
            value: "Spouse",
          },
          {
            name: "Father",
            value: "Father",
          },
          {
            name: "Mother",
            value: "Mother",
          },
        ],
      },
      {
        label: "Total share of Nominee",
        tag: "range",
        isMandatory: true,
        name: "nomineeShare",
      },
    ],
  ]);
  useEffect(() => {
    if (inputData?.subFormData != undefined && inputData?.subFormData != null) {
      setNomineeData(inputData?.subFormData);
      const filteredData = Object.keys(inputData?.subFormData)?.filter((prop) =>
        prop.startsWith("nomineeName")
      );
      setNomineeCount(filteredData?.length || 1);
      let isSuccess = checkSum(
        inputData?.subFormData,
        filteredData?.length || 1
      );
    }
  }, []);
  const showNomineeButton = (rootIndex, countIndex) => {
    return countIndex === rootIndex && countIndex < 3 ? true : false;
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    const filteredNomineeObj = Object.keys(nomineeData).reduce(
      (result, key) => {
        if (key.includes("nomineeShare") && key !== name) {
          result[key] = Number(nomineeData[key]);
        }
        return result;
      },
      {}
    );
    const values = Object.values(filteredNomineeObj);
    const sum = values.reduce((acc, value) => Number(acc) + Number(value), 0);
    const newValue = Number(value);

    setNomineeData((data) => ({
      ...data,
      [name]: String(
        Number(newValue) > 100 - Number(sum)
          ? 100 - Number(sum)
          : Number(newValue)
      ),
    }));
  };

  // handle change input
  const handleChangeInput = (e) => {
    e.preventDefault();
    let { name, value, type } = e.target;
    setNomineeData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const selectedLabel = (radioData, index) => {
    return nomineeData[radioData?.name + index] === radioData.value;
  };
  const seriesWiseLabel = (data, index) => {
    if (data?.name === "nomineeName") {
      switch (index) {
        case 1:
          return "First";
        case 2:
          return "Second";
        case 3:
          return "Third";
        default:
          break;
      }
    }
  };

  useEffect(() => {
    let obj = {
      subFormData: nomineeData,
    };
    setInputData(obj);
    let isSuccess = checkSum(nomineeData, nomineeCount);
  }, [nomineeData]);

  //  check sum function
  const checkSum = (data, len) => {
    let sum = 0;
    for (let i = 1; i <= len; i++) {
      sum += Number(data[`nomineeShare${i}`]);
    }
    return sum === 100;
  };

  //  remove nominees
  const removeNominees = (i) => {
    if (i < 2) {
      return;
    }

    const updatedData = Object.fromEntries(
      Object.entries(nomineeData).filter(([key]) => !key.includes(i))
    );

    setNomineeData(updatedData);
    setNomineeCount((prev) => prev - 1);
  };

  //  show hide remove nominees section
  const showHideRemoveButton = (rootIndex, countIndex) => {
    return countIndex === rootIndex && rootIndex != 1 ? true : false;
  };

  //  max date of nominee
  const maxDateFunction = () => {
    const date = new Date();
    return Utils.convertToLocalDateForInput(date);
  };
  const renderTable = () => {
    let elements = [];

    for (let index = 1; index <= nomineeCount; index++) {
      elements.push(
        nomineeForm?.map((data, nomineeIndex) => {
          return (
            <div
              className={`row  ${
                nomineeForm?.length - 1 === nomineeIndex
                  ? Classes.lastRowBorder
                  : "mb-3"
              }`}
              key={nomineeIndex}
            >
              {showHideRemoveButton(index, nomineeCount) && (
                <div
                  className={Classes.removeNominees}
                  onClick={() => removeNominees(index)}
                >
                  <span>x</span>{" "}
                </div>
              )}
              {data?.map((subData, subIndex) => {
                return (
                  <div className="col-lg-6" key={subIndex}>
                    <div className="form-group">
                      <label
                        htmlFor={subData?.name}
                        className={Classes.main_label}
                      >
                        {seriesWiseLabel(subData, index)} {subData.label}{" "}
                      </label>

                      {subData?.tag === "input" && (
                        <input
                          type={subData.type}
                          id={subData?.name}
                          name={subData?.name + index}
                          value={nomineeData[subData?.name + index] || ""}
                          max={subData?.type === "date" && maxDateFunction()}
                          placeholder={
                            subData?.type != "date" &&
                            seriesWiseLabel(subData, index) +
                              " " +
                              subData?.placeholder
                          }
                          onInput={handleChangeInput}
                          className="form-control"
                        />
                      )}
                      {subData?.tag === "select" && (
                        <div className={Classes.radioCollection}>
                          <select
                            className="form-control form-select"
                            onChange={handleChangeInput}
                          >
                            <option disabled selected>
                              Select
                            </option>
                            {subData?.options?.map((option, optIndex) => {
                              return (
                                <option
                                  value={option["name"]}
                                  selected={selectedLabel(option, optIndex)}
                                  key={optIndex}
                                >
                                  {option["value"]}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}

                      {subData?.tag === "range" && (
                        <div className={Classes.rangeInputWidth}>
                          <RangeInputWithoutMaxLimit
                            title={"Total share of Nominee"}
                            range={"Max - 100"}
                            value={
                              Number(nomineeData[subData?.name + index]) || 0
                            }
                            setValue={handleChange}
                            max={100}
                            color={"var(--app-pie-chart-1st)"}
                            name={subData?.name + index}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}

              {showNomineeButton(index, nomineeCount) && (
                <div className="col-lg-12">
                  <div className={Classes.addAnotherNominee}>
                    <SecondaryButton
                      label={"Add Another Nominee"}
                      onClick={() =>
                        nomineeCount < 3
                          ? setNomineeCount((prev) => prev + 1)
                          : null
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })
      );
    }

    return elements;
  };

  return <div className={Classes.nomineeBox}>{renderTable()}</div>;
};
export default NomineeDetails;
