import React, { useState } from "react";
import Classes from "./CustomTable.module.css";
import Utils from "../../Utils";

const CustomTableObjBased = ({
  documentsList,
  tableCaption,
  headings,
  setPage,
}) => {
  return (
    <>
      <div className="table-responsive">
        <table
          className={
            "table table-hover bg-white" +
            " " +
            Classes.custom_table +
            "  caption-top border-bottom"
          }
        >
          {tableCaption ? <caption>{tableCaption}</caption> : null}
          <thead className="table-light ">
            <tr>
              <th className={Classes.table_th}>
                SR No.
                {/* <img src={ArrowUPIcon} /> */}
              </th>
              {headings &&
                headings.map((heading, key) => (
                  <th
                    className={Classes.table_th}
                    style={
                      heading.shortlenText
                        ? { width: 100 / (headings.length - 1) + "%" }
                        : { width: "auto" }
                    }
                    key={key}
                  >
                    {heading.header}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {documentsList ? (
              Object.keys(documentsList).map((ele, dataIndex) => (
                <tr key={dataIndex}>
                  <td className={Classes.table_value}>{dataIndex + 1}</td>

                  {headings &&
                    headings.map((heading, id) =>
                      heading.toolTipStatus == true ? (
                        <td
                          key={id}
                          className="text-left"
                          title={
                            heading.titleHover != undefined
                              ? heading.titleHover(
                                  documentsList[ele],
                                  dataIndex
                                )
                              : null
                          }
                        >
                          <span className={`${Classes.table_value} text-left`}>
                            {heading.shortlenText.isShown == true
                              ? Utils.ShortLengthFunction(
                                  heading.accessor(
                                    documentsList[ele],
                                    dataIndex
                                  ),
                                  heading.shortlenText.len
                                )
                              : heading.accessor(
                                  documentsList[ele],
                                  dataIndex
                                )}{" "}
                          </span>
                        </td>
                      ) : (
                        <td
                          key={id}
                          title={
                            heading.titleHover != undefined
                              ? heading.titleHover(
                                  documentsList[ele],
                                  dataIndex
                                )
                              : null
                          }
                        >
                          <span className={Classes.table_value}>
                            {heading.accessor(documentsList[ele], dataIndex)}
                          </span>
                        </td>
                      )
                    )}
                </tr>
              ))
            ) : (
              <tr className="text-center w-100">
                <td colSpan={headings.length + 1}>NO Data</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CustomTableObjBased;
