import React, { useEffect, useState } from "react";
import classes from "./MasterManagement.module.css";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import UploadDocument from "../../../components/UploadDocument/UploadDocument";
import exportIcon from "../../../assets/TableDashboard/export_csv.svg";

import Utils from "../../../Utils";

import SearchIcon from "../../../assets/SearchIcon_grey.svg";

import { useTranslation } from "react-i18next";

import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import infoIcon from "../../../assets/info_Icon.svg";
import { useNavigate } from "react-router";
import {
  BASE_URL,
  ADMIN_URL,
  MAKER_CHECKER_TASK_URL,
  MASTERMANAGEMENT_URL,
  MAKER_CHANGER_MASTER,
  DASHBOARD_URL,
} from "../../../Routes/Urls";
import {
  downloadSampleFileMaster,
  exportFullMasterDataCsv,
  exportMasterDataCsv,
  getAllMasters,
  importBulkMasters,
} from "../AdminManagementActions";

import { useDispatch, useSelector } from "react-redux";
import Select from "../../../components/Select/Select";
import CorporateManagement from "./Part/CorporateManagement";
import HolidayManagement from "./Part/HolidayManagement";
import EmailTemplate from "./Part/EmailTemplate";
import PFMSManagement from "./Part/PFMSManagement";
import DocumentTypeManagement from "./Part/DocumentTypeManagement";
import POP_SP_Management from "./Part/POP_SP_Management";
import ChargeManagement from "./Part/ChargeManagement";
import Consts from "../../../Consts";
import { memo } from "react";
import { setAlertMsg } from "../../Home/HomeSlice";
const MasterManagement = () => {
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.loginUserDetails);
  const makerChangerMaster = `/${BASE_URL}/${ADMIN_URL}/${MASTERMANAGEMENT_URL}/${MAKER_CHANGER_MASTER}`;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [perPage, setperPage] = useState(10);
  const [page, setPage] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [searchingQuery, setSearchingQuery] = useState("");
  const [masterList, setMasterList] = useState([]);
  const [initialMaster, setInitialMaster] = useState([]);
  const [currMasterId, setCurrMasterId] = useState(1);
  const [isWorkflowEnabled, setIsWorkflowEnabled] = useState(false);
  const [isAddMaster, setIsAddMaster] = useState(false);

  const [isImportModalActive, setIsImportModalActive] = useState(false);
  const [baseUrlCSV, setBaseUrlCSV] = useState("");

  const [tableFunctionName, setTableFunctionName] = useState({
    Holiday: {
      name: "Holiday",
      tableHeading: "List of All Holiday",
      placeholder: "Search by Name",
      CSVUrl: "holidaymasters",
      isHideFilters: false,
      isImportShow: true,
      label: "Holiday Master",
      addBtn: true,
    },
    Corporate: {
      name: "Corporate",
      tableHeading: "List of all corporate masters",
      placeholder: "Search by AckId/Name",
      CSVUrl: "corp-reg/corporates",
      isHideFilters: false,
      isImportShow: false,
      label: "Corporate Master",
      addBtn: false,
    },
    EmailTemplate: {
      name: "EmailTemplate",
      tableHeading: "List of all Email Template",
      placeholder: "Search by Name",
      CSVUrl: "emailtemplatemasters",
      isHideFilters: false,
      isImportShow: true,
      label: "Email Template",
      addBtn: false,
    },
    PFM: {
      name: "PFM",
      tableHeading: "List of all PFM Master",
      placeholder: "Search by PFM  Name",
      CSVUrl: "pfms",
      isHideFilters: false,
      isImportShow: true,
      label: "PFMS Master",
      addBtn: false,
    },
    DocumentType: {
      name: "DocumentType",
      tableHeading: "List of all Documents",
      placeholder: "Search by Name",
      CSVUrl: "processdocumenttypes",
      isHideFilters: false,
      isImportShow: false,
      label: "Document Type Master",
      addBtn: false,
    },
    POPSp: {
      name: "POPSp",
      tableHeading: "List of all POP SP",
      placeholder: "Search by Name",
      CSVUrl: "popspmasters/page",
      isHideFilters: false,
      isImportShow: true,
      label: "POP SP Master",
      addBtn: false,
    },
    Charge: {
      name: "Charge",
      tableHeading: "List of all Charges ",
      placeholder: "Search by  or Value",
      CSVUrl: "charges",
      isHideFilters: true,
      isImportShow: false,
      label: "Charge Master",
      addBtn: false,
    },
  });
  const [currMasterName, setCurrMasterName] = useState();

  const [perPageArr, setPerPageArr] = useState([
    { val: 10, text: "10 per page" },
    { val: 20, text: "20 per page" },
    { val: 50, text: "50 per page" },
  ]);
  useEffect(() => {
    getAllMastersFunction();
  }, []);

  const getAllMastersFunction = () => {
    getAllMasters()
      .then((res) => {
        if (res.data?.success === true) {
          let datas = res.data?.data;
          if (!Array.isArray(datas)) return;
          if (datas.length > 0) {
            setInitialMaster(datas);
            setMasterList(datas);

            // partially
            let currName = Utils.convertToCamelCase(datas[0].name);

            if (tableFunctionName[currName] == undefined) {
              let newCurrName = Utils.convertToCamelCase(datas[1].name);
              setCurrMasterName(newCurrName);
              setCurrentTaskFunction(
                datas[1].id,
                newCurrName,
                datas[1].isWorkflowEnabled
              );
            } else {
              setCurrMasterName(currName);
              setCurrentTaskFunction(
                datas[0].id,
                currName,
                datas[0].isWorkflowEnabled
              );
            }
          } else {
            setMasterList(res.data.data);
          }
        }
      })
      .catch((err) => {
        if (err?.response?.data?.success === false) {
          dispatch(
            setAlertMsg({
              msg: err?.response?.data?.message,
              alertType: "danger",
            })
          );
        } else {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.internal_server_error"),
              alertType: "danger",
            })
          );
        }
      });
  };

  // current function
  const setCurrentTaskFunction = (id, name, isEnabled) => {
    let camelCaseName = Utils.convertToCamelCase(name);
    if (tableFunctionName[camelCaseName] != undefined) {
      setCurrMasterId(id);
      setCurrMasterName(camelCaseName);
      setBaseUrlCSV(tableFunctionName[camelCaseName].CSVUrl);
      setperPage(10);
      setSearchInput("");
      setSearchingQuery("");
      setIsWorkflowEnabled(isEnabled);
    }
    setIsAddMaster(false);
  };

  //  handle search
  const handleMasterDataSearch = (e) => {
    e.preventDefault();
    setperPage(10);
    setSearchingQuery(searchInput);
  };

  // search master
  const filterBySearchMaster = (event) => {
    const query = event.target.value;

    if (query.trim() === "") {
      setMasterList(initialMaster);
    } else {
      var updatedList = initialMaster;
      const filteredItems = updatedList.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      setMasterList(filteredItems);
    }
  };

  const renderedTable = () => {
    switch (currMasterName) {
      case tableFunctionName.Corporate.name:
        return (
          <CorporateManagement
            searchingQuery={searchingQuery}
            perPage={perPage}
            isWorkflowEnabled={isWorkflowEnabled}
            masterId={currMasterId}
          />
        );
      case tableFunctionName.Holiday.name:
        return (
          <HolidayManagement
            searchingQuery={searchingQuery}
            perPage={perPage}
            isWorkflowEnabled={isWorkflowEnabled}
            masterId={currMasterId}
            isAddMaster={isAddMaster}
            setIsAddMaster={setIsAddMaster}
          />
        );
      case tableFunctionName.EmailTemplate.name:
        return (
          <EmailTemplate
            searchingQuery={searchingQuery}
            perPage={perPage}
            isWorkflowEnabled={isWorkflowEnabled}
            masterId={currMasterId}
          />
        );
      case tableFunctionName.PFM.name:
        return (
          <PFMSManagement
            searchingQuery={searchingQuery}
            perPage={perPage}
            isWorkflowEnabled={isWorkflowEnabled}
            masterId={currMasterId}
          />
        );
      case tableFunctionName.DocumentType.name:
        return (
          <DocumentTypeManagement
            searchingQuery={searchingQuery}
            perPage={perPage}
            isWorkflowEnabled={isWorkflowEnabled}
            masterId={currMasterId}
          />
        );
      case tableFunctionName.POPSp.name:
        return (
          <POP_SP_Management
            searchingQuery={searchingQuery}
            perPage={perPage}
            isWorkflowEnabled={isWorkflowEnabled}
            masterId={currMasterId}
          />
        );
      case tableFunctionName.Charge.name:
        return (
          <ChargeManagement
            searchingQuery={searchingQuery}
            perPage={perPage}
            isWorkflowEnabled={isWorkflowEnabled}
            masterId={currMasterId}
          />
        );
    }
  };

  // file import function
  const fileUpdateFunction = (currFile) => {
    let formData = new FormData();

    formData.append("file", currFile);
    importBulkMasters(currMasterId, formData)
      .then((res) => {
        if (res.data?.success) {
          dispatch(
            setAlertMsg({
              msg: res.data?.message,
              alertType: "success",
            })
          );
          getAllMastersFunction();
        }
      })
      .catch((err) => {
        if (err?.response?.data?.success === false) {
          dispatch(
            setAlertMsg({
              msg: err?.response?.data?.message,
              alertType: "danger",
            })
          );
        } else {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.internal_server_error"),
              alertType: "danger",
            })
          );
        }
      });
  };

  const handleClickSampleFile = () => {
    downloadSampleFileMaster(currMasterId);
  };

  const UploadModalHelpingComponent = () => {
    return (
      <div className="col-lg-12 mb-2 px-0">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className={classes.uploadModalHeading}>
            Upload Document -{" "}
            {currMasterName && tableFunctionName[currMasterName]?.label}
          </h3>
          <SecondaryButton
            onClick={() => handleClickSampleFile()}
            label={"Sample File"}
          />
        </div>
      </div>
    );
  };

  const userTypeArr = [
    Consts.INTERNAL_GRP_ROLE_IDS.PROD.id,
    Consts.INTERNAL_GRP_ROLE_IDS.SALES.id,
  ];
  const myMakerCheckerurl = Utils.isCustomTypeUser(userDetails, userTypeArr)
    ? `${DASHBOARD_URL}`
    : `${ADMIN_URL}/${MASTERMANAGEMENT_URL}`;
  const makerCheckerTaskUrl = `/${BASE_URL}/${myMakerCheckerurl}/${MAKER_CHECKER_TASK_URL}`;
  return (
    <>
      <div className={classes.master_container}>
        {/* <button onClick={() => dispatch(setAlertMsg("Send to Checker"))}>
        click
      </button> */}
        <div className={classes.goBackContainer}>
          <GoBackButton />
          <div>
            {Utils.hasMakerCheckerAccess(userDetails) && (
              <SecondaryButton
                onClick={() => navigate(makerCheckerTaskUrl)}
                label={"My Makers checkers Task"}
              />
            )}

            {Utils.isCustomTypeUser(userDetails, [
              Consts.INTERNAL_GRP_ROLE_IDS.ADMIN.id,
            ]) && (
              <PrimaryButton
                onClick={() => navigate(makerChangerMaster)}
                label={t("maker.checker.master")}
              />
            )}
          </div>
        </div>
        <div className={classes.header_container}>
          <div className={classes.heading_heads}>
            <h1 className={classes.master_header}>Master Management</h1>
            <p>Here you can manage all your master data</p>
          </div>
          <div className={classes.export_btns_sec}>
            {currMasterName && tableFunctionName[currMasterName]?.addBtn && (
              <>
                <SecondaryButton
                  onClick={() => setIsAddMaster(true)}
                  label={"Add Holiday"}
                />
              </>
            )}

            {currMasterName &&
              tableFunctionName[currMasterName]?.isImportShow && (
                <>
                  <div
                    className={classes.export_btn}
                    onClick={() => setIsImportModalActive(true)}
                  >
                    <img alt="Import master" src={exportIcon} />
                    Import Master
                  </div>
                </>
              )}
            <div
              className={classes.export_btn}
              onClick={() => {
                currMasterName === "Corporate"
                  ? exportFullMasterDataCsv(baseUrlCSV)
                  : exportMasterDataCsv(baseUrlCSV);
              }}
            >
              <img alt="Export master" src={exportIcon} />
              Export Master
            </div>
          </div>

          <div className={classes.master_body_container}>
            <div className={classes.sidebar_container}>
              <div className={classes.sidebar_header}>
                <h4>List of All Masters</h4>
              </div>
              <div className={classes.master_searchbar}>
                <img className={classes.searchIcon} src={SearchIcon} alt="" />
                <input
                  placeholder="Search Name"
                  onChange={(e) => filterBySearchMaster(e)}
                />
              </div>
              <div className={classes.sidebar_list}>
                {masterList.map((data, index) => {
                  return (
                    <div
                      onClick={() => {
                        setCurrentTaskFunction(
                          data.id,
                          data.name,
                          data.isWorkflowEnabled
                        );
                      }}
                      key={index}
                      className={` ${
                        currMasterId === data.id
                          ? classes.active_list
                          : classes.inactive_list
                      }
                      `}
                    >
                      {data.name}
                      <span className={classes.checker_flow}>
                        {data.isWorkflowEnabled ? "*" : ""}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={classes.master_table}>
              <div className={classes.table_header}>
                <h4>
                  {(currMasterName &&
                    tableFunctionName[currMasterName].tableHeading) ||
                    "List of All"}
                </h4>
              </div>
              {currMasterName &&
                tableFunctionName[currMasterName]?.isHideFilters === false && (
                  <div className={classes.table_searchBar}>
                    <div className="col-lg-5 col-md-6 col-12">
                      <form
                        onSubmit={handleMasterDataSearch}
                        className={classes.header_searchbar}
                      >
                        <p>
                          <img src={SearchIcon} />
                          <input
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            placeholder={
                              currMasterName &&
                              tableFunctionName[currMasterName].placeholder
                            }
                          />
                        </p>
                        <PrimaryButton
                          style={{
                            height: "100%",
                            borderRadius: "0rem  .5rem .5rem 0rem ",
                          }}
                          onClick={handleMasterDataSearch}
                          label={t("label.search")}
                        />
                      </form>
                    </div>

                    <Select
                      onChangeHandler={setperPage}
                      pageChange={setPage}
                      dataObj={perPageArr}
                      selectedData={perPage}
                    />
                  </div>
                )}
              {renderedTable()}
            </div>
          </div>
        </div>
      </div>
      {isImportModalActive && (
        <UploadDocument
          isModalOpen={isImportModalActive}
          setIsModalOpen={setIsImportModalActive}
          acceptedFileType={Consts.ACCEPTED_FILE_TYPE_MASTER}
          uploadedDocuments={fileUpdateFunction}
          InnerComponent={UploadModalHelpingComponent}
        />
      )}
    </>
  );
};

export default memo(MasterManagement);
