import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import Classes from "./ContactVerification.module.css";
import { useState } from "react";
import Timer from "../../../components/Timer/Timer";
import OTPInput from "react-otp-input";
import SuccessfullyRegistrationModal from "../SubscriberHelperModal/SuccessfullyRegistrationModal/SuccessfullyRegistrationModal";
import {
  generateACKIDFuntion,
  sendOTPtoEmailFunction,
  sendOTPtoNumberFunction,
  validateOTPtoEmailFunction,
  validateOTPtoNumberFunction,
} from "./ContactVerificationAction";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMsg } from "../../Home/HomeSlice";
import Utils from "../../../Utils";
import { useTranslation } from "react-i18next";

const ContactVerification = forwardRef(
  (
    {
      startTime,
      successModal,
      setSuccessModal,
      setIsProceed,
      handleProceedAckIdFunction,
      setAckId,
      setPreviewData,
      setCorporateData,
      corpIdParam,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const subRegType = useSelector((state) => state.subRegType);
    const [isMobileOtpTimeOut, setIsMobileOtpTimeOut] = useState(false);
    const [isEmailOtpTimeOut, setIsEmailOtpTimeOut] = useState(false);
    const [contactValidate, setContactValidate] = useState(false);
    const [emailValidate, setEmailValidate] = useState(false);
    const [contactInputValue, setContactInputValue] = useState({});
    const [ackIdData, setAckIdData] = useState({});
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [mobile_otp, setMobile_otp] = useState("");
    const [email_otp, setEmail_otp] = useState("");

    const [contactData, setContactData] = useState([
      {
        label: "Contact Info",
        name: "contact",
        type: "number",
        isValidateInput: false,
        bottomInput: "OTP will be sent to this number",
        isButtonType: true,
        isButtonName: "Send OTP",
        isMandatory: true,
        isTimeEnd: true,
        isButtonDisabled: false,
        isInputDisabled: false,
        isValidate: contactValidate,
        setIsValidate: setContactValidate,
      },
      {
        label: "Enter OTP sent on your mobile number",
        name: "contact_Otp",
        type: "number",
        isValidateInput: true,
        isButtonType: true,
        isButtonName: "Validate",
        isOtpShown: false,
        isValidate: contactValidate,
        setOtpEndFunction: setIsMobileOtpTimeOut,
        isOtpEndFunction: isMobileOtpTimeOut,
        isTimerShow: false,
      },
      {
        label: "Email",
        name: "email",
        type: "email",
        isValidateInput: false,
        bottomInput: "OTP will be sent to this number",
        isButtonType: true,
        isButtonName: "Send OTP",
        isMandatory: true,
        isButtonDisabled: false,
        isInputDisabled: false,
        isValidate: emailValidate,
        setIsValidate: setEmailValidate,
      },
      {
        label: "Enter OTP sent on your Email",
        name: "email_Otp",
        type: "number",
        isValidateInput: true,
        isButtonType: true,
        isButtonName: "Validate",
        isOtpShown: false,
        isValidate: emailValidate,
        setOtpEndFunction: setIsEmailOtpTimeOut,
        isOtpEndFunction: isEmailOtpTimeOut,
        isTimerShow: false,
      },
    ]);

    //  validating email and phone
    useEffect(() => {
      if (mobile_otp?.length === 6) {
        onValidateOtp(contactData[1]);
      }
    }, [mobile_otp]);

    useEffect(() => {
      if (email_otp?.length === 6) {
        onValidateOtp(contactData[3]);
      }
    }, [email_otp]);

    //  ui validation for email and mobile
    const checkUIValidation = async (name) => {
      if (contactInputValue[name] !== undefined) {
        if (
          name === "contact" &&
          !Utils.validateContact(contactInputValue[name]).valid
        ) {
          dispatch(
            setAlertMsg({
              msg: Utils.validateContact(contactInputValue[name])?.message,
              alertType: "danger",
            })
          );
          return false;
        } else if (
          name === "email" &&
          !Utils.validateEmail(contactInputValue[name]).valid
        ) {
          dispatch(
            setAlertMsg({
              msg: Utils.validateEmail(contactInputValue[name])?.message,
              alertType: "danger",
            })
          );
          return false;
        } else {
          return true;
        }
      } else {
        dispatch(
          setAlertMsg({
            msg: `${name} Cannot be Empty`,
            alertType: "danger",
          })
        );
        return false;
      }
    };

    //  onCLick otp Send
    const onSendOtpClick = async (dataName, index) => {
      let isSuccess = false;
      const isValidInput = await checkUIValidation(dataName);
      if (!isValidInput) {
        return;
      }
      let copyArr = [...contactData];
      let findIndex = contactData.findIndex(
        (e) => e.name === `${dataName}_Otp`
      );
      if (copyArr[findIndex]?.name === "contact_Otp") {
        const success = await sendOTPtoNumber();
        isSuccess = success;
      } else if (copyArr[findIndex]?.name === "email_Otp") {
        const success = await sendOTPtoEmail();
        isSuccess = success;
      }
      if (isSuccess) {
        copyArr[findIndex].isOtpShown = true;
        // copyArr[index].isButtonDisabled = true;
        copyArr[findIndex].isTimerShow = true;
      }
      setContactData(copyArr);
    };

    // sendOTPtoNumber
    const sendOTPtoNumber = () => {
      return new Promise((resolve, reject) => {
        let obj = {
          phoneNumber: contactInputValue["contact"],
          requestType: "subscriber",
          consent: "y",
        };
        setPhone(contactInputValue["contact"]);
        sendOTPtoNumberFunction(obj)
          .then((res) => {
            if (res?.data?.success) {
              setContactInputValue((prev) => ({
                ...prev,
                ["contact_request_id"]: res?.data?.data?.request_id,
              }));
              dispatch(
                setAlertMsg({
                  msg: res.data?.message,
                  alertType: "success",
                })
              );

              resolve(true);
            } else {
              dispatch(
                setAlertMsg({
                  msg: res?.response?.data?.message,
                  alertType: "danger",
                })
              );
              resolve(false);
            }
          })
          .catch((err) => {
            dispatch(
              setAlertMsg({
                msg: err?.response?.data?.message,
                alertType: "danger",
              })
            );
            reject(false);
          });
      });
    };

    // sendOTPtoNumber
    const sendOTPtoEmail = () => {
      return new Promise((resolve, reject) => {
        let obj = {
          email: contactInputValue["email"],
          consent: "y",
        };
        setEmail(contactInputValue["email"]);
        sendOTPtoEmailFunction(obj)
          .then((res) => {
            if (res?.data?.success) {
              dispatch(
                setAlertMsg({
                  msg: res.data?.message,
                  alertType: "success",
                })
              );

              resolve(true);
            } else {
              dispatch(
                setAlertMsg({
                  msg: res?.response?.data?.message,
                  alertType: "danger",
                })
              );
              resolve(false);
            }
          })
          .catch((err) => {
            dispatch(
              setAlertMsg({
                msg: err?.response?.data?.message,
                alertType: "danger",
              })
            );
            reject(false);
          });
      });
    };

    // remove otp word from name
    const removeOtpString = (data) => {
      let arr = data?.split("_");
      return arr[0] || data;
    };

    // onValidate Otp function
    const onValidateOtp = async (data) => {
      let isSuccess = false;
      let copyArr = [...contactData];
      let findIndex = contactData.findIndex((e) => e.name === data?.name);
      let inputFieldIndex = contactData.findIndex(
        (e) => e.name === removeOtpString(data?.name)
      );
      if (copyArr[findIndex]?.name === "contact_Otp") {
        const success = await onValidateOTPtoNumber();
        setContactValidate(success);
        isSuccess = success;
      } else if (copyArr[findIndex]?.name === "email_Otp") {
        const success = await onValidateOTPtoEmail();
        setEmailValidate(success);
        isSuccess = success;
      }
      if (isSuccess) {
        copyArr[inputFieldIndex].isButtonName = "Verified";
        copyArr[inputFieldIndex].isValidate = true;
        copyArr[findIndex].isValidate = true;
        copyArr[findIndex].isOtpShown = false;
        copyArr[inputFieldIndex].isInputDisabled = isSuccess;
        copyArr[inputFieldIndex].isButtonDisabled = isSuccess;
      }
      setContactData(copyArr);
    };

    //  if details existing
    const existingDetailsFunction = (text) => {
      let updatedArr = [...contactData];

      if (text.includes("contact")) {
        updatedArr.forEach((data) => {
          if (data.name.includes("contact")) {
            data.isValidate = false;
            data.isInputDisabled = false;
            data.isButtonDisabled = false;
            data.isTimerShow = false;
          }
        });

        setContactInputValue((prev) => {
          let updatedInputValue = { ...prev };
          updatedArr.forEach((data) => {
            if (data.name.includes("contact")) {
              updatedInputValue[data.name] = "";
            }
          });
          return updatedInputValue;
        });

        setContactData(updatedArr);
      }
    };

    // validate otp to number
    const onValidateOTPtoNumber = () => {
      return new Promise((resolve, reject) => {
        let obj = {
          request_id: contactInputValue["contact_request_id"],
          otp: mobile_otp,
          requestType: "subscriber",
          phoneNumber: `${phone}`,
        };

        validateOTPtoNumberFunction(obj)
          .then((res) => {
            if (res?.data?.success) {
              dispatch(
                setAlertMsg({
                  msg: res.data?.message,
                  alertType: "success",
                })
              );
              resolve(true);
            } else {
              dispatch(
                setAlertMsg({
                  msg: res?.response?.data?.message,
                  alertType: "danger",
                })
              );
              resolve(false);
            }
          })
          .catch((err) => {
            dispatch(
              setAlertMsg({
                msg: err?.response?.data?.message,
                alertType: "danger",
              })
            );
            reject(false);
          });
      });
    };

    // validate otp to email
    const onValidateOTPtoEmail = () => {
      return new Promise((resolve, reject) => {
        let obj = {
          otp: email_otp,
          email: `${email}`,
        };

        validateOTPtoEmailFunction(obj)
          .then((res) => {
            if (res?.data?.success) {
              dispatch(
                setAlertMsg({
                  msg: res.data?.message,
                  alertType: "success",
                })
              );
              resolve(true);
            } else {
              dispatch(
                setAlertMsg({
                  msg: res?.response?.data?.message,
                  alertType: "danger",
                })
              );
              resolve(false);
            }
          })
          .catch((err) => {
            dispatch(
              setAlertMsg({
                msg: err?.response?.data?.message,
                alertType: "danger",
              })
            );
            reject(false);
          });
      });
    };

    // resend otp button function
    const resendFunction = (data) => {
      if (data.name === "contact_Otp") {
        const success = sendOTPtoNumber();
        if (success) {
          setIsMobileOtpTimeOut(!isMobileOtpTimeOut);
        }
      } else {
        const success = sendOTPtoNumber();
        if (success) {
          setIsEmailOtpTimeOut(!isEmailOtpTimeOut);
        }
      }
      setContactInputValue((prev) => ({
        ...prev,
        [data?.name]: "",
      }));
    };

    const setOtpEndFunction = (name) => {
      if (name === "contact_Otp") {
        return setIsMobileOtpTimeOut;
      } else {
        return setIsEmailOtpTimeOut;
      }
    };

    // disable otp input class
    const otpdisableClass = (name) => {
      if (name === "contact_Otp") {
        return isMobileOtpTimeOut ? "active" : Classes.disabled;
      } else {
        return isEmailOtpTimeOut ? "active" : Classes.disabled;
      }
    };

    // validate button disable  class
    const disableValidateButtons = (name) => {
      if (name === "contact_Otp") {
        return isMobileOtpTimeOut ? Classes.disableDiv : "active";
      } else {
        return isEmailOtpTimeOut ? Classes.disableDiv : "active";
      }
    };

    //  restart otp function
    const reStartOTPFunction = (name) => {
      if (name === "contact_Otp") {
        return !isMobileOtpTimeOut;
      } else {
        return !isEmailOtpTimeOut;
      }
    };

    useEffect(() => {
      onValidateSuccessfull();
    }, [emailValidate && contactValidate]);

    const onValidateSuccessfull = () => {
      if (emailValidate && contactValidate) {
        setIsProceed(false);
      }
    };

    // filter values  (special Characters)
    const filterUserData = (name, e) => {
      switch (name) {
        case "email":
          return e.target.value?.length < 90
            ? Utils.emailRegex(e)
            : contactInputValue[name];
        case "contact":
          let val = Utils.PreventSpecialCharacterForNumber(e);
          return val;
        default:
          return e.target.value;
      }
    };

    // handle contact input function
    const handleContactInput = (e) => {
      let { name, value } = e.target;
      let newVal = filterUserData(name, e);
      setContactInputValue((prev) => ({
        ...prev,
        [name]: newVal,
      }));
    };

    useImperativeHandle(ref, () => ({
      subscriberRefFunction() {
        handleContactverificationModal();
      },
    }));

    //  modal for ack Id
    const handleContactverificationModal = () => {
      let obj = {
        corporateId: corpIdParam,
        phoneNumber: contactInputValue["contact"],
        email: contactInputValue["email"],
        registrationType: subRegType.toString(),
      };

      // for only test purpose start
      setSuccessModal(true);
      setAckIdData("100");
      setPreviewData({});
      setAckId("SR100");
      setCorporateData({});
      // for only test purpose end

      generateACKIDFuntion(obj)
        ?.then((res) => {
          if (res.data?.success) {
            setSuccessModal(true);
            setAckIdData(res?.data?.data);
            setPreviewData(res?.data?.data);
            setAckId(res?.data?.data?.subData?.id);
            setCorporateData(res?.data?.data?.corpFormData);
          } else {
            existingDetailsFunction(res?.response?.data?.message);
            dispatch(
              setAlertMsg({
                msg: res?.response?.data?.message,
                alertType: "danger",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(
            setAlertMsg({
              msg: err?.response?.data?.message,
              alertType: "danger",
            })
          );
        });
    };

    const handleErrorMsg = (type) => {
      if (type == "contact") {
        return Utils.validateContact(contactInputValue["contact"]).message;
      } else if (type === "email") {
        return Utils.validateEmail(contactInputValue["email"]).message;
      }
    };

    return (
      <>
        <div className={Classes.contactDetailsSubscriber}>
          <div className="row">
            {contactData?.map((data, index) => {
              return (
                <div className="col-lg-6" key={index}>
                  {data?.isOtpShown !== false && (
                    <div
                      className={`form-group ${
                        data?.isValidateInput ? Classes.otp_form_group : ""
                      }`}
                    >
                      <label className="form-label">
                        {data?.label}
                        {data?.isMandatory && (
                          <span className={Classes.mandatory}>*</span>
                        )}
                      </label>
                      {data?.name?.includes("Otp") ? (
                        <div className={Classes.otp_input_fields}>
                          <OTPInput
                            value={
                              data?.name === "contact_Otp"
                                ? mobile_otp
                                : email_otp
                            }
                            onChange={
                              data?.name === "contact_Otp"
                                ? setMobile_otp
                                : setEmail_otp
                            }
                            numInputs={6}
                            renderSeparator={<span> </span>}
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                      ) : (
                        <div
                          className={`${Classes.inputWithTextButton}  ${
                            data?.isInputDisabled ? Classes.disableDiv : ""
                          } `}
                        >
                          <input
                            type={data?.type}
                            name={data?.name}
                            value={contactInputValue[data?.name]}
                            onInput={handleContactInput}
                            disabled={data?.isInputDisabled}
                          />

                          {data?.isButtonType && (
                            <div
                              className={`${Classes.inputButton}
                            ${data?.isValidate ? Classes.successValidate : ""} 
                            ${data?.isButtonDisabled ? Classes.disableDiv : ""} 
                            ${disableValidateButtons(data.name)} `}
                              onClick={() =>
                                data?.isButtonDisabled === false &&
                                onSendOtpClick(data?.name, index)
                              }
                            >
                              {data?.isButtonName}
                            </div>
                          )}
                        </div>
                      )}
                      <div className={Classes.bottomLabel}>
                        {data?.isOtpShown &&
                        data?.isValidate === false &&
                        data?.isTimerShow ? (
                          <div className={Classes.otpShownClass}>
                            <Timer
                              startTime={startTime}
                              isEnd={setOtpEndFunction(data.name)}
                              reStart={reStartOTPFunction(data.name)}
                            />
                            <span
                              className={`${
                                Classes.resendotp
                              } ${otpdisableClass(data.name)} `}
                              onClick={() =>
                                otpdisableClass(data.name) === "active" &&
                                resendFunction(data)
                              }
                            >
                              Resend OTP
                            </span>
                          </div>
                        ) : (
                          <>
                            {data?.bottomInput}
                            <p className={Classes.error_msg}>
                              {contactInputValue[data?.name] &&
                                handleErrorMsg([data?.name])}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {successModal && (
          <SuccessfullyRegistrationModal
            isModalActive={successModal}
            setIsModalActive={setSuccessModal}
            data={ackIdData}
            handleProceed={handleProceedAckIdFunction}
          />
        )}
      </>
    );
  }
);

export default ContactVerification;
