import React from "react";
import classes from "./InputBox.module.css";

const InputBox = ({
  value,
  disabled,
  label,
  handleOnchange,
  description,
  errorMsg,
  placeholder,
  name,
  max,
}) => {
  const handleMaxValue = (e) => {
    let value = e.target.value?.slice(0, max);
    e.target.value = value;
    return handleOnchange(e);
  };
  return (
    <div className={classes.input_box}>
      <div className={classes.label}>{label}</div>
      <div>
        <input
          name={name}
          className={`${errorMsg ? classes.input_empty : classes.input}`}
          value={value}
          onChange={handleMaxValue}
          disabled={disabled}
          type="text"
          placeholder={placeholder}
        />
        {description && <h3 className={classes.description}>{description}</h3>}
        {errorMsg && <p className={classes.error_msg}>{errorMsg}</p>}
      </div>
    </div>
  );
};

export default InputBox;
