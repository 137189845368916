import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getParamsFromDigiLocker } from "../../SubscriberOnboardAction";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../../Home/HomeSlice";
const SubscriberCallApiParams = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const callFunction = (data) => {
    getParamsFromDigiLocker(data)
      .then((res) => {
        if (res.data.success) {
          window.close();
        }
      })
      .then((err) => {
        dispatch(
          setAlertMsg({
            msg: err?.response?.data?.message,
            alertType: "danger",
          })
        );
        window.close();
      });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const channel = new BroadcastChannel("myChannel");
    const message = "true";
    channel.postMessage(message);
    if (queryParams.size > 0) {
      callFunction(queryParams);
    }
  }, []);

  return <div></div>;
};

export default SubscriberCallApiParams;
