import React, { useState } from "react";
import classes from "./RangeInput.module.css";

function RangeInput({ value, max, title, range, setValue, inputState, name }) {
  //  accessor is the key of an object
  // range is any instruction about certain input

  const handleChange = (e) => {
    if (e.target.value < max + 1) {
      inputState(e.target.value);
    } else {
      return;
    }
  };

  return (
    <div className={classes.range_container}>
      <div>
        <div className={classes.title_container}>
          <p>{title}</p>
          <p>{range}</p>
        </div>
        <input
          type="range"
          min="00"
          max={max}
          step="1"
          value={value}
          name={name}
          onChange={(e) => setValue(e)}
          className={classes.red_slider}
        />
      </div>

      <p className={classes.percentage}>
        <input
          type="number"
          value={value ? value : "0"}
          max={max}
          min={0}
          onChange={handleChange}
        />
        %
      </p>
    </div>
  );
}

export default RangeInput;
