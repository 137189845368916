import React from "react";
import classes from "../MasterManagement.module.css";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import { useState } from "react";
import Utils from "../../../../Utils";
import ArrowUPIcon from "../../../../assets/arrow-up.svg";
import PenEditIcon from "../../../../assets/pen_edit.svg";
import { useEffect } from "react";
import {
  editClaimUnclaim,
  getAllEmailtemplates,
  makerCheckerFlow,
} from "../../AdminManagementActions";
import TextButton from "../../../../components/Buttons/TextButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import Modal from "../../../../components/Modal/Modal";
import { useTranslation } from "react-i18next";
import {
  getEmailtemplate,
  updateEmailTemplate,
} from "../../AdminManagementActions";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMsg } from "../../../Home/HomeSlice";
const EmailTemplate = ({
  searchingQuery,
  perPage,
  isWorkflowEnabled,
  masterId,
}) => {
  const userDetails = useSelector((state) => state.loginUserDetails);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("");
  const [accessor, setAccessor] = useState("");
  const [columnRotations, setColumnRotations] = useState("submissionDateasc");
  const [tableData, setTableData] = useState([]);
  const [pageData, setPageData] = useState({});
  const [filterBy, setfilterBy] = useState("");
  const [disabledStatus, setDisabledStatus] = useState(true);
  const [selectDate, setSelectDate] = useState({
    dateOne: "",
    dateSecond: "",
  });
  const [rowId, setRowId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [emailtempActive, setEmailtempActive] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState({
    id: 0,
    email_subject: "",
    email_name: "",
    email_body: "",
  });

  const [changedEmailInput, setChangedEmailInput] = useState({
    id: 0,
    email_subject: "",
    email_name: "",
    email_body: "",
  });
  const EmailTemplateTableColumns = [
    {
      header: (
        <p className={classes.headers}>
          NAME
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "nameasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("name")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.name,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 20 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          Subject
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "subjectasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("subject")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.subject,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 20 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          Body
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "bodyasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("body")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.body,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 30 },
      isTextCenter: true,
    },
    {
      header: <p className={classes.headers}>ACTION</p>,
      key: "corpAction",
      accessor: (e) => (
        <div className={classes.action_btn}>
          {isWorkflowEnabled && (
            <TextButton
              style={{
                fontSize: "0.8rem",
                display:
                  e.makerCheckerStatus === null ||
                  e.makerCheckerStatus === 0 ||
                  e.currentMaker?.username !== userDetails.username ||
                  (e.makerCheckerFlowFunction == 1 &&
                    e.currentMaker?.username !== userDetails.username)
                    ? "none"
                    : "",
              }}
              disabled={
                e.makerCheckerStatus === 2 ||
                (e.makerCheckerStatus === 1 &&
                  e?.currentMaker?.username !== userDetails.username)
              }
              onClick={() => {
                editClaimUnclaimFunction(e.id, true);
                setRowId(e.id);
              }}
              label={t("button.release")}
            />
          )}

          <TextButton
            disabled={
              e.makerCheckerStatus === 2 ||
              (e.makerCheckerStatus === 1 &&
                e?.currentMaker?.username !== userDetails.username &&
                userDetails.username != "wbadmin")
            }
            onClick={() => {
              isWorkflowEnabled == true &&
              (e.makerCheckerStatus == 0 || e.makerCheckerStatus == null) ? (
                <>{(setIsEdit(true), editEmailFunctionData(e.id))}</>
              ) : (
                <>{(setEmailtempActive(true), editEmailFunctionData(e.id))}</>
              );
            }}
            label={t("button.edit")}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    setPage(0);
  }, [perPage, searchingQuery]);

  useEffect(() => {
    getAllEmailtemplatesFunction();
  }, [sort, accessor, page, perPage, searchingQuery]);

  const getAllEmailtemplatesFunction = () => {
    getAllEmailtemplates({
      page,
      searchingQuery,
      sort,
      accessor,
      perPage,
      filterBy,
    }).then((res) => {
      let datas = res.data.data;
      setTableData(datas.content);
      setPageData(datas);
    });
  };
  const handleSort = (key) => {
    var sorting = sort;

    // Toggle sort order
    const newSort = sort === "asc" ? "desc" : "asc";
    setSort(newSort);
    setAccessor(key);

    // Update rotation state for the current column
    if (sort === "asc") {
      setColumnRotations(key + "desc");
    } else {
      setColumnRotations(key + "asc");
    }
  };
  const editEmailFunctionData = (id) => {
    setRowId(id);
    getEmailtemplate(id).then((res) => {
      let email_obj = {
        id: id,
        email_name: res.data.data.masterData.name,
        email_subject: res.data.data.masterData.subject,
        email_body: res.data.data.masterData.body,
      };

      setEmailTemplate(email_obj);
      setChangedEmailInput(email_obj);
    });
  };
  const editEmailTemplate = (event) => {
    const { name, value } = event.target;
    setEmailTemplate((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const editClaimUnclaimFunction = (rowId, isReleased) => {
    editClaimUnclaim(masterId, rowId)
      .then((res) => {
        if (isReleased == false) {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.task_claimed"),
              alertType: "success",
            })
          );
          setEmailtempActive(true);
          setIsEdit(false);
        } else {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.task_unclaimed"),
              alertType: "success",
            })
          );
          getAllEmailtemplatesFunction();
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.task_claim_fail"),
            alertType: "danger",
          })
        );
      });
  };
  const makerCheckerFlowFunction = () => {
    let data = {};
    if (emailTemplate.email_name != changedEmailInput.email_name) {
      data.name = Utils.removeWhiteSpaceFromBetween(emailTemplate.email_name);
    }
    if (emailTemplate.email_subject != changedEmailInput.email_subject) {
      data.subject = Utils.removeWhiteSpaceFromBetween(
        emailTemplate.email_subject
      );
    }
    if (emailTemplate.email_body != changedEmailInput.email_body) {
      data.body = Utils.removeWhiteSpaceFromBetween(emailTemplate.email_body);
    }
    makerCheckerFlow(masterId, rowId, data)
      .then((res) => {
        dispatch(
          setAlertMsg({
            msg:
              isWorkflowEnabled === true
                ? t("alertMsg.changes_for_approval")
                : t("alertMsg.edit_successfully"),
            alertType: "success",
          })
        );
        setEmailtempActive(false);
        getAllEmailtemplatesFunction();
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.update_failed"),
            alertType: "danger",
          })
        );
      });
  };

  useEffect(() => {
    checkSubmitDisabled();
  }, [emailTemplate]);

  const checkSubmitDisabled = () => {
    var status =
      (Utils.removeWhiteSpaceFromBetween(
        emailTemplate.email_name
      ).toLocaleLowerCase() ==
        Utils.removeWhiteSpaceFromBetween(
          changedEmailInput.email_name
        ).toLocaleLowerCase() &&
        Utils.removeWhiteSpaceFromBetween(
          emailTemplate.email_subject
        ).toLocaleLowerCase() ==
          Utils.removeWhiteSpaceFromBetween(
            changedEmailInput.email_subject
          ).toLocaleLowerCase() &&
        Utils.removeWhiteSpaceFromBetween(
          emailTemplate.email_body
        ).toLocaleLowerCase() ==
          Utils.removeWhiteSpaceFromBetween(
            changedEmailInput.email_body
          ).toLocaleLowerCase()) ||
      Utils.removeWhiteSpaceFromBetween(emailTemplate.email_name) == "" ||
      Utils.removeWhiteSpaceFromBetween(emailTemplate.email_body) == "" ||
      Utils.removeWhiteSpaceFromBetween(emailTemplate.email_subject) == ""
        ? true
        : false;

    setDisabledStatus(status);
  };
  return (
    <>
      <CustomTable
        page={page}
        setPage={setPage}
        headings={EmailTemplateTableColumns}
        data={tableData}
        pageData={pageData}
        sort={sort}
        setSort={setSort}
        accessor={accessor}
        setAccessor={setAccessor}
        srNO={true}
      />
      {/* for holiday edit */}
      {/* for email template  edit */}

      {isEdit == true && isWorkflowEnabled == true && (
        <Modal>
          <div className={classes.editModal_container}>
            <h4>
              <span>
                <img src={PenEditIcon} alt="pen edit Master management" />
              </span>{" "}
              {t("proceed.edit.master")}
            </h4>
            <p>{t("proceed.edit.master.text")}</p>
            <div className={classes.editModal_btn}>
              <PrimaryButton
                onClick={() => {
                  editClaimUnclaimFunction(rowId, false);
                }}
                label={t("button.proceed")}
              />
              <TextButton
                onClick={() => {
                  setIsEdit(false);
                }}
                label={t("button.close")}
              />
            </div>
          </div>
        </Modal>
      )}

      {emailtempActive && (
        <Modal
          closeBtn={{}}
          onClose={() => {
            getAllEmailtemplatesFunction();
            setEmailtempActive(false);
          }}
        >
          <div className={classes.email_modal_body}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-input mb-3">
                  <label>Email Name</label>
                  <input
                    type="text"
                    name="email_name"
                    value={emailTemplate.email_name}
                    onInput={editEmailTemplate}
                    className="form-control"
                  />
                </div>
                <div className="form-input mb-3">
                  <label>Email Subject</label>
                  <input
                    type="text"
                    name="email_subject"
                    value={emailTemplate.email_subject}
                    onInput={editEmailTemplate}
                    className="form-control"
                  />
                </div>
                <div className="form-input mb-3">
                  <label>Email Body</label>

                  <textarea
                    rows={5}
                    name="email_body"
                    value={emailTemplate.email_body}
                    onInput={editEmailTemplate}
                    className={`textarea form-control ${classes.emailTemplate}`}
                  ></textarea>
                </div>
                <div className="btn-group mt-4">
                  <div className="btn-form mr-3">
                    <PrimaryButton
                      disabled={disabledStatus}
                      label={t("button.submit")}
                      onClick={() => {
                        makerCheckerFlowFunction(emailTemplate.id);
                        // changeEmailFunction(emailTemplate.id);
                      }}
                    />
                  </div>
                  <div className="btn-form">
                    <SecondaryButton
                      onClick={() => {
                        getAllEmailtemplatesFunction();
                        setEmailtempActive(false);
                      }}
                      label={t("button.cancel")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default EmailTemplate;
