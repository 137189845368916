import React from "react";
import Modal from "../../../components/Modal/Modal";
import classes from "./ApplicationModal.module.css";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import fileIcon from "../../../assets/file-icon.svg";
import acceptDocumentIcon from "../../../assets/accept_document.svg";
import checkIcon from "../../../assets/tick-icon-blue-bg.svg";
import idIcon from "../../../assets/identity-icon.svg";
import signatureIcon from "../../../assets/signature-icon.svg";

const ApplicationModal = () => {
  return (
    <>
      <Modal>
        <div className={classes.applicationModal_container}>
          <div className={classes.titleContainer}>
            <div className={classes.leftContainer}>
              <span>
                <img src={acceptDocumentIcon} alt='icon' />
              </span>
              <div className={classes.headingText}>Application accepted</div>
            </div>

            <div className={classes.rightContainer}>
              Acknowledgement ID : 6372811
            </div>
          </div>
          <div className={classes.messageContainer}>
            <span>
              <img src={checkIcon} alt='icon' />
            </span>
            <span> All the documents are accepted , OSV is completed</span>
          </div>
          <div className={classes.statusContainer}>
            <div className={classes.iconContainer}>
              <span>
                <img src={fileIcon} alt='icon' />
              </span>
              <span>Form</span>
              <span>
                <img src={checkIcon} alt='icon' />
              </span>
            </div>
            <div className={classes.iconContainer}>
              <span>
                <img src={idIcon} alt='icon' />
              </span>
              <span>Aadhar</span>
              <span>
                <img src={checkIcon} alt='icon' />
              </span>
            </div>
            <div className={classes.iconContainer}>
              <span>
                <img src={idIcon} alt='icon' />
              </span>
              <span>Photo</span>
              <span>
                <img src={checkIcon} alt='icon' />
              </span>
            </div>
            <div className={classes.iconContainer}>
              <span>
                <img src={signatureIcon} alt='icon' />
              </span>
              <span>Signature</span>
              <span>
                <img src={checkIcon} alt='icon' />
              </span>
            </div>
          </div>
          <div className={classes.buttonsContainer}>
            <PrimaryButton label={"Proceed"} />
            <SecondaryButton
              label={"Close"}
              style={{
                borderRadius: "0.5rem",
                fontWeight: "600",
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ApplicationModal;
