import React, { useEffect, useState } from "react";
import classes from "./RoleManagement.module.css";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import TextButton from "../../../components/Buttons/TextButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import { useTranslation } from "react-i18next";
import TabComp from "../../../components/Tabs/TabComp";
import Select from "../../../components/Select/Select";
import {
  getAllGroups,
  getRolesViaGroup,
} from "../UserManagement/UserManagementActions";
import CustomTable from "../../../components/CustomTable/CustomTable";
import ArrowUPIcon from "../../../assets/arrow-up.svg";
import RedDot from "../../../assets/TableDashboard/RedDot.svg";
import GreenDot from "../../../assets/TableDashboard/GreenDot.svg";
import {
  addRoleToUser,
  getAllUserManagement,
  removeRoleToUser,
} from "../AdminManagementActions";
import searchIcon from "../../../assets/SearchIcon_grey.svg";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../Home/HomeSlice";
import Utils from "../../../Utils";

const RoleManagement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [designationList, setDesignationList] = useState([]);
  const [designationId, setDesignationId] = useState("");
  const [tabComp, setTabComp] = useState(0);
  const [sort, setSort] = useState("asc");
  const [page, setPage] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [columnRotations, setColumnRotations] = useState("");
  const [filterBy, setfilterBy] = useState("");
  const [accessor, setAccessor] = useState("");
  const [tableData, setTableData] = useState([]);
  const [pageData, setPageData] = useState({});
  const [perPage, setperPage] = useState(10);
  const [userList, setUserList] = useState([]);
  const [isHPMCUser, setIsHPMCUser] = useState(false);

  useEffect(() => {
    getAllGroups()
      .then((res) => {
        if (res.data.success === true) {
          setDepartmentList(res.data.data);
          setTabComp(0);
        }
      })
      .catch((err) => {
        setDepartmentList([]);
        setTabComp(0);
      });
  }, []);

  // get users data
  const getAllUsersFunction = () => {
    let assign = tabComp === 1 ? "noigrole" : "";
    let groupId = tabComp === 1 ? "" : departmentId;
    let roleId = tabComp === 1 ? "" : designationId;
    getAllUserManagement({
      accessor,
      sort,
      perPage,
      page,
      searchInput,
      assign,
      groupId,
      roleId,
    })
      .then((res) => {
        setTableData(res.data.data.pageData.content);
        setIsHPMCUser(res.data.data?.internalGroupRole?.isHpmcRole);
        setPageData(res.data.data.pageData);
        if (res.data.data.pageData.content.length < 1) {
          dispatch(
            setAlertMsg({
              msg: "No User available",
              alertType: "warning",
            })
          );
          setTabComp(0);
        }
      })
      .catch((err) => {
        setTableData([]);
        setPageData([]);
        setIsHPMCUser(false);
      });
    setUserList([]);
  };

  // search for users
  useEffect(() => {
    if (departmentId && designationId) {
      // setPage(0);
      getAllUsersFunction();
    } else return;
  }, [
    filterBy,
    sort,
    accessor,
    perPage,
    page,
    designationId,
    tabComp,
    departmentId,
  ]);

  useEffect(() => {
    setPage(0);
    setAccessor("");
  }, [perPage]);

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(0);
    setAccessor("");
    getAllUsersFunction();
  };

  // tab obj
  const tabComObj = [
    {
      title: "With Designation",
    },
    {
      title: isHPMCUser === false || tabComp === 1 ? "Without Designation" : "",
    },
  ];

  // perPage component object
  const perPageObj = [
    { text: "10 Page", val: 10 },
    { text: "20 Page", val: 20 },
    { text: "50 Page", val: 50 },
  ];

  // table heading object
  const columns = [
    {
      header:
        isHPMCUser === false || tabComp === 1 ? (
          <p className={classes.headers}></p>
        ) : null,
      accessor: (e) => (
        <>
          {(tabComp === 1 && !e.isHpmcUser) || isHPMCUser === false ? (
            <input
              onChange={() => handleCheck(e)}
              checked={userList.includes(e.id)}
              type="checkbox"
            />
          ) : null}
        </>
      ),
    },
    {
      header: (
        <p className={classes.headers}>
          Employee ID
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "employeeCodeasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("employeeCode")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => (
        <p className={classes.table_column}>
          {Utils.ShortLengthFunction(e.employeeCode, 20)}
        </p>
      ),
    },
    {
      header: (
        <p className={classes.headers}>
          Employee Name
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "fullNameeasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("fullName")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => (
        <p>{Utils.ShortLengthFunction(e.fullName, 25) || "--"}</p>
      ),
    },
    {
      header: <p className={classes.headers}>STATUS</p>,
      key: "employeeStatus",
      accessor: (e) => (
        <p className={classes.state_container}>
          {e.canLogin ? (
            <p className={classes.table_column}>
              <img src={GreenDot} />
              Active
            </p>
          ) : (
            <p className={classes.table_column}>
              <img src={RedDot} />
              Inactive
            </p>
          )}
        </p>
      ),
    },
    {
      header: (
        <p className={classes.headers}>
          Email Id
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "usernameasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("username")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => (
        <p className={classes.table_column}>
          {Utils.ShortLengthFunction(e.username, 30)}
        </p>
      ),
    },
    {
      header:
        isHPMCUser === false || tabComp === 1 ? (
          <p className={classes.headers}>Action</p>
        ) : (
          <></>
        ),
      accessor: (e) =>
        isHPMCUser === false || (tabComp === 1 && e.isHpmcUser === false) ? (
          <>
            <TextButton
              onClick={(event) => {
                handleSingleAssignDesignation(event, e.id);
              }}
              label={tabComp === 0 ? "UnAssign" : "Assign"}
            />
          </>
        ) : null,
    },
  ];

  // handle all select
  const handleAllSelect = (tableData) => {
    if (tableData.length !== userList.length) {
      setUserList(tableData?.map((data) => data.id));
    } else {
      setUserList([]);
    }
  };

  // handle check and uncheck
  const handleCheck = (e) => {
    if (userList.includes(e.id)) {
      setUserList(userList.filter((id) => e.id !== id));
    } else {
      setUserList([...userList, e.id]);
    }
  };

  // multiple assign designation to user
  const handleAssignDesignation = (event) => {
    if (tabComp === 1) {
      addRoleToUser(departmentId, designationId, [...userList])
        .then((res) => {
          if (res.data.success === true) {
            dispatch(
              setAlertMsg({
                msg: "Designation unassigned to all selected user successfully",
                alertType: "success",
              })
            );
            handleSearch(event);
          }
        })
        .catch((error) => {
          dispatch(
            setAlertMsg({ msg: error.response.message, alertType: "danger" })
          );
        });
    } else if (tabComp === 0) {
      removeRoleToUser(departmentId, designationId, [...userList])
        .then((res) => {
          if (res.data.success === true) {
            dispatch(
              setAlertMsg({
                msg: "Designation unassigned to all selected user successfully",
                alertType: "success",
              })
            );
            handleSearch(event);
          }
        })
        .catch((error) => {
          dispatch(
            setAlertMsg({ msg: error.response.message, alertType: "danger" })
          );
        });
    }
  };

  // single assign designation to user
  const handleSingleAssignDesignation = (event, id) => {
    if (tabComp === 1) {
      addRoleToUser(departmentId, designationId, [id])
        .then((res) => {
          if (res.data.success === true) {
            handleSearch(event);
            dispatch(
              setAlertMsg({
                msg: "Designation assigned successfully",
                alertType: "success",
              })
            );
          }
        })
        .catch((error) => {
          dispatch(
            setAlertMsg({ msg: error.response.message, alertType: "danger" })
          );
        });
    } else if (tabComp === 0) {
      removeRoleToUser(departmentId, designationId, [id])
        .then((res) => {
          if (res.data.success === true) {
            handleSearch(event);
            dispatch(
              setAlertMsg({
                msg: "Designation unassigned successfully",
                alertType: "success",
              })
            );
          }
        })
        .catch((error) => {
          dispatch(
            setAlertMsg({ msg: error.response.message, alertType: "danger" })
          );
        });
    }
  };

  // handling sort
  const handleSort = (key) => {
    const newSort = sort === "asc" ? "desc" : "asc";
    setSort(newSort);
    setAccessor(key);
    if (sort === "asc") {
      setColumnRotations(key + "desc");
    } else {
      setColumnRotations(key + "asc");
    }
  };

  // clear department and designation
  const handleResetAll = (e) => {
    setDesignationId("");
    setDesignationList([]);
    setTableData([]);
    setPageData({});
    setDepartmentId();
    setSearchInput("");
  };

  //select department
  const handleSelectDepartment = (e) => {
    setDesignationList([]);
    setDesignationId("");
    setTableData([]);
    setPageData({});
    setSearchInput("");
    setDepartmentId(e.target.value);
    // get designation as per department
    if (e.target.value) {
      getRolesViaGroup(e.target.value).then((res) => {
        if (res.data.success === true) {
          setDesignationList(res.data.data);
        }
      });
    }
  };

  // select designation
  const handleSelectDesignation = (e) => {
    setDesignationId(e.target.value);
    setTableData([]);
    setSearchInput("");
  };

  return (
    <div className={classes.role_management_container}>
      <div className={classes.title_container}>
        <GoBackButton />
        <h5>{t("text.roleManagement")}</h5>
      </div>
      <div className={classes.role_management}>
        <div className={classes.select_filter_container}>
          <div className={classes.dropDown_box}>
            <h6>Select Department</h6>
            <select
              value={departmentId ? departmentId?.groupName : ""}
              onChange={handleSelectDepartment}
            >
              <option>select</option>
              {departmentList &&
                departmentList.map((department, index) => {
                  return (
                    <option key={index} value={department.id}>
                      {department?.groupName}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className={classes.dropDown_box}>
            <h6>Select Designation</h6>
            <select
              disabled={!departmentId}
              value={designationId.roleName}
              onChange={handleSelectDesignation}
            >
              <option>select</option>
              {designationList.map((designation, index) => {
                return (
                  <option key={index} value={designation.id}>
                    {designation.roleName}
                  </option>
                );
              })}
            </select>
          </div>
          <div className={classes.reset_btn}>
            <TextButton onClick={handleResetAll} label={"Reset all"} />
          </div>
        </div>

        <div className={classes.table_search_container}>
          <div className={classes.heading}>
            <h5>List Of All Users </h5>
            {page.totalPages && (
              <span>
                Showing {page + 1} / {pageData.totalPages}
              </span>
            )}
          </div>
          <div className={classes.tab}>
            <TabComp
              tabValue={tabComp}
              setTabValue={setTabComp}
              label={tabComObj}
              clickAble={true}
            />
          </div>

          <div className={classes.searchBar}>
            {(isHPMCUser === false || tabComp === 1) &&
            tableData.length > 0 &&
            designationId ? (
              <div className={classes.selectAll_container}>
                <label>
                  <input
                    onChange={() => handleAllSelect(tableData)}
                    checked={userList.length === tableData.length}
                    type="checkbox"
                  />
                  Select All ({userList.length})
                </label>
                <SecondaryButton
                  disabled={userList.length === 0}
                  onClick={(e) => handleAssignDesignation(e, e.Id)}
                  label={tabComp === 0 ? "Assign All" : "UnAssign All"}
                />
              </div>
            ) : null}

            <div>
              <form
                onSubmit={handleSearch}
                className={classes.searchBar_container}
              >
                <img src={searchIcon} alt="search" />
                <input
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  placeholder="Search Name"
                  type="text"
                  disabled={!designationId}
                />
              </form>
              <Select
                selectedData={perPage}
                onChangeHandler={setperPage}
                dataObj={perPageObj}
                disabled={!designationId}
              />
            </div>
          </div>
        </div>

        <CustomTable
          page={page}
          setPage={setPage}
          pageData={pageData}
          data={tableData}
          headings={columns}
          srNO={false}
        />
      </div>
    </div>
  );
};

export default RoleManagement;
