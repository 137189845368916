import React from "react";
import classes from "../MasterManagement.module.css";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import { useState } from "react";
import Utils from "../../../../Utils";
import ArrowUPIcon from "../../../../assets/arrow-up.svg";
import PenEditIcon from "../../../../assets/pen_edit.svg";
import documentIcon from "../../../../assets/ActionBtnIcon/register_icon_inactive.svg";
import { useEffect } from "react";
import {
  downloadCorpData,
  editClaimUnclaim,
  getCorporateMasterData,
  makerCheckerFlow,
} from "../../AdminManagementActions";
import { useDispatch, useSelector } from "react-redux";
import TextButton from "../../../../components/Buttons/TextButton";
import { useTranslation } from "react-i18next";
import ObjForm from "../../../../components/ObjForm/ObjForm";
import formObj from "./JsonFileCorporate/CorporateManagement.json";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import Modal from "../../../../components/Modal/Modal";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import {
  editCorporateMasterForm,
  getCorpFormData,
} from "../../../CorponboardingForm/CorporateAction";
import { setAlertMsg } from "../../../Home/HomeSlice";
import { Tooltip } from "@mui/material";
import { getAllRMUser } from "../../UserManagement/UserManagementActions";

const CorporateManagement = ({
  searchingQuery,
  dateOne,
  dateTwo,
  perPage,
  isWorkflowEnabled,
  masterId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userDetails = useSelector((state) => state.loginUserDetails);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("");
  const [accessor, setAccessor] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [columnRotations, setColumnRotations] = useState("");
  const [isForm, setIsForm] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageData, setPageData] = useState({});
  const [filterBy, setfilterBy] = useState("");
  const [CorpId, setCorpId] = useState(0);
  const [corpFormDetails, setCorpFormDetails] = useState({});
  const [formChangeDetails, setFormChangeDetails] = useState({});
  const [disabledCorpData, setDisabledCorpData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [validateInputField, setValidateInputField] = useState({});
  const [isMandatoryValues, setIsMandatoryValues] = useState([]);
  const [corpUsers, setCorpUsers] = useState({
    prodUser: "",
    saleUser: "",
  });
  const [allRMUser, setAllRMUser] = useState([]);

  // corporate table columns

  const corporateTableColumns = [
    {
      header: (
        <p className={classes.headers}>
          ACKNOWLEDGEMENT Id
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "ackIdasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => {
              handleSort("ackId");
            }}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => (
        <>
          {e.ackId}
          <Tooltip placement="bottom" title={"Document"}>
            <button
              className={classes.corpDownloadButton}
              onClick={(event) => {
                downloadCorpData(e?.corporateId);
              }}
            >
              <img className={classes.icon_img} src={documentIcon} />
            </button>
          </Tooltip>
        </>
      ),
    },
    {
      header: (
        <p className={classes.headers} style={{ textAlign: "left" }}>
          CORPORATE NAME
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "corpNameasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => {
              handleSort("corpName");
            }}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.corpName,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 25 },
    },
    {
      header: (
        <p className={classes.headers}>
          Date
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "submissionDateasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("corpNpsRegDate")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => Utils.convertToLocalDateOnly(e.corpNpsRegDate),
    },

    {
      header: <p className={classes.headers}>ACTION</p>,
      key: "corpAction",
      accessor: (e) => (
        // makercheckerStatus 0 or null === edit, or 1 == claimed , 2 == process
        <div className={classes.action_btn}>
          {isWorkflowEnabled && (
            <TextButton
              style={{
                display:
                  e.makerCheckerStatus === null ||
                  e.makerCheckerStatus === 0 ||
                  e.currentMaker?.username !== userDetails.username ||
                  (e.makerCheckerFlowFunction == 1 &&
                    e.currentMaker?.username !== userDetails.username)
                    ? "none"
                    : "",
              }}
              // disabled={
              //   //  if status is 1 and currentmaker user not equal to login user or admin then disable
              //   // e.makerCheckerStatus === 2 ||
              //   e.makerCheckerStatus === 1 &&
              //   e?.currentMaker?.username !== userDetails.username &&
              //   userDetails.username !== "wbadmin"
              // }
              disabled={
                e?.makerCheckerStatus === 2 ||
                (e?.makerCheckerStatus === 1 &&
                  e?.currentMaker?.username !== userDetails.username)
              }
              onClick={() => {
                editClaimUnclaimFunction(e.id, true);
                setCorpId(e.id);
              }}
              label={t("button.release")}
            />
          )}

          <TextButton
            disabled={
              e?.makerCheckerStatus === 2 ||
              (e?.makerCheckerStatus === 1 &&
                e?.currentMaker?.username !== userDetails.username &&
                !Utils.isAdmin(userDetails))
            }
            // e.makerCheckerStatus === 1 ||
            onClick={() => {
              isWorkflowEnabled == true &&
              (e?.makerCheckerStatus == 0 || e?.makerCheckerStatus == null) ? (
                <>{(setCorpId(e.id), setIsEdit(true), mapUserFunction(e))}</>
              ) : (
                <>{(setCorpId(e.id), setIsForm(true), mapUserFunction(e))}</>
              );
            }}
            label={t("button.edit")}
          />
        </div>
      ),
    },
  ];
  const mapUserFunction = (e) => {
    setCorpUsers({
      prodUser: e?.currentAssignedUser?.username,
      saleUser: e?.submitter?.username,
    });
  };

  useEffect(() => {
    setPage(0);
  }, [perPage, searchingQuery]);

  useEffect(() => {
    getCorporateMasterData({
      page,
      searchingQuery,
      sort,
      accessor,
      perPage,
      filterBy,
    }).then((res) => {
      setTableData(res.data.data.content);
      setPageData(res.data.data);
    });
  }, [sort, accessor, dateOne, dateTwo, page, perPage, searchingQuery]);

  const handleSort = (key) => {
    var sorting = sort;

    const newSort = sort === "asc" ? "desc" : "asc";
    setSort(newSort);
    setAccessor(key);

    // Update rotation state for the current column
    if (sort === "asc") {
      setColumnRotations(key + "desc");
    } else {
      setColumnRotations(key + "asc");
    }
  };

  useEffect(() => {
    if (CorpId !== 0) {
      editCorporateMasterForm(CorpId).then((res) => {
        if (res.data.success === true) {
          let corpIdData = res.data?.data?.masterData;
          let mergedData = {
            ...corpIdData,
            ...corpUsers,
          };
          setCorpFormDetails((data) => ({
            ...mergedData,
            docVerifiedMark:
              corpIdData?.docVerifiedMark === true ? "Yes" : "No",
            isPopShift:
              corpIdData.isPopShift === true ? "POP Shift" : "New Registration",
            corpNpsRegDate: Utils.convertToLocalDateForInput(
              corpIdData.corpNpsRegDate
            ),
            corpRegDate: Utils.convertToLocalDateForInput(
              corpIdData.corpRegDate
            ),
            hierarchy: corpIdData?.hierarchy
              ?.map((user) => user?.username)
              ?.join(" -> "),
          }));

          setDisabledCorpData(mergedData);
          setFormChangeDetails({});
        }
      });
    }
  }, [CorpId]);
  //  handle search
  const handleMasterDataSearch = () => {
    getCorporateMasterData({
      page,
      searchingQuery,
      sort,
      accessor,
      perPage,
      filterBy,
    }).then((res) => {
      setTableData(res.data.data.content);
      setPageData(res.data.data);
    });
    setPage(0);
  };

  const handleChange = (e, key, validType) => {
    if (
      validType === "gst" ||
      validType === "cin" ||
      validType === "ifsc" ||
      (validType === "tan" && validType !== "date")
    ) {
      // if(e?.target?.value === "." || e?.target?.value.includes(".")){
      //   return;
      // }
      setCorpFormDetails((data) => ({
        ...data,
        [key]: Utils.removeWhiteSpaceFromBetween(
          Utils.PreventSpecialCharacter(e).toUpperCase()
        ).trim(),
      }));

      if (
        disabledCorpData[key] !=
        Utils.removeWhiteSpaceFromBetween(e.target.value).trim()
      ) {
        setFormChangeDetails((data) => ({
          ...data,
          [key]: Utils.removeWhiteSpaceFromBetween(
            Utils.PreventSpecialCharacter(e).toUpperCase()
          ).trim(),
        }));
      }
    } else if (validType === "dropdown") {
      setCorpFormDetails((data) => ({
        ...data,
        [key]: e.target.value,
      }));

      setFormChangeDetails((data) => ({
        ...data,
        [key]: e.target.value,
      }));
    } else if (validType === "email") {
      setCorpFormDetails((data) => ({
        ...data,
        [key]:
          e.target.value?.length <= 90
            ? Utils.emailRegex(e)
            : corpFormDetails[key],
      }));

      if (disabledCorpData[key] != Utils.emailRegex(e)) {
        setFormChangeDetails((data) => ({
          ...data,
          [key]:
            e.target.value?.length <= 90
              ? Utils.emailRegex(e)
              : corpFormDetails[key],
        }));
      }
    } else if (validType === "date") {
      setCorpFormDetails((data) => ({
        ...data,
        [key]: e.target.value,
      }));

      setFormChangeDetails((data) => ({
        ...data,
        [key]: e.target.value,
      }));
    } else if (validType === "contact" || validType === "acc") {
      setCorpFormDetails((data) => ({
        ...data,
        [key]: Utils.PreventSpecialCharacterForNumber(e),
      }));

      if (disabledCorpData[key] != Utils.PreventSpecialCharacterForNumber(e)) {
        setFormChangeDetails((data) => ({
          ...data,
          [key]: Utils.PreventSpecialCharacterForNumber(e),
        }));
      }
    } else {
      setCorpFormDetails((data) => ({
        ...data,
        [key]: Utils.PreventSpecialCharacter(e),
      }));

      if (
        disabledCorpData[key] !=
        Utils.removeWhiteSpaceFromBetween(e.target.value).trim()
      ) {
        setFormChangeDetails((data) => ({
          ...data,
          [key]: Utils.removeWhiteSpaceFromBetween(
            Utils.PreventSpecialCharacter(e)
          ),
        }));
      }
    }
    if (
      disabledCorpData[key] ===
      Utils.removeWhiteSpaceFromBetween(Utils.PreventSpecialCharacter(e)).trim()
    ) {
      delete formChangeDetails[key];
    } else if (
      disabledCorpData[key] === undefined &&
      Utils.removeWhiteSpaceFromBetween(
        Utils.PreventSpecialCharacter(e)
      ).trim() === ""
    ) {
      delete formChangeDetails[key];
      setFormChangeDetails(formChangeDetails);
    }

    //  validate fund as per logic
    const validFund = (fundValue) => {
      let obj = {
        valid: true,
        message: "",
      };
      if (fundValue > 75 && validType === "fundE") {
        obj.valid = false;
        obj.message = "cannot exceed 75%";
      }

      if (fundValue > 6 && validType === "fundA") {
        obj.valid = false;
        obj.message = "cannot exceed 5%";
      }

      const { fundA, fundE, fundC, fundG } = formObj;
      if (
        (fundA ? fundA : 0) +
          (fundC ? fundC : 0) +
          (fundE ? fundE : 0) +
          (fundG ? fundG : 0) !==
        100
      ) {
        dispatch(
          setAlertMsg({
            msg: "The sum of all funds should be equal to 100.",
            alertType: "danger",
          })
        );
      }

      return obj;
    };

    //  during onchange validating values
    const validationFunctions = {
      email: Utils.validateEmail,
      pan: Utils.validatePan,
      contact: Utils.validateContact,
      acc: Utils.validateAccount,
      ifsc: Utils.validateIFSC,
      cin: Utils.validateCIN,
      gst: Utils.validateGST,
      tan: Utils.validateTan,
      fundE: validFund,
      fundA: validFund,
      fundC: validFund,
      fundG: validFund,
      pinCode: Utils.validatePinCode,
    };

    const validationFunction = validationFunctions[validType];
    if (validationFunction) {
      setValidateInputField((data) => ({
        ...data,
        [key]: validationFunction(e.target.value.toUpperCase()).message,
      }));
    }
  };

  useEffect(() => {
    formObj.forEach((item) => {
      if (item.section && Array.isArray(item.section)) {
        item.section.forEach((section) => {
          if (section.Fields && Array.isArray(section.Fields)) {
            section.Fields.forEach((field) => {
              if (
                field.is_mandatory !== undefined &&
                field.is_mandatory === true
              ) {
                setIsMandatoryValues((data) => [
                  ...data,
                  {
                    is_mandatory: field.is_mandatory,
                    accessor: field.accessor,
                    label: field.label,
                  },
                ]);
              }
            });
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    if (isForm === true) {
      getAllRMUser()
        .then((res) => {
          if (res.data.success === true) {
            setAllRMUser(
              res.data.data?.map((user) => {
                return { id: user?.id, email: user?.username };
              })
            );
          }
        })
        .catch((err) => {
          dispatch(
            setAlertMsg({
              msg: err.response.data?.message,
              alertType: "danger",
            })
          );
        });
    }
  }, [isForm]);

  //  submit maker changes
  const handleMakerChangeSubmit = () => {
    let blankValue = isMandatoryValues.find(
      (e) =>
        corpFormDetails[e.accessor] === "" ||
        (validateInputField[e.accessor] != undefined &&
          validateInputField[e.accessor] != "")
    );

    if (blankValue != null || blankValue != undefined) {
      dispatch(
        setAlertMsg({
          msg: `Please Fill ${blankValue.label}`,
          alertType: "danger",
        })
      );

      return false;
    }

    let emailId = allRMUser.filter(
      (user) => user?.email === formChangeDetails["rmEmail"]
    );

    let obj = formChangeDetails.hasOwnProperty("rmEmail")
      ? { ...formChangeDetails, rm_id: emailId[0]?.id }
      : formChangeDetails;

    makerCheckerFlow(masterId, CorpId, obj)
      .then((res) => {
        if (res.data.success === true) {
          dispatch(
            setAlertMsg({
              msg:
                isWorkflowEnabled === true
                  ? t("alertMsg.changes_for_approval")
                  : t("alertMsg.edit_successfully"),
              alertType: "success",
            })
          );
          setIsForm(false);
          handleMasterDataSearch();
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: `Fail to Edit`,
            alertType: "danger",
          })
        );
      });
  };
  //  assign and unassign maker
  const editClaimUnclaimFunction = (rowId, isReleased) => {
    editClaimUnclaim(masterId, rowId)
      .then((res) => {
        if (isReleased == false) {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.task_claimed"),
              alertType: "success",
            })
          );
          setIsForm(true);
          setIsEdit(false);
        } else {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.task_unclaimed"),
              alertType: "success",
            })
          );
          handleMasterDataSearch();
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.task_claim_fail"),
            alertType: "danger",
          })
        );
      });
  };

  var closeBtnStyle = {
    backgroundColor: "rgb(255, 255, 255)",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    top: "-15px",
    justifyContent: "center",
    right: "-18px",
    cursor: "pointer",
    borderRadius: "50%",
  };

  const disabledValFunction = (data, accessor) => {
    let disabledFieldArray = [
      "prodUser",
      "saleUser",
      "panNumber",
      "gstNo",
      "isPopShift",
      "cinNumber",
      "popChngChoNum",
      "craChargesBorneBy",
      "nodalOfficerName",
      "nodalOfficerPhone",
      "nodalOfficerEmail",
      "altNodalOfficerName",
      "altNodalOfficerPhone",
      "altNodalOfficerEmail",
      "corpRegDate",
      "retirementAge",
      "schemePreference",
      "pfmSelection",
      "investmentOption",
      "autoChoiceLc",
      "fundA",
      "fundC",
      "fundG",
      "fundE",
      "registrationLink",
      "docUploaded",
      "docVerifiedMark",
      "rmPhone",
      "zonalManager",
      "hierarchy",
      "rmZone",
      "craName",
      "rmName",
      "corpNpsRegDate",
    ];
    if (
      (corpFormDetails["investmentOption"]?.toLowerCase() === "auto" &&
        (accessor === "fundE" ||
          accessor === "fundC" ||
          accessor === "fundG" ||
          accessor === "fundA")) ||
      disabledFieldArray.includes(accessor)
    ) {
      return true;
    }
    if (
      corpFormDetails["investmentOption"]?.toLowerCase() === "active" &&
      accessor === "autoChoiceLc"
    ) {
      return true;
    }
  };
  const handleDropDownOption = (name, options) => {
    let userArray = allRMUser.map((user) => user?.email);
    if (name === "rmEmail") {
      return userArray;
    }
    return options;
  };
  return (
    <>
      <CustomTable
        page={page}
        setPage={setPage}
        headings={corporateTableColumns}
        data={tableData}
        pageData={pageData}
        sort={sort}
        setSort={setSort}
        accessor={accessor}
        setAccessor={setAccessor}
        srNO={true}
      />
      {/* clicked corp include checker flow */}
      {isEdit == true && isWorkflowEnabled == true && (
        <Modal>
          <div className={classes.editModal_container}>
            <h4>
              <span>
                <img src={PenEditIcon} alt="pen edit Master management" />
              </span>{" "}
              {t("proceed.edit.master")}
            </h4>
            <p>{t("proceed.edit.master.text")}</p>
            <div className={classes.editModal_btn}>
              <PrimaryButton
                onClick={() => {
                  // handleAssignMaker(CorpId);
                  editClaimUnclaimFunction(CorpId, false);
                }}
                label={t("button.proceed")}
              />
              <SecondaryButton
                onClick={() => {
                  setIsEdit(false);
                }}
                label={t("button.close")}
              />
            </div>
          </div>
        </Modal>
      )}

      {isForm && (
        <Modal
          closeBtn={closeBtnStyle}
          onClose={() => {
            handleMasterDataSearch();
            setCurrentStep(0);
            setIsForm(false);
          }}
        >
          <div
            style={{ backgroundColor: "white" }}
            className={classes.form_container + " "}
          >
            <div className="row">
              <div className="col-lg-12">
                <div className={classes.form_modal_title}>
                  <h5>{t("edit.corporate.master")}</h5>
                  <p>
                    *Modification to these master, will trigger maker checker
                    worker flow
                  </p>
                </div>
              </div>
            </div>
            <div className="row d-flex ">
              <div className="col-lg-4 col-md-3 col-3 mt-4 pt-3">
                {formObj.map((data, index) => {
                  return (
                    <p
                      className={` ${
                        index === currentStep
                          ? classes.active
                          : classes.unactive
                      }
                      `}
                      onClick={() => setCurrentStep(index)}
                      key={index}
                    >
                      {data.header}
                    </p>
                  );
                })}
              </div>
              <div className="col-lg-8 col-md-9 col-9">
                <ObjForm
                  corpFormDetails={corpFormDetails}
                  setFormDetails={setCorpFormDetails}
                  currentStep={currentStep}
                  formObj={formObj}
                  handleChange={handleChange}
                  setFormChangeDetails={setFormChangeDetails}
                  validateString={validateInputField}
                  disabledValFunction={disabledValFunction}
                  handleDropDownOption={handleDropDownOption}
                />

                <div className={classes.edit_form_btn}>
                  {currentStep === 6 && (
                    <PrimaryButton
                      disabled={
                        Object.keys(formChangeDetails).length === 0
                          ? true
                          : false
                      }
                      onClick={handleMakerChangeSubmit}
                      label={t("button.submit")}
                    />
                  )}
                  <SecondaryButton
                    onClick={() => {
                      handleMasterDataSearch();
                      setIsForm(false);
                    }}
                    label={t("button.cancel")}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CorporateManagement;
