import React, { useState } from "react";
import classes from "./OSV.module.css";
import { useTranslation } from "react-i18next";
import ArrowUPIcon from "../../../assets/TableDashboard/ArrowUp.svg";
import OverdueIcon from "../../../assets/TableDashboard/overdue.svg";
import TimelimitIcon from "../../../assets/TableDashboard/Timelimit.svg";
import ClockIcon from "../../../assets/TableDashboard/time.svg";
import CheckMarkIcon from "../../../assets/TableDashboard/checkmark.svg";
import TableDashboard from "../../../components/TableDashboard/TableDashboard";
import SwitchToggle from "../../../components/SwitchToggle/SwitchToggle";

const OSV = () => {
  const columnRotations = "ackIdasc";
  const { t } = useTranslation();
  const [pranTypeBoolean, setPranTypeBoolean] = useState(true);
  const [regType, setRegType] = useState("2");

  const columns = [
    {
      header: (
        <p className={classes.headers}>
          ACKNOWLEDGEMENT Id
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "ackIdasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            src={ArrowUPIcon}
            alt='icon'
          />
        </p>
      ),
    },
    {
      header: (
        <p className={classes.headers}>
          NAME
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "corpNameasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            src={ArrowUPIcon}
            alt='icon'
          />
        </p>
      ),
      accessor: (e) => e.corpName,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 20 },
    },
    {
      header: (
        <p className={classes.headers}>
          TAT
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "tatasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            src={ArrowUPIcon}
            alt='icon'
          />
        </p>
      ),
    },
    {
      header: <p className={classes.headers}>STATUS</p>,
      key: "corpStatus",
      accessor: (e) => (
        <p className={classes.state_container}>{e.corpStatus?.name}</p>
      ),
    },
    {
      header: (
        <p className={classes.headers}>
          PENDING WITH
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "currentAssignedUser.usernameasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            src={ArrowUPIcon}
            alt='icon'
          />
        </p>
      ),
      key: "pendingWith",
      accessor: (e) => (
        <p className={classes.state_pending}>
          {e?.currentAssignedUser && e?.corpStatus?.name !== "Onboarded"
            ? e?.currentAssignedUser.username
            : "NA"}
        </p>
      ),
    },
    {
      header: <p className={classes.headers_action}>ACTION</p>,
      key: "corpAction",
    },
  ];

  const tabsLabel = [
    {
      title: ` All (20)`,
      key: "",
    },
    {
      title: `${t("text.pending")} (20)`,
      key: "pending",
    },
    {
      title: `${t("text.rejected")} (20)`,
      key: "rejected",
    },
    {
      title: `${t("text.tatexceeded")} (20)`,
      key: "tatexceeded",
    },
  ];

  const tableStrings = {
    title: "PRAN Journey - Genpact",
    subTitle: "Explore all subscriber applications",
    listAll_title: "PRAN Registrations Applications",
    search_placeholder: "Search Name/Acknowledgement Id",
  };

  const analyticCard = [
    { icon: ClockIcon, title: t("text.pending"), count: 78 },
    {
      icon: CheckMarkIcon,
      title: "OSV completed",
      count: 45,
    },
    {
      icon: OverdueIcon,
      title: t("text.futherRequest"),
      count: 20,
    },
    {
      icon: TimelimitIcon,
      title: t("text.overdue"),
      count: 33,
    },
  ];

  const pranStatusDOM = () => {
    return (
      <div className={classes.status}>
        <div className={classes.statusBox}>
          <div
            className={`${classes.statusName} ${
              !pranTypeBoolean ? classes.active : ""
            }`}
            onClick={() => {
              setRegType("2");
              setPranTypeBoolean(!pranTypeBoolean);
            }}>
            Registration
          </div>
          <SwitchToggle
            bgColor={"var(--app-btn-background)"}
            status={pranTypeBoolean}
            otherFunction={() => {
              setPranTypeBoolean(!pranTypeBoolean);
              pranTypeBoolean ? setRegType("2") : setRegType("1");
            }}
          />
          <div
            className={`${classes.statusName} ${
              pranTypeBoolean ? classes.active : ""
            }`}
            onClick={() => {
              setRegType("1");
              setPranTypeBoolean(!pranTypeBoolean);
            }}>
            Migration
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <TableDashboard
        tabsLabel={tabsLabel}
        columns={columns}
        analyticCard={analyticCard}
        tableStrings={tableStrings}
        isAccordionOff={true}
        isPranType={pranStatusDOM()}
        isOsv={true}
      />
    </div>
  );
};

export default OSV;
