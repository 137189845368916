import React, { memo, useEffect, useState } from "react";
import Classes from "./FunctinalityManagement.module.css";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import {
  addFunctionalAreaToInternalGrpRole,
  functionalareasByGrpRole,
  getAllGroups,
  getAllUsers,
  getFunctionalityDesignation,
  getRolesViaGroup,
} from "./FunctionalityManagementActions";
import Utils from "../../../Utils";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../Home/HomeSlice";
import { useTranslation } from "react-i18next";
import { ADMIN_URL, AUTHMANAGEMENT_URL, BASE_URL } from "../../../Routes/Urls";
import { useNavigate } from "react-router";

const FunctionalityManagement = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [functionalityDesignation, setFunctionalityDesignation] = useState([]);

  const [grpId, setGrpId] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [functionalityAreasBox, setFunctionalityAreasBox] = useState([]);
  const [internalGrpRoleId, setInternalGrpRoleId] = useState();

  // department form elements
  const [deptFormElem, setDeptFormElem] = useState({
    department: {
      label: "Department",
      name: "department",
      options: [],
      optionName: "groupName",
    },

    designation: {
      label: "Designation",
      name: "designation",
      options: [],
      optionName: "roleName",
    },
  });

  useEffect(() => {
    getFunctionalityDesignation()
      .then((res) => {
        setFunctionalityDesignation(res.data.data);
      })
      .catch((err) => {});
    getDepartment();
  }, []);

  // fetch department
  const getDepartment = () => {
    getAllGroups().then((res) => {
      let result = res?.data?.data;
      setDeptFormElem((prevState) => ({
        ...prevState,
        department: {
          ...prevState.department,
          options: result,
        },
      }));
    });
  };

  // get designations by group
  const getDesignation = (id) => {
    if (id === null || id === "") {
      return;
    }
    getRolesViaGroup(id).then((res) => {
      let result = res?.data?.data;
      setDeptFormElem((prevState) => ({
        ...prevState,
        designation: {
          ...prevState.designation,
          options: result,
        },
      }));
    });
  };

  useEffect(() => {
    if (roleId != null) {
      getFunctionalArea(grpId, roleId);
    }
  }, [roleId]);

  // get functional areas by groupId and userId
  const getFunctionalArea = (group, role) => {
    functionalareasByGrpRole(group, role)
      .then((res) => {
        if (res.data?.success) {
          let resData = res.data?.data;
          setInternalGrpRoleId(resData?.id);
          let newArr = resData?.functionalAreas?.map((e) => e.id);
          setFunctionalityAreasBox(newArr);
        } else {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.internal_server_error"),
              alertType: "danger",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.internal_server_error"),
            alertType: "danger",
          })
        );
      });
  };

  // select deptartment and designation
  const deptDesgFunction = (e) => {
    if (e.target.name === deptFormElem.department.name) {
      setFunctionalityAreasBox([]);
      document.getElementById(`${deptFormElem.designation.name}`).value = "";
      setGrpId(e.target.value);
    } else if (e.target.name === deptFormElem.designation.name) {
      setRoleId(e.target.value);
    }
  };

  useEffect(() => {
    getDesignation(grpId);
  }, [grpId]);

  // check uncheck designation
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    let val = Number(name);
    setFunctionalityAreasBox((prevFunctionalityAreasBox) => {
      prevFunctionalityAreasBox = prevFunctionalityAreasBox || [];

      if (checked) {
        if (!prevFunctionalityAreasBox.includes(val)) {
          return [...prevFunctionalityAreasBox, val];
        }
      } else {
        return prevFunctionalityAreasBox.filter((item) => item !== val);
      }
      return prevFunctionalityAreasBox;
    });
  };

  // check the functionality of check uncheck
  const checkedFunctionality = (e) => {
    if (functionalityAreasBox?.length > 0) {
      let res = functionalityAreasBox.includes(e);
      if (res) {
        return true;
      }
      return false;
    }
    return false;
  };

  // submit form
  const handleSubmitForm = () => {
    addFunctionalAreaToInternalGrpRole(internalGrpRoleId, functionalityAreasBox)
      .then((res) => {
        if (res.data?.success) {
          dispatch(
            setAlertMsg({
              msg: res.data?.message,
              alertType: "success",
            })
          );
          navigate(`/${BASE_URL}/${ADMIN_URL}/${AUTHMANAGEMENT_URL}`);
        } else {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.internal_server_error"),
              alertType: "danger",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.internal_server_error"),
            alertType: "danger",
          })
        );
      });
  };

  // redirect to dashboard
  const redirectToDashboard = () => {
    navigate(`/${BASE_URL}/${ADMIN_URL}/${AUTHMANAGEMENT_URL}`);
  };

  return (
    <section>
      <div className="contain-fluid p-5">
        <GoBackButton />

        <div className="col-lg-12 mb-4">
          <div className={Classes.page_head}>
            <h5>Functionality Management </h5>
          </div>
        </div>
        <div className={`card border-0 ${Classes.radius_10}`}>
          <div className="card-body p-lg-5 p-3">
            <div className="row mb-4" id={Classes.select_box}>
              {deptFormElem &&
                Object.keys(deptFormElem).map((data, index) => {
                  return (
                    <div className="col-lg-5 col-md-6 col-12 mb-4" key={index}>
                      <div className="input_group">
                        <label className={Classes.form_label} htmlFor="group">
                          {deptFormElem[data].label}
                        </label>
                        {
                          <>
                            <select
                              name={deptFormElem[data].name}
                              className="form-control form-select"
                              id={deptFormElem[data].name}
                              // defaultValue={""}
                              onChange={(e) => deptDesgFunction(e)}
                            >
                              <option value="">
                                Select {deptFormElem[data]?.label}
                              </option>
                              {deptFormElem[data]?.options &&
                                deptFormElem[data]?.options.map(
                                  (subData, subIndex) => {
                                    return (
                                      <option
                                        value={subData?.id}
                                        key={subIndex}
                                      >
                                        {
                                          subData[
                                            deptFormElem[data]?.optionName
                                          ]
                                        }
                                      </option>
                                    );
                                  }
                                )}
                            </select>
                          </>
                        }
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className="row pt-2 pb-4">
              <div className="col-lg-12">
                <div className={Classes.list_head}>
                  <h4>Functionality for Designation</h4>
                  <p>
                    (You can add / remove functionality for this user)
                    <span>
                      ({functionalityAreasBox && functionalityAreasBox?.length}{" "}
                      selected)
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className={"row mb-4" + " " + Classes.list_checkbox}>
              {functionalityDesignation &&
                functionalityDesignation?.map((data, index) => {
                  return (
                    <div className="col-lg-4 col-md-6 col-12 mb-4" key={index}>
                      <div className={`form-check `}>
                        <input
                          className={`check_input ${Classes.pointer}`}
                          type="checkbox"
                          checked={checkedFunctionality(data.id)}
                          name={data.id}
                          // checked={checkboxes.checkbox1}
                          onChange={handleCheckboxChange}
                          id={Utils.stringSpacetoSpecialChar(data.uiString)}
                        />
                        <label
                          className={`form-check-label ${Classes.pointer}`}
                          htmlFor={Utils.stringSpacetoSpecialChar(
                            data.uiString
                          )}
                        >
                          {data.name}
                        </label>
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className="row px-4 mt-4">
              <div className="button_group mr-4">
                <SecondaryButton
                  onClick={redirectToDashboard}
                  label={"Cancel"}
                />
              </div>

              <div className="button_group">
                <PrimaryButton
                  disabled={!internalGrpRoleId}
                  onClick={handleSubmitForm}
                  label={"Save"}
                />
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
      {/* </div> */}
    </section>
  );
};

export default FunctionalityManagement;
