import React from "react";
import Dashboard from "../../components/Dashboard/Dashboard";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import FurtherRequirementImg from "../../assets/CardsImgs/FurtherRequest.png";
import ServiceRequestImg from "../../assets/CardsImgs/ServiceRequest.png";
import ContributionImg from "../../assets/CardsImgs/ContributionImg.png";
import CorporateOnboardingImg from "../../assets/CardsImgs/CorporateForm.png";
import PranJourneyImg from "../../assets/CardsImgs/PranJourney.png";
import { BASE_URL, Pran_Journey_URL, RMDashboard_URL } from "../../Routes/Urls";

const RMDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const rmDashboardCard = [
    {
      path: FurtherRequirementImg,
      label: t("card.CorporateOnboarding"),
      img: CorporateOnboardingImg,
    },
    {
      path: Pran_Journey_URL,
      label: t("card.PranJourney"),
      img: PranJourneyImg,
    },
    {
      path: FurtherRequirementImg,
      label: t("card.furtherRequirement"),
      img: FurtherRequirementImg,
    },
    {
      path: ServiceRequestImg,
      label: t("card.serviceRequest"),
      img: ServiceRequestImg,
    },
    {
      path: ContributionImg,
      label: t("card.contribution"),
      img: ContributionImg,
    },
  ];

  const handleCardClick = (path) => {
    navigate(`/${BASE_URL}/${RMDashboard_URL}/${path}`);
  };
  return (
    <div>
      <Dashboard
        cardsObj={rmDashboardCard}
        handleCardClick={handleCardClick}
        title={t("text.rmDashboard")}
      />
    </div>
  );
};

export default RMDashboard;
