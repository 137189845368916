import React, { useEffect, useState } from "react";
import Classes from "./NomineeBox.module.css";
import RangeInput from "../../../../../components/RangeInput/RangeInput";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import Utils from "../../../../../Utils";

const NomineeDetails = ({ setPreviewData, setIsProceed, previewData }) => {
  const [nomineeCount, setNomineeCount] = useState(1);
  const [nomineeData, setNomineeData] = useState({});
  const [nomineeForm, setNomineeForm] = useState([
    [
      {
        tag: "input",
        type: "text",
        name: "nomineeName",
        label: "Nominee Name",
        isMandatory: true,
        placeholder: "nominee name",
        bottomInput: "Write Name as per official records",
      },
      {
        tag: "input",
        type: "date",
        name: "nomineeDob",
        label: "Date of Birth",
        isMandatory: true,
        bottomInput: "Select DOB of the nominee as per official records",
      },
    ],
    [
      {
        label: "Relationship with Nominee",
        type: "radio",
        isMandatory: true,
        options: [
          {
            label: "Son",
            type: "radio",
            name: "nomineeRelation",
            value: "Son",
          },
          {
            label: "Daughter",
            type: "radio",
            name: "nomineeRelation",
            value: "Daughter",
          },
          {
            label: "Spouse",
            type: "radio",
            name: "nomineeRelation",
            value: "Spouse",
          },
          {
            label: "Father",
            type: "radio",
            name: "nomineeRelation",
            value: "Father",
          },
          {
            label: "Mother",
            type: "radio",
            name: "nomineeRelation",
            value: "Mother",
          },
        ],
      },
    ],
    [
      {
        label: "Total share of Nominee",
        type: "range",
        isMandatory: true,
        name: "nomineeShare",
      },
    ],
  ]);
  const [usedShare, setUsedShare] = useState(0);
  useEffect(() => {
    if (
      previewData?.subFormData != undefined &&
      previewData?.subFormData != null
    ) {
      setNomineeData(previewData?.subFormData);
      const filteredData = Object.keys(previewData?.subFormData)?.filter(
        (prop) =>
          prop.startsWith("nomineeName")
      );
      setNomineeCount(filteredData?.length || 1);
      let isSuccess = checkSum(
        previewData?.subFormData,
        filteredData?.length || 1
      );
      setIsProceed(!isSuccess);
    } else {
      setIsProceed(true);
    }
  }, []);
  const showNomineeButton = (nomineeIndex, rootIndex) => {
    return nomineeCount === rootIndex && nomineeIndex === 2 && nomineeCount < 3
      ? true
      : false;
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    const filteredNomineeObj = Object.keys(nomineeData).reduce(
      (result, key) => {
        if (key.includes("nomineeShare") && key !== name) {
          result[key] = Number(nomineeData[key]);
        }
        return result;
      },
      {}
    );
    const values = Object.values(filteredNomineeObj);
    const sum = values.reduce((acc, value) => Number(acc) + Number(value), 0);
    const newValue = Number(value);

    setNomineeData((data) => ({
      ...data,
      [name]: String(
        Number(newValue) > 100 - Number(sum)
          ? 100 - Number(sum)
          : Number(newValue)
      ),
    }));
  };

  // handle change input
  const handleChangeInput = (e) => {
    e.preventDefault();
    let { name, value, type } = e.target;
    setNomineeData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const selectedLabel = (radioData, index) => {
    return nomineeData[radioData?.name + index] === radioData.value;
  };
  const seriesWiseLabel = (data, index) => {
    if (data?.name === "nomineeName") {
      switch (index) {
        case 1:
          return "First";
        case 2:
          return "Second";
        case 3:
          return "Third";
        default:
          break;
      }
    }
  };

  useEffect(() => {
    let obj = {
      subFormData: nomineeData,
    };
    setPreviewData(obj);
    let isSuccess = checkSum(nomineeData, nomineeCount);
    setIsProceed(!isSuccess);
  }, [nomineeData]);

  //  check sum function
  const checkSum = (data, len) => {
    let sum = 0;
    for (let i = 1; i <= len; i++) {
      sum += Number(data[`nomineeShare${i}`]);
    }
    return sum === 100;
  };

  //  max date of nominee
  const maxDateFunction = () => {
    const date = new Date();
    return Utils.convertToLocalDateForInput(date);
  };
  const renderTable = () => {
    const elements = [];

    for (let index = 1; index <= nomineeCount; index++) {
      elements.push(
        nomineeForm?.map((data, nomineeIndex) => {
          return (
            <div
              className={`row  ${
                nomineeForm?.length - 1 === nomineeIndex
                  ? Classes.lastRowBorder
                  : "mb-3"
              }`}
              key={nomineeIndex}
            >
              {data?.map((subData, subIndex) => {
                return (
                  <div className="col-lg-6" key={subIndex}>
                    {subData?.type != "radio" && subData?.type != "range" && (
                      <div className="form_group">
                        <label
                          htmlFor={subData?.name}
                          className={Classes.main_label}
                        >
                          {seriesWiseLabel(subData, index)} {subData.label}{" "}
                          {subData?.isMandatory && (
                            <span className={Classes.mandatory}>*</span>
                          )}{" "}
                        </label>
                        {subData?.tag === "input" && (
                          <input
                            type={subData.type}
                            id={subData?.name}
                            name={subData?.name + index}
                            value={nomineeData[subData?.name + index] || ""}
                            max={subData?.type === "date" && maxDateFunction()}
                            placeholder={
                              subData?.type != "date" &&
                              seriesWiseLabel(subData, index) +
                                " " +
                                subData?.placeholder
                            }
                            onInput={handleChangeInput}
                            className="form-control"
                          />
                        )}
                      </div>
                    )}
                    {subData?.type === "radio" && (
                      <div className="form_group">
                        <label
                          htmlFor={subData?.name}
                          className={Classes.main_label}
                        >
                          {subData.label}{" "}
                          {subData?.isMandatory && (
                            <span className={Classes.mandatory}>*</span>
                          )}
                        </label>
                        <div className={Classes.radioCollection}>
                          {subData?.options?.map((radioData, radioIndex) => {
                            return (
                              <label
                                htmlFor={`${radioData?.value}_${index}_${radioIndex}`}
                                className={`${Classes.radioBtns}  ${
                                  selectedLabel(radioData, index)
                                    ? Classes.activeLabel
                                    : ""
                                }  `}
                                key={radioIndex}
                              >
                                <input
                                  type={radioData?.type}
                                  id={`${radioData?.value}_${index}_${radioIndex}`}
                                  name={radioData?.name + index}
                                  value={radioData?.value}
                                  checked={selectedLabel(radioData, index)}
                                  onChange={handleChangeInput}
                                />{" "}
                                <span>{radioData?.label}</span>
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    {subData?.type === "range" && (
                      <div className="form_group">
                        <label htmlFor={subData?.share}>
                          {subData?.label}{" "}
                          {subData?.isMandatory && (
                            <span className={Classes.mandatory}>*</span>
                          )}
                        </label>
                        <div className={Classes.rangeInputWidth}>
                          <RangeInput
                            title={"Total share of Nominee"}
                            range={"Max - 100"}
                            value={
                              Number(nomineeData[subData?.name + index]) || 0
                            }
                            setValue={handleChange}
                            max={100}
                            color={"var(--app-pie-chart-1st)"}
                            name={subData?.name + index}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}

              {showNomineeButton(nomineeIndex, index) && (
                <div className="col-lg-6">
                  <div className={Classes.addAnotherNominee}>
                    <SecondaryButton
                      label={"Add Another Nominee"}
                      onClick={() =>
                        nomineeCount < 3
                          ? setNomineeCount((prev) => prev + 1)
                          : null
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })
      );
    }

    return elements;
  };

  return <div className={Classes.nomineeBox}>{renderTable()}</div>;
};
export default NomineeDetails;
