import React, { useEffect } from "react";
import classes from "./LoginForm.module.css";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TextButton from "../../../components/Buttons/TextButton";
import { loginUser, sendOTPtoNumber, validateMobileOTP } from "../AuthActions";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../Home/HomeSlice";
import Utils from "../../../Utils";
import Login from "../../../assets/Login_Img.png";
import Captcha from "../../../components/Captcha/Captcha";
import { getCaptcha } from "../../Home/HomeAction";

const OtpLogin = ({
  handleOtpLogin,
  setIsOtpLogin,
  setCaptchaInput,
  captchaInput,
  setCaptchaKey,
  captchaKey,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [phone, setPhone] = useState();
  const [otp, setOtp] = useState("");
  const [isTimerEnd, setIsTimerEnd] = useState(false);

  const handleSendOTP = (e) => {
    e.preventDefault();
    let mobileObj = {
      phoneNumber: `${phone}`,
      requestType: "login",
      captchaValue: captchaInput,
      captchaKey: captchaKey,
    };

    sendOTPtoNumber(mobileObj)
      .then((res) => {
        if (res?.data?.success) {
          dispatch(
            setAlertMsg({
              msg: res?.data?.message,
              alertType: "success",
            })
          );
          setIsOtpSend(true);
          setIsTimerEnd(false);
        } else if (!res?.response?.data?.success) {
          dispatch(
            setAlertMsg({
              msg: res?.response?.data?.message,
              alertType: "danger",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({ msg: err.response.message, alertType: "danger" })
        );
      });
  };

  const handleValidateOTP = (e) => {
    e.preventDefault();
    let obj = {
      phoneNumber: `${phone}`,
      otp: `${otp}`,
      loginType: "otp",
      requestType: "login",
      captchaValue: captchaInput,
      captchaKey: captchaKey,
    };
    handleOtpLogin(obj);
  };

  useEffect(() => {
    let interval;
    if (isOtpSend) {
      interval = setInterval(() => {
        setIsTimerEnd(true);
      }, 30000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isOtpSend]);

  //  check button disable
  const mobileValidate = () => {
    if (
      phone?.length > 0 &&
      Utils.validateContact(phone)?.valid &&
      captchaInput?.length > 0
    ) {
      return false;
    }

    return true;
  };

  return isOtpSend ? (
    <form className={classes.form_half_page} onSubmit={handleValidateOTP}>
      <div className={classes.loginForm} autoComplete="on">
        <div className={classes.loginForm_otp_title}>
          <h2>OTP Authentication</h2>
          <h4>Please Authenticate Your Account By Entering</h4>
          <h4>
            Authorized Code Sent On Number +91
            {Utils.partialHiddenContact(phone)}
          </h4>
        </div>
        <div className={classes.otp_input_fields}>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span> </span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>
        <div className={classes.otp_loginForm_title}>
          <h4>Didn't receive the code ?</h4>
          <p
            className={
              isTimerEnd ? classes.resendButton : classes.resendButtonDisabled
            }
            onClick={(e) => (isTimerEnd ? handleSendOTP(e) : "")}
          >
            Resend code
          </p>
        </div>
        <div className={classes["otp-submit-button"]}>
          <PrimaryButton
            style={{ width: "18rem", height: "2.8rem" }}
            label={"Authenticate"}
            disabled={otp?.length < 6}
            type="submit"
          />
          <SecondaryButton
            style={{ width: "18rem", height: "2.8rem" }}
            onClick={() => {
              setIsOtpLogin(false);
              setCaptchaInput("");
            }}
            label={"cancel"}
            type="Submit"
          />
        </div>
        <div className={classes["post-submit-button"]}></div>
      </div>
    </form>
  ) : (
    <form className={classes.form_half_page} onSubmit={handleSendOTP}>
      <div className={classes.loginForm} autoComplete="on">
        <div className={classes.loginForm_container}>
          <div className={classes.loginForm_title}>
            <h2>Login</h2>
            <h4>Welcome back! Please login to your account</h4>
          </div>
          <div className={classes.input_fields}>
            <label htmlFor="email">
              Enter register Mobile number
              <input
                type="text"
                placeholder="9876543210"
                autoFocus={true}
                className={classes["form-field"]}
                value={phone}
                onInput={(e) => setPhone(Utils.PreventSpecialCharacter(e))}
              />
              {phone && (
                <span className={classes.spanValidateText}>
                  {Utils.validateContact(phone)?.message}
                </span>
              )}
            </label>

            <Captcha
              value={captchaInput}
              onchange={(e) => setCaptchaInput(e.target.value)}
              setCaptchaInput={setCaptchaInput}
              setCaptchaKey={setCaptchaKey}
            />

            <div className={classes.forgot_password}>
              <p>
                or login with
                <span
                  onClick={() => {
                    setIsOtpLogin(false);
                    setCaptchaInput("");
                  }}
                >
                  Email
                </span>
              </p>
            </div>
            <PrimaryButton
              style={{
                width: "100%",
                height: "2.8rem",
                marginTop: "1rem",
              }}
              type="submit"
              disabled={mobileValidate()}
              label={t("text.generateOtp")}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default OtpLogin;
