import React, { useState } from "react";
import Classes from "./TimeLineRoadMapModal.module.css";
import WorkFlowRoadMap from "../../../../components/WorkFlowRoadMap/WorkFlowRoadMap";
import Modal from "../../../../components/Modal/Modal";
import downloadIcon from "../../../../assets/download_icon.svg";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import { useEffect } from "react";
import { memo } from "react";
import Consts from "../../../../Consts";
import {
  corporateSendReminder,
  downloadZipDoc,
  getSingleCorpExportCSV,
} from "../../CorporateAction";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../../Home/HomeSlice";
import { useTranslation } from "react-i18next";
import Utils from "../../../../Utils";

const TimeLineRoadMapModal = ({
  isModal,
  setIsModal,
  data,
  corporateData,
  handlePrviewForm,
}) => {
  const { t } = useTranslation();
  const [corpDetailsState, setCorpDetailsState] = useState({
    ackId: {
      name: "ACKNOWLEDGEMENT NUMBER :",
      body: "",
    },
    pendingWith: {
      name: "PENDING WITH :",
      body: "",
    },
    corpName: {
      name: "CORPORATE NAME :",
      body: "",
    },
    priority: {
      name: "PRIORITY :",
      body: "",
    },
    tat1: {
      name: "TAT1 :",
      body: "",
    },
    tat2: {
      name: "TAT2 :",
      body: "",
    },
    rm: {
      name: "RELATIONSHIP MANAGER :",
      body: "",
    },
  });

  const dispatch = useDispatch();
  const [corpProcessId, setCorpProcessId] = useState("");
  const [flowStatus, setFlowStatus] = useState({});

  useEffect(() => {
    setCorpProcessId(corporateData?.processState?.id);
    setCorpDetailsState((prevState) => ({
      ...prevState,
      ackId: {
        ...prevState.ackId,
        body: corporateData?.ackId,
      },
      pendingWith: {
        ...prevState.pendingWith,
        body: corporateData?.currentAssignedUser?.username,
      },
      corpName: {
        ...prevState.corpName,
        body: corporateData?.corpName,
      },
      priority: {
        ...prevState.priority,
        body: getPriorityFunction(corporateData.tat2),
      },
      tat1: {
        ...prevState.tat1,
        body: `${corporateData?.tat} ${
          corporateData?.tat > 1 ? "Days" : "Day"
        }`,
      },
      tat2: {
        ...prevState.tat2,
        body: `${corporateData?.tat2} ${
          corporateData?.tat2 > 1 ? "Days" : "Day"
        }`,
      },
      rm: {
        ...prevState.rm,
        body: corporateData?.submitter?.username,
      },
    }));

    setFlowStatus(corporateData?.corpStatus);
  }, [corporateData]);

  const getPriorityFunction = (e) => {
    if (e >= 15 && e < 30) {
      return <span className={Classes.redText}> Medium</span>;
    } else if (e > 30) {
      return <span className={Classes.redText}> high</span>;
    }
    return <span> low</span>;
  };

  var closeBtnStyle = {
    backgroundColor: "rgb(255, 255, 255)",
    width: "2.5rem",
    height: "2.5rem",
    display: "flex",
    alignItems: "center",
    top: "-0.938rem",
    justifyContent: "center",
    right: "-1.125rem",
    cursor: "pointer",
    zIndex: 9,
    borderRadius: "50%",
  };

  const handleSendReminder = () => {
    corporateSendReminder(corporateData.id)
      .then((res) => {
        if (res.data.success === true) {
          dispatch(
            setAlertMsg({
              msg: "Reminder sent successfully",
              alertType: "success",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setAlertMsg({
            msg: error?.response?.data?.message,
            alertType: "danger",
          })
        );
      });
    setIsModal(false);
  };

  const handleExportCSV = () => {
    getSingleCorpExportCSV(corporateData?.id);
  };

  //  download corporate related doc
  const handleZipDownload = () => {
    downloadZipDoc(corporateData?.id)
      .then((res) => {
        if (res.status === 200) {
          const fileUrl = URL.createObjectURL(res.data);
          window.open(fileUrl, "_blank", "noopener,noreferrer");
        }
      })
      .catch((error) => {
        Utils.blobReader(error.response.data).then((res) =>
          dispatch(
            setAlertMsg({
              msg: JSON.parse(res)?.message,
              alertType: "danger",
            })
          )
        );
      });
  };

  return (
    isModal && (
      <Modal closeBtn={closeBtnStyle} onClose={() => setIsModal(false)}>
        <div className={Classes.roadMapModalBox}>
          <div className={Classes.timelineHeaders}>
            <div className={`${Classes.flexHeader} `}>
              <div className={Classes.leftSide}>
                <h3>Workflow Timeline</h3>
                <p>Corporate registration workflow time line</p>
              </div>
              <div className={Classes.rightSide}>
                <PrimaryButton
                  style={{ marginRight: "0.75rem" }}
                  onClick={handleSendReminder}
                  label={t("button.sendReminder")}
                />
                <SecondaryButton
                  onClick={handleExportCSV}
                  label={t("button.exportCSV")}
                />
                {corpProcessId != 1 && corpProcessId != 2 && (
                  <SecondaryButton
                    style={{ marginLeft: "0.75rem" }}
                    label={"Download Documents"}
                    onClick={handleZipDownload}
                  />
                )}
                {corpProcessId != 1 && (
                  <SecondaryButton
                    style={{ marginLeft: "0.75rem" }}
                    label={"Download CHO Form"}
                    onClick={() => handlePrviewForm(corporateData?.ackId)}
                  />
                )}
              </div>
            </div>

            <div className={Classes.timelineBody}>
              <div className={Classes.flexBody}>
                <div className={Classes.leftSide}>
                  <div className={Classes.gridClass}>
                    {corpDetailsState &&
                      Object.keys(corpDetailsState).map((data, index) => {
                        return (
                          <div className={Classes.eachColumn} key={index}>
                            <p className={Classes.itemHead}>
                              {corpDetailsState[data]?.name}
                            </p>
                            <p className={Classes.itemBody}>
                              {" "}
                              {corpDetailsState[data]?.body || `NA`}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className={Classes.rightSide}>
                  <div className={Classes.statusBox}>
                    <p>status</p>
                    <div
                      className={
                        flowStatus?.id ===
                        Consts.CORPORATE_ONBOARD_STATUSCODE.onboarded
                          ? `${Classes.status + " " + Classes.onboarded}`
                          : `${Classes.status + " " + Classes.pending}`
                      }
                    >
                      {flowStatus?.name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <WorkFlowRoadMap data={data} />
        </div>
      </Modal>
    )
  );
};

export default memo(TimeLineRoadMapModal);
