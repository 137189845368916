import React from "react";
import Classes from "./Select.module.css";

const Select = ({
  dataObj,
  onChangeHandler,
  selectedData,
  pageChange,
  disabled,
}) => {
  return (
    <select
      disabled={disabled}
      className={Classes.select_container}
      value={selectedData}
      onChange={(e) => {
        onChangeHandler(e.target.value);
        pageChange(0);
      }}
    >
      {dataObj &&
        dataObj.map((data, index) => {
          return (
            <option value={data.val} key={index}>
              {data.text}
            </option>
          );
        })}
    </select>
  );
};

export default Select;
