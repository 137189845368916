import axios from "axios";

export function getAllMasters() {
  return axios({
    url: `/hpmc/api/masters`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getAllRoles() {
  return axios({
    url: `/hpmc/api/rolemanagement/getallroles`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getAllGroups(data) {
  data = data === undefined ? true : false;
  return axios({
    url: `/hpmc/api/groups?getall=${data}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// update makerChecker Master
export function updateMakerCheckerMaster(data) {
  return axios({
    url: `/hpmc/api/masters/all`,
    method: "put",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// get roles from selecting groups
export function getRolesViaGroup(id) {
  return axios({
    url: `/hpmc/api/groups/roles/${id}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

//  get master details
export function getSingleMasterData(data, dateOne, dateSecond, masterId) {
  return axios({
    url: `/hpmc/api/makercheckers?query=${data.searchInput}&sortBy=${data.accessor}&orderBy=${data.sort}&pageSize=${data.perPage}&pageNumber=${data.page}&filterBy=${data.filterBy}&startDate=${dateOne}&endDate=${dateSecond}&masterId=`,
    method: "get",
    responseType: "json",
  });
}

// export csv
export function downloadMasterCSv() {
  let url = `/hpmc/api/makercheckers?&exportCsv=1`;
  window.open(url, "_blank", "noopener,noreferrer");
}

// corporate masterdata
// &startDate=${dateOne}&endDate=${dateSecond
export function getCorporateMasterData(data) {
  return axios({
    url: `/hpmc/api/corpmaster/corpmasters?sortBy=${data.accessor}&orderBy=${data.sort}&pageSize=${data.perPage}&pageNumber=${data.page}&query=${data.searchingQuery}&filterBy=onboarded`,
    method: "get",
    responseType: "json",
  });
}

// exporte masterdata csv
export function exportMasterDataCsv(baseUrlCSV, data, dateOne, dateSecond) {
  let url = `/hpmc/api/${baseUrlCSV}?exportCsv=1`;
  window.open(url, "_blank", "noopener,noreferrer");
}

export function exportFullMasterDataCsv(baseUrlCSV, data, dateOne, dateSecond) {
  let url = `/hpmc/api/${baseUrlCSV}?exportFullCsv=1&filterBy=onboarded`;
  window.open(url, "_blank", "noopener,noreferrer");
}

// assigne and unassign  make of master
export function assignMakerOfCorpForm(corpId) {
  return axios({
    url: `/hpmc/api/makercheckers/corporates/claimunclaim/${corpId}`,
    method: "post",
    responseType: "json",
  });
}

// submit maker changes
export function submitMakerChanges(corpId, data) {
  return axios({
    url: `/hpmc/api/makercheckers/corporates/${corpId}`,
    method: "post",
    responseType: "json",
    data: data,
  });
}

// claim master
export function claimMaster(id, type) {
  return axios({
    url: `/hpmc/api/makercheckers/${type}/${id}`,
    method: "post",
    responseType: "json",
  });
}

// submit maker changes
export function submitMakerChecker(corpId, data) {
  return axios({
    url: `/hpmc/api/makercheckers/complete/${corpId}`,
    method: "post",
    responseType: "json",
    data: data,
  });
}
// delegate and assigne checker master
export function delegateCheckerMaster(makerId, Id) {
  return axios({
    url: `/hpmc/api/makercheckers/assigntask/${makerId}/${Id}`,
    method: "post",
    responseType: "json",
  });
}

//  get checker potential user
export function checkerPotentailUser(userId) {
  return axios({
    url: `/hpmc/api/makercheckers/potentialusers/${userId}`,
    method: "get",
    responseType: "json",
  });
}

// holiday days and dates
export function getAllHolidayMasters(data) {
  return axios({
    url: `/hpmc/api/holidaymasters?sortBy=${data.accessor}&orderBy=${data.sort}&pageSize=${data.perPage}&pageNumber=${data.page}&query=${data.searchingQuery}&filterBy=onboarded`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getHolidayMasters(id) {
  return axios({
    url: `/hpmc/api/holidaymasters/${id}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// update holiday  Master
export function updateHolidayMaster(data) {
  return axios({
    url: `/hpmc/api/holidaymasters`,
    method: "put",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getAllEmailtemplates(data) {
  return axios({
    url: `/hpmc/api/emailtemplatemasters?sortBy=${data.accessor}&orderBy=${data.sort}&pageSize=${data.perPage}&pageNumber=${data.page}&query=${data.searchingQuery}&filterBy=onboarded`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getEmailtemplate(id) {
  return axios({
    url: `/hpmc/api/emailtemplatemasters/${id}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// update holiday  Master
export function updateEmailTemplate(data) {
  return axios({
    url: `/hpmc/api/emailtemplatemasters`,
    method: "put",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// pfms
export function getAllPFMMasters(data) {
  return axios({
    url: `/hpmc/api/pfms?sortBy=${data.accessor}&orderBy=${data.sort}&pageSize=${data.perPage}&pageNumber=${data.page}&query=${data.searchingQuery}&filterBy=onboarded`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export function getPFMMasters(id) {
  return axios({
    url: `/hpmc/api/pfms/${id}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// update holiday  Master
export function updatePFMMaster(data) {
  return axios({
    url: `/hpmc/api/pfms`,
    method: "put",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// claimunclaim edit mode on/off
export function editClaimUnclaim(masterId, rowId) {
  return axios({
    url: `/hpmc/api/makercheckers/claimunclaim/${masterId}/${rowId}`,
    method: "post",
    responseType: "json",
  });
}

// Start Maker Checker Flow
export function makerCheckerFlow(masterId, rowId, data) {
  return axios({
    url: `/hpmc/api/makercheckers/masters/${masterId}/${rowId}`,
    method: "post",
    responseType: "json",
    data: data,
  });
}

// document type apis
// get all
// holiday days and dates
export function getAllProcessDocumentManagement(data) {
  return axios({
    url: `/hpmc/api/processdocumenttypes?sortBy=${data.accessor}&orderBy=${data.sort}&pageSize=${data.perPage}&pageNumber=${data.page}&query=${data.searchingQuery}&filterBy=onboarded`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getProcessDocumentManagement(id) {
  return axios({
    url: `/hpmc/api/processdocumenttypes/${id}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// POP SP management

// POP-SP
export function getAllPOPSP(data) {
  return axios({
    url: `/hpmc/api/popspmasters/page?sortBy=${data.accessor}&orderBy=${data.sort}&pageSize=${data.perPage}&pageNumber=${data.page}&query=${data.searchingQuery}&filterBy=onboarded`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export function getPOPSP(id) {
  return axios({
    url: `/hpmc/api/popspmasters/${id}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// update POP-SP  Master
export function updatePOPSP(data) {
  return axios({
    url: `/hpmc/api/popspmasters`,
    method: "put",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// Charge management

// charge
export function getAllCharge(data) {
  return axios({
    url: `/hpmc/api/charges?sortBy=${data.accessor}&orderBy=${data.sort}&pageSize=${data.perPage}&pageNumber=${data.page}&query=${data.searchingQuery}&filterBy=onboarded`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export function getCharge(id) {
  return axios({
    url: `/hpmc/api/charges/${id}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// update POP-SP  Master
export function updateCharge(data) {
  return axios({
    url: `/hpmc/api/charges`,
    method: "put",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// getAllCRAs
export function getAllCRAs() {
  return axios({
    url: `/hpmc/api/cras`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// get cra by id
export function getCraById(id) {
  return axios({
    url: `/hpmc/api/cras/${id}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// role management get all user as per department and designation
export function getAllUserManagement({
  accessor,
  sort,
  perPage,
  page,
  searchInput,
  assign,
  groupId,
  roleId,
}) {
  return axios({
    url: `/hpmc/api/usermanagement/users?sortBy=${accessor}&orderBy=${sort}&pageSize=${perPage}&pageNumber=${page}&query=${searchInput}&filterBy=${assign}&groupId=${groupId}&roleId=${roleId}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// add role
export function addRoleToUser(groupId, roleId, userList) {
  return axios({
    url: `/hpmc/api/usermanagement/users/addrole/${groupId}/${roleId}`,
    method: "post",
    responseType: "json",
    data: userList,
  });
}

// remove role
export function removeRoleToUser(groupId, roleId, userList) {
  return axios({
    url: `/hpmc/api/usermanagement/users/removerole/${groupId}/${roleId}`,
    method: "post",
    responseType: "json",
    data: userList,
  });
}

// import file in master management
export function importBulkMasters(id, fileObj) {
  return axios({
    url: `/hpmc/api/masters/import/${id}`,
    method: "post",
    responseType: "json",
    data: fileObj,
  });
}

// download sample files in Master Management

export function downloadSampleFileMaster(id) {
  let url = `/hpmc/api/masters/sample/${id}`;
  window.open(url, "_blank", "noopener,noreferrer");
}

// download corporate Data via Corp Managementa csv
export function downloadCorpData(id) {
  let url = `/hpmc/api/corp-reg/corporates/downloaddoczip/${id}`;
  window.open(url, "_blank", "noopener,noreferrer");
}

// add holiday master
export function addHolidayMaster(data) {
  return axios({
    url: `/hpmc/api/holidaymasters`,
    method: "POST",
    data: data,
    responseType: "json",
  });
}

// delete holiday master
export function deleteHolidayMaster(id) {
  return axios({
    url: `/hpmc/api/holidaymasters/${id} `,
    method: "POST",
    responseType: "json",
  });
}
