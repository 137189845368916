import React, { useEffect } from 'react';
import Classes from "./NonLabelStepper.module.css";
const NonLabelStepper = ({ steps,activeIndex }) => {
    return (
        <div className={Classes.nonLabelStepper}>
            {steps &&
                steps.map((data, index) => {
                    return (
                        <div className={`${Classes.step} ${data.index === activeIndex ? Classes.activeStep : Classes.default}`} key={index}></div>
                    )
                })
            }
        </div>
    )
}
export default NonLabelStepper