import React, { useEffect, useState } from "react";
import Classes from "./ContributionUploadDocument.module.css";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import UploadDocument from "../../../components/UploadDocument/UploadDocument";
import Consts from "../../../Consts";
import {
  checkIfCorporateOnboarded,
  contributionUploadDocumentApi,
} from "../ContributionActions";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../Home/HomeSlice";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const ContributionUploadDocument = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [isCorporateOnboard, setIsCorporateOnboard] = useState(false);

  useEffect(() => {
    checkIfCorporateOnboarded()
      .then((res) => {
        if (res.data.success === true) {
          setIsCorporateOnboard(res.data?.data);
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: err?.response?.data?.message,
            alertType: "danger",
          })
        );
      });
  }, []);

  const fileUpdateFunction = (e) => {
    var formData = new FormData();
    formData.append("file", e);
    contributionUploadDocumentApi(formData)
      ?.then((res) => {
        if (res?.data) {
          if (res?.data?.success) {
            dispatch(
              setAlertMsg({
                msg: res?.data?.message,
                alertType: "success",
              })
            );
          }
        } else {
          dispatch(
            setAlertMsg({
              msg: res.response.data.message,
              alertType: "danger",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: err.response.data.message,
            alertType: "danger",
          })
        );
      });
  };

  return (
    <>
      <section>
        <div className="container-fluid px-4">
          <div className={Classes.goback_btn}>
            <GoBackButton />
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className={Classes.form_title}>
                <h1>Upload Document </h1>
              </div>
            </div>
          </div>
          <div className={Classes.upload_doc}>
            <div className="col-lg-12 mx-auto text-center">
              <PrimaryButton
                label={"Upload Document"}
                onClick={() => setIsUploadModal(true)}
                disabled={!isCorporateOnboard}
              />
            </div>
            {!isCorporateOnboard && (
              <p>The corporate has not been onboarded.</p>
            )}
          </div>
        </div>
      </section>

      {isUploadModal && (
        <UploadDocument
          isModalOpen={isUploadModal}
          setIsModalOpen={setIsUploadModal}
          acceptedFileType={Consts.ACCEPTED_FILE_TYPE_CONTRIBUTION}
          uploadedDocuments={fileUpdateFunction}
          //   InnerComponent={UploadModalHelpingComponent}
        />
      )}
    </>
  );
};

export default ContributionUploadDocument;
