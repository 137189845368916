import React, { useState, useRef } from "react";
import classes from "./Header.module.css";
import logoImage from "../../assets/HeaderAssets/HDFCLOGO2.svg";
import { logoutUser } from "../../modules/Auth/AuthActions";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom/dist";
import "../../Fonts/SohoGothicPro-Bold.otf";
import SearchIconwhite from "../../assets/search_icon_black.svg";
import {
  BASE_URL,
  USER_URL,
  DASHBOARD_URL,
  MASTERMANAGEMENT_URL,
  CORPORATE_ONBOARD_FORM_URL,
  CORPORATEDASHBOARD_URL,
  ADMIN_URL,
  FUNCTIONALITY_MANAGEMENT_URL,
  MISREPORT_URL,
  MAKER_CHECKER_TASK_URL,
  MAKER_CHANGER_MASTER,
  AUTHMANAGEMENT_URL,
} from "../../Routes/Urls";
import DownArrowIcon from "../../assets/HeaderAssets/DownArrow.svg";
import { useTranslation } from "react-i18next";
import Utils from "../../Utils";

const Header = ({ handleToggle, disable }) => {
  const history = useNavigate();
  const { t } = useTranslation();
  const { isLoggedIn } = useSelector((state) => state);
  const dropdownRef = useRef(null);
  const [isToggleOpen, setIsToggleOpen] = useState(true);
  const userDetails = useSelector((state) => state.loginUserDetails);
  const [searchInput, setSearchInput] = useState("");
  const [filterSearch, setFilterSearch] = useState([]);

  const onClickHandler = () => {
    setIsToggleOpen(!isToggleOpen);
  };

  //  global search obj
  const globalSearchObj = [
    {
      functionalAreas: [1],
      cardName: "Corporate Registration",
      route: `/${BASE_URL}/${DASHBOARD_URL}/${CORPORATEDASHBOARD_URL}`,
    },
    {
      functionalAreas: [],
      cardName: "Functionality Management",
      route: `${BASE_URL}/${ADMIN_URL}/${AUTHMANAGEMENT_URL}/${FUNCTIONALITY_MANAGEMENT_URL}`,
    },
    {
      functionalAreas: [1],
      cardName: t("text.registerCorporate"),
      route: `/${BASE_URL}/${DASHBOARD_URL}/${CORPORATEDASHBOARD_URL}/${CORPORATE_ONBOARD_FORM_URL}`,
    },
    {
      functionalAreas: [],
      cardName: "MIS Report",
      route: `/${BASE_URL}/${MISREPORT_URL}`,
    },
    {
      functionalAreas: [],
      cardName: "Master Dashboard",
      route: `/${BASE_URL}/${ADMIN_URL}/${MASTERMANAGEMENT_URL}`,
    },
    {
      functionalAreas: [],
      cardName: "Maker Checker Master",
      route: `/${BASE_URL}/${ADMIN_URL}/${MASTERMANAGEMENT_URL}/${MAKER_CHANGER_MASTER}`,
    },
    {
      functionalAreas: [1, 2, 3],
      cardName: "Maker Checker Task",
      route: `/${BASE_URL}/${DASHBOARD_URL}/${MAKER_CHECKER_TASK_URL}`,
    },
  ];

  const handleItemClick = (item) => {
    if (item === "user") {
      history(`${BASE_URL}/${USER_URL}`);
    } else if (item === "logout") {
      // Handle logout action
      logoutUser();
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsToggleOpen(true);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const cardRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setFilterSearch([]);
        setSearchInput("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle onChange search
  const handleOnChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchInput(Utils.PreventSpecialCharacter(e));

    const filteredArray = globalSearchObj.filter((value) => {
      return value.cardName.toLowerCase().startsWith(searchTerm);
    });

    if (e.target.value === "") {
      setFilterSearch([]);
    } else {
      setFilterSearch(filteredArray);
    }
  };

  //  navigate Router
  const handleNavigate = (route) => {
    history(route);
    setFilterSearch([]);
    setSearchInput("");
  };

  // check if flow is allowed
  const allowedFlow = (functionalAreas) => {
    const userFunctionalAreasId = userDetails?.functionalAreas?.map(
      (area) => area.id
    );
    if (Utils.isAdmin(userDetails)) {
      return true;
    } else {
      return functionalAreas.some((element) =>
        userFunctionalAreasId.includes(element)
      );
    }
  };

  return (
    <>
      <div className={classes.app_header_container}>
        <div className={classes.header_logo}>
          {/* <IconButton onClick={handleToggle} className={classes.menu_icon}>
            <MenuIcon sx={{ color: "var(--app-text-light-color)" }} />
          </IconButton> */}
          <img
            onClick={() => {
              isLoggedIn && history(`${BASE_URL}/${DASHBOARD_URL}`);
            }}
            src={logoImage}
          />
        </div>
        {isLoggedIn && (
          <>
            <div className={classes.header_globalsearch_container}>
              <div className={classes.header_searchbar}>
                <img src={SearchIconwhite} />
                <input
                  value={searchInput}
                  onChange={handleOnChange}
                  placeholder="Search"
                />
                <div className={classes.card_name} ref={cardRef}>
                  {filterSearch.map((card, key) => {
                    return (
                      allowedFlow(card?.functionalAreas) && (
                        <span
                          key={key}
                          onClick={() => handleNavigate(card.route)}
                        >
                          {card.cardName}
                        </span>
                      )
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className={classes.dropdown}
              ref={dropdownRef}
              onClick={() => onClickHandler()}
            >
              <button>
                {userDetails.fullName || t("text.login")}
                <i>
                  <img src={DownArrowIcon} />
                </i>
              </button>

              <div
                className={
                  isToggleOpen
                    ? `${classes.dropdownContent}`
                    : `${classes.dropdownContent} ${classes.dropdownHover}`
                }
              >
                <div
                  className={classes.selectDropOption}
                  onClick={() => handleItemClick("user")}
                >
                  User Profile
                </div>
                <div
                  className={classes.selectDropOption}
                  onClick={() => handleItemClick("logout")}
                >
                  Logout
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Header;
