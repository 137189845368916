import React, { useEffect } from "react";
import { getResultFromDigiLocker } from "../../SubscriberOnboardAction";
import { useState } from "react";
import Modal from "../../../../components/Modal/Modal";
import Classes from "./SubscriberDigiLocker.module.css";

const SubscriberDigiLocker = React.memo(
  ({ url, isOpenModal, ackId, setisDigiLockerSubmit, setIsKycValidate }) => {
    const [receivedMessage, setReceivedMessage] = useState("false");
    const [isModal, setIsModal] = useState(false);
    useEffect(() => {
      const newWindow = window.open(
        url,
        "newWindow",
        "width=615,height=450 , noopener,noreferrer"
      );
      setIsModal(true);
      checkWindowClosed(newWindow);
    }, []);

    const checkWindowClosed = (dynamicWindow) => {
      const intervalId = setInterval(() => {
        if (dynamicWindow.closed) {
          setIsModal(false);
          clearInterval(intervalId);
        }
      }, 500);
    };

    // get msg from digilocker window
    const channel = new BroadcastChannel("myChannel");

    channel.onmessage = (event) => {
      const message = event.data;
      setReceivedMessage(message);
    };

    //  check digilocker msg and call function
    useEffect(() => {
      getResultDigiLocker();
    }, [receivedMessage === "true"]);

    const getResultDigiLocker = () => {
      getResultFromDigiLocker(ackId).then((res) => {
        let kycValidate = false;
        let isDigiLockerSubmit = true;
        if (res?.data?.data === "pending") {
          isDigiLockerSubmit = true;
        } else if (res?.data?.data === "granted") {
          isDigiLockerSubmit = true;
          kycValidate = true;
          setIsModal(false);
        } else {
          isDigiLockerSubmit = true;
          kycValidate = true;
        }
        setisDigiLockerSubmit(isDigiLockerSubmit);
        setIsKycValidate(kycValidate);
      });
    };

    return (
      isModal && (
        <Modal style={{ background: "#f9f9f9" }}>
          <div className={Classes.processingModal}>
            <h3>Processing...</h3>
            <p>
              Please do not refresh the page or click the "Back" or "Close"
              button of your browser
            </p>
          </div>
        </Modal>
      )
    );
  }
);

export default SubscriberDigiLocker;
