import React, { useEffect, useState } from "react";
import ObjPreViewForm from "../../../components/PreViewForm/ObjPreViewForm";
import NpsConfigObj from "../SubsciberPrevFormConfig.json";
import classes from "./SubscriberPrevForm.module.css";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import uploadIcon from "../../../assets/upload_doc_icon.svg";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import Consts from "../../../Consts";
import UploadDocument from "../../../components/UploadDocument/UploadDocument";
import fileIcon from "../../../assets/file.svg";
import {
  getSubscriberById,
  uploadSubscriberDocument,
} from "../SubscriberOnboardAction";
import { saveorSubmitSubscriberForm } from "../SubscriberRegistration/SubscriberRegistrationAction";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../Home/HomeSlice";

const SubscriberPrevForm = ({ previewData }) => {
  const dispatch = useDispatch();
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [uploadDocument, setUploadDocument] = useState(previewData?.subDocData);
  const [currentDocId, setCurrentDocId] = useState("0");
  const [isEdit, setIsEdit] = useState(false);
  const [previewFormData, setPreviewFormData] = useState({
    ...previewData?.subFormData,
  });

  const handleDocUpload = (docId) => {
    setIsUploadModal(true);
    setCurrentDocId(docId);
  };

  const fileUpdateFunction = (e) => {
    let formData = new FormData();
    formData.append("file", e);
    uploadSubscriberDocument(
      previewData?.subData?.id,
      currentDocId,
      formData
    ).then((res) => {
      if (res.data.success === true) {
        subscriberDetails();
      }
    });
  };

  const subscriberDetails = () => {
    getSubscriberById(previewData?.subData?.id).then((res) => {
      if (res.data.success === true) {
        setUploadDocument(res.data.data?.subDocData);
      }
    });
  };

  useEffect(() => {
    subscriberDetails();
  }, []);

  const handleSubmit = () => {
    let formdata = {
      subFormData: previewFormData,
    };
    saveorSubmitSubscriberForm(previewData?.subData?.id, formdata, "submit")
      .then((res) => {
        if (res?.data?.success === true) {
          dispatch(
            setAlertMsg({
              msg: "Form submit successfully",
              alertType: "success",
            })
          );
        } else {
          dispatch(
            setAlertMsg({
              msg: res?.response?.data?.message,
              alertType: "danger",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: err?.response?.data?.message,
            alertType: "danger",
          })
        );
      });
  };

  return (
    <div className={classes.previewForm_container}>
      <div className={classes.form_header_container}>
        <GoBackButton />
        <div className={classes.form_headings}>
          <h4>New NPS Registration</h4>
          <h6>Your Acknowledgement ID : {previewData?.subData?.ackId}</h6>
        </div>
      </div>
      <div className={classes.form_container}>
        <div className={classes.form_acton_container}>
          <div className={classes.form_title}>
            <h4>Form Review</h4>
            <p>To generate PRAN, Kindly review the form carefully</p>
          </div>
        </div>
        <div className={classes.form}>
          <ObjPreViewForm
            ConfigObj={NpsConfigObj}
            previewData={previewData?.subFormData}
            isEdit={isEdit}
            previewFormData={previewFormData}
            setPreviewFormData={setPreviewFormData}
            setIsEdit={setIsEdit}
          />
          <div className={classes.upload_screen_container}>
            <div className={classes.photo_upload}>
              <img
                src={`https://images.unsplash.com/photo-1606002830191-c1b4f20e6cda?q=80&w=930&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D`}
              />
              <div className={classes.upload_doc}>
                <img src={uploadIcon} /> Upload image
              </div>
            </div>
            <div className={classes.signature_upload}>
              <img
                src={`https://signature.freefire-name.com/img.php?f=2&t=M.s%20Dhoni`}
              />
              <div className={classes.upload_doc}>
                <img src={uploadIcon} /> Upload Signature
              </div>
            </div>
          </div>
        </div>
        {uploadDocument?.map((doc, index) => {
          return (
            <div key={index} className={classes.upload_document}>
              <label
                onClick={() => handleDocUpload(doc?.processDocumentType?.id)}
              >
                <img src={uploadIcon} />
                {doc?.isSaved ? `Reupload  ` : `Upload `}
                {doc?.processDocumentType?.uiString}
              </label>
              {doc?.isSaved && (
                <div className={classes.uploaded_file}>
                  <img src={fileIcon} />
                  {doc?.documentName}
                </div>
              )}
            </div>
          );
        })}
        <div className={classes.previewform_btn}>
          <PrimaryButton onClick={handleSubmit} label={"Submit"} />
          <SecondaryButton label={"Cancel"} />
        </div>
      </div>
      {isUploadModal && (
        <UploadDocument
          isModalOpen={isUploadModal}
          setIsModalOpen={setIsUploadModal}
          acceptedFileType={Consts.ACCEPTED_FILE_TYPE_ARR}
          uploadedDocuments={fileUpdateFunction}
        />
      )}
    </div>
  );
};

export default SubscriberPrevForm;
