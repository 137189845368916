import axios from "axios";

export function getChargeDetails(corpId) {
    return axios({
      url: `/hpmc/api/corp-reg/corporateCharges/${corpId}`,
      method: "get",
      responseType: "json",
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }


  export function submitChargeScuritny(corpId, obj) {
    return axios({
      url: `/hpmc/api/corp-reg/corporates/scrutinycharges/${corpId}`,
      method: "post",
      data: obj,
      responseType: "json",
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  export function submitUpdateCharge(corpId, obj) {
    return axios({
      url: `/hpmc/api/corp-reg/corporateUpdateCharges/${corpId}`,
      method: "post",
      data: obj,
      responseType: "json",
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }