import React from "react";
import Classes from "./DigiLockerModal.module.css";
import Modal from "../../../../components/Modal/Modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";

const DigiLockerModal = ({setIsModalOpen, callDigiLockerfunction}) => {
const {t } = useTranslation();
  const [digiLockerInput, setDigiLockerInput] = useState(false);
  return (
    <Modal>
      <div className={Classes.digiLockerModal}>
        <div className={Classes.digilocker_modal_header}>
          <h4>Digilocker Redirect Consent</h4>
          <p>You will be re directed to Digilock website</p>
        </div>
        <div className={Classes.terms_input}>
          <label>
            <input
              type="checkbox"
              name="digilockerDeclaration"
              checked={digiLockerInput}
              onChange={() => setDigiLockerInput(!digiLockerInput)}
            />
            Please confirm the declaration by clicking check box to proceed.
          </label>{" "}
        </div>
        <div className={Classes.digilocker_modal_content}>
          <p>
            You will be redirected to DigiLocker Website wherein you need to
            login with your login credentials and provide consent for sharing of
            documents/information with CRA. Kindly click on Accept to proceed
            further. Kindly click on Cancel to cancel the request.
          </p>
        </div>
        <div className={Classes.digilockerButton}> 
        <PrimaryButton disabled={!digiLockerInput} onClick={callDigiLockerfunction} label={t("proceed.digilocker")} />
        <SecondaryButton onClick={()=>setIsModalOpen(false)} label={t("Cancel")} />
        </div>
      </div>
    </Modal>
  );
};

export default DigiLockerModal;
