import React from 'react';
import Classes from "./SuccessfullyRegistrationModal.module.css";
import Modal from '../../../../components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import CheckMarkGreenIcon from "../../../../assets/Check-green.svg";
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import SecondaryButton from '../../../../components/Buttons/SecondaryButton';
import TextButton from '../../../../components/Buttons/TextButton';
import Utils from '../../../../Utils';
import { useNavigate } from 'react-router';
import { BASE_URL, DASHBOARD_URL, SUBSCRIBER_REGISTRATION_HOME } from '../../../../Routes/Urls';

const SuccessfullyRegistrationModal = ({isModalActive,setIsModalActive, handleProceed,data}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();


    const redirectToDashboard = () => {
      navigate(`/${BASE_URL}/${DASHBOARD_URL}/${SUBSCRIBER_REGISTRATION_HOME}`);
    };
  return (
    isModalActive && (
        <Modal style={{borderRadius : "1rem"}}>
           <div className={Classes.ackId_modal_container}>
            <div className={Classes.ackId_title}>
              <img src={CheckMarkGreenIcon} />
              <h2 className={Classes.ackId}>{data?.subData?.ackId}</h2>
              <p className={Classes.p}>
              Your Acknowledgement ID has been initiated successfully
              </p>
              <TextButton
                label={t("button.printThis")}
                onClick={() => window.print()}
              />
            </div>
            <div className={Classes.ackId_details}>
              <div className={Classes.corp_name}>
                <p>New PRAN Registeration </p>
                {/* <p></p> */}
              </div>
              <div className={Classes.receipt_date}>
                <p>Receipt date</p>
                <p className={Classes.date_p}>
               {Utils.convertToLocalDateOnly(data?.subData?.submissionDate)}
                </p>
              </div>
            </div>
            <div className={Classes.ackId_noted}>
              <p className={Classes.p}>
              Note - Click on proceed to continue the registration and use acknowledgement ID to complete the process later.
              </p>
            </div>
            <div className={Classes.ackId_btn}>
              <PrimaryButton
                label={t("button.proceed")}
                onClick={handleProceed}
              />
              <SecondaryButton
                onClick={()=>{setIsModalActive(false); redirectToDashboard()}}
                label={t("button.close")}
              />
            </div>
          </div>
        </Modal>
      )
  )
}

export default SuccessfullyRegistrationModal