import { createSlice } from "@reduxjs/toolkit";

export const UIFunctionObj = createSlice({
  name: "UIFunctionObj",
  initialState: [],
  reducers: {
    uiFunctionObj: (state, action) => {
      return action.payload;
    },
  },
});
export const loginDetails = createSlice({
  name: "loginUser",
  initialState: {},
  reducers: {
    loginUserDetail: (state, action) => {
      return action.payload;
    },
  },
});

export const alertDetails = createSlice({
  name: "alertMsg",
  initialState: { msg: "", alertType: "" },
  reducers: {
    setAlertMsg: (state, action) => {
      return action.payload;
    },
  },
});

export const arrayOfAlertDetails = createSlice({
  name: "arrayOfAlertMsg",
  initialState: [],
  reducers: {
    setArrayOfAlertMsg: (state, action) => {
      return action.payload;
    },
  },
});

export const isLoggedIn = createSlice({
  name: "isLoggedIn",
  initialState: null,
  reducers: {
    setIsLoggedIn: (state, action) => {
      return action.payload;
    },
  },
});

export const { uiFunctionObj } = UIFunctionObj.actions;
export const { loginUserDetail } = loginDetails.actions;
export const { setAlertMsg } = alertDetails.actions;
export const { setArrayOfAlertMsg } = arrayOfAlertDetails.actions;
export const { setIsLoggedIn } = isLoggedIn.actions;

export const uiFunctionObjReducer = UIFunctionObj.reducer;
export const loginUserDetailsReducer = loginDetails.reducer;
export const alertDetailsReducer = alertDetails.reducer;
export const arrayOfAlertDetailsReducer = arrayOfAlertDetails.reducer;
export const IsLoggedInReducer = isLoggedIn.reducer;
