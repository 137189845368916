import React, { useEffect, useRef, useState } from "react";
import TableDashboard from "../../../../components/TableDashboard/TableDashboard";
import ArrowUPIcon from "../../../../assets/TableDashboard/ArrowUp.svg";
import GreenDot from "../../../../assets/TableDashboard/GreenDot.svg";
import RedDot from "../../../../assets/TableDashboard/RedDot.svg";
import classes from "./UserManagementDashBoard.module.css";
import {
  BASE_URL,
  USER_MANAGEMENT_DASHBOARD_URL,
  USER_MANAGEMENT_CREATE_USER_URL,
  ADMIN_URL,
  USER_MANAGEMENT_EDIT_USER_URL,
} from "../../../../Routes/Urls";
import {
  getCorpCsvFile,
  getCorpFullCsvFile,
  getCorporateDashboardData,
} from "../../../CorponboardingForm/CorporateAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import {
  downloadAllUsers,
  downloadSampleFileUserManagement,
  getAllUsers,
  importBulkUsers,
} from "../UserManagementActions";
import TextButton from "../../../../components/Buttons/TextButton";
import Utils from "../../../../Utils";
import { setAlertMsg } from "../../../Home/HomeSlice";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import UploadDocument from "../../../../components/UploadDocument/UploadDocument";
import Consts from "../../../../Consts";

const UserManagementDashboard = () => {
  // to print screen
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [columnRotations, setColumnRotations] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [pageData, setPageData] = useState({});
  const [page, setPage] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [sort, setSort] = useState("asc");
  const [accessor, setAccessor] = useState("");
  const [perPage, setperPage] = useState(10);
  const [totalCount, setTotalCount] = useState({});
  const [filterBy, setfilterBy] = useState("");
  const [isImportModalActive, setIsImportModalActive] = useState(false);

  useEffect(() => {
    getAllUsersFunction();
  }, []);

  const getAllUsersFunction = () => {
    getAllUsers({ page, searchInput, sort, accessor, perPage, filterBy })
      .then((res) => {
        setTableData(res.data.data.pageData.content);
        setPageData(res.data.data.pageData);
        // setTotalCount(res.data.data.pageData.countData);
      })
      .catch((err) => {
        if (err?.response?.data?.success === false) {
          dispatch(
            setAlertMsg({
              msg: err?.response?.data?.message,
              alertType: "danger",
            })
          );
        } else {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.internal_server_error"),
              alertType: "danger",
            })
          );
        }
      });
  };

  useEffect(() => {
    getAllUsersFunction();
  }, [filterBy, sort, accessor, perPage, page]);
  useEffect(() => {
    setPage(0);
    setAccessor("");
  }, [perPage, filterBy]);

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(0);
    setAccessor("");
    getAllUsersFunction();
  };

  // select roles from array
  const selectRoleFunction = (data) => {
    let filteredData = data?.filter((e) => e?.id !== 3);
    if (filteredData.length > 0) {
      return (
        <span
          className={classes.roleDashboardCSS}
          title={filteredData[0]?.role?.roleName}
        >
          {" "}
          {Utils.ShortLengthFunction(filteredData[0]?.role?.roleName, 15)}
        </span>
      );
    }
    return "--";
  };

  // tooltip status and shortlen status, shortlen show when both conditions are true
  const columns = [
    {
      header: (
        <p className={classes.headers}>
          EMPLOYEE CODE
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "employeeCodeasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("employeeCode")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.employeeCode || "NA",
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 20 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          Employee Name
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "fullNameeasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("fullName")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.fullName || "--",
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 20 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          STATUS{" "}
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "employeeStatusasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("employeeStatus")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      key: "employeeStatus",
      accessor: (e) => (
        <p className={classes.state_container}>
          {e.canLogin ? (
            <>
              <img src={GreenDot} />
              Active
            </>
          ) : (
            <>
              <img src={RedDot} />
              Inactive
            </>
          )}
        </p>
      ),
    },
    {
      header: (
        <p className={classes.headers}>
          Email Id
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "usernameasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("username")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.username,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 20 },
      isTextCenter: true,
    },
    {
      header: <p className={classes.headers}>Role</p>,
      accessor: (e) => selectRoleFunction(e.internalGroupRoles),
    },

    {
      header: <p className={classes.headers}>Action</p>,
      accessor: (e) => (
        <>
          <TextButton onClick={() => handleEditUser(e.id)} label="Edit user" />
        </>
      ),
    },
  ];

  // handling sort
  const handleSort = (key) => {
    const newSort = sort === "asc" ? "desc" : "asc";
    setSort(newSort);
    setAccessor(key);
    if (sort === "asc") {
      setColumnRotations(key + "desc");
    } else {
      setColumnRotations(key + "asc");
    }
  };

  const analyticCard = [];

  // tab  data array
  const tabsLabel = [
    {
      title: ` All `,
      key: "",
    },
    {
      title: `${t("text.hpmcUser")} `,
      key: "hpmcUser",
    },
    {
      title: `${t("text.nonHpmcUser")} `,
      key: "nonHpmcUser",
    },
  ];

  // redirect to new corp  form
  const handleNewUserForm = () => {
    navigate(
      `/${BASE_URL}/${ADMIN_URL}/${USER_MANAGEMENT_DASHBOARD_URL}/${USER_MANAGEMENT_CREATE_USER_URL}`
    );
  };

  const handleEditUser = (data) => {
    navigate(
      `/${BASE_URL}/${ADMIN_URL}/${USER_MANAGEMENT_DASHBOARD_URL}/${USER_MANAGEMENT_EDIT_USER_URL}`
    );
    localStorage.setItem("userManagement_userId", JSON.stringify(data));
  };

  //  table strings
  const tableStrings = {
    title: "User Management",
    listAll_title: "Users",
    search_placeholder: "Search Name",
  };

  //  downloadzip csv
  const downloadCsv = () => {
    downloadAllUsers({ page, searchInput, sort, accessor, perPage, filterBy });
  };

  const downloadFullCsv = () => {
    getCorpFullCsvFile();
  };

  const bulkImportUser = () => {
    return (
      <SecondaryButton
        style={{ marginLeft: "auto", marginRight: "1rem" }}
        label={"Bulk Import"}
        onClick={() => setIsImportModalActive(true)}
      />
    );
  };

  const handleClickSampleFile = () => {
    downloadSampleFileUserManagement();
  };

  const UploadModalHelpingComponent = () => {
    return (
      <div className="col-lg-12 mb-2 px-0">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className={classes.uploadModalHeading}>
            Upload Document - Import Users
          </h3>
          <SecondaryButton
            onClick={() => handleClickSampleFile()}
            label={"Sample File"}
          />
        </div>
      </div>
    );
  };

  const fileUpdateFunction = (currFile) => {
    let formData = new FormData();

    formData.append("file", currFile);
    importBulkUsers(formData)
      .then((res) => {
        if (res.data?.success) {
          dispatch(
            setAlertMsg({
              msg: res.data?.message,
              alertType: "success",
            })
          );
          getAllUsersFunction();
        }
      })
      .catch((err) => {
        if (err?.response?.data?.success === false) {
          dispatch(
            setAlertMsg({
              msg: err?.response?.data?.message,
              alertType: "danger",
            })
          );
        } else {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.internal_server_error"),
              alertType: "danger",
            })
          );
        }
      });
  };

  return (
    <div>
      <TableDashboard
        data={{ page, sort, accessor, perPage, filterBy }}
        tableStrings={tableStrings}
        columns={columns}
        downloadCsv={downloadCsv}
        handleSort={handleSort}
        analyticCard={analyticCard}
        tabsLabel={tabsLabel}
        handleNewCorpForm={handleNewUserForm}
        newFormButtonLabel={"Add New User"}
        pageData={pageData}
        tabValue={tabValue}
        setTabValue={setTabValue}
        filterBy={filterBy}
        setfilterBy={setfilterBy}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        handleSearch={handleSearch}
        perPage={perPage}
        setperPage={setperPage}
        page={page}
        tableData={tableData}
        accessor={accessor}
        setAccessor={setAccessor}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        downloadFullCsv={downloadFullCsv}
        dashboardCardsInActive={true}
        isPriorityContainerInactive={true}
        srNO={true}
        bulkImportUser={bulkImportUser()}
      />
      {isImportModalActive && (
        <UploadDocument
          isModalOpen={isImportModalActive}
          setIsModalOpen={setIsImportModalActive}
          acceptedFileType={Consts.ACCEPTED_FILE_TYPE_MASTER}
          uploadedDocuments={fileUpdateFunction}
          InnerComponent={UploadModalHelpingComponent}
        />
      )}
    </div>
  );
};

export default UserManagementDashboard;
