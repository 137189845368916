import * as React from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";

const size = {
  width: 200,
  height: 200,
};

function PieArcLabel({ data, width }) {
  return (
    <PieChart
      series={[
        {
          arcLabel: (item) => `${item.icon} (${item.value})`,
          arcLabelMinAngle: 45,
          data,
          innerRadius: 25,
          outerRadius: width ? width : 100,
          paddingAngle: 5,
          cornerRadius: 5,
          startAngle: -180,
          endAngle: 180,
          cx: 95,
          cy: 95,
        },
      ]}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          // This is a dynamic CSS class selector
          fill: "white",
          fontWeight: "bold",
        },
        [`& .MuiChartsLegend-series`]: {
          // This is a dynamic CSS class selector
          display: "none",
        },
      }}
      {...size}
    />
  );
}

export default PieArcLabel;
