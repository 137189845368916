export const BASE_URL = "hpmc-ui";

// Main screens
export const SIGN_IN_URL = "sign-in";
export const DASHBOARD_URL = "dashboard";
export const ADMIN_URL = "admin";
export const REPORT_URL = "misreport";
export const TASKS_URL = "tasks";
// export const NPSFORM_URL = "subscriber-registration";
// export const NPSSubRegForm_URL = "new-subscriber-registration";
// export const NPSSubRegForm_Form_URL = "new-subscriber-registration-form";
// export const NPSRegForm_COMPLETE_URL = "complete-subscriber-registration";

// dashboard corporate screens
export const CORPORATE_ONBOARD_FORM_URL = "corporate-onboarding";
export const SCRUTINY_FORM_URL = "scruitny-form";
export const CHO_FORM_URL = "cho_form";
export const CORPORATEDASHBOARD_URL = "corporate-dashboard";
export const ROAD_MAP_URL = "roadmap";
export const CORPORATE_CHARGE_SCRUITNY_URL = "charge-scruitny";
export const CORPORATE_CHARGE_UPDATE_URL = "update-charge";

// admin management screens
export const USER_URL = "users";
export const MISREPORT_URL = "misreport";
export const AUTHMANAGEMENT_URL = "authmanagement";
export const MASTERMANAGEMENT_URL = "mastermanagement";
export const MAKER_CHECKER_TASK_URL = "makerchecker_task";

// master management screens
export const MAKER_CHANGER_MASTER = "makerCheckerMaster";
export const REVIEW_CORPORATE_MASTER = "reviewCorporateMaster";

// functionalityManagement
export const FUNCTIONALITY_MANAGEMENT_URL = "functionalityManagement";

// Subscriber screens
export const SUBSCRIBER_URL = "subscriber";
export const DIGILOCKER_STATUS = "digilocker-status";
export const SUBSCRIBER_FORM = "subscriber-form";
export const SUBSCRIBER_UPLOAD = "subscriberUpload";
export const SUBSCRIBER_REGISTRATION_HOME = "subscriber-Registration-home";
export const SUBSCRIBER_MIGRATION = "subscriber-migration-form";

// password reset screen
export const PASSWORD_RESET = "reset-password";

// user management
export const USER_MANAGEMENT_DASHBOARD_URL = "usermanagementdashboard";
export const USER_MANAGEMENT_CREATE_USER_URL = "createuser";
export const USER_MANAGEMENT_EDIT_USER_URL = "editUser";

// Group Management
export const GroupManagement_URL = "group-management";

// Role Management
export const RoleManagement_URL = "role-management";

//  OPS screens
export const CORP_CONTRIBUTION_DASHBOARD_URL = "corp-contribution-dashboard";
export const CORP_CONTRIBUTION_URL = "corp-contribution";
export const ServiceRequest_URL = "service_request";
export const ServiceRequest_Update_Url = "service_request_update";
export const OPS_URL = "ops";
export const CORP_SUBSCRIBERS = "corp_subscribers";
export const SUBSCRIBER_SCRUTINY_FORM_URL = "subscriber-scrutiny-form";

// rm screens
export const RMDashboard_URL = "rm_dashboard";
export const CORPORATE_ONBOARDING_URL = "corporateOnboarding";
export const Pran_Journey_URL = "pran_journey";
export const PRAN_OSV_DASHBOARD_URL = "pran-osv-dashboard";
