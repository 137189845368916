import { Bar } from "react-chartjs-2";

export const BarChart = ({ chartData }) => {
  return (
    <Bar
      data={chartData}
      options={{
        plugins: {
          title: {
            display: true,
            text: "Total Corpus at retirement ",
          },
          legend: {
            display: false,
          },
        },
      }}
    />
  );
};
