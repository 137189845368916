import axios from "axios";

export function getFunctionalityDesignation() {
  return axios({
    url: `/hpmc/api/authz/functionalareas`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}


// get roles from selecting groups
export function getRolesViaGroup(id) {
    return axios({
      url: `/hpmc/api/groups/roles/${id}`,
      method: "get",
      responseType: "json",
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  
  export function getAllGroups() {
    return axios({
      url: `/hpmc/api/groups`,
      method: "get",
      responseType: "json",
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }



  export function getAllUsers() {
    return axios({
      url: `/hpmc/api/usermanagement/getallusers`,
      method: "get",
      responseType: "json",
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }


  export function functionalareasByGrpRole(group,role) {
    return axios({
      url: `/hpmc/api/authz/internalgrouproles/${group}/${role}`,
      method: "get",
      responseType: "json",
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  
  export function createUser(data) {
    return axios({
      url: `/hpmc/api/usermanagement/writeuser`,
      method: "post",
      responseType: "json",
      data : data,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }



  export function addFunctionalAreaToInternalGrpRole(id,data) {
    return axios({
      url: `/hpmc/api/authz/roles/functionalareas/${id}`,
      method: "post",
      responseType: "json",
      data : data,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }