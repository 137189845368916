import browserHistory from "../../../history";
import axios from "axios";

export function genrateAckId(data) {
  return axios({
    url: "/hpmc/api/corp-reg/corporates",
    method: "post",
    data: data,
    responseType: "json",
  });
}

export function dscDocUpload(id, file) {
  return axios({
    url: `/hpmc/api/corp-reg/dsc-cho/${id}`,
    method: "post",
    data: file,
    responseType: "json",
  });
}

export function registerCorporate(data, corpId, type) {
  return axios({
    url: `/hpmc/api/corp-reg/corporates/${corpId}?action=${type}`,
    method: "put",
    data: data,
    responseType: "json",
  });
}

export function getCorporateDocuments(corpId) {
  return axios({
    url: `/hpmc/api/corp-reg/corporates/documents/${corpId}`,
    method: "get",
    responseType: "json",
  });
}

export function getCorporateDetails(corpId) {
  return axios({
    url: `/hpmc/api/corp-reg/corporates/${corpId}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function corpDocumentUpload(taskid, formData, deletedFilesString) {
  return axios({
    url: `/hpmc/api/corp-reg/corporates/savedocs/${taskid}?${deletedFilesString}`,
    method: "post",
    data: formData,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function corpDocumentSubmit(taskid, formData) {
  return axios({
    url: `/hpmc/api/corp-reg/corporates/upload/${taskid}`,
    method: "post",
    data: formData,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function ScrutinyFormSubmit(corpId, documentObj, isSendMail) {
  return axios({
    url: `/hpmc/api/corp-reg/corporates/scrutiny/${corpId}?sendmail=${isSendMail}`,
    method: "post",
    data: documentObj,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function ScrutinyFormRaiseFR(corpId, documentObj, isSendMail) {
  return axios({
    url: `/hpmc/api/corp-reg/corporates/updatechocbo/${corpId}?sendmail=${isSendMail}`,
    // url: `/hpmc/api/corp-reg/corporates/scrutiny/${corpId}?sendmail=${isSendMail}`,
    method: "post",
    data: documentObj,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getSingleDocView(docId) {
  const headers = {
    token: "bawER8765#$&UIsdf=",
    "Content-Type": "application/json",
  };
  return axios({
    url: `/hpmc/api/orion/view-document?documentid=${docId}`,
    method: "get",
    headers,
    responseType: "blob",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getCorporateDashboardData(data, dateOne, dateSecond) {
  return axios({
    url: `/hpmc/api/corp-reg/corporates?sortBy=${data.accessor}&orderBy=${data.sort}&pageSize=${data.perPage}&pageNumber=${data.page}&query=${data.searchInput}&filterBy=${data.filterBy}&startDate=${dateOne}&endDate=${dateSecond}&subordinateId=${data.subordinateId} `,
    method: "get",
    responseType: "json",
  });
}

export function UpdateCHOFunction(corpId, choNum) {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios({
    url: `/hpmc/api/corp-reg/corporates/updatechocbo/${corpId}`,
    method: "post",
    data: choNum,
    headers,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function claimCorporate(corpId) {
  return axios({
    url: `/hpmc/api/corp-reg/corporates/claimtask/${corpId}`,
    method: "post",
    responseType: "json",
  });
}

export function releaseCorporate(corpId) {
  return axios({
    url: `/hpmc/api/corp-reg/corporates/releasetask/${corpId}`,
    method: "post",
    responseType: "json",
  });
}

// export function genrateAckId(data) {
//   return axios({
//     url: `/hpmc/api/corp-reg/corporates`,
//     method: "post",
//     data: data,
//     responseType: "json",
//   });
// }

export function getCorpFormData(corpId) {
  return axios({
    url: `/hpmc/api/corp-reg/corporates/${corpId}`,
    method: "get",
    responseType: "json",
  });
}

export function getCorpFormDataByAckId(ackId) {
  return axios({
    url: `/hpmc/api/corp-reg/corporatebyackid?ackId=${ackId}`,
    method: "get",
  });
}

export function getCorpPreviewFormByAckId(ackId) {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios({
    url: `/hpmc/api/corp-reg/corporates/cho1form/${ackId}`,
    method: "post",
    headers,
    responseType: "blob",
  });
}

export function downloadZipDoc(corpId) {
  let url = `/hpmc/api/corp-reg/corporates/downloaddoczip/${corpId}`;
  window.open(url, "_blank", "noopener,noreferrer");
}

export function getCorpCsvFile(dateOne, dateTwo) {
  let url = `/hpmc/api/corp-reg/corporates?startDate=${dateOne}&endDate=${dateTwo}&exportCsv=1`;
  window.open(url, "_blank", "noopener,noreferrer");
}
export function getCorpFullCsvFile(dateOne, dateTwo) {
  let url = `/hpmc/api/corp-reg/corporates?startDate=${dateOne}&endDate=${dateTwo}&exportFullCsv=1&filterBy=onboarded`;
  window.open(url, "_blank", "noopener,noreferrer");
}
export function sendCorpWelcomeKit(corpId) {
  return axios({
    url: `/hpmc/api/corp-reg/corporates/sendwelcomekit/${corpId}`,
    method: "post",
    responseType: "json",
  });
}

export function getPotentialUser(corpId) {
  return axios({
    url: `/hpmc/api/corp-reg/corporates/potentialusers/${corpId}`,
    method: "get",
    responseType: "json",
  });
}

export function assignPotentialUser(corpId, userId) {
  return axios({
    url: `/hpmc/api/corp-reg/corporates/assigntask/${corpId}/${userId}`,
    method: "post",
    responseType: "json",
  });
}

export function ScrutinyRejectReasons() {
  return axios({
    url: `/hpmc/api/rejectionmasters`,
    method: "get",
    responseType: "json",
  });
}

export function corporatePreviewForm(corpId) {
  const headers = {
    token: "bawER8765#$&UIsdf=",
    "Content-Type": "application/json",
  };
  return axios({
    url: `/hpmc/api/corp-reg/corporates/choform/${corpId}`,
    method: "post",
    headers,
    responseType: "blob",
  });
}

// get bank details
export function getBankDetails(ifsc) {
  return axios({
    url: `/hpmc/api/corp-reg/bankdetails?ifsc=${ifsc}`,
    method: "get",
    responseType: "json",
  });
}

// get form charges
export function getFromChargesFromMaster() {
  return axios({
    url: `/hpmc/api/charges`,
    method: "GET",
    responseType: "json",
  });
}

// get pfm for corporate form
export function getCorporatePfm() {
  return axios({
    url: `/hpmc/api/pfms/?findAll=1`,
    method: "get",
    responseType: "json",
  });
}

// verify bank details
export function verifyBankDetails(bankDetails, corpId) {
  return axios({
    url: `/hpmc/api/corp-reg/verifybankaccount/${corpId}`,
    data: bankDetails,
    method: "POST",
    responseType: "json",
  });
}

//  get auto populate data of corporate
export function autoPopulateCorporateFrom(pan) {
  return axios({
    url: `/hpmc/api/corp-reg/corporates/autopopulate`,
    data: pan,
    method: "POST",
    responseType: "json",
  });
}

export function getAreaDetailsFromPinCode(pin, accessor) {
  return axios({
    url: `/hpmc/api/corp-reg/areadetails?pin=${pin}&type=${accessor}`,
    method: "GET",
    responseType: "json",
  });
}

export function corporateSendReminder(corpId) {
  return axios({
    url: `/hpmc/api/corp-reg/corporates/sendreminder/${corpId}`,
    method: "GET",
    responseType: "json",
  });
}

export function getSingleCorpExportCSV(corpId) {
  let url = `/hpmc/api/corp-reg/corporates/workflow/${corpId}?exportCsv=1`;
  window.open(url, "_blank", "noopener,noreferrer");
}

// get all CRA
export function getAllCRA() {
  return axios({
    url: `/hpmc/api/cras`,
    method: "GET",
    responseType: "json",
  });
}

// get all POP of respective CRA
export function getAllPOPOFCRA(CRAId) {
  return axios({
    url: `/hpmc/api/popmasters?craId=${CRAId}`,
    method: "GET",
    responseType: "json",
  });
}

// authenticate corporate pan if registered
export function authenticateCorporatePan(pan) {
  return axios({
    url: `/hpmc/api/corp-reg/checkpan`,
    method: "POST",
    responseType: "json",
    data: pan,
  });
}

export function getAllStates() {
  return axios({
    url: `/hpmc/api/state`,
    method: "GET",
    responseType: "json",
  });
}

export function getAllDistricts(stateId) {
  return axios({
    url: `/hpmc/api/district/state/${stateId}`,
    method: "GET",
    responseType: "json",
  });
}

export function checkStateWithGST(gst) {
  return axios({
    url: `/hpmc/api/statebyGst`,
    method: "POST",
    responseType: "json",
    data: gst,
  });
}

export function editCorporateMasterForm(id) {
  return axios({
    url: `/hpmc/api/corpmaster/corpmasters/${id}`,
    method: "get",
    responseType: "json",
  });
}
