import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const AccordionComponent = (props) => {
  return (
    <Accordion defaultExpanded={props?.defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {props?.title}
        </AccordionSummary>
        <AccordionDetails>
        {props?.children}
        </AccordionDetails>
      </Accordion>
  )
}

export default AccordionComponent