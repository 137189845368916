import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Utils from "../../Utils";
import { loginUser, logoutUser } from "../Auth/AuthActions";
import LoginForm from "../Auth/components/LoginForm";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "./HomeSlice";

function Login(props) {
  const [showError, setShowEror] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loginHandler = (loginDetailsObj) => {
    loginUser(loginDetailsObj).then((response) => {
      if (response && response.data) {
        if (response.data.success) {
          setShowEror(false);
        } else {
          setShowEror(true);
          setErrorMessage(t("login.wrongCredentials"));
        }
      } else {
        if (
          response?.response?.status === 404 ||
          response?.response?.status === 504
        ) {
          setShowEror(true);
          setErrorMessage(t("login.failedRequest"));
        } else if (
          !response?.response?.data?.success ||
          !response?.response?.data?.data?.success
        ) {
          dispatch(
            setAlertMsg({
              msg:
                response?.response?.data?.message ||
                response?.response?.data?.data?.message,
              alertType: "danger",
            })
          );
        }
      }
    });
  };

  function errorDialogeCloseHandler() {
    setShowEror(false);
  }

  return (
    <>
      {/* <Header logoutUser={logoutUser} /> */}
      {showError &&
        Utils.showErrorDialog(errorMessage, errorDialogeCloseHandler)}

      <LoginForm handleLogin={loginHandler} />
    </>
  );
}

export default Login;
