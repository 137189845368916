import browserHistory from "../../../history.js";
import axios from "axios";
import { BASE_URL, SIGN_IN_URL } from "../../Routes/Urls.js";

export function loginUser(creds) {
  return axios({
    url: "/hpmc/api/auth/login",
    method: "post",
    data: creds,
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        browserHistory.push(BASE_URL);
        browserHistory.go();
      }
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function sendOTPtoNumber(mobileObj) {
  return axios({
    url: "/hpmc/api/otp/mobile/send",
    method: "post",
    data: mobileObj,
    responseType: "json",
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export function validateMobileOTP(obj) {
  return axios({
    url: "/hpmc/api/auth/login",
    method: "post",
    data: obj,
    responseType: "json",
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
// export function loginUsingToken(token) {
//   return axios({
//     url: "/pax/user/token",
//     method: "put",
//     headers: {
//       Authorization: token,
//       Type: 'b2b'
//     }
//   })
//     .then((response) => {
//       if (response.data.success) {
//         browserHistory.push("/pax-partner/tenants");
//         browserHistory.go();
//       }
//       return response;
//     }).catch((error) => {
//       return error;
//     });
// }

export function logoutUser(skipRedirect) {
  return axios({
    url: "/hpmc/api/auth/logout",
    method: "post",
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        if (skipRedirect !== true) {
          browserHistory.push(`/${BASE_URL}/${SIGN_IN_URL}`);
          browserHistory.go();
        }
      }
      return response;
    })
    .catch((error) => {
      return error;
    });
}
