import React, { useState } from "react";
import Classes from "./CustomTable.module.css";
import Pagination from "@mui/material/Pagination";
import Utils from "../../Utils";

const CustomTable = ({
  data,
  headings,
  pageData,
  page,
  setPage,
  isPaginationShow,
  srNO,
  handleTableRowColor,
  tableRow,
}) => {
  const handlePageChange = (e, value) => {
    setPage(value - 1);
  };

  // Check if handleClick is a function before invoking it
  const handleClickWrapper = (arg) => {
    if (tableRow) {
      return handleTableRowColor(arg);
    }
  };
  return (
    <>
      <div className="table-responsive">
        <table
          className={"table table-hover bg-white" + " " + Classes.custom_table}
        >
          <thead className="table-light ">
            <tr>
              {srNO && (
                <th className={Classes.table_th_srno}>
                  SR NO.
                  {/* <img src={ArrowUPIcon} /> */}
                </th>
              )}
              {headings &&
                headings.map((heading, key) => (
                  <th
                    className={Classes.table_th}
                    key={key}
                    style={
                      heading?.widthStyle && { width: `${heading?.widthStyle}` }
                    }
                  >
                    {heading.header}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map((ele, dataIndex) => (
                <tr
                  style={{
                    background: handleClickWrapper(ele?.highlight),
                  }}
                  key={dataIndex}
                >
                  {srNO && (
                    <td className={Classes.table_value}>
                      {pageData?.pageable?.offset == null
                        ? dataIndex + 1
                        : pageData?.pageable?.offset + dataIndex + 1}
                    </td>
                  )}
                  {headings &&
                    headings.map((heading, id) =>
                      // new commited changes
                      heading.toolTipStatus == true ? (
                        <td
                          key={id}
                          title={heading.accessor(ele, dataIndex)}
                          className={!heading?.isTextCenter && "text-left"}
                        >
                          <span className={`${Classes.table_value} text-left`}>
                            {heading?.shortlenText?.isShown == true
                              ? Utils.ShortLengthFunction(
                                  heading.accessor(ele, dataIndex),
                                  heading.shortlenText.len
                                )
                              : heading.accessor(ele, dataIndex)}{" "}
                          </span>
                        </td>
                      ) : (
                        <td key={id}>
                          <span className={Classes.table_value}>
                            {heading.accessor(ele, dataIndex)}
                          </span>
                        </td>
                      )
                    )}
                </tr>
              ))
            ) : (
              <tr className="text-center w-100">
                <td colSpan={headings.length + 1}>No Data</td>
              </tr>
            )}
          </tbody>
        </table>
        {!isPaginationShow && (
          <div className={Classes.pagination_conatiner}>
            {pageData && (
              <Pagination
                page={page + 1}
                onChange={handlePageChange}
                count={pageData.totalPages}
                shape="rounded"
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CustomTable;
