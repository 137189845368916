import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allUsers: { tenants: [], currentUser: {} },
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    fetchTenants: (state, action) => {
      state.allUsers.tenants = action.payload;
    },
    fetchProfile: (state, action) => {
      state.allUsers.currentUser = action.payload.user;
    },
  },
});

export const usersActions = usersSlice.actions;

export default usersSlice.reducer;
