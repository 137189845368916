import { createSlice } from "@reduxjs/toolkit";

const EntityRowId = createSlice({
  name: "EntityRowId",
  initialState: {},
  reducers: {
    RowId: (state, action) => {
      // Update the state with the payload value
      return action.payload;
    },
  },
});

const CheckerID = createSlice({
  name: "CheckerID",
  initialState: "",
  reducers: {
    CheckerId: (state, action) => {
      return action.payload;
    },
  },
});

export const { RowId } = EntityRowId.actions;
export const { CheckerId } = CheckerID.actions;

export const EntityRowIdReducer = EntityRowId.reducer;
export const CheckerIDReducer = CheckerID.reducer;
