import React from "react";
import { useLocation, useNavigate } from "react-router";
import Classes from "./GoBackButton.module.css";
import Utils from "../../Utils";
import rightarrow from "../../assets/arrow-back-outline.svg";

const GoBackButton = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <button onClick={() => navigate(-1)} className={Classes.back_btn}>
      <img src={rightarrow} />
      GO BACK
    </button>
  );
};

export default GoBackButton;
