import React, { forwardRef, useEffect } from "react";
import classes from "./PreViewForm.module.css";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";

const PreViewForm = forwardRef(
  (
    { handleSubmitCorporateForm, setIsPreview, corporatePreviewData, showBtn },
    ref
  ) => {
    return (
      <form ref={ref} className={classes.form_container}>
        {corporatePreviewData && (
          <iframe
            src={`${corporatePreviewData}#view=FitH`}
            className={classes.preview_iframe}
            sandbox
          ></iframe>
        )}

        {showBtn && (
          <div className={classes.previewform_btn}>
            {handleSubmitCorporateForm && (
              <PrimaryButton
                style={{ marginRight: "1rem" }}
                onClick={() => handleSubmitCorporateForm("submit")}
                label={"Submit"}
              />
            )}

            {setIsPreview && (
              <SecondaryButton
                label={"Cancel"}
                onClick={() => setIsPreview(false)}
              />
            )}
          </div>
        )}
      </form>
    );
  }
);

export default PreViewForm;
