import React, { useState, useEffect } from 'react';

const Timer = ({ startTime, isEnd, reStart }) => {
  const [totalSeconds, setTotalSeconds] = useState(startTime);
  let timer;

  useEffect(() => {
    timer = setInterval(() => {
      setTotalSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, [startTime]);

  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  useEffect(() => {
    if (totalSeconds === 0) {
      isEnd(true);
      clearInterval(timer);
    }
  }, [totalSeconds, isEnd]);

  useEffect(() => {
    if (reStart) {
      setTotalSeconds(startTime);
    }
  }, [reStart]);

  return `${String(minutes).padStart(2, '0')} : ${String(seconds).padStart(2, '0')}`;
};

export default Timer;
