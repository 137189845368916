import React, { useEffect } from "react";
import TextButton from "../../components/Buttons/TextButton";
import classes from "./Tabs.module.css";

export default function TabComp({
  label,
  tabValue,
  setTabValue,
  setfilterBy,
  setExportAll,
  formSectionErrorArray,
  clickAble,
}) {
  const handleChange = (key, index, pageType, clickElem) => {
    if (key || key === "") {
      exportToggle(pageType, clickElem);
      setfilterBy(key);
    }
    if (setTabValue) {
      setTabValue(index);
    }
  };

  const exportToggle = (pageType, clickElem) => {
    if (pageType === "corporateDashboard" && clickElem == "onboarded") {
      setExportAll(true);
    } else {
      setExportAll(false);
    }
  };

  const showAlert = (subSection) => {
    return subSection?.some((section, id) =>
      formSectionErrorArray.includes(section.section_id)
    );
  };

  return (
    <p className={classes.tabs_container}>
      {label &&
        label.map(
          (data, index) =>
            data.title && (
              <TextButton
                onClick={() => {
                  handleChange(data.key, index, data.pageType, data.click);
                }}
                value={index}
                label={data?.title}
                style={{
                  fontSize: "1rem",
                  heigth: "4rem",
                  borderRadius: "0rem",
                  color: showAlert(data?.Sub_section)
                    ? "var(--app-text-red-color)"
                    : tabValue === index
                    ? ""
                    : "var(--app-text-dim-grey-color)",

                  borderBottom:
                    tabValue === index
                      ? ".125rem solid var(--app-btn-background)"
                      : "",
                  fontFamily: tabValue === index ? "Medium" : "",
                  cursor: clickAble === true ? "pointer" : "context-menu",
                }}
                key={data?.key || index}
              />
            )
        )}
    </p>
  );
}
