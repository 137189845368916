import React from "react";
import classes from "./AdminManagement.module.css";
import Card from "../../components/Card/Card";
import { useTranslation } from "react-i18next";
import UserManagementImg from "../../assets/CardsImgs/UserManagement.png";
import ReportImg from "../../assets/CardsImgs/Report.png";
import AuthImg from "../../assets/CardsImgs/Authorization.png";
import MasterManagementImg from "../../assets/CardsImgs/MasterManagement.png";

import {
  ADMIN_URL,
  AUTHMANAGEMENT_URL,
  BASE_URL,
  MASTERMANAGEMENT_URL,
  MISREPORT_URL,
  USER_MANAGEMENT_DASHBOARD_URL,
  USER_URL,
} from "../../Routes/Urls";
import { useNavigate } from "react-router";

function AdminManagement() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleCardClick = (path) => {
    navigate(`/${BASE_URL}/${ADMIN_URL}/${path}`);
  };

  const adminCardObj = [
    {
      path: USER_MANAGEMENT_DASHBOARD_URL,
      label: t("admin.users"),
      img: UserManagementImg,
    },
    {
      path: MISREPORT_URL,
      label: t("misreport.master"),
      img: ReportImg,
    },
    {
      path: AUTHMANAGEMENT_URL,
      label: t("admin.permission"),
      img: AuthImg,
    },
    {
      path: MASTERMANAGEMENT_URL,
      label: t("admin.master"),
      img: MasterManagementImg,
    },
  ];

  return (
    <div className={classes.card_container}>
      <h1>Management</h1>
      <div className={classes.cards}>
        {adminCardObj.map((data, key) => {
          return (
            <div key={key} className={classes.card}>
              <Card
                onClick={() => handleCardClick(data.path)}
                label={t(data.label)}
                width="100%"
                height="100%"
                img={data.img}
              ></Card>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AdminManagement;
