import React, { useEffect, useState } from "react";
import classes from "./MISReport.module.css";
import GoBackButton from "../../components/Buttons/GoBackButton";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import Utils from "../../Utils";
import TextButton from "../../components/Buttons/TextButton";
import SearchIcon from "../../assets/SearchIcon_grey.svg";
import Modal from "../../components/Modal/Modal";
import { useTranslation } from "react-i18next";
import ObjForm from "../../components/ObjForm/ObjForm";
import formObj from "../CorponboardingForm/corporateObj.json";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import downloadIcon from "../../assets/download_icon.svg";
import {
  CSVDownloadMISReportPage,
  PDFDownloadMISReportPage,
} from "../AdminManagement/AdminAction";
import DoubleDatePicker from "../../components/DatePicker/DoubleDatePicker";
import Select from "../../components/Select/Select";
import AuditReport from "./Part/AuditReport";
import CorporateFRReport from "./Part/CorporateFRReport";
import { setAlertMsg } from "../Home/HomeSlice";
import { useDispatch } from "react-redux";
import CorporateCHOAutoPopulate from "./Part/CorporateCHOAutoPopulate";
import ApiReport from "./Part/ApiReport";
import UserReport from "./Part/UserReport";

const MISReport = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [perPage, setperPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [masterList, setMasterList] = useState([
    {
      id: 1,
      tableName: "AuditReport",
      name: "Audit Trail Report",
      url: "audit",
    },
    {
      id: 2,
      tableName: "FRReport",
      name: "FR Report",
      url: "fr/corporate",
    },
    {
      id: 3,
      tableName: "CorporateCHOAutoPopulate",
      name: "Corporate CHO Auto Populate Report",
      url: "choautopopulate",
    },
    {
      id: 4,
      tableName: "APIReport",
      name: "API Report",
      url: "api-report",
    },
    {
      id: 5,
      tableName: "UserReport",
      name: "User Report",
      url: "user-report",
    },
  ]);
  const [dynamicMasterList, setDynamicMasterList] = useState([]);
  const dispatch = useDispatch();
  const [searchListName, setSearchListName] = useState("");

  const [resetValue, setResetValue] = useState(false);
  const [currMasterId, setCurrMasterId] = useState(1);
  const [currMasterName, setCurrMasterName] = useState("");
  const [searchingQuery, setSearchingQuery] = useState("");

  // pdf and csv
  const [baseUrlData, setBaseUrlData] = useState("");

  const [perPageArr, setPerPageArr] = useState([
    { val: 10, text: "10 per page" },
    { val: 20, text: "20 per page" },
    { val: 50, text: "50 per page" },
  ]);
  var dateOne = "";
  var dateTwo = "";
  dateOne =
    startDate == "" ? startDate : Utils.convertToLocalDateForInput(startDate);
  dateTwo = endDate == "" ? endDate : Utils.convertToLocalDateForInput(endDate);

  const [tableFunctionName, setTableFunctionName] = useState({
    AuditReport: {
      name: "AuditReport",
      tableHeading: "Audit Trail Reports",
      placeholder: "Search by Username/Action",
      isDateActive: true,
    },
    FRReport: {
      name: "FRReport",
      tableHeading: "FR Report",
      placeholder: "Search by AckId/Status",
      isDateActive: true,
    },
    CorporateCHOAutoPopulate: {
      name: "CorporateCHOAutoPopulate",
      tableHeading: "Corporate CHO Auto Populate",
      placeholder: "Search by ACK ID/Name",
      isDateActive: false,
    },
    APIReport: {
      name: "APIReport",
      tableHeading: "API Report",
      placeholder: "Search by User Name/API Name",
      isDateActive: false,
    },
    UserReport: {
      name: "UserReport",
      tableHeading: "User Report",
      placeholder: "Search by Action/Email",
      isDateActive: false,
    },
  });

  useEffect(() => {
    setCurrentMasterFunction(masterList[0]);
  }, []);

  // csv download
  const downloadCSVFunction = () => {
    CSVDownloadMISReportPage(
      baseUrlData,
      searchingQuery,
      dateOne,
      dateTwo,
      1
    )?.then((res) => {
      dispatch(
        setAlertMsg({
          msg: `Downloaded Successflly`,
          alertType: "success",
        })
      );
    });
  };
  const downloadPDFFunction = () => {
    PDFDownloadMISReportPage(
      baseUrlData,
      searchingQuery,
      dateOne,
      dateTwo,
      1
    )?.then((res) => {
      dispatch(
        setAlertMsg({
          msg: `Downloaded Successflly`,
          alertType: "success",
        })
      );
    });
  };
  const FirstDateFunction = (e) => {
    setStartDate(e);
    setSearchListName("");
    setSearchInput("");
    setSearchingQuery("");
    setPage(0);
    setperPage(10);
  };
  const SecondDateFunction = (e) => {
    setEndDate(e);
    setSearchListName("");
    setSearchInput("");
    setSearchingQuery("");
    setPage(0);
    setperPage(10);
  };

  const getQueryData = (e) => {
    e.preventDefault();
    setSearchingQuery(searchInput);
    setPage(0);
    setperPage(10);
  };

  // search master
  const filterBySearchMaster = (event) => {
    const query = Utils.PreventSpecialCharacterForEmail(event);
    setSearchListName(query);
    if (query.trim() === "") {
      setDynamicMasterList(masterList);
    } else {
      var updatedList = masterList;
      const filteredItems = updatedList.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      setDynamicMasterList(filteredItems);
    }
  };
  // set current master
  const setCurrentMasterFunction = (data) => {
    setSearchListName("");
    setSearchInput("");
    setSearchingQuery("");
    setperPage(10);
    setPage(0);
    setStartDate("");
    setEndDate("");
    setCurrMasterId(data.id);
    setCurrMasterName(data.tableName);
    setBaseUrlData(data.url);

    setDynamicMasterList(masterList);

    if (currMasterId != data.id) {
      setResetValue(true);
    } else {
      setResetValue(false);
    }
  };

  useEffect(() => {
    setSearchListName("");
  }, [currMasterId]);

  const renderedTable = () => {
    switch (currMasterName) {
      case masterList[0]?.tableName:
        return (
          <AuditReport
            searchingQuery={searchingQuery}
            dateOne={dateOne}
            dateTwo={dateTwo}
            perPage={perPage}
          />
        );
      case masterList[1]?.tableName:
        return (
          <CorporateFRReport
            searchingQuery={searchingQuery}
            dateOne={dateOne}
            dateTwo={dateTwo}
            perPage={perPage}
          />
        );
      case masterList[2]?.tableName:
        return (
          <CorporateCHOAutoPopulate
            searchingQuery={searchingQuery}
            dateOne={dateOne}
            dateTwo={dateTwo}
            perPage={perPage}
          />
        );
      case masterList[3]?.tableName:
        return (
          <ApiReport
            searchingQuery={searchingQuery}
            dateOne={dateOne}
            dateTwo={dateTwo}
            perPage={perPage}
          />
        );
      case masterList[4]?.tableName:
        return (
          <UserReport
            searchingQuery={searchingQuery}
            dateOne={dateOne}
            dateTwo={dateTwo}
            perPage={perPage}
          />
        );
    }
  };

  const handleDropDownOption = (name, options) => {
    return options;
  };

  return (
    <div className={classes.master_container}>
      <div className={classes.goBackContainer}>
        <GoBackButton />
      </div>
      <div className={classes.header_container}>
        <div className={classes.mis_report_header}>
          <div className="heading_left mr-auto">
            <h1 className={classes.master_header}>MIS Reports</h1>
            <p>Here you can manage all your reports</p>
          </div>
          <div className="right_heading_button">
            <div className={`d-flex align-items-end ${classes.gap_20}`}>
              <div
                className={classes.link_button}
                onClick={() => downloadCSVFunction(1)}
              >
                <i>
                  <img src={downloadIcon} />
                </i>
                Download CSV
              </div>
              <div
                className={classes.link_button}
                onClick={() => downloadPDFFunction(1)}
              >
                <i>
                  <img src={downloadIcon} />
                </i>
                Download PDF
              </div>
            </div>
          </div>
        </div>

        <div className={classes.master_body_container}>
          <div className={classes.sidebar_container}>
            <div className={classes.sidebar_header}>
              <h4>List of all MIS Reports</h4>
            </div>
            <div className={classes.master_searchbar}>
              <img src={SearchIcon} />
              <input
                type="text"
                value={searchListName}
                placeholder="Search Name"
                onChange={filterBySearchMaster}
              />
            </div>
            <div className={classes.sidebar_list}>
              {dynamicMasterList?.map((data, index) => {
                return (
                  <p
                    onClick={() => {
                      setCurrentMasterFunction(data);
                    }}
                    key={index}
                    className={` ${
                      currMasterId === index + 1
                        ? classes.active_list
                        : classes.unactive_list
                    }
                      `}
                  >
                    {data.name}
                    <span className={classes.checker_flow}>
                      {data.isWorkflowEnabled ? "*" : ""}
                    </span>
                  </p>
                );
              })}
            </div>
          </div>
          <div className={classes.master_table}>
            <div className={classes.table_header}>
              {currMasterName && (
                <h4> {tableFunctionName[currMasterName]?.tableHeading}</h4>
              )}
            </div>
            <div className={classes.table_searchBar}>
              <div className="col-lg-5 col-md-6 col-12">
                <form
                  onSubmit={getQueryData}
                  className={classes.header_searchbar}
                >
                  <p>
                    <img src={SearchIcon} />
                    <input
                      value={searchInput}
                      onChange={(e) =>
                        setSearchInput(Utils.PreventSpecialCharacterForEmail(e))
                      }
                      placeholder={
                        tableFunctionName[currMasterName]?.placeholder ||
                        "Search By"
                      }
                    />
                  </p>
                  <PrimaryButton
                    style={{
                      height: "100%",
                      borderRadius: "0rem  .5rem .5rem 0rem ",
                    }}
                    onClick={getQueryData}
                    label={t("label.search")}
                  />
                </form>
              </div>

              <div className="mr-auto">
                {tableFunctionName[currMasterName]?.isDateActive && (
                  <DoubleDatePicker
                    placeHolder1={"Start Date"}
                    placeHolder2={"End Date"}
                    FirstDateFunction={FirstDateFunction}
                    SecondDateFunction={SecondDateFunction}
                    min1={0}
                    max1={endDate || new Date()}
                    min2={startDate}
                    max2={new Date()}
                    resetValue={resetValue}
                    setResetValue={setResetValue}
                  />
                )}
              </div>
              <Select
                onChangeHandler={setperPage}
                dataObj={perPageArr}
                pageChange={setPage}
                selectedData={perPage}
              />
            </div>
            <div className={classes.report_table}>{renderedTable()}</div>
          </div>
        </div>
      </div>

      {isEdit && (
        <Modal>
          <div className={classes.editModal_container}>
            <h4> {t("proceed.edit.master")}</h4>
            <p>{t("proceed.edit.master.text")}</p>
            <div className={classes.editModal_btn}>
              <PrimaryButton
                onClick={() => {
                  setIsForm(true);
                  setIsEdit(false);
                }}
                label={t("button.proceed")}
              />
              <TextButton
                onClick={() => {
                  setIsEdit(false);
                }}
                label={t("button.close")}
              />
            </div>
          </div>
        </Modal>
      )}

      {isForm && (
        <Modal onClose={() => setIsForm(false)}>
          <div className={classes.form_container}>
            <div className={classes.form_modal_title}>
              <h5>{t("edit.corporate.master")}</h5>
              <p>
                *Modification to these master, will trigger maker checker worker
                flow
              </p>
            </div>
            <div className={classes.form_modal_body}>
              <div>
                {formObj.map((data, index) => {
                  return (
                    <p
                      className={` ${
                        index === currentStep
                          ? classes.active
                          : classes.unactive
                      }
                      `}
                      onClick={() => setCurrentStep(index)}
                      key={index}
                    >
                      {data.header}
                    </p>
                  );
                })}
              </div>
              <div>
                <ObjForm
                  handleDropDownOption={handleDropDownOption}
                  currentStep={currentStep}
                  formObj={formObj}
                />
                <div className={classes.edit_form_btn}>
                  <PrimaryButton label={t("button.save")} />
                  <SecondaryButton
                    onClick={() => setIsForm(false)}
                    label={t("button.cancel")}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MISReport;
