import React from "react";
import Classes from "./SubscriptionRequirementModal.module.css";
import Modal from "../../../../components/Modal/Modal";
import { useState } from "react";
import fileIcon from "../../../../assets/file_icon.svg";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import verified_doc_icon from "../../../../assets/NPSRegistrationIcons/verified_doc.svg";
import bankIcon  from "../../../../assets/NPSRegistrationIcons/bank.svg";
import signIcon   from "../../../../assets/NPSRegistrationIcons/sign_icon.svg";

const SubscriptionRequirementModal = ({
  requiredModal,
  setRequiredModal,
  handleFunction,
}) => {
  const [contentPart, setContentPart] = useState([
    {
      name: "PAN",
      icons : verified_doc_icon
    },
    {
      name: "Aadhar card",
      icons : verified_doc_icon
    },
    {
      name: "Bank Details / UPI",
      icons : bankIcon
    },
    {
      name: "signature",
      icons : signIcon
    },
    
   
  ]);
  return (
    requiredModal && (
      <Modal style={{ borderRadius: "1.2rem" }}>
        <div className={Classes.subscriptionRequirementModal}>
          <div className={Classes.head_part}>
            <h2>
              Keep these documents at hand for a more{" "}
              <span>efficient process</span>
            </h2>
            <p>
              The prime objective of the scheme is to provide all citizens of
              India with an attractive long-term savings avenue to plan for
              retirement through safe and reasonable market-based returns.
            </p>
          </div>
          <div className={Classes.body_part}>
            <div className={Classes.grid_box}>
              {contentPart &&
                contentPart.map((data, index) => (
                  <div className={Classes.box} key={index}>
                    <div className={Classes.box_aligning}>
                      <div className={Classes.icon_area}>
                        <img src={data?.icons} width={"100%"} />
                      </div>
                      <div className={Classes.content_area}>
                        <h6>{data.name}</h6>
                        <p>{data.text}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className={Classes.button_area}>
            <div className="btn-group mr-4">
              <PrimaryButton
                label={"Proceed"}
                onClick={() => handleFunction()}
              />
            </div>
            <div className="btn-group">
              <SecondaryButton
                label={"Close"}
                onClick={() => setRequiredModal(false)}
              />
            </div>
          </div>
        </div>
      </Modal>
    )
  );
};

export default SubscriptionRequirementModal;
