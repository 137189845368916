import React, { useEffect, useState } from "react";
import classes from "./ReviewCorporateMaster.module.css";
import ObjForm from "../../../../../components/ObjForm/ObjForm";
import {
  editCorporateMasterForm,
  getCorpFormData,
} from "../../../../CorponboardingForm/CorporateAction";
import { submitMakerChecker } from "../../../AdminManagementActions";
import formObj from "../../../../AdminManage/MasterManagement/Part/JsonFileCorporate/CorporateManagement.json";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import GoBackButton from "../../../../../components/Buttons/GoBackButton";
import infoIcon from "../../../../../assets/info_attention_orange_icon.svg";
import crossIconSVG from "../../../../../assets/cross-x-icon.svg";
import Modal from "../../../../../components/Modal/Modal";
import rejectfileIcon from "../../../../../assets/reject_document.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  ADMIN_URL,
  BASE_URL,
  MAKER_CHECKER_TASK_URL,
  MASTERMANAGEMENT_URL,
} from "../../../../../Routes/Urls";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../../../Home/HomeSlice";
import { getAllRMUser } from "../../../UserManagement/UserManagementActions";
import Utils from "../../../../../Utils";

const ReviewCorporateMaster = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { checkerid, rowID } = useSelector((state) => state);

  const [isForm, setIsForm] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [customRejReason, setCustomRejReason] = useState(false);
  const [selectedReason, setSelectedReason] = useState([]);
  const [rejCheckButton, setRejCheckButton] = useState(true);
  const [proceedData, setProceedData] = useState({
    isApproved: "",
    reason: "",
  });
  const [ackId, setAckId] = useState("");
  const rejectReasons = [
    "Spelling",
    "Not a Correct Document",
    "Spelling Mistake",
    "Invalid Id",
  ];

  const [corpFormData, setCorpFormData] = useState({});
  const [checkerData, setCheckerData] = useState({});
  const [headerChangedIcon, setHeaderChangedIcon] = useState({});
  const [allRMUser, setAllRMUser] = useState([]);

  useEffect(() => {
    editCorporateMasterForm(rowID).then((res) => {
      var output = res.data.data?.masterData;

      if (res.data.data?.checkerData?.hasOwnProperty("rm_id")) {
        // get all user

        handleGetAllUser(res.data.data?.checkerData, output);
      } else {
        setCheckerData(res.data.data?.checkerData);
        setCorpFormData((data) => ({
          ...output,
          docVerifiedMark: data.docVerifiedMark === true ? "Yes" : "No",
          isPopShift:
            data.isPopShift === true ? "POP Shift" : "New Registration",
          corpNpsRegDate: Utils.convertToLocalDateForInput(
            output.corpNpsRegDate
          ),
          corpRegDate: Utils.convertToLocalDateForInput(output.corpRegDate),
          hierarchy: output?.hierarchy
            ?.map((user) => user?.username)
            ?.join(" -> "),
        }));
      }
      setAckId(output.ackId);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(checkerData)?.length > 0) {
      for (var i = 0; i < formObj.length; i++) {
        const sectionArray = formObj[i].section;
        const headingName = formObj[i].header;

        if (headerChangedIcon[headingName]) {
          continue;
        }

        for (var j = 0; j < sectionArray.length; j++) {
          const fieldsArray = sectionArray[j].Fields;

          for (var k = 0; k < fieldsArray.length; k++) {
            const accessor = fieldsArray[k].accessor;

            if (checkerData[accessor] !== undefined) {
              setHeaderChangedIcon((data) => ({
                ...data,
                [headingName]: true,
              }));
              break;
            }
          }
        }
      }
    }
  }, [checkerData]);

  const handleGetAllUser = (checkData, output) => {
    getAllRMUser()
      .then((res) => {
        if (res.data.success === true) {
          setAllRMUser(res.data.data);
          let obj = res.data.data?.filter(
            (user) => user?.id === Number(checkData?.rm_id)
          );
          setCorpFormData({
            ...output,
            rmEmail: obj[0]?.username,
            rmPhone: obj[0]?.contactNo,
            rmName: obj[0]?.fullName,
            rmZone: obj[0]?.rmZone,
            zonalManager: obj[0]?.zonalManager,
            docVerifiedMark: output.docVerifiedMark === true ? "Yes" : "No",
            isPopShift:
              output.isPopShift === true ? "POP Shift" : "New Registration",
            corpNpsRegDate: Utils.convertToLocalDateForInput(
              output.corpNpsRegDate
            ),
            corpRegDate: Utils.convertToLocalDateForInput(output.corpRegDate),
            hierarchy: obj[0]?.hierarchy
              ?.map((user) => user?.username)
              ?.join(" -> "),
          });

          setCheckerData({
            ...checkData,
            rmEmail: obj[0]?.username,
          });
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: err.response.data?.message,
            alertType: "danger",
          })
        );
      });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // for corporate application modal
  function closeModal() {
    setIsModal(false);
  }
  const selectedReasonFunction = (e) => {
    if (e != "others") {
      if (!selectedReason.includes(e)) {
        setSelectedReason([...selectedReason, e]);
        setRejCheckButton(false);
      }
    } else {
      setCustomRejReason(true);
    }
    if (selectedReason.length > 0) {
      setRejCheckButton(false);
    }
  };
  //   remove selected reasons
  const removeselectedReasonFunction = (item) => {
    setSelectedReason((old) => {
      return old.filter((e) => e !== item);
    });
  };

  // check rejCheckButtonStatus
  const rejCheckButtonStatus = () => {
    if (selectedReason.length < 2) {
      setRejCheckButton(true);
    } else {
      setRejCheckButton(false);
    }
  };

  // rejected add reason function
  const rejectedReasonFormsFunction = (id) => {
    if (customRejReason) {
      let textareaData = document.getElementById("customreason").value;
      if (textareaData != null && textareaData.length > 0) {
        selectedReason.push(textareaData);
      }
    }
  };

  const proceedDataFunction = (id, approval) => {
    if (approval == true) {
      proceedData.isApproved = approval;
    } else {
      let allreason = selectedReason.join("|");
      proceedData.isApproved = approval;
      proceedData.reason = allreason;
    }
  };

  const submitHandle = (id, approval) => {
    submitMakerChecker(id, proceedData).then((res) => {
      if (res.data.success === true) {
        dispatch(
          setAlertMsg({
            msg:
              approval === true
                ? `Accepted  Successfully`
                : "Rejected Successfully",
            alertType: "success",
          })
        );
      }
      Navigate(
        `/${BASE_URL}/${ADMIN_URL}/${MASTERMANAGEMENT_URL}/${MAKER_CHECKER_TASK_URL}`
      );
    });
  };

  const handleDropDownOption = (name, options) => {
    let userArray = allRMUser.map((user) => user?.username);
    if (name === "rmEmail") {
      return userArray;
    }
    return options;
  };

  return (
    <>
      <section>
        <div className="container-fluid px-5 py-4">
          <div className="row">
            <GoBackButton />
          </div>
          <div className="row mb-4">
            <div className="col-lg-12">
              <div className={classes.head_section}>
                <div className={classes.front_part}>
                  <h5>Review Corporate Master</h5>
                  <p className="mt-2">
                    <i>
                      <img src={infoIcon} />
                    </i>
                    Fields marked yellow need to be reviewed
                  </p>
                </div>
                <div className={classes.end_part}>
                  <p className={classes.ackId}>
                    Your acknowledgement ID : {ackId}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-4 mt-4 pt-3">
              {formObj.map((data, index) => {
                return (
                  <p
                    className={
                      index === currentStep ? classes.active : classes.unactive
                    }
                    onClick={() => {
                      setCurrentStep(index);
                    }}
                    key={index}
                  >
                    {data.header}{" "}
                    {headerChangedIcon[`${data.header}`] ? (
                      <i className={classes.step_heading_icon}>
                        <img src={infoIcon} />
                      </i>
                    ) : (
                      ""
                    )}
                  </p>
                );
              })}
            </div>

            <div className="col-lg-8 col-md-8 col-8">
              <ObjForm
                currentStep={currentStep}
                formObj={formObj}
                disabledVal={true}
                checker={true}
                checkString="Changes are done in this Field"
                corpFormDetails={corpFormData}
                checkData={checkerData}
                handleDropDownOption={handleDropDownOption}
              />
              <div className={classes.edit_form_btn}>
                <PrimaryButton
                  onClick={() => {
                    proceedDataFunction(checkerid, true);
                    submitHandle(checkerid, true);
                  }}
                  label={t("button.accept")}
                />
                <PrimaryButton
                  onClick={() => {
                    setIsModal(true);
                  }}
                  label={t("button.reject")}
                />
                <SecondaryButton
                  onClick={() => {
                    setIsForm(false);
                    Navigate(-1);
                  }}
                  label={t("button.cancel")}
                />
              </div>
            </div>
            {/* <div className={classes.form_modal_body + " " + "mt-4 pt-2"}>
                  <div>
                    {formObj.map((data, index) => {
                      return (
                        <p
                          className={
                            index === currentStep
                              ? classes.active
                              : classes.unactive
                          }
                          onClick={() => {
                            setCurrentStep(index);
                          }}
                          key={index}
                        >
                          {data.header}{" "}
                          {headerChangedIcon[`${data.header}`] ? (
                            <i className={classes.step_heading_icon}>
                              <img src={infoIcon} />
                            </i>
                          ) : (
                            ""
                          )}
                        </p>
                      );
                    })}
                  </div>
                  <div>
                    <ObjForm
                      currentStep={currentStep}
                      formObj={formObj}
                      disabledVal={true}
                      checker={true}
                      checkString="Changes are done in this Field"
                      corpFormDetails={corpFormData}
                      checkData={checkerData}
                    />
                    <div className={classes.edit_form_btn}>
                      <PrimaryButton
                        onClick={() => {
                          proceedDataFunction(checkerid, true);
                          submitHandle(checkerid);
                        }}
                        label={t("accept")}
                      />
                      <PrimaryButton
                        onClick={() => {
                          setIsModal(true);
                        }}
                        label={t("button.reject")}
                      />
                      <SecondaryButton
                        onClick={() => setIsForm(false)}
                        label={t("button.cancel")}
                      />
                    </div>
                  </div>
                </div> */}
          </div>
        </div>
      </section>
      {isModal && (
        <Modal onClose={closeModal}>
          <div className={"p-4" + " " + classes.rejected_modal}>
            <div className="d-flex align-items-center justify-content-between flex-column">
              <h5>
                <img src={rejectfileIcon} className={classes.rejected_svg} />{" "}
                {t("document.rejected")}
              </h5>
            </div>

            <div className={"mt-4" + " " + classes.reject_document_name}>
              <p>{t("changes_by_maker.rejected")}</p>
            </div>

            <div className="mt-4 ">
              <p className={classes.normal_small_text + " " + "mb-2 "}>
                {t("rejected_document_reason.list.text")}
              </p>
            </div>
            <div className="reasons-part">
              <div className="rejected_reasons mt-1">
                <select
                  className="form-control form-select"
                  onChange={(e) => selectedReasonFunction(e.target.value)}
                >
                  <option selected disabled>
                    Select Reasons
                  </option>
                  {rejectReasons.map((item, id) => {
                    return <option value={item}>{item}</option>;
                  })}
                  <option value="others">Others</option>
                </select>
                <p className={classes.normal_small_text}>
                  <span>(You can select multiple reasons)</span>
                </p>
              </div>
              <div className={classes.selected_reasons}>
                <ul>
                  {selectedReason &&
                    selectedReason.map((item, id) => {
                      if (item != undefined) {
                        return (
                          <li key={id}>
                            {item}
                            <span
                              onClick={() => {
                                removeselectedReasonFunction(item);
                                rejCheckButtonStatus();
                              }}
                            >
                              <i className={classes.cross_icon_rejected}>
                                <img src={crossIconSVG} />
                              </i>
                            </span>
                          </li>
                        );
                      }
                    })}
                </ul>
              </div>

              {customRejReason && (
                <div className="custom_reason">
                  <textarea
                    className="textarea form-control w-100 rounded"
                    rows="3"
                    placeholder="write your reasons"
                    id="customreason"
                    onInput={(e) => {
                      e.target.value.length > 0
                        ? setRejCheckButton(false)
                        : selectedReason.length > 0
                        ? setRejCheckButton(false)
                        : setRejCheckButton(true);
                    }}
                  ></textarea>
                </div>
              )}
            </div>
            <div className="col-lg-12 mt-4 px-0">
              <div className="btn-group ml-auto mr-3">
                <PrimaryButton
                  disabled={rejCheckButton}
                  label={t("Submit")}
                  onClick={() => {
                    // rejectedFunction(true, isActiveDocumentName);
                    rejectedReasonFormsFunction(checkerid);
                    proceedDataFunction(checkerid, false);

                    submitHandle(checkerid, false);
                    setIsModal(false);
                  }}
                />
              </div>
              <div className="btn-group mr-3">
                <SecondaryButton
                  label={t("Close")}
                  onClick={() => setIsModal(false)}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ReviewCorporateMaster;
