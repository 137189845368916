import { createSlice } from "@reduxjs/toolkit";

export const opsSRFormData = createSlice({
  name: "srUpdateFormData",
  initialState: {},
  reducers: {
    setSrUpdateFormData: (state, action) => {
      return action.payload;
    },
  },
});

// actions
export const { setSrUpdateFormData } = opsSRFormData.actions;

// reducer
export const opsSRFormDataReducer = opsSRFormData.reducer;
