import React, { useEffect, useState } from "react";
import classes from "./Dashboard.module.css";
import Card from "../../components/Card/Card";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  BASE_URL,
  DASHBOARD_URL,
  CORPORATEDASHBOARD_URL,
  MAKER_CHECKER_TASK_URL,
  ADMIN_URL,
  MASTERMANAGEMENT_URL,
  CORP_CONTRIBUTION_DASHBOARD_URL,
  CORP_CONTRIBUTION_URL,
} from "../../Routes/Urls";
import FurtherRegImg from "../../assets/CardsImgs/Report.png";
import CorporateFromImg from "../../assets/CardsImgs/CorporateForm.png";
import ServiceRequestImg from "../../assets/CardsImgs/ServiceRequest.png";
import FurtherRequestImg from "../../assets/CardsImgs/FurtherRequest.png";
import ContributionImg from "../../assets/CardsImgs/Contribution.png";
import "../../Fonts/SohoGothicPro-Bold.otf";
import { useNavigate } from "react-router";
import MakerCheckerImg from "../../assets/CardsImgs/MakerChecker.png";
import MasterManagementImg from "../../assets/CardsImgs/MasterManagement.png";
import Utils from "../../Utils";
import Consts from "../../Consts";
import CorporateSubsImg from "../../assets/OPSDashboardCard/CorporateSubs.png";
import AgentImg from "../../assets/OPSDashboardCard/Agents.png";
import FurtherReqImg from "../../assets/OPSDashboardCard/FurtherReq.png";
import { setAlertMsg } from "../Home/HomeSlice";
import { checkIfCropContributionAllowed } from "../Home/HomeAction";

function Dashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.loginUserDetails);
  const { checkFunctionalAreas, checkRoleFunction } = Utils;
  const {
    CORP_FORM_FILL_UP,
    CORP_SCRUITNY,
    CORP_CHO_UPDATE,
    CORP_PROFILE_UPDATE,
    CORP_SERVICING,
    MAKERCHECKER_CHECKER,
    CHARGES_APPROVAL,
  } = Consts.FUNCTIONALITY_AREAS_ID;

  const [showCorpContributionCard, setShowCorpContributionCard] =
    useState(null);

  useEffect(() => {
    checkIfCropContributionAllowed(userDetails?.internalGroupRoles[0]?.id)
      .then((res) => {
        if (res.data.success === true) {
          setShowCorpContributionCard(res.data.data.status);
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({ msg: err.response?.data?.message, alertType: "danger" })
        );
      });
  }, []);

  const dashboardCardItems = [
    {
      label: "Corporate Journey",
      url: `${DASHBOARD_URL}/${CORPORATEDASHBOARD_URL}`,
      img: CorporateFromImg,
      authority: {
        adminCheck: true,
        functional_areas: [
          CORP_FORM_FILL_UP,
          CORP_SCRUITNY,
          CORP_CHO_UPDATE,
          CHARGES_APPROVAL,
        ],
      },
    },
    {
      label: "Contribution",
      url: `${DASHBOARD_URL}/${CORP_CONTRIBUTION_URL}`,
      img: ContributionImg,
      authority: {
        adminCheck: false,
        check_role: [
          {
            item: Consts.INTERNAL_GRP_ROLE_NAME_SUFFIX.NO.name,
          },
        ],
      },
    },
    {
      label: "Contribution",
      url: `${DASHBOARD_URL}/${CORP_CONTRIBUTION_DASHBOARD_URL}`,
      img: ContributionImg,
      authority: {
        adminCheck: false,
        exception: showCorpContributionCard,
      },
    },
    {
      label: "My Makers Checkers Task",
      url: `${DASHBOARD_URL}/${MAKER_CHECKER_TASK_URL}`,
      img: MakerCheckerImg,
      authority: {
        adminCheck: true,
        functional_areas: [MAKERCHECKER_CHECKER],
      },
    },
    {
      label: "Master Management",
      url: `${
        checkFunctionalAreas(
          [
            CORP_FORM_FILL_UP,
            CORP_SCRUITNY,
            CORP_CHO_UPDATE,
            CORP_PROFILE_UPDATE,
          ],
          userDetails
        )
          ? DASHBOARD_URL
          : ADMIN_URL
      }/${MASTERMANAGEMENT_URL}`,
      img: MasterManagementImg,
      authority: {
        adminCheck: true,
        functional_areas: [
          CORP_FORM_FILL_UP,
          CORP_SCRUITNY,
          CORP_CHO_UPDATE,
          CORP_PROFILE_UPDATE,
        ],
      },
    },
  ];

  const handleCardClick = (endUrl) => {
    navigate(`/${BASE_URL}/${endUrl}`);
  };

  const checkCardAuthority = (authObj, userDetail) => {
    switch (true) {
      case userDetail?.isAdmin && authObj.hasOwnProperty("adminCheck"):
        return authObj.adminCheck && userDetail?.isAdmin;
      case authObj.hasOwnProperty("functional_areas"):
        return checkFunctionalAreas(authObj.functional_areas, userDetail);
      case authObj.hasOwnProperty("check_role"):
        return checkRoleFunction(authObj.check_role, userDetail);
      case authObj.hasOwnProperty("exception"):
        return authObj.exception;
      default:
        return null;
    }
  };

  return (
    <div className={classes.Dashboard_container}>
      <div className={classes.dashboard_title}>
        <h1>Welcome Back, {userDetails.fullName} </h1>
        <div className={classes.dashboard_body}>
          {userDetails &&
            dashboardCardItems.map((data, key) => {
              if (checkCardAuthority(data?.authority, userDetails)) {
                return (
                  data?.label && (
                    <div key={key} className={classes.card}>
                      <Card
                        onClick={() => handleCardClick(data.url)}
                        label={t(data.label)}
                        width="100%"
                        height="100%"
                        img={data.img}
                      ></Card>
                    </div>
                  )
                );
              }
            })}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
