import React, { useEffect, useState } from "react";
import classes from "./ObjForm.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PieArcLabel from "../PieChart/PieChart";
import { memo } from "react";

const ObjForm = ({
  formObj,
  currentStep,
  disabledVal,
  checker,
  checkString,
  corpFormDetails,
  handleChange,
  setFormDetails,
  setFormChangeDetails,
  checkData,
  setFormInstruction,
  validateString,
  disabledValFunction,
  handleDropDownOption,
}) => {
  const disabledValueFunction =
    disabledValFunction && typeof disabledValFunction === "function"
      ? disabledValFunction
      : () => {};

  if (disabledVal == undefined) {
    disabledVal = false;
  }
  if (checker == undefined) {
    checker = false;
  }
  if (checkData == undefined) {
    checkData = {};
  }
  const [isOpenAccordion, setIsOpenAccordion] = useState(0);

  // open and close accordion
  useEffect(() => {
    setIsOpenAccordion(0);
  }, [currentStep]);

  const handleChangeAccordion = (index, formInstruction) => {
    if (index === isOpenAccordion) {
      setIsOpenAccordion("");
    } else {
      setIsOpenAccordion(index);
    }
    if (setFormInstruction != undefined) {
      setFormInstruction(formInstruction);
    }
  };

  const checkedIsPOPShiftFunction = (val, option) => {
    if (corpFormDetails[val] === "true" && option === "POP Shift") {
      return true;
    } else if (
      corpFormDetails[val] === "false" &&
      option === "New registration"
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className={classes.form_box}>
      <h5 className={classes.header}>{formObj[currentStep]?.header}</h5>
      {formObj[currentStep].section.map((section, index) => {
        return section?.shortIdName === "inCasePopMigrating" &&
          corpFormDetails?.isPopShift === "false" ? null : (
          <Accordion
            key={index}
            sx={{
              borderRadius: ".4rem",
              border: "1px solid ",
              borderColor:
                index === isOpenAccordion
                  ? `var(--app-btn-background)`
                  : `var(--app-text-secondary-color)`,
              marginBottom: "1rem",
              display:
                formObj.isPopShift &&
                currentStep === 0 &&
                index === 1 &&
                formObj.isPopShift !== "POP Shift"
                  ? "none"
                  : "",
            }}
            expanded={isOpenAccordion === index}
            onChange={() => {
              handleChangeAccordion(index, section);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  fontWeight: "300",
                  color: `var(--app-btn-background)`,
                }}
              >
                {section.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {section?.Fields?.map((field, fieldIndex) => (
                  <div
                    className={classes.fieldscontainer}
                    key={fieldIndex + field.label}
                  >
                    <label className={classes.label}>
                      {field.label}
                      {field.is_mandatory && <>*</>}
                    </label>

                    {field.tag === "input" && (
                      <>
                        <input
                          title={
                            checkData[field.accessor] != undefined
                              ? checkData[field.accessor]
                              : corpFormDetails &&
                                corpFormDetails[field.accessor]
                          }
                          className={
                            checkData[field.accessor] != undefined
                              ? `${classes.input} ${classes.highlight_border}`
                              : validateString != undefined &&
                                validateString[field.accessor] &&
                                corpFormDetails[field.accessor]
                              ? `${classes.input_empty}`
                              : `${classes.input}`
                          }
                          type={field.type}
                          placeholder={field.placeholder}
                          disabled={
                            disabledVal ||
                            field?.disable === "true" ||
                            disabledValueFunction(
                              corpFormDetails[field.accessor],
                              field.accessor
                            )
                          }
                          value={
                            checkData[field.accessor] != undefined
                              ? checkData[field.accessor]
                              : corpFormDetails &&
                                corpFormDetails[field.accessor]
                          }
                          onInput={(e) =>
                            handleChange(e, field.accessor, field.validType)
                          }
                        />
                        {checkData[field.accessor] != undefined ? (
                          <span className={classes.orange_alert}>
                            {checkString}
                          </span>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                    {field.tag === "dropdown" && (
                      <>
                        <select
                          className={classes.select}
                          disabled={
                            disabledVal ||
                            disabledValueFunction(
                              corpFormDetails[field.accessor],
                              field.accessor
                            )
                          }
                          value={
                            checkData[field.accessor] != undefined
                              ? checkData[field.accessor]
                              : corpFormDetails &&
                                corpFormDetails[field.accessor]
                          }
                          onChange={(e) =>
                            handleChange(e, field.accessor, field.validType)
                          }
                        >
                          <option value="">Select</option>
                          {handleDropDownOption(
                            field.accessor,
                            field?.options
                          )?.map((option, optionIndex) => (
                            <option key={optionIndex}>{option}</option>
                          ))}
                        </select>
                        {checkData[field.accessor] != undefined ? (
                          <span className={classes.orange_alert}>
                            {checkString}
                          </span>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                    {field.tag === "checkbox" && (
                      <div className={classes.form_checkbox}>
                        {field.options?.map((option, index) => (
                          <label key={option}>
                            <input
                              type="checkbox"
                              disabled={
                                disabledVal ||
                                field.accessor === "isPopShift" ||
                                disabledValueFunction(
                                  corpFormDetails[field.accessor],
                                  field.accessor
                                )
                              }
                              checked={
                                (corpFormDetails &&
                                  corpFormDetails[field.accessor] === option) ||
                                (corpFormDetails[field.accessor] === "true" &&
                                  field.accessor != "isPopShift") ||
                                (checkData[field.accessor] != undefined
                                  ? checkData[field.accessor] === option
                                  : false) ||
                                (field.accessor === "isPopShift" &&
                                  checkedIsPOPShiftFunction(
                                    field.accessor,
                                    option
                                  ))
                              } // Check if the current option is selected
                              // onChange={(e) => {
                              //   setFormDetails((data) => ({
                              //     ...data,
                              //     [field.accessor]:
                              //       data[field.accessor] === option ? '' : option,
                              //   }));

                              //   if (setFormChangeDetails) {
                              //     setFormChangeDetails((data) => ({
                              //       ...data,
                              //       [field.accessor]:
                              //         data[field.accessor] === option ? '' : option,
                              //     }));
                              //   }

                              // }}
                              value={
                                corpFormDetails[field.accessor] === option
                                  ? ""
                                  : option
                              }
                              onChange={(e) =>
                                handleChange(e, field.accessor, field.validType)
                              }
                            />
                            {option}
                            {checkData[field.accessor] != undefined &&
                            checkData[field.accessor] === option ? (
                              <span className={classes.orange_alert}>
                                {checkString}
                              </span>
                            ) : (
                              ""
                            )}
                          </label>
                        ))}
                      </div>
                    )}
                    {field.tag === "pieChart" && (
                      <div>
                        <input type="checkbox" />
                        <PieArcLabel data={field.chartData} />
                      </div>
                    )}
                    {field.tag === "description" &&
                      field.description2.map((describe, key) => {
                        return (
                          <p key={key} className={classes.description}>
                            {describe}
                          </p>
                        );
                      })}
                    {validateString != undefined &&
                      validateString[field.accessor] &&
                      corpFormDetails[field.accessor] && (
                        <p className={classes.error_msg}>
                          {validateString[field.accessor] &&
                            validateString[field.accessor]}
                        </p>
                      )}
                    <p className={classes.description}>{field.description}</p>
                  </div>
                ))}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default ObjForm;
