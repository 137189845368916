import React from "react";
import DatePickerUI from "./DatePickerUI";
import Classes from "./DoubleDatePicker.module.css";
import "./DoubleDatePicker.css";
import CalenderIcon from "../../assets/TableDashboard/calendar.svg";

const DoubleDatePicker = ({
  placeHolder1,
  placeHolder2,
  onChangeFirst,
  onChangeSecond,
  FirstDateFunction,
  SecondDateFunction,
  min1,
  max1,
  min2,
  max2,
  resetValue,
  setResetValue
}) => {
  return (
    <div className={Classes.date_container}>
      <i className={Classes.icon_image}>
        <img src={CalenderIcon} />
      </i>
      <DatePickerUI
        placeHolder={placeHolder1}
        onInputChange={FirstDateFunction}
        maximumDate={max1}
        minimumDate={min1}
        resetValue={resetValue}
        setResetValue={setResetValue}
      />{" "}
      <span className={Classes.line}>|</span>{" "}
      <DatePickerUI
        placeHolder={placeHolder2}
        onInputChange={SecondDateFunction}
        maximumDate={max2}
        minimumDate={min2}
        resetValue={resetValue}
        setResetValue={setResetValue}
      />
    </div>
  );
};

export default DoubleDatePicker;
