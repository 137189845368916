import axios from "axios";

export function sendOTPtoNumberFunction(data) {
  return axios({
    url: `/hpmc/api/otp/mobile/send`,
    method: "post",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function validateOTPtoNumberFunction(data) {
  return axios({
    url: `/hpmc/api/otp/mobile/validate`,
    method: "post",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}


// email otp send
export function sendOTPtoEmailFunction(data) {
  return axios({
    url: `/hpmc/api/otp/email/send`,
    method: "post",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

//  validate email
export function validateOTPtoEmailFunction(data) {
  return axios({
    url: `/hpmc/api/otp/email/validate`,
    method: "post",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function generateACKIDFuntion(data) {
  return axios({
    url: `/hpmc/api/subscribers/generate-ackid`,
    method: "post",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
