import React from "react";
import classes from "../MISReport.module.css";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { useState } from "react";
import Utils from "../../../Utils";
import ArrowUPIcon from "../../../assets/arrow-up.svg";
import { useEffect } from "react";
import { getAllAPIReport } from "../../AdminManagement/AdminAction";

const ApiReport = ({ searchingQuery, dateOne, dateTwo, perPage }) => {
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("");
  const [accessor, setAccessor] = useState("");
  const [columnRotations, setColumnRotations] = useState("submissionDateasc");
  const [tableData, setTableData] = useState([]);
  const [pageData, setPageData] = useState({});
  // fr corporate columns
  const ApiReportColumn = [
    {
      header: (
        <p className={classes.headers}>
          ID
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "entityRowIdasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("id")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e?.id,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 12 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          USER NAME
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "frClosedByasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("userName")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e?.userName,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 16 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          API NAME
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "actionasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("apiName")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e?.apiName,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 16 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          DATE & TIME
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "frRaisedByasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("dateTime")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) =>
        `${Utils.getDateFromDateTime(e?.dateTime)}, ${Utils.getTimeFromDateTime(
          e?.dateTime
        )}`,
      toolTipStatus: true,
      isTextCenter: true,
    },

    {
      header: (
        <p className={classes.headers}>
          IP ADDRESS
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "submissionTimeasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("ipAddress")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e?.ipAddress,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 16 },
      isTextCenter: true,
    },

    {
      header: (
        <p className={classes.headers}>
          REQUEST BODY
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "updationTimeasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("requestBody")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e?.requestBody,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 10 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          RESPONSE BODY
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "statusasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("responseBody")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e?.responseBody,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 10 },
      isTextCenter: true,
    },
  ];
  useEffect(() => {
    setPage(0);
  }, [perPage, searchingQuery, dateOne, dateTwo]);
  useEffect(() => {
    getDataFunction();
  }, [sort, accessor, dateOne, dateTwo, page, perPage, searchingQuery]);

  const getDataFunction = () => {
    getAllAPIReport(
      searchingQuery,
      dateOne,
      dateTwo,
      page,
      perPage,
      sort,
      accessor
    ).then((res) => {
      setTableData(res.data.data.content);
      setPageData(res.data.data);
    });
  };

  const handleSort = (key) => {
    var sorting = sort;
    // Toggle sort order
    const newSort = sort === "asc" ? "desc" : "asc";
    setSort(newSort);
    setAccessor(key);

    // Update rotation state for the current column
    if (sort === "asc") {
      setColumnRotations(key + "desc");
    } else {
      setColumnRotations(key + "asc");
    }
  };
  return (
    <CustomTable
      page={page}
      setPage={setPage}
      headings={ApiReportColumn}
      data={tableData}
      pageData={pageData}
      sort={sort}
      setSort={setSort}
      accessor={accessor}
      setAccessor={setAccessor}
      srNO={true}
    />
  );
};
export default ApiReport;
