import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

export const UserListSlice = createSlice({
  name: "User",
  initialState: [],
  reducers: {
    addAllusers(state, action) {
      return [...action.payload];
    },
    addUsers(state, action) {
      return [...state, action.payload];
    },
    deleteUsers(state, action) {
      return state.filter((user) => user !== action.payload);
    },
  },
});

export const GroupListSlice = createSlice({
  name: "Groups",
  initialState: [],
  reducers: {
    addAllGroups(state, action) {
      return [...action.payload];
    },
    addGroups(state, action) {
      return [...state, action.payload];
    },
    deleteGroups(state, action) {
      return state.filter((group) => group !== action.payload);
    },
  },
});

export const RoleListSlice = createSlice({
  name: "Roles",
  initialState: [],
  reducers: {
    addAllRoles(state, action) {
      return [...action.payload];
    },
    addRoles(state, action) {
      return [...state, action.payload];
    },
    deleteRoles(state, action) {
      return state.filter((role) => role !== action.payload);
    },
  },
});

export const CurrentUser = createSlice({
  name: "currentUser",
  initialState: "",
  reducers: {
    assignUser: (state, action) => {
      return action.payload;
    },
  },
});

export const UserDetail = createSlice({
  name: "userDetail",
  initialState: { groups: [], roles: [] },
  reducers: {
    userDetail: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const UsersTabValue = createSlice({
  name: "usersTabValue",
  initialState: 1,
  reducers: {
    userTabValue: (state, action) => {
      return action.payload;
    },
  },
});

export const NewUser = createSlice({
  name: "NewUser",
  initialState: false,
  reducers: {
    setNewUser: (state, action) => {
      return action.payload;
    },
  },
});

export const AdminDataList = createSlice({
  name: "adminDataList",
  initialState: [],
  reducers: {
    adminDataList: (state, action) => {
      return action.payload;
    },
  },
});

export const CurrentGroup = createSlice({
  name: "currentGroup",
  initialState: "",
  reducers: {
    groupName: (state, action) => {
      return action.payload;
    },
  },
});

export const UsersInRole = createSlice({
  name: "usersInRole",
  initialState: {},
  reducers: {
    usersOfRole: (state, action) => {
      return action.payload;
    },
  },
});

export const UsersInGroup = createSlice({
  name: "usersInGroup",
  initialState: {},
  reducers: {
    usersOfGroup: (state, action) => {
      return action.payload;
    },
  },
});

export const { addUsers, deleteUsers, addAllusers } = UserListSlice.actions;
export const { addGroups, deleteGroups, addAllGroups } = GroupListSlice.actions;
export const { addRoles, deleteRoles, addAllRoles } = RoleListSlice.actions;
export const { assignUser } = CurrentUser.actions;
export const { userTabValue } = UsersTabValue.actions;
export const { adminDataList } = AdminDataList.actions;
export const { userDetail } = UserDetail.actions;
export const { setNewUser } = NewUser.actions;
export const { groupName } = CurrentGroup.actions;
export const { usersOfRole } = UsersInRole.actions;
export const { usersOfGroup } = UsersInGroup.actions;

export const usersInGroupReducer = UsersInGroup.reducer;
export const usersOfRoleReducer = UsersInRole.reducer;
export const groupNameReducer = CurrentGroup.reducer;
export const currentUserReducer = CurrentUser.reducer;
export const userslistReducer = UserListSlice.reducer;
export const grouplistReducer = GroupListSlice.reducer;
export const rolelistReducer = RoleListSlice.reducer;
export const userTabValueReducer = UsersTabValue.reducer;
export const adminDataListReducer = AdminDataList.reducer;
export const userDetailReducer = UserDetail.reducer;
export const NewUserReducer = NewUser.reducer;
