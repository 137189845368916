import React from "react";
import Modal from "../Modal/Modal";
import Classes from "./ShowReasonsModal.module.css"
import SecondaryButton from "../Buttons/SecondaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";
import { useTranslation } from "react-i18next";
const ShowReasonsModal=({isModalActive,setIsModalActive,activeDocumentString,reasonsArr,setModalObj,setUploadModalActive})=>{
    const { t } = useTranslation();

    return (
   isModalActive && (
        <Modal onclose={(e) => setIsModalActive(false)}>
          <div className={Classes.rejected_modal_box}>
            <div className="col-lg-12">
              <div className={Classes.heading + " " + "mb-3"}>
                <h4>Rejected Reason</h4>
              </div>
              {activeDocumentString && 
              <div className={Classes.documentName + " " + "mb-2"}>
                <p>{activeDocumentString}</p>
              </div>
              }
              <ul>
                {reasonsArr != undefined && reasonsArr.map((data, id) => {
                  return (
                    <li key={id}>
                      <span className="text-danger">{id + 1 + ". "}</span>{" "}
                      <p>{data}</p>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className={"col-lg-12 my-3 " + " " + Classes.btn_media}>
              <div
                className={Classes.btn_group + " " + "btn-group ml-auto mr-3"}
              >
                <PrimaryButton
                
                  // disabled={checkSubmit}
                  label={t("Select File")}
                  onClick={() => {
                    setModalObj((data) => ({
                      ...data,
                      documentName:
                      activeDocumentString,
                    }));
                    setIsModalActive(false);
                    setUploadModalActive(true);
                  }}
                />
              </div>

              <div className={Classes.btn_group + " " + "btn-group mr-3"}>
                <SecondaryButton
                  label={t("Cancel")}
                  onClick={() => {
                    setIsModalActive(false);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      )
    )

}
export default ShowReasonsModal;