import React, { useEffect, useState } from "react";
import Classes from "./ProgressBar.module.css";

const ProgressBar = (props) => {
  const [barWidth, setBarWidth] = useState(0);
  let timer;
  useEffect(() => {
    clearInterval(timer);
    timer = setTimeout(() => {
      if (barWidth === 100) {
        clearInterval(timer);
        if(props.hideBar != undefined){
          props.hideBar(true);
        }
        return;
      }
      if(props.hideBar != undefined){
        props.hideBar(false);
      }
      setBarWidth((e) => e + 1);
    }, props.timing / 10);
    return () => clearInterval(timer);
  }, [barWidth]);

  return (
    <>
      <div
        className="progress"
        style={{
          width: 100 + "%",
          height: props.height && props.height ? `${props.height}` : "100%",
        }}
      >
        <div
          className={"progress-bar" + " " + Classes.customProgressBar}
          role="progressbar"
          style={{ width: +barWidth + "%" }}
          aria-valuenow={barWidth}
          aria-valuemin="0"
          aria-valuemax="100"
          data={barWidth}
        >
          {props.percentShow && props.percentShow ? barWidth + "%" : ""}
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
