import React from "react";
import classes from "../MISReport.module.css";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { useState } from "react";
import Utils from "../../../Utils";
import ArrowUPIcon from "../../../assets/arrow-up.svg";
import { useEffect } from "react";
import { getAllCorporateReportFR } from "../../AdminManagement/AdminAction";

const CorporateFRReport = ({ searchingQuery, dateOne, dateTwo, perPage }) => {
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("");
  const [accessor, setAccessor] = useState("");
  const [columnRotations, setColumnRotations] = useState("submissionDateasc");
  const [tableData, setTableData] = useState([]);
  const [pageData, setPageData] = useState({});
  // fr corporate columns
  const FRCorporatecolumns = [
    {
      header: (
        <p className={classes.headers} style={{ textAlign: "left" }}>
          ACK ID
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "entityRowIdasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("entityRowId")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => (
        <div
          title={
            e.entityType === "Corporate"
              ? `CR${e.entityRowId}`
              : `SR${e.entityRowId}`
          }
          style={{ textAlign: "left" }}
        >
          {Utils.ShortLengthFunction(
            e.entityType === "Corporate"
              ? `CR${e.entityRowId}`
              : `SR${e.entityRowId}`,
            12
          )}
        </div>
      ),
    },
    {
      header: (
        <p className={classes.headers}>
          ACTION
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "actionasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("action")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.action,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 16 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          FR RAISED BY
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "frRaisedByasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("frRaisedBy")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.frRaisedBy,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 16 },
      isTextCenter: true,
    },

    {
      header: (
        <p className={classes.headers}>
          SUBMISSION DATE & TIME
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "submissionTimeasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("submissionTime")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) =>
        `${Utils.getDateFromDateTime(
          e.submissionTime
        )}, ${Utils.getTimeFromDateTime(e.submissionTime)}`,
      toolTipStatus: true,
      isTextCenter: true,
    },

    {
      header: (
        <p className={classes.headers}>
          FR CLOSED BY
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "frClosedByasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("frClosedBy")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => (e.frClosedBy != null ? e.frClosedBy : "--"),
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 16 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          UPDATION DATE & TIME
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "updationTimeasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("updationTime")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) =>
        e?.updationTime != null
          ? `${Utils.getDateFromDateTime(
              e.updationTime
            )}, ${Utils.getTimeFromDateTime(e.updationTime)}`
          : "--",
      toolTipStatus: true,
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          STATUS
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "statusasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("status")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.status,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 16 },
      isTextCenter: true,
    },
  ];
  useEffect(() => {
    setPage(0);
  }, [perPage, searchingQuery, dateOne, dateTwo]);
  useEffect(() => {
    getDataFunction();
  }, [sort, accessor, dateOne, dateTwo, page, perPage, searchingQuery]);

  const getDataFunction = () => {
    getAllCorporateReportFR(
      searchingQuery,
      dateOne,
      dateTwo,
      page,
      perPage,
      sort,
      accessor
    ).then((res) => {
      setTableData(res.data.data.content);
      setPageData(res.data.data);
    });
  };

  const handleSort = (key) => {
    var sorting = sort;
    // Toggle sort order
    const newSort = sort === "asc" ? "desc" : "asc";
    setSort(newSort);
    setAccessor(key);

    // Update rotation state for the current column
    if (sort === "asc") {
      setColumnRotations(key + "desc");
    } else {
      setColumnRotations(key + "asc");
    }
  };
  return (
    <CustomTable
      page={page}
      setPage={setPage}
      headings={FRCorporatecolumns}
      data={tableData}
      pageData={pageData}
      sort={sort}
      setSort={setSort}
      accessor={accessor}
      setAccessor={setAccessor}
      srNO={true}
    />
  );
};

export default CorporateFRReport;
