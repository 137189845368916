import axios from "../../axios";

export function getUIObject(username) {
  return axios({
    url: `/hpmc/api/uifunction?userName=${username}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getUserDetails() {
  return axios({
    url: `/hpmc/api/usermanagement/getuserdetails`,
    method: "get",
    responseType: "json",
  });
}

// send email link
export function forgotPassword(email) {
  return axios({
    url: `/hpmc/api/auth/forgot-password `,
    data: email,
    method: "post",
    responseType: "json",
  });
}

// check token
export function checkToken(token) {
  return axios({
    url: `/hpmc/api/auth/check-token/${token}`,
    method: "post",
    responseType: "json",
  });
}

// change password
export function changePassword(token, password) {
  return axios({
    url: `/hpmc/api/auth/reset-password`,
    data: { token: token, password: password },
    method: "post",
    responseType: "json",
  });
}

// get captcha
export function getCaptcha() {
  return axios({
    url: `/hpmc/api/auth/generate-captcha`,
    method: "get",
    responseType: "json",
  });
}

export function checkIfCropContributionAllowed(id) {
  return axios({
    url: `/hpmc/api/check/contribution/corporate/${id}`,
    method: "get",
    responseType: "json",
  });
}
