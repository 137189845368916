import React, { useState } from "react";
import classes from "./NPSICSSForm.module.css";
import formObj from "./NPSICSS.json";
import Modal from "../../../components/Modal/Modal";
import { useEffect } from "react";

const NPSICSSForm = ({setIsProceed, setPreviewData}) => {
  const [declarationModal, setDeclarationModal] = useState(false);
  const [gender, setGender] = useState("male");
useEffect(()=>{
setIsProceed(false);

let obj = {
  subFormData :{
  }
}
setPreviewData(obj);
},[])

  return (
    <div className={classes.nps_form_container}>
      {formObj.map((section, index) => {
        return (
          <div className={classes.form_container} key={index}>
            <h5 className={classes.section_title}>{section?.title}</h5>
            <div className={classes.form_fields_container}>
              {section.Fields.map((field, fieldIndex) => {
                return (
                  <div className={classes.fieldsContainer} key={fieldIndex}>
                    <label>
                      {field.label}
                      {field?.Mandatory && <span> * </span>}
                    </label>
                    {field.tag === "input" && (
                      <input className={classes.input} type={field.type} />
                    )}
                    {field.tag === "checkbox" && (
                      <div className={classes.checkbox}>
                        {field?.options.map((option, i) => {
                          return (
                            <label key={i}>
                              <input
                                checked={gender === option}
                                onChange={(e) => setGender(option)}
                                type="radio"
                              />
                              {option}
                            </label>
                          );
                        })}
                      </div>
                    )}
                    <span> {field.description} </span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <label className={classes.checkbox_declaration}>
        <input type="checkbox" />
        Declaration by Subscriber
        <span onClick={() => setDeclarationModal(true)}>view more</span>
      </label>

      {declarationModal && (
        <Modal
          style={{ width: "fit-content", height: "fit-content" }}
          onClose={() => setDeclarationModal(false)}
        >
          <div className={classes.declaration_modal_container}>
            <h4> Declaration By Subscriber </h4>
            <p>
              I agree to be bound by the terms and conditions for the target CRA
              (in which my PRan will belong after processing of this inters) CRA
              Shifting request and understand that CRA may, as approved by
              PFRDA, amend any of the service completely or partially without
              any new Declaration/Under taking being signed
            </p>
            <p>
              Further, I agree to pay all the necessary charges, as applicable,
              of the target CRA. I understand that incase of pending transaction
              at the time of shifting will automatically be cancelled by Source
              CRA
            </p>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default NPSICSSForm;
