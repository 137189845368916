import React from "react";
import classes from "./Dropdown.module.css";

const Dropdown = ({
  options,
  label,
  disabled,
  value,
  handleOnchange,
  errorMsg,
}) => {
  return (
    <div className={classes.dropdown_container}>
      <h5 className={classes.label}>{label}</h5>
      <select
        className={`${errorMsg ? classes.select_empty : classes.select}`}
        value={value}
        onChange={handleOnchange}
        disabled={disabled}
      >
        <option value="">Select Option</option>
        {options?.map((option, key) => {
          return (
            <option key={key} value={option?.id}>
              {option.label}
            </option>
          );
        })}
      </select>
      {errorMsg && <p className={classes.error_msg}>{errorMsg}</p>}
    </div>
  );
};

export default Dropdown;
