import React, { useEffect, useState } from "react";
import Loading from "./components/Loading/Loading";
import Router from "./Routes/Router";
import Header from "./components/Header/Header";
import MainSidebar from "./modules/Home/MainSidebar";
import { logoutUser } from "./modules/Auth/AuthActions";
import { BASE_URL, SIGN_IN_URL } from "./Routes/Urls";
import classes from "./App.module.css";
import "./Fonts/SohoGothicPro-Regular.otf";
import "./Fonts/SohoGothicPro-Bold.otf";
import "./Fonts/SohoGothicPro-Medium.otf";
import Alert from "./components/Alert/Alert";
import {
  loginUserDetail,
  setAlertMsg,
  setIsLoggedIn,
} from "./modules/Home/HomeSlice";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "./modules/Home/HomeAction";
import Login from "./modules/Home/Login";
import Utils from "./Utils";
import { useSearchParams } from "react-router-dom";

// Add format api to JS String.
// Allows formatting strings by replacing placeholders like {0} with the
// provided corresponding argument.

if (!String.format) {
  String.format = function (format) {
    var args = Array.prototype.slice.call(arguments, 1);
    return format.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != "undefined" ? args[number] : match;
    });
  };
}

function App() {
  const [sidebarToggel, setSidebarToggel] = useState(true);
  const dispatch = useDispatch();
  const { loginUserDetails, isLoggedIn } = useSelector((state) => state);
  const [searchParams, setSearchParams] = useSearchParams();

  // sidebar toggle
  const handleToggle = () => {
    if (sidebarToggel) setSidebarToggel(false);
    else setSidebarToggel(true);
  };

  const checkUrlParams = () => {
    let idParam = searchParams.get("corporateId");
    if (idParam === null || idParam === undefined) {
      localStorage.removeItem("subs");
    }
  };

  useEffect(() => {
    checkUrlParams();

    getUserDetails()
      .then((res) => {
        if (res.data.success === true) {
          dispatch(loginUserDetail(res.data.data));
          dispatch(setIsLoggedIn(true));
        }
      })
      .catch((err) => {
        dispatch(loginUserDetail({}));
        dispatch(setIsLoggedIn(false));
        dispatch(
          setAlertMsg({
            msg: err.response?.data?.message,
            alertType: "warning",
          })
        );
      });
  }, []);

  if (isLoggedIn === null) {
    return <></>;
  }

  return (
    <>
      <div className={classes.app_container}>
        <Loading target={window} />
        <Alert />
        {isLoggedIn ? (
          <div className={classes.dashboard_container}>
            <Header handleToggle={handleToggle} logoutUser={logoutUser} />
            <div className={classes.dashboard_body}>
              <div
                style={{ display: sidebarToggel ? "flex" : "none" }}
                className={classes.dashbord_sidebar}
              >
                <MainSidebar url={BASE_URL} />
              </div>
              <div className={classes.dashboard_rightscreen}>
                <Router />
              </div>
            </div>
          </div>
        ) : (
          <Router />
        )}
      </div>
    </>
  );
}

export default App;
