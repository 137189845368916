import React, { useEffect } from "react";
import classes from "./GroupManagement.module.css";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import { useTranslation } from "react-i18next";
import SearchIcon from "../../../assets/SearchIcon_grey.svg";
import GroupsIcon from "../../../assets/group_PeopleIcon.svg";
import WorkIcon from "../../../assets/Work.svg";
import { getAllGroups } from "../FunctionalityManagement/FunctionalityManagementActions";
import { useState } from "react";
import Utils from "../../../Utils";

const GroupManagement = () => {
  const { t } = useTranslation();
  const [groupList, setGroupList] = useState([]);
  const [searchGroup, setSearchGroup] = useState("");
  const [filterList, setFilterList] = useState([]);

  useEffect(() => {
    getAllGroups().then((res) => {
      if (res.data.success === true) {
        setGroupList(res.data.data);
        setFilterList(res.data.data);
      }
    });
  }, []);
  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchGroup(searchTerm);
    if (searchTerm === "") {
      setFilterList(groupList);
    } else {
      setFilterList(
        groupList.filter((group) =>
          group.groupName.toLowerCase().startsWith(searchTerm)
        )
      );
    }
  };

  return (
    <div className={classes.GroupManagement_container}>
      <div className={classes.GoBackButton}>
        <GoBackButton />
      </div>
      <div className={classes.searchbar_container}>
        <h5>Department Management</h5>
        <div className={classes.input_box}>
          <img src={SearchIcon} alt="search" />
          <input
            type="text"
            value={searchGroup}
            onChange={handleSearch}
            placeholder={t("text.search")}
          />
        </div>
      </div>
      <div className={classes.card_box}>
        <div className={classes.card_container}>
          {filterList?.map((card, index) => {
            return (
              <div key={index} className={classes.cardBox}>
                <div className={classes.card_header}>
                  <img src={WorkIcon} />
                  <div>
                    <h5 title={card?.groupName}>
                      {Utils.ShortLengthFunction(card?.groupName, 20)}
                    </h5>
                  </div>
                </div>
                <div className={classes.card_info}>
                  <span>
                    {" "}
                    <img src={GroupsIcon} /> {card?.userCount}{" "}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GroupManagement;
