import React from "react";
import classes from "../MasterManagement.module.css";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import { useState } from "react";
import Utils from "../../../../Utils";
import ArrowUPIcon from "../../../../assets/arrow-up.svg";
import { useEffect } from "react";
import {
  addHolidayMaster,
  deleteHolidayMaster,
  editClaimUnclaim,
  getAllHolidayMasters,
  getHolidayMasters,
  makerCheckerFlow,
  updateHolidayMaster,
} from "../../AdminManagementActions";
import TextButton from "../../../../components/Buttons/TextButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import PenEditIcon from "../../../../assets/pen_edit.svg";
import Modal from "../../../../components/Modal/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMsg } from "../../../Home/HomeSlice";
import InputBox from "../../../../components/form/InputBox";
import DateInputBox from "../../../../components/form/DateInputBox";

const HolidayManagement = ({
  searchingQuery,
  dateOne,
  dateTwo,
  perPage,
  isWorkflowEnabled,
  masterId,
  isAddMaster,
  setIsAddMaster,
}) => {
  const userDetails = useSelector((state) => state.loginUserDetails);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("");
  const [accessor, setAccessor] = useState("");
  const [columnRotations, setColumnRotations] = useState("submissionDateasc");
  const [tableData, setTableData] = useState([]);
  const [pageData, setPageData] = useState({});
  const [filterBy, setfilterBy] = useState("");
  const [disabledStatus, setDisabledStatus] = useState(true);
  const [holidayFormActive, setHolidayFormActive] = useState(false);
  const [editHolidayName, seteditHolidayName] = useState("");
  const [editHolidayDate, seteditHolidayDate] = useState("");
  const [changedHolidayInput, setChangedHolidayInput] = useState({
    holidayName: "",
    holidayDate: "",
  });
  const [rowId, setRowId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [addMasterDetails, setAddMasterDetails] = useState({
    name: "",
    date: "",
  });

  // holiday table columns
  const holidayTableColumns = [
    {
      header: (
        <p className={classes.headers}>
          HOLIDAY NAME
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "nameasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("name")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.name,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 30 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          Holiday Date
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "dateasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("date")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.date,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 30 },
      isTextCenter: true,
    },

    {
      header: <p className={classes.headers}>ACTION</p>,
      key: "corpAction",
      accessor: (e) => (
        <div className={classes.action_btn}>
          {isWorkflowEnabled && (
            <TextButton
              style={{
                display:
                  e.makerCheckerStatus === null ||
                  e.makerCheckerStatus === 0 ||
                  e.currentMaker?.username !== userDetails.username ||
                  (e.makerCheckerFlowFunction == 1 &&
                    e.currentMaker?.username !== userDetails.username)
                    ? "none"
                    : "",
              }}
              disabled={
                e.makerCheckerStatus === 2 ||
                (e.makerCheckerStatus === 1 &&
                  e.currentMaker?.username !== userDetails.username)
              }
              onClick={() => {
                editClaimUnclaimFunction(e.id, true);
                setRowId(e.id);
              }}
              label={t("button.release")}
            />
          )}

          <TextButton
            disabled={
              e.makerCheckerStatus === 2 ||
              (e.makerCheckerStatus === 1 &&
                e.currentMaker?.username !== userDetails.username &&
                userDetails.username != "wbadmin")
            }
            onClick={() => {
              isWorkflowEnabled == true &&
              (e.makerCheckerStatus == 0 || e.makerCheckerStatus == null) ? (
                <>{(setIsEdit(true), editHolidayFunctionData(e.id))}</>
              ) : (
                <>
                  {(setHolidayFormActive(true), editHolidayFunctionData(e.id))}
                </>
              );
            }}
            label={t("button.edit")}
          />
          <TextButton
            disabled={
              e.makerCheckerStatus === 2 ||
              (e.makerCheckerStatus === 1 &&
                e.currentMaker?.username !== userDetails.username &&
                userDetails.username != "wbadmin")
            }
            onClick={() => handleDeleteHoliday(e?.id)}
            label={"Delete"}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    setPage(0);
  }, [perPage, searchingQuery]);
  useEffect(() => {
    getAllHolidayMastersFunction();
  }, [sort, accessor, dateOne, dateTwo, page, perPage, searchingQuery]);

  const getAllHolidayMastersFunction = () => {
    getAllHolidayMasters({
      page,
      searchingQuery,
      sort,
      accessor,
      perPage,
      filterBy,
    }).then((res) => {
      setTableData(res.data.data.content);
      setPageData(res.data.data);
    });
  };

  const handleSort = (key) => {
    var sorting = sort;
    // Toggle sort order
    const newSort = sort === "asc" ? "desc" : "asc";
    setSort(newSort);
    setAccessor(key);

    // Update rotation state for the current column
    if (sort === "asc") {
      setColumnRotations(key + "desc");
    } else {
      setColumnRotations(key + "asc");
    }
  };

  const editHolidayFunctionData = (id) => {
    setRowId(id);
    getHolidayMasters(id).then((res) => {
      let h_name = res.data.data.masterData.name;
      let h_date = Utils.convertDateToYMD(res.data.data.masterData.date);
      seteditHolidayName(h_name);
      seteditHolidayDate(h_date);
      setChangedHolidayInput({
        id: id,
        holidayName: h_name,
        holidayDate: h_date,
      });
    });
  };

  const editClaimUnclaimFunction = (rowId, isReleased) => {
    editClaimUnclaim(masterId, rowId)
      .then((res) => {
        if (isReleased == false) {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.task_claimed"),
              alertType: "success",
            })
          );
          setHolidayFormActive(true);
          setIsEdit(false);
        } else {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.task_unclaimed"),
              alertType: "success",
            })
          );
          getAllHolidayMastersFunction();
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.task_claim_fail"),
            alertType: "danger",
          })
        );
      });
  };

  const makerCheckerFlowFunction = () => {
    let data = {};
    if (editHolidayName != changedHolidayInput.holidayName) {
      data.name = Utils.removeWhiteSpaceFromBetween(editHolidayName);
    }
    if (editHolidayDate != changedHolidayInput.holidayDate) {
      data.date = Utils.convertDateToDMY(editHolidayDate);
    }
    makerCheckerFlow(masterId, rowId, data)
      .then((res) => {
        dispatch(
          setAlertMsg({
            msg:
              isWorkflowEnabled === true
                ? t("alertMsg.changes_for_approval")
                : t("alertMsg.edit_successfully"),
            alertType: "success",
          })
        );
        setHolidayFormActive(false);
        getAllHolidayMastersFunction();
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.update_failed"),
            alertType: "danger",
          })
        );
      });
  };

  useEffect(() => {
    checkSubmitDisabled();
  }, [editHolidayDate, editHolidayName]);

  const checkSubmitDisabled = () => {
    var status =
      (Utils.removeWhiteSpaceFromBetween(
        editHolidayName
      ).toLocaleLowerCase() ===
        Utils.removeWhiteSpaceFromBetween(
          changedHolidayInput.holidayName
        ).toLocaleLowerCase() &&
        editHolidayDate === changedHolidayInput.holidayDate) ||
      Utils.removeWhiteSpaceFromBetween(editHolidayName) === "" ||
      editHolidayDate === ""
        ? true
        : false;
    setDisabledStatus(status);
  };

  const handleOnchange = (e) => {
    let { name, value } = e.target;

    if (name === "name") {
      setAddMasterDetails((data) => ({
        ...data,
        [name]: Utils.PreventSpecialCharacterForAddress(e),
      }));
    } else {
      setAddMasterDetails((data) => ({
        ...data,
        [name]: value,
      }));
    }
  };

  const handleAddMaster = () => {
    let obj = {
      name: addMasterDetails?.name,
      date: Utils.convertDateToDMY(addMasterDetails?.date),
    };
    addHolidayMaster(obj)
      .then((res) => {
        if (res.data.success === true) {
          setIsAddMaster(false);
          getAllHolidayMastersFunction();

          dispatch(
            setAlertMsg({
              msg: "Master added successfully",
              alertType: "success",
            })
          );
        }
      })
      .catch((err) => {
        setIsAddMaster(false);
        dispatch(
          setAlertMsg({
            msg: err.response?.data?.message,
            alertType: "danger",
          })
        );
      });
    setAddMasterDetails({
      name: "",
      date: "",
    });
  };

  const handleMaxDate = () => {
    var currentDate = new Date();

    currentDate.setDate(currentDate.getDate() + 1);

    return currentDate.toJSON().slice(0, 10);
  };

  const handleDeleteHoliday = (id) => {
    deleteHolidayMaster(id)
      .then((res) => {
        if (res.data.success === true) {
          getAllHolidayMastersFunction();
          dispatch(
            setAlertMsg({
              msg: "Master Deleted successfully",
              alertType: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: err.response?.data?.message,
            alertType: "danger",
          })
        );
      });
  };

  return (
    <>
      <CustomTable
        page={page}
        setPage={setPage}
        headings={holidayTableColumns}
        data={tableData}
        pageData={pageData}
        sort={sort}
        setSort={setSort}
        accessor={accessor}
        setAccessor={setAccessor}
        srNO={true}
      />
      {/* for holiday edit */}

      {isEdit == true && isWorkflowEnabled == true && (
        <Modal>
          <div className={classes.editModal_container}>
            <h4>
              <span>
                <img src={PenEditIcon} alt="pen edit Master management" />
              </span>{" "}
              {t("proceed.edit.master")}
            </h4>
            <p>{t("proceed.edit.master.text")}</p>
            <div className={classes.editModal_btn}>
              <PrimaryButton
                onClick={() => {
                  editClaimUnclaimFunction(rowId, false);
                }}
                label={t("button.proceed")}
              />
              <SecondaryButton
                onClick={() => {
                  setIsEdit(false);
                }}
                label={t("button.close")}
              />
            </div>
          </div>
        </Modal>
      )}

      {holidayFormActive && (
        <Modal
          closeBtn={{}}
          onClose={() => {
            getAllHolidayMastersFunction();
            setHolidayFormActive(false);
          }}
        >
          <div className={classes.holiday_modal_body}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-input mb-3">
                  <label>Holiday Name</label>
                  <input
                    type="text"
                    name="holiday_name"
                    value={editHolidayName}
                    onInput={(e) => {
                      seteditHolidayName(e.target.value);
                    }}
                    className="form-control"
                  />
                </div>
                <div className="form-input">
                  <label>Holiday Date</label>
                  <input
                    type="date"
                    name="holiday_date"
                    value={editHolidayDate}
                    onChange={(e) => seteditHolidayDate(e.target?.value)}
                    className="form-control"
                  />
                </div>
                <div className="btn-group mt-4">
                  <div className="btn-form mr-3">
                    <PrimaryButton
                      disabled={disabledStatus}
                      label={t("button.submit")}
                      onClick={() => {
                        makerCheckerFlowFunction(changedHolidayInput.id);
                        // changeHolidayFunction(changedHolidayInput.id);
                      }}
                    />
                  </div>
                  <div className="btn-form">
                    <SecondaryButton
                      onClick={() => {
                        getAllHolidayMastersFunction();
                        setHolidayFormActive(false);
                      }}
                      label={t("button.cancel")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {isAddMaster && (
        <Modal>
          <div className={classes.addMaster_container}>
            <InputBox
              value={addMasterDetails.name}
              label={"Enter Holiday Name"}
              handleOnchange={handleOnchange}
              name={"name"}
              placeholder={"Ex- Diwali"}
            />
            <DateInputBox
              value={addMasterDetails.date}
              label={"Enter Holiday Date"}
              handleOnchange={handleOnchange}
              name={"date"}
              min={handleMaxDate()}
            />

            <div className={classes.addMaster_btn}>
              <PrimaryButton
                disabled={!addMasterDetails.name || !addMasterDetails.date}
                onClick={handleAddMaster}
                label={"Submit"}
              />
              <SecondaryButton
                onClick={() => {
                  setIsAddMaster(false);
                  setAddMasterDetails({
                    name: "",
                    date: "",
                  });
                }}
                label="Cancel"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default HolidayManagement;
