import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Password from "../../../components/Password/Password";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import classes from "./LoginForm.module.css";
import Login from "../../../assets/Login_Img.png";
import TextButton from "../../../components/Buttons/TextButton";
import Modal from "../../../components/Modal/Modal";
import OtpLogin from "./OtpLogin";
import { forgotPassword } from "../../Home/HomeAction";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../Home/HomeSlice";
import Utils from "../../../Utils";
import Header from "../../../components/Header/Header";
import Captcha from "../../../components/Captcha/Captcha";

function LoginForm({ handleLogin }) {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [isOtpLogin, setIsOtpLogin] = useState(false);
  const [isForgetPass, setIsForgetPass] = useState(false);
  const [captchaInput, setCaptchaInput] = useState("");
  const [captchaKey, setCaptchaKey] = useState("");
  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function passwordChangeHandler(e) {
    setPassword(e.target.value);
  }
  const handleOtpLogin = (e) => {
    e.preventDefault();

    const creds = {
      userId: userId,
      password: Utils.encryptedPassword(password),
      captchaValue: captchaInput,
      captchaKey: captchaKey,
    };
    handleLogin(creds);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    if (Utils.validateEmail(email).valid) {
      forgotPassword({
        email: email,
        captchaValue: captchaInput,
        captchaKey: captchaKey,
      })
        .then((res) => {
          if (res.data.success === true) {
            setIsForgetPass(false);
            dispatch(
              setAlertMsg({
                msg: `password reset link send to ${email}`,
                alertType: "success",
              })
            );
          }
        })
        .catch((error) => {
          dispatch(
            setAlertMsg({
              msg: error.response.data.message,
              alertType: "danger",
            })
          );
        });
    } else {
      setIsForgetPass(false);
      dispatch(
        setAlertMsg({ msg: "Enter correct email", alertType: "danger" })
      );
    }
    setEmail("");
  };

  return (
    <>
      <Header />
      <div className={classes.main}>
        <div className={classes.login_imgside}>
          <div className={classes.login_imgcontainer}>
            <img className={classes.loginscreen_logo} src={Login} />
          </div>
        </div>

        {!isOtpLogin ? (
          <form className={classes.form_half_page} onSubmit={handleOtpLogin}>
            <div className={classes.loginForm} autoComplete="on">
              <div className={classes.loginForm_container}>
                <div className={classes.loginForm_title}>
                  <h2>Login</h2>
                  <h4>Welcome back! Please login to your account</h4>
                </div>
                <div className={classes.input_fields}>
                  <label htmlFor="email">
                    {t("loginForm.username")}
                    <input
                      id="email"
                      // type="email"
                      autoComplete="username"
                      placeholder="Enter your email Id"
                      autoFocus={true}
                      className={classes["form-field"]}
                      onChange={(e) => setUserId(e.target.value)}
                    />
                  </label>
                  <label htmlFor="password">
                    {t("loginForm.password")}
                    <Password
                      placeholder="Enter your password"
                      id="password"
                      autoComplete="current-password"
                      width="100%"
                      height="100%"
                      onChange={passwordChangeHandler}
                      togglePasswordVisibility={true}
                    />
                  </label>
                  <Captcha
                    value={captchaInput}
                    onchange={(e) => setCaptchaInput(e.target.value)}
                    setCaptchaKey={setCaptchaKey}
                  />
                  <div className={classes.forgot_password}>
                    <p>
                      or login with
                      <span
                        onClick={() => {
                          setIsOtpLogin(true);
                          setCaptchaInput("");
                        }}
                      >
                        OTP
                      </span>
                    </p>
                    <TextButton
                      onClick={() => {
                        setIsForgetPass(true);
                        setCaptchaInput("");
                      }}
                      label={"Forgot Password ?"}
                    />
                  </div>
                  <PrimaryButton
                    style={{
                      width: "100%",
                      height: "2.8rem",
                      marginTop: "1rem",
                    }}
                    label={t("button.login")}
                    type="submit"
                  />
                </div>
              </div>
            </div>
          </form>
        ) : (
          <>
            <OtpLogin
              handleOtpLogin={handleLogin}
              setIsOtpLogin={setIsOtpLogin}
              setCaptchaInput={setCaptchaInput}
              captchaInput={captchaInput}
              setCaptchaKey={setCaptchaKey}
              captchaKey={captchaKey}
            />
          </>
        )}
        {isForgetPass && (
          <Modal
            onClose={() => {
              setIsForgetPass(false);
            }}
          >
            <form onSubmit={handleForgotPassword}>
              <div className={classes.forgotPass_container}>
                <h5>Forgot Password</h5>
                <div className={classes.forgotPass_input}>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="EX. amangupta@gmail.com"
                  />
                  <p>Enter your register email</p>
                </div>
                <Captcha
                  value={captchaInput}
                  onchange={(e) => setCaptchaInput(e.target.value)}
                  setCaptchaKey={setCaptchaKey}
                />
                <PrimaryButton
                  disabled={!email || captchaInput.length === 0}
                  type="submit"
                  label={"Submit"}
                />
              </div>
            </form>
          </Modal>
        )}
      </div>
    </>
  );
}

export default LoginForm;
