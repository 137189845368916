import React, { memo, useEffect, useState } from "react";
import Classes from "./CreateUser.module.css";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import GoBackButton from "../../../../components/Buttons/GoBackButton";
import SwitchToggle from "../../../../components/SwitchToggle/SwitchToggle";
import infoIcon from "../../../../assets/info-blue-icon.svg";
import {
  createUser,
  functionalareasByGrpRole,
  getAllGroups,
  getAllUsers,
  getFunctionalityDesignation,
  getRolesViaGroup,
} from "../UserManagementActions";
import Utils from "../../../../Utils";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../../Home/HomeSlice";
import { useTranslation } from "react-i18next";
import {
  ADMIN_URL,
  BASE_URL,
  USER_MANAGEMENT_DASHBOARD_URL,
} from "../../../../Routes/Urls";
import { useNavigate } from "react-router";
const numberInputInvalidChars = ["-", "+", "e", "."];
const CreateUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [functionalityDesignation, setFunctionalityDesignation] = useState([]);

  const [grpId, setGrpId] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [functionalityAreasBox, setFunctionalityAreasBox] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [employeeStatus, setEmployeeStatus] = useState(false);
  const [showValidationMsg, setShowValidationMsg] = useState({});

  // basic information form elements
  const [basicInfoFormElem, setBasicInfoFormElem] = useState([
    {
      title: "Basic Information",
      fieldsType: "array",
      isAdminCheck: true,
      fields: [
        {
          tag: "input",
          type: "text",
          label: "Name",
          name: "fullName",
          placeholder: "Enter username",
          mandatory: true,
          autoComplete: "off",
        },
        {
          tag: "select",
          type: "text",
          label: "Status of the Employee",
          name: "canLogin",
          status: false,
          options: ["Inactive", "Active"],
          placeholder: "Ex - ABCD",
          mandatory: false,
          autoComplete: "off",
        },
        {
          tag: "input",
          type: "email",
          label: "Email Id",
          name: "email",
          placeholder: "Ex abc@gmail.com",
          mandatory: true,
          autoComplete: "off",
        },
        {
          tag: "input",
          type: "text",
          label: "Employee Code ",
          name: "employeeCode",
          placeholder: "Ex. 01234",
          mandatory: false,
          autoComplete: "off",
        },
        {
          tag: "input",
          type: "number",
          name: "contactNo",
          label: "Contact No ",
          placeholder: "Ex. 9999933399",
          mandatory: true,
          autoComplete: "off",
        },
      ],
    },
    {
      title: "Location",
      fieldsType: "array",
      isAdminCheck: false,
      fields: [
        {
          tag: "input",
          type: "text",
          label: "Zone",
          name: "zone",
          status: false,
          placeholder: "Ex - North zone",
          mandatory: false,
          autoComplete: "off",
        },
        {
          tag: "input",
          type: "text",
          label: "Region",
          name: "region",
          status: false,
          placeholder: "Ex - Delhi",
          mandatory: false,
          autoComplete: "off",
        },
        {
          tag: "input",
          type: "text",
          label: "Location",
          name: "location",
          status: false,
          placeholder: "Ex - Delhi - New Delhi",
          mandatory: false,
          autoComplete: "off",
        },
      ],
    },
    {
      title: "Department And Designation",
      isAdminCheck: false,
      fieldsType: "object",
      fields: {},
    },
    {
      title: "Password",
      isAdminCheck: false,
      fieldsType: "array",
      fields: [
        {
          tag: "input",
          label: "Enter Password",
          type: "password",
          name: "password",
          placeholder: "Ex - 123",
          bottomHint: "Must contain 8 characters",
          autoComplete: "new-password",
        },
        {
          tag: "input",
          label: "Confirm Password",
          type: "password",
          name: "confirm_password",
          placeholder: "Ex - 123",
          bottomHint: "Must contain 8 characters",
          autoComplete: "new-password",
        },
      ],
    },
  ]);

  // department form elements
  const [deptFormElem, setDeptFormElem] = useState({
    department: {
      label: "Department",
      name: "department",
      options: [],
      optionName: "groupName",
    },

    designation: {
      label: "Designation",
      name: "designation",
      options: [],
      optionName: "roleName",
    },
  });

  const [mandatoryFields, setMandatoryFields] = useState([
    {
      name: "fullName",
      errorText: "Name",
    },
    {
      name: "email",
      errorText: "Email",
    },
    // {
    //   name: "employeeCode",
    //   errorText: "Employee Code"
    // },
    {
      name: "contactNo",
      errorText: "Contact No",
    },
    {
      name: "password",
      errorText: "Password",
    },
    {
      name: "confirm_password",
      errorText: "Confirm Password",
    },
  ]);

  //  password hint type
  const [passwordInstruct, setPasswordInstruct] = useState([
    "Include both lower and upper case characters",
    "Include at least one number",
    "Be in range of 8 to 20 characters long",
    "Include at least one special character [@#$%^&+=]",
  ]);

  useEffect(() => {
    getFunctionalityDesignation()
      .then((res) => {
        setFunctionalityDesignation(res.data.data);
      })
      .catch((err) => {});
    getDepartment();
  }, []);

  // fetch department
  const getDepartment = () => {
    getAllGroups(false).then((res) => {
      let result = res?.data?.data;
      setDeptFormElem((prevState) => ({
        ...prevState,
        department: {
          ...prevState.department,
          options: result,
        },
      }));
    });
  };

  // get designations by group
  const getDesignation = (id) => {
    if (id === null || id === "") {
      return;
    }
    getRolesViaGroup(id).then((res) => {
      let result = res?.data?.data;
      setDeptFormElem((prevState) => ({
        ...prevState,
        designation: {
          ...prevState.designation,
          options: result,
        },
      }));
    });
  };

  useEffect(() => {
    if (roleId != null) {
      getFunctionalArea(grpId, roleId);
    }
  }, [roleId]);

  // get functional areas by groupId and userId
  const getFunctionalArea = (group, role) => {
    functionalareasByGrpRole(group, role)
      .then((res) => {
        if (res.data?.success) {
          let resData = res.data?.data;
          setUserInfo((e) => ({
            ...e,
            internalGroupRoleId: resData?.id,
          }));
          let newArr = resData?.functionalAreas?.map((e) => e.id);
          setFunctionalityAreasBox(newArr);
        } else {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.internal_server_error"),
              alertType: "danger",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.internal_server_error"),
            alertType: "danger",
          })
        );
      });
  };

  // select deptartment and designation
  const deptDesgFunction = (e) => {
    if (e.target.name === deptFormElem.department.name) {
      setFunctionalityAreasBox([]);
      document.getElementById(`${deptFormElem.designation.name}`).value = "";
      setGrpId(e.target.value);
    } else if (e.target.name === deptFormElem.designation.name) {
      setRoleId(e.target.value);
    }
  };

  useEffect(() => {
    getDesignation(grpId);
  }, [grpId]);

  // check uncheck designation
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    let val = Number(name);
    setFunctionalityAreasBox((prevFunctionalityAreasBox) => {
      prevFunctionalityAreasBox = prevFunctionalityAreasBox || [];
      if (checked) {
        if (!prevFunctionalityAreasBox.includes(val)) {
          return [...prevFunctionalityAreasBox, val];
        }
      } else {
        return prevFunctionalityAreasBox.filter((item) => item !== val);
      }
      return prevFunctionalityAreasBox;
    });
  };

  // check the functiona;ity of check uncheck
  const checkedFunctionality = (e) => {
    if (functionalityAreasBox.length > 0) {
      let res = functionalityAreasBox.includes(e);
      if (res) {
        return true;
      }
      return false;
    }
    return false;
  };

  // on submit the form
  const handleSubmitForm = () => {
    if (checkValidation() === false) {
      return false;
    }
    if (userInfo.password != userInfo.confirm_password) {
      dispatch(
        setAlertMsg({
          msg: "Password does not match",
          alertType: "danger",
        })
      );
      return;
    }

    let submitData = {
      employeeCode: userInfo.employeeCode,
      fullName: userInfo.fullName,
      band: "",
      zone: userInfo.zone,
      region: userInfo.region,
      location: userInfo.location,
      internalGroupRoleId: userInfo.internalGroupRoleId,
      immediateManagerId: null,
      fls: "NFLS",
      username: userInfo.email,
      password: Utils.encryptedPassword(userInfo.password),
      contactNo: userInfo.phone,
      employeeType: "Manager",

      lwd: null,
      functionalAreaIds: functionalityAreasBox,
      canLogin: employeeStatus,
      isAdmin: isAdmin,
      contactNo: userInfo.contactNo,
      isHpmcUser: false,
    };
    createUser(submitData)
      .then((res) => {
        if (res.data?.success) {
          dispatch(
            setAlertMsg({
              msg: res.data.message,
              alertType: "success",
            })
          );
          redirectToDashboard();
        } else {
          const { success, message } = res?.response?.data || {};

          const alertMsg = {
            msg: success ? t("alertMsg.internal_server_error") : message,
            alertType: "danger",
          };

          dispatch(setAlertMsg(alertMsg));
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.internal_server_error"),
            alertType: "danger",
          })
        );
      });
  };

  // filter values  (special Characters)
  const filterUserData = (name, e) => {
    switch (name) {
      case "fullName":
        return Utils.nameRegex(e);
      case "email":
        return e.target.value?.length <= 90
          ? Utils.emailRegex(e)
          : userInfo[name];
      case "contactNo":
        return Utils.handleInputLengthFunction(e, 10);
      case "employeeCode":
        return e.target.value.length > 16
          ? userInfo.employeeCode
          : Utils.PreventSpecialCharacterForAddress(e);

      case "zone":
      case "location":
      case "region":
        return Utils.PreventSpecialCharacterForAddress(e);
      case "password":
      case "confirm_password":
        return Utils.handleInputLengthFunction(e, 20);
      default:
        return e.target.value;
    }
  };

  const setErrorMsgsFunction = (name, val) => {
    const validationFunctions = {
      contactNo: Utils.validateContact,
      email: Utils.validateEmail,
      password: Utils.validatePassword,
    };

    if (validationFunctions.hasOwnProperty(name)) {
      const validate = validationFunctions[name];
      const errorMsg = validate(val);
      if (errorMsg && errorMsg?.valid === false) {
        setShowValidationMsg((prevState) => ({
          ...prevState,
          [name]: errorMsg?.message,
        }));
      } else {
        setShowValidationMsg((prevFormData) => {
          const { [name]: valueToRemove, ...remainingFormData } = prevFormData;
          return remainingFormData;
        });
      }
    }
  };

  const handleChangeUserInfo = (e) => {
    const { name, value } = e.target;
    let newVal = filterUserData(name, e);

    setErrorMsgsFunction(name, newVal);

    setUserInfo((data) => {
      if (value) {
        return {
          ...data,
          [name]: newVal,
        };
      } else {
        const { [name]: removedObjData, ...newObjData } = data;
        return newObjData;
      }
    });
  };

  const checkValidation = () => {
    for (let i = 0; i < mandatoryFields.length; i++) {
      const data = mandatoryFields[i];
      if (userInfo[data.name] === undefined) {
        dispatch(
          setAlertMsg({
            msg: `${data.errorText} cannot be empty `,
            alertType: "danger",
          })
        );
        return false;
      } else if (
        data?.name === "email" &&
        !Utils.validateEmail(userInfo[data.name]).valid
      ) {
        dispatch(
          setAlertMsg({
            msg: Utils.validateEmail(userInfo[data.name])?.message,
            alertType: "danger",
          })
        );
        return false;
      } else if (
        data?.name === "contactNo" &&
        !Utils.validateContact(userInfo[data.name]).valid
      ) {
        dispatch(
          setAlertMsg({
            msg: Utils.validateContact(userInfo[data.name])?.message,
            alertType: "danger",
          })
        );
        return false;
      }
    }
    if (grpId === null || grpId === "") {
      dispatch(
        setAlertMsg({
          msg: `Department cannot be empty `,
          alertType: "danger",
        })
      );
      return false;
    }
    if (roleId === null || roleId === "") {
      dispatch(
        setAlertMsg({
          msg: `Designation cannot be empty `,
          alertType: "danger",
        })
      );
      return false;
    }
  };

  const groupActiveOrNotFunction = (status) => {
    setEmployeeStatus(!status);
  };

  // redirect to dashboard
  const redirectToDashboard = () => {
    navigate(`/${BASE_URL}/${ADMIN_URL}/${USER_MANAGEMENT_DASHBOARD_URL}`);
  };

  //  show validation issue
  const showValidationError = (e) => {
    return (
      showValidationMsg[e] && (
        <span className={Classes.err_msg}>{showValidationMsg[e]}</span>
      )
    );
  };

  return (
    <section>
      <div className="contain-fluid px-5 py-3">
        <GoBackButton />

        <div className="col-lg-12 mb-4">
          <div className={Classes.page_head}>
            <h5>Add New User </h5>
            <p className="mt-2 pb-2">
              Here you can add only <span>Non HPMC Users</span>
            </p>
          </div>
        </div>
        <div className={`card border-0 ${Classes.radius_10}`}>
          <div className="card-body p-lg-5 p-3">
            {basicInfoFormElem &&
              basicInfoFormElem.map((data, index) => {
                return (
                  <div key={index}>
                    <div
                      className="row mb-4"
                      id={Classes.select_box}
                      key={index}
                    >
                      <div className="col-lg-12 mb-4">
                        <div className={Classes.page_form_title}>
                          <h5>
                            {data.title}{" "}
                            {index === 3 && (
                              <div className={Classes.passwordInstructions}>
                                <img src={infoIcon} />
                                <div className={Classes.showInstruction}>
                                  <ul>
                                    {passwordInstruct &&
                                      passwordInstruct?.map(
                                        (passData, passIndex) => {
                                          return (
                                            <li key={passIndex}>{passData}</li>
                                          );
                                        }
                                      )}
                                  </ul>
                                </div>
                              </div>
                            )}
                          </h5>
                        </div>
                      </div>
                      {data.fieldsType === "array" &&
                        data?.fields &&
                        data.fields.map((fieldsData, index) => {
                          return (
                            <div
                              className="col-lg-5 col-md-6 col-12 mb-4"
                              key={index}
                            >
                              <div className="input_group">
                                <label
                                  className={Classes.form_label}
                                  htmlFor="group"
                                >
                                  {fieldsData.label}
                                  {fieldsData.mandatory && (
                                    <span className={Classes.mandatory_css}>
                                      *
                                    </span>
                                  )}
                                </label>
                                {fieldsData.tag === "input" && (
                                  <input
                                    onKeyDown={(e) => {
                                      if (
                                        fieldsData.type === "number" &&
                                        numberInputInvalidChars.includes(e.key)
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    type={fieldsData.type}
                                    name={fieldsData.name}
                                    value={userInfo[fieldsData.name]}
                                    placeholder={fieldsData?.placeholder}
                                    autoComplete={fieldsData.autoComplete}
                                    onInput={(e) => handleChangeUserInfo(e)}
                                    className="form-control"
                                  />
                                )}
                                {fieldsData?.bottomHint && (
                                  <span className={Classes.bottomHint}>
                                    {fieldsData.bottomHint}
                                  </span>
                                )}
                                {fieldsData?.tag === "select" && (
                                  <>
                                    <div
                                      className={Classes.employeeStatusClass}
                                    >
                                      <span
                                        className={
                                          employeeStatus
                                            ? Classes.inactiveStatus
                                            : Classes.activeStatus
                                        }
                                      >
                                        {fieldsData.options[0]}
                                      </span>
                                      <SwitchToggle
                                        bgColor={"var(--app-btn-background)"}
                                        status={employeeStatus}
                                        otherFunction={() =>
                                          groupActiveOrNotFunction(
                                            employeeStatus
                                          )
                                        }
                                      />
                                      <span
                                        className={
                                          employeeStatus
                                            ? Classes.activeStatus
                                            : Classes.inactiveStatus
                                        }
                                      >
                                        {fieldsData.options[1]}
                                      </span>
                                    </div>
                                  </>
                                )}

                                {showValidationError(fieldsData?.name)}
                              </div>
                            </div>
                          );
                        })}

                      {data?.fieldsType === "object" &&
                        deptFormElem &&
                        Object.keys(deptFormElem).map((data, index) => {
                          return (
                            <div
                              className="col-lg-5 col-md-6 col-12 mb-3"
                              key={index}
                            >
                              <div className="input_group">
                                <label htmlFor="group">
                                  {deptFormElem[data]?.label}
                                  <span className={Classes.mandatory_css}>
                                    *
                                  </span>
                                </label>
                                <select
                                  name={deptFormElem[data].name}
                                  className="form-control form-select"
                                  id={deptFormElem[data].name}
                                  // defaultValue={""}
                                  onChange={(e) => deptDesgFunction(e)}
                                >
                                  <option
                                    value=""
                                    selected
                                    disabled={
                                      deptFormElem[data]?.name === "department"
                                    }
                                  >
                                    Select {deptFormElem[data]?.label}
                                  </option>
                                  {deptFormElem[data]?.options &&
                                    deptFormElem[data]?.options.map(
                                      (subData, subIndex) => {
                                        return (
                                          <option
                                            value={subData?.id}
                                            key={subIndex}
                                          >
                                            {
                                              subData[
                                                deptFormElem[data]?.optionName
                                              ]
                                            }
                                          </option>
                                        );
                                      }
                                    )}
                                </select>
                              </div>
                            </div>
                          );
                        })}

                      {}
                    </div>

                    {data.isAdminCheck && (
                      <div className={`row mb-4 ${Classes.list_checkbox} `}>
                        <div className="col-lg-4 col-md-6 col-12 mb-4">
                          <div className={`form-check px-0`}>
                            <input
                              className={`check_input mr-3 ${Classes.pointer}`}
                              type="checkbox"
                              checked={isAdmin}
                              name={"isAdmin"}
                              id="isAdmin"
                              value={isAdmin}
                              onChange={(e) => setIsAdmin(!isAdmin)}
                            />
                            <label
                              className={`form-check-label ${Classes.pointer} ${Classes.admin_check_label}`}
                              htmlFor={"isAdmin"}
                            >
                              Is user type Admin ?
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                    {data.fieldsType === "object" && (
                      <>
                        <div className="row pt-2 pb-4">
                          <div className="col-lg-12">
                            <div className={Classes.list_head}>
                              <h4>Functionality for Designation</h4>
                              <p>
                                (You can add / remove functionality for this
                                user)
                                <span>
                                  ({functionalityAreasBox?.length} selected)
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className={"row mb-4" + " " + Classes.list_checkbox}
                        >
                          {functionalityDesignation &&
                            functionalityDesignation?.map((data, index) => {
                              return (
                                <div
                                  className="col-lg-4 col-md-6 col-12 mb-4"
                                  key={index}
                                >
                                  <div className={`form-check `}>
                                    <input
                                      className={`check_input ${Classes.pointer}`}
                                      type="checkbox"
                                      checked={checkedFunctionality(data.id)}
                                      name={data.id}
                                      // checked={checkboxes.checkbox1}
                                      onChange={handleCheckboxChange}
                                      id={Utils.stringSpacetoSpecialChar(
                                        data.uiString
                                      )}
                                    />
                                    <label
                                      className={`form-check-label ${Classes.pointer}`}
                                      htmlFor={Utils.stringSpacetoSpecialChar(
                                        data.uiString
                                      )}
                                    >
                                      {data.name}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}

            <div className="row px-4 mt-4">
              <div className="button_group mr-4">
                <SecondaryButton
                  onClick={redirectToDashboard}
                  label={"Cancel"}
                />
              </div>

              <div className="button_group">
                <PrimaryButton
                  onClick={handleSubmitForm}
                  label={"Add New User"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateUser;
