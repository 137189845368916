import { createSlice } from "@reduxjs/toolkit";

const RmTaskState = createSlice({
  name: "taskState",
  initialState: {},
  reducers: {
    RmState: (state, action) => {
      // Update the state with the payload value
      return action.payload;
    },
  },
});

const CorpId = createSlice({
  name: "CorpId",
  initialState: "",
  reducers: {
    CorporateId: (state, action) => {
      return action.payload;
    },
  },
});

export const { RmState } = RmTaskState.actions;
export const { CorporateId } = CorpId.actions;

export const RmTaskStateReducer = RmTaskState.reducer;
export const CorpIdReducer = CorpId.reducer;
