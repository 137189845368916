import React, { useEffect, useRef, useState } from "react";
import TableDashboard from "../../../../components/TableDashboard/TableDashboard";
import Utils from "../../../../Utils";
import ArrowUPIcon from "../../../../assets/TableDashboard/ArrowUp.svg";
import CheckMarkIcon from "../../../../assets/TableDashboard/checkmark.svg";
import OverdueIcon from "../../../../assets/TableDashboard/overdue.svg";
import TimelimitIcon from "../../../../assets/TableDashboard/Timelimit.svg";
import ClockIcon from "../../../../assets/TableDashboard/time.svg";
import GreenDot from "../../../../assets/TableDashboard/GreenDot.svg";
import OrangeDot from "../../../../assets/TableDashboard/OrangeDot.svg";
import RedDot from "../../../../assets/TableDashboard/RedDot.svg";
import classes from "./CorporateSubscribersDashboard.module.css";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import SwitchToggle from "../../../../components/SwitchToggle/SwitchToggle";
import {
  getAllSubscriber,
  getSubscriberCsvFile,
  getSubscriberFullCsvFile,
} from "../../OPSActions";
import TextButton from "../../../../components/Buttons/TextButton";
import infoIcon from "../../../../assets/info-blue-icon.svg";
import menuIcon from "../../../../assets/Menu_3_dot.svg";

const CorporateSubscribersDashboard = () => {
  // to print screen
  const { loginUserDetails } = useSelector((state) => state);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [columnRotations, setColumnRotations] = useState("submissionDateasc");
  const [tableData, setTableData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [pageData, setPageData] = useState({});
  const [page, setPage] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [sort, setSort] = useState("desc");
  const [accessor, setAccessor] = useState("submissionDate");
  const [perPage, setperPage] = useState(10);
  const [totalCount, setTotalCount] = useState({});
  const [filterBy, setfilterBy] = useState("");
  const [formObj, setFormObj] = useState({});
  const [corpFormModal, setCorpFormModal] = useState(false);
  const [dateOne, setDateOne] = useState("");
  const [dateTwo, setDateTwo] = useState("");
  const userDeatils = useSelector((state) => state.loginUserDetails);
  const [subordinateId, setSubordinateId] = useState("");
  const [pranTypeBoolean, setPranTypeBoolean] = useState(false);
  const [regType, setRegType] = useState("2");

  useEffect(() => {
    getAllSubscriber(
      {
        page,
        searchInput,
        sort,
        accessor,
        perPage,
        filterBy,
        subordinateId,
        regType,
      },
      Utils.convertToLocalDateForInput(dateOne),
      Utils.convertToLocalDateForInput(dateTwo)
    ).then((res) => {
      setTableData(res.data.data.pageData.content);
      setPageData(res.data.data.pageData);
      setTotalCount(res.data.data.countData);
    });
  }, [
    page,
    sort,
    accessor,
    perPage,
    filterBy,
    subordinateId,
    dateOne && dateTwo,
    regType,
  ]);

  useEffect(() => {
    setCorpFormModal(false);
  }, [formObj]);

  useEffect(() => {
    setPage(0);
  }, [perPage]);

  // handle search
  const handleSearch = (e) => {
    e.preventDefault();
    getAllSubscriber(
      {
        page,
        searchInput,
        sort,
        accessor,
        perPage,
        filterBy,
        subordinateId,
        regType,
      },
      Utils.convertToLocalDateForInput(dateOne),
      Utils.convertToLocalDateForInput(dateTwo)
    ).then((res) => {
      setTableData(res.data.data.pageData.content);
      setPageData(res.data.data.pageData);
      setTotalCount(res.data.data.countData);
    });
  };

  // tooltip status and shortlen status, shortlen show when both conditions are true
  const columns = [
    {
      header: (
        <p className={classes.headers}>
          ACKNOWLEDGEMENT Id
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "ackIdasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("ackId")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => <>{e.ackId}</>,
    },
    {
      header: (
        <p className={classes.headers}>
          Subscriber Email
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "emaileasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("email")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => <>{e.email}</>,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 20 },
    },
    {
      header: (
        <p className={classes.headers}>
          Corporate Name
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "submissionDateasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("submissionDate")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => <>{e?.corporate?.corpName || "--"}</>,
    },
    {
      header: (
        <p className={classes.headers}>
          Pran Number
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "tatasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("tat")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => <>{e?.pranNumber || "NA"}</>,
    },
    {
      header: (
        <p className={classes.headers}>
          TAT
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "tatasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("tat")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => (
        <p className={classes.tat_container}>
          <img
            src={
              e.tat === 0 || e.tat <= 5
                ? GreenDot
                : e.tat === 6 || e.tat <= 10
                ? OrangeDot
                : RedDot
            }
          />
          {e.tat}
        </p>
      ),
    },
    {
      header: <p className={classes.headers}>STATUS</p>,
      key: "corpStatus",
      accessor: (e) => (
        <p className={classes.state_container}>{e?.subStatus?.name || "--"}</p>
      ),
    },
    {
      header: (
        <p className={classes.headers}>
          PENDING WITH
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "currentAssignedUser.usernameasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("currentAssignedUser.username")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      key: "pendingWith",
      accessor: (e) => (
        <p className={classes.state_pending}>
          {e?.currentAssignedUser && e?.corpStatus?.name != "Onboarded"
            ? e?.currentAssignedUser.username
            : "NA"}
        </p>
      ),
    },
    {
      header: <p className={classes.headers}>ACTION</p>,
      key: "corpAction",
      accessor: (e) => (
        <div className={classes.action_btn}>
          <TextButton label={e?.subAction?.name} />
          <img src={infoIcon} alt="workflow" />
          <img src={menuIcon} alt="Menu" />
        </div>
      ),
    },
  ];

  // handling sort
  const handleSort = (key) => {
    // Toggle sort order
    const newSort = sort === "asc" ? "desc" : "asc";
    setSort(newSort);
    setAccessor(key);

    // Update rotation state for the current column
    if (sort === "asc") {
      setColumnRotations(key + "desc");
    } else {
      setColumnRotations(key + "asc");
    }
  };

  const analyticCard = [
    { icon: ClockIcon, title: t("text.pending"), count: totalCount.pendingall },
    {
      icon: CheckMarkIcon,
      title: t("text.complete"),
      count: totalCount.completedall,
    },
    {
      icon: TimelimitIcon,
      title: t("text.overdue"),
      count: totalCount.overdueall,
    },
    {
      icon: OverdueIcon,
      title: t("text.futherRequest"),
      count: totalCount.frall,
    },
  ];

  // tab  data array
  const tabsLabel = [
    {
      title: ` All (${
        totalCount.pending +
        totalCount.rejected +
        totalCount.accepted +
        totalCount.onboarded +
        totalCount.unclaimed
      })`,
      key: "",
    },
    {
      title: `${t("text.pending")} (${totalCount.pending})`,
      key: "pending",
    },
    {
      title: `${t("text.rejected")} (${totalCount.rejected})`,
      key: "rejected",
    },
    {
      title: `${t("text.accepted")} (${totalCount.accepted})`,
      key: "accepted",
    },
    {
      title: `${t("text.onboard")} (${totalCount.onboarded})`,
      key: "onboarded",
      pageType: "corporateDashboard",
      click: "onboarded",
    },
    {
      title: `${t("text.tatexceeded")} (${totalCount.tatexceeded})`,
      key: "tatexceeded",
    },
    {
      title: `${t("text.unclaimed")} (${totalCount.unclaimed})`,
      key: "unclaimed",
    },
  ];

  //  table strings
  const tableStrings = {
    title: "Corporate Subscribers",
    listAll_title: "Corporate Subscribers Application",
    search_placeholder: "Search Name/Acknowledgement Id",
  };

  //  downloadzip csv
  const downloadCsv = () => {
    getSubscriberCsvFile(
      Utils.convertToLocalDateForInput(dateOne),
      Utils.convertToLocalDateForInput(dateTwo)
    );
  };

  const downloadFullCsv = () => {
    getSubscriberFullCsvFile(
      Utils.convertToLocalDateForInput(dateOne),
      Utils.convertToLocalDateForInput(dateTwo)
    );
  };

  const pranStatusDOM = () => {
    return (
      <div className={classes.status}>
        <div className={classes.statusBox}>
          <div
            className={`${classes.statusName} ${
              !pranTypeBoolean ? classes.active : ""
            }`}
            onClick={() => {
              setRegType("2");
              setPranTypeBoolean(!pranTypeBoolean);
            }}
          >
            PRAN Registeration
          </div>
          <SwitchToggle
            bgColor={"var(--app-btn-background)"}
            status={pranTypeBoolean}
            otherFunction={() => {
              setPranTypeBoolean(!pranTypeBoolean);
              pranTypeBoolean ? setRegType("2") : setRegType("1");
            }}
          />
          <div
            className={`${classes.statusName} ${
              pranTypeBoolean ? classes.active : ""
            }`}
            onClick={() => {
              setRegType("1");
              setPranTypeBoolean(!pranTypeBoolean);
            }}
          >
            PRAN Migration
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <TableDashboard
        data={{ page, sort, accessor, perPage, filterBy, dateOne, dateTwo }}
        dateOne={dateOne}
        dateTwo={dateTwo}
        tableStrings={tableStrings}
        columns={columns}
        downloadCsv={downloadCsv}
        handleSort={handleSort}
        analyticCard={analyticCard}
        tabsLabel={tabsLabel}
        handleNewCorpForm={false}
        pageData={pageData}
        tabValue={tabValue}
        setTabValue={setTabValue}
        filterBy={filterBy}
        setfilterBy={setfilterBy}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        handleSearch={handleSearch}
        setDateOne={setDateOne}
        setDateTwo={setDateTwo}
        perPage={perPage}
        setperPage={setperPage}
        page={page}
        tableData={tableData}
        accessor={accessor}
        setAccessor={setAccessor}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        downloadFullCsv={downloadFullCsv}
        setSubordinateId={setSubordinateId}
        srNO={true}
        isPranType={pranStatusDOM()}
        isAccordionOff={true}
      />
    </div>
  );
};

export default CorporateSubscribersDashboard;
