import React from "react";
import classes from "./SimpleobjForm.module.css";
import Typography from "@mui/material/Typography";
const SimpleObjForm = ({
  formObj,
  disabledVal,
  checkString,
  corpFormDetails,
  setFormDetails,
  setFormChangeDetails,
  checkData,
}) => {
  return (
    <div className={classes.form_box}>
      <h3 className={classes.header}>{formObj?.header}</h3>
      {formObj.section.map((section, index) => {
        return (
          <Typography key={index}>
            {section.Fields.map((field, fieldIndex) => (
              <div
                className={classes.fieldscontainer}
                key={fieldIndex + field.accessor}
              >
                <label>
                  {field.label}
                  {field.is_mandatory && <>*</>}
                </label>
                {field.tag === "input" && (
                  <>
                    {field.accessor && (
                      <input
                        className={
                          checkData != undefined &&
                          checkData[field.accessor] != undefined
                            ? `${classes.input} ${classes.highlight_border}`
                            : `${classes.input}`
                        }
                        type={field.type}
                        placeholder={field.placeholder}
                        disabled={disabledVal}
                        value={
                          checkData != undefined &&
                          checkData[field.accessor] != undefined
                            ? checkData[field.accessor]
                            : corpFormDetails && corpFormDetails[field.accessor]
                        }
                        // onChange={(e) => handleChange(e, field.accessor)}
                      />
                    )}
                    {checkData != undefined &&
                    checkData[field.accessor] != undefined ? (
                      <span className={classes.orange_alert}>
                        {checkString}
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                )}

                {field.tag === "textarea" && (
                  <>
                    <textarea
                      rows={5}
                      className={
                        checkData != undefined &&
                        checkData[field.accessor] != undefined
                          ? `${classes.input} ${classes.highlight_border}`
                          : `${classes.input}`
                      }
                      placeholder={field.placeholder}
                      disabled={disabledVal}
                      value={
                        checkData != undefined &&
                        checkData[field.accessor] != undefined
                          ? checkData[field.accessor]
                          : corpFormDetails && corpFormDetails[field.accessor]
                      }
                      // onChange={(e) => handleChange(e, field.accessor)}
                    ></textarea>
                    {checkData != undefined &&
                    checkData[field.accessor] != undefined ? (
                      <span className={classes.orange_alert}>
                        {checkString}
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                )}
                {field.tag === "dropdown" && (
                  <>
                    <select
                      className={classes.select}
                      disabled={disabledVal}
                      value={
                        checkData != undefined &&
                        checkData[field.accessor] != undefined
                          ? checkData[field.accessor]
                          : corpFormDetails && corpFormDetails[field.accessor]
                      }
                      // onChange={(e) => handleChange(e, field.accessor)}
                    >
                      <option value="">Select</option>
                      {field.options.map((option, optionIndex) => (
                        <option key={optionIndex}>{option}</option>
                      ))}
                    </select>
                    {checkData != undefined &&
                    checkData[field.accessor] != undefined ? (
                      <span className={classes.orange_alert}>
                        {checkString}
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                )}

                <p className={classes.description + " " + "mb-0 p-0"}>
                  {field.description}
                </p>
              </div>
            ))}
          </Typography>
        );
      })}
    </div>
  );
};

export default SimpleObjForm;
