import React, { useEffect, useState } from "react";
import classes from "./CorporateOnboardProcess.module.css";
import CorporateForm from "./CorporateForm";
import { useLocation, useNavigate } from "react-router";
import Utils from "../../../Utils";
import ConfigObj from "../../AdminManage/MasterManagement/Part/JsonFileCorporate/CorporateManagement.json";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Modal from "../../../components/Modal/Modal";
import TextButton from "../../../components/Buttons/TextButton";
import {
  authenticateCorporatePan,
  autoPopulateCorporateFrom,
  genrateAckId,
  getFromChargesFromMaster,
} from "../CorporateAction";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import CheckMarkGreenIcon from "../../../assets/Check-green.svg";
import BrowserHistory from "../../../../history";
import { BASE_URL, DASHBOARD_URL } from "../../../Routes/Urls";
import { useTranslation } from "react-i18next";
import CorporateUploadDocuments from "./CorporateUploadDocuments";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../Home/HomeSlice";
import StepProgressBar from "../../../components/StepProgressBar/StepProgressBar";
import Consts from "../../../Consts";
import { useRef } from "react";

const CorporateOnboardProcess = () => {
  const dispatch = useDispatch();
  const pandivRef = useRef(null);
  const inputRef = useRef(null);
  const activeStepRef = useRef(0);
  const corpState = JSON.parse(localStorage.getItem("corpState"));
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(
    corpState < 3 ? (corpState === 2 ? 5 : corpState) : 0
  );
  const [formFields, setFormFields] = useState(ConfigObj);
  const [stepperErrorArray, setStepperErrorArray] = useState([]);
  const [panNum, setPanNum] = useState("");
  const [ackId, setackId] = useState({});
  const [corpId, setcorpId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [autoPopulateData, setAutoPopulateData] = useState({});
  const [autoPopulateModal, setAutoPopulateModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const [formDoneBy, setFormDoneBy] = useState("rm");
  const [corporateFormDataElement, setCorporateFormDataElement] = useState([
    {
      label: "First Name",
      placeholder: "ABCD..",
      name: "noFirstName",
      type: "text",
      tag: "input",
      mandatory: true,
      max: 90,
    },
    {
      label: "Last Name",
      placeholder: "ABCD..",
      name: "noLastName",
      type: "text",
      tag: "input",
      max: 90,
    },
    {
      label: "Email ID",
      placeholder: "abc@..",
      name: "noEmailId",
      type: "email",
      tag: "input",
      validType: "email",
      mandatory: true,
      max: 90,
    },
    {
      label: "Mobile Number",
      placeholder: "99999999...",
      name: "noMobileNumber",
      type: "number",
      validType: "number",
      tag: "input",
      mandatory: true,
      max: 10,
    },
    {
      label: "Standard Charges",
      description: "Select whether standard charges will be applied.",
      mandatory: true,
      type: "text",
      tag: "dropdown",
      options: ["Yes", "No"],
      name: "standardCharge",
    },
    {
      label: "Account Opening Charges",
      description: "Enter the account opening charge, between 200 and 400.",
      mandatory: true,
      type: "number",
      tag: "input",
      name: "acOpeningCharge",
      validType: "range",
      range: [200, 400],
      max: 6,
      symbol: "₹",
    },
    {
      label: "Type of Contribution Charges",
      description:
        "Enter the contribution charges, which are 0.5% or ₹ 30 , whichever is higher.",
      mandatory: true,
      type: "text",
      tag: "dropdown",
      options: ["₹ 30", "Percentage"],
      name: "contriChargesSelection",
    },
    {
      label: "Contribution Charges",
      description:
        "Enter the contribution charges, which are 0.5% or ₹ 30 , whichever is higher.",
      mandatory: true,
      type: "number",
      tag: "input",
      name: "contriCharges",
      max: 6,
      symbol: "₹",
    },
    {
      label: "Non-Financial Transactions",
      description: "Enter any non-financial transactions that will be applied.",
      mandatory: true,
      type: "number",
      tag: "input",
      name: "nonFinTransactions",
      max: 6,
      symbol: "₹",
    },
    {
      label: "Superannuation Contribution Charges to be borne by",
      description:
        "Select who will bear the superannuation contribution charges: the employee or the employer.",
      mandatory: false,
      type: "text",
      tag: "dropdown",
      options: ["Employee", "Employer"],
      name: "saContributionCharges",
    },
    {
      label: "Superannuation Contribution Charges",
      description: "Enter the superannuation contribution charges up to 0.5% .",
      mandatory: true,
      type: "number",
      tag: "input",
      name: "superContribCharges",
      max: 6,
      symbol: "%",
    },
  ]);
  const [corporateName, setCorporateName] = useState("");
  const [fieldsError, setFieldsError] = useState([]);
  const [disableCorpName, setDisableCorpName] = useState(true);
  const [highlightedPan, setHighlightedPan] = useState(false);
  const [corporateFormInputs, setCorporateFormInputs] = useState({
    noFirstName: "",

    noEmailId: "",
    noMobileNumber: "",

    standardCharge: "",

    acOpeningCharge: "",

    contriCharges: "",

    nonFinTransactions: "",

    saContributionCharges: "",

    superContribCharges: "",
  });
  const [corporateInputsError, setCorporateInputsError] = useState({});
  const steps = [
    { number: 1, text: "Step 1", detail: "BASIC DETAILS" },
    { number: 2, text: "Step 2", detail: "REGISTRATION FORM" },
    { number: 3, text: "Step 3", detail: "SCHEME PREFERENCE" },
    { number: 4, text: "Step 4", detail: "SIGNING" },
    { number: 5, text: "Step 5", detail: "UPLOAD DOCUMENT" },
  ];

  const nextStep = () => {
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    if (!corpState) {
      setActiveStep(0);
    }

    document.body.addEventListener("click", handleDocumentClick);

    return () => {
      document.body.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (corporateFormInputs.standardCharge === "Yes") {
      getFromChargesFromMaster().then((res) => {
        if (res.data.success === true) {
          const resultObject = {};

          res.data?.data?.forEach((item) => {
            if (
              item.corpFieldName !== "contriCharges" &&
              item.corpFieldName !== "__proto__" &&
              item.corpFieldName !== "constructor"
            ) {
              resultObject[item.corpFieldName] = item.value;
            }
          });
          setCorporateFormInputs((data) => ({
            ...data,
            ...resultObject,
            contriCharges: "autoContriCharges",
          }));
        }
      });
    } else if (corporateFormInputs.standardCharge !== "Yes") {
      setCorporateFormInputs((data) => ({
        ...data,
        acOpeningCharge: "",
        superContribCharges: "",
        contriCharges: "",
        nonFinTransactions: "",
      }));
    }
  }, [corporateFormInputs.standardCharge]);

  // .update input while changing in form fill up user
  useEffect(() => {
    setCorporateFormInputs({});
    setCorporateInputsError({});
  }, [formDoneBy]);

  const numberInputInvalidChars = ["-", "+", "e", "."];

  // Change form fillup user
  const changeFormDoneByFunction = (event) => {
    setFormDoneBy(event.target.value);
    setCorporateFormInputs({});
  };

  // fill the values of corporate if selected
  const handleChangeCorporateValues = (e, name, tag, max) => {
    if (tag === "input") {
      if (max && e.target.value?.length < max + 1) {
        setCorporateFormInputs({
          ...corporateFormInputs,
          [name]: validateFields(e, name),
        });
      } else if (!max) {
        setCorporateFormInputs({
          ...corporateFormInputs,
          [name]: validateFields(e, name),
        });
      }
    } else {
      if (name === "contriChargesSelection" && e.target.value === "₹ 30") {
        setCorporateFormInputs({
          ...corporateFormInputs,
          [name]: e.target.value,
          contriCharges: "30",
        });
      } else if (name === "contriChargesSelection" && e.target.value !== "₹") {
        setCorporateFormInputs({
          ...corporateFormInputs,
          [name]: e.target.value,
          contriCharges: "",
        });
      } else
        setCorporateFormInputs({
          ...corporateFormInputs,
          [name]: e.target.value,
        });
    }
  };

  const chargesArray = [
    "superContribCharges",
    "acOpeningCharge",
    "nonFinTransactions",
    "contriCharges",
  ];

  const validateFields = (e, name) => {
    if (e.target.type === "text") {
      return Utils.nameRegex(e);
    } else if (e.target.type === "email") {
      return e.target.value?.length <= 90
        ? Utils.emailRegex(e)
        : corporateFormInputs[name];
    } else if (e.target.type === "number") {
      if (chargesArray.includes(name)) {
        return Utils.chargesRegex(e);
      } else {
        return Utils.PreventSpecialCharacterForNumber(e);
      }
    }
  };

  const checkEmptyFields = () => {
    if (formDoneBy === "rm") return [];
    let emptyFields = corporateFormDataElement
      .filter((field) => {
        return (
          field.mandatory === true && handleCheckFieldsValidation(field.name)
        );
      })
      .map((field) => {
        return field.name;
      });

    return emptyFields;
  };

  const handleCheckFieldsValidation = (name) => {
    if (
      name === "contriChargesSelection" &&
      corporateFormInputs?.standardCharge === "Yes"
    ) {
      return false;
    } else if (
      corporateFormInputs[name] === undefined ||
      corporateFormInputs[name] === ""
    ) {
      return true;
    }

    if (name === "noMobileNumber") {
      return !Utils.validateContact(corporateFormInputs[name]).valid;
    } else if (name === "noEmailId") {
      return !Utils.validateEmail(corporateFormInputs[name]).valid;
    } else if (
      name === "superContribCharges" &&
      (parseFloat(corporateFormInputs.superContribCharges) > 0.5 ||
        parseFloat(corporateFormInputs.superContribCharges) < 0.1)
    ) {
      return true;
    } else if (
      name === "contriCharges" &&
      corporateFormInputs?.contriChargesSelection === "Percentage" &&
      (corporateFormInputs.contriCharges > 0.5 ||
        corporateFormInputs.contriCharges < 0.1)
    ) {
      return true;
    } else if (
      name === "acOpeningCharge" &&
      (parseFloat(corporateFormInputs.acOpeningCharge) > 400 ||
        parseFloat(corporateFormInputs.acOpeningCharge) < 200)
    ) {
      return true;
    } else if (
      name === "nonFinTransactions" &&
      parseFloat(corporateFormInputs[name]) < 30 &&
      corporateFormInputs[name] !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  // generate corporate ack id using pan number
  const handleSubmit = () => {
    if (checkEmptyFields()?.length > 0) {
      setFieldsError(checkEmptyFields());
      dispatch(
        setAlertMsg({
          msg: "Please fill mandatory fields",
          alertType: "warning",
        })
      );
      return;
    }

    if (Object.keys(corporateInputsError).length > 0 && formDoneBy === "corp") {
      return;
    }
    //  if new registration done by corporate
    const registrationObj =
      formDoneBy === "rm"
        ? {
            corpFormData: {
              panNumber: panNum.toLocaleUpperCase(),
              corpName: corporateName,
              ...autoPopulateData,
              formfiller: formDoneBy,
            },
            autoPopulate: autoPopulateData && Object.keys(autoPopulateData),
          }
        : {
            corpFormData: {
              panNumber: panNum.toLocaleUpperCase(),
              corpName: corporateName,
              ...corporateFormInputs,
              ...autoPopulateData,
              formfiller: formDoneBy,
              contriCharges:
                corporateFormInputs?.standardCharge === "Yes"
                  ? "autoContriCharges"
                  : corporateFormInputs?.contriCharges,
            },
            autoPopulate: autoPopulateData && Object.keys(autoPopulateData),
          };

    if (panNum.length === 0) {
      dispatch(
        setAlertMsg({
          msg: "PAN  cannot be empty",
          alertType: "warning",
        })
      );
    } else if (
      Utils.validateCorporatePan(panNum.toLocaleUpperCase()).valid === false
    ) {
      dispatch(
        setAlertMsg({
          msg: "PAN is not valid",
          alertType: "warning",
        })
      );
    } else {
      genrateAckId(registrationObj)
        .then((res) => {
          if (res.data) {
            if (res.data.data.corpData && res.data.success === true) {
              setackId(res.data.data.corpData);
              setcorpId(res.data.data.corpData.id);
              JSON.stringify(
                localStorage.setItem("corpId", res.data.data.corpData.id)
              );
              JSON.stringify(
                localStorage.setItem(
                  "corpState",
                  res.data.data.corpData.processState.id
                )
              );
              setShowModal(true);
            }
          }
        })
        .catch((res) => {
          dispatch(
            setAlertMsg({
              msg: res.response.data.message,
              alertType: "warning",
            })
          );
        });
    }
  };

  // close ack id modal
  const handleModalClose = () => {
    setShowModal(false);
    BrowserHistory.push(`/${BASE_URL}/${DASHBOARD_URL}`);
    BrowserHistory.go();
  };

  //  ack-id modal generation to form
  const handleProceed = () => {
    if (formDoneBy === "rm") {
      nextStep();
      setShowModal(false);
    } else {
      navigate(-1);
    }
  };

  // validate pan
  const handleOnChange = (e) => {
    if (e?.target?.name === "panNumber") {
      setPanNum(
        Utils.removeWhiteSpaceFromBetween(
          Utils.PreventSpecialCharacter(e),
          true
        )
      );
    } else {
      setCorporateName(
        Utils.removeWhiteSpaceFromBetween(
          Utils.PreventSpecialCharacter(e),
          true
        )
      );
      if (Object.keys(autoPopulateData).length > 0) {
        setAutoPopulateData({
          ...autoPopulateData,
          corpName: Utils.removeWhiteSpaceFromBetween(
            Utils.PreventSpecialCharacter(e),
            true
          ),
        });
      }
    }
  };

  // if pan is valid then populate data
  const populateData = () => {
    setAutoPopulateData([]);

    // check pan if it's valid then call autoPopulate api
    if (
      Utils.validateCorporatePan(panNum.toLocaleUpperCase()).valid &&
      activeStep === 0
    ) {
      authenticateCorporatePan({
        pan: panNum.toLocaleUpperCase(),
        consent: "Y",
      })
        .then((res) => {
          if (res.data.success === true) {
            //  auto populate data if pan is not registered already
            autoPopulateCorporateFrom({ pan: panNum })
              .then((res) => {
                if (res.data.success === true) {
                  setCorporateName(res.data.data.corpName);
                  setAutoPopulateData({
                    ...res.data.data,
                  });
                  setAutoPopulateModal(true);
                  setDisableCorpName(true);
                  dispatch(
                    setAlertMsg({
                      msg: "Data fetched successfully",
                      alertType: "success",
                    })
                  );
                }
              })
              .catch((error) => {
                setDisableCorpName(false);
                dispatch(
                  setAlertMsg({
                    msg: error.response.data.message || "internal server error",
                    alertType: "warning",
                  })
                );
              });
          }
        })
        .catch((error) => {
          if (error.response?.data?.responseCode === "API_FAILED") {
            setDisableCorpName(true);
          }
          setDisableCorpName(false);
          dispatch(
            setAlertMsg({
              msg: error.response.data.message || "internal server error",
              alertType: "warning",
            })
          );
        });
    }
  };

  const handleAutoPopulate = () => {
    setAutoPopulateModal(false);
  };

  const handleManually = () => {
    setAutoPopulateData({});
    setAutoPopulateModal(false);
  };

  //  show error msg
  const handleShowError = (name) => {
    if (name === "noMobileNumber" && corporateFormInputs[name]) {
      return Utils.validateContact(corporateFormInputs[name]).message;
    } else if (name === "noEmailId" && corporateFormInputs[name]) {
      return Utils.validateEmail(corporateFormInputs[name]).message;
    } else if (
      name === "superContribCharges" &&
      (corporateFormInputs.superContribCharges > 0.5 ||
        corporateFormInputs.superContribCharges < 0.1) &&
      corporateFormInputs[name] !== ""
    ) {
      return "Charges should be between 0.1% to 0.5%";
    } else if (
      name === "contriCharges" &&
      corporateFormInputs?.contriChargesSelection === "Percentage" &&
      (corporateFormInputs.contriCharges > 0.5 ||
        corporateFormInputs.contriCharges < 0.1) &&
      corporateFormInputs[name] !== ""
    ) {
      return "Charges should be between 0.1% to 0.5%";
    } else if (
      name === "acOpeningCharge" &&
      (corporateFormInputs.acOpeningCharge > 400 ||
        corporateFormInputs.acOpeningCharge < 200) &&
      corporateFormInputs[name] !== ""
    ) {
      return "Charges should be between 200 and 400";
    } else if (
      name === "nonFinTransactions" &&
      corporateFormInputs[name] < 30 &&
      corporateFormInputs[name] !== ""
    ) {
      return "Non-transactions should be more than 30";
    } else if (fieldsError.includes(name)) {
      return (
        corporateFormInputs[name]?.length > 0 || "Please fill mandatory fields"
      );
    }
  };

  const handleOnClickFocus = () => {
    inputRef.current.focus();
    setHighlightedPan(true);
  };

  const handleDocumentClick = (event) => {
    if (pandivRef.current && !pandivRef.current.contains(event.target)) {
      setHighlightedPan(false);
    }
  };

  const handleDisabled = (name) => {
    let chargeStructure = [
      "superContribCharges",
      "nonFinTransactions",
      "acOpeningCharge",
      "contriCharges",
    ];
    if (
      chargeStructure.includes(name) &&
      corporateFormInputs?.standardCharge !== "No"
    ) {
      return true;
    }

    if (
      name === "contriChargesSelection" &&
      corporateFormInputs?.standardCharge !== "No"
    ) {
      return true;
    }

    if (
      name === "contriCharges" &&
      corporateFormInputs?.contriChargesSelection !== "Percentage"
    ) {
      return true;
    }
  };

  const handleDescription = (description, name) => {
    let chargeStructureArray = [
      "nonFinTransactions",
      "superContribCharges",
      "acOpeningCharge",
      "contriCharges",
    ];
    if (
      corporateFormInputs?.standardCharge === "Yes" &&
      name === "contriCharges"
    ) {
      return Consts.Corporate_form_contribution_charge;
    }
    if (
      chargeStructureArray.includes(name) &&
      corporateFormInputs.standardCharge === "Yes"
    ) {
      return "";
    } else return description;
  };

  const handleExceptionInputs = (data) => {
    if (
      data?.name === "contriCharges" &&
      corporateFormInputs?.standardCharge === "Yes"
    ) {
      return <p></p>;
    }
  };

  const handleProgressBar = (number) => {
    if (number < 5 && activeStep !== 5) {
      activeStepRef.current.validateCurrentSTep(number);
      setActiveStep(number);
      setCurrentTab(0);
      localStorage.setItem("currentStep", JSON.stringify(number));
      localStorage.setItem("currentSubTab", JSON.stringify(0));
    }
  };

  const showHideInput = (name) => {
    if (
      name === "contriChargesSelection" &&
      corporateFormInputs?.standardCharge === "Yes"
    ) {
      return false;
    }
    return true;
  };

  // handle input special symbol
  const handleSpecialInput = (accessor) => {
    if (
      accessor === "contriCharges" &&
      corporateFormInputs?.standardCharge === "Yes"
    ) {
      return <p></p>;
    } else if (
      accessor === "contriCharges" &&
      corporateFormInputs.contriChargesSelection === "Percentage"
    ) {
      return "%";
    } else {
      return "";
    }
  };

  return (
    <div className={classes.corpprocess_container}>
      <GoBackButton />
      <div className={classes.form_title}>
        <h1>{t("text.registerCorporate")} </h1>
        {ackId.ackId && <p> Your Acknowledgement ID : {ackId.ackId} </p>}
      </div>
      <div className={classes.onboard_form_container}>
        {activeStep > 0 && (
          <div className={classes.step_progess_container}>
            <StepProgressBar
              activeStep={activeStep}
              steps={steps}
              clickAble={activeStep < 5 && true}
              stepperErrorArray={stepperErrorArray}
              limit={5}
              handleProgressBar={handleProgressBar}
            />
          </div>
        )}
        <div className={classes.corpform_container}>
          {activeStep === 0 ? (
            <div className={classes.form_container}>
              <div className={classes.pan_input_container}>
                <h3 className={classes.h3}>{formFields[0].header}</h3>

                <div className={classes.new_details}>
                  <div className={classes.selectUserType}>
                    <h4>New Corporate Registration will be done by?</h4>
                    <p>Select who will be filling the CHO FORM </p>

                    <div className="form-check form-check-inline mb-3 mr-4">
                      <label
                        className={`form-check-label ${classes.cursor_pointer}`}
                        htmlFor="rmCheckBox"
                      >
                        <input
                          className="form-check-input mr-2 "
                          type="radio"
                          value="rm"
                          onChange={changeFormDoneByFunction}
                          checked={formDoneBy === "rm"}
                          id="rmCheckBox"
                        />
                        Myself (RM)
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <label
                        className={`form-check-label ${classes.cursor_pointer}`}
                        htmlFor="corpCheckBox"
                      >
                        <input
                          className="form-check-input mr-2"
                          type="radio"
                          value={Consts.CORPORATE}
                          onChange={changeFormDoneByFunction}
                          checked={formDoneBy === Consts.CORPORATE}
                          id="corpCheckBox"
                        />
                        Corporate (Nodal Officer)
                      </label>
                    </div>
                  </div>

                  <div className={`${classes.pan_fieldscontainer} row`}>
                    <div className="col-lg-5 col-md-6">
                      <label className={classes.label}>PAN </label>
                      <span className={classes.mandatory}>*</span>
                      <div
                        className={`${classes.pancard_box} ${
                          classes.pancard_box_div
                        } ${highlightedPan ? classes.highlighted : ""}`}
                        ref={pandivRef}
                        onClick={handleOnClickFocus}
                      >
                        <input
                          style={{ textTransform: "uppercase" }}
                          value={panNum}
                          name="panNumber"
                          onChange={handleOnChange}
                          //  onFocus={handleInputFocus}
                          placeholder="EX. PVMCP0098M"
                          type="text"
                          required={true}
                          ref={inputRef}
                        />
                        <PrimaryButton
                          disabled={
                            panNum === "" ||
                            !Utils.validateCorporatePan(
                              panNum.toLocaleUpperCase()
                            ).valid ||
                            Object.keys(autoPopulateData).length > 0
                          }
                          onClick={populateData}
                          style={{
                            width: "30%",
                            height: "2.2rem",
                            fontSize: ".7rem",
                            whiteSpace: "nowrap",
                            padding: "0",
                          }}
                          label={"Get Details"}
                        />
                      </div>
                      <p className={classes.field_description}>
                        Enter PAN as per registered on official documents
                      </p>
                      <p className={classes.Validate_input}>
                        {panNum !== "" &&
                          Utils.validateCorporatePan(panNum.toLocaleUpperCase())
                            .message}
                      </p>
                    </div>
                    <div className="col-lg-5 col-md-6">
                      <label className={classes.label}>
                        Corporate Name
                        <span className={classes.mandatory}>*</span>
                      </label>
                      <input
                        name="corporateName"
                        className="form-control"
                        placeholder="EX. ABC Corporate"
                        type="text"
                        value={corporateName}
                        onInput={handleOnChange}
                        required={true}
                        disabled={disableCorpName}
                      />
                      <p className={classes.field_description}>
                        Enter name as per registered on the PAN Card
                      </p>
                    </div>
                  </div>
                  {formDoneBy === Consts.CORPORATE && (
                    <div className={` ${classes.corpFormBox} row`}>
                      {corporateFormDataElement.map((data, index) => {
                        return (
                          showHideInput(data.name) && (
                            <div className="col-lg-5 mb-3 col-md-6" key={index}>
                              <div className="form-group mb-1">
                                <label htmlFor="exampleInputPassword1">
                                  {data.label}
                                  {data.mandatory === true ? (
                                    <span className={classes.mandatory}>*</span>
                                  ) : (
                                    ""
                                  )}
                                </label>
                                {data.tag === "input" &&
                                  (handleExceptionInputs(data) || (
                                    <div className={classes.input_box}>
                                      <input
                                        onKeyDown={(e) => {
                                          if (
                                            data.type === "number" &&
                                            !chargesArray.includes(data.name) &&
                                            numberInputInvalidChars.includes(
                                              e.key
                                            )
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                        style={{
                                          textTransform:
                                            data?.type === "email"
                                              ? "uppercase"
                                              : "inherit",
                                        }}
                                        type={data.type}
                                        className="form-control"
                                        name={data.name}
                                        id={data.name}
                                        value={corporateFormInputs[data.name]}
                                        placeholder={data.placeholder}
                                        required={data.mandatory}
                                        disabled={handleDisabled(data.name)}
                                        onInput={(e) =>
                                          handleChangeCorporateValues(
                                            e,
                                            data.name,
                                            data.tag,
                                            data?.max
                                          )
                                        }
                                      />
                                      <span className={classes.number_symbol}>
                                        {handleSpecialInput(data.name) ||
                                          data?.symbol}
                                      </span>
                                    </div>
                                  ))}
                                {data.tag === "dropdown" && (
                                  <select
                                    required={data.mandatory}
                                    className="form-control"
                                    onInput={(e) =>
                                      handleChangeCorporateValues(
                                        e,
                                        data.name,
                                        data.tag
                                      )
                                    }
                                    value={corporateFormInputs[data.name]}
                                    disabled={handleDisabled(data.name)}
                                  >
                                    <option value={""}>Select Option</option>
                                    {data?.options.map(
                                      (option, optionIndex) => {
                                        return (
                                          <option key={optionIndex}>
                                            {option}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                )}

                                <span className={classes.input_errors}>
                                  {handleShowError(data.name)}
                                </span>
                                <p className={classes.field_description}>
                                  {handleDescription(
                                    data.description,
                                    data.name
                                  )}
                                </p>
                              </div>
                            </div>
                          )
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.form_btn}>
                  <SecondaryButton
                    label={t("button.cancel")}
                    onClick={() => Utils.navigationBack(location, navigate)}
                  />
                  <PrimaryButton
                    style={{ background: "var(--app-btn-background)" }}
                    onClick={handleSubmit}
                    label={t("text.proceed")}
                    disabled={!corporateName || !panNum}
                  />
                </div>
              </div>
            </div>
          ) : activeStep >= 1 && activeStep < 5 ? (
            <>
              <CorporateForm
                activeStep={activeStep}
                panNum={panNum}
                setActiveStep={setActiveStep}
                nextStep={nextStep}
                setPanNum={setPanNum}
                setackId={setackId}
                autoPopulateData={autoPopulateData}
                setAutoPopulateData={setAutoPopulateData}
                setStepperErrorArray={setStepperErrorArray}
                stepperErrorArray={stepperErrorArray}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                ref={activeStepRef}
              />
            </>
          ) : (
            <>
              <div style={{ width: "100%" }}>
                <CorporateUploadDocuments corpId={corpId} getAckId={setackId} />
              </div>
            </>
          )}
        </div>
      </div>
      {/* auto Population modal */}
      {autoPopulateModal && (
        <Modal style={{ overflow: "auto" }}>
          <div className={classes.autopopulate_container}>
            <div className={classes.autopopulate_title}>
              <h2>Auto Populate Data</h2>
              <p>
                To enhance your experience , we will automatically populate the
                following fields using the information from your PAN Card
              </p>
            </div>
            <div className={classes.autopopulate_checkbox_container}>
              <p>
                Please mark the fields which you want to auto populate, This
                won't be editable later.
              </p>
              <div>
                {ConfigObj.map((config, stepIndex) => {
                  return (
                    <div key={stepIndex}>
                      {config.section.map((section, sectionIndex) => {
                        let showData = false;
                        for (const field of section?.Fields) {
                          if (autoPopulateData[field.accessor]) {
                            showData = true;
                            break;
                          }
                        }
                        return (
                          showData && (
                            <div
                              className={classes.section_container}
                              key={sectionIndex}
                            >
                              <label className={classes.fields_title}>
                                {section.title}
                              </label>
                              <div className="row">
                                {
                                  <>
                                    {section?.Fields?.map(
                                      (field, fieldIndex) =>
                                        autoPopulateData[field.accessor] && (
                                          <div
                                            className={`${classes.autopopulate_fields} col-lg-5 mb-3`}
                                            key={fieldIndex}
                                          >
                                            {field?.label && (
                                              <label>{field.label}</label>
                                            )}
                                            <input
                                              disabled={true}
                                              className="form-control"
                                              value={
                                                autoPopulateData[field.accessor]
                                              }
                                            />
                                          </div>
                                        )
                                    )}
                                  </>
                                }
                              </div>
                            </div>
                          )
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={classes.auto_populate_note}>
              Note : click on Auto populate to continue the registration , these
              fields won't be editable
            </div>
            <div className={classes.auto_populate_btn2}>
              <PrimaryButton
                onClick={handleAutoPopulate}
                label={t("button.auto&populate")}
              />
              <PrimaryButton
                onClick={handleManually}
                label={t("button.fillmanually")}
              />
            </div>
          </div>
        </Modal>
      )}

      {/*  ackId modal */}
      {showModal && ackId && (
        <Modal close={false}>
          <div className={classes.ackId_modal_container}>
            <div className={classes.ackId_title}>
              <img src={CheckMarkGreenIcon} />
              <h2 className={classes.ackId}>{ackId.ackId}</h2>
              <p className={classes.p}>
                Your registration request has been initiated successfully
              </p>
              <TextButton
                style={{ border: " .2rem solid var(--light-grey)" }}
                label={t("button.printThis")}
                onClick={() => window.print()}
              />
            </div>
            <div className={classes.ackId_details}>
              <div className={classes.corp_name}>
                <p>PAN </p>
                <p>{panNum.toLocaleUpperCase()}</p>
              </div>
              <div className={classes.receipt_date}>
                <p>Receipt date</p>
                <p>
                  {Utils.convertToLocalDateFullYearOnly(ackId.submissionDate)}
                </p>
              </div>
            </div>
            <div className={classes.ackId_noted}>
              <p className={classes.p}>Note - </p>
              <p className={classes.p}>
                click on proceed to continue the registration or close to
                process later.
              </p>
            </div>
            <div className={classes.ackId_btn}>
              <PrimaryButton
                label={t("button.proceed")}
                onClick={handleProceed}
              />
              <SecondaryButton
                onClick={handleModalClose}
                label={t("button.close")}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CorporateOnboardProcess;
