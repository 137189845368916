import axios from "axios";

export function subscriberAckid(data) {
  return axios({
    url: `/hpmc/api/subscribers/generate-ackid`,
    method: "post",
    data: data,
    responseType: "json",
  });
}

export function getSubscriber(ackId, pan) {
  return axios({
    url: `/hpmc/api/subscribers/getdetails?ackId=${ackId}&phoneNumber=${pan}`,
    method: "get",
    responseType: "json",
  });
}

export function getSubscriberById(Id) {
  return axios({
    url: `/hpmc/api/subscribers/${Id}`,
    method: "get",
    responseType: "json",
  });
}

// save data  and submit subscriber uploaded document
export function saveSubscriberForm(data, id, action) {
  return axios({
    url: `/hpmc/api/subscribers/${id}?action=${action}`,
    method: "put",
    data: data,
    responseType: "json",
  });
}

// get documents for Subscriber Document Upload
export function getDocumentsListForSubscriber(ackId) {
  return axios({
    url: `/hpmc/api/subscribers/${ackId}`,
    method: "get",
    responseType: "json",
  });
}

// upload subscriber documents
export function uploadSubscriberDocument(subId, docId, data) {
  return axios({
    url: `/hpmc/api/subscribers/save/${subId}/${docId}`,
    method: "POST",
    responseType: "json",
    data: data,
  });
}

//  get parameter from digilocker redirect from website

export function getParamsFromDigiLocker(params) {
  return axios({
    url: `/hpmc/api/subscribers/set-digilocker-status?${params}`,
    method: "get",
    responseType: "json",
  });
}

//  get result from api
export function getResultFromDigiLocker(id) {
  return axios({
    url: `/hpmc/api/subscribers/get-digilocker-status/${id}`,
    method: "get",
    responseType: "json",
  });
}


export function getDigiLockStartLinkFunction(id) {
  return axios({
    url: `/hpmc/api/subscribers/digilocker-link/${id}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function saveorSubmitSubscriberForm(id, data, action) {
  return axios({
    url: `/hpmc/api/subscribers/update/${id}?action=${action}`,
    method: "put",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
