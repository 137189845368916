import axios from "axios";

export function getDigiLockStartLinkFunction(id) {
  return axios({
    url: `/hpmc/api/subscribers/digilocker-link/${id}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function saveorSubmitSubscriberForm(id, data, action) {
  return axios({
    url: `/hpmc/api/subscribers/update/${id}?action=${action}`,
    method: "put",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
