import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./Users.module.css";
import { useSelector } from "react-redux";
import GoBackButton from "../../components/Buttons/GoBackButton";

function UserInfo({ label, value }) {
  return (
    <div className={classes.user_div}>
      <label className={classes.user_label}>{label}</label>
      <p className={classes.user_label_value}>{value}</p>
    </div>
  );
}
function UserInfoList({ label, value, propertyToPrint }) {
  return (
    <div className={classes.user_div}>
      <label className={classes.user_label}>{label}</label>
      <ul>
        {value &&
          value.map((data, index) => {
            return (
              <li className={classes.list} key={index}>
                {data[propertyToPrint]}
              </li>
            );
          })}
        {value?.length === 0 && <p className={classes.user_label_value}>NA</p>}
      </ul>
    </div>
  );
}
function Users(props) {
  const { t } = useTranslation();
  const { loginUserDetails } = useSelector((state) => state);
  const [deprtDesignationArr, setDeprtDesignationArr] = useState([]);

  useEffect(() => {
    const getDepartmentDesignation = (data) => {
      let splittedData = data?.map((e) => {
        let subArray = e?.name.split("_");
        return {
          department: subArray[0],
          designation: subArray[subArray.length - 1],
        };
      });
      setDeprtDesignationArr(splittedData);
    };

    getDepartmentDesignation(loginUserDetails.internalGroupRoles);
  }, [loginUserDetails]);

  return (
    <div className={classes.userdetail_container}>
      <div className={classes.backBtn}>
        <GoBackButton />
      </div>
      <div className={classes.userdetail_form}>
        <div className={classes.title}>
          <h2>{t("User Profile")}</h2>
          <p>Here you can see your profile</p>
        </div>

        <div className={classes.user_info}>
          <hr />
          <div className={classes.user_sub_heading}>
            <h3>{t("Basic Information")}</h3>
          </div>
          <div className="d-flex align-items-start">
            <UserInfo
              label={t("Name")}
              value={loginUserDetails.fullName || "NA"}
            />
            <UserInfo
              label={t("Email Id")}
              value={loginUserDetails.username || "NA"}
            />
          </div>
          <div className="d-flex align-items-start">
            <UserInfo
              label={t("Employee ID")}
              value={loginUserDetails?.employeeCode || "NA"}
            />
            <UserInfo
              label={t("Contact")}
              value={loginUserDetails.contactNo || "NA"}
            />
          </div>
          <div className="d-flex align-items-start">
            <UserInfo
              label={t("Operating Location")}
              value={loginUserDetails?.location || "NA"}
            />
            <UserInfo
              label={t("Immediate Manager")}
              value={loginUserDetails?.immediateManagerUsername || "NA"}
            />
          </div>

          <div className="d-flex align-items-start">
            <UserInfoList
              label={t("Department")}
              value={deprtDesignationArr}
              propertyToPrint="department"
            />
            <UserInfoList
              label={t("Designation")}
              value={deprtDesignationArr}
              propertyToPrint="designation"
            />
          </div>

          {/* <div className="d-flex align-items-start">
            <UserInfoList
              label={t("Managers")}
              value={loginUserDetails.managers?.map((e) => ({
                username: `${e?.username} (level - ${e?.level})`,
              }))}
              propertyToPrint="username"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Users;
