import React from "react";
import classes from "../MasterManagement.module.css";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import { useState } from "react";
import ArrowUPIcon from "../../../../assets/arrow-up.svg";
import PenEditIcon from "../../../../assets/pen_edit.svg";
import { useEffect } from "react";
import {
  editClaimUnclaim,
  makerCheckerFlow,
  getAllPOPSP,
  getPOPSP,
  getAllCRAs,
} from "../../AdminManagementActions";
import TextButton from "../../../../components/Buttons/TextButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import Modal from "../../../../components/Modal/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMsg } from "../../../Home/HomeSlice";
import Utils from "../../../../Utils";

const POP_SP_Management = ({
  searchingQuery,
  dateOne,
  dateTwo,
  perPage,
  isWorkflowEnabled,
  masterId,
}) => {
  const userDetails = useSelector((state) => state.loginUserDetails);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("");
  const [accessor, setAccessor] = useState("");
  const [columnRotations, setColumnRotations] = useState("submissionDateasc");
  const [tableData, setTableData] = useState([]);
  const [pageData, setPageData] = useState({});
  const [filterBy, setfilterBy] = useState("");
  const [pFMFormActive, setPFMFormActive] = useState(false);
  const [editPOP_SP, setEditPOP_SP] = useState({
    id: null,
    popSpCraId: null,
    popSpOffice: "",
    popSpRegNo: "",
  });
  const [staticPOP_SPInput, setStaticPOP_SPInput] = useState({
    id: null,
    popSpCraId: null,
    popSpOffice: "",
    popSpRegNo: "",
  });
  const [disabledStatus, setDisabledStatus] = useState(true);
  const [changedVal, setChangedVal] = useState({});

  const [allCRAs, setAllCRAs] = useState([]);
  const [rowId, setRowId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  // holiday table columns
  const pfmTableColumns = [
    {
      header: (
        <p className={classes.headers + " " + "text-left"}>
          CRA Name
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              textAlign: "left",
              transform:
                columnRotations === "cra.nameasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("cra.name")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.craName,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 100 },
    },
    {
      header: (
        <p className={classes.headers + " " + "text-left"}>
          POP SP Office
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              textAlign: "left",
              transform:
                columnRotations === "popSpOfficeasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("popSpOffice")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.popSpOffice || "--",
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 100 },
    },
    {
      header: (
        <p className={classes.headers}>
          POP SP Reg. No
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              textAlign: "left",
              transform:
                columnRotations === "popSpRegNoasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("popSpRegNo")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.popSpRegNo || "--",
    },

    {
      header: <p className={classes.headers}>ACTION</p>,
      key: "corpAction",
      accessor: (e) => (
        <div className={classes.action_btn}>
          {isWorkflowEnabled && (
            <TextButton
              style={{
                fontSize: "0.8rem",
                display:
                  e.makerCheckerStatus === null ||
                  e.makerCheckerStatus === 0 ||
                  e?.currentMaker?.username !== userDetails.username ||
                  (e.makerCheckerFlowFunction == 1 &&
                    e?.currentMaker?.username !== userDetails.username)
                    ? "none"
                    : "",
              }}
              disabled={
                e.makerCheckerStatus === 2 ||
                (e.makerCheckerStatus === 1 &&
                  e?.currentMaker?.username !== userDetails.username)
              }
              onClick={() => {
                editClaimUnclaimFunction(e.id, true);
                setRowId(e.id);
              }}
              label={t("button.release")}
            />
          )}

          <TextButton
            disabled={
              e.makerCheckerStatus === 2 ||
              (e.makerCheckerStatus === 1 &&
                e?.currentMaker?.username !== userDetails.username &&
                userDetails.username != "wbadmin")
            }
            onClick={() => {
              isWorkflowEnabled == true &&
              (e.makerCheckerStatus == 0 || e.makerCheckerStatus == null) ? (
                <>{(setIsEdit(true), editPFMSFunctionData(e.id))}</>
              ) : (
                <>{(setPFMFormActive(true), editPFMSFunctionData(e.id))}</>
              );
            }}
            label={t("button.edit")}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    setPage(0);
  }, [perPage, searchingQuery]);
  useEffect(() => {
    getAllPOPSPFunction();
    getAllCRAsFunction();
  }, [sort, accessor, dateOne, dateTwo, page, perPage, searchingQuery]);

  const getAllPOPSPFunction = () => {
    getAllPOPSP({
      page,
      searchingQuery,
      sort,
      accessor,
      perPage,
      filterBy,
    }).then((res) => {
      setTableData(res.data.data.content);
      setPageData(res.data.data);
    });
  };

  const getAllCRAsFunction = () => {
    getAllCRAs().then((res) => {
      setAllCRAs(res.data.data);
    });
  };

  const handleSort = (key) => {
    var sorting = sort;
    const newSort = sort === "asc" ? "desc" : "asc";
    setSort(newSort);
    setAccessor(key);
    if (sort === "asc") {
      setColumnRotations(key + "desc");
    } else {
      setColumnRotations(key + "asc");
    }
  };

  const editPFMSFunctionData = (id) => {
    setRowId(id);
    getPOPSP(id).then((res) => {
      const { cra, popSpOffice, popSpRegNo, id } = res.data.data.masterData;
      let popSpCraIdObj = {
        popSpCraId: Number(cra.id),
      };
      let otherObj = {
        id: id,
        popSpOffice: popSpOffice,
        popSpRegNo: popSpRegNo,
      };
      let mergeArr = { ...otherObj, ...popSpCraIdObj };

      setEditPOP_SP(mergeArr);
      setStaticPOP_SPInput(mergeArr);
    });
  };

  const editClaimUnclaimFunction = (rowId, isReleased) => {
    editClaimUnclaim(masterId, rowId)
      .then((res) => {
        if (isReleased == false) {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.task_claimed"),
              alertType: "success",
            })
          );
          setPFMFormActive(true);
          setIsEdit(false);
        } else {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.task_unclaimed"),
              alertType: "success",
            })
          );
          getAllPOPSPFunction();
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.task_claim_fail"),
            alertType: "danger",
          })
        );
      });
  };
  const makerCheckerFlowFunction = () => {
    let data = changedVal;
    makerCheckerFlow(masterId, rowId, data)
      .then((res) => {
        if (res.data.success === true) {
          dispatch(
            setAlertMsg({
              msg:
                isWorkflowEnabled === true
                  ? t("alertMsg.changes_for_approval")
                  : t("alertMsg.edit_successfully"),
              alertType: "success",
            })
          );

          setPFMFormActive(false);
          getAllPOPSPFunction();
        } else {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.update_failed"),
              alertType: "danger",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.update_failed"),
            alertType: "danger",
          })
        );
      });
  };

  //  handle change modal inputs
  const handleChangeInput = (e, isNumber) => {
    let { name, value } = e.target;
    value = isNumber ? Number(value) : value;
    setEditPOP_SP((data) => ({
      ...data,
      [name]: value,
    }));

    if (staticPOP_SPInput[name] === value) {
      delete changedVal[name];
    } else {
      setChangedVal((data) => ({
        ...data,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    checkSubmitDisabled();
  }, [editPOP_SP]);

  // checked changes or not?
  const checkSubmitDisabled = () => {
    var status =
      (editPOP_SP.popSpCraId === staticPOP_SPInput.popSpCraId &&
        editPOP_SP.popSpRegNo === staticPOP_SPInput.popSpRegNo &&
        Utils.removeWhiteSpaceFromBetween(editPOP_SP.popSpOffice)
          .toLocaleLowerCase()
          .trim() ==
          Utils.removeWhiteSpaceFromBetween(staticPOP_SPInput.popSpOffice)
            .toLocaleLowerCase()
            .trim()) ||
      Utils.removeWhiteSpaceFromBetween(editPOP_SP.popSpOffice) == ""
        ? true
        : false;

    // var status = true;
    setDisabledStatus(status);
  };
  return (
    <>
      <CustomTable
        page={page}
        setPage={setPage}
        headings={pfmTableColumns}
        data={tableData}
        pageData={pageData}
        sort={sort}
        setSort={setSort}
        accessor={accessor}
        setAccessor={setAccessor}
        srNO={true}
      />
      {/* for holiday edit */}

      {isEdit == true && isWorkflowEnabled == true && (
        <Modal>
          <div className={classes.editModal_container}>
            <h4>
              <span>
                <img src={PenEditIcon} alt="pen edit Master management" />
              </span>{" "}
              {t("proceed.edit.master")}
            </h4>
            <p>{t("proceed.edit.master.text")}</p>
            <div className={classes.editModal_btn}>
              <PrimaryButton
                onClick={() => {
                  editClaimUnclaimFunction(rowId, false);
                }}
                label={t("button.proceed")}
              />
              <TextButton
                onClick={() => {
                  setIsEdit(false);
                }}
                label={t("button.close")}
              />
            </div>
          </div>
        </Modal>
      )}

      {pFMFormActive && (
        <Modal
          closeBtn={{}}
          onClose={() => {
            getAllPOPSPFunction();
            setPFMFormActive(false);
          }}
        >
          <div className={classes.holiday_modal_body}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-input mb-3">
                  <label>CRA Name</label>
                  <select
                    className="form-control form-select"
                    name="popSpCraId"
                    value={editPOP_SP.popSpCraId}
                    onChange={(e) => handleChangeInput(e, true)}
                  >
                    <option value="">Select CRA Name</option>
                    {allCRAs &&
                      allCRAs.map((data, index) => {
                        return <option value={data.id}>{data.name}</option>;
                      })}
                  </select>
                </div>
                <div className="form-input mb-3">
                  <label>POP SP Office</label>
                  <input
                    type="text"
                    name="popSpOffice"
                    value={editPOP_SP.popSpOffice}
                    onInput={(e) => handleChangeInput(e, false)}
                    className="form-control"
                  />
                </div>
                <div className="form-input">
                  <label>POP SP Reg. No</label>
                  <input
                    type="number"
                    name="popSpRegNo"
                    value={editPOP_SP.popSpRegNo}
                    onInput={(e) => handleChangeInput(e, true)}
                    className="form-control"
                  />
                </div>

                <div className="btn-group mt-4">
                  <div className="btn-form mr-3">
                    <PrimaryButton
                      disabled={disabledStatus}
                      label={t("button.submit")}
                      onClick={() => {
                        makerCheckerFlowFunction(staticPOP_SPInput.id);
                        // changePFMFunction(changedPFMInput.id);
                      }}
                    />
                  </div>
                  <div className="btn-form">
                    <SecondaryButton
                      onClick={() => {
                        getAllPOPSPFunction();
                        setPFMFormActive(false);
                      }}
                      label={t("button.cancel")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default POP_SP_Management;
