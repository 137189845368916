import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import {
  BASE_URL,
  DASHBOARD_URL,
  SIGN_IN_URL,
  SUBSCRIBER_REGISTRATION_HOME,
} from "../../../Routes/Urls";
import {
  getDigiLockStartLinkFunction,
  saveorSubmitSubscriberForm,
} from "../SubscriberRegistration/SubscriberRegistrationAction";
import Classes from "./SubscriberMigration.module.css";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import SubscriberFormStep3 from "../SubscriberForm/SubscriberFormStep3";
import SubscriberRegistrationForm from "../SubscriberRegistration/RegistrationHelper/SubscriberRegistrationForm/SubscriberRegistrationForm";
import SubscriptionRequirementModal from "../SubscriberRegistration/RegistrationHelperModal/SubscriptionRequirementModal";
import ContactVerification from "../SubscriberRegistration/RegistrationHelper/ContactVerification/ContactVerification";
import NonLabelStepper from "../../../components/NonLabelStepper/NonLabelStepper";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import leftArrowIcon from "../../../assets/left-arrow-icon.svg";
import NPSICSSForm from "../NpsICSSForm/NPSICSSForm";
import NPSISSForm from "../NPSISSForm/NPSISSForm";
import ObjPreViewForm from "../../../components/PreViewForm/ObjPreViewForm";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SubscriberDigiLocker from "../SubscriberRegistration/SubscriberDigiLocker/SubscriberDigiLocker";
import SubscriberPrevForm from "../SubscriberPrevForm/SubscriberPrevForm";
import { getSubscriber } from "../SubscriberOnboardAction";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../Home/HomeSlice";

const SubscriberMigration = () => {
  const navigate = useNavigate();
  const bankDetailsRef = useRef();
  const subscriberRef = useRef();
  const dispatch = useDispatch();
  const [successModal, setSuccessModal] = useState(false);
  const [requirementModal, setRequirementModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [nextButtonDisable, setNextButtonDisable] = useState(true);
  const [previewData, setPreviewData] = useState({});
  const [allData, setAllData] = useState({});
  const [isFlowFinished, setIsFlowFinished] = useState(false);
  const [ackId, setAckId] = useState(null);
  const [isDigiLockerModal, setIsDigiLockerModal] = useState(false);
  const [digiLockerURL, setDigiLockerURL] = useState("");
  const [isICSS, setIsICSS] = useState(false);
  const [corpIdParam, setCorpIdParam] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const steps = [
    {
      index: 0,
      stepName: "Step 1.... How can we contact you ?",
      formHeading: "Contact Details ",
      instruction: {
        text: "Email and Phone number is only for communication purpose only. OTP will be sent these number and email id.",
      },
    },
    {
      index: 1,
      stepName: "Step 2.....Let's know about you!",
      formHeading: "Subscriber Details",
      instruction: {
        text: "As per the amendments made under Prevention of Money-Laundering (Maintenance of Records) Second Amendment Rules, 2019, PAN is mandatory under NPS.If you do not have PAN at present, please ensure that these details are provided within six months of submission of this Subscriber Registration Form",
      },
    },
    {
      index: 2,
      stepName: isICSS
        ? "3. ICSS (Inter CRA Subscriber Shifting"
        : "ISS (Inter Sector Subscriber Shifting)",
      formHeading: isICSS
        ? "3. ICSS (Inter CRA Subscriber Shifting)"
        : "ISS (Inter Sector Subscriber Shifting)",
      instruction: {
        text: isICSS ? (
          <div>
            <h6>Existing PRAN association (Source Office Details)</h6>
            <p>
              The POP SP i.e Point of Presence Service Provider is the NPS
              intermediary with whom you have opened your NPS account
            </p>
          </div>
        ) : (
          <p>
            Kindly download CSV template , fill in the details and upload the
            file for verification
          </p>
        ),
      },
    },
    {
      index: 3,
      stepName: "Step 3",
      formHeading: "Investment Details",
      instruction: {
        text: "The NPS Auto Choice Option Is For Passive Investors Who Seek To Let An Automatic Allocation Decide The Proportion Of Money Spread Across The Available Asset Classes.",
        htmlText: (
          <div className={Classes.third_instruction}>
            <h6>Lifecycle fund and its types</h6>
            <p>
              <span>Note</span> : In case, you do not indicate a choice of LC,
              your funds will be invested as per LC 50
            </p>
            <ul>
              <li>
                <span>1. LC 75 -</span> It is the Life cycle fund where the Cap
                to Equity investments is 75% of the total asset
              </li>
              <li>
                {" "}
                <span>2. LC 50 -</span> It is the Life cycle fund where the Cap
                to Equity investments is 50% of the total asset{" "}
              </li>
              <li>
                <span>3. LC 25 - </span> It is the Life cycle fund where the Cap
                to Equity investments is 25% of the total asset
              </li>
            </ul>
          </div>
        ),
      },
    },
  ];

  const handleClick = (e) => {
    if (activeIndex <= 1 && e === -1) {
      return;
    }
    if (activeIndex === 0) {
      subscriberRef.current.subscriberRefFunction();
      return;
    }
    if (activeIndex + e === steps.length) {
      setIsFlowFinished(true);
    }
    handleSaveSubmitForm(previewData);
    setActiveIndex((data) =>
      data + e >= steps.length
        ? steps.length - 1
        : data + e < steps[0].index
        ? steps[0].index
        : data + e
    );
  };

  // on cancel click
  const handleCancelClick = () => {
    navigate(`/${BASE_URL}/${DASHBOARD_URL}/${SUBSCRIBER_REGISTRATION_HOME}`);
  };

  // call digilocker function
  const callDigiLockerfunction = () => {
    getDigiLockStartLinkFunction(194)?.then((res) => {
      if (res.data.success) {
        setDigiLockerURL(res?.data?.data?.redirectUrl);
        setIsDigiLockerModal(true);
      }
    });
  };

  useEffect(() => {
    checkUrlParams();
    setNextButtonDisable(true);

    let obj = {
      subFormData: {},
    };
    if (ackId != null) {
      handleSaveSubmitForm(obj);
    }

    //  get subscriber details
    const subsDetail = JSON.parse(localStorage.getItem("subDetails"));
    if (subsDetail) {
      getSubscriber(subsDetail?.subAckId, subsDetail?.subPhone)
        .then((res) => {
          if (res.data.success === true) {
            setAckId(res.data.data?.subData?.id);
            setActiveIndex(1);
          }
        })
        .catch((error) => {
          dispatch(
            setAlertMsg({
              msg: error?.response?.data?.message,
              alertType: "danger",
            })
          );
        });
    }
  }, []);

  const checkUrlParams = () => {
    let idParam = searchParams.get("corporateId");
    setCorpIdParam(idParam);
    if (idParam === null || idParam === undefined) {
      navigate(`/${BASE_URL}/${SIGN_IN_URL}`);
    }
  };

  // handle save submit data
  const handleSaveSubmitForm = (data) => {
    saveorSubmitSubscriberForm(ackId, data, "save")
      .then((res) => {
        if (res?.data?.success) {
          setAllData(res?.data?.data);
          setPreviewData(res?.data?.data?.subFormData);
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: err?.response?.data?.message,
            alertType: "danger",
          })
        );
      });
  };

  return (
    <>
      {isFlowFinished ? (
        <SubscriberPrevForm previewData={allData} />
      ) : (
        <div className="container-fluid py-4 px-4 ">
          <div className={Classes.goback_btn}>
            <GoBackButton />
          </div>
          <div className={Classes.page_title}>
            <h4> Register Corporate Subscriber </h4>
            {ackId != null && <p>Your Acknowledgement ID : {ackId} </p>}
          </div>

          <div className={Classes.newSubscriberBox}>
            <div className={Classes.formArea}>
              <div className={Classes.formBox}>
                <div className={Classes.headerSteps}>
                  <div className={Classes.stepName}>
                    <span
                      className={Classes.prevButtonIcon}
                      onClick={() => handleClick(-1)}
                    >
                      <img src={leftArrowIcon} alt="Prev Back " />
                    </span>{" "}
                    {steps[activeIndex]?.stepName}
                  </div>
                  <NonLabelStepper steps={steps} activeIndex={activeIndex} />
                </div>
                <div className={Classes.formHeading}>
                  <h4>{steps[activeIndex]?.formHeading}</h4>
                  <div className={Classes.mandaory}>
                    <span>*</span> Mandatory Fields{" "}
                  </div>
                </div>

                {activeIndex === steps[0].index && (
                  <ContactVerification
                    startTime={30}
                    successModal={successModal}
                    setSuccessModal={setSuccessModal}
                    setIsProceed={setNextButtonDisable}
                    setAckId={setAckId}
                    ref={subscriberRef}
                    corpIdParam={corpIdParam}
                    setPreviewData={setAllData}
                    handleProceedAckIdFunction={() => {
                      setActiveIndex(1);
                      setSuccessModal(false);
                    }}
                  />
                )}
                {requirementModal && (
                  <SubscriptionRequirementModal
                    requiredModal={requirementModal}
                    setRequiredModal={setRequirementModal}
                    handleFunction={() => {
                      setRequirementModal(false);
                      setActiveIndex(1);
                    }}
                  />
                )}
                {activeIndex === steps[1].index && (
                  <SubscriberRegistrationForm
                    ref={subscriberRef}
                    callDigiLockerfunction={callDigiLockerfunction}
                    ackId={ackId}
                    setIsProceed={setNextButtonDisable}
                    previewData={allData}
                    setPreviewData={setPreviewData}
                    setActiveIndex={setActiveIndex}
                  />
                )}

                {activeIndex === steps[2].index && <NPSICSSForm />}

                {activeIndex === steps[3].index && (
                  <SubscriberFormStep3
                    setIsProceed={setNextButtonDisable}
                    ackId={ackId}
                    ref={subscriberRef}
                    previewData={allData}
                    setPreviewData={setPreviewData}
                    setActiveIndex={setActiveIndex}
                    migration={true}
                  />
                )}
              </div>
            </div>
            <div className={Classes.comment_container_form}>
              <p className={Classes.instruct_p}>
                0{Number(steps[activeIndex]?.index) + 1} | Instruction
              </p>
              <h5>{steps[activeIndex]?.formHeading}</h5>
              <p className={Classes.text}>
                {steps[activeIndex]?.instruction?.text}
              </p>
              {steps[activeIndex]?.instruction?.htmlText &&
                steps[activeIndex]?.instruction?.htmlText}
            </div>
            <div className="btns mt-5">
              <SecondaryButton
                label={"Cancel"}
                disabled={activeIndex === steps[0].index}
                onClick={handleCancelClick}
                style={{ marginRight: "1rem" }}
              />
              <SecondaryButton
                label={activeIndex > 0 ? "Next" : "Proceed"}
                disabled={nextButtonDisable}
                onClick={() => handleClick(1)}
                style={{ marginRight: "1rem" }}
              />

              {steps[activeIndex]?.index === 4 && (
                <PrimaryButton
                  label={"Fill Bank Details Manually"}
                  onClick={() => bankDetailsRef.current.toggle()}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {isDigiLockerModal && (
        <SubscriberDigiLocker
          url={digiLockerURL}
          isOpenModal={isDigiLockerModal}
          setIsOpenModal={setIsDigiLockerModal}
        />
      )}
    </>
  );
};

export default SubscriberMigration;
