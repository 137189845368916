import React from "react";
import classes from "../MISReport.module.css";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { useState } from "react";
import Utils from "../../../Utils";
import ArrowUPIcon from "../../../assets/arrow-up.svg";
import { useEffect } from "react";
import {
  getAllCorporateAutoPopulate,
  getMISReportAudit,
} from "../../AdminManagement/AdminAction";

const CorporateCHOAutoPopulate = ({
  searchingQuery,
  dateOne,
  dateTwo,
  perPage,
}) => {
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("");
  const [accessor, setAccessor] = useState("");
  const [columnRotations, setColumnRotations] = useState("submissionDateasc");
  const [tableData, setTableData] = useState([]);
  const [pageData, setPageData] = useState({});
  // mis report table columns
  const corporateCHO = [
    {
      header: (
        <p className={classes.headers}>
          CORPORATE ACK ID
          <img
            style={{
              cursor: "pointer",
              // paddingLeft: ".4rem",
              // paddingRight: ".4rem",
              width: "0.6rem",
              marginLeft: ".25rem",
              transform:
                columnRotations === "corporate.ackIdasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("corporate.ackId")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.corporateAckId,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 18 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          CORPORATE NAME
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "corporate.corpNameasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("corporate.corpName")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.corporateName,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 18 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          FIELD NAME
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "corpField.nameasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("corpField.name")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.corpFieldName,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 18 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          VALUE
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "valueasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("value")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.value || "NA",
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 18 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          CHANGED VALUE
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "changedValueasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("changedValue")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.changedValue || "NA",
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 18 },
      isTextCenter: true,
    },
    {
      header: (
        <p className={classes.headers}>
          MODIFICATION DATE & TIME
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "modificationDateasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("modificationDate")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) =>
        e.modificationDate
          ? `${Utils.getDateFromDateTime(
              e?.modificationDate
            )}, ${Utils.getTimeFromDateTime(e?.modificationDate)}`
          : "NA",
      toolTipStatus: true,
      isTextCenter: true,
    },
  ];
  useEffect(() => {
    setPage(0);
  }, [perPage, searchingQuery, dateOne, dateTwo]);
  useEffect(() => {
    getAllCorporateAutoPopulate(
      searchingQuery,
      dateOne,
      dateTwo,
      page,
      perPage,
      sort,
      accessor
    ).then((res) => {
      setTableData(res.data.data.content);
      setPageData(res.data.data);
    });
  }, [sort, accessor, dateOne, dateTwo, page, perPage, searchingQuery]);

  const handleSort = (key) => {
    var sorting = sort;
    // Toggle sort order
    const newSort = sort === "asc" ? "desc" : "asc";
    setSort(newSort);
    setAccessor(key);

    // Update rotation state for the current column
    if (sort === "asc") {
      setColumnRotations(key + "desc");
    } else {
      setColumnRotations(key + "asc");
    }
  };
  return (
    <CustomTable
      page={page}
      setPage={setPage}
      headings={corporateCHO}
      data={tableData}
      pageData={pageData}
      sort={sort}
      setSort={setSort}
      accessor={accessor}
      setAccessor={setAccessor}
      srNO={true}
    />
  );
};

export default CorporateCHOAutoPopulate;
