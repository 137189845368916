import React, { useEffect, useRef, useState } from "react";
import classes from "./OPSServiceRequest.module.css";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Utils from "../../../Utils";
import { useTranslation } from "react-i18next";
import { getCorporateDashboardData } from "../../CorponboardingForm/CorporateAction";

// icons
import ArrowUPIcon from "../../../assets/TableDashboard/ArrowUp.svg";
import GreenDot from "../../../assets/TableDashboard/GreenDot.svg";
import RedDot from "../../../assets/TableDashboard/RedDot.svg";
import OrangeDot from "../../../assets/TableDashboard/OrangeDot.svg";
import TableDashboard from "../../../components/TableDashboard/TableDashboard";
import ClockIcon from "../../../assets/TableDashboard/time.svg";
import CheckMarkIcon from "../../../assets/TableDashboard/checkmark.svg";
import TimelimitIcon from "../../../assets/TableDashboard/Timelimit.svg";
import OverdueIcon from "../../../assets/TableDashboard/overdue.svg";

// urls
import {
  BASE_URL,
  CORPORATEDASHBOARD_URL,
  CORPORATE_ONBOARD_FORM_URL,
  DASHBOARD_URL,
  ServiceRequest_URL,
  ServiceRequest_Update_Url,
} from "../../../Routes/Urls";
import Modal from "../../../components/Modal/Modal";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import { getCorporateByCHO } from "../OPSActions";
import { setAlertMsg } from "../../Home/HomeSlice";
import { setSrUpdateFormData } from "../OPSSlice";

const OPSServiceRequest = () => {
  // to print screen
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [columnRotations, setColumnRotations] = useState("submissionDateasc");
  const [tableData, setTableData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [pageData, setPageData] = useState({});
  const [page, setPage] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [sort, setSort] = useState("desc");
  const [accessor, setAccessor] = useState("submissionDate");
  const [perPage, setperPage] = useState(10);
  const [totalCount, setTotalCount] = useState({});
  const [filterBy, setfilterBy] = useState("");
  const [formObj, setFormObj] = useState({});
  const [corpFormModal, setCorpFormModal] = useState(false);
  const [dateOne, setDateOne] = useState("");
  const [dateTwo, setDateTwo] = useState("");
  const [subordinateId, setSubordinateId] = useState("");
  const [isNewRequest, setIsNewRequest] = useState(false);
  const [srRaisedBy, setSRRaisedBy] = useState("Corporate");
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    getCorporateDashboardData(
      {
        page,
        searchInput,
        sort,
        accessor,
        perPage,
        filterBy,
        subordinateId,
      },
      Utils.convertToLocalDateForInput(dateOne),
      Utils.convertToLocalDateForInput(dateTwo)
    ).then((res) => {
      setTableData(res.data.data.pageData.content);
      setPageData(res.data.data.pageData);
      setTotalCount(res.data.data.countData);
    });
  }, [
    page,
    sort,
    accessor,
    perPage,
    filterBy,
    subordinateId,
    dateOne && dateTwo,
  ]);

  useEffect(() => {
    setCorpFormModal(false);
  }, [formObj]);

  useEffect(() => {
    setPage(0);
  }, [perPage]);

  // handle search
  const handleSearch = (e) => {
    e.preventDefault();
    getCorporateDashboardData(
      {
        page,
        searchInput,
        sort,
        accessor,
        perPage,
        filterBy,
        subordinateId,
      },
      Utils.convertToLocalDateForInput(dateOne),
      Utils.convertToLocalDateForInput(dateTwo)
    ).then((res) => {
      setTableData(res.data.data.pageData.content);
      setPageData(res.data.data.pageData);
      setTotalCount(res.data.data.countData);
    });
  };

  // table columns

  // tooltip status and shortlen status, shortlen show when both conditions are true
  const columns = [
    {
      header: (
        <p className={classes.headers}>
          ACKNOWLEDGEMENT Id
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "ackIdasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("ackId")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => <>{e.ackId}</>,
    },
    {
      header: (
        <p className={classes.headers}>
          CORPORATE NAME
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "corpNameasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("corpName")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.corpName,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 20 },
    },
    {
      header: (
        <p className={classes.headers}>
          DATE
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "submissionDateasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("submissionDate")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => Utils.convertToLocalDateOnly(e.submissionDate),
    },
    {
      header: (
        <p className={classes.headers}>
          TAT 1
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "tatasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("tat")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => (
        <p className={classes.tat_container}>
          <img
            src={
              e.tat === 0 || e.tat <= 5
                ? GreenDot
                : e.tat === 6 || e.tat <= 10
                ? OrangeDot
                : RedDot
            }
          />
          {e.tat}
        </p>
      ),
    },
    {
      header: (
        <p className={classes.headers}>
          TAT 2
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "tat2asc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("tat2")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => (
        <p className={classes.tat_container}>
          <img
            src={
              e.tat2 === 0 || e.tat2 <= 5
                ? GreenDot
                : e.tat2 === 6 || e.tat2 <= 10
                ? OrangeDot
                : RedDot
            }
          />
          {e.tat2}
        </p>
      ),
    },
    {
      header: <p className={classes.headers}>STATUS</p>,
      key: "corpStatus",
      accessor: (e) => (
        <p className={classes.state_container}>{e.corpStatus?.name}</p>
      ),
    },
    {
      header: (
        <p className={classes.headers}>
          PENDING WITH
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "currentAssignedUser.usernameasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("currentAssignedUser.username")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      key: "pendingWith",
      accessor: (e) => (
        <p className={classes.state_pending}>
          {e?.currentAssignedUser && e?.corpStatus?.name != "Onboarded"
            ? e?.currentAssignedUser.username
            : "NA"}
        </p>
      ),
    },
  ];

  // handling sort
  const handleSort = (key) => {
    // Toggle sort order
    const newSort = sort === "asc" ? "desc" : "asc";
    setSort(newSort);
    setAccessor(key);

    // Update rotation state for the current column
    if (sort === "asc") {
      setColumnRotations(key + "desc");
    } else {
      setColumnRotations(key + "asc");
    }
  };

  const analyticCard = [
    { icon: ClockIcon, title: t("text.pending"), count: totalCount.pendingall },
    {
      icon: CheckMarkIcon,
      title: t("text.complete"),
      count: totalCount.completedall,
    },
    {
      icon: TimelimitIcon,
      title: t("text.overdue"),
      count: totalCount.overdueall,
    },
    {
      icon: OverdueIcon,
      title: t("text.futherRequest"),
      count: totalCount.frall,
    },
  ];

  // tab  data array
  const tabsLabel = [
    {
      title: ` All (${
        totalCount.pending +
        totalCount.rejected +
        totalCount.accepted +
        totalCount.onboarded +
        totalCount.unclaimed
      })`,
      key: "",
    },
    {
      title: `${t("text.pending")} (${totalCount.pending})`,
      key: "pending",
    },
    {
      title: `${t("text.rejected")} (${totalCount.rejected})`,
      key: "rejected",
    },
    {
      title: `${t("text.accepted")} (${totalCount.accepted})`,
      key: "accepted",
    },
    {
      title: `${t("text.onboard")} (${totalCount.onboarded})`,
      key: "onboarded",
      pageType: "corporateDashboard",
      click: "onboarded",
    },
    {
      title: `${t("text.tatexceeded")} (${totalCount.tatexceeded})`,
      key: "tatexceeded",
    },
    {
      title: `${t("text.unclaimed")} (${totalCount.unclaimed})`,
      key: "unclaimed",
    },
  ];

  // redirect to new corp  form
  const handleNewRequest = () => {
    getCorporateByCHO(inputValue)
      .then((res) => {
        if (res.data.success === true) {
          dispatch(setSrUpdateFormData(res.data?.data));
          navigate(
            `/${BASE_URL}/${DASHBOARD_URL}/${ServiceRequest_Update_Url}`
          );
        }
      })
      .catch((err) => {
        setIsNewRequest(false);
        setInputValue("");
        dispatch(
          setAlertMsg({
            msg: err?.response?.data?.message,
            alertType: "danger",
          })
        );
      });
  };

  const handleOnclickNewReQuest = () => {
    setIsNewRequest(true);
  };

  //  table strings
  const tableStrings = {
    title: "Corporate Onboarding",
    listAll_title: "Corporate Registration",
    search_placeholder: "Search Name/Acknowledgement Id",
  };

  //  downloadzip csv
  const downloadCsv = () => {};

  const downloadFullCsv = () => {};

  return (
    <div>
      <TableDashboard
        newFormButtonLabel={t("New Service Request")}
        data={{ page, sort, accessor, perPage, filterBy, dateOne, dateTwo }}
        dateOne={dateOne}
        dateTwo={dateTwo}
        tableStrings={tableStrings}
        columns={columns}
        downloadCsv={downloadCsv}
        handleSort={handleSort}
        analyticCard={analyticCard}
        tabsLabel={tabsLabel}
        handleNewCorpForm={handleOnclickNewReQuest}
        pageData={pageData}
        tabValue={tabValue}
        setTabValue={setTabValue}
        filterBy={filterBy}
        setfilterBy={setfilterBy}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        handleSearch={handleSearch}
        setDateOne={setDateOne}
        setDateTwo={setDateTwo}
        perPage={perPage}
        setperPage={setperPage}
        page={page}
        tableData={tableData}
        accessor={accessor}
        setAccessor={setAccessor}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        downloadFullCsv={downloadFullCsv}
        setSubordinateId={setSubordinateId}
        srNO={true}
      />

      {isNewRequest && (
        <Modal>
          <div className={classes.sr_modal_container}>
            <h1 className={classes.modal_title}>New Service Request</h1>
            <p className={classes.modal_info}>
              Kindly select one of them new service request
            </p>
            <div className={classes.modal_select_container}>
              <label>
                <input
                  checked={srRaisedBy === "Corporate"}
                  onChange={() => setSRRaisedBy("Corporate")}
                  type="radio"
                />
                For Corporate
              </label>
              <label>
                <input
                  checked={srRaisedBy === "Subscriber"}
                  onChange={() => setSRRaisedBy("Subscriber")}
                  type="radio"
                />
                For Subscriber
              </label>
            </div>
            {srRaisedBy === "Corporate" ? (
              <div className={classes.modal_input_container}>
                <h5>Fill CHO/CBO Number</h5>
                <div>
                  <label>CHO/CBO Number</label>
                  <input
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder="Enter CHO/CBO Number"
                    type="text"
                  />
                  <p>Write number as per official document</p>
                </div>
              </div>
            ) : (
              <div className={classes.modal_input_container}>
                <h5>Fill PAN</h5>
                <div>
                  <label>PAN</label>
                  <input
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder="Enter PAN"
                    type="text"
                  />
                  <p>Write number as per official document</p>
                </div>
              </div>
            )}

            <div className={classes.btn_container}>
              <PrimaryButton
                onClick={handleNewRequest}
                label={t("button.next")}
                disabled={!inputValue}
              />
              <PrimaryButton
                onClick={() => setIsNewRequest(false)}
                label={t("button.cancel")}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default OPSServiceRequest;
