import React, { useEffect, useState } from "react";
import ObjPreViewForm from "../../../components/PreViewForm/ObjPreViewForm";
import NpsConfigObj from "../SubsciberPrevFormConfig.json";
import classes from "./SubscriberPrevForm.module.css";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import uploadIcon from "../../../assets/upload_doc_icon.svg";
import EditIcon from "../../../assets/penIcon.svg";
import PrintIcon from "../../../assets/printIcon.svg";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import Consts from "../../../Consts";
import UploadDocument from "../../../components/UploadDocument/UploadDocument";
import fileIcon from "../../../assets/file.svg";
import {
  getSubscriberById,
  uploadSubscriberDocument,
  saveorSubmitSubscriberForm
} from "../SubscriberAction";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../Home/HomeSlice";

const SubscriberPrevForm = ({ previewData }) => {
  const dispatch = useDispatch();
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [uploadDocument, setUploadDocument] = useState(previewData?.subDocData);
  const [currentDocId, setCurrentDocId] = useState("0");
  const [isEdit, setIsEdit] = useState(false);
  const [previewFormData, setPreviewFormData] = useState({
    ...previewData?.subFormData,
  });

  const handleDocUpload = (docId) => {
    setIsUploadModal(true);
    setCurrentDocId(docId);
  };

  const fileUpdateFunction = (e) => {
    let formData = new FormData();
    formData.append("file", e);
    uploadSubscriberDocument(
      previewData?.subData?.id,
      currentDocId,
      formData
    ).then((res) => {
      if (res.data.success === true) {
        subscriberDetails();
      }
    });
  };

  const subscriberDetails = () => {
    getSubscriberById(previewData?.subData?.id).then((res) => {
      if (res.data.success === true) {
        setUploadDocument(res.data.data?.subDocData);
      }
    });
  };

  useEffect(() => {
    subscriberDetails();
  }, []);

  const handleSubmit = () => {
    let formdata = {
      subFormData: previewFormData,
    };
    saveorSubmitSubscriberForm(previewData?.subData?.id, formdata, "submit")
      .then((res) => {
        if (res?.data?.success === true) {
          dispatch(
            setAlertMsg({
              msg: "Form submit successfully",
              alertType: "success",
            })
          );
        } else {
          dispatch(
            setAlertMsg({
              msg: res?.response?.data?.message,
              alertType: "danger",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: err?.response?.data?.message,
            alertType: "danger",
          })
        );
      });
  };

  return (
    <div className={classes.previewForm_container}>
      <div className={classes.form_header_container}>
        <GoBackButton />
        <div className={classes.form_headings}>
          <h4>New NPS Registration</h4>
          <h6>Your Acknowledgement ID : {previewData?.subData?.ackId}</h6>
        </div>
      </div>
      <div className={classes.form_container}>
        <div className={classes.form_acton_container}>
          <h4>Form Review</h4>
          <div className={classes.form_action_btn}>
            {!isEdit ? (
              <label onClick={() => setIsEdit(true)}>
                <img src={EditIcon} />
                Edit
              </label>
            ) : (
              <label onClick={() => setIsEdit(false)}>
                <img src={EditIcon} />
                Save
              </label>
            )}
            <label>
              <img src={PrintIcon} />
              Print This
            </label>
          </div>
        </div>
        <div className={classes.form}>
          <ObjPreViewForm
            ConfigObj={NpsConfigObj}
            previewData={previewData?.subFormData}
            isEdit={isEdit}
            previewFormData={previewFormData}
            setPreviewFormData={setPreviewFormData}
          />
        </div>
        {uploadDocument.map((doc, index) => {
          return (
            <div key={index} className={classes.upload_document}>
              <label
                onClick={() => handleDocUpload(doc?.processDocumentType?.id)}
              >
                <img src={uploadIcon} />
                {doc?.isSaved ? `Reupload  ` : `Upload `}
                {doc?.processDocumentType?.uiString}
              </label>
              {doc?.isSaved && (
                <div className={classes.uploaded_file}>
                  <img src={fileIcon} />
                  {doc?.documentName}
                </div>
              )}
            </div>
          );
        })}
        <div className={classes.previewform_btn}>
          <PrimaryButton onClick={handleSubmit} label={"Submit"} />
          <PrimaryButton label={"Download"} />
          <SecondaryButton label={"Cancel"} />
        </div>
      </div>
      {isUploadModal && (
        <UploadDocument
          isModalOpen={isUploadModal}
          setIsModalOpen={setIsUploadModal}
          acceptedFileType={Consts.ACCEPTED_FILE_TYPE_ARR}
          uploadedDocuments={fileUpdateFunction}
        />
      )}
    </div>
  );
};

export default SubscriberPrevForm;
