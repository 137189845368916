import React, { useState, useEffect } from "react";
import classes from "./MainSidebar.module.css";
import { useNavigate } from "react-router-dom/dist";
import { BASE_URL } from "../../Routes/Urls";
import DashboardIcon from "../../assets/SidebarAssets/Dashboard-active.svg";
import Dashboard_InActiveIcon from "../../assets/SidebarAssets/Dashboard-inactive.svg";
import AnalyticsIcon from "../../assets/SidebarAssets/Analytics-active.svg";
import Analytics_InActiveIcon from "../../assets/SidebarAssets/Analytics-inactive.svg";
import ReportIcon from "../../assets/SidebarAssets/Report-active.svg";
import Report_InActiveIcon from "../../assets/SidebarAssets/Report-inactive.svg";
import AdminManagementIcon from "../../assets/SidebarAssets/Management-active.svg";
import AdminManagement_InActiveIcon from "../../assets/SidebarAssets/Management-inactive.svg";
import Utils from "../../Utils";
import { useSelector } from "react-redux";

const MainSidebar = () => {
  const navigate = useNavigate();
  const { loginUserDetails } = useSelector((state) => state);
  const [activeTab, setActiveTab] = useState("dashboard");

  useEffect(() => {
    setActiveTab(window.location.href.split("/")[4]);
  }, [window.location.href]);

  useEffect(() => {
    // Preload the active icon images
    const iconsToPreload = [
      DashboardIcon,
      AnalyticsIcon,
      ReportIcon,
      AdminManagementIcon,
    ];
    const preloadedIcons = [];

    iconsToPreload.forEach((icon) => {
      const img = new Image();
      img.src = icon;
      preloadedIcons.push(img);
    });
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    navigate(`/${BASE_URL}/${tab}`);
  };

  return (
    <div className={classes.sidebar_container}>
      <div className={classes.sidebar_iconlist}>
        <span
          className={`${
            activeTab === "dashboard" ? classes.active : classes.sidebar_icons
          }`}
          onClick={() => handleTabClick("dashboard")}
        >
          {activeTab === "dashboard" ? (
            <img src={DashboardIcon} alt="Dashboard" />
          ) : (
            <img src={Dashboard_InActiveIcon} alt="Dashboard" />
          )}
          Dashboard
        </span>

        {/* report section */}
        {Utils.isAdmin(loginUserDetails) && (
          <span
            className={`${
              activeTab === "misreport" ? classes.active : classes.sidebar_icons
            }`}
            onClick={() => handleTabClick("misreport")}
          >
            {activeTab === "misreport" ? (
              <img src={ReportIcon} alt="Reports" />
            ) : (
              <img src={Report_InActiveIcon} alt="Reports" />
            )}
            Reports
          </span>
        )}

        {/*  Analytics */}
        {/* <span
          className={`${
            activeTab === "tasks" ? classes.active : classes.sidebar_icons
          }`}
          onClick={() => handleTabClick("tasks")}
        >
          {activeTab === "tasks" ? (
            <img src={AnalyticsIcon} alt="Analytics" />
          ) : (
            <img src={Analytics_InActiveIcon} alt="Analytics" />
          )}
          Analytics
        </span> */}

        {/* management */}
        {Utils.isAdmin(loginUserDetails) && (
          <span
            className={`${
              activeTab === "admin" ? classes.active : classes.sidebar_icons
            }`}
            onClick={() => handleTabClick("admin")}
          >
            {activeTab === "admin" ? (
              <img src={AdminManagementIcon} alt="Management" />
            ) : (
              <img src={AdminManagement_InActiveIcon} alt="Management" />
            )}
            Management
          </span>
        )}
      </div>
    </div>
  );
};

export default MainSidebar;
