import React from "react";
import classes from "./Card.module.css";

function Card(props) {
  return (
    <>
      <div
        onClick={props.onClick}
        className={classes.card}
        style={{ width: props.width, height: props.height }}
      >
        <img src={props.img} className={classes.card_img} />
        <p> {props.label}</p>
      </div>
    </>
  );
}

export default Card;
