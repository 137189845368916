import React, { useState, useEffect, useRef } from "react";
import Classes from "./CorporateUploadDocuments.module.css";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import { useTranslation } from "react-i18next";
import {
  getCorporateDocuments,
  corpDocumentUpload,
  corpDocumentSubmit,
  getCorporateDetails,
} from "../CorporateAction";
import ReportIcon from "../../../assets/SidebarAssets/Report-active.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  BASE_URL,
  DASHBOARD_URL,
  CORPORATEDASHBOARD_URL,
} from "../../../Routes/Urls";
import Consts from "../../../Consts";
import { setAlertMsg } from "../../Home/HomeSlice";
import UploadDocument from "../../../components/UploadDocument/UploadDocument";
import Utils from "../../../Utils";
import CustomTable from "../../../components/CustomTable/CustomTable";
import ShowReasonsModal from "../../../components/ShowReasonsModal/ShowReasonsModal";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
var other = "OTHERS";
const CorporateUploadDocuments = ({ getAckId }) => {
  const userDetails = useSelector((state) => state.loginUserDetails);
  const corpId = JSON.parse(localStorage.getItem("corpId"));
  const dispatch = useDispatch();
  const [isUploadModalActive, setIsUploadModalActive] = useState(false);
  const [isActiveId, setIsActiveId] = useState(0);
  const [checkSubmit, setCheckSubmit] = useState(true);
  const [allDoc, setAllDoc] = useState({});
  const [processDocumentTypeId, setProcessDocumentTypeId] = useState(0);
  const [deletedFilesArr, setDeletedFilesArr] = useState([]);
  const [modalObj, setModalObj] = useState({
    heading: "Upload Document",
  });
  const [documents, setDocuments] = useState([]);
  const [mandatoryDocs, setMandatoryDocs] = useState([]);
  const [nonMandatoryDocs, setNonMandatoryDocs] = useState([]);
  const [docType, setDocType] = useState(1);
  const [backendUploadedArr, setBackendUploadedArr] = useState([]);
  const [backendDataArr, setBackendDataArr] = useState([]);
  const [rejectedReasonArr, setRejectedReasonArr] = useState([]);
  const [rejectedModalISActive, setRejectedModalISActive] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // geting all documents by backend
  useEffect(() => {
    checkAuth();
    getCorporateDocumentsFunction();
  }, []);

  const checkAuth = () => {
    getCorporateDetails(corpId)?.then((res) => {});
  };

  const getCorporateDocumentsFunction = () => {
    getCorporateDocuments(corpId)
      .then((res) => {
        var uploadedDocumentsData = res.data.data.orionDocuments;

        // Check if uploadedDocumentsData is an array
        if (!Array.isArray(uploadedDocumentsData)) {
          return;
        }

        var serverOrionDocument = uploadedDocumentsData.filter(
          (e) => e.isApproved != true
        );
        getAckId({ ackId: res.data.data.corpData.ackId });
        mergeServerDataWithUI(serverOrionDocument);
        let copiedArr = [...serverOrionDocument];
        setBackendDataArr(copiedArr);
        setBackendUploadedArr(copiedArr.filter((e) => e.documentName != null));
        setMandatoryDocs(
          serverOrionDocument?.filter(
            (e) => e?.processDocumentType?.isMandatory === true
          )
        );
        setNonMandatoryDocs(
          serverOrionDocument?.filter(
            (e) => e?.processDocumentType?.isMandatory === false
          )
        );
        IsMandatoryCheckedFunction(serverOrionDocument);
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: err.response.data?.message,
            alertType: "danger",
          })
        );
      });
  };
  const mergeServerDataWithUI = (orionDocument) => {
    let n = orionDocument.length;

    // Validate that n is a non-negative integer
    if (!Number.isInteger(n) || n < 0) {
      return;
    }

    for (var i = 0; i < n; i++) {
      var datas = orionDocument[i];
      if (
        datas.isApproved === true ||
        (datas.isSaved == true && datas.isApproved != true)
      ) {
        datas.status = 2;
        datas.isChecked = true;
      } else if (datas.isApproved != true && datas.documentName != null) {
        datas.status = 4;
        datas.isChecked = false;
      } else if (datas.isSaved !== true && datas.documentName === null) {
        datas.status = 0;
      } else {
        // just for saved doc
        datas.status = 2;
        datas.isChecked = true;
      }
    }
  };

  // create objects
  const handleFileChange = (id, files) => {
    let id_action = `${id}`;

    let checkExist = deletedFilesArr.includes(id_action);
    if (checkExist) {
      let indexs = deletedFilesArr.findIndex((e) => e == id_action);
      deletedFilesArr.splice(indexs, 1);
    }

    const file = files;
    setAllDoc((data) => ({
      ...data,
      [id_action]: file,
    }));
  };
  const deleteNonExistFile = (id) => {
    id = Number(id);
    let tempObjDoc = { ...allDoc };
    let tempDocuments = [...mandatoryDocs, ...nonMandatoryDocs];
    let checkExist = tempObjDoc.hasOwnProperty(`${id}`);
    if (checkExist) {
      delete tempObjDoc[id];
    }
    let docId = tempDocuments?.findIndex(
      (e) => e?.processDocumentType?.id === id
    );
    reselectFileFunction(id, docId, docType);
    setAllDoc(tempObjDoc);
  };

  //  check if all uploaded exist or not in local system
  const checkFilesExist = async (files) => {
    const fileExistPromises = Object.entries(files).map(async ([id, file]) => {
      if (!file) {
        deleteNonExistFile(id);
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.file_not_found"),
            alertType: "danger",
          })
        );

        return false;
      }

      return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          resolve(true);
        };

        reader.onerror = () => {
          deleteNonExistFile(id);
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.file_not_found"),
              alertType: "danger",
            })
          );

          resolve(false);
        };
        reader.readAsDataURL(file);
      });
    });

    const results = await Promise.all(fileExistPromises);
    return results.every((result) => result);
  };

  // handle uploaded data on save
  const handleUploadDoc = async () => {
    var deletedfilesIndex = deletedFilesArr.toString();
    var deletedFilesString = `delete=${deletedfilesIndex}`;

    var filesExist = await checkFilesExist(allDoc);
    if (!filesExist) {
      return;
    }
    const formData = new FormData();
    Object.entries(allDoc).forEach(([key, file]) => {
      formData.append(key, file);
    });
    corpDocumentUpload(`${corpId}`, formData, deletedFilesString)
      .then((res) => {
        if (res.data.success === true) {
          getCorporateDocumentsFunction();
          partiallySaved();
          setNonMandatoryDocs((e) => [...e]);
          setMandatoryDocs((e) => [...e]);

          setAllDoc({});
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.saved_successfully"),
              alertType: "success",
            })
          );
        } else {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.internal_server_error"),
              alertType: "danger",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: err?.response?.data?.message,
            alertType: "danger",
          })
        );
      });
  };

  const submitUploadDoc = async () => {
    const formData = new FormData();

    var filesExist = await checkFilesExist(allDoc);
    if (!filesExist) {
      return;
    }

    Object.entries(allDoc).forEach(([key, file]) => {
      formData.append(key, file);
    });
    corpDocumentSubmit(`${corpId}`, formData, deletedFilesArr)
      .then((res) => {
        if (res.data?.success === true) {
          dispatch(
            setAlertMsg({
              msg: "Documents are submitted for scrutiny",
              alertType: "success",
            })
          );
          navigate(`/${BASE_URL}/${DASHBOARD_URL}/${CORPORATEDASHBOARD_URL}`);
        } else {
          dispatch(
            setAlertMsg({
              msg: res?.response?.data?.message,
              alertType: "danger",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: err?.response?.data?.message,
            alertType: "danger",
          })
        );
      });
  };

  const IsMandatoryCheckedFunction = (doc) => {
    let checkMandatory = doc.every(
      (e) =>
        (e.processDocumentType?.isMandatory == true &&
          e?.isChecked == true &&
          e.status <= 2) ||
        e.processDocumentType?.isMandatory == false
      // (e.processDocumentType?.isMandatory == false && e.status <= 2)
    );

    setCheckSubmit(!checkMandatory);
  };

  // add files while uploading
  function fileUpdateFunction(currFile) {
    let newArr = [];
    if (currFile != undefined) {
      if (docType === 1) {
        newArr = JSON.parse(JSON.stringify(mandatoryDocs));
      } else {
        newArr = JSON.parse(JSON.stringify(nonMandatoryDocs));
      }

      let mergedArr = [...mandatoryDocs, ...nonMandatoryDocs];
      if (mergedArr.some((e) => e.documentName === currFile.name)) {
        dispatch(
          setAlertMsg({
            msg: t("Duplicate file not allowed"),
            alertType: "danger",
          })
        );
        return false;
      } else if (
        newArr[isActiveId]?.processDocumentType?.documentSize < currFile.size
      ) {
        dispatch(
          setAlertMsg({
            msg: t("File size limit exceeded for " + currFile.name),
            alertType: "danger",
          })
        );
        return false;
      }

      newArr[isActiveId].documentName = `${currFile?.name}`;
      newArr[isActiveId].status = 1;
      if (newArr[isActiveId]?.processDocumentType?.isMandatory) {
        newArr[isActiveId].isChecked = true;
      } else {
        newArr[isActiveId].isChecked = false;
      }
      handleFileChange(processDocumentTypeId, currFile);

      if (docType === 1) {
        setMandatoryDocs(newArr);
        IsMandatoryCheckedFunction(newArr);
      } else {
        setNonMandatoryDocs(newArr);
      }
    } else {
      dispatch(
        setAlertMsg({
          msg: t("alertMsg.upload_fail"),
          alertType: "danger",
        })
      );
    }
  }

  // remove file while uploading
  const removeUploadedFile = (id, index, docType) => {
    let newArr = docType === 1 ? [...mandatoryDocs] : [...nonMandatoryDocs];
    newArr[index].documentName = null;
    newArr[index].status = 3;
    newArr[index].isChecked = false;

    if (docType === 1) {
      setMandatoryDocs(newArr);
      IsMandatoryCheckedFunction(mandatoryDocs);
    } else {
      setNonMandatoryDocs(newArr);
    }

    let deleteAction = `${id}`;
    let checkExist = allDoc.hasOwnProperty(`${deleteAction}`);
    if (checkExist) {
      delete allDoc[deleteAction];
    }
    setDeletedFilesArr([...deletedFilesArr, deleteAction]);
  };

  // partially saved files
  const updateStatus = (docsArray) => {
    return docsArray.map((doc) => ({
      ...doc,
      status: doc.status === 1 ? 2 : doc.status,
    }));
  };

  const partiallySaved = () => {
    setMandatoryDocs(updateStatus([...mandatoryDocs]));
    setNonMandatoryDocs(updateStatus([...nonMandatoryDocs]));
  };

  // cancel button function
  const removeDocumentsFunction = () => {
    navigate(`${BASE_URL}/${DASHBOARD_URL}/${CORPORATEDASHBOARD_URL}`);
  };

  // reslect function
  const reselectFileFunction = (id, currIndex, docType) => {
    let newArr1 = docType === 1 ? [...mandatoryDocs] : [...nonMandatoryDocs];
    let hasActiveValue = backendUploadedArr.some((item) => item.id === id);

    if (hasActiveValue) {
      let getIndex = backendUploadedArr.findIndex((item) => item.id === id);

      newArr1[currIndex].documentName =
        backendUploadedArr[getIndex].documentName;
      if (
        backendUploadedArr[getIndex].isApproved ||
        backendUploadedArr[getIndex].isSaved
      ) {
        newArr1[currIndex].status = 2;
        newArr1[currIndex].isChecked = true;
      } else {
        newArr1[currIndex].status = 4;
        newArr1[currIndex].isChecked = false;
      }
    } else {
      newArr1[currIndex].documentName = null;
      newArr1[currIndex].status = 0;
      newArr1[currIndex].isChecked = false;
    }
    // setDocuments(newArr1);
    docType === 1 ? setMandatoryDocs(newArr1) : setNonMandatoryDocs(newArr1);
    IsMandatoryCheckedFunction(newArr1);
    let deleteAction = `${id}`;
    let checkExist = allDoc.hasOwnProperty(`${deleteAction}`);
    if (checkExist) {
      delete allDoc[deleteAction];
    }
  };

  //  modal of show rejected reasons of document
  const showRejectedListModal = (listofRemark) => {
    var remarkArray = listofRemark.split("|");
    setRejectedReasonArr(remarkArray);
    setRejectedModalISActive(true);
  };

  const documentStatusFunction = (e) => {
    let docStatus = {
      docStatustext: "",
      docStatusClass: "",
    };
    if (e.status === 2) {
      // return 2;
      docStatus.docStatustext = "Uploaded";
      docStatus.docStatusClass = Classes.bg_lights_green;
    } else if (e.status === 4) {
      // return 4;
      docStatus.docStatustext = "Rejected";
      docStatus.docStatusClass = Classes.bg_lights_red;
    } else if (e.status === 0) {
      // return 0;
      docStatus.docStatustext = "Not Uploaded ";
      docStatus.docStatusClass = Classes.bg_lights;
    } else if (e.status === 1) {
      // return 0;
      docStatus.docStatustext = "Marked for  Upload ";
      docStatus.docStatusClass = Classes.bg_lights_blue;
    } else if (e.status === 3) {
      // return 0;
      docStatus.docStatustext = "Marked for  Remove ";
      docStatus.docStatusClass = Classes.bg_lights_red;
    } else {
      // return 2;
      docStatus.docStatustext = "Uploaded";
      docStatus.docStatusClass = Classes.bg_lights_green;
    }
    return (
      <span className={`badge ${Classes.upload} ${docStatus.docStatusClass}`}>
        {docStatus.docStatustext}
      </span>
    );
  };

  const showDocumentRemoveFunction = (e, index, param) => {
    if (e.status >= 2 && e.documentName != null) {
      return (
        <span
          className={Classes.cross_icon}
          onClick={() => {
            setDocType(param);
            removeUploadedFile(e.processDocumentType.id, index, param);
          }}
        >
          x
        </span>
      );
    }
  };

  const showReasonButtonsFunction = (e, index, param) => {
    if (e.status && e.status == 4) {
      return (
        <div
          className={Classes.action + " " + "mr-2"}
          onClick={() => {
            setDocType(param);
            showRejectedListModal(e.remark);
            // setIsActiveDocumentName(e.processDocumentType.uiString);
            setProcessDocumentTypeId(e.processDocumentType.id);
            setIsActiveId(index);
          }}
        >
          Show Reason
        </div>
      );
    }
  };

  const selectReselectButtonFunction = (e, index, param) => {
    if (e.status >= 2) {
      return (
        <div
          className={
            e.isApproved == true && e.isChecked == true && e.status == 2
              ? `${Classes.action} ${Classes.disabledDiv}`
              : Classes.action
          }
          onClick={() => {
            setDocType(param);
            setIsActiveId(index);
            setModalObj((data) => ({
              ...data,
              documentName: e.processDocumentType.uiString,
            }));

            setProcessDocumentTypeId(e.processDocumentType.id);
            setIsUploadModalActive(true);
          }}
        >
          Select File
        </div>
      );
    } else if (e.status === 0) {
      return (
        <div
          className={Classes.action}
          onClick={() => {
            setDocType(param);
            setIsActiveId(index);
            setProcessDocumentTypeId(e.processDocumentType.id);
            setIsUploadModalActive(true);

            setModalObj((data) => ({
              ...data,
              documentName: e.processDocumentType.uiString,
            }));
            // setModalObj({});
            // setIsActiveDocumentName(item);
          }}
        >
          Select File
        </div>
      );
    } else {
      return (
        <div
          className={Classes.action}
          onClick={() => {
            setDocType(param);
            reselectFileFunction(e.id, index, param);
          }}
        >
          Reselect File
        </div>
      );
    }
  };
  const CorporateUploadTableColumns = (param) => [
    {
      widthStyle: "40%",
      header: "List of Documents",
      accessor: (item, index) => (
        <>
          <div className={Classes.document_list + " " + "text-left"}>
            {item?.processDocumentType?.orionDocumentType?.apiName?.startsWith(
              other
            )
              ? "Others - "
              : null}
            {item?.processDocumentType?.uiString}
            <span className={Classes.text_danger_mandatory}>
              {" "}
              {item?.processDocumentType?.isMandatory ? "*" : ""}
            </span>
          </div>
          <div className={Classes.size_column}>
            Size - {Utils.formatSize(item?.processDocumentType?.documentSize)}
          </div>
        </>
      ),
    },
    {
      header: "Name of the File",
      accessor: (item, index) => (
        <p title={item?.documentName}>
          {" "}
          <span className="mr-2">
            <img src={ReportIcon} alt="Report Icon" />
          </span>
          {item?.documentName != null
            ? item?.documentName?.length > 16
              ? item?.documentName?.substring(0, 10) + "..."
              : // item.fileType
                item?.documentName
            : "NA"}
          {showDocumentRemoveFunction(item, index, param)}
        </p>
      ),
    },
    {
      header: "Status",
      accessor: (item) => documentStatusFunction(item),
    },
    {
      header: "Action",
      key: "corpAction",
      accessor: (item, index) => (
        <div className="d-flex align-items-center justify-content-center">
          {showReasonButtonsFunction(item, index, param)}
          {selectReselectButtonFunction(item, index, param)}
        </div>
      ),
    },
  ];

  const CorporateUploadTableColumns1 = CorporateUploadTableColumns(1);
  const CorporateUploadTableColumns2 = CorporateUploadTableColumns(2);

  return (
    <>
      <section className=" h-100">
        <div className=" container-fluid py-5">
          <div className="row">
            <div className="col-lg-12">
              <div className={Classes.documents_heading}>
                <h3>Upload Documents</h3>
              </div>
              <div className={Classes.table_caption}>
                <span>
                  <i className={Classes.text_danger_mandatory}>*</i>{" "}
                  {/* Asterisk means Mandatory Document | size limit: 3 MB */}
                  Mandatory Document
                </span>
                {/* <span>Non Mandatory Document | size limit: 500 KB</span> */}
              </div>
              <CustomTable
                headings={CorporateUploadTableColumns1}
                data={mandatoryDocs}
                srNO={true}
              />

              <Accordion>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  style={{ background: "#ededed" }}
                >
                  <div className={`${Classes.documents_heading} mb-0`}>
                    <h3>Optional Documents</h3>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <CustomTable
                    headings={CorporateUploadTableColumns2}
                    data={nonMandatoryDocs}
                    srNO={true}
                  />
                </AccordionDetails>
              </Accordion>
            </div>

            <div className={"col-lg-12 mt-3 " + " " + Classes.btn_media}>
              <div
                className={Classes.btn_group + " " + "btn-group ml-auto mr-3"}
              >
                <SecondaryButton
                  label={t("Cancel")}
                  onClick={(e) => {
                    removeDocumentsFunction();
                  }}
                />
              </div>
              <div className={Classes.btn_group + " " + "btn-group mr-3"}>
                <SecondaryButton
                  label={t("Save")}
                  onClick={(e) => {
                    handleUploadDoc();
                  }}
                />
              </div>
              <div className={Classes.btn_group + " " + "btn-group mr-3"}>
                <PrimaryButton
                  disabled={checkSubmit}
                  label={t("Submit for Scrutiny")}
                  onClick={() => {
                    submitUploadDoc();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <UploadDocument
        isModalOpen={isUploadModalActive}
        setIsModalOpen={setIsUploadModalActive}
        documents={modalObj}
        acceptedFileType={Consts.ACCEPTED_FILE_TYPE_ARR}
        uploadedDocuments={fileUpdateFunction}
      />

      <ShowReasonsModal
        isModalActive={rejectedModalISActive}
        setIsModalActive={setRejectedModalISActive}
        activeDocumentString={
          documents[isActiveId]?.processDocumentType?.uiString
        }
        setModalObj={setModalObj}
        reasonsArr={rejectedReasonArr}
        setUploadModalActive={setIsUploadModalActive}
      />
    </>
  );
};

export default CorporateUploadDocuments;
