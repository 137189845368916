import React, { useState, useEffect } from "react";
import Modal from "../../../../../components/Modal/Modal";
import classes from "./ReviewByModal.module.css";
import { useTranslation } from "react-i18next";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";

import SimpleObjForm from "../../../../../components/SimpleObjForm/SimpleObjForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmailtemplate,
  getHolidayMasters,
  getPFMMasters,
  submitMakerChecker,
  getProcessDocumentManagement,
  getPOPSP,
  getCharge,
  getCraById,
} from "../../../AdminManagementActions";
import Consts from "../../../../../Consts";
import Utils from "../../../../../Utils";

import crossIconSVG from "../../../../../assets/cross-x-icon.svg";
import rejectfileIcon from "../../../../../assets/reject_document.svg";

import { useNavigate } from "react-router";
import { setAlertMsg } from "../../../../Home/HomeSlice";

const ReviewsByModal = ({
  isReviewModalOpen,
  SetIsReviewModalOpen,
  reviewTypeId,
  setIsReviewRejectModalOpen,
  isReviewRejectModalOpen,
  refreshTableFunction,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { checkerid, rowID } = useSelector((state) => state);
  const [data, setData] = useState({});
  const [checkerData, setCheckerData] = useState({});

  const [customRejReason, setCustomRejReason] = useState(false);
  const [selectedReason, setSelectedReason] = useState([]);
  const [rejCheckButton, setRejCheckButton] = useState(true);
  const rejectReasons = [
    "Spelling",
    "Not a Correct Document",
    "Spelling Mistake",
    "Invalid Id",
  ];

  const [proceedData, setProceedData] = useState({
    isApproved: "",
    reason: "",
  });
  const formObject = () => {
    if (reviewTypeId === Consts.REVIEW_MAKER_CHECKER_IDS.EMAIL_TEMP.id) {
      return {
        header: "Email Template",
        section: [
          {
            title: "Email Template",
            Fields: [
              {
                label: "Name ",
                placeholder: "Ex. Genpact...",
                is_mandatory: true,
                type: "text",
                tag: "input",
                accessor: "name",
                autopopulate: true,
              },
              {
                label: "Subject",
                placeholder: "Ex. Genpact...",
                is_mandatory: true,
                type: "text",
                tag: "input",
                accessor: "subject",
                autopopulate: true,
              },
              {
                label: "Body",
                placeholder: "Ex. Genpact...",
                is_mandatory: true,
                type: "text",
                tag: "textarea",
                accessor: "body",
                autopopulate: true,
              },
            ],
          },
        ],
      };
    } else if (reviewTypeId === Consts.REVIEW_MAKER_CHECKER_IDS.HOLIDAY.id) {
      return {
        header: "Holiday Master",
        section: [
          {
            title: "Holiday Master",
            Fields: [
              {
                label: "Name of the Holiday",
                placeholder: "Ex. Genpact...",
                is_mandatory: true,
                type: "text",
                tag: "input",
                accessor: "name",
                autopopulate: true,
              },
              {
                label: "Date of the Holiday",
                placeholder: "01-01-2023",
                is_mandatory: true,
                type: "date",
                tag: "input",
                accessor: "date",
                autopopulate: true,
              },
            ],
          },
        ],
      };
    } else if (reviewTypeId === Consts.REVIEW_MAKER_CHECKER_IDS.PFMS.id) {
      return {
        header: "PFMS Master",
        section: [
          {
            title: "PFMS Master",
            Fields: [
              {
                label: "Name ",
                placeholder: "Ex. Genpact...",
                is_mandatory: true,
                type: "text",
                tag: "input",
                accessor: "name",
                autopopulate: true,
              },
            ],
          },
        ],
      };
    } else if (
      reviewTypeId === Consts.REVIEW_MAKER_CHECKER_IDS.DOCUMENT_TYPE.id
    ) {
      return {
        header: "Document Type",
        section: [
          {
            title: "Document Type",
            Fields: [
              {
                label: "Name ",
                placeholder: "Ex. Genpact...",
                is_mandatory: false,
                type: "text",
                tag: "input",
                accessor: "uiString",
                autopopulate: true,
              },
              {
                label: "Document Mandatory Status ",
                placeholder: "Ex. Genpact...",
                is_mandatory: false,
                type: "text",
                tag: "dropdown",
                accessor: "isMandatory",
                autopopulate: true,
                options: ["true", "false"],
              },
              {
                label: "Document Size ",
                placeholder: "",
                is_mandatory: false,
                type: "number",
                tag: "input",
                accessor: "documentSize",
                autopopulate: true,
              },
            ],
          },
        ],
      };
    } else if (reviewTypeId === Consts.REVIEW_MAKER_CHECKER_IDS.POP_SP.id) {
      return {
        header: "POP SP Master",
        section: [
          {
            title: "POP SP Master",
            Fields: [
              {
                label: "CRA Name ",
                placeholder: "Ex. Genpact...",
                is_mandatory: true,
                type: "text",
                tag: "input",
                accessor: "craName",
                autopopulate: true,
              },
              {
                label: "POP SP Office ",
                placeholder: "Ex. Genpact...",
                is_mandatory: true,
                type: "text",
                tag: "input",
                accessor: "popSpOffice",
                autopopulate: true,
              },
              {
                label: "POP SP Reg No",
                placeholder: "Ex. Genpact...",
                is_mandatory: true,
                type: "text",
                tag: "input",
                accessor: "popSpRegNo",
                autopopulate: true,
              },
            ],
          },
        ],
      };
    } else if (reviewTypeId === Consts.REVIEW_MAKER_CHECKER_IDS.Charge.id) {
      return {
        header: "Charge Master",
        section: [
          {
            title: "Charge Master",
            Fields: [
              {
                label: "Name ",
                placeholder: "Ex. Genpact...",
                is_mandatory: true,
                type: "text",
                tag: "input",
                accessor: "name",
                autopopulate: true,
              },
              {
                label: "Value ",
                placeholder: "Ex 10-99.",
                is_mandatory: true,
                type: "text",
                tag: "input",
                accessor: "value",
                autopopulate: true,
              },
            ],
          },
        ],
      };
    }
  };

  useEffect(() => {
    if (reviewTypeId === Consts.REVIEW_MAKER_CHECKER_IDS.EMAIL_TEMP.id) {
      getEmailFunction();
    } else if (reviewTypeId === Consts.REVIEW_MAKER_CHECKER_IDS.HOLIDAY.id) {
      getHolidayFunction();
    } else if (reviewTypeId === Consts.REVIEW_MAKER_CHECKER_IDS.PFMS.id) {
      getPFMSFunction();
    } else if (
      reviewTypeId === Consts.REVIEW_MAKER_CHECKER_IDS.DOCUMENT_TYPE.id
    ) {
      getDocumentTypeFunction();
    } else if (reviewTypeId === Consts.REVIEW_MAKER_CHECKER_IDS.POP_SP.id) {
      getPOP_SPFunction();
    } else if (reviewTypeId === Consts.REVIEW_MAKER_CHECKER_IDS.Charge.id) {
      getChargeFunction();
    }
  }, []);

  const getEmailFunction = () => {
    getEmailtemplate(rowID).then((res) => {
      setData(res.data.data.masterData);
      setCheckerData(res.data.data.checkerData);
    });
  };
  const getPFMSFunction = () => {
    getPFMMasters(rowID).then((res) => {
      setData(res.data.data.masterData);
      setCheckerData(res.data.data.checkerData);
    });
  };
  const getHolidayFunction = () => {
    getHolidayMasters(rowID).then((res) => {
      let e = res.data.data;
      e.masterData.date = Utils.convertDateToYMD(e.masterData.date);
      setData(e.masterData);

      setCheckerData({
        name: e.checkerData?.name,
        date: Utils.convertDateToYMD(e.checkerData.date),
      });
    });
  };
  const getDocumentTypeFunction = () => {
    getProcessDocumentManagement(rowID).then((res) => {
      setData(res.data.data.masterData);
      setCheckerData(res.data.data.checkerData);
    });
  };

  // get pop sp function
  const getPOP_SPFunction = () => {
    getPOPSP(rowID).then(async (res) => {
      let e = res.data.data;
      e.masterData.craName = e.masterData?.cra?.name;
      if (e?.checkerData?.popSpCraId != undefined) {
        let craNAme = await getCraNameByIdFunction(e.checkerData?.popSpCraId);
        e.checkerData.craName = craNAme?.name;
      }
      setData(e.masterData);
      setCheckerData(e.checkerData);
    });
  };

  // get cra name by id
  const getCraNameByIdFunction = async (id) => {
    try {
      const res = await getCraById(id);
      const obj = {
        name: res.data?.data?.name,
      };
      return obj;
    } catch (err) {
      return { name: null };
    }
  };

  // get charge function by id
  const getChargeFunction = () => {
    getCharge(rowID).then((res) => {
      setData(res.data.data.masterData);
      setCheckerData(res.data.data.checkerData);
    });
  };
  // close reject modal
  const closeRejectModal = () => {
    SetIsReviewModalOpen(true);
    setIsReviewRejectModalOpen(false);
  };

  const selectedReasonFunction = (e) => {
    if (e != "others") {
      if (!selectedReason.includes(e)) {
        setSelectedReason([...selectedReason, e]);
        setRejCheckButton(false);
      }
    } else {
      setCustomRejReason(true);
    }
    if (selectedReason.length > 0) {
      setRejCheckButton(false);
    }
  };
  //   remove selected reasons
  const removeselectedReasonFunction = (item) => {
    setSelectedReason((old) => {
      return old.filter((e) => e !== item);
    });
  };

  // check rejCheckButtonStatus
  const rejCheckButtonStatus = () => {
    if (selectedReason.length < 2) {
      setRejCheckButton(true);
    } else {
      setRejCheckButton(false);
    }
  };

  // rejected add reason function
  const rejectedReasonFormsFunction = (id) => {
    if (customRejReason) {
      let textareaData = document.getElementById("customreason").value;
      if (textareaData != null && textareaData.length > 0) {
        selectedReason.push(textareaData);
      }
    }
  };

  const proceedDataFunction = (id, approval) => {
    if (approval == true) {
      proceedData.isApproved = approval;
    } else {
      let allreason = selectedReason.join("|");
      proceedData.isApproved = approval;
      proceedData.reason = allreason;
    }
  };

  const submitHandle = (id, isAccepted) => {
    submitMakerChecker(id, proceedData)
      .then((res) => {
        if (res.data.success == true) {
          dispatch(
            setAlertMsg({
              msg: isAccepted
                ? `Approved  Successfully`
                : "Rejected Successfully",
              alertType: "success",
            })
          );
          const simulatedEvent = { preventDefault: () => {} };
          refreshTableFunction(simulatedEvent);
          SetIsReviewModalOpen(false);
          setIsReviewRejectModalOpen(false);
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: isAccepted ? t("alertMsg.update_failed") : "Failed to Reject",
            alertType: "danger",
          })
        );
      });
  };

  var closeBtnStyle = {
    backgroundColor: "rgb(255, 255, 255)",
    width: "2.5rem",
    height: "2.5rem",
    display: "flex",
    alignItems: "center",
    top: "-0.938rem",
    justifyContent: "center",
    right: "-1.125rem",
    cursor: "pointer",
    borderRadius: "50%",
  };

  return (
    <>
      {isReviewModalOpen && (
        <Modal
          closeBtn={
            {
              // lineHeight: "1rem",
              // position: "absolute",
              // top: "-1.4rem",
              // right: "-0.4rem",
            }
          }
          onClose={() => {
            SetIsReviewModalOpen(false);
            setIsReviewRejectModalOpen(false);
          }}
        >
          <div className={classes.modal_body}>
            {data && (
              <SimpleObjForm
                currentStep={0}
                formObj={formObject()}
                disabledVal={true}
                checker={true}
                checkString="Changes are done in this Field"
                corpFormDetails={data}
                checkData={checkerData}
                onDataChange={(newData) => setData(newData)} // Handle data change
              />
            )}

            <div className="btn-group mt-0">
              <div className="btn-form mr-3">
                <PrimaryButton
                  label={t("button.accept")}
                  onClick={() => {
                    proceedDataFunction(checkerid, true);
                    submitHandle(checkerid, true);
                  }}
                />
              </div>
              <div className="btn-form mr-3">
                <PrimaryButton
                  label={t("button.reject")}
                  onClick={() => {
                    setIsReviewRejectModalOpen(true);
                    SetIsReviewModalOpen(false);
                  }}
                />
              </div>
              <div className="btn-form">
                <SecondaryButton
                  onClick={() => SetIsReviewModalOpen(false)}
                  label={t("button.cancel")}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
      {isReviewRejectModalOpen && (
        <Modal closeBtn={closeBtnStyle} onClose={() => closeRejectModal()}>
          <div className={"p-4" + " " + classes.rejected_modal}>
            <div className="d-flex align-items-center justify-content-between flex-column">
              <h5>
                <img src={rejectfileIcon} className={classes.rejected_svg} />{" "}
                {t("document.rejected")}
              </h5>
            </div>

            <div className={"mt-4" + " " + classes.reject_document_name}>
              <p>{t("changes_by_maker.rejected")}</p>
            </div>

            <div className="mt-4 ">
              <p className={classes.normal_small_text + " " + "mb-2 "}>
                {t("rejected_document_reason.list.text")}
              </p>
            </div>
            <div className="reasons-part">
              <div className="rejected_reasons mt-1">
                <select
                  className="form-control form-select"
                  onChange={(e) => selectedReasonFunction(e.target.value)}
                >
                  <option selected disabled>
                    Select Reasons
                  </option>
                  {rejectReasons.map((item, id) => {
                    return <option value={item}>{item}</option>;
                  })}
                  <option value="others">Others</option>
                </select>
                <p className={classes.normal_small_text}>
                  <span>(You can select multiple reasons)</span>
                </p>
              </div>
              <div className={classes.selected_reasons}>
                <ul>
                  {selectedReason &&
                    selectedReason.map((item, id) => {
                      if (item != undefined) {
                        return (
                          <li key={id}>
                            {item}
                            <span
                              onClick={() => {
                                removeselectedReasonFunction(item);
                                rejCheckButtonStatus();
                              }}
                            >
                              <i className={classes.cross_icon_rejected}>
                                <img src={crossIconSVG} />
                              </i>
                            </span>
                          </li>
                        );
                      }
                    })}
                </ul>
              </div>

              {customRejReason && (
                <div className="custom_reason">
                  <textarea
                    className="textarea form-control w-100 rounded"
                    rows="3"
                    placeholder="write your reasons"
                    id="customreason"
                    onInput={(e) => {
                      e.target.value.length > 0
                        ? setRejCheckButton(false)
                        : selectedReason.length > 0
                        ? setRejCheckButton(false)
                        : setRejCheckButton(true);
                    }}
                  ></textarea>
                </div>
              )}
            </div>
            <div className="col-lg-12 mt-4 px-0">
              <div className="btn-group ml-auto mr-3">
                <PrimaryButton
                  disabled={rejCheckButton}
                  label={t("Submit")}
                  onClick={() => {
                    // rejectedFunction(true, isActiveDocumentName);
                    rejectedReasonFormsFunction(checkerid);
                    proceedDataFunction(checkerid, false);

                    submitHandle(checkerid, false);
                    setIsReviewRejectModalOpen(false);
                  }}
                />
              </div>
              <div className="btn-group mr-3">
                <SecondaryButton
                  label={t("Close")}
                  onClick={() => {
                    closeRejectModal();
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ReviewsByModal;
