import React, { useEffect, useState } from "react";
import CustomTable from "../CustomTable/CustomTable";
import GoBackButton from "../Buttons/GoBackButton";
import PrimaryButton from "../Buttons/PrimaryButton";
import classes from "./TableDashboard.module.css";
import DownloadIcon from "../../assets/TableDashboard/Download.svg";
import TabComp from "../Tabs/TabComp";
import ExportCsvIcon from "../../assets/TableDashboard/export_csv.svg";
import OrangeDot from "../../assets/TableDashboard/OrangeDot.svg";
import GreenDot from "../../assets/TableDashboard/GreenDot.svg";
import RedDot from "../../assets/TableDashboard/RedDot.svg";
import searchIcon from "../../assets/SearchIcon_grey.svg";
import { useTranslation } from "react-i18next";
import Select from "../Select/Select";
import DoubleDatePicker from "../../components/DatePicker/DoubleDatePicker";
import FilterComponent from "../FilterComponent/FilterComponent";
import { useSelector } from "react-redux";
import { Accordion, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Utils from "../../Utils";

const TableDashboard = ({
  srNO,
  columns,
  handleSort,
  dateOne,
  dateTwo,
  analyticCard,
  tabsLabel,
  handleNewCorpForm,
  newFormButtonLabel,
  pageData,
  tabValue,
  setTabValue,
  filterBy,
  setfilterBy,
  searchInput,
  setSearchInput,
  handleSearch,
  setDateOne,
  setDateTwo,
  handleDateChange,
  perPage,
  setperPage,
  page,
  tableData,
  accessor,
  setAccessor,
  setPage,
  sort,
  setSort,
  setChoModal,
  data,
  tableStrings,
  downloadCsv,
  downloadFullCsv,
  setSubordinateId,
  dashboardCardsInActive,
  isPriorityContainerInactive,
  isPranType,
  isAccordionOff,
  tableRow,
  handleTableRowColor,
  bulkImportUser,
  bulkVerificationBtn,
  isOsv,
}) => {
  const { t } = useTranslation();
  const [exportAll, setExportAll] = useState(false);
  const [expendAccordion, setExpendAccordion] = useState(true);
  const [perPageArr, setPerPageArr] = useState([
    { val: 10, text: "10 per page" },
    { val: 20, text: "20 per page" },
    { val: 50, text: "50 per page" },
  ]);
  const { loginUserDetails } = useSelector((state) => state);

  return (
    <section className={classes.table_dashboard_container}>
      <div className={classes.goback_btn}>
        <GoBackButton />

        {bulkImportUser && bulkImportUser}

        {handleNewCorpForm && (
          <PrimaryButton
            onClick={handleNewCorpForm}
            label={newFormButtonLabel || t("button.registerNewCorp")}
          />
        )}
      </div>
      {!dashboardCardsInActive && (
        <div className={classes.analytic_card}>
          <Accordion
            sx={{
              background: "var(--app-white)",
              margin: "1rem",
              width: "97%",
              borderRadius: "1rem",
              border: "none !important",
              boxShadow: "none !important",
              paddingLeft: "",
            }}
            expanded={expendAccordion}
            onChange={() =>
              !isAccordionOff &&
              (expendAccordion
                ? setExpendAccordion(false)
                : setExpendAccordion(true))
            }
          >
            <AccordionSummary
              expandIcon={!isAccordionOff && <ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography component={"span"} sx={{ width: "100%" }}>
                <div className={classes.card_title}>
                  <h3> {tableStrings.title} </h3>
                  {isPranType && isPranType}
                  {setChoModal && (
                    <div
                      onClick={() => setChoModal(true)}
                      className={classes.download_container}
                    >
                      <img src={DownloadIcon} />
                      <p className={classes.download_cho}>Download CHO Form</p>
                    </div>
                  )}
                </div>
                <div className={classes.subTitle}>{tableStrings?.subTitle}</div>
              </Typography>
            </AccordionSummary>
            <div className={classes.card_box}>
              <div className={classes.card_detail_container}>
                {analyticCard &&
                  analyticCard.map((card, key) => {
                    return (
                      <div key={key}>
                        <div className={classes.analytic_icon}>
                          <img src={card.icon} />
                        </div>
                        <div className={classes.detail_card_info}>
                          <h4>{card.count}</h4>
                          <p>{card.title} </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Accordion>
        </div>
      )}
      {dashboardCardsInActive && <br />}
      <div className={classes.Table_tabs}>
        <div className={classes.tab_title}>
          <h3>List All {tableStrings.listAll_title} </h3>
          <h6>
            Showing{" "}
            {pageData && pageData.totalPages === 0 ? 0 : pageData.number + 1}/
            {pageData && pageData.totalPages}
          </h6>
        </div>
        <div className={classes.table_container}>
          <div className={classes.tab_table}>
            <TabComp
              clickAble={true}
              label={tabsLabel}
              tabValue={tabValue}
              setTabValue={setTabValue}
              filterBy={filterBy}
              setfilterBy={setfilterBy}
              setExportAll={setExportAll}
            />
          </div>
          <div className="d-flex align-items-center">
            {exportAll && (
              <div
                className={
                  classes.export_csv +
                  " " +
                  classes.custom_pointer +
                  " " +
                  " mr-4 "
                }
                onClick={() => downloadFullCsv()}
              >
                <img src={ExportCsvIcon} />
                Export Full CSV
              </div>
            )}

            {bulkVerificationBtn && bulkVerificationBtn}

            {downloadCsv && (
              <div
                className={classes.export_csv + " " + classes.custom_pointer}
                onClick={() => downloadCsv()}
              >
                <img src={ExportCsvIcon} />
                Export CSV
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={classes.datatable_container}>
        {!isPriorityContainerInactive && (
          <div className={classes.priority_container}>
            <div>
              <img src={GreenDot} />
              {t("text.lowPriority")}
            </div>
            <div>
              <img src={OrangeDot} />
              {t("text.mediumPriority")}
            </div>
            <div>
              <img src={RedDot} />
              {t("text.highPriority")}
            </div>
          </div>
        )}

        <div className={classes.table_sort_container}>
          <div className={classes.search_container}>
            <form onSubmit={handleSearch} className={classes.header_searchbar}>
              <p>
                <img src={searchIcon} />
                <input
                  value={searchInput}
                  onChange={(e) =>
                    setSearchInput(Utils.PreventSpecialCharacterForEmail(e))
                  }
                  placeholder={tableStrings.search_placeholder}
                />
              </p>
              <input
                value={t("label.search")}
                type="button"
                onClick={handleSearch}
              />
            </form>
            {!dashboardCardsInActive && (
              <DoubleDatePicker
                placeHolder1={"Start Date"}
                placeHolder2={"End Date"}
                FirstDateFunction={setDateOne}
                SecondDateFunction={setDateTwo}
                min1={0}
                max1={new Date()}
                min2={dateOne}
                max2={new Date()}
              />
            )}
            {handleNewCorpForm &&
              (loginUserDetails?.subordinates?.length > 0 || isOsv ? (
                <FilterComponent
                  dataArray={loginUserDetails?.subordinates}
                  setSubordinateId={setSubordinateId}
                />
              ) : null)}
          </div>
          {!isOsv && (
            <Select
              onChangeHandler={setperPage}
              dataObj={perPageArr}
              selectedData={perPage}
            />
          )}
        </div>
        <CustomTable
          handleTableRowColor={handleTableRowColor}
          page={page}
          setPage={setPage}
          pageData={pageData}
          data={tableData}
          headings={columns}
          sort={sort}
          setSort={setSort}
          accessor={accessor}
          setAccessor={setAccessor}
          srNO={srNO}
          tableRow={tableRow}
        />
      </div>
    </section>
  );
};

export default TableDashboard;
