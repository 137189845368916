import React, { useEffect, useState } from "react";
import Classes from "./SubscriberScrutinyFormFrame.module.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import { useTranslation } from "react-i18next";
import Utils from "../../../../Utils";
import GoBackButton from "../../../../components/Buttons/GoBackButton";
import fileSVG from "../../../../assets/file.svg";
import CheckGreenIcon from "../../../../assets/Right_check_icons/Green_Check.svg";
import CheckWhiteIcon from "../../../../assets/Right_check_icons/White_Check.svg";
import CrossRedIcon from "../../../../assets/Cross_Icons/Cross_red.svg";
import CrossWhiteIcon from "../../../../assets/Cross_Icons/Cross_white.svg";
import info_icon from "../../../../assets/info-blue-icon.svg";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import TextButton from "../../../../components/Buttons/TextButton";
import { memo } from "react";

const ScruitnyForm = ({
  corpDetails,
  documentsStatus,
  isActiveId,
  setIsActiveId,
  documentsItem,
  setDocumentsItem,
  showCurrentImage,
  ischeckedFunction,
  setCurrDocOrionId,
  rejectedForms,
  setRejectedForms,
  setIsCorporateApplicationModal,
  setIsRejCorporateApplicationModal,
  handleNavigate,
  acceptDisable,
  rejectDisable,
  isActiveDocumentName,
  setIsActiveDocumentName,
  setSelectedReason,
  proceedStatus,
  proceedDataFunction,
  setIsModal,
  setRejectionReason,
  setIsComparisonModalOpen: setIsComparisonModalOpen,
  isComparisonModalOpen,
  headDetails,
  setIsFRHistoryModal,
}) => {
  const { t } = useTranslation();
  const setIsActiveIdFunction = (id) => {
    setIsActiveId(id);
    ischeckedFunction(documentsItem[id]);
  };

  const acceptedFunction = (check) => {
    if (documentsItem && check == true) {
      var newArr = documentsItem;
      newArr[isActiveId].docStatus = documentsStatus.accepted;
      newArr[isActiveId].isChecked = true;

      setDocumentsItem(newArr);

      if (rejectedForms[isActiveId] != null) {
        delete rejectedForms[isActiveId];
      }
      setRejectedForms((data) => ({
        ...data,
      }));

      ischeckedFunction(newArr[isActiveId]);
    }
  };

  // if page has rejected documents or not
  const rejectionPresence = () => {
    if (Object.keys(rejectedForms).length > 0) {
      setIsRejCorporateApplicationModal(true);
    } else {
      setIsCorporateApplicationModal(true);
    }
  };

  // change active status of document and also list
  const showchangedDocument = (num, speed, doclen) => {
    let showcurrObj = documentsItem[num];
    if (showcurrObj === undefined || showcurrObj.orionDocId === null) {
      if (speed === 1) {
        if (num + 1 >= doclen) {
          num = -1;
          setIsActiveId(num);
        }
        setIsActiveId(num + 1);
        return showchangedDocument(num + 1, 1, doclen);
      } else {
        if (num === -1) {
          num = doclen;
          setIsActiveId(num);
        }
        setIsActiveId(num - 1);
        return showchangedDocument(num - 1, -1, doclen);
      }
    }
    setCurrDocOrionId(showcurrObj.orionDocId);
    ischeckedFunction(documentsItem[num]);
    setIsActiveDocumentName(documentsItem[num]);
  };

  const changeDocument = (num) => {
    var doclen = documentsItem.length;
    doclen = doclen + 1;
    if (num == 1) {
      setIsActiveId(isActiveId + 1);
      showchangedDocument(isActiveId + 1, num, doclen);
    } else {
      setIsActiveId(isActiveId - 1);
      showchangedDocument(isActiveId - 1, num, doclen);
    }
  };

  // scrutiny compare function
  const scrutinyCompareFunction = (index, item) => {
    setIsActiveIdFunction(index);
    setIsActiveDocumentName(item.processDocumentType);
    setCurrDocOrionId(item.orionDocId);
    setIsComparisonModalOpen(true);
  };

  return (
    <main className={Classes.full_width + " " + Classes.mainScreenheight}>
      <GoBackButton />
      <div
        className={
          Classes.container + "   mb-3 py-3 " + " " + Classes.containerHeight
        }
      >
        <div className={"row pt-3" + " " + Classes.corporateScruitny}>
          <div className="col-lg-12 p-0">
            <div
              className="card p-4   border-0"
              style={{ marginBottom: "3px" }}
            >
              <h6 className={Classes.list_head}> Application Details :</h6>
              <div className={Classes.corpoDetails}>
                {headDetails &&
                  headDetails?.map((data, index) => {
                    return (
                      <div className={Classes.details_box}>
                        <p className={Classes.details_head}>{data.label}</p>
                        <p className={Classes.details_body}>{data.value}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 p-0">
            <div className="card p-4 border-0">
              <div
                className={"row pt-2 pb-0" + " " + Classes.documentRowHeight}
              >
                <div
                  className={
                    "col-lg-6 mb-4" + " " + Classes.formDocumentSection
                  }
                >
                  <div className={"card" + " " + Classes.full_height}>
                    <div className={"card-body py-3"}>
                      <div className="d-flex align-items-center flex-wrap ">
                        <div
                          className={
                            Classes.details + " " + "py-md-0 py-2 mr-auto"
                          }
                        >
                          <i className={Classes.svg_icon_size}>
                            <img src={fileSVG} />
                          </i>

                          {documentsItem[isActiveId] &&
                            documentsItem[isActiveId].processDocumentType && (
                              <p
                                className={
                                  documentsItem[isActiveId].processDocumentType
                                    .isMandatory
                                    ? Classes.text_mandatory +
                                      " " +
                                      "card-title mb-0 pb-0 " +
                                      " " +
                                      Classes.form_para_head
                                    : +" " +
                                      "card-title mb-0 pb-0 " +
                                      " " +
                                      Classes.form_para_head
                                }
                                title={
                                  documentsItem[isActiveId]?.processDocumentType
                                    ?.uiString
                                }
                              >
                                {Utils.ShortLengthFunction(
                                  documentsItem[isActiveId]?.processDocumentType
                                    ?.uiString,
                                  30
                                )}
                              </p>
                            )}
                        </div>

                        <div
                          className={`${Classes.document_badge} ${Classes.pran_doc}`}
                        >
                          <p>PRAN Document</p>
                        </div>
                      </div>

                      {/* <hr className="my-1" /> */}
                    </div>
                    <hr className="my-1" />
                    <div
                      className={
                        "document_body overflow-hidden" +
                        " " +
                        Classes.full_width +
                        " " +
                        Classes.full_height
                      }
                    >
                      <iframe
                        style={{ minHeight: "60vh" }}
                        className={
                          Classes.full_height + " " + Classes.full_width
                        }
                        src={showCurrentImage}
                        sandbox
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "col-lg-6 mb-4" + " " + Classes.formDocumentSection
                  }
                >
                  <div className={"card" + " " + Classes.full_height}>
                    <div className={"card-body py-3"}>
                      <div className="d-flex align-items-center flex-wrap ">
                        <div
                          className={
                            Classes.details + " " + "py-md-0 py-2 mr-auto"
                          }
                        >
                          <i className={Classes.svg_icon_size}>
                            <img src={fileSVG} />
                          </i>

                          {documentsItem[isActiveId] &&
                            documentsItem[isActiveId].processDocumentType && (
                              <p
                                className={
                                  documentsItem[isActiveId].processDocumentType
                                    .isMandatory
                                    ? Classes.text_mandatory +
                                      " " +
                                      "card-title mb-0 pb-0 " +
                                      " " +
                                      Classes.form_para_head
                                    : +" " +
                                      "card-title mb-0 pb-0 " +
                                      " " +
                                      Classes.form_para_head
                                }
                                title={
                                  documentsItem[isActiveId]?.processDocumentType
                                    ?.uiString
                                }
                              >
                                {Utils.ShortLengthFunction(
                                  documentsItem[isActiveId]?.processDocumentType
                                    ?.uiString,
                                  30
                                )}
                              </p>
                            )}
                        </div>

                        <div
                          className={`${Classes.document_badge} ${Classes.support_doc}`}
                        >
                          <p>Supported Document</p>
                        </div>
                      </div>

                      {/* <hr className="my-1" /> */}
                    </div>
                    <hr className="my-1" />
                    <div
                      className={
                        "document_body overflow-hidden" +
                        " " +
                        Classes.full_width +
                        " " +
                        Classes.full_height
                      }
                    >
                      <iframe
                        style={{ minHeight: "60vh" }}
                        className={
                          Classes.full_height + " " + Classes.full_width
                        }
                        src={showCurrentImage}
                        sandbox
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pb-3 pt-0">
                <div className="col-lg-12">
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <div className="btn_areas">
                      <div className={Classes.bottom_btn_text}>
                        <p>Do you want to accept / reject this application ?</p>
                      </div>
                      <div className="btn-group mr-3">
                        <SecondaryButton
                          disabled={acceptDisable}
                          style={{
                            backgroundColor:
                              acceptDisable == true
                                ? "var(--app-btn-background)"
                                : "",
                          }}
                          onClick={() => {
                            acceptedFunction(true);
                            proceedDataFunction(
                              documentsItem[isActiveId].id,
                              true
                            );
                          }}
                          label={t("Accept")}
                          iconImage={
                            acceptDisable == true
                              ? CheckWhiteIcon
                              : CheckGreenIcon
                          }
                        />
                      </div>
                      <div className="btn-group mr-3">
                        <SecondaryButton
                          style={
                            rejectDisable == true
                              ? {
                                  backgroundColor: "var(--app-btn-background)",
                                  color: "var(--app-white)",
                                }
                              : {}
                          }
                          label={t("Reject")}
                          iconImage={
                            rejectDisable == true
                              ? CrossWhiteIcon
                              : CrossRedIcon
                          }
                          onClick={() => {
                            setIsModal(true);
                          }}
                        />
                      </div>
                      <div className="btn-group">
                        <TextButton
                          label={t("Cancel")}
                          iconImage={
                            rejectDisable == true
                              ? CrossWhiteIcon
                              : CrossRedIcon
                          }
                          onClick={() => {
                            setIsModal(true);
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className={
                        Classes.details +
                        " " +
                        Classes.cursorPointer +
                        " " +
                        "pb-md-0 pb-2"
                      }
                      onClick={() => setIsFRHistoryModal(true)}
                    >
                      <div className={`mb-0 ${Classes.count_rej_doc_div}`}>
                        <span>
                          <img src={info_icon} /> Raised FR History :
                        </span>
                        <p>2 times</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default memo(ScruitnyForm);
