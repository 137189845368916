import React from 'react'
import classes from "../../../NPSRegistrationPage/NPSRegistrationPage.module.css";
import Modal from '../../../../../components/Modal/Modal';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import TextButton from '../../../../../components/Buttons/TextButton';

const PendingFormModal = ({
    isOTP,
    isOtpValid,
    isRegPendingModal,
    setIsRegPendingModal,
    setBasicDetails,
    basicDetails,
    handleSendOTP,
    handleValidateOTP,
    requestId,
    handleSubscriber
}) => {
  return (
    isRegPendingModal && (
        <Modal onClose={() => setIsRegPendingModal(false)}>
          <div className={classes.pending_Modal}>
            <h5>Complete Pending Registration</h5>
            <div className={classes.input_container}>
              <p> Acknowledgement Id </p>
              <input
                className={classes.ackId_input}
                value={basicDetails.ackId}
                onChange={(e) =>
                  setBasicDetails((data) => ({
                    ...data,
                    ackId: e.target.value.toLocaleUpperCase(),
                  }))
                }
                type="text"
              />
            </div>
            <div className={classes.input_container}>
              <p>Phone Number</p>
              <div>
                <input
                  className={classes.phone_input}
                  value={basicDetails.phoneNumber}
                  onChange={(e) =>
                    setBasicDetails((data) => ({
                      ...data,
                      phoneNumber: e.target.value,
                    }))
                  }
                  type="number"
                />
                <TextButton
                  onClick={handleSendOTP}
                  disabled={basicDetails.phoneNumber.length !== 10}
                  style={{ height: "2rem" }}
                  label={"Send OTP"}
                />
              </div>
            </div>
            {isOTP && requestId && (
              <div className={classes.input_container}>
                <p>Enter OTP</p>
                <div>
                  <input
                    className={classes.phone_input}
                    value={basicDetails.otp}
                    onInput={(e) =>
                      setBasicDetails((data) => ({
                        ...data,
                        otp: e.target.value,
                      }))
                    }
                    type="number"
                  />
                  <TextButton
                    onClick={handleValidateOTP}
                    disabled={!basicDetails.otp}
                    style={{ height: "2rem" }}
                    label={"Validate"}
                  />
                </div>
              </div>
            )}

            <PrimaryButton
              disabled={!isOtpValid}
              onClick={handleSubscriber}
              label={"Proceed"}
            />
          </div>
        </Modal>
      )
  )
}

export default PendingFormModal