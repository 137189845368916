import React from "react";
import classes from "./DateInputBox.module.css";
import Utils from "../../Utils";

const DateInputBox = ({
  value,
  disabled,
  label,
  handleOnchange,
  comment,
  errorMsg,
  placeholder,
  max,
  name,
  min,
}) => {
  return (
    <div className={classes.input_box}>
      <div className={classes.label}>{label}</div>
      <div>
        <input
          max={max}
          className={`${errorMsg ? classes.input_empty : classes.input}`}
          value={value}
          onChange={handleOnchange}
          disabled={disabled}
          type="date"
          placeholder={placeholder}
          name={name}
          min={min}
        />
        {comment && <h3 className={classes.comment}>{comment}</h3>}
        {errorMsg && <p className={classes.error_msg}>{errorMsg}</p>}
      </div>
    </div>
  );
};

export default DateInputBox;
