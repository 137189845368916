import React from "react";
import classes from "../MasterManagement.module.css";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import { useState } from "react";
import Utils from "../../../../Utils";
import ArrowUPIcon from "../../../../assets/arrow-up.svg";
import PenEditIcon from "../../../../assets/pen_edit.svg";
import { useEffect } from "react";
import {
  editClaimUnclaim,
  getAllProcessDocumentManagement,
  getProcessDocumentManagement,
  makerCheckerFlow,
} from "../../AdminManagementActions";
import TextButton from "../../../../components/Buttons/TextButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import Modal from "../../../../components/Modal/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMsg } from "../../../Home/HomeSlice";

const DocumentTypeManagement = ({
  searchingQuery,
  perPage,
  isWorkflowEnabled,
  masterId,
}) => {
  const userDetails = useSelector((state) => state.loginUserDetails);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("");
  const [accessor, setAccessor] = useState("");
  const [columnRotations, setColumnRotations] = useState("submissionDateasc");
  const [tableData, setTableData] = useState([]);
  const [pageData, setPageData] = useState({});
  const [filterBy, setfilterBy] = useState("");
  const [disabledStatus, setDisabledStatus] = useState(true);
  const [holidayFormActive, setHolidayFormActive] = useState(false);
  const [editDocumentName, setEditDocumentName] = useState("");
  const [editDocumentSize, setEditDocumentSize] = useState(0);
  const [editDocumentMandatoryStatus, setEditDocumentMandatoryStatus] =
    useState(true);
  const [changedDocumentTypeInput, setChangedDocumentTypeInput] = useState({
    id: "",
    DocumentName: "",
    DocumentMandatory: "",
    DocumentSize: "",
  });
  const [rowId, setRowId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  // document type table columns
  const processDocumentTableColumns = [
    {
      header: (
        <p className={classes.headers}>
          NAME
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "uiStringasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("uiString")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.uiString,
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 35 },
      isTextCenter : true
    },
    {
      header: (
        <p className={classes.headers}>
          Mandatory
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "isMandatoryasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("isMandatory")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => (e.isMandatory ? "Yes" : "No"),
    },
    {
      header: (
        <p className={classes.headers}>
          Document Size
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "documentSizeasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("documentSize")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => Utils.formatSize(e.documentSize),
      toolTipStatus: true,
      shortlenText: { isShown: true, len: 15},
      isTextCenter : true
      
    },

    {
      header: <p className={classes.headers}>ACTION</p>,
      key: "corpAction",
      accessor: (e) => (
        <div className={classes.action_btn}>
          {isWorkflowEnabled && (
            <TextButton
              style={{
                display:
                  e.makerCheckerStatus === null ||
                  e.makerCheckerStatus === 0 ||
                  e.currentMaker?.username !== userDetails.username ||
                  (e.makerCheckerFlowFunction == 1 &&
                    e.currentMaker?.username !== userDetails.username)
                    ? "none"
                    : "",
              }}
              disabled={
                e.makerCheckerStatus === 2 ||
                (e.makerCheckerStatus === 1 &&
                  e.currentMaker?.username !== userDetails.username)
              }
              onClick={() => {
                editClaimUnclaimFunction(e.id, true);
                setRowId(e.id);
              }}
              label={t("button.release")}
            />
          )}
          {/* userDetails.username != "wbadmin */}
          <TextButton
            disabled={
              e.makerCheckerStatus === 2 ||
              (e.makerCheckerStatus === 1 &&
                e.currentMaker?.username !== userDetails.username &&
                !Utils.isAdmin(userDetails))
            }
            onClick={() => {
              isWorkflowEnabled == true &&
              (e.makerCheckerStatus == 0 || e.makerCheckerStatus == null) ? (
                <>{(setIsEdit(true), editHolidayFunctionData(e.id))}</>
              ) : (
                <>
                  {(setHolidayFormActive(true), editHolidayFunctionData(e.id))}
                </>
              );
            }}
            label={t("button.edit")}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    setPage(0);
  }, [perPage, searchingQuery]);
  useEffect(() => {
    getAllProcessDocumentManagementFunction();
  }, [sort, accessor, page, perPage, searchingQuery]);

  const getAllProcessDocumentManagementFunction = () => {
    getAllProcessDocumentManagement({
      page,
      searchingQuery,
      sort,
      accessor,
      perPage,
      filterBy,
    }).then((res) => {
      setTableData(res.data.data.content);
      setPageData(res.data.data);
    });
  };

  const handleSort = (key) => {
    var sorting = sort;
    const newSort = sort === "asc" ? "desc" : "asc";
    setSort(newSort);
    setAccessor(key);

    // Update rotation state for the current column
    if (sort === "asc") {
      setColumnRotations(key + "desc");
    } else {
      setColumnRotations(key + "asc");
    }
  };

  const editHolidayFunctionData = (id) => {
    setRowId(id);
    getProcessDocumentManagement(id).then((res) => {
      let d_name = res.data.data.masterData.uiString;
      let d_size = res.data.data.masterData.documentSize;
      let d_mandatory_status = String(res.data.data.masterData.isMandatory);
      setEditDocumentName(d_name);
      setEditDocumentSize(d_size);
      setEditDocumentMandatoryStatus(d_mandatory_status);
      setChangedDocumentTypeInput({
        id: id,
        DocumentName: d_name,
        DocumentSize: d_size,
        DocumentMandatory: d_mandatory_status,
      });
    });
  };

  const editClaimUnclaimFunction = (rowId, isReleased) => {
    editClaimUnclaim(masterId, rowId)
      .then((res) => {
        if (isReleased == false) {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.task_claimed"),
              alertType: "success",
            })
          );
          setHolidayFormActive(true);
          setIsEdit(false);
        } else {
          dispatch(
            setAlertMsg({
              msg: t("alertMsg.task_unclaimed"),
              alertType: "success",
            })
          );
          getAllProcessDocumentManagementFunction();
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.task_claim_fail"),
            alertType: "danger",
          })
        );
      });
  };

  const makerCheckerFlowFunction = () => {
    let data = {};
    if (editDocumentName != changedDocumentTypeInput.DocumentName) {
      data.uiString = Utils.removeWhiteSpaceFromBetween(editDocumentName);
    }
    if (editDocumentSize != changedDocumentTypeInput.DocumentSize) {
      data.documentSize = editDocumentSize;
    }
    if (
      editDocumentMandatoryStatus != changedDocumentTypeInput.DocumentMandatory
    ) {
      data.isMandatory = editDocumentMandatoryStatus;
    }
    makerCheckerFlow(masterId, rowId, data)
      .then((res) => {
        dispatch(
          setAlertMsg({
            msg:
              isWorkflowEnabled === true
                ? t("alertMsg.changes_for_approval")
                : t("alertMsg.edit_successfully"),
            alertType: "success",
          })
        );
        setHolidayFormActive(false);
        getAllProcessDocumentManagementFunction();
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            msg: t("alertMsg.update_failed"),
            alertType: "danger",
          })
        );
      });
  };

  useEffect(() => {
    checkSubmitDisabled();
  }, [editDocumentName, editDocumentSize, editDocumentMandatoryStatus]);

  const checkSubmitDisabled = () => {
    var status =
      (Utils.removeWhiteSpaceFromBetween(
        editDocumentName
      ).toLocaleLowerCase() ==
        Utils.removeWhiteSpaceFromBetween(
          changedDocumentTypeInput.DocumentName
        ).toLocaleLowerCase() &&
        editDocumentMandatoryStatus ==
          changedDocumentTypeInput.DocumentMandatory &&
        editDocumentSize == changedDocumentTypeInput.DocumentSize) ||
      editDocumentName == "" ||
      editDocumentSize == "" ||
      editDocumentMandatoryStatus == ""
        ? true
        : false
        ? true
        : false;
    setDisabledStatus(status);
  };

  return (
    <>
      <CustomTable
        page={page}
        setPage={setPage}
        headings={processDocumentTableColumns}
        data={tableData}
        pageData={pageData}
        sort={sort}
        setSort={setSort}
        accessor={accessor}
        setAccessor={setAccessor}
        srNO={true}
      />
      {/* for holiday edit */}

      {isEdit == true && isWorkflowEnabled == true && (
        <Modal>
          <div className={classes.editModal_container}>
            <h4>
              <span>
                <img src={PenEditIcon} alt="pen edit Master management" />
              </span>{" "}
              {t("proceed.edit.master")}
            </h4>
            <p>{t("proceed.edit.master.text")}</p>
            <div className={classes.editModal_btn}>
              <PrimaryButton
                onClick={() => {
                  editClaimUnclaimFunction(rowId, false);
                }}
                label={t("button.proceed")}
              />
              <SecondaryButton
                onClick={() => {
                  setIsEdit(false);
                }}
                label={t("button.close")}
              />
            </div>
          </div>
        </Modal>
      )}

      {holidayFormActive && (
        <Modal
          closeBtn={{}}
          onClose={() => {
            getAllProcessDocumentManagementFunction();
            setHolidayFormActive(false);
          }}
        >
          <div className={classes.holiday_modal_body}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-input mb-3">
                  <label>Document Name</label>
                  <input
                    type="text"
                    name="document_name"
                    value={editDocumentName}
                    onInput={(e) => {
                      setEditDocumentName(e.target.value);
                    }}
                    className="form-control"
                  />
                </div>
                <div className="form-input mb-3">
                  <label>Document Mandatory Status</label>
                  <select
                    className="form-control form-select"
                    name="document_mandatory"
                    value={editDocumentMandatoryStatus}
                    onChange={(e) => {
                      setEditDocumentMandatoryStatus(e.target.value);
                    }}
                  >
                    <option value={"true"}>Yes</option>
                    <option value={"false"}>No</option>
                  </select>
                </div>
                <div className="form-input">
                  <label>Document Size</label>
                  <input
                    type="number"
                    name="document_size"
                    value={editDocumentSize}
                    onInput={(e) => setEditDocumentSize(e.target.value)}
                    className="form-control"
                  />
                </div>

                <div className="btn-group mt-4">
                  <div className="btn-form mr-3">
                    <PrimaryButton
                      disabled={disabledStatus}
                      label={t("button.submit")}
                      onClick={() => {
                        makerCheckerFlowFunction(changedDocumentTypeInput.id);
                        // changeHolidayFunction(changedHolidayInput.id);
                      }}
                    />
                  </div>
                  <div className="btn-form">
                    <SecondaryButton
                      onClick={() => {
                        getAllProcessDocumentManagementFunction();
                        setHolidayFormActive(false);
                      }}
                      label={t("button.cancel")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DocumentTypeManagement;
