import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import classes from "./CorporateForm.module.css";
import ConfigObj from "../corporateObj.json";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Utils from "../../../Utils";
import Modal from "../../../components/Modal/Modal";
import PreViewForm from "../../../components/PreViewForm/PreViewForm";
import {
  checkStateWithGST,
  corporatePreviewForm,
  getAllCRA,
  getAllDistricts,
  getAllPOPOFCRA,
  getAllStates,
  getAreaDetailsFromPinCode,
  getBankDetails,
  getCorpFormData,
  getCorporatePfm,
  getFromChargesFromMaster,
  registerCorporate,
  verifyBankDetails,
} from "../CorporateAction";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setAlertMsg, setArrayOfAlertMsg } from "../../Home/HomeSlice";
import { json, useNavigate } from "react-router";
import PieArcLabel from "../../../components/PieChart/PieChart";
import RangeInput from "../../../components/RangeInput/RangeInput";
import TabComp from "../../../components/Tabs/TabComp";
import ErrorIcon from "../../../assets/Error_icon.svg";
import PenIcon from "../../../assets/penIcon.svg";
import TextButton from "../../../components/Buttons/TextButton";
import linkIcon from "../../../assets/info_Icon.svg";
import CorporateUtils from "./CorporateUtils";
import Consts from "../../../Consts";
import { current } from "@reduxjs/toolkit";

const CorporateForm = forwardRef(
  (
    {
      setPanNum,
      panNum,
      setActiveStep,
      setackId,
      autoPopulateData,
      activeStep,
      setStepperErrorArray,
      currentTab,
      setCurrentTab,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const useRefCurrentStep = useRef(0);
    const useRefCurrentTab = useRef(0);
    const corpId = JSON.parse(localStorage.getItem("corpId"));
    const [isPreview, setIsPreview] = useState(false);
    const [isOpenAccordion, setIsOpenAccordion] = useState(0);
    const [previewData, setPreviewData] = useState();

    const [formObj, setFormObj] = useState({
      sector: "",
      constOfBusiness: "",
      corpName: "",
      panNumber: panNum,
      hoBuildingName: "",
      hoArea: "",
      mobileNumber: "",
      corpHeadOfficeLoc: "",
      autoChoiceLc: "LC50",
      corpSubOfficeLoc: "",
      corpHeadHrName: "",
      corpHeadHrEmail: "",
      corpHeadHrMobile: "",
      corpSpocName: "",
      corpSpocEmail: "",
      corpSpocMobile: "",
      "corpHeadC&bName": "",
      "corpHeadC&bEmail": "",
      "corpHeadC&bMobile": "",
      sector: "",
      gstno: "",
      cinNumber: "",
      craName: "",
      bankName: "",
      accountNumber: "",
      ifscNumber: "",
      hoFlatNo: "",
      hoDistrict: "",
      hoState: "",
      hoPinCode: "",
      popChargesBorneBy: "",
      retirementAge: "",
      schemePreference: "Subscriber",
      pfmSelection: "",
      investmentOption: "Auto Choice",
      noFirstName: "",
      noDesignation: "",
      noMobileNumber: "",
      noEmailId: "",
      altNoFirstName: "",
      altNoDesignation: "",
      altNoMobileNumber: "",
      altNoEmailId: "",
      popChargesDedCycle: "",
      isPopShift: "false",
      noFlatNo: "",
      isNoAddSameAsCorp: "",
      noDistrict: "",
      noState: "",
      noPinCode: "",
      isAnoAddSameAsCorp: "",
      anoFlatNo: "",
      anoDistrict: "",
      anoState: "",
      anoPinCode: "",
      popChngtargetedPopRegNo: "",
      popChngtargetedPop: "",
      nonFinTransactions: "",
      totalNewJoinees: "",
      expectedTotalNewJoineesForNps: "",
      "c&bOrg": "",
      totalEmployees: "",
      totalEmployeesAbove10L: "",
      popChngChoNum: "",
      popChngExistPop: "",
      popChngExistPopRegNo: "",
      "corpHeadC&bMobile": "",
      corpHeadHrMobile: "",
      mobileNumber: "",
      noMobileNumber: "",
      altNoMobileNumber: "",
      corpSpocMobile: "",
      saCorpName: "",
      saCorpDate: "",
      saCorpPlace: "",
      saCorpDesig: "",
      saCorpDept: "",
      saHdfcName: "",
      saHdfcDate: "",
      saHdfcPlace: "",
      saHdfcDesig: "",
      saHdfcDept: "",
      npsEnrollWindow: "Monthly",
      craChargesBorneBy: "Employer",
    });
    const [validateInputField, setValidateInputField] = useState({});
    const [autoPopulateAccessor, setAutoPopulateAccessor] = useState([]);
    // to print screen
    const componentRef = useRef();

    const [formSectionError, setFormSectionError] = useState([]);
    const [inputFieldsError, setInputFieldsError] = useState([]);
    const [allCRA, setAllCRA] = useState([]);
    const [allPOPS, setAllPOPS] = useState([]);
    const [allStates, setAllStates] = useState([]);
    const [allDistrict, setAllDistrict] = useState({
      hoDistrict: [],
      noDistrict: [],
      anoDistrict: [],
    });
    const [isHoPinCode, setIsHoPinCode] = useState(true);
    const [isNoPinCode, setIsNoPinCode] = useState(true);
    const [isAnoPinCode, setIsAnoPinCode] = useState(true);
    const SectorArray = [
      "Advertisement",
      "Airport",
      "Architecture",
      "Automobiles",
      "Banking and Financial Services",
      "Telecommunication",
      "Manufacturing",
      "IT,BPO,KPO and Services",
      "Real Estate",
      "Agriculture",
      "Chemicals",
      "Defence",
      "E-commerce",
      "FMCG",
      "Healthcare",
      "Hospitality",
      "Pharma",
      "Oil and Gas",
      "Textile",
      "Power",
    ];

    //  temp fund state
    const [fundA, setfundA] = useState(formObj.fundA ? formObj.fundA : "0");
    const [fundE, setfundE] = useState(formObj.fundC ? formObj.fundC : "25");
    const [fundC, setfundC] = useState(formObj.fundE ? formObj.fundE : "50");
    const [fundG, setfundG] = useState(formObj.fundG ? formObj.fundG : "25");

    const [pfmOptions, setPfmOptions] = useState([]);

    const numberInputInvalidChars = ["-", "+", "e", "."];
    const checkboxAccessor = ["contriChargesBorneBy", "accOpChargesBorneBy"];

    useImperativeHandle(ref, () => ({
      validateCurrentSTep(number) {
        handleClickBreadCrumb(number);
      },
    }));

    // pie Chart object
    const pieChartObj = [
      {
        value: Number(fundE),
        label: "Equity (E)",
        color: "var(--app-pie-chart-1st)",
        icon: "E",
      },
      {
        value: Number(fundC),
        label: "Corporate Bonds (C)",
        color: "var(--app-pie-chart-2nd)",
        icon: "C",
      },
      {
        value: Number(fundG),
        label: "Government Securities (G)",
        color: "var(--app-pie-chart-3rd)",
        icon: "G",
      },
      {
        value: Number(fundA),
        label: "Alternate Allocation (A)",
        color: "var(--app-pie-chart-4th)",
        icon: "A",
      },
    ];

    const { t } = useTranslation();
    // get form data
    useEffect(() => {
      //  set auto populate data of corporate from
      if (autoPopulateData.length !== 0) {
        setFormObj((data) => ({
          ...data,
          ...autoPopulateData,
        }));
      }
      //  get previous data of corporate form
      getCorpFormData(corpId)
        .then((res) => {
          if (res.data.success === true) {
            setackId(res.data.data.corpData);
            setAutoPopulateAccessor(
              res.data?.data?.autoPopulate.filter((value) => {
                return value !== "corpName";
              })
            );

            handleAllPOP(res.data.data?.corpFormData?.craName);

            let fieldInputData = {
              ...formObj,
              ...res.data.data.corpFormData,
              isNoAddSameAsCorp:
                res.data.data.corpFormData.isNoAddSameAsCorp === "true"
                  ? "Same as Company"
                  : "",
              isAnoAddSameAsCorp:
                res.data.data.corpFormData.isAnoAddSameAsCorp === "true"
                  ? "Same as Company"
                  : "",

              // if sector is not present in sector array then show sector value in other sector
              otherSector: res.data.data?.corpFormData?.otherSector,

              // if sector is not present in sector array then show empty sector
              sector: res.data.data?.corpFormData?.sector
                ? SectorArray.includes(res.data.data?.corpFormData?.sector)
                  ? res.data.data?.corpFormData?.sector
                  : "Others"
                : "",
            };
            setFormObj(fieldInputData);
            setPanNum(res.data.data.corpData.panNumber);
            if (localStorage.getItem("currentStep")) {
              setActiveStep(JSON.parse(localStorage.getItem("currentStep")));
            }

            // validate fields input
            let step = JSON?.parse(localStorage?.getItem("currentStep")) || 0;
            let subStep =
              JSON?.parse(localStorage?.getItem("currentSubTab")) || 0;
            if (step > 1) {
              let errorObj = checkRequiredFieldsTabClick(
                step,
                subStep,
                fieldInputData
              );

              setStepperErrorArray(errorObj.stepperError);
              setFormSectionError(errorObj.sectionError);
              setInputFieldsError(errorObj.fieldsError);
            }
          }
        })
        .catch((error) => {
          if (error?.response?.data?.success === false) {
            navigate(-1);
          }
          dispatch(
            setAlertMsg({
              msg: error?.response?.data?.message,
              alertType: "danger",
            })
          );
        });

      // get pfm for corporate form
      getCorporatePfm().then((res) => {
        if (res.data.success === true) {
          let options = res.data.data
            .sort((a, b) => a.id - b.id)
            .map((data) => data.name);

          setFormObj((data) => ({
            ...data,
            pfmSelection: formObj.pfmSelection || pfmOptions[0],
          }));

          return setPfmOptions(options);
        }
      });

      // get all cra
      getAllCRA()
        .then((res) => {
          if (res.data.success === true) {
            setAllCRA(res.data.data);
          }
        })
        .catch((error) => {
          dispatch(
            setAlertMsg({
              msg: error?.response.data.message,
              alertType: "danger",
            })
          );
        });

      setCurrentTab(JSON?.parse(localStorage?.getItem("currentSubTab")) || 0);
    }, []);

    const handleAllPOP = (craName) => {
      // Check if formObj.craName exists and is not the default option
      let craId = craName === "KfinTech" ? 2 : 3;

      if (craId) {
        getAllPOPOFCRA(craId)
          .then((res) => {
            if (res.data.success === true) {
              let id = craName === "Protean" ? "5000925" : "1615939";
              setAllPOPS(res.data.data.filter((pop) => pop?.popRegNo !== id));

              let targetPOP = res.data.data.filter(
                (pop) => pop?.popRegNo === id
              );
              setFormObj((data) => ({
                ...data,
                popChngtargetedPop: targetPOP[0]?.name,
                popChngtargetedPopRegNo: targetPOP[0]?.popRegNo,
              }));
            }
          })
          .catch((error) =>
            dispatch(
              setAlertMsg({
                msg: error?.response.data.message,
                alertType: "danger",
              })
            )
          );
      }
    };

    useEffect(() => {
      setFormObj((value) => ({
        ...value,
        fundA: fundA,
        fundG: fundG,
        fundC: fundC,
        fundE: fundE,
      }));
      if (
        Number(fundA) + Number(fundG) + Number(fundC) + Number(fundE) !== 100 &&
        !formObj.autoChoiceLc &&
        formObj.investmentOption === "Active Choice" &&
        activeStep === 3
      ) {
        dispatch(
          setAlertMsg({
            msg: `The sum of all funds should be equal to 100.`,
            alertType: "warning",
          })
        );
      }
    }, [fundA, fundC, fundE, fundG]);

    //  set address same as company head office
    useEffect(() => {
      //   if scheme preferences auto then empty funds field
      if (formObj.investmentOption === "Active Choice") {
        setFormObj((data) => {
          const { autoChoiceLc, ...updatedData } = data;
          return {
            ...updatedData,
          };
        });
        setfundA(formObj.fundA ? formObj.fundA : "0");
        setfundC(formObj.fundC ? formObj.fundC : "25");
        setfundE(formObj.fundE ? formObj.fundE : "50");
        setfundG(formObj.fundG ? formObj.fundG : "25");
      } else {
        setfundA("");
        setfundC("");
        setfundE("");
        setfundG("");
        setFormObj((data) => ({
          ...data,
          autoChoiceLc: "LC50",
        }));
      }

      if (formObj.schemePreference === "Subscriber") {
        setFormObj((data) => ({
          ...data,
          autoChoiceLc: "",
          pfmSelection: "",
          investmentOption: "",
        }));
        setfundA("");
        setfundC("");
        setfundE("");
        setfundG("");
      } else if (formObj.schemePreference !== "Subscriber") {
        setFormObj((data) => ({
          ...data,
          pfmSelection: formObj.pfmSelection || pfmOptions[0],
        }));
      }
    }, [
      formObj.isPopShift,
      formObj.investmentOption,
      formObj.schemePreference,
      formObj.accountNumber,
      formObj.ifscNumber,
    ]);

    useEffect(() => {
      setFormObj((data) => ({
        ...data,
        npsEnrollEndDate: formObj?.npsEnrollEndDate || "",
      }));
    }, [formObj.npsEnrollStartDate]);

    useEffect(() => {
      // for head office district

      if (formObj?.hoState) {
        handleGetDistrict(formObj?.hoState, "hoDistrict");
      }
    }, [formObj?.hoState]);

    useEffect(() => {
      // for nodal officer district
      if (formObj?.noState) {
        handleGetDistrict(formObj?.noState, "noDistrict");
      }
    }, [formObj?.noState]);

    useEffect(() => {
      // for alternate nodal officer district
      if (formObj?.anoState) {
        handleGetDistrict(formObj?.anoState, "anoDistrict");
      }
    }, [formObj?.anoState]);

    useEffect(() => {
      if (formObj.isNoAddSameAsCorp !== "") {
        setFormObj((data) => ({
          ...data,
          noFlatNo: formObj.hoFlatNo,
          noBuildingName: formObj.hoBuildingName,
          noArea: formObj.hoArea,
          noDistrict: formObj.hoDistrict,
          noState: formObj.hoState,
          noPinCode: formObj.hoPinCode,
        }));
      }

      if (formObj.isAnoAddSameAsCorp !== "") {
        setFormObj((data) => ({
          ...data,
          anoFlatNo: formObj.hoFlatNo,
          anoBuildingName: formObj.hoBuildingName,
          anoArea: formObj.hoArea,
          anoDistrict: formObj.hoDistrict,
          anoState: formObj.hoState,
          anoPinCode: formObj.hoPinCode,
        }));
      }

      // if spoc and no is same person
      if (formObj.isNoSameAsSpoc?.length > 0) {
        setFormObj((data) => ({
          ...data,
          noFirstName: formObj.corpSpocName,
          noMobileNumber: formObj.corpSpocMobile,
          noEmailId: formObj.corpSpocEmail,
        }));
      }
    }, [currentTab === 3]);

    useEffect(() => {
      if (
        formObj?.hoState &&
        formObj.gstno.length === 15 &&
        activeStep === 2 &&
        currentTab === 0
      ) {
        checkStateWithGST({ gst: formObj?.gstno })
          .then((res) => {
            if (res.data.success === true) {
              if (
                res.data?.data?.stateData?.name.toLocaleUpperCase() !==
                formObj?.hoState.toLocaleUpperCase()
              ) {
                dispatch(
                  setAlertMsg({
                    msg: "State and GST do not match",
                    alertType: "warning",
                  })
                );
              }
            }
          })
          .catch((err) => {
            dispatch(
              setAlertMsg({
                msg: "State and GST do not match",
                alertType: "warning",
              })
            );
          });
      }
    }, [formObj?.hoState, formObj.gstno]);

    const handleGetDistrict = (value, key) => {
      let stateId = allStates?.filter((state) => {
        if (state.name === value) {
          return state?.id;
        }
      });
      if (stateId[0]?.id) {
        getAllDistricts(stateId[0]?.id)
          .then((res) => {
            if (res.data.success === true) {
              setAllDistrict((district) => ({
                ...district,
                [key]: res?.data?.data,
              }));
            }
          })
          .catch((error) => {
            dispatch(
              setAlertMsg({
                msg: error.response?.data.message,
                alertType: "warning",
              })
            );
          });
      }
    };

    // handle dropdown type inputs
    const handleDropDown = (e, field) => {
      setFormObj((data) => ({
        ...data,
        [field.accessor]: e.target.value,
      }));

      //  when cra change clear existing pop name and no.
      if (field.accessor === "craName") {
        handleAllPOP(e.target.value);
        setFormObj((data) => ({
          ...data,
          popChngExistPop: "",
          popChngExistPopRegNo: "",
          popChngtargetedPop: "",
          popChngtargetedPopRegNo: "",
        }));
      }

      //  change existing pop number based on pop name
      if (field.accessor === "popChngExistPop") {
        let pop = allPOPS.filter((pop) => {
          if (pop.name === e.target.value) {
            return pop?.popRegNo;
          }
        });
        if (pop.length > 0) {
          setFormObj((data) => ({
            ...data,
            popChngExistPopRegNo: pop[0]?.popRegNo,
          }));
        }
      }

      if (
        field.accessor === "contriChargesSelection" &&
        e.target.value === "₹ 30"
      ) {
        setFormObj((data) => ({
          ...data,
          contriCharges: "30",
        }));
      } else if (
        field.accessor === "contriChargesSelection" &&
        e.target.value !== "₹ 30"
      ) {
        setFormObj((data) => ({
          ...data,
          contriCharges: "",
        }));
      }

      if (e.target?.value === "Yes" && field.accessor === "standardCharge") {
        getFromChargesFromMaster().then((res) => {
          if (res.data.success === true) {
            const resultObject = {};

            res.data?.data?.forEach((item) => {
              if (
                item.corpFieldName !== "contriCharges" &&
                item.corpFieldName !== "__proto__" &&
                item.corpFieldName !== "constructor"
              ) {
                resultObject[item.corpFieldName] = item.value;
              }
            });

            setFormObj((data) => ({
              ...data, // Spread the existing form object
              ...resultObject, // Spread the resultObject
              contriCharges: "autoContriCharges", //
              contriChargesSelection: "",
            }));
          }
        });
      } else if (
        e.target?.value !== "Yes" &&
        field.accessor === "standardCharge"
      ) {
        // If standardCharge is "No", reset the relevant fields
        setFormObj((data) => ({
          ...data,
          acOpeningCharge: "",
          superContribCharges: "",
          contriCharges: "",
          nonFinTransactions: "",
        }));
      }
    };

    //  validate number type inputs length
    const reCheckCustomNumberFunction = (e, max, key) => {
      const val = e?.target?.value?.length;
      const maxValue = max;

      const prevValue = e.target.previousValue || "";

      if (val > maxValue) {
        e.preventDefault();
        e.target.value = prevValue;
      }

      e.target.previousValue = e.target.value;
      return e;
    };

    const chargesArray = [
      "superContribCharges",
      "acOpeningCharge",
      "nonFinTransactions",
      "contriCharges",
    ];
    //  validate number type inputs
    const handleNumberInput = (e, max, key) => {
      //  if max limit is present
      if (e.target.value?.length <= max) {
        if (chargesArray.includes(key)) {
          setFormObj((data) => ({
            ...data,
            [key]: Utils.chargesRegex(e),
          }));
        } else {
          if (
            key === "expectedTotalNewJoineesForNps" ||
            key === "totalEmployeesAbove10L"
          ) {
            setFormObj((data) => ({
              ...data,
              [key]:
                e.target.value <= Number(formObj?.totalEmployees)
                  ? Utils.PreventSpecialCharacterForNumber(e)
                  : formObj?.[key],
            }));
          } else if (key === "totalEmployees") {
            setFormObj((data) => ({
              ...data,
              expectedTotalNewJoineesForNps: "",
              totalEmployeesAbove10L: "",
              [key]: Utils.PreventSpecialCharacterForNumber(e),
            }));
          } else {
            setFormObj((data) => ({
              ...data,
              [key]: Utils.PreventSpecialCharacterForNumber(e),
            }));
          }
        }
      }
    };

    const upperCaseFields = ["gst", "cin", "ifsc", "pan", "tan", "email"];

    //  handle form field input
    const handleChange = (e, key, validType, max, inputType) => {
      let specialNumbers = ["gst", "cin", "ifsc", "pan", "tan"];

      if (
        (validType === "contact" ||
          validType === "retirementAge" ||
          validType === "pinCode") &&
        inputType === "number"
      ) {
        e = reCheckCustomNumberFunction(e, max, key);
      }

      if (specialNumbers.includes(validType)) {
        setFormObj((data) => ({
          ...data,
          [key]: Utils.PreventSpecialCharacter(e),
        }));
      }
      //  email
      else if (validType === "email") {
        setFormObj((data) => ({
          ...data,
          [key]:
            e.target.value?.length <= 90 ? Utils.emailRegex(e) : formObj[key],
        }));
      }
      // for fields with only alphabetic characters
      else if (validType === "text") {
        setFormObj((data) => ({
          ...data,
          [key]: Utils.validateText(e),
        }));
      } else if (validType === "name") {
        setFormObj((data) => ({
          ...data,
          [key]: Utils.nameRegex(e),
        }));
      } else if (validType === "address") {
        setFormObj((data) => ({
          ...data,
          [key]: Utils.PreventSpecialCharacterForAddress(e),
        }));
      } else if (inputType === "number") {
        handleNumberInput(e, max, key);
      } else if (validType === "date") {
        setFormObj((data) => ({
          ...data,
          [key]: Utils.dateRegex(e),
        }));
      } else {
        setFormObj((data) => ({
          ...data,
          [key]: Utils.PreventSpecialCharacterForAddress(e),
        }));
      }

      //  during onchange validating values
      const validationFunctions = {
        email: Utils.validateEmail,
        pan: Utils.validatePan,
        contact: Utils.validateContact,
        acc: Utils.validateAccount,
        ifsc: Utils.validateIFSC,
        cin: Utils.validateCIN,
        gst: Utils.validateGST,
        tan: Utils.validateTan,
        pinCode: Utils.validatePinCode,
        retirementAge: Utils.validateRetirementAge,
        name: Utils.validateName,
        date: Utils.validateDate,
        address: Utils.validateAddress,
      };

      const validationFunction = validationFunctions[validType];
      if (validationFunction) {
        if (validType === "email") {
          setValidateInputField((data) => ({
            ...data,
            [key]: validationFunction(
              e.target.value?.length <= 90 ? Utils.emailRegex(e) : formObj[key]
            ),
          }));
        } else if (validType !== "date") {
          setValidateInputField((data) => ({
            ...data,
            [key]: validationFunction(
              Utils.PreventSpecialCharacter(e).toUpperCase(),
              formObj.panNumber
            ),
          }));
        } else {
          setValidateInputField((data) => ({
            ...data,
            [key]: validationFunction(e.target.value),
          }));
        }
      }

      // get data from area pin code
      if (validType === "pinCode") {
        let accessor =
          key === "hoPinCode" ? "ho" : key === "noPinCode" ? "no" : "ano";
        if (
          Utils.validatePinCode(e.target.value).valid &&
          e.target.value.length > 0
        ) {
          CorporateUtils.handleAreaPinCode(
            e.target.value,
            accessor,
            setFormObj,
            dispatch,
            setAllStates,
            setIsNoPinCode,
            setIsAnoPinCode,
            setIsHoPinCode
          );
        }
      }
    };

    // submit corporate form
    const handleSubmitCorporateForm = (action, next) => {
      const newForm = {
        ...formObj,
        panNumber: panNum,
        isAnoAddSameAsCorp:
          formObj.isAnoAddSameAsCorp === "Same as Company" ? "true" : "false",
        isNoAddSameAsCorp:
          formObj.isNoAddSameAsCorp === "Same as Company" ? "true" : "false",
        contriCharges:
          formObj?.standardCharge === "Yes"
            ? "autoContriCharges"
            : formObj?.contriCharges,
      };
      // { corpData: newForm, autoPopulate: autoPopulateAccessor }
      registerCorporate(
        { corpFormData: newForm, autoPopulate: autoPopulateAccessor },
        corpId,
        action
      )
        .then((res) => {
          if (res.data) {
            if (res.data.success === true) {
              //  when submitting form
              if (action === "submit") {
                setActiveStep(5);
                localStorage.setItem("currentStep", JSON.stringify(5));
                localStorage.removeItem("currentSubTab");
                dispatch(
                  setAlertMsg({
                    msg: `Please get the form digitally signed from the corporate and proceed.`,
                    alertType: "success",
                  })
                );
              } else if (action === "save") {
                // when saving form exit
                navigate(-1);
                dispatch(
                  setAlertMsg({
                    msg: "Form has been saved",
                    alertType: "success",
                  })
                );
              }

              //  if next parameter is present, then move next
            }
          }
        })
        .catch((error) => {
          setIsPreview(false);
          dispatch(
            setAlertMsg({
              msg: error?.response?.data?.message || "Internal Server Error",
              alertType: "danger",
            })
          );
        });
    };

    const handleCurrentTabClick = (tabValue) => {
      setCurrentTab(tabValue);
      if (tabValue > useRefCurrentTab.current) {
        let errorObj = checkRequiredFieldsTabClick(
          activeStep,
          tabValue > 0 ? tabValue - 1 : 0,
          formObj
        );
        setStepperErrorArray(errorObj.stepperError);
        setFormSectionError(errorObj.sectionError);
        setInputFieldsError(errorObj.fieldsError);
      }
      localStorage.setItem("currentSubTab", JSON.stringify(tabValue));
      useRefCurrentTab.current = tabValue;
    };

    const checkRequiredFieldsTabClick = (checkTill, checkTillTab, formObj) => {
      const stepperErrorArray = [];
      const formSectionErrorArray = [];
      const tabErrorArray = [];

      for (let index = 0; index < checkTill; index++) {
        const config = ConfigObj[index];

        if (config.section) {
          config.section.forEach((section, sectionIndex) => {
            if (sectionIndex <= checkTillTab) {
              return section.Sub_section.some((subSection) => {
                const subSectionErrors = subSection.Fields.filter((field) => {
                  return CorporateUtils.isFieldInvalid(
                    formObj,
                    field,
                    validateInputField
                  );
                }).map((field) => ({
                  section_id: subSection.section_id,
                  field_accessor: field.accessor,
                }));

                if (subSectionErrors.length > 0) {
                  if (!tabErrorArray.includes(section.title)) {
                    tabErrorArray.push(section.title);
                  }
                  if (!stepperErrorArray.includes(index)) {
                    stepperErrorArray.push(index);
                  }
                  formSectionErrorArray.push(...subSectionErrors);
                }

                return false;
              });
            }
          });
        }
      }

      if (
        formObj?.saCorpName === formObj?.saHdfcName &&
        formObj?.saCorpPlace === formObj?.saHdfcPlace &&
        formObj?.saCorpDesig === formObj?.saHdfcDesig &&
        formObj?.saCorpDept === formObj?.saHdfcDept &&
        activeStep === 4
      ) {
        stepperErrorArray.push(3);
        formSectionErrorArray.push({
          section_id: 7.1,
          field_accessor: "",
        });
        formSectionErrorArray.push({
          section_id: 7.2,
          field_accessor: "",
        });
        dispatch(
          setAlertMsg({
            msg: "Signing Authority : Corporate And HDFC Pension fields cannot be same",
            alertType: "warning",
          })
        );
      }

      // Nodal officer and alternate nodal officer should not be same
      if (activeStep > 1 && currentTab === 3) {
        if (
          formObj.noMobileNumber === formObj?.altNoMobileNumber ||
          formObj.noEmailId?.toUpperCase() ===
            formObj?.altNoEmailId?.toUpperCase()
        ) {
          stepperErrorArray.push(1);
          formSectionErrorArray.push({
            section_id: 5.1,
            field_accessor: "noMobileNumber",
          });
          formSectionErrorArray.push({
            section_id: 5.2,
            field_accessor: "noEmailId",
          });
          dispatch(
            setAlertMsg({
              msg: "Nodal Officer and Alternate Nodal Officer must be different Details",
              alertType: "warning",
            })
          );
        }
      }

      // If assets allocation is less than 100 show error
      if (
        activeStep > 2 &&
        currentTab === 1 &&
        Number(fundA) + Number(fundG) + Number(fundC) + Number(fundE) !== 100 &&
        formObj.investmentOption === "Active Choice"
      ) {
        stepperErrorArray.push(2);
        formSectionErrorArray.push({
          section_id: 6.3,
          field_accessor: "investmentOption",
        });
        dispatch(
          setAlertMsg({
            msg: `The sum of all funds should be equal to 100.`,
            alertType: "warning",
          })
        );
      }

      if (tabErrorArray.length > 0) {
        dispatch(
          setArrayOfAlertMsg([
            {
              msg: `Details missing in section: ${tabErrorArray.join(", ")}`,
              alertType: "warning",
            },
          ])
        );
      }

      return {
        stepperError: stepperErrorArray,
        sectionError: [
          ...new Set(
            formSectionErrorArray.map((section) => section?.section_id)
          ),
        ],
        fieldsError: [
          ...new Set(
            formSectionErrorArray.map((field) => field?.field_accessor)
          ),
        ],
      };
    };

    const handleClickBreadCrumb = (stepValue) => {
      if (stepValue > useRefCurrentStep.current) {
        let errorObj = checkRequiredFields(stepValue - 1);
        setStepperErrorArray(errorObj.stepperError);
        setFormSectionError(errorObj.sectionError);
        setInputFieldsError(errorObj.fieldsError);
        handleSubmitCorporateForm("none", "next");
      }
      localStorage.setItem("currentStep", JSON.stringify(stepValue + 1));
      localStorage.setItem("currentSubTab", JSON.stringify(0));
      useRefCurrentStep.current = stepValue;
    };

    const handleNextButton = () => {
      setIsOpenAccordion(0);

      // if no error found in current tab
      if (ConfigObj[activeStep - 1].section.length <= currentTab + 1) {
        let errorObj = checkRequiredFields(activeStep);
        setStepperErrorArray(errorObj.stepperError);
        setFormSectionError(errorObj.sectionError);
        setInputFieldsError(errorObj.fieldsError);

        useRefCurrentStep.current = activeStep + 1;
        useRefCurrentTab.current = 0;
        setActiveStep((pre) => pre + 1);
        setCurrentTab(0);
        localStorage.setItem("currentStep", JSON.stringify(activeStep + 1));
        localStorage.setItem("currentSubTab", JSON.stringify(0));
        handleSubmitCorporateForm("none", "next");

        //  save it to local storage
      } else {
        let errorObj = checkRequiredFieldsTabClick(
          activeStep,
          currentTab,
          formObj
        );
        setStepperErrorArray(errorObj.stepperError);
        setFormSectionError(errorObj.sectionError);
        setInputFieldsError(errorObj.fieldsError);
        setCurrentTab(currentTab + 1);
        useRefCurrentTab.current = currentTab + 1;
        localStorage.setItem("currentSubTab", JSON.stringify(currentTab + 1));
        handleSubmitCorporateForm("none", "next");
      }
    };

    const handlePreviousStep = () => {
      if (currentTab > 0) {
        setCurrentTab((curr) => curr - 1);
        localStorage.setItem("currentSubTab", JSON.stringify(currentTab - 1));
      } else {
        setActiveStep((pre) => pre - 1);
        setCurrentTab(ConfigObj[activeStep - 2].section.length - 1);
        localStorage.setItem("currentStep", JSON.stringify(activeStep - 1));
        localStorage.setItem(
          "currentSubTab",
          JSON.stringify(ConfigObj[activeStep - 2].section.length - 1)
        );
      }
    };

    // Updated function
    const checkRequiredFields = (checkTill, checkTillTab) => {
      const stepperErrorArray = [];
      const formSectionErrorArray = [];
      const tabErrorArray = [];

      for (let index = 0; index < checkTill; index++) {
        const config = ConfigObj[index];

        if (config.section) {
          config.section.some((section, sectionIndex) => {
            return section.Sub_section.some((subSection) => {
              const subSectionErrors = subSection.Fields.filter((field) => {
                return CorporateUtils.isFieldInvalid(
                  formObj,
                  field,
                  validateInputField
                );
              }).map((field) => ({
                section_id: subSection.section_id,
                field_accessor: field.accessor,
              }));

              if (subSectionErrors.length > 0) {
                if (!tabErrorArray.includes(section.title)) {
                  tabErrorArray.push(section.title);
                }
                if (!stepperErrorArray.includes(index)) {
                  stepperErrorArray.push(index);
                }
                formSectionErrorArray.push(...subSectionErrors);
              }

              return false;
            });
          });
        }
      }

      if (
        formObj?.saCorpName === formObj?.saHdfcName &&
        formObj?.saCorpPlace === formObj?.saHdfcPlace &&
        formObj?.saCorpDesig === formObj?.saHdfcDesig &&
        formObj?.saCorpDept === formObj?.saHdfcDept &&
        activeStep === 4
      ) {
        stepperErrorArray.push(3);
        formSectionErrorArray.push({
          section_id: 7.1,
          field_accessor: "",
        });
        formSectionErrorArray.push({
          section_id: 7.2,
          field_accessor: "",
        });
        dispatch(
          setAlertMsg({
            msg: "Signing Authority : Corporate And HDFC Pension fields cannot be same",
            alertType: "warning",
          })
        );
      }

      // Nodal officer and alternate nodal officer should not be same
      if (activeStep > 1 && currentTab === 3) {
        if (
          formObj.noMobileNumber === formObj?.altNoMobileNumber ||
          formObj.noEmailId?.toUpperCase() ===
            formObj?.altNoEmailId?.toUpperCase()
        ) {
          stepperErrorArray.push(1);
          formSectionErrorArray.push({
            section_id: 5.1,
            field_accessor: "noMobileNumber",
          });
          formSectionErrorArray.push({
            section_id: 5.2,
            field_accessor: "noEmailId",
          });
          dispatch(
            setAlertMsg({
              msg: "Nodal Officer and Alternate Nodal Officer must be different Details",
              alertType: "warning",
            })
          );
        }
      }

      // If assets allocation is less than 100 show error
      if (
        activeStep > 2 &&
        currentTab === 1 &&
        Number(fundA) + Number(fundG) + Number(fundC) + Number(fundE) !== 100 &&
        formObj.investmentOption === "Active Choice"
      ) {
        stepperErrorArray.push(2);
        formSectionErrorArray.push({
          section_id: 6.3,
          field_accessor: "investmentOption",
        });
        dispatch(
          setAlertMsg({
            msg: `The sum of all funds should be equal to 100.`,
            alertType: "warning",
          })
        );
      }

      if (tabErrorArray.length > 0) {
        dispatch(
          setArrayOfAlertMsg([
            {
              msg: `Details missing in section: ${tabErrorArray.join(", ")}`,
              alertType: "warning",
            },
          ])
        );
      }

      return {
        stepperError: stepperErrorArray,
        sectionError: [
          ...new Set(
            formSectionErrorArray.map((section) => section?.section_id)
          ),
        ],
        fieldsError: [
          ...new Set(
            formSectionErrorArray.map((field) => field?.field_accessor)
          ),
        ],
      };
    };

    const handleChangeAccordion = (index) => {
      if (index === isOpenAccordion) {
        setIsOpenAccordion("");
      } else {
        setIsOpenAccordion(index);
      }
    };

    //  handle submit and preview
    const handleSubmitPreview = () => {
      handleSubmitCorporateForm("none");

      //  check if any mandate field left empty then return
      let errorObj = checkRequiredFields(activeStep);
      if (errorObj.stepperError.length > 0) {
        setStepperErrorArray(errorObj.stepperError);
        setFormSectionError(errorObj.sectionError);
        setInputFieldsError(errorObj.fieldsError);

        return;
      }

      const newForm = {
        ...formObj,
        panNumber: panNum,
        isAnoAddSameAsCorp:
          formObj.isAnoAddSameAsCorp === "Same as Company" ? "true" : "false",
        isNoAddSameAsCorp:
          formObj.isNoAddSameAsCorp === "Same as Company" ? "true" : "false",
      };
      // { corpData: newForm, autoPopulate: autoPopulateAccessor }
      registerCorporate(
        { corpFormData: newForm, autoPopulate: autoPopulateAccessor },
        corpId,
        "save"
      )
        .then((res) => {
          if (res.data) {
            if (res.data.success === true) {
              // preview form after saving form
              setStepperErrorArray([]);
              setFormSectionError([]);
              corporatePreviewForm(corpId).then((res) => {
                const fileUrl = URL.createObjectURL(res.data);
                setPreviewData(fileUrl);
                setIsPreview(true);
              });
            }
          }
        })
        .catch((error) => {
          setIsPreview(false);
          dispatch(
            setAlertMsg({
              msg: error?.response.data.message,
              alertType: "danger",
            })
          );
        });
    };

    // edit accessor
    const handleEditAccessor = (accessor) => {
      setAutoPopulateAccessor(
        autoPopulateAccessor.filter((value) => {
          return value !== accessor;
        })
      );
    };

    // pie chart assets range
    const handleRangeValues = (accessor1, accessor2, accessor3, value) => {
      if (
        Number(accessor1) +
          Number(accessor2) +
          Number(accessor3) +
          Number(value) !==
        0
      ) {
        let maxValue = 100 - accessor1 - accessor2 - accessor3;
        return maxValue > value ? value?.toString() : maxValue?.toString();
      } else {
        dispatch(
          setAlertMsg({
            msg: "Sum of all assets cannot be empty",
            alertType: "warning",
          })
        );
        return 10;
      }
    };

    const handleChangeRange = (e) => {
      let { name, value } = e?.target;
      switch (name) {
        case "setfundC":
          setfundC(handleRangeValues(fundA, fundE, fundG, value));
          break;

        case "setfundA":
          setfundA(handleRangeValues(fundC, fundE, fundG, value));
          break;

        case "setfundE":
          setfundE(handleRangeValues(fundA, fundC, fundG, value));
          break;

        case "setfundG":
          setfundG(handleRangeValues(fundA, fundE, fundC, value));
          break;

        default:
          break;
      }
    };

    // custom css class for  fields
    const customCssClass = (accessor, tag) => {
      if (
        accessor === "npsEnrollMonth" ||
        accessor === "npsEnrollStartDate" ||
        accessor === "npsEnrollEndDate"
      ) {
        return "threeColumnClass";
      } else {
        return "";
      }
    };

    // hide section of form
    const hideFormSection = (title) => {
      if (title === "Charge Structure" && formObj.formfiller !== "rm")
        return false;
      else if (title) return true;
      else return false;
    };

    // handle input special symbol
    const handleSpecialInput = (accessor) => {
      if (accessor === "contriCharges" && formObj?.standardCharge === "Yes") {
        return <p></p>;
      } else if (
        accessor === "contriCharges" &&
        formObj?.standardCharge === "No" &&
        formObj?.contriChargesSelection === "Percentage"
      ) {
        return "%";
      } else if (
        accessor === "contriCharges" &&
        formObj?.standardCharge === "No" &&
        formObj?.contriChargesSelection !== "Percentage"
      ) {
        return "₹";
      } else {
        return "";
      }
    };

    //handle min value of input field
    const handleMinValue = (field) => {
      if (field === "current date") {
        var currentDate = new Date();

        currentDate.setDate(currentDate.getDate() + 1);

        return currentDate.toJSON().slice(0, 10);
      }
      return formObj[field];
    };

    const handleDescription = (accessor, description) => {
      let chargesArray = [
        "acOpeningCharge",
        "superContribCharges",
        "nonFinTransactions",
      ];
      if (
        formObj?.standardCharge === "Yes" &&
        chargesArray.includes(accessor)
      ) {
        return "";
      }
      if (formObj?.standardCharge === "Yes" && accessor === "contriCharges") {
        return Consts.Corporate_form_contribution_charge;
      }

      return description;
    };

    const handleChangeCheckbox = (e, accessor, option) => {
      formObj[accessor] === option
        ? setFormObj((data) => ({
            ...data,
            [accessor]: "",
          }))
        : setFormObj((data) => ({
            ...data,
            [accessor]: option,
          }));

      if (accessor === "isNoAddSameAsCorp" && e.target.checked === true) {
        setFormObj((data) => ({
          ...data,
          noFlatNo: formObj.hoFlatNo,
          noBuildingName: formObj.hoBuildingName,
          noArea: formObj.hoArea,
          noDistrict: formObj.hoDistrict,
          noState: formObj.hoState,
          noPinCode: formObj.hoPinCode,
        }));
      } else if (
        accessor === "isNoAddSameAsCorp" &&
        e.target.checked === false
      ) {
        setFormObj((data) => ({
          ...data,
          noFlatNo: "",
          noBuildingName: "",
          noArea: "",
          noDistrict: "",
          noState: "",
          noPinCode: "",
        }));
      }

      if (accessor === "isAnoAddSameAsCorp" && e.target.checked === true) {
        setFormObj((data) => ({
          ...data,
          anoFlatNo: formObj.hoFlatNo,
          anoBuildingName: formObj.hoBuildingName,
          anoArea: formObj.hoArea,
          anoDistrict: formObj.hoDistrict,
          anoState: formObj.hoState,
          anoPinCode: formObj.hoPinCode,
        }));
      } else if (
        accessor === "isAnoAddSameAsCorp" &&
        e.target.checked === false
      ) {
        setFormObj((data) => ({
          ...data,
          anoFlatNo: "",
          anoBuildingName: "",
          anoArea: "",
          anoDistrict: "",
          anoState: "",
          anoPinCode: "",
        }));
      }

      // if spoc and no is same person
      if (accessor === "isNoSameAsSpoc" && e.target.checked === true) {
        setFormObj((data) => ({
          ...data,
          noFirstName: formObj.corpSpocName,
          noMobileNumber: formObj.corpSpocMobile,
          noEmailId: formObj.corpSpocEmail,
        }));
      } else if (accessor === "isNoSameAsSpoc" && e.target.checked === false) {
        setFormObj((data) => ({
          ...data,
          noFirstName: "",
          noMobileNumber: "",
          noEmailId: "",
        }));
      }
    };

    const handleChangeRadioButton = (e, accessor, option) => {
      setFormObj((data) => ({
        ...data,
        [accessor]:
          accessor === "isPopShift"
            ? option === "POP Shift"
              ? "true"
              : "false"
            : option,
      }));

      if (accessor === "isPopShift") {
        setFormObj((data) => ({
          ...data,
          popChngChoNum: "",
          popChngExistPop: "",
          popChngExistPopRegNo: "",
        }));
      }

      if (accessor === "npsEnrollWindow") {
        setFormObj((data) => ({
          ...data,
          npsEnrollEndDate: "",
          npsEnrollMonth: "",
          npsEnrollStartDate: "",
        }));
      }
    };

    return (
      <>
        <div className={classes.form_container}>
          <div className={classes.form}>
            <div className={classes.form_box}>
              <div className={classes.currentTab_title}>
                <h3 className={classes.header}>
                  {ConfigObj[activeStep - 1]?.header}
                </h3>
              </div>
              {ConfigObj[activeStep - 1]?.section.length > 1 && (
                <TabComp
                  tabValue={currentTab}
                  label={ConfigObj[activeStep - 1]?.section}
                  formSectionErrorArray={formSectionError}
                  clickAble={true}
                  setTabValue={handleCurrentTabClick}
                />
              )}
              {ConfigObj[activeStep - 1]?.section[currentTab]?.Sub_section.map(
                (section, index) => {
                  return (
                    hideFormSection(section.title) && (
                      <Accordion
                        key={index}
                        sx={{
                          borderRadius: ".4rem",
                          border: "1px solid var(--app-btn-background)",
                          marginBottom: "1.5rem",
                          borderColor:
                            isOpenAccordion === index
                              ? " var(--app-btn-background)"
                              : "var(--app-text-secondary-color)",
                        }}
                        expanded={isOpenAccordion === index}
                        onChange={() => handleChangeAccordion(index)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography
                            sx={{
                              fontWeight: "600",
                              color: "var(--app-btn-secondary-text)",
                              paddingLeft: "3%",
                            }}
                          >
                            {section.title}
                            {formSectionError.includes(section.section_id) && (
                              <img
                                className={classes.error_icon}
                                src={ErrorIcon}
                                alt="error_icon"
                              />
                            )}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography component={"span"}>
                            <div className={classes.form_fields_container}>
                              {section.Fields.map((field, fieldIndex) => {
                                return (
                                  <div
                                    className={`${classes.fieldscontainer}  ${
                                      classes[
                                        customCssClass(
                                          field.accessor,
                                          field.tag
                                        )
                                      ]
                                    }`}
                                    key={fieldIndex + field.accessor}
                                    style={{
                                      display:
                                        CorporateUtils.handleShowAndHideFields(
                                          field.accessor,
                                          formObj,
                                          SectorArray
                                        )
                                          ? "none"
                                          : "",
                                    }}
                                  >
                                    <label>
                                      {field.is_mandatory ? (
                                        <>
                                          {field.label}
                                          <span>*</span>
                                          {field.readMore && (
                                            <a
                                              className={classes.readMore}
                                              href={field.readMore}
                                              target="_blank"
                                              rel="noopener"
                                            >
                                              <img
                                                className={classes.linkIcon}
                                                src={linkIcon}
                                                alt="link"
                                              />
                                            </a>
                                          )}
                                        </>
                                      ) : (
                                        field.label
                                      )}
                                      {autoPopulateAccessor.includes(
                                        field.accessor
                                      ) && (
                                        <button
                                          onClick={() =>
                                            handleEditAccessor(field.accessor)
                                          }
                                        >
                                          <img src={PenIcon} />
                                        </button>
                                      )}
                                    </label>
                                    {field.tag === "input" && (
                                      <div className={classes.input_box}>
                                        {CorporateUtils.handleExceptionInputs(
                                          field.accessor,
                                          formObj,
                                          field,
                                          validateInputField,
                                          inputFieldsError,
                                          setFormObj,
                                          allStates,
                                          allDistrict,
                                          isHoPinCode,
                                          isNoPinCode,
                                          isAnoPinCode
                                        ) || (
                                          <input
                                            style={{
                                              textTransform:
                                                upperCaseFields.includes(
                                                  field.validType
                                                )
                                                  ? "uppercase"
                                                  : "inherit",
                                            }}
                                            onKeyDown={(e) => {
                                              if (
                                                field.type === "number" &&
                                                !chargesArray.includes(
                                                  field.accessor
                                                ) &&
                                                numberInputInvalidChars.includes(
                                                  e.key
                                                )
                                              ) {
                                                e.preventDefault();
                                              }
                                            }}
                                            className={`${
                                              CorporateUtils.handleFieldError(
                                                field?.accessor,
                                                formObj,
                                                validateInputField,
                                                inputFieldsError
                                              )
                                                ? classes.input_empty
                                                : classes.input
                                            }`}
                                            type={field.type}
                                            value={
                                              formObj[field.accessor] || ""
                                            }
                                            onInput={(e) =>
                                              handleChange(
                                                e,
                                                field.accessor,
                                                field.validType,
                                                field.max,
                                                field.type
                                              )
                                            }
                                            placeholder={field.placeholder}
                                            disabled={
                                              field.disable ||
                                              CorporateUtils.handleDisable(
                                                field.accessor,
                                                formObj,
                                                autoPopulateAccessor,
                                                allStates
                                              )
                                            }
                                            max="2222-05-26"
                                            min={handleMinValue(field.min)}
                                          />
                                        )}
                                        {field.btn && (
                                          <PrimaryButton
                                            disabled={
                                              formObj?.isBankAccountVerified ===
                                                "true" ||
                                              validateInputField[field.accessor]
                                                ?.valid === false ||
                                              formObj[field.accessor] === ""
                                            }
                                            onClick={(e) =>
                                              CorporateUtils.handleFormBtn(
                                                e,
                                                field.accessor,
                                                formObj,
                                                dispatch,
                                                setFormObj,
                                                corpId
                                              )
                                            }
                                            label={field?.btnLabel}
                                          />
                                        )}
                                        <span className={classes.number_symbol}>
                                          {handleSpecialInput(field.accessor) ||
                                            field?.number_type}
                                        </span>
                                      </div>
                                    )}
                                    {field.tag === "dropdown" && (
                                      <select
                                        className={`${
                                          CorporateUtils.handleFieldError(
                                            field?.accessor,
                                            formObj,
                                            validateInputField,
                                            inputFieldsError
                                          )
                                            ? classes.input_empty
                                            : classes.input
                                        }`}
                                        value={formObj[field.accessor]}
                                        onChange={(e) =>
                                          handleDropDown(e, field)
                                        }
                                        disabled={
                                          field.disable ||
                                          CorporateUtils.handleDisable(
                                            field.accessor,
                                            formObj,
                                            autoPopulateAccessor,
                                            allStates
                                          )
                                        }
                                      >
                                        {field.accessor ===
                                        "pfmSelection" ? null : (
                                          <option value={""}>
                                            Select Option
                                          </option>
                                        )}
                                        {CorporateUtils.dropDownOption(
                                          field.accessor,
                                          field?.options,
                                          pfmOptions,
                                          formObj,
                                          allCRA,
                                          allPOPS
                                        )?.map((option, optionIndex) => (
                                          <option
                                            key={optionIndex}
                                            value={option}
                                          >
                                            {option}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                    {field.tag === "checkbox" && (
                                      <div
                                        className={`${
                                          CorporateUtils.handleFieldError(
                                            field?.accessor,
                                            formObj,
                                            validateInputField,
                                            inputFieldsError
                                          )
                                            ? classes.form_checkbox_empty
                                            : classes.form_checkbox
                                        }  ${
                                          checkboxAccessor.includes(
                                            field.accessor
                                          ) && classes.twoColClass
                                        }`}
                                      >
                                        {/*  when options are more then one */}
                                        {field?.options?.length !== 1
                                          ? field?.options?.map(
                                              (option, index) => (
                                                <label key={index}>
                                                  <input
                                                    disabled={
                                                      field.disable ||
                                                      CorporateUtils.handleDisable(
                                                        field.accessor,
                                                        formObj,
                                                        autoPopulateAccessor,
                                                        allStates
                                                      )
                                                    }
                                                    type="radio"
                                                    checked={
                                                      formObj[
                                                        field.accessor
                                                      ] ===
                                                      (field.accessor ===
                                                      "isPopShift"
                                                        ? option === "POP Shift"
                                                          ? "true"
                                                          : "false"
                                                        : option)
                                                    } // Check if the current option is selected
                                                    onChange={(e) =>
                                                      handleChangeRadioButton(
                                                        e,
                                                        field.accessor,
                                                        option
                                                      )
                                                    }
                                                  />
                                                  {option}
                                                </label>
                                              )
                                            )
                                          : field?.options?.map(
                                              (option, index) => (
                                                <label key={index}>
                                                  <input
                                                    type="checkbox"
                                                    value={
                                                      formObj[field.accessor]
                                                    }
                                                    checked={
                                                      formObj[
                                                        field.accessor
                                                      ] === option
                                                    }
                                                    // Check if the current option is selected
                                                    onChange={(e) => {
                                                      handleChangeCheckbox(
                                                        e,
                                                        field.accessor,
                                                        option
                                                      );
                                                    }}
                                                  />
                                                  {option}
                                                </label>
                                              )
                                            )}

                                        {field.btn &&
                                          field.accessor ===
                                            "isBankAccountVerified" && (
                                            <PrimaryButton
                                              onClick={(e) =>
                                                CorporateUtils.handleFormBtn(
                                                  e,
                                                  field.accessor,
                                                  formObj,
                                                  dispatch,
                                                  setFormObj,
                                                  corpId
                                                )
                                              }
                                              label={field?.btnLabel}
                                            />
                                          )}
                                      </div>
                                    )}
                                    {field.tag === "pieChart" && (
                                      <div
                                        className={classes.pieChart_container}
                                      >
                                        <div className={classes.pieChart}>
                                          <PieArcLabel data={pieChartObj} />
                                        </div>
                                        <div
                                          className={classes.slider_container}
                                        >
                                          <RangeInput
                                            title={"Equity (E)"}
                                            range={"Cannot exceed 75%"}
                                            value={fundE}
                                            max={75}
                                            setValue={handleChangeRange}
                                            color={pieChartObj[0].color}
                                            name={"setfundE"}
                                            inputState={setfundE}
                                          />
                                          <RangeInput
                                            title={"Corporate Bonds (C)"}
                                            range={"max (100)"}
                                            value={fundC}
                                            setValue={handleChangeRange}
                                            max={100}
                                            color={pieChartObj[1].color}
                                            name={"setfundC"}
                                            inputState={setfundC}
                                          />
                                          <RangeInput
                                            title={"Government Securities (G)"}
                                            range={"max (100)"}
                                            max={100}
                                            value={fundG}
                                            setValue={handleChangeRange}
                                            color={pieChartObj[2].color}
                                            name={"setfundG"}
                                            inputState={setfundG}
                                          />
                                          <RangeInput
                                            title={"Asset Allocation (A)"}
                                            range={"Cannot exceed  5%"}
                                            value={fundA}
                                            setValue={handleChangeRange}
                                            max={5}
                                            color={pieChartObj[3].color}
                                            name={"setfundA"}
                                            inputState={setfundA}
                                          />
                                        </div>
                                      </div>
                                    )}
                                    <span className={classes.description}>
                                      {handleDescription(
                                        field.accessor,
                                        field.description
                                      )}
                                    </span>
                                    <span className={classes.error_msg}>
                                      {(inputFieldsError.includes(
                                        field.accessor
                                      ) &&
                                        !formObj[field.accessor] &&
                                        "Field cannot be empty") ||
                                        CorporateUtils.handleInputError(
                                          field.accessor,
                                          formObj,
                                          allPOPS,
                                          inputFieldsError,
                                          validateInputField,
                                          setValidateInputField,
                                          field
                                        )}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    )
                  );
                }
              )}
            </div>

            <div className={classes.form_btn}>
              <SecondaryButton
                onClick={() => handleSubmitCorporateForm("save")}
                label={t("button.save&Exit")}
              />
              {activeStep > 1 && (
                <PrimaryButton
                  onClick={handlePreviousStep}
                  label={t("button.previous")}
                />
              )}
              {activeStep !== 4 && (
                <>
                  <PrimaryButton
                    onClick={handleNextButton}
                    label={t("button.next")}
                  />
                </>
              )}
              {activeStep === 4 && (
                <PrimaryButton
                  style={{ width: "10rem" }}
                  label={t("button.pre&submit")}
                  onClick={handleSubmitPreview}
                />
              )}
            </div>
          </div>

          {/* preview from Modal */}
          {isPreview && (
            <Modal
              onClose={() => setIsPreview(false)}
              style={{
                width: "90%",
                minWidth: "35rem",
                height: "95%",
                overflowY: "scroll",
              }}
            >
              <PreViewForm
                setIsPreview={setIsPreview}
                handleSubmitCorporateForm={handleSubmitCorporateForm}
                ref={componentRef}
                corporatePreviewData={previewData}
                showBtn={true}
              />
            </Modal>
          )}
        </div>
      </>
    );
  }
);

export default CorporateForm;
