import React from "react";
import classes from "./RangeInputWithoutMaxLimit.module.css";

function RangeInputWithoutMaxLimit({
  value,
  max,
  title,
  range,
  setValue,
  inputState,
  name,
}) {
  const handleChange = (e) => {
    if (e.target.value < max + 1) {
      inputState(e.target.value);
    } else {
      return;
    }
  };

  return (
    <div className={classes.range_container}>
        <div className={classes.percentage}>
          <input
            type="number"
            value={value ? value : "0"}
            max={max}
            min={0}
            onChange={handleChange}
          />
          %
        </div>
      <input
        type="range"
        min="00"
        max={max}
        step="1"
        value={value}
        name={name}
        onChange={(e) => setValue(e)}
        className={classes.red_slider}
      />
      <div className={classes.showMinMax}>
        <span>0</span>
        <span>{max}</span>
      </div>
    </div>
  );
}

export default RangeInputWithoutMaxLimit;
