import React from "react";
import classes from "./CorporateForm.module.css";
import {
  getAllDistricts,
  getAllStates,
  getAreaDetailsFromPinCode,
  getBankDetails,
  verifyBankDetails,
} from "../CorporateAction";
import { setAlertMsg } from "../../Home/HomeSlice";
import Utils from "../../../Utils";

const CorporateUtils = {
  // hide and show input fields
  handleShowAndHideFields: (accessor, formObj, SectorArray) => {
    if (formObj.formfiller !== "rm" && accessor === "isNoSameAsSpoc") {
      return true;
    }

    if (
      accessor === "contriChargesSelection" &&
      formObj?.standardCharge === "Yes"
    ) {
      return true;
    }

    // if corporate status is pop show fields
    const corporateStatus = [
      "popChngChoNum",
      "popChngExistPop",
      "popChngExistPopRegNo",
      "popChngtargetedPop",
      "popChngtargetedPopRegNo",
    ];

    if (formObj.isPopShift !== "true" && corporateStatus.includes(accessor)) {
      return true;
    }

    // if pop charges borne by employee disable charge invoice
    if (
      formObj?.popChargesBorneBy == "Employee" &&
      accessor === "popChargesDedCycle"
    ) {
      return true;
    }

    // if pop charges borne by hybrid
    if (
      formObj.popChargesBorneBy !== "Hybrid" &&
      (accessor === "accOpChargesBorneBy" ||
        accessor === "contriChargesBorneBy")
    )
      return true;

    // if selection scheme preference is subscriber then disable
    const schemeValues = [
      "investmentOption",
      "fundE",
      "fundC",
      "fundG",
      "fundA",
      "autoChoiceLc",
      "pfmSelection",
      "pfmLabel",
    ];
    if (
      formObj.schemePreference !== "Corporate" &&
      schemeValues.includes(accessor)
    )
      return true;

    // disable address for no and alt no same as company  address
    const nodalAdd = [
      "noFlatNo",
      "noBuildingName",
      "noArea",
      "noDistrict",
      "noState",
      "noPinCode",
    ];
    const altNodalAdd = [
      "anoFlatNo",
      "anoBuildingName",
      "anoArea",
      "anoDistrict",
      "anoState",
      "anoPinCode",
    ];
    if (formObj.isNoAddSameAsCorp && nodalAdd.includes(accessor)) return true;
    if (formObj.isAnoAddSameAsCorp && altNodalAdd.includes(accessor))
      return true;

    // if no is same as spoc then hide no
    let noDetails = [
      "noFirstName",
      "noMiddleName",
      "noLastName",
      "noMobileNumber",
      "noEmailId",
    ];

    if (formObj.isNoSameAsSpoc && noDetails.includes(accessor)) return true;

    // if no option selected then hide month , start and end dates
    const npsWindowAccessor = [
      "npsEnrollMonth",
      "npsEnrollStartDate",
      "npsEnrollEndDate",
    ];
    if (!formObj.npsEnrollWindow && npsWindowAccessor.includes(accessor))
      return true;

    // if enrollment window is monthly hide month input
    if (formObj?.npsEnrollWindow === "Monthly" && accessor === "npsEnrollMonth")
      return true;

    // if enrollment window is continuous hide start , end ,month input
    if (
      formObj?.npsEnrollWindow === "Continuous" &&
      npsWindowAccessor.includes(accessor)
    )
      return true;

    // disable option fields
    let autoOpt = ["pfmLabel"];
    let active = ["autoChoiceLc"];
    if (
      formObj.investmentOption !== "Auto Choice" &&
      active.includes(accessor)
    ) {
      return true;
    }
    if (
      formObj.investmentOption !== "Active Choice" &&
      autoOpt.includes(accessor)
    ) {
      return true;
    }

    if (
      accessor === "otherSector" &&
      (formObj.sector !== "Others" ||
        !formObj?.sector ||
        SectorArray.includes(formObj?.sector))
    ) {
      return true;
    }
  },

  // show error msg for wrong input fields
  handleInputError: (
    accessor,
    formObj,
    allPOPS,
    inputFieldsError,
    validateInputField
  ) => {
    //  check charge structure fields validation
    // if (inputFieldsError?.includes(accessor)) {
    //   return formObj[accessor] !== "" || "Field cannot be empty";
    // }

    if (
      accessor === "acOpeningCharge" &&
      (formObj[accessor] < 200 || formObj[accessor] > 400)
    ) {
      return (
        formObj?.acOpeningCharge === "" ||
        "Input limit should be between 200 and 400"
      );
    }
    if (accessor === "nonFinTransactions" && formObj?.nonFinTransactions < 30) {
      return (
        formObj?.nonFinTransactions === "" || "Non Financial minimum to 30"
      );
    }
    if (
      accessor === "superContribCharges" &&
      (formObj?.superContribCharges > 0.5 || formObj?.superContribCharges < 0.1)
    ) {
      return (
        formObj?.superContribCharges === "" ||
        "Charges should be between 0.1% to 0.5%"
      );
    }

    if (
      accessor === "contriCharges" &&
      formObj?.contriChargesSelection === "Percentage" &&
      (formObj?.contriCharges > 0.5 || formObj?.contriCharges < 0.1)
    ) {
      return (
        formObj?.contriCharges === "" ||
        "Charges should be between 0.1% to 0.5%"
      );
    }

    //  check charge structure fields validation
    if (accessor === "popChngExistPop" && allPOPS.length === 0) {
      return "Please select CRA Name";
    }

    if (accessor === "popChngChoNum" && formObj?.popChngChoNum?.length < 7) {
      return formObj?.popChngChoNum === "" || "Enter valid CHO number";
    }

    if (accessor === "totalEmployees" && formObj[accessor] < 1) {
      return (
        formObj[accessor] === "" || "Total number of Employees cannot be zero"
      );
    }

    if (
      (accessor === "noEmailId" || accessor === "altNoEmailId") &&
      formObj.noEmailId?.toUpperCase() ===
        formObj?.altNoEmailId?.toUpperCase() &&
      formObj[accessor] !== ""
    ) {
      return "Nodal officer and Alternate Nodal officer cannot have the same Email Id";
    }
    if (
      (accessor === "noMobileNumber" || accessor === "altNoMobileNumber") &&
      formObj.noMobileNumber === formObj?.altNoMobileNumber &&
      formObj[accessor]
    ) {
      return "Nodal officer and Alternate Nodal officer cannot have the same Mobile Number";
    }

    // if no special case
    return validateInputField[accessor]?.message;
  },

  // dynamic  dropdown of corporate form
  dropDownOption: (accessor, options, pfmOptions, formObj, allCRA, allPOPS) => {
    //   pfm options on active choice and auto choice
    if (accessor === "pfmSelection" && pfmOptions) {
      return pfmOptions;
    }

    //  nps enrollment window
    let daysInMonth = [
      "January",
      "March",
      "May",
      "July",
      "August",
      "October",
      "December",
    ];
    if (accessor === "npsEnrollStartDate") {
      let days =
        formObj.npsEnrollMonth === "February"
          ? 28
          : daysInMonth.includes(formObj.npsEnrollMonth)
          ? 31
          : 30;
      let options = [];
      if (formObj?.npsEnrollWindow === "Monthly") {
        for (let i = 1; i <= 27; i++) {
          options.push(i);
        }
        options.push("28/29/30/31");
      } else {
        for (let i = 1; i <= days; i++) {
          options.push(i);
        }
      }
      return options;
    }
    if (accessor === "npsEnrollEndDate") {
      let days =
        formObj.npsEnrollMonth === "February"
          ? 28
          : daysInMonth.includes(formObj.npsEnrollMonth)
          ? 31
          : 30;
      let options = [];

      if (formObj.npsEnrollWindow === "Monthly") {
        for (let i = formObj.npsEnrollStartDate; i < 27; i++) {
          options.push(i);
        }
        options.push("28/29/30/31");
      } else {
        for (let i = formObj.npsEnrollStartDate; i < days + 1; i++) {
          options.push(i);
        }
      }
      return options;
    }

    // all cra dropdown
    if (accessor === "craName") {
      return allCRA.map((cra) => cra.name);
    }

    if (accessor === "popChngExistPop") {
      return allPOPS.map((pop) => pop.name);
    }

    // if no condition true the return
    return options;
  },

  // disable fields
  handleDisable: (accessor, formObj, autoPopulateAccessor) => {
    if (
      autoPopulateAccessor.length > 0 &&
      autoPopulateAccessor.includes(accessor)
    ) {
      return true;
    }

    // if corporate start form then disable nodal email and mobile fields

    if (
      (formObj.formfiller !== "rm" && accessor === "noEmailId") ||
      (formObj.formfiller !== "rm" && accessor === "noMobileNumber") ||
      (formObj.formfiller !== "rm" && accessor === "noFirstName")
    ) {
      return true;
    }

    // hide charges if yes selected
    const chargesAccessor = [
      "acOpeningCharge",
      "superContribCharges",
      "contriCharges",
      "nonFinTransactions",
    ];
    if (formObj?.standardCharge !== "No" && chargesAccessor.includes(accessor))
      return true;

    //disable existing pop number
    if (accessor === "popChngExistPopRegNo") return true;

    // if month not selected then disable start and end dates
    if (
      accessor === "npsEnrollStartDate" &&
      formObj?.npsEnrollWindow !== "Monthly" &&
      (formObj?.npsEnrollMonth === "" || !formObj?.npsEnrollMonth)
    ) {
      return true;
    }
    if (
      accessor === "npsEnrollEndDate" &&
      (formObj?.npsEnrollStartDate === "" || !formObj?.npsEnrollStartDate)
    ) {
      return true;
    }

    if (
      accessor === "contriCharges" &&
      formObj?.contriChargesSelection !== "Percentage"
    ) {
      return true;
    }
  },

  // if any field is empty or incorrect input fields will be red
  handleFieldError: (field, formObj, validateInputField, inputFieldsError) => {
    if (
      (field === "noEmailId" || field === "altNoEmailId") &&
      formObj.noEmailId?.toUpperCase() ===
        formObj?.altNoEmailId?.toUpperCase() &&
      formObj[field] !== ""
    ) {
      return true;
    } else if (
      field === "npsAgreementEndDate" &&
      formObj?.npsAgreementEndDate < CorporateUtils.getCurrentDateYYYYMMDD()
    ) {
      return true;
    } else if (
      (field === "noMobileNumber" || field === "altNoMobileNumber") &&
      formObj.noMobileNumber === formObj?.altNoMobileNumber &&
      formObj[field]
    ) {
      return true;
    } else if (
      (inputFieldsError?.includes(field) &&
        CorporateUtils?.checkNonMandateFieldsValidation(field, formObj)) ||
      (inputFieldsError?.includes(field) && !formObj[field]) ||
      (validateInputField[field]?.valid === false && formObj[field] !== "")
    ) {
      return true;
    } else {
      return false;
    }
  },

  //  check if any non mandate fields are incorrectly filled
  checkNonMandateFieldsValidation: (accessor, formObj) => {
    if (
      (accessor === "npsEnrollStartDate" || accessor === "npsEnrollEndDate") &&
      formObj?.npsEnrollWindow === "Monthly" &&
      (formObj?.npsEnrollStartDate === "" || formObj?.npsEnrollEndDate === "")
    ) {
      return true;
    }

    let npsWindowOptions = ["Quarterly", "Half Yearly", "Yearly"];
    let npsWindowAccessor = [
      "npsEnrollMonth",
      "npsEnrollStartDate",
      "npsEnrollEndDate",
    ];
    if (
      npsWindowAccessor?.includes(accessor) &&
      npsWindowOptions.includes(formObj?.npsEnrollWindow) &&
      (formObj?.npsEnrollStartDate === "" ||
        formObj?.npsEnrollEndDate === "" ||
        !formObj?.npsEnrollMonth ||
        formObj?.npsEnrollMonth === "")
    ) {
      return true;
    }

    return false;
  },

  // custom function for mandatory
  customMandatoryFunction: (data, propertyCheck) => {
    if (
      data["popChargesBorneBy"] === "Employee" &&
      propertyCheck === "popChargesDedCycle" &&
      data[propertyCheck] === ""
    ) {
      return true;
    } else if (
      data["schemePreference"] === "Subscriber" &&
      (propertyCheck === "investmentOption" ||
        propertyCheck === "pfmSelection" ||
        propertyCheck === "autoChoiceLc" ||
        propertyCheck === "pfmLabel") &&
      data[propertyCheck] === ""
    ) {
      return true;
    } else if (
      data["isPopShift"] === "false" &&
      (propertyCheck === "popChngChoNum" ||
        propertyCheck === "popChngExistPop" ||
        propertyCheck === "popChngExistPopRegNo" ||
        propertyCheck === "popChngtargetedPop" ||
        propertyCheck === "popChngtargetedPopRegNo")
    ) {
      return true;
    } else if (
      data["investmentOption"] === "Active Choice" &&
      propertyCheck === "autoChoiceLc"
    ) {
      return true;
    } else if (
      data["npsEnrollWindow"] === "Monthly" &&
      propertyCheck === "npsEnrollMonth"
    ) {
      return true;
    } else if (
      data["npsEnrollWindow"] === "Continuous" &&
      (propertyCheck === "npsEnrollMonth" ||
        propertyCheck === "npsEnrollEndDate" ||
        propertyCheck === "npsEnrollStartDate")
    ) {
      return true;
    } else if (propertyCheck === "otherSector" && data["sector"] !== "Others") {
      return true;
    } else if (
      propertyCheck === "contriCharges" &&
      data.standardCharge === "Yes"
    ) {
      return true;
    } else {
      return false;
    }
  },

  getCurrentDateYYYYMMDD: () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  },

  // Function to check if a field is mandatory or has invalid input
  isFieldInvalid: (formObj, field, validateInputField) => {
    let nonMandateFieldArray = ["npsEnrollMonth", "npsEnrollWindow"];
    //  if cho number less than 7
    if (nonMandateFieldArray.includes(field?.accessor)) {
      return CorporateUtils.checkNonMandateFieldsValidation(
        field?.accessor,
        formObj
      );
    } else if (
      field?.accessor === "npsAgreementEndDate" &&
      formObj?.npsAgreementEndDate < CorporateUtils.getCurrentDateYYYYMMDD()
    ) {
      return true;
    } else if (
      !formObj?.npsEnrollWindow &&
      (field?.accessor === "npsEnrollMonth" ||
        field?.accessor === "npsEnrollEndDate" ||
        field?.accessor === "npsEnrollStartDate")
    ) {
      return false;
    } else if (
      field.accessor === "popChngChoNum" &&
      (!formObj?.popChngChoNum || formObj?.popChngChoNum.length < 7) &&
      formObj?.isPopShift === "true"
    ) {
      return true;
    } else if (
      field.accessor === "totalEmployees" &&
      formObj[field?.accessor] < 1
    ) {
      return true;
    } else if (
      field?.accessor === "superContribCharges" &&
      (parseFloat(formObj.superContribCharges) > 0.5 ||
        parseFloat(formObj.superContribCharges) < 0.1)
    ) {
      return true;
    } else if (
      field?.accessor === "contriChargesSelection" &&
      formObj?.standardCharge === "Yes"
    ) {
      return false;
    } else if (
      field?.accessor === "contriCharges" &&
      formObj?.contriChargesSelection === "Percentage" &&
      (formObj?.contriCharges > 0.5 || formObj?.contriCharges < 0.1)
    ) {
      return true;
    } else if (
      field?.accessor === "acOpeningCharge" &&
      (parseFloat(formObj.acOpeningCharge) > 400 ||
        parseFloat(formObj.acOpeningCharge) < 200)
    ) {
      return true;
    } else if (
      field?.accessor === "nonFinTransactions" &&
      parseFloat(formObj[field?.accessor]) < 30 &&
      formObj[field?.accessor] !== ""
    ) {
      return true;
    } else if (
      (field.is_mandatory &&
        !CorporateUtils.customMandatoryFunction(formObj, field.accessor) &&
        !formObj[field.accessor]) ||
      validateInputField[field.accessor]?.valid === false ||
      formObj[field.accessor] === "Select Option"
    ) {
      return true;
    }
  },
  // get area data from area pin code
  handleAreaPinCode: (
    pinCode,
    accessor,
    setFormObj,
    dispatch,
    setAllStates,
    setIsNoPinCode,
    setIsAnoPinCode,
    setIsHoPinCode
  ) => {
    getAreaDetailsFromPinCode(pinCode, accessor)
      .then((res) => {
        if (res.data?.success === true) {
          let addObj = res.data.data;
          if (typeof addObj !== "object") return;

          setFormObj((obj) => ({
            ...obj,
            ...addObj,
          }));

          if (accessor === "ho") {
            setIsHoPinCode(true);
          } else if (accessor === "no") {
            setIsNoPinCode(true);
          } else {
            setIsAnoPinCode(true);
          }
        } else {
          dispatch(
            setAlertMsg({
              msg: "No details found for this pin code",
              alertType: "danger",
            })
          );
        }
      })
      .catch((err) => {
        //  all state
        getAllStates()
          .then((res) => {
            if (res.data.success === true) {
              setAllStates(res.data?.data);
            }
          })
          .catch((error) => {
            dispatch(
              setAlertMsg({
                msg: error.response?.data?.message,
                alertType: "warning",
              })
            );
          });

        if (accessor === "ho") {
          setIsHoPinCode(false);
          setFormObj((obj) => ({
            ...obj,
            hoState: "",
            hoDistrict: "",
          }));
        } else if (accessor === "no") {
          setIsNoPinCode(false);
          setFormObj((obj) => ({
            ...obj,
            noState: "",
            noDistrict: "",
          }));
        } else {
          setIsAnoPinCode(false);
          setFormObj((obj) => ({
            ...obj,
            anoState: "",
            anoDistrict: "",
          }));
        }
        dispatch(
          setAlertMsg({
            msg: err.response.data.message || "Internal Server Error",
            alertType: "danger",
          })
        );
      });
  },

  // show in place of this
  handleExceptionInputs: (
    accessor,
    formObj,
    field,
    validateInputField,
    inputFieldsError,
    setFormObj,
    allStates,
    allDistrict,
    isHoPinCode,
    isNoPinCode,
    isAnoPinCode
  ) => {
    if (accessor === "contriCharges" && formObj?.standardCharge === "Yes") {
      return <></>;
    }

    if (accessor === "gstno" && formObj?.tempGsts?.length > 0) {
      const gstArray = formObj?.tempGsts.split(",");
      return (
        <>
          <select
            className={`${
              CorporateUtils.handleFieldError(
                field?.accessor,
                formObj,
                validateInputField,
                inputFieldsError
              )
                ? classes.input_empty
                : classes.input
            }`}
            value={formObj[field.accessor]}
            onChange={(e) =>
              setFormObj((data) => ({
                ...data,
                [field.accessor]: e.target.value,
              }))
            }
          >
            {gstArray?.map((gst, index) => {
              return (
                <option key={index} value={gst}>
                  {gst}
                </option>
              );
            })}
          </select>
        </>
      );
    }

    // for head office pinCode address
    if (
      (isHoPinCode === false && accessor === "hoState") ||
      (isNoPinCode === false && accessor === "noState") ||
      (isAnoPinCode === false && accessor === "anoState")
    ) {
      let district =
        accessor === "hoState"
          ? "hoDistrict"
          : accessor === "noState"
          ? "noDistrict"
          : "anoDistrict";
      return (
        <>
          <select
            disabled={allStates?.length === 0}
            className={`${
              CorporateUtils.handleFieldError(
                field?.accessor,
                formObj,
                validateInputField,
                inputFieldsError
              )
                ? classes.input_empty
                : classes.input
            }`}
            value={formObj[field.accessor]}
            onChange={(e) =>
              setFormObj((data) => ({
                ...data,
                [field.accessor]: e.target.value,
                [district]: "",
              }))
            }
          >
            <option> Select Option</option>

            {allStates?.map((states, index) => {
              return <option key={index}> {states?.name} </option>;
            })}
          </select>
        </>
      );
    }

    // for district
    if (
      (isHoPinCode === false && accessor === "hoDistrict") ||
      (isNoPinCode === false && accessor === "noDistrict") ||
      (isAnoPinCode === false && accessor === "anoDistrict")
    ) {
      return (
        <>
          <select
            disabled={allDistrict[accessor]?.length === 0}
            className={`${
              CorporateUtils.handleFieldError(
                field?.accessor,
                formObj,
                validateInputField,
                inputFieldsError
              )
                ? classes.input_empty
                : classes.input
            }`}
            value={formObj[field.accessor]}
            onChange={(e) =>
              setFormObj((data) => ({
                ...data,
                [field.accessor]: e.target.value,
              }))
            }
          >
            <option> Select Option</option>

            {allDistrict[accessor]?.map((district, index) => {
              return <option key={index}>{district?.name}</option>;
            })}
          </select>
        </>
      );
    }
  },

  //  handle btn click
  handleFormBtn: (e, accessor, formObj, dispatch, setFormObj, corpId) => {
    // get bank details as ifsc code filled
    if (accessor === "ifscNumber") {
      let ifsc = formObj.ifscNumber;
      // if ifsc correct get bank details
      if (Utils.validateIFSC(ifsc.toUpperCase()).valid) {
        getBankDetails(ifsc)
          .then((res) => {
            if (res.data.success === true) {
              setFormObj((data) => ({
                ...data,
                bankName: res.data.data.bank,
              }));
              dispatch(
                setAlertMsg({
                  msg: "Bank details found",
                  alertType: "success",
                })
              );
            }
          })
          .catch((err) => {
            dispatch(
              setAlertMsg({
                msg: err.response.data.message,
                alertType: "danger",
              })
            );
          });
      }
    }

    //   validate bank details
    if (accessor === "isBankAccountVerified") {
      if (
        Utils.validateAccount(formObj?.accountNumber).valid &&
        Utils.validateIFSC(formObj?.ifscNumber?.toUpperCase()).valid &&
        formObj?.isBankAccountVerified !== true
      ) {
        verifyBankDetails(
          {
            ifsc: formObj.ifscNumber,
            accNo: formObj.accountNumber,
            consent: "Y",
          },
          corpId
        )
          .then((res) => {
            if (res.data.success === true) {
              setFormObj((data) => ({
                ...data,
                isBankAccountVerified:
                  res.data.data?.corpFormData?.isBankAccountVerified,
              }));
              dispatch(
                setAlertMsg({
                  msg: "Bank Details Verified",
                  alertType: "success",
                })
              );
            }
          })
          .catch((error) => {
            dispatch(
              setAlertMsg({
                msg: error.response.data.message || "internal server error",
                alertType: "danger",
              })
            );
          });
      } else {
        dispatch(
          setAlertMsg({ msg: "Bank Details Invalid", alertType: "warning" })
        );
      }
    }
  },
};

export default CorporateUtils;
