import React from "react";
import classes from "./PreViewForm.module.css";
import EditIcon from "../../assets/penIcon.svg";

const ObjPreViewForm = ({
  ConfigObj,
  isEdit,
  previewFormData,
  setPreviewFormData,
  setIsEdit,
}) => {
  return (
    <div className={classes.form_container}>
      <div className={classes.form}>
        {ConfigObj &&
          ConfigObj.map((step, stepIndex) => {
            return (
              <div className={classes.form_box} key={stepIndex}>
                <div
                  style={{
                    background:
                      stepIndex !== 0 ? "var(--app-gray-background) " : "",
                  }}
                  className={classes.sections}
                >
                  {stepIndex === 0 ? (
                    <div
                      className={classes.editIcon_container}
                      onClick={() => setIsEdit(!isEdit)}
                    >
                      <img src={EditIcon} /> Edit
                    </div>
                  ) : null}
                  {step.section &&
                    step.section.map((section, index) => (
                      <div key={index} className={classes.section_container}>
                        {section.title && (
                          <span className={classes.form_title_container}>
                            <h3 className={classes.title}>{section.title}</h3>
                          </span>
                        )}
                        <div className={classes.all_fields} key={index}>
                          {section.Fields &&
                            section?.Fields.map((field, fieldIndex) => (
                              <div
                                className={classes.fieldscontainer}
                                key={fieldIndex}
                              >
                                <p className={classes.p_label}>
                                  {field.label}
                                  {field.is_mandatory && <>*</>}
                                </p>
                                {previewFormData && !isEdit && (
                                  <p className={classes.p_input}>
                                    {previewFormData[field.accessor]}
                                  </p>
                                )}
                                {isEdit && (
                                  <input
                                    type={field.type}
                                    placeholder={`Ex - ${field?.placeholder}`}
                                    className={classes.edit_input}
                                    value={previewFormData[field.accessor]}
                                    onChange={(e) =>
                                      setPreviewFormData((data) => ({
                                        ...data,
                                        [field.accessor]: e.target.value,
                                      }))
                                    }
                                  />
                                )}
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ObjPreViewForm;
