import React from "react";
import Classes from "./ChargeScruitny.module.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Utils from "../../../../Utils";
import GoBackButton from "../../../../components/Buttons/GoBackButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import { useEffect } from "react";
import Consts from "../../../../Consts";
import {
  getChargeDetails,
  submitChargeScuritny,
  submitUpdateCharge,
} from "./ChargeActions";
import ChargeRejectModal from "./ChargeRejectModal";
import { useNavigate } from "react-router";
import {
  BASE_URL,
  CORPORATEDASHBOARD_URL,
  DASHBOARD_URL,
} from "../../../../Routes/Urls";
import { setAlertMsg } from "../../../Home/HomeSlice";
import { useDispatch } from "react-redux";
import infoIcon from "../../../../assets/info-blue-icon.svg";

const ChargeScruitny = ({ screenType }) => {
  const corpId = JSON.parse(localStorage.getItem("corpId"));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isRaiseModal, setIsRaiseModal] = useState(false);
  const [reasonString, setReasonString] = useState("");
  const [staticResult, setStaticResult] = useState({});
  const [errMsg, setErrMsg] = useState({});
  const [corpDetailsObj, setCorpDetailsObj] = useState({
    corporateName: "",
    ackId: "",
    date: "",
    tat: null,
    isPOPShift: null,
  });
  const [formObj, setFormObj] = useState([
    {
      label: "Account Opening Charge",
      name: Consts.CHARGES_APPROVAL_ACCESSORS.ACCOUNT_OPENING_CHARGES,
      type: "number",
      tag: "input",
      value: "",
      min: 200,
      max: 400,
      value_type: "₹",
      step: 1,
      placeholder: "Ex - 300",
      description: "Enter the account opening charge, between 200 and 400.",
    },
    {
      label: "Superannuation Contribution Charges",
      name: Consts.CHARGES_APPROVAL_ACCESSORS.SUPER_CONTRI_CHARGE,
      type: "number",
      tag: "input",
      value: "",
      step: 0.1,
      max: 0.5,
      min: 0.1,
      value_type: "%",
      placeholder: "Ex - 0.4",
      description: "Enter the superannuation contribution charges up to 0.5% .",
    },
    {
      label: "Type of Contribution Charges",
      name: Consts.CHARGES_APPROVAL_ACCESSORS.CONTRI_CHARGE_SELECTION,
      tag: "select",
      description:
        "Enter the contribution charges, which are 0.5% or ₹ 30, whichever is higher.",
      options: [
        {
          id: Consts.CONTRI_CHARGE_SELECT_AMOUNT,
          value: Consts.CONTRI_CHARGE_SELECT_AMOUNT,
        },
        {
          id: Consts.CONTRI_CHARGE_SELECT_PERCENTAGE,
          value: Consts.CONTRI_CHARGE_SELECT_PERCENTAGE,
        },
      ],
    },
    {
      label: "Contribution Charges",
      name: Consts.CHARGES_APPROVAL_ACCESSORS.CONTRI_CHARGE,
      type: "number",
      tag: "input",
      value: "",
      step: 0.1,
      max: 0.5,
      min: 0.1,
      value_type: "₹",
      placeholder: "Ex - 0.4",
      disabled: true,
      description:
        "Enter the contribution charges, which are 0.5% or ₹ 30, whichever is higher.",
    },
    {
      label: "Non-Financial Transactions",
      name: Consts.CHARGES_APPROVAL_ACCESSORS.NON_FINANCE_TRANSACTION,
      type: "number",
      tag: "input",
      value: "",
      placeholder: "Ex - 35",
      description: "Enter any non-financial transactions that will be applied.",
      value_type: "₹",
      min: 30,
    },
  ]);

  useEffect(() => {
    if (screenType === undefined) {
      screenType = Consts.SCREEN_TYPE.VIEW;
    }
    getChargeDetailsFunction();
  }, []);

  //  chnage value type
  const changeValueType = (arr) => {
    const contriIndex = arr.findIndex(
      (e) =>
        e?.name === Consts.CHARGES_APPROVAL_ACCESSORS.CONTRI_CHARGE_SELECTION
    );
    const selectionVal = arr[contriIndex]?.value;

    const contriChargeindex = arr.findIndex(
      (e) => e?.name === Consts.CHARGES_APPROVAL_ACCESSORS.CONTRI_CHARGE
    );

    arr[contriChargeindex].value_type =
      selectionVal === Consts.CONTRI_CHARGE_SELECT_PERCENTAGE
        ? Consts.PERCENT_ICON
        : Consts.RUPEE_ICON;
    arr[contriChargeindex].disabled =
      selectionVal === Consts.CONTRI_CHARGE_SELECT_AMOUNT;

    return arr;
  };

  const getChargeDetailsFunction = () => {
    var obj = [...formObj];
    getChargeDetails(corpId)?.then((res) => {
      if (res.data.success === true) {
        let chargeObj = res?.data?.data?.charges;
        setReasonString(res?.data?.data?.rejectionReason || "");

        // corp data
        setCorpDetailsObj({
          corporateName: res.data.data.corpData.corpName,
          ackId: res.data.data.corpData.ackId,
          date: Utils.convertToLocalDateOnly(
            res.data.data.corpData.submissionDate
          ),
          tat: res.data.data.corpData.tat,
          isPOPShift: res.data.data.corpData.isPopShift,
        });

        obj?.map((e) => (e.value = chargeObj[e?.name]));
        obj = changeValueType(obj);
        setFormObj(obj);

        const statResult = {};
        for (const item of obj) {
          const { name, value } = item;
          statResult[name] = value;
        }

        setStaticResult(statResult);
      }
    });
  };

  const showErrorMsgFunction = (e, index) => {
    let { name, value } = e.target;
    if (e.target.tag === "input") {
      value = value > 999999 ? 999999 : value;
    } else {
      value = e?.target?.value;
    }
    let msg = "";
    const min = formObj[index]["min"];
    const max = formObj[index]["max"];
    if (
      name === Consts.CHARGES_APPROVAL_ACCESSORS.ACCOUNT_OPENING_CHARGES ||
      name === Consts.CHARGES_APPROVAL_ACCESSORS.SUPER_CONTRI_CHARGE ||
      name === Consts.CHARGES_APPROVAL_ACCESSORS.CONTRI_CHARGE
    ) {
      let checkBoolean = true;

      if (name === Consts.CHARGES_APPROVAL_ACCESSORS.CONTRI_CHARGE) {
        const contriSelectIndex = formObj.findIndex(
          (item) =>
            item?.name ===
            Consts.CHARGES_APPROVAL_ACCESSORS.CONTRI_CHARGE_SELECTION
        );
        if (
          formObj[contriSelectIndex].value ===
          Consts.CONTRI_CHARGE_SELECT_AMOUNT
        ) {
          checkBoolean = false;
        }
      }
      if (checkBoolean) {
        const condition = value >= min && value <= max;
        msg = condition ? "" : "Value must be between " + min + " and " + max;
      }
    } else if (
      name === Consts.CHARGES_APPROVAL_ACCESSORS.NON_FINANCE_TRANSACTION
    ) {
      const condition = value >= min;
      msg = condition ? "" : "Value must be above  30";
    } else if (
      name === Consts.CHARGES_APPROVAL_ACCESSORS.CONTRI_CHARGE_SELECTION
    ) {
      const condition = value != "";
      msg =
        condition === false ? "Please select type of Contributon Charges" : "";
    }

    if (msg !== null && msg !== "") {
      setErrMsg((prevState) => ({
        ...prevState,
        [name]: msg,
      }));
    } else {
      setErrMsg((prevFormData) => {
        const { [name]: valueToRemove, ...remainingFormData } = prevFormData;
        return remainingFormData;
      });
    }
  };

  //  specific changes in Contri charge select
  const changeContriSelectFunction = (obj, e) => {
    const currVal = e?.target?.value;
    const contriIndex = obj.findIndex(
      (item) => item?.name === Consts.CHARGES_APPROVAL_ACCESSORS.CONTRI_CHARGE
    );

    obj[contriIndex].value_type =
      currVal === Consts.CONTRI_CHARGE_SELECT_PERCENTAGE
        ? Consts.PERCENT_ICON
        : Consts.RUPEE_ICON;
    obj[contriIndex].value =
      currVal === Consts.CONTRI_CHARGE_SELECT_AMOUNT ? 30 : 0.5;
    obj[contriIndex].disabled = currVal === Consts.CONTRI_CHARGE_SELECT_AMOUNT;

    return obj;
  };

  //  handle input for edit
  const handleInput = (e, index) => {
    if (screenType === Consts.SCREEN_TYPE.VIEW) {
      return;
    }
    showErrorMsgFunction(e, index);
    setFormObj((prev) => {
      var updatedFormObj = [...prev];
      let name = e?.target?.name;
      if (name === Consts.CHARGES_APPROVAL_ACCESSORS.CONTRI_CHARGE_SELECTION) {
        updatedFormObj = changeContriSelectFunction(updatedFormObj, e);
      }

      updatedFormObj[index] = {
        ...updatedFormObj[index],
        value: e?.target?.value,
      };
      return updatedFormObj;
    });
  };

  //  go to dashboard
  const gotoDashboard = () => {
    navigate(`/${BASE_URL}/${DASHBOARD_URL}/${CORPORATEDASHBOARD_URL}`);
  };

  //  accept / reject charges while scruitny
  const handleSubmit = (type, reason = "") => {
    let obj = {
      reason: reason,
      isFrRaised: "false",
    };
    if (type === Consts.TYPE_REJECT) {
      obj["isFrRaised"] = "true";
    }
    submitChargeScuritny(corpId, obj)
      .then((res) => {
        if (res?.data?.success) {
          dispatch(
            setAlertMsg({
              alertType: "success",
              msg: res?.data?.message,
            })
          );
          gotoDashboard();
        } else {
          if (!res?.response?.data?.success) {
            dispatch(
              setAlertMsg({
                alertType: "danger",
                msg:
                  res?.response?.data?.message ||
                  t("alertMsg.internal_server_error"),
              })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            alertType: "danger",
            msg: t("alertMsg.internal_server_error"),
          })
        );
      });
  };

  // handle submit update charge
  const submitUpdateChargeFunction = () => {
    const result = {};
    let contrivalCondition = false;
    for (const item of formObj) {
      const { name, value } = item;
      result[name] = value;
      if (
        name === Consts.CHARGES_APPROVAL_ACCESSORS.CONTRI_CHARGE_SELECTION &&
        value === Consts.CONTRI_CHARGE_SELECT_AMOUNT
      ) {
        contrivalCondition = true;
      }
    }
    if (contrivalCondition) {
      result[Consts.CHARGES_APPROVAL_ACCESSORS.CONTRI_CHARGE] = 30;
    }

    submitUpdateCharge(corpId, result)
      .then((res) => {
        if (res?.data?.success) {
          dispatch(
            setAlertMsg({
              alertType: "success",
              msg: res?.data?.message,
            })
          );
          gotoDashboard();
        } else {
          if (!res?.response?.data?.success) {
            dispatch(
              setAlertMsg({
                alertType: "danger",
                msg:
                  res?.response?.data?.message ||
                  t("alertMsg.internal_server_error"),
              })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMsg({
            alertType: "danger",
            msg: t("alertMsg.internal_server_error"),
          })
        );
      });
  };

  //  check procced button disablity
  const checkProceedDisability = () => {
    if (screenType === Consts.SCREEN_TYPE.EDIT) {
      if (Object.keys(errMsg)?.length > 0) {
        return true;
      }
      return (
        formObj?.some(
          (e) => e.value === null || e.value === "" || e.value?.length < 1
        ) || false
      );
    }
    return false;
  };

  //  show validation issue
  const showValidationError = (e) => {
    return errMsg[e] && <span className={Classes.err_msg}>{errMsg[e]}</span>;
  };

  useEffect(() => {
    formObj.forEach((input, index) => {
      showErrorMsgFunction(
        {
          target: { name: input.name, value: input.value, tag: input.tag },
          preventDefault: () => {},
        },
        index
      );
    });
  }, [staticResult]);

  const handleKeyDown = (event, tag) => {
    if (tag === "input") {
      const inputValue = event.target.value + event.key;
      if (parseFloat(inputValue) > parseFloat(999999)) {
        event.preventDefault();
      }
    }
  };

  return (
    <section className={Classes.cho_page_form}>
      <div className="container-fluid px-0">
        <GoBackButton />
        <div className="row my-3">
          <div className="col-lg-12">
            <div className={Classes.page_header}>
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="mr-5 mb-0 text-capitalize">
                  {screenType === Consts.SCREEN_TYPE.VIEW
                    ? "Charge Scrutiny"
                    : "Update Charge"}{" "}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid h-75">
        <div className="row" style={{ marginBottom: "3px" }}>
          <div className="col-lg-12">
            <div className="card border-0">
              <div className="py-4 px-5">
                <div className={Classes.corpoDetails_box + " " + "mt-3"}>
                  <div className={Classes.corpoHeading}>Corporate Details</div>
                  <div className={Classes.details_box}>
                    <div className={Classes.details}>
                      Corporate Name :{" "}
                      <span
                        className={
                          Classes.light_grey_color + " " + "font-weight-bold"
                        }
                      >
                        {corpDetailsObj.corporateName}
                      </span>
                    </div>

                    <div className={Classes.details + " " + "text-center"}>
                      Acknowledegement Id :{" "}
                      <span
                        className={
                          Classes.light_grey_color + " " + "font-weight-bold"
                        }
                      >
                        {corpDetailsObj.ackId}
                      </span>
                    </div>
                    {corpDetailsObj.tat != null ? (
                      <div className={Classes.details + " " + "text-center"}>
                        TAT :{" "}
                        <span
                          className={
                            Classes.light_grey_color + " " + "font-weight-bold"
                          }
                        >
                          {corpDetailsObj.tat}{" "}
                          {corpDetailsObj.tat > 1 ? "Days" : "Day"}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      className={
                        corpDetailsObj.tat == null
                          ? `text-center  ${Classes.details}`
                          : Classes.details
                      }
                    >
                      Date :{" "}
                      <span
                        className={
                          Classes.light_grey_color + " " + "font-weight-bold"
                        }
                      >
                        {corpDetailsObj.date}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-lg-12 ">
            <div className="card  border-0">
              <div className="py-4 px-5">
                <div className="row g-3 align-items-center mt-3">
                  <div className="col-md-12">
                    <div className={Classes.form_labels_heading + " " + "mb-2"}>
                      Standard Charges{" "}
                      {screenType === Consts.SCREEN_TYPE.EDIT && (
                        <span
                          title="View Reason"
                          onClick={() => setIsRaiseModal(true)}
                        >
                          <img src={infoIcon} />
                        </span>
                      )}
                    </div>
                  </div>
                  {formObj &&
                    formObj?.map((data, index) => {
                      return (
                        <div
                          className="col-lg-5 col-md-6 col-12 mt-2 mb-2"
                          key={index}
                        >
                          <div className="form-group mb-0">
                            <label className={Classes.mandatoryCheck}>
                              {data?.label}
                            </label>
                            <div className="position-relative">
                              {data?.tag === "input" ? (
                                <input
                                  type={data?.type}
                                  value={data?.value}
                                  name={data?.name}
                                  step={data.step}
                                  max={data?.max}
                                  min={data?.min || 0}
                                  placeholder={data?.placeholder}
                                  className="form-control w-100"
                                  disabled={
                                    screenType === Consts.SCREEN_TYPE.VIEW ||
                                    data?.disabled
                                  }
                                  onKeyDown={(e) => {
                                    handleKeyDown(e, data?.tag);
                                    Utils.handleDecimalCountFromNumInput(e, 2);
                                  }}
                                  onInput={(e) => handleInput(e, index)}
                                />
                              ) : (
                                <select
                                  value={data?.value}
                                  name={data?.name}
                                  className="form-control w-100"
                                  onChange={(e) => handleInput(e, index)}
                                  disabled={
                                    screenType === Consts.SCREEN_TYPE.VIEW
                                  }
                                >
                                  <option value="" selected disabled>
                                    Select
                                  </option>
                                  {data?.options &&
                                    data?.options?.map(
                                      (subOption, subIndex) => {
                                        return (
                                          <option
                                            key={subIndex}
                                            value={subOption?.id}
                                          >
                                            {subOption?.value}
                                          </option>
                                        );
                                      }
                                    )}
                                </select>
                              )}
                              {data?.value_type && (
                                <span className={Classes.value_type}>
                                  {data?.value_type}
                                </span>
                              )}
                            </div>
                            <div className={Classes.input_text}>
                              {data?.description}
                            </div>
                            {showValidationError(data?.name)}
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="col-lg-12 pb-3 px-0">
                  <div className="col-lg-12 mt-3 px-0">
                    <div className="btn-group ml-auto mr-3">
                      <SecondaryButton
                        label={t("Cancel")}
                        onClick={() => gotoDashboard()}
                      />
                    </div>
                    {screenType && screenType === Consts.SCREEN_TYPE.VIEW && (
                      <div className="btn-group ml-auto mr-3">
                        <SecondaryButton
                          label={t("Raise FR")}
                          onClick={() => setIsRaiseModal(true)}
                        />
                      </div>
                    )}
                    <div className="btn-group mr-3">
                      <PrimaryButton
                        label={t("Proceed")}
                        disabled={checkProceedDisability()}
                        onClick={() =>
                          screenType === Consts.SCREEN_TYPE.VIEW
                            ? handleSubmit(Consts.TYPE_ACCEPT)
                            : submitUpdateChargeFunction()
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isRaiseModal && (
        <ChargeRejectModal
          isRaiseModal={isRaiseModal}
          setIsRaiseModal={setIsRaiseModal}
          handleSubmit={handleSubmit}
          reasonString={reasonString}
          screenType={screenType || Consts.SCREEN_TYPE.VIEW}
        />
      )}
    </section>
  );
};

export default ChargeScruitny;
