import React, { useEffect, useState } from "react";
import classes from "./InvestmentModal.module.css";
import Modal from "../../../components/Modal/Modal";
import RedDotIcon from "../../../assets/TableDashboard/RedDot.svg";
import GreenDotIcon from "../../../assets/TableDashboard/GreenDot.svg";
import OrangeDotIcon from "../../../assets/TableDashboard/OrangeDot.svg";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import PieArcLabel from "../../../components/PieChart/PieChart";
import { BarChart } from "../../../components/BarChart/BarChart";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import piggyBankIcon from "../../../assets/piggyBankIcon.svg";
import Utils from "../../../Utils";
import RangeInput from "../../../components/RangeInput/RangeInput";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../Home/HomeSlice";
import TextButton from "../../../components/Buttons/TextButton";

const InvestmentModal = () => {
  const numberInputInvalidChars = ["-", "+", "e", "."];
  const dispatch = useDispatch();
  const [isInvestment, setIsInvestment] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [changePreference, setChangePreference] = useState(false);
  const [changePreferenceTier2, setChangePreferenceTier2] = useState(false);
  const [investAmountInput, setInvestAmountInput] = useState("");
  const [choice, setChoice] = useState(true);
  const [choiceTier2, setChoiceTier2] = useState(true);
  const [fundA, setfundA] = useState("0");
  const [fundE, setfundE] = useState("25");
  const [fundC, setfundC] = useState("50");
  const [fundG, setfundG] = useState("25");
  const [sameAsTier1, setSameAsTier1] = useState(true);
  const [autoChoicePreference, setAutoChoicePreference] =
    useState("Moderate (LC50)");
  const investAmountSuggestion = [
    {
      label: "Rs 1,000",
      data: 1000,
    },
    {
      label: "Rs 5,000",
      data: 5000,
    },
    {
      label: "Rs 10,000",
      data: 10000,
    },
  ];
  const pieChartObj = [
    {
      value: 50,
      label: "Equity (E)",
      color: "var(--app-pie-chart-1st)",
      icon: "E",
    },
    {
      value: 20,
      label: "Corporate Bonds (C)",
      color: "var(--app-pie-chart-2nd)",
      icon: "C",
    },
    {
      value: 15,
      label: "Government Securities (G)",
      color: "var(--app-pie-chart-3rd)",
      icon: "G",
    },
    {
      value: 15,
      label: "Alternate Allocation (A)",
      color: "var(--app-pie-chart-4th)",
      icon: "A",
    },
  ];
  const [sipCalculation, setSipCalculation] = useState({
    invested: "",
    gain: "",
    corpus: "",
  });

  const Data = [
    {
      id: 1,
      year: "Invested",
      userGain: sipCalculation.invested,
    },
    {
      id: 2,
      year: "Gain",
      userGain: sipCalculation.gain,
    },
    {
      id: 3,
      year: "Corpus",
      userGain: sipCalculation.corpus,
    },
  ];

  const [chartData, setChartData] = useState({});

  useEffect(() => {
    setChartData({
      labels: Data.map((data, index) => data.year),
      datasets: [
        {
          label: "",
          data: Data.map((data) => data.userGain),
          backgroundColor: ["rgba(75,192,192,1)", "#1e5d8d", "#ecf0f1"],
        },
      ],
    });
  }, [sipCalculation]);

  useEffect(() => {
    const calculateSip = setTimeout(() => {
      const n = 40 * 12;
      const monthlyRate = 12 / 12 / 1 / 100;
      const totalValue =
        investAmountInput *
        (((1 + monthlyRate) ** n - 1) / monthlyRate) *
        (1 + monthlyRate);

      const totalInvested = 40 * 12 * investAmountInput;

      setSipCalculation((data) => ({
        ...data,
        corpus: Math.ceil(totalValue),
        invested: totalInvested,
        gain: Math.ceil(totalValue - totalInvested),
      }));
    }, 100);

    return () => clearTimeout(calculateSip);
  }, [investAmountInput]);

  Chart.register(CategoryScale);

  //  object of pie chart data
  const autoChoiceObj = [
    {
      choice_type: "Conservative (LC25)",
      data: [
        {
          value: fundE,
          label: "Equity (E)",
          color: "#1C68AC",
          icon: "E",
        },
        {
          value: fundC,
          label: "Corporate Bonds (C)",
          color: "#60B2BD",
          icon: "C",
        },
        {
          value: fundG,
          label: "Government Securities (G)",
          color: "#DFA51C",
          icon: "G",
        },
        {
          value: fundA,
          label: "Asset Allocation (A)",
          color: "#F44336",
          icon: "A",
        },
      ],
    },
    {
      choice_type: "Moderate (LC50)",
      data: [
        {
          value: 50,
          label: "Equity (E)",
          color: "#1C68AC",
          icon: "E",
        },
        {
          value: 30,
          label: "Corporate Bonds (C)",
          color: "#60B2BD",
          icon: "C",
        },
        {
          value: 20,
          label: "Government Securities (G)",
          color: "#DFA51C",
          icon: "G",
        },
        {
          value: 20,
          label: "Asset Allocation (A)",
          color: "#F44336",
          icon: "A",
        },
      ],
    },
    {
      choice_type: "Aggressive (LC75)",
      data: [
        {
          value: 70,
          label: "Equity (E)",
          color: "#1C68AC",
          icon: "E",
        },
        {
          value: 10,
          label: "Corporate Bonds (C)",
          color: "#60B2BD",
          icon: "C",
        },
        {
          value: 15,
          label: "Government Securities (G)",
          color: "#DFA51C",
          icon: "G",
        },
        {
          value: 5,
          label: "Asset Allocation (A)",
          color: "#60B2BD",
          icon: "A",
        },
      ],
    },
  ];

  const handleRangeValues = (accessor1, accessor2, accessor3, value) => {
    if (
      Number(accessor1) +
        Number(accessor2) +
        Number(accessor3) +
        Number(value) !==
      0
    ) {
      let maxValue = 100 - accessor1 - accessor2 - accessor3;
      return maxValue > value ? value?.toString() : maxValue?.toString();
    } else {
      dispatch(
        setAlertMsg({
          msg: "Sum of all assets cannot be empty",
          alertType: "warning",
        })
      );
      return 10;
    }
  };

  const handleChangeRange = (e) => {
    let { name, value } = e?.target;
    switch (name) {
      case "setfundC":
        setfundC(handleRangeValues(fundA, fundE, fundG, value));
        break;

      case "setfundA":
        setfundA(handleRangeValues(fundC, fundE, fundG, value));
        break;

      case "setfundE":
        setfundE(handleRangeValues(fundA, fundC, fundG, value));
        break;

      case "setfundG":
        setfundG(handleRangeValues(fundA, fundE, fundC, value));
        break;

      default:
        break;
    }
  };

  return (
    <Modal style={{ width: "80%", maxWidth: "70rem" }}>
      <div className={classes.investmentModal_container}>
        <h5 className={classes.investmentModal_title}> Investment Details </h5>
        {isInvestment ? (
          <div className={classes.investment_container}>
            <div className={classes.tier1_title}>
              <h5> For TIER 2 Account </h5>
              <span>⭐ Recommended</span>
            </div>
            <div className={classes.change_investment_Details}>
              <div className={classes.user_info}>
                <div>
                  <div className={classes.investment_age}>
                    <h5>Your Current Age</h5>
                    <h6>20</h6>
                  </div>
                  <div className={classes.investmentValue_container}>
                    <h5>I wish to invest the amount monthly</h5>
                    <input
                      onKeyDown={(e) => {
                        if (numberInputInvalidChars.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      type="number"
                      value={investAmountInput}
                      onChange={(e) => setInvestAmountInput(e.target.value)}
                    />
                    <div className={classes.investment_options}>
                      {investAmountSuggestion?.map((data, index) => {
                        return (
                          <SecondaryButton
                            key={index}
                            style={{
                              borderRadius: "1.5rem",
                              whiteSpace: "nowrap",
                              backgroundColor:
                                investAmountInput === data?.data &&
                                "var(--app-btn-background)",
                              color:
                                investAmountInput === data?.data &&
                                "var(--app-white)",
                            }}
                            label={data.label}
                            onClick={() => setInvestAmountInput(data?.data)}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div>
                  <div className={classes.investment_age}>
                    <h5>Date of Retirement</h5>
                    <h6>06/08/2058</h6>
                  </div>
                  <div className={classes.investmentValue_container}>
                    <h5>Investment Strategy (as per your age)</h5>
                    <select>
                      <option> Aggressive (Recommended) </option>
                      <option> Moderate </option>
                      <option> Conservative </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className={classes.investment_calculation_container}>
                <div className={classes.investment_calculation}>
                  <h5 className={classes.investment_prediction_title}>
                    What you'll get after 40 years
                  </h5>
                  <div className={classes.investment_type_info}>
                    <div>
                      <h6>Total Invested</h6>
                      <h5>
                        ₹ {Utils.formatShortMoney(sipCalculation?.invested)}
                      </h5>
                    </div>
                    <div>
                      <h6>Gains</h6>
                      <h5>₹ {Utils.formatShortMoney(sipCalculation?.gain)}</h5>
                    </div>
                    <div>
                      <h6>Total Corpus</h6>
                      <h5>
                        ₹ {Utils.formatShortMoney(sipCalculation?.corpus)}
                      </h5>
                    </div>
                  </div>
                  <div className={classes.expected_pension}>
                    <div>
                      <h6> Your Expected Pension Per Month Will Be</h6>
                      <h5>₹25,000</h5>
                    </div>
                  </div>
                  <p className={classes.tax_info}>
                    <img src={piggyBankIcon} />
                    Total taxes you will save under section 80(C): ₹5.04 Lacs
                    (assuming 20% tax slab)
                  </p>
                </div>
                <div className={classes.BarChart}>
                  <BarChart chartData={chartData} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={classes.investmentDetails_container}>
              <div className={classes.investmentInfo}>
                <h6>
                  On the basis of your age and profile we have curated
                  investment option best for you.
                </h6>
                <h5>
                  Kindly review investment details carefully on review page
                  before submitting.
                </h5>
              </div>

              <div className={classes.Investment_tier}>
                <div>
                  <div className={classes.tier_container}>
                    <h6>For TIER 1</h6>
                    <div className={classes.tier1_info}>
                      <div>
                        Auto Choice with Moderate (LC50)
                        {!changePreference && <span>⭐ Recommended</span>}
                      </div>
                      <TextButton
                        onClick={() => setChangePreference(!changePreference)}
                        label={"Change"}
                      />
                    </div>
                  </div>
                  <div className={classes.investment_info}>
                    <p>
                      <img src={RedDotIcon} alt="img" /> Government securities
                      (G)
                    </p>
                    <p>
                      <img src={GreenDotIcon} alt="img" /> Equity (E)
                    </p>
                    <p>
                      <img src={OrangeDotIcon} alt="img" /> Corporate Bonds (C)
                    </p>
                  </div>
                </div>
                <PieArcLabel width={90} data={pieChartObj} />
              </div>
            </div>
            {changePreference && (
              <div className={classes.investmentOpt_container}>
                <div className={classes.investmentOpt_choice}>
                  <h5>Select your investment option</h5>
                  <div className={classes.fundType_container}>
                    <label>
                      <input
                        checked={choice}
                        onChange={() => {
                          setChoice(true);
                        }}
                        type="checkbox"
                      />
                      Auto Choice
                    </label>
                    <label>
                      <input
                        checked={!choice}
                        onChange={() => {
                          setChoice(false);
                        }}
                        type="checkbox"
                      />
                      Active Choice
                    </label>
                  </div>
                </div>
                <div className={classes.equity_symbol}>
                  <h5>
                    Select your of lifecycle fund for
                    {choice === true ? " Auto" : " Active"} choice
                  </h5>
                  <div className={classes.pieChart_info_points}>
                    <p>
                      <img src={GreenDotIcon} /> Equity (E)
                    </p>
                    <p>
                      <img src={OrangeDotIcon} /> Corporate Bonds (C)
                    </p>
                    <p>
                      <img src={RedDotIcon} /> Government Securities (G)
                    </p>
                  </div>
                </div>
                {choice === true && (
                  <div className={classes.chart_container}>
                    {autoChoiceObj.map((chartData, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() =>
                            setAutoChoicePreference(chartData.choice_type)
                          }
                        >
                          <label>
                            <input
                              checked={
                                autoChoicePreference === chartData.choice_type
                              }
                              type="checkbox"
                            />
                            {chartData.choice_type}
                          </label>

                          <PieArcLabel key={index} data={chartData.data} />
                        </div>
                      );
                    })}
                  </div>
                )}
                {choice !== true && (
                  <div className={classes.activeOpt_container}>
                    <div className={classes.active_chart_container}>
                      <h5>Percentage Allocation</h5>
                      <PieArcLabel data={autoChoiceObj[0].data} />
                    </div>
                    <div className={classes.slider_container}>
                      <RangeInput
                        title={"Equity (E)"}
                        range={"Cannot exceed 75%"}
                        value={fundE}
                        max={75}
                        setValue={handleChangeRange}
                        name={"setfundE"}
                        inputState={setfundE}
                      />
                      <RangeInput
                        title={"Corporate Bonds (C)"}
                        range={"max (100)"}
                        value={fundC}
                        setValue={handleChangeRange}
                        max={100}
                        name={"setfundC"}
                        inputState={setfundC}
                      />
                      <RangeInput
                        title={"Government Securities (G)"}
                        range={"max (100)"}
                        max={100}
                        value={fundG}
                        setValue={handleChangeRange}
                        name={"setfundG"}
                        inputState={setfundG}
                      />
                      <RangeInput
                        title={"Asset Allocation (A)"}
                        range={"Cannot exceed  5%"}
                        value={fundA}
                        setValue={handleChangeRange}
                        max={5}
                        name={"setfundA"}
                        inputState={setfundA}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className={classes.tier1_checkbox}>
              <label>
                Same as Tier 1 Investment Details
                <input
                  checked={sameAsTier1}
                  value={sameAsTier1}
                  onChange={(e) => setSameAsTier1(!sameAsTier1)}
                  type="checkbox"
                />
              </label>
            </div>
            {/*  tier 2 details */}
            {!sameAsTier1 && (
              <>
                <div className={classes.investmentDetails_container}>
                  <div className={classes.investmentInfo}>
                    <h6>
                      On the basis of your age and profile we have curated
                      investment option best for you.
                    </h6>
                    <h5>
                      Kindly review investment details carefully on review page
                      before submitting.
                    </h5>
                  </div>

                  <div className={classes.Investment_tier}>
                    <div>
                      <div className={classes.tier_container}>
                        <h6>For TIER 1</h6>
                        <div className={classes.tier1_info}>
                          <div>
                            Auto Choice with Moderate (LC50)
                            {!changePreferenceTier2 && (
                              <span>⭐ Recommended</span>
                            )}
                          </div>
                          <TextButton
                            onClick={() =>
                              setChangePreferenceTier2(!changePreferenceTier2)
                            }
                            label={"Change"}
                          />
                        </div>
                      </div>
                      <div className={classes.investment_info}>
                        <p>
                          <img src={RedDotIcon} alt="img" /> Government
                          securities (G)
                        </p>
                        <p>
                          <img src={GreenDotIcon} alt="img" /> Equity (E)
                        </p>
                        <p>
                          <img src={OrangeDotIcon} alt="img" /> Corporate Bonds
                          (C)
                        </p>
                      </div>
                    </div>
                    <PieArcLabel width={90} data={pieChartObj} />
                  </div>
                </div>
                {changePreferenceTier2 && (
                  <div className={classes.investmentOpt_container}>
                    <div className={classes.investmentOpt_choice}>
                      <h5>Select your investment option</h5>
                      <div className={classes.fundType_container}>
                        <label>
                          <input
                            checked={choiceTier2}
                            onChange={() => {
                              setChoiceTier2(true);
                            }}
                            type="checkbox"
                          />
                          Auto Choice
                        </label>
                        <label>
                          <input
                            checked={!choiceTier2}
                            onChange={() => {
                              setChoiceTier2(false);
                            }}
                            type="checkbox"
                          />
                          Active Choice
                        </label>
                      </div>
                    </div>
                    <div className={classes.equity_symbol}>
                      <h5>
                        Select your of lifecycle fund for
                        {choiceTier2 === true ? " Auto" : " Active"} choice
                      </h5>
                      <div className={classes.pieChart_info_points}>
                        <p>
                          <img src={GreenDotIcon} /> Equity (E)
                        </p>
                        <p>
                          <img src={OrangeDotIcon} /> Corporate Bonds (C)
                        </p>
                        <p>
                          <img src={RedDotIcon} /> Government Securities (G)
                        </p>
                      </div>
                    </div>
                    {choiceTier2 === true && (
                      <div className={classes.chart_container}>
                        {autoChoiceObj.map((chartData, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() =>
                                setAutoChoicePreference(chartData.choice_type)
                              }
                            >
                              <label>
                                <input
                                  checked={
                                    autoChoicePreference ===
                                    chartData.choice_type
                                  }
                                  type="checkbox"
                                />
                                {chartData.choice_type}
                              </label>

                              <PieArcLabel key={index} data={chartData.data} />
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {choiceTier2 !== true && (
                      <div className={classes.activeOpt_container}>
                        <div className={classes.active_chart_container}>
                          <h5>Percentage Allocation</h5>
                          <PieArcLabel data={autoChoiceObj[0].data} />
                        </div>
                        <div className={classes.slider_container}>
                          <RangeInput
                            title={"Equity (E)"}
                            range={"Cannot exceed 75%"}
                            value={fundE}
                            max={75}
                            setValue={handleChangeRange}
                            name={"setfundE"}
                            inputState={setfundE}
                          />
                          <RangeInput
                            title={"Corporate Bonds (C)"}
                            range={"max (100)"}
                            value={fundC}
                            setValue={handleChangeRange}
                            max={100}
                            name={"setfundC"}
                            inputState={setfundC}
                          />
                          <RangeInput
                            title={"Government Securities (G)"}
                            range={"max (100)"}
                            max={100}
                            value={fundG}
                            setValue={handleChangeRange}
                            name={"setfundG"}
                            inputState={setfundG}
                          />
                          <RangeInput
                            title={"Asset Allocation (A)"}
                            range={"Cannot exceed  5%"}
                            value={fundA}
                            setValue={handleChangeRange}
                            max={5}
                            name={"setfundA"}
                            inputState={setfundA}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
        <div className={classes.investmentBtn}>
          <div>
            <PrimaryButton
              onClick={() => setIsInvestment(true)}
              label={"Yes, Invest Now"}
            />
            <SecondaryButton
              onClick={() => setIsInvestment(false)}
              label={"No, Later"}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InvestmentModal;
