import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../../../components/Modal/Modal";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import rejectfileIcon from "../../../../../assets/reject_document.svg";
import circularCrossOutline from "../../../../../assets/circularCrossOutline.svg";
import Utils from "../../../../../Utils";
import Classes from "./RejectedDocumentListScrutinyModal.module.css";
import CustomTableObjBased from "../../../../../components/CustomTable/CustomTableObjBased";
import { memo } from "react";
const RejectedDocumentListScrutinyModal = ({
  isModalOpen,
  setIsModalOpen,
  corpDetails,
  handleSubmitFunctionSELF,
  rejectedForms,
}) => {
  const { t } = useTranslation();

  const rejectedDocumentTableColumns = [
    {
      widthStyle: "40%",
      header: <p className="text-left mb-0">List of all Document</p>,
      titleHover: (item, index) => item.document_name && item.document_name,
      accessor: (item, index) => (
        <div key={index}>
          {item.document_name &&
            Utils.ShortLengthFunction(item.document_name, 25)}
          {item.mandatory ? (
            <span className={Classes.text_danger_mandatory}>*</span>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      header: <p className="text-left mb-0">Status</p>,
      accessor: (item, index) => <>Rejected</>,
    },
    {
      header: <p className="text-left mb-0">Reason</p>,
      accessor: (item, index) => (
        <div className={Classes.reasons_list}>
          {item.reason &&
            item.reason.map((subItem, id) => {
              return <li key={id}>{subItem}</li>;
            })}{" "}
        </div>
      ),
    },
  ];

  var closeBtnStyle = {
    backgroundColor: "rgb(255, 255, 255)",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    top: "-15px",
    justifyContent: "center",
    right: "-18px",
    cursor: "pointer",
    borderRadius: "50%",
  };
  return (
    isModalOpen && (
      <Modal closeBtn={closeBtnStyle} onClose={() => setIsModalOpen(false)}>
        <div className={"p-4" + " " + Classes.rejected_modal}>
          <div className="d-flex align-items-center justify-content-between flex-column">
            <h5>
              <img src={rejectfileIcon} className={Classes.rejected_svg} />{" "}
              Document rejected !
            </h5>
            <div
              className={" " + " " + Classes.modal_acknowledgement_badge}
              disabled
            >
              Acknowledegement Id : {corpDetails.ackId}
            </div>
          </div>
          <div className={"mt-4" + " " + Classes.reject_document_name}>
            <p>
              <i>
                <img
                  src={circularCrossOutline}
                  className={Classes.circular_check_svg}
                />
              </i>{" "}
              The Application has{" "}
              {rejectedForms != null && Object.keys(rejectedForms).length}{" "}
              document rejected.
            </p>
          </div>
          <div className={Classes.corpoDetails_box + " " + "mt-3"}>
            <div
              className={Classes.details_box + " " + "justify-content-start"}
            >
              <div className={Classes.details}>
                <span className={Classes.light_grey_color}>Corporate Name</span>
                &ensp;
                <span
                  className={Classes.black_color + " " + "font-weight-bold"}
                >
                  {" "}
                  {corpDetails.corpName}
                </span>{" "}
              </div>
              <div
                className={
                  Classes.details + " " + "text-center justify-content-center"
                }
              >
                <span className={Classes.light_grey_color}>TAT </span>
                &ensp;
                <span
                  className={Classes.black_color + " " + "font-weight-bold"}
                >
                  {corpDetails.tat + " Days"}{" "}
                </span>
              </div>

              <div className={Classes.details}>
                <span className={Classes.light_grey_color}>
                  Relationship Manager
                </span>
                &ensp;{" "}
                <span
                  className={Classes.black_color + " " + "font-weight-bold"}
                >
                  {" "}
                  {corpDetails?.submitter && corpDetails?.submitter?.username}
                </span>
              </div>
            </div>
          </div>
          <div className="mt-4 ">
            <p
              className={
                Classes.sm_font_bold + " " + Classes.disabled + " " + "mb-1 "
              }
            ></p>
          </div>
          <div className={Classes.rejectedDocument_list}>
            <CustomTableObjBased
              headings={rejectedDocumentTableColumns}
              tableCaption={"List of Rejected Documents"}
              documentsList={rejectedForms}
            />
          </div>
          <div className="col-lg-12 mt-3 px-0">
            <p className={Classes.rej_text_down + " " + "mb-3 "}>
              Do you want to raise FR for corporate application Acknowledgement
              ID : {corpDetails.ackId}?
            </p>
            <div className="btn-group ml-auto mr-3">
              <PrimaryButton
                onClick={handleSubmitFunctionSELF}
                label={t("Raise FR")}
              />
            </div>
            <div className="btn-group mr-3">
              <SecondaryButton
                label={t("Close")}
                onClick={() => setIsModalOpen(false)}
              />
            </div>
          </div>
        </div>
      </Modal>
    )
  );
};

export default memo(RejectedDocumentListScrutinyModal);
