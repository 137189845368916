import React, { useEffect, useRef, useState } from "react";
import classes from "./MakerChecker.module.css";
import TableDashboard from "../../../components/TableDashboard/TableDashboard";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getCorporateDashboardData } from "../../CorponboardingForm/CorporateAction";
import Utils from "../../../Utils";
import { useTranslation } from "react-i18next";
import {
  BASE_URL,
  ADMIN_URL,
  MASTERMANAGEMENT_URL,
  REVIEW_CORPORATE_MASTER,
} from "../../../Routes/Urls";
import TextButton from "../../../components/Buttons/TextButton";
import ArrowUPIcon from "../../../assets/TableDashboard/ArrowUp.svg";
import CheckMarkIcon from "../../../assets/TableDashboard/checkmark.svg";
import OverdueIcon from "../../../assets/TableDashboard/overdue.svg";
import TimelimitIcon from "../../../assets/TableDashboard/Timelimit.svg";
import ClockIcon from "../../../assets/TableDashboard/time.svg";
import GreenDot from "../../../assets/TableDashboard/GreenDot.svg";
import OrangeDot from "../../../assets/TableDashboard/OrangeDot.svg";
import RedDot from "../../../assets/TableDashboard/RedDot.svg";
import RejectedIcon from "../../../assets/TableDashboard/Rejected.svg";
import UserBlueIcon from "../../../assets/user_blue_icon.svg";
import Modal from "../../../components/Modal/Modal";
import RejectedDocIcon from "../../../assets/reject_document.svg";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import { CheckerId, RowId } from "../MakerCheckerSlice";
import {
  checkerPotentailUser,
  claimMaster,
  delegateCheckerMaster,
  downloadMasterCSv,
  getSingleMasterData,
} from "../AdminManagementActions";
import { Autocomplete, TextField } from "@mui/material";
import Consts from "../../../Consts";
import ReviewsByModal from "./ReviewMasters/ReviewsByModal/ReviewsByModal";
import { setAlertMsg } from "../../Home/HomeSlice";
const MakerChecker = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.loginUserDetails);
  const checkerUrl = `/${BASE_URL}/${ADMIN_URL}/${MASTERMANAGEMENT_URL}/${REVIEW_CORPORATE_MASTER}`;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [columnRotations, setColumnRotations] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [pageData, setPageData] = useState({});
  const [page, setPage] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [sort, setSort] = useState("desc");
  const [accessor, setAccessor] = useState("submissionDate");
  const [perPage, setperPage] = useState(10);
  const [totalCount, setTotalCount] = useState({});
  const [filterBy, setfilterBy] = useState("");
  const [dateOne, setDateOne] = useState("");
  const [dateTwo, setDateTwo] = useState("");
  const [isReasonModal, setIsReasonModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [potentialUserId, setPotentialUserId] = useState("");
  const [potentialUser, setPotentialUsers] = useState([]);
  const [rejectedReason, setrejectedReason] = useState([]);
  const [currMasterId, setCurrMasterId] = useState("");
  const [reviewType, setReviewType] = useState(0);
  const [isReviewModalOpen, SetIsReviewModalOpen] = useState(false);
  const [isReviewRejectModalOpen, setIsReviewRejectModalOpen] = useState(false);
  var firstDate = "";
  var secondDate = "";
  firstDate = dateOne == "" ? dateOne : Utils.convertToLocalDateForInput(dateOne);
  secondDate = dateTwo == "" ? dateTwo : Utils.convertToLocalDateForInput(dateTwo);
  const release = "release";
  useEffect(() => {
    getSingleMasterData(
      {
        page,
        searchInput,
        sort,
        accessor,
        perPage,
        filterBy,
      },
      firstDate,
      secondDate
    ).then((res) => {
      
      setTableData(res.data.data.pageData.content);
      setPageData(res.data.data.pageData);
      setTotalCount(res.data.data.countData);
    });
  }, [page, sort, accessor, perPage, filterBy, dateOne, dateTwo]);

  // handle search
  const handleSearch = (e) => {
    e.preventDefault();
    getSingleMasterData(
      {
        page,
        searchInput,
        sort,
        accessor,
        perPage,
        filterBy,
      },
      firstDate,
      secondDate
    ).then((res) => {
      setTableData(res.data.data.pageData.content);
      setPageData(res.data.data.pageData);
      setTotalCount(res.data.data.countData);
    });
  };

  // Function to handle changes to Date 1 and Date 2 inputs

  // table columns
  const columns = [
    {
      header: (
        <p className={classes.headers}>
          ACKNOWLEDGEMENT Id
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "ackIdasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("ackId")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.ackId,
    },
    {
      header: (
        <p className={classes.headers}>
          MASTER NAME
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "corpNameasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("master.name")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => e.masterName,
    },
    {
      header: (
        <p className={classes.headers}>
          DATE
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "submissionDateasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("submissionDate")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => Utils.convertToLocalDateOnly(e.submissionDate),
    },
    {
      header: (
        <p className={classes.headers}>
          TAT
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "tatasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("tat")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      accessor: (e) => (
        <p className={classes.tat_container}>
          <img src={e.tat === 0 ? OrangeDot : RedDot} /> {e.tat}
        </p>
      ),
    },
    {
      header: <p className={classes.headers}>STATUS</p>,
      key: "corpStatus",
      accessor: (e) => (
        <p className={classes.state_container}>{e.makerCheckerStatusName}</p>
      ),
    },
    {
      header: (
        <p className={classes.headers}>
          PENDING WITH
          <img
            style={{
              cursor: "pointer",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              transform:
                columnRotations === "checker.usernameasc"
                  ? "rotate(180deg)"
                  : "rotate(0deg)", // Apply rotation
            }}
            onClick={() => handleSort("checker.username")}
            src={ArrowUPIcon}
          />
        </p>
      ),
      key: "pendingWith",
      accessor: (e) => (
        <p className={classes.state_pending}>
          {e.checkerUsername ? e.checkerUsername : "NA"}
        </p>
      ),
    },
    {
      header: <p className={classes.headers_action}>ACTION</p>,
      key: "corpAction",
      accessor: (e) => (
        <div className={classes.action_btn}>
          {e.makerCheckerStatusId === 1 ||
            (e.makerCheckerStatusId === 2 && (
              <TextButton
                onClick={() => handledelegateModal(e)}
                style={{
                  display:  Utils.isAdmin(userDetails) ? "" : "none",
                }}
                label={
                  e.checkerUsername !== null
                    ? t("button.Delegate")
                    : t("button.assigne")
                }
              />
            ))}
          {e.makerCheckerStatusId === 1 && (
            <TextButton
              onClick={() => handleClaimMaster(e.id, "claim",e)}
              label={"Claim"}
            />
          )}
          {e.makerCheckerStatusId === 4 && (
            <TextButton
              onClick={() => handleViewReason(e.reason)}
              label={"View Reason"}
            />
          )}
          {e.makerCheckerStatusId === 2 && (
            <>
              <TextButton
                onClick={() => {
                 
                  selectReviewTypeFunction(e);
                }}
                disabled={
                  userDetails.username === e.checkerUsername ? false : true
                }
                label={"Proceed"}
              />
              <TextButton
                disabled={
                  userDetails.username === e.checkerUsername ? false : true
                }
                onClick={() => handleClaimMaster(e.id, "release",e)}
                label={t("button.release")}
              />
            </>
          )}
        </div>
      ),
    },
  ];

  // select review function
  const selectReviewTypeFunction = (e) => {
    var getIds = Consts.REVIEW_MAKER_CHECKER_IDS;
    dispatch(RowId(e.entityRowId));
    dispatch(CheckerId(e.id));
    if (e.masterId == getIds.Corporate.id) {
      navigate(checkerUrl);
    } else {
      setReviewType(e.masterId);
      SetIsReviewModalOpen(true);
    }
  };

  //  handle rejected reason of corporate
  const handleViewReason = (reason) => {
    setIsReasonModal(true);
    setrejectedReason(reason.split("|"));
  };

  //  open delegate modal
  const handledelegateModal = (obj) => {
    setAssignModal(true);
    let initalPotentailUser = { username: obj.checkerUsername };
    setPotentialUserId(initalPotentailUser);
    setCurrMasterId(obj.id);
    checkerPotentailUser(obj.id).then((res) => {
      if (res.data.success === true) {
        setPotentialUsers(res.data.data);
       
      }
    });
  };

  //  assigne user to master or  delegate
  const handleAssignUser = (e) => {
    delegateCheckerMaster(currMasterId, potentialUserId.id)
      .then((res) => {
        if (res.data.success === true) {
          dispatch(
            setAlertMsg({
              msg: res.data.message,
              alertType: "success",
            })
          );
          setAssignModal(false);
          const simulatedEvent = { preventDefault: () => {} }; // Mock event object
handleSearch(simulatedEvent);
        }
        else {
          dispatch(
            setAlertMsg({
              msg: "Failed to delegate task",
              alertType: "danger",
            })
          );
        }
      })
      .catch((error) => dispatch(
        setAlertMsg({
          msg: t("alertMsg.internal_server_error"),
          alertType: "danger",
        })
      ));
  };

  // claim master
  const handleClaimMaster = (id, type,e) => {
    claimMaster(id, type)
      .then((res) => {
        if (res.data?.success === true) {
          const simulatedEvent = { preventDefault: () => {} }; // Mock event object
handleSearch(simulatedEvent);
          dispatch(
            setAlertMsg({
              msg:
                type == release
                  ? "Task Released Successfully"
                  : "Task Claimed Successfully ",
              alertType: "success",
            })
          );
         
        }
      })
      .catch((error) =>
        dispatch(
          setAlertMsg({
            msg: "Failed to Claim",
            alertType: "danger",
          })
        )
      );
  };

  // handling sort
  const handleSort = (key) => {
    // Toggle sort order
    const newSort = sort === "asc" ? "desc" : "asc";
    setSort(newSort);
    setAccessor(key);

    // Update rotation state for the current column
    if (sort === "asc") {
      setColumnRotations(key + "desc");
    } else {
      setColumnRotations(key + "asc");
    }
  };

  // Analytic Card detail
  const analyticCard = [
    { icon: ClockIcon, title: t("text.pending"), count: totalCount.pendingall },
    {
      icon: CheckMarkIcon,
      title: t("text.accept"),
      count: totalCount.acceptedall,
    },
    {
      icon: RejectedIcon,
      title: t("button.reject"),
      count: totalCount.rejectedall,
    },
    {
      icon: TimelimitIcon,
      title: t("text.unassigned"),
      count: totalCount.unassignedall,
    },
  ];

  // tab  data array
  const tabsLabel = [
    {
      title: ` All (${totalCount.pending +
        totalCount.rejected +
        totalCount.accepted +
        totalCount.unassignedall
        })`,
      key: "",
    },
    {
      title: `${t("text.pending")} (${totalCount.pending})`,
      key: "pending",
    },
    {
      title: `${t("button.reject")} (${totalCount.rejected})`,
      key: "rejected",
    },
    {
      title: `${t("text.accepted")} (${totalCount.accepted})`,
      key: "accepted",
    },
    {
      title: `${t("text.unassigned")} (${totalCount.unassigned})`,
      key: "unassigned",
    },
  ];

  //  table strings
  const tableStrings = {
    title: "Maker Checker",
    listAll_title: "Task",
    search_placeholder: "Search Acknowledgement Id",
  };

  const downloadCSv = () => {
    downloadMasterCSv();
  };


  return (
    <div>
      <TableDashboard
        data={{ page, sort, accessor, perPage, filterBy, dateOne, dateTwo }}
        columns={columns}
        tableStrings={tableStrings}
        downloadCsv={downloadCSv}
        handleSort={handleSort}
        analyticCard={analyticCard}
        tabsLabel={tabsLabel}
        pageData={pageData}
        tabValue={tabValue}
        setTabValue={setTabValue}
        filterBy={filterBy}
        setfilterBy={setfilterBy}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        handleSearch={handleSearch}
        dateOne={dateOne}
        dateSecond={dateTwo}
        setDateOne={setDateOne}
        setDateTwo={setDateTwo}
        perPage={perPage}
        setperPage={setperPage}
        page={page}
        tableData={tableData}
        accessor={accessor}
        setAccessor={setAccessor}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
      />

      {isReasonModal && (
        <Modal onClose={() => setIsReasonModal(false)}>
          <div className={classes.reasonModal_container}>
            <div
              className={
                classes.reasonModal_title + " " + " justify-content-center "
              }
            >
              <img src={RejectedDocIcon} />
              <h3> Corporate master changes rejected! </h3>
            </div>
            <div className={classes.reasonModal_note}>
              <p>Corporate master change done by maker is rejected!</p>
            </div>
            <div className={classes.reasonModal_mistake_container}>
              <p>Due to following reasons :</p>
              <div className={classes.reasonModal_mistake}>
                {rejectedReason.map((reason, key) => {
                  return <p key={key}> {reason} </p>;
                })}
              </div>
            </div>
            <div className={classes.reasonModal_btn}>
              <SecondaryButton
                onClick={() => setIsReasonModal(false)}
                label={t("button.close")}
              />
            </div>
          </div>
        </Modal>
      )}

      {assignModal && (
        <Modal>
          <div className={classes.select_user_container}>
            <h1 className={classes.title}>
              <span>
                <img src={UserBlueIcon} alt="user Assign " />
              </span>{" "}
              Assign User
            </h1>
            <label>
              Assign User
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={potentialUser}
                size="small"
                getOptionLabel={(option) => option.username}
                sx={{ width: 300 }}
                onChange={(e, v) => setPotentialUserId(v)}
                renderInput={(params) => <TextField {...params} label="Select User" />}
              />

            </label>
            <div className={classes.assignModal_btn}>
              <PrimaryButton
                onClick={handleAssignUser}
                label={t("button.assigne")}
              />
              <SecondaryButton
                onClick={() => setAssignModal(false)}
                label={t("button.cancel")}
              />
            </div>
          </div>
        </Modal>
      )}

      {isReviewModalOpen != isReviewRejectModalOpen && (
        <ReviewsByModal
          SetIsReviewModalOpen={SetIsReviewModalOpen}
          reviewTypeId={reviewType}
          isReviewModalOpen={isReviewModalOpen}
          setIsReviewRejectModalOpen={setIsReviewRejectModalOpen}
          isReviewRejectModalOpen={isReviewRejectModalOpen}
          refreshTableFunction={handleSearch}
        />
      )}
    </div>
  );
};

export default MakerChecker;
