import React from "react";
import classes from "./UpdateApplication.module.css";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import fileIcon from "../../../assets/file-icon.svg";
import idIcon from "../../../assets/identity-icon.svg";
import buildingIcon from "../../../assets/bank-icon.svg";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";

const UpdateApplication = () => {
  return (
    <div className={classes.mainContainer}>
      <GoBackButton />
      <div className={classes.titleContainer}>
        <div className={classes.leftWrapper}>
          <div className={classes.titleText}>Further Requirements</div>{" "}
          <div className={classes.subTitle}>
            Sub title text sample text demo
          </div>
        </div>
        <div className={classes.idText}>
          Your Acknowledgement ID : 1998696840
        </div>
      </div>
      <div className={classes.applicationDetailsWrapper}>
        <div className={classes.detailsTitleWrapper}>
          <div className={classes.detailsTitleText}>
            Your Application Details :
          </div>
          <div className={classes.statusWrapper}>
            Your Application Status :{" "}
            <div className={classes.statusValue}>Rejected</div>
          </div>
        </div>
        <div className={classes.detailsContent}>
          <div className={classes.detailsBox}>
            <span className={classes.detailTitle}>Subscriber Name</span>
            <span className={classes.detailValue}>Rahul Sharma</span>
          </div>
          <div className={classes.detailsBox}>
            <span className={classes.detailTitle}>Journey Type</span>
            <span className={classes.detailValue}>PRAN Registration</span>
          </div>
          <div className={classes.detailsBox}>
            <span className={classes.detailTitle}>Company Name</span>
            <span className={classes.detailValue}>Genpact</span>
          </div>
          <div className={classes.detailsBox}>
            <span className={classes.detailTitle}>Rejected by</span>
            <span className={classes.detailValue}>OPS</span>
          </div>
          <div className={classes.detailsBox}>
            <span className={classes.detailTitle}>Rejected on</span>
            <span className={classes.detailValue}>23-03-24 | 10:00 am</span>
          </div>
        </div>
      </div>
      <div className={classes.rejectionWrapper}>
        <div className={classes.rejectTitle}>Reasons for rejection :</div>
        <ol className={classes.reasonsList}>
          <li>
            <div className={classes.listItem}>
              {" "}
              <span>
                {" "}
                <img src={fileIcon} alt='icon' />
              </span>
              <span>Employment ID code in the form is incorrect.</span>
            </div>
          </li>
          <li>
            <div className={classes.listItem}>
              {" "}
              <span>
                {" "}
                <img src={idIcon} alt='icon' />
              </span>
              <span>Aadhar card is not updated</span>
            </div>{" "}
          </li>
          <li>
            <div className={classes.listItem}>
              {" "}
              <span>
                {" "}
                <img src={buildingIcon} alt='icon' />
              </span>
              <span> Bank Details is not verified.</span>
            </div>{" "}
          </li>
        </ol>
      </div>
      <div className={classes.buttonContainer}>
        <PrimaryButton label={"Update Application"} />
      </div>
    </div>
  );
};

export default UpdateApplication;
