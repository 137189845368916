import React from "react";
import Modal from "../../../components/Modal/Modal";
import classes from "./CsvUploadModal.module.css";
import acceptDocumentIcon from "../../../assets/accept_document.svg";
import crossIcon from "../../../assets/Cross_Icons/cross-red-filled.svg";
import checkIcon from "../../../assets/Right_check_icons/check-green-filled.svg";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";

const CsvUploadModal = () => {
  return (
    <>
      <Modal>
        <div className={classes.uploadModal_container}>
          <div className={classes.titleContainer}>
            <span>
              <img src={acceptDocumentIcon} alt="icon" />
            </span>
            <div className={classes.headingText}>Successful CSV Upload</div>
          </div>
          <div className={classes.countContainer}>
            This file has data of 100 subscribers.
          </div>
          <div className={classes.rejectionContainer}>
            <div className={classes.rejectionTitle}>
              <span>
                <img src={crossIcon} alt="icon" />
              </span>
              10 applications rejected.
            </div>

            <span>
              Subscribers will be communicated for correction and document
              requirements.
            </span>
          </div>
          <div className={classes.acceptContainer}>
            <div className={classes.acceptTitle}>
              <span>
                <img src={checkIcon} alt="icon" />
              </span>
              12 applications accepted.
            </div>

            <span>These applications will be sent to CRA</span>
          </div>
          <div className={classes.confirmationContainer}>
            <div className={classes.confirmText}>Do You want to Proceed ?</div>
            <div className={classes.buttonsContainer}>
              <PrimaryButton label={"Yes , Proceed"} />
              <SecondaryButton
                label={"Cancel"}
                style={{
                  borderRadius: "0.5rem",
                  fontWeight: "600",
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CsvUploadModal;
