import React, { useState } from "react";
import classes from "./StepProgressBar.module.css";
import ErrorIcon from "../../assets/Error_icon.svg";
import GreenCheckIcon from "../../assets/Check-green.svg";

const StepProgressBar = ({
  activeStep,
  setActiveStep,
  steps,
  clickAble,
  error,
  stepperErrorArray,
  limit,
  handleProgressBar,
}) => {
  const progressWidth = ((activeStep - 1) / (steps.length - 1)) * 100;

  return (
    <div className={classes["step-progress-bar"]}>
      <div className={classes["step-container"]}>
        {steps.map((step, index) => (
          <div
            style={{ cursor: clickAble ? "pointer" : "" }}
            onClick={() => {
              handleProgressBar(step.number);
            }}
            key={index}
            className={`${classes.step} ${
              activeStep > index + 1
                ? classes.completed
                : activeStep === index + 1
                ? classes.active
                : ""
            }  `}
          >
            {stepperErrorArray.includes(index) && activeStep !== index + 1 ? (
              <div className={`${classes.step} ${classes.error}  `}>
                <img src={ErrorIcon} alt="error" />
              </div>
            ) : !stepperErrorArray.includes(index) && activeStep > index + 1 ? (
              <div className={`${classes.step} ${classes.checkmark}  `}>
                <img src={GreenCheckIcon} alt="error" />
              </div>
            ) : (
              <span className={classes["step-number"]}>{step.number}</span>
            )}
            <div className={classes["step-details"]}>
              <span className={classes["step-text-detail"]}>{step.detail}</span>
            </div>
          </div>
        ))}

        <div className={classes["step-line"]}>
          <div
            className={classes["step-line-progress"]}
            style={{ width: `${progressWidth}%` }}
          />
        </div>
      </div>
    </div>
  );
};

export default StepProgressBar;
