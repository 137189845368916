import React from "react";
import searchOutlineImage from "../../assets/search-outline.svg";
import classes from "./SearchField.module.css";

function SearchField({ label, value, setValue, placeholder, input }) {
  return (
    <div className={classes.main}>
      <label htmlFor="search">
        <span> {label}</span>
      </label>
      <div className={classes.searchBar}>
        <img
          src={searchOutlineImage}
          alt="Serach Field"
          width="100%"
          height="100%"
          style={{ opacity: ".5" }}
        />
        <input
          id="search"
          placeholder={placeholder}
          type="search"
          onChange={(e) => setValue(e.target.value)}
          value={value}
          style={input}
        />
      </div>
    </div>
  );
}

export default SearchField;
