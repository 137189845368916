import React, { forwardRef, useImperativeHandle, useState } from "react";
import Classes from "./SubscriberBankDetails.module.css";
import { useEffect } from "react";
import Utils from "../../../Utils";

const SubscriberBankDetails = forwardRef(
  ({ setIsProceed, previewData, setPreviewData, bankDetailsRef }, ref) => {
    const [isManually, setIsManually] = useState(false);
    const [errors, setErrors] = useState({});
    const [bankTypeForm, setBankTypeForm] = useState([
      {
        label: "Account Type",
        name: "bankAccountType",
        type: "radio",
        options: [
          {
            label: "Saving ",
            value: "savings",
          },
          {
            label: "Current ",
            value: "current",
          },
        ],
      },
    ]);

    const [bankDetails, setBankDetails] = useState([
      {
        name: "bankAccountNumber",
        type: "number",
        label: "Account Number",
        isMandatory: true,
        placeholder: "XXXX-XXXX-XXXX",
      },
      {
        name: "re_bankAccountNumber",
        type: "number",
        label: "Re enter account number",
        isMandatory: true,
        placeholder: "XXXX-XXXX-XXXX",
      },
      {
        name: "bankIfscCode",
        type: "text",
        label: "IFSC Code",
        isMandatory: true,
        placeholder: "XXXX0000000",
      },
    ]);
    const [inputData, setInputData] = useState({
      bankAccountType: "saving",
    });

    useEffect(() => {
      setIsProceed(true);
      if (previewData != undefined && previewData?.subFormData != undefined) {
        setInputData(previewData?.subFormData);
        checkMandatory(previewData?.subFormData);
      }
    }, []);

    useImperativeHandle(bankDetailsRef, () => ({
      toggle() {
        setIsManually(!isManually);
      },
    }));

    //  filter values (input)
    const filterInputs = (e) => {
      switch (e.target.name) {
        case "bankAccountNumber":
        case "re_bankAccountNumber":
          return Utils.PreventSpecialCharacterForNumber(e);
        default:
          return e.target.value; // Return the value itself for other cases
      }
    };

    // handle inputs data
    const handleInputData = React.useCallback(
      (e) => {
        let { name } = e.target;
        let filteredValue = filterInputs(e);
        setInputData((prev) => ({
          ...prev,
          [name]: filteredValue,
        }));
      },
      [filterInputs]
    );

    // disable proceed button
    useEffect(() => {
      let obj = {
        subFormData: inputData,
      };
      setPreviewData(obj);
      checkMandatory(inputData);
    }, [inputData]);

    const checkMandatory = (data) => {
      let isFilled = bankDetails?.every((e) => {
        if (e?.name !== "bankAccountType") {
          return (
            data[e?.name] !== undefined &&
            data[e?.name] !== "" &&
            handleShowError(e?.name, data[e.name]) === ""
          );
        } else {
          return true;
        }
      });
      setIsProceed(!isFilled);
    };

    const handleShowError = (type, value) => {
      if (value) {
        if (type === "bankAccountNumber") {
          return Utils.validateAccount(value).message;
        } else if (type === "bankIfscCode") {
          return Utils.validateIFSC(value).message;
        } else if (type === "re_bankAccountNumber") {
          return (
            Utils.validateAccount(value).message ||
            (inputData["bankAccountNumber"] !==
            inputData["re_bankAccountNumber"]
              ? "Account number mismatch"
              : "")
          );
        }
      } else {
        return "";
      }
    };

    return isManually ? (
      <section className={Classes.manuallySection}>
        <div className="row">
          <div className="col-lg-12">
            {bankTypeForm &&
              bankTypeForm?.map((data, index) => {
                return (
                  <div className="form-group" key={index}>
                    <label className={Classes.label} htmlFor={data.name}>
                      {data?.label}
                    </label>
                    <div className={Classes.radioBox}>
                      {data?.options?.map((subData, subIndex) => {
                        return (
                          <span className={Classes.radioInputs} key={subIndex}>
                            <input
                              type={data.type}
                              name={data.name}
                              value={subData.value}
                              checked={inputData[data.name] === subData.value}
                              id={`${data.name}_${subIndex}`}
                              onChange={handleInputData}
                            />
                            <label
                              className={Classes.radioInputLabel}
                              htmlFor={`${data.name}_${subIndex}`}
                            >
                              {subData.label}
                            </label>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="row">
          {bankDetails?.map((data, index) => {
            return (
              <div className="col-lg-6 mb-3" key={index}>
                <div className="form-group">
                  <label className={Classes.label} htmlFor={data.name}>
                    {data.label}{" "}
                    {data?.isMandatory && (
                      <span className={Classes.isMandatory}>*</span>
                    )}
                  </label>
                  <input
                    type={data.type}
                    name={data.name}
                    id={data.name}
                    className="form-control"
                    value={inputData[data.name] || ""}
                    placeholder={data?.placeholder}
                    onInput={handleInputData}
                  />
                  {handleShowError(data.name, inputData[data?.name]) && (
                    <div className={`${Classes.bottomLabel} text-danger `}>
                      {handleShowError(data.name, inputData[data?.name])}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </section>
    ) : (
      <div></div>
    );
  }
);

export default SubscriberBankDetails;
