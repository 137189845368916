import React, { useEffect, useState } from "react";
import Classes from "./BasicDetailsForm.module.css";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import Utils from "../../../Utils";
import CheckGreenIcon from "../../../assets/Check-green.svg";
import AccordionComponent from "../../../components/Accordion/AccordionComponent";
import { formObj } from "./BasicFormObj";
import NomineeDetails from "../NomineeDetails/NomineeDetails";
const BasicDetailsForm = ({
  setDigilockerProceedModal,
  setPreviewData,
  setIsProceed,
  previewData,
  isDigiLockerSubmit,
  isKycValidate,
}) => {
  const [subscriberFormData, setSubscriberFormData] = useState([
    [
      {
        label: "PAN Number",
        tag: "input",
        type: "text",
        name: "pan",
        bottomInput: "Write Number as per registered on the PAN Card",
        isMandatory: true,
        placeholder: "Ex CCWCU7777H",
      },
      {
        label: "Date of Birth",
        tag: "input",
        type: "date",
        name: "dob",
        bottomInput: "",
        isMandatory: true,
        placeholder: "dd-mm-yyyy",
      },
    ],
    [
      {
        label: "First name",
        tag: "input",
        type: "text",
        name: "firstName",
        bottomInput: "Write name as per official records",
        isMandatory: true,
        placeholder: "Enter your first name",
      },
      {
        label: "Last name",
        tag: "input",
        type: "text",
        name: "lastName",
        bottomInput: "Write name as per official records",
        isMandatory: true,
        placeholder: "Enter your last name",
      },
      {
        label: "Corporate Name",
        tag: "input",
        type: "text",
        name: "corpName",
        bottomInput: "Write Number as per official records",
        isMandatory: true,
        placeholder: "Ex. Company ABC",
        disabled: true,
      },
      {
        label: "CHO Number",
        tag: "input",
        type: "text",
        name: "choNumber",
        bottomInput: "",
        isMandatory: false,
        placeholder: "CHo number",
        disabled: true,
      },
      {
        label: "Employee Code",
        tag: "input",
        type: "text",
        name: "employeeCode",
        bottomInput: "Write Number as per official records",
        isMandatory: true,
        placeholder: "EX- BRNYF7868T",
      },
      {
        label: "Date of Joining",
        tag: "input",
        type: "date",
        name: "dateOfJoin",
        bottomInput: "",
        isMandatory: false,
        placeholder: "",
      },
      {
        label: "CKYC Number",
        tag: "input",
        type: "text",
        name: "ckyc",
        bottomInput: "Write Number as per official records",
        isMandatory: false,
        placeholder: "EX- BRNYF7868T",
      },
    ],
    [
      {
        sideLabel: (
          <span>
            I have accepted the <span className={Classes.anchor}>FATCA</span>{" "}
            declaration
          </span>
        ),
        tag: "checkBox",
        name: "declaration",
        isMandatory: true,
      },
      {
        sideLabel: <span>I am resident of India</span>,
        tag: "checkBox",
        name: "isIndianResident",
        isMandatory: false,
      },
      {
        sideLabel: <span>I acknowledge I do not have NPS account</span>,
        tag: "checkBox",
        type: "text",
        name: "npsAccount",
        isMandatory: true,
        placeholder: "EX- BRNYF7868T",
      },
      {
        sideLabel: <span>I am married</span>,
        tag: "checkBox",
        type: "text",
        name: "isMarried",
        isMandatory: false,
        placeholder: "EX- BRNYF7868T",
      },
    ],
  ]);
  const [inputData, setInputData] = useState({});
  const [mandatoryInputs, setMandatoryInputs] = useState([
    "pan",
    "dob",
    "firstName",
    "lastName",
    "npsAccount",
    "declaration",
    "employeeCode",
  ]);

  useEffect(() => {
    setIsProceed(true);
    if (previewData != undefined && previewData?.subFormData != undefined) {
      setInputData(previewData?.subFormData);
      checkMandatory(previewData?.subFormData);
    }
  }, []);

  useEffect(() => {
    let obj = {
      subFormData: inputData,
    };
    setPreviewData(obj);
    checkMandatory(inputData);
  }, [inputData]);

  const checkMandatory = (data) => {
    let isFilled = mandatoryInputs?.every(
      (e) =>
        data[e] != undefined &&
        data[e] != "false" &&
        data[e] != "" &&
        handleShowError(e, data[e]) === ""
    );
    setIsProceed(!isFilled);
  };

  const filterValues = (name, e) => {
    switch (name) {
      case "pan":
        return Utils.PreventSpecialCharacter(e)?.toUpperCase();
      case "dateOfJoin":
      case "dob":
        return e.target.value;
      default:
        return Utils.PreventSpecialCharacter(e);
    }
  };
  // handle inputs
  const handleInputForm = (e) => {
    let { name, value, type } = e.target;

    value = filterValues(name, e);

    if (type === "checkbox") {
      setInputData((prev) => ({
        ...prev,
        [name]: `${e.target.checked}`,
      }));
    } else {
      setInputData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  //  handle errors
  //  show error msg
  const handleShowError = (type, value) => {
    if (value) {
      if (type === "contact") {
        return Utils.validateContact(value).message;
      } else if (type === "email") {
        return Utils.validateEmail(value).message;
      } else if (type === "pan") {
        return Utils.validatePan(value).message;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  //  set form title in accordion
  const setFormTitle = (e) => {
    return (
      <div className={`${Classes.form_sub_title} px-2`}>
        <h4>{e}</h4>
      </div>
    );
  };

  return (
    <>
      <div className={Classes.contactDetailsSubscriber}>
        {formObj &&
          formObj?.map((data, formIndex) => {
            return (
              <>
                {" "}
                {formIndex === 0 && (
                  <div className="col-lg-12 my-3 px-0">
                    {isDigiLockerSubmit === true && (
                      <div
                        className={`${Classes.kycShowBox} ${
                          isKycValidate && Classes.active
                        }`}
                      >
                        <p>
                          {isKycValidate ? (
                            <span>
                              CKYC Verified <img src={CheckGreenIcon} />
                            </span>
                          ) : (
                            "CKYC Is Not Available"
                          )}
                        </p>
                      </div>
                    )}

                    <div className={Classes.digiLockerDiv}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className={Classes.digiContentPart}>
                          <h4>Digilocker</h4>
                          <p>
                            Digilocker Automatically Verifies Your Document For
                            CKYC
                          </p>
                        </div>
                        <div>
                          <SecondaryButton
                            onClick={() => setDigilockerProceedModal(true)}
                            label={"Proceed With Aadhar Verification"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className={Classes.formBox}>
                  <AccordionComponent
                    defaultExpanded={true}
                    title={setFormTitle(data?.title)}
                  >
                    <div className="row px-2" key={formIndex}>
                      {data?.formArr?.map((subData, index) => {
                        return (
                          <div className="col-lg-6" key={index}>
                            <div className="form-group">
                              {subData?.label && (
                                <label
                                  className="form-label"
                                  htmlFor={subData?.name}
                                >
                                  {subData?.label}{" "}
                                  {subData?.isMandatory && (
                                    <span className={Classes.mandatory}>*</span>
                                  )}{" "}
                                </label>
                              )}
                              {subData.tag === "input" && (
                                <div className={Classes.inputWithTextButton}>
                                  <input
                                    type={subData?.type}
                                    name={subData?.name}
                                    id={subData?.name}
                                    placeholder={subData.placeholder}
                                    disabled={subData?.disabled}
                                    value={inputData[subData?.name]}
                                    onInput={handleInputForm}
                                  />
                                  {subData?.isButtonType && (
                                    <div className={Classes.inputButton}>
                                      {subData?.isButtonName}
                                    </div>
                                  )}
                                </div>
                              )}

                              {subData.tag === "select" && (
                                <select
                                  className="form-control"
                                  id={subData?.name}
                                >
                                  <option value="" disabled selected>
                                    Select
                                  </option>
                                  {subData.options.map(
                                    (optionList, subIndex) => {
                                      return (
                                        <option
                                          value={optionList["name"]}
                                          key={subIndex}
                                        >
                                          {optionList["value"]}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              )}

                              {subData.tag === "checkBox" && (
                                <div
                                  className={`form-check ${Classes.formCheck}`}
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={subData?.name}
                                    name={subData?.name}
                                    checked={
                                      inputData[subData?.name] === "true"
                                    }
                                    onChange={handleInputForm}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={data.name}
                                  >
                                    {data?.sideLabel}
                                  </label>
                                  {data?.isMandatory && (
                                    <span className={Classes.mandatory}>*</span>
                                  )}
                                </div>
                              )}
                              {data?.bottomInput && (
                                <div className={Classes.bottomLabel}>
                                  {data.bottomInput}
                                </div>
                              )}

                              {handleShowError(
                                data.name,
                                inputData[data?.name]
                              ) && (
                                <div
                                  className={`${Classes.bottomLabel} text-danger `}
                                >
                                  {handleShowError(
                                    data.name,
                                    inputData[data?.name]
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </AccordionComponent>
                </div>
              </>
            );
          })}
        <div className={Classes.formBox}>
          <AccordionComponent
            defaultExpanded={true}
            title={setFormTitle("Nominee Details")}
          >
            {" "}
            <NomineeDetails inputData={inputData} setInputData={setInputData} />
          </AccordionComponent>
        </div>
      </div>
    </>
  );
};
export default BasicDetailsForm;
